import React, { useEffect, useState } from 'react'
import { chakra } from '@chakra-ui/react'
import Table from 'components/Table'
import MoreDropdown from 'components/Table/DesktopTable/components/MoreDropdown'
import ViewContractModal from 'components/ViewContractModal'
import { observer } from 'mobx-react'
import moment from 'moment'
import { IRowActionItem } from 'types/table'
import { IContract } from 'constants/schema'
import { getValidArray } from 'utils/commonUtils'
import { useStores } from 'utils/hooks/useStores'
import { getHeaderLaborContractList, headerListTabletTable } from '../../constant'

const LaborContract = () => {
  const { userContractStore } = useStores()
  const [isOpenModal, setOpenModal] = useState(false)
  const [contractName, setContractName] = useState('')
  const { contractList, currentContractContent } = userContractStore

  function showHandler(id: string, fileName: string) {
    setOpenModal(true)
    setContractName(fileName)
    userContractStore.showContractFile(id, fileName)
  }

  function onCloseModal() {
    setOpenModal(false)
  }
  useEffect(() => {
    userContractStore.getContractList()
  }, [])

  const dataInTable = getValidArray(contractList).map((contract: IContract) => {
    const isActive: boolean = contract?.isActive ?? false
    const isDeleted: boolean = contract?.isDeleted ?? false
    let statusText: string = 'Not-Active'
    if (isActive) {
      statusText = 'Active'
    }
    if (isDeleted) {
      statusText = 'Deleted'
    }
    const actionsTablet: IRowActionItem[] = []
    actionsTablet.push({
      name: 'Detail',
      handler: () => showHandler(contract._id, contract.fileName),
    })

    return {
      ...contract,
      status: statusText,
      publishedDate: moment(contract?.publishedDate ?? '').format('DD/MM/YYYY'),
      actions: (
        <MoreDropdown isDetail={true} detailActionHandler={() => showHandler(contract._id, contract.fileName)} />
      ),
      actionsTablet: actionsTablet,
    }
  })
  return (
    <chakra.form width={{ lg: 'full', md: 'full', sm: 'full' }}>
      <Table
        tableSize="md"
        headerList={getHeaderLaborContractList()}
        headerTabletList={headerListTabletTable}
        tableData={dataInTable}
        isShowPagination={false}
        isStriped
        isDetail
        mobileDifferentDesktop={true}
      />
      <ViewContractModal
        isOpen={isOpenModal}
        onClose={onCloseModal}
        content={currentContractContent}
        fileName={contractName}
      />
    </chakra.form>
  )
}

export default observer(LaborContract)
