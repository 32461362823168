import { useEffect } from 'react'
import CurriculumVitaeDetail from 'components/CurriculumVitaeDetail'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import { pdfjs } from 'react-pdf'
import { useParams } from 'react-router-dom'
import { useStores } from 'utils/hooks/useStores'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const UserCV = () => {
  const { adminUserStore } = useStores()
  const params = useParams()
  const userId = get(params, 'id', '')

  const title: string = 'Curriculum User Detail'
  window.sessionStorage.setItem('subPageTitle', title)
  window.dispatchEvent(new Event('storage'))
  useEffect(() => {
    adminUserStore.getCVNameByUserId(userId)
  }, [userId, adminUserStore])

  return <CurriculumVitaeDetail isShowButton={false} cvFileName={adminUserStore.cvFileName} />
}

export default observer(UserCV)
