import { useState } from 'react'
import React from 'react'
import { useMediaQuery } from '@chakra-ui/react'
import Table from 'components/Table'
import OptionButtonsWithoutDropBox from 'components/Table/DesktopTable/components/OptionButtonsWithoutDropBox'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import { maxMobileSize } from 'constants/common'
import { getValidArray } from 'utils/commonUtils'
import { getFormattedDateWith_MMMDDYYYY } from 'utils/dateFormatUtils'
import { useStores } from 'utils/hooks/useStores'
import FixedProjectRatingModal from '../components/FixedProjectRatingsModal'
import { getFixedProjectHeaderList } from '../constants'

const FixedProjectRating = () => {
  const [isMobile]: boolean[] = useMediaQuery(maxMobileSize)
  const { adminUserStore } = useStores()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [currentProjectRatings, setCurrentProjectRatings] = useState(undefined)
  const { projectRatings } = adminUserStore
  const fixedProjectRatingsData = projectRatings?.fixedProjectRatings
  const params = useParams()
  const userId = get(params, 'id', '')
  function openEditModal(projectName: string) {
    const selectedItems = getValidArray(fixedProjectRatingsData).filter((projectRating) => {
      return projectRating.projectName === projectName
    })
    if (getValidArray(selectedItems)) {
      setCurrentProjectRatings(selectedItems[0])
      setIsOpenModal(true)
    }
  }

  function closeModal() {
    setIsOpenModal(false)
  }

  function editProjectRatingHandler(data) {
    adminUserStore.editFixedRatePriceV2(userId, data)
    closeModal()
  }

  const tableData = getValidArray(fixedProjectRatingsData).map((projectRate) => {
    return {
      ...projectRate,
      futureApplyDate: projectRate.futureApplyDate ? getFormattedDateWith_MMMDDYYYY(projectRate.futureApplyDate) : '',
      currentApplyDate: projectRate.currentApplyDate
        ? getFormattedDateWith_MMMDDYYYY(projectRate.currentApplyDate)
        : '',
      actions: (
        <OptionButtonsWithoutDropBox isEdit={true} editActionHandler={() => openEditModal(projectRate.projectName)} />
      ),
    }
  })
  return (
    <React.Fragment>
      <Table headerList={getFixedProjectHeaderList(isMobile)} tableData={tableData} isStriped hasBottomHeader={false} />
      <FixedProjectRatingModal
        isOpen={isOpenModal}
        closeHandler={closeModal}
        data={currentProjectRatings}
        saveHandler={editProjectRatingHandler}
      />
    </React.Fragment>
  )
}

export default observer(FixedProjectRating)
