import { WarningIcon } from '@chakra-ui/icons'
import { Box, HStack } from '@chakra-ui/layout'
import { backgroundGrey50, borderGray200, textRed500 } from 'theme/globalStyles'
import { getValidArray, isValidArray } from 'utils/commonUtils'
import { ErrorItem } from './component/ErrorItem'
import { IAutoDetectingErrorsProps } from './interface'

export const AutoDetectingErrors = (props: IAutoDetectingErrorsProps) => {
  const { errors } = props
  if (!isValidArray(errors)) {
    return null
  }
  return (
    <Box
      mt={4}
      backgroundColor={backgroundGrey50}
      borderRadius="6px"
      border="1px solid"
      borderColor={borderGray200}
      padding="12px">
      <HStack margin={2}>
        <WarningIcon color={textRed500} />
        <Box as="span" flex="1" textAlign="left" color={textRed500} fontWeight="500" fontSize="14px">
          Automatic Error Detection
        </Box>
      </HStack>
      {getValidArray(errors).map((error, index) => (
        <Box key={`${error.errorTitle}_${error.errorDescription}`}>
          <ErrorItem error={error} />
          {index !== errors.length - 1 && <Box borderBottom="1px solid" borderColor={borderGray200} marginY={4} />}
        </Box>
      ))}
    </Box>
  )
}
