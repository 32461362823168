import { ITableHeader } from 'types/table'
import { IUser } from 'constants/schema'

export interface IUserFilter extends Omit<IUser, 'levelId' | 'titleId' | 'role' | 'paymentCategoryId' | 'fullName'> {
  titleId?: string[]
  role?: string[]
  levelId?: string[]
  fullName?: any
  paymentCategoryId?: string[]
  project?: string
}

export const headerListTabletTable = {
  fullNameVI: 'Full Name',
  number: 'Contract No',
  status: 'Status',
  isDeleted: 'Deleted',
  language: 'Languages',
  actionsTablet: '',
}

export function getHeaderLaborContractList() {
  const headers: ITableHeader[] = [
    {
      Header: 'Contract No.',
      accessor: 'number',
    },
    {
      Header: 'Contract Type',
      accessor: 'type',
    },
    {
      Header: 'Published Date',
      accessor: 'publishedDate',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: '',
      accessor: 'actions',
    },
  ]

  return headers
}
