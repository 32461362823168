import React from 'react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { HStack, VStack, Text, Box, Link } from '@chakra-ui/react'
import { IReportData } from 'API/report/constants'
import Icon from 'components/Icon'
import Table from 'components/Table/CustomExpandedTable'
import HighlightTable from 'components/Table/HighlightTableForSupervisor'
import { observer } from 'mobx-react'
import ReactQuill from 'react-quill'
import { Messages } from 'constants/index'
import { getValidArray } from 'utils/commonUtils'
import { getFormattedTimeDate } from 'utils/dateFormatUtils'
import { getFormattedNumber } from 'utils/numberUtils'
import { handleUpdateDescription, calculateLateLoggingTime, formatDurationRow } from '../../../utils'

interface ISupervisorReportProps {
  reportData: IReportData
}

const SupervisorReport = (props: ISupervisorReportProps) => {
  const { reportData } = props
  const { timesheetList, highlightData } = reportData
  const expandedRowObj = {
    expanded: {},
  }
  function getSupervisorReportHeaderList() {
    const headers = [
      {
        Header: 'TASK NAME',
        accessor: 'taskName',
        // id: "expander",
        Cell: ({ row }) => <span {...row.getToggleRowExpandedProps()}>{row.original.taskName}</span>,
      },
      {
        Header: 'USER',
        accessor: 'developerName',
        Cell: ({ row }) => <span {...row.getToggleRowExpandedProps()}>{row.original?.developerName[0]}</span>,
      },
      {
        Header: 'START TIME',
        accessor: 'startTime',
      },
      {
        Header: 'END TIME',
        accessor: 'endTime',
      },
      {
        Header: 'DURATION (HRS)',
        accessor: 'duration',
        Cell: ({ row }) => (
          <span className={`duration ${row.original?.durationStatus}`} {...row.getToggleRowExpandedProps()}>
            {row.original?.duration}
          </span>
        ),
      },
      {
        Header: 'LATE LOGGING TIME',
        accessor: 'lateLoggingTime',
        Cell: ({ row }) => (
          <span
            className={`late-logging-time-status ${row.original?.lateLoggingTimeStatus}`}
            {...row.getToggleRowExpandedProps()}>
            {row.original?.lateLoggingTime}
          </span>
        ),
      },
    ]
    return headers
  }
  const tableData = getValidArray(timesheetList).map((timesheet) => {
    const lateLoggingTimeData = calculateLateLoggingTime(timesheet)
    const durationStatusData = formatDurationRow(timesheet.workingTime)
    return {
      ...timesheet,
      taskTypes: timesheet.taskTypesName.map((taskType) => taskType).join(', '),
      duration: getFormattedNumber(timesheet.workingTime, 1),
      durationStatus: durationStatusData,
      salary: getFormattedNumber(timesheet.salary, 1),
      startTime: getFormattedTimeDate(timesheet.startTime),
      endTime: getFormattedTimeDate(timesheet.endTime),
      lateLoggingTime: lateLoggingTimeData.delayDate,
      lateLoggingTimeStatus: lateLoggingTimeData.status,
    }
  })

  const subTable = React.useCallback(({ row }) => {
    return (
      <Box backgroundColor={'blue.50'} padding={'16px'} width={'inherit'}>
        <Text fontWeight={'bold'}>Description</Text>
        <ReactQuill
          className="task-description"
          value={handleUpdateDescription(row.original.description)}
          theme="bubble"
          readOnly
        />
        {row.original.taskLink && (
          <Box>
            <Link href={row.original.taskLink} isExternal>
              Task link <ExternalLinkIcon mx="2px" />
            </Link>
          </Box>
        )}
        {row.original.taskTypes && (
          <Box>
            <Text fontWeight={'bold'}>Task Types</Text>
            {row.original.taskTypes}
          </Box>
        )}
      </Box>
    )
  }, [])

  return (
    <HStack width="inherit" background="white" margin={{ base: 2, lg: '16px' }}>
      <VStack width="inherit" padding={'16px'} alignItems={'flex-start'}>
        <h3>Report of {highlightData.projectName}</h3>
        <Box maxWidth={'830px'}>
          <HighlightTable data={highlightData} />
        </Box>
        <Box width={'inherit'}>
          <h3>Timesheet List</h3>
          <HStack mb={2}>
            <Icon iconName="information-blue-fill-circle.svg" size={20} />{' '}
            <Text> {Messages.pressToExpandTimesheetDetailGuide} </Text>
          </HStack>
          <VStack width={'inherit'} maxHeight={'500px'} overflowY={'scroll'}>
            <Table
              tableData={tableData}
              headerList={getSupervisorReportHeaderList()}
              subComponent={subTable}
              expandRows={true}
              expandedRowObj={[expandedRowObj]}
            />
          </VStack>
        </Box>
      </VStack>
    </HStack>
  )
}

export default observer(SupervisorReport)
