import { useEffect, useState } from 'react'
import { Flex, HStack, Image, Input, InputGroup, InputLeftAddon, Text, VStack } from '@chakra-ui/react'
import Button from 'components/Button'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import QRCode from 'react-qr-code'
import { borderGray200 } from 'theme/globalStyles'
import QRBackGroundURL from '../../assets/images/background/qr-background.png'

const QRCodePage = () => {
  const [link, setLink] = useState<string>('')
  const methods = useForm()
  const { control, register } = methods
  const watchedWebLink: string = useWatch({ name: 'webLink', control })

  function generateQRCode(): void {
    let webLink: string = ''
    if (watchedWebLink?.includes('https')) {
      webLink = watchedWebLink
    } else {
      webLink = `https://${watchedWebLink}`
    }
    setLink(webLink)
  }

  function downloadQRCode(): void {
    const svg = document.getElementById('QRCode')
    if (svg) {
      const svgData = new XMLSerializer().serializeToString(svg)
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      const img = new window.Image() // Sử dụng window.Image() nếu có vấn đề với scope
      img.onload = () => {
        canvas.width = img.width
        canvas.height = img.height
        ctx.drawImage(img, 0, 0)
        const pngFile = canvas.toDataURL('image/png')
        const downloadLink = document.createElement('a')
        downloadLink.download = 'QRCode'
        downloadLink.href = `${pngFile}`
        downloadLink.click()
      }
      img.src = `data:image/svg+xml;base64,${btoa(svgData)}`
    } else {
      console.error('QR Code SVG not found.')
    }
  }

  useEffect(() => {
    generateQRCode()
  }, [watchedWebLink])

  return (
    <VStack alignItems={'center'} backgroundColor="white" borderRadius="8px" p={{ base: 6, lg: '69px' }} spacing={6}>
      <FormProvider {...methods}>
        <form>
          <VStack width={{ base: 'full', md: '520px' }} spacing={2}>
            <HStack justifyContent="space-between" width="full">
              <Text color="gray.700" lineHeight={6} margin={0}>
                URL
              </Text>
              <Text color="blue.600" lineHeight={6} textAlign="right">
                QR Code will be generated automatically
              </Text>
            </HStack>
            <InputGroup height={10} width={{ base: 'full', md: '520px' }}>
              <InputLeftAddon maxHeight="full" children="https://" />
              <Input
                type="text"
                {...register('webLink')}
                padding={0}
                border={`1px solid ${borderGray200}`}
                maxHeight="full"
                placeholder="Enter URL"
                paddingLeft={4}
                _focus={{
                  border: `1px solid ${borderGray200}`,
                }}
              />
            </InputGroup>
          </VStack>
        </form>
      </FormProvider>
      {watchedWebLink ? (
        <Flex
          padding="24px"
          maxHeight="300px"
          maxWidth="300px"
          borderRadius="8px"
          border={`1px solid ${borderGray200}`}>
          <QRCode
            id="QRCode"
            size={256}
            style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
            value={link}
            viewBox={`0 0 256 256`}
          />
        </Flex>
      ) : (
        <Image width="300px" height="300px" src={QRBackGroundURL} />
      )}

      <Button variant="solid" leftIcon="download.svg" disabled={!watchedWebLink} onClick={downloadQRCode}>
        Download
      </Button>
    </VStack>
  )
}

export default QRCodePage
