import { ChangeEvent, useRef, useState, useEffect } from 'react'
import { Image, Input, HStack } from '@chakra-ui/react'
import { handleError } from 'API/error'
import { uploadImage } from 'API/upload'
import Button from 'components/Button'
import { toast } from 'react-toastify'
import { isFileSizeOverKB } from 'utils/commonUtils'
import { generateImageURL } from 'utils/imageUtils'

interface IUploadBadgeImageProps {
  afterUploadHandler?: (formData: any) => string
  setIsValidBadgeImage?: (isValid: boolean) => void
  data?: string
  type?: string
}

const UploadBadgeImage = (props: IUploadBadgeImageProps) => {
  const { data, afterUploadHandler, setIsValidBadgeImage, type } = props
  const badgeImageRef = useRef<HTMLInputElement | null>(null)
  const [selectedImage, selectImage] = useState<File | null>(null)
  const [preview, setPreview] = useState('')
  const [badgeImage, setBadgeImage] = useState(null)
  const [isValidPhoto, setIsValidPhoto] = useState(false)
  const fullLogoURL = generateImageURL(data, type)

  function chooseBadgeImageHandler(): void {
    badgeImageRef.current?.click()
  }
  async function changeBadgeImageHandler(event: ChangeEvent<HTMLInputElement>): Promise<void> {
    try {
      const file = event.target.files[0]

      if (isFileSizeOverKB(100, file)) {
        toast.error('Badge image size must be less than 100KB')
        setIsValidBadgeImage(false)
        return
      }

      setIsValidBadgeImage(true)

      const formData = new FormData()
      formData.append('myImage', file)
      const response = await uploadImage(formData, type)
      if (!!event?.target?.files?.[0]) {
        !!event?.target?.files?.[0] && selectImage(event?.target?.files?.[0])
      }

      afterUploadHandler(response?.data?.fileName)
    } catch (error) {
      handleError(
        error,
        'src/components/UploadBadgeImage/UploadBadgeImageWithoutFormik/index.tsx',
        'changeBadgeImageHandler'
      )
    }
  }

  useEffect(() => {
    if (fullLogoURL) {
      setBadgeImage(fullLogoURL)
      setIsValidPhoto(true)
    }
  }, [data])

  useEffect(() => {
    if (!selectedImage) {
      setPreview('')
      return
    }
    const objectUrl = URL.createObjectURL(selectedImage)
    setPreview(objectUrl)
    setIsValidPhoto(true)
    //* INFO: disable unnecessary eslint rule eslint-disable-next-line consistent-return
    // eslint-disable-next-line consistent-return
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedImage])

  return (
    <HStack height={'40px'} width={'307'}>
      {isValidPhoto && (
        <Image
          maxHeight={'24px'}
          maxWidth={'130px'}
          src={preview.length === 0 ? badgeImage : preview}
          alt="badge image"
        />
      )}
      <HStack>
        <Button
          variant="outline"
          leftIcon="upload.svg"
          customStyles={{ paddingY: '10px' }}
          onClick={chooseBadgeImageHandler}>
          Upload Image
        </Button>
        <Input type="file" ref={badgeImageRef} accept={'image/*'} display="none" onChange={changeBadgeImageHandler} />
      </HStack>
    </HStack>
  )
}

export default UploadBadgeImage
