import { useEffect, useState } from 'react'
import { VStack, HStack, Box } from '@chakra-ui/react'
import EmptyContentBox from 'components/EmptyContentBox'
import LoadingChakra from 'components/LoadingChakra'
import { observer } from 'mobx-react'
import queryString from 'query-string'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { EConditionGetList, EDashboardSubTitle, ETypeOfFilterOfHeatMap } from 'constants/enum'
import { Messages } from 'constants/index'
import { createOptionsOfReactSelectFromDB } from 'utils/dropdownOptionUtils'
import { useStores } from 'utils/hooks/useStores'
import HeatmapFilterFormForAdmin from '../../components/HeatmapFilterFormForAdmin'
import { IHeatmapFilterFormData, IOption, typeOfFilterOfHeadMap } from '../../constant'
import { RenderBodyHeatChart } from '../../container'
import { IHeatMapFilter } from './interface'

const Heatmap = () => {
  window.sessionStorage.setItem('subPageTitle', EDashboardSubTitle.HEATMAP)
  window.dispatchEvent(new Event('storage'))
  const history = useHistory()
  const { adminProjectStore, adminDashboardStore, adminUserStore } = useStores()
  const { userNameList } = adminUserStore
  const [isValidAnalysis, setIsValidAnalysis] = useState(false)
  const { projectNameList } = adminProjectStore
  const { haveData, chartData, isLoadingHeatmap } = adminDashboardStore

  const projectOptions: IOption[] = createOptionsOfReactSelectFromDB(projectNameList)
  const userOptions: IOption[] = createOptionsOfReactSelectFromDB(userNameList)

  const methods = useForm<IHeatmapFilterFormData>({
    defaultValues: {
      typeOfFilter: typeOfFilterOfHeadMap[0],
    },
  })
  const { getValues, setValue } = methods
  const { selectedProject, selectedUser } = getValues()

  const queryStringInURL = history.location.search
  const parseQueryString = queryString.parse(queryStringInURL)
  const initialFilter: IHeatMapFilter = {}
  if (queryStringInURL) {
    initialFilter.typeOfFilter = parseQueryString?.typeOfFilter as ETypeOfFilterOfHeatMap
    if (parseQueryString?.typeOfFilter === ETypeOfFilterOfHeatMap.FILTER_BY_PROJECT) {
      initialFilter.project = String(parseQueryString?.project)
    }
    if (parseQueryString?.typeOfFilter === ETypeOfFilterOfHeatMap.FILTER_BY_USER) {
      initialFilter.user = String(parseQueryString?.user)
    }
  }

  const [filter, setFilter] = useState<IHeatMapFilter>(initialFilter ?? {})

  function viewHeatmapHandler(data: IHeatmapFilterFormData) {
    const { selectedProject, selectedUser, typeOfFilter } = data
    if (typeOfFilter?.value === ETypeOfFilterOfHeatMap.FILTER_BY_PROJECT) {
      setFilter({
        project: selectedProject?.value,
        typeOfFilter: typeOfFilter?.value,
      })
      history.push({
        search: queryString.stringify(filter),
      })
    }
    if (typeOfFilter?.value === ETypeOfFilterOfHeatMap.FILTER_BY_USER) {
      setFilter({
        user: selectedUser?.value,
        typeOfFilter: typeOfFilter?.value,
      })
      history.push({
        search: queryString.stringify(filter),
      })
    }
  }

  useEffect(() => {
    if (filter?.project && filter?.typeOfFilter === ETypeOfFilterOfHeatMap.FILTER_BY_PROJECT) {
      const selectedProjectOption = projectOptions.find((option) => option.value === filter?.project)
      setValue('selectedProject', selectedProjectOption)
      setValue('typeOfFilter', typeOfFilterOfHeadMap[0])
      adminDashboardStore.getAdminHeatmapData(filter)
    }
    if (filter?.user && filter?.typeOfFilter === ETypeOfFilterOfHeatMap.FILTER_BY_USER) {
      const selectedUser = userOptions.find((option) => option.value === filter?.user)
      setValue('selectedUser', selectedUser)
      setValue('typeOfFilter', typeOfFilterOfHeadMap[1])
      adminDashboardStore.getAdminHeatmapData(filter)
    }
  }, [filter, projectNameList, userNameList])

  useEffect(() => {
    adminProjectStore.getAllNameProjectList(EConditionGetList.ACTIVE)
    adminUserStore.getAllNameUserList(EConditionGetList.ACTIVE)
  }, [])

  useEffect(() => {
    if ((haveData && selectedProject) || (haveData && selectedUser)) {
      setIsValidAnalysis(true)
    } else {
      setIsValidAnalysis(false)
    }
  }, [haveData])

  return (
    <VStack width="full">
      <FormProvider {...methods}>
        <HeatmapFilterFormForAdmin
          projectNameList={projectNameList}
          viewAnalyticHandler={viewHeatmapHandler}
          userNameList={userNameList}
        />

        <LoadingChakra isLoading={isLoadingHeatmap}>
          <Box width="inherit" background="white" margin={{ base: 2, lg: '16px' }}>
            {isValidAnalysis && <div className="heatmap-chart-container">{RenderBodyHeatChart({ chartData })}</div>}
            {!isValidAnalysis && (
              <HStack width="inherit" padding={'16px'} minHeight={'80vh'}>
                <EmptyContentBox text={Messages.emptyBoxFilterMessage} position="bottom" />
              </HStack>
            )}
          </Box>
        </LoadingChakra>
      </FormProvider>
    </VStack>
  )
}

export default observer(Heatmap)
