import { chakra, Text, Flex as CKFlex } from '@chakra-ui/react'
import { statusStyles, typeStyles } from 'containers/Leave/constants'

export const Label = chakra(Text, {
  baseStyle: () => ({
    fontWeight: 400,
    fontSize: 'md',
    lineHeight: 6,
    color: 'gray.500',
    margin: 0,
  }),
})

export const Information = chakra(Text, {
  baseStyle: () => ({
    fontWeight: 500,
    fontSize: 'md',
    lineHeight: 6,
    color: 'gray.700',
    margin: 0,
    marginTop: 2,
  }),
})

export const Flex = chakra(CKFlex, {
  baseStyle: () => ({
    flexDirection: 'column',
  }),
})

export const StatusText = chakra(Text, {
  baseStyle: (props) => {
    const styles = statusStyles[props.status] || {}
    return {
      fontWeight: 500,
      fontSize: 'md',
      lineHeight: 6,
      margin: 0,
      marginTop: 2,
      padding: '4px 8px',
      borderRadius: '4px',
      display: 'inline-block',
      ...styles,
    }
  },
  shouldForwardProp: (prop) => ['children', 'status'].includes(prop),
})

export const TypeText = chakra(Text, {
  baseStyle: (props) => {
    const styles = typeStyles[props.type] || {}
    return {
      fontWeight: 500,
      fontSize: 'md',
      lineHeight: 6,
      margin: 0,
      marginTop: 2,
      padding: '4px 8px',
      borderRadius: '4px',
      display: 'inline-block',
      ...styles,
    }
  },
  shouldForwardProp: (prop) => ['children', 'type'].includes(prop),
})
