import { useEffect, useState } from 'react'
import Header from 'components/Header'
import Sidebar from 'components/Sidebar'
import { observer } from 'mobx-react'
import { ELocalStorageKeys } from 'constants/localStorageKeys'
import { useStores } from 'utils/hooks/useStores'
import { getPageTitle, generatePageTitleInTabBar } from './utils'

import './index.scss'
import QuickAccessBubble from 'components/QuickAccessBubble'

const Layout = (props) => {
  const { component: Component, match, title, notifications, getNotificationsList, unReadFeedbackNumber } = props
  const { layoutStore } = useStores()

  const [isCollapsed, setIsCollapsed] = useState<boolean>(false)
  const [isOpenUserPanel, setIsOpenUserPanel] = useState<boolean>(false)
  const [isActiveUserPanel, setIsActiveUserPanel] = useState<boolean>(false)

  const { path } = match
  const pathElement: string[] = path.split('/')
  const action: string = pathElement[1]

  useEffect(() => {
    getNotificationsList()
    mobileScreenDetector()
  }, [])

  useEffect(() => {
    const currentPageTitle: string = getPageTitle(path)
    layoutStore.setPageTitle(currentPageTitle)
  }, [path])

  useEffect(() => {
    document.title = generatePageTitleInTabBar(title)
  }, [title])

  function mobileScreenDetector(): void {
    const isMobile: boolean = window.matchMedia('only screen and (max-width: 767px)').matches
    if (isMobile) {
      setIsCollapsed(true)
      window.localStorage.setItem(ELocalStorageKeys.IS_COLLAPSED, JSON.stringify(true))
    }
  }

  function toggleCollapsedSidebar(): void {
    setIsCollapsed(!isCollapsed)
    window.localStorage.setItem(ELocalStorageKeys.IS_COLLAPSED, JSON.stringify(!isCollapsed))
  }

  function triggerToggleUserPanel(): void {
    setIsOpenUserPanel(!isOpenUserPanel)
    setIsActiveUserPanel(!isActiveUserPanel)
  }

  return (
    <div className="app">
      <Header
        {...props}
        notifications={notifications}
        isCollapsed={isCollapsed}
        isOpenUserPanel={isOpenUserPanel}
        isActiveUserPanel={isActiveUserPanel}
        getNotificationsList={getNotificationsList}
        toggleCollapsedSidebarHandler={toggleCollapsedSidebar}
        triggerToggleUserPanelHandler={triggerToggleUserPanel}
      />
      <Sidebar
        {...props}
        isCollapsed={isCollapsed}
        toggleCollapsedSidebar={toggleCollapsedSidebar}
        actionTab={action}
        unReadFeedbackNumber={unReadFeedbackNumber}
      />
      <div className={isCollapsed ? 'collapsed app-wrapper' : 'app-wrapper'}>
        <div className="app-content-wrapper">
          <Component {...props} />
          <QuickAccessBubble currentPath={path} />
        </div>
      </div>
    </div>
  )
}

export default observer(Layout)
