import React from 'react'
import { FormControl } from '@chakra-ui/react'
import Select, { StylesConfig } from 'react-select'

interface Option {
  label: string
  value: string
}

interface ReactSelectProps {
  label: string
  options: Option[]
  disabled?: boolean
  isMulti?: boolean
  placeholder?: string
  value?: Option | Option[] | null
  onChange: (newValue: Option | Option[] | null) => void
}

const customStyles: StylesConfig<Option, false> = {
  control: (provided) => ({
    ...provided,
    borderColor: '#3182ce', // Chakra UI's blue
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#2b6cb0',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#3182ce' : state.isFocused ? '#bee3f8' : 'white',
    color: state.isSelected ? 'white' : 'black',
    '&:hover': {
      backgroundColor: '#bee3f8',
      color: 'black',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'black',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#A0AEC0', // Chakra UI's gray
  }),
}

const ReactSelect: React.FC<ReactSelectProps> = ({
  label,
  options = [],
  disabled = false,
  isMulti = false,
  placeholder = 'Select...',
  value,
  onChange,
}) => {
  const handleChange = (newValue: Option | Option[] | null) => {
    onChange(newValue)
  }

  return (
    <FormControl className="select-field">
      <label className="label-autocomplete-select">{label}</label>
      <Select
        isMulti={isMulti}
        styles={customStyles}
        onChange={handleChange}
        options={options}
        value={value}
        isClearable
        isSearchable
        placeholder={placeholder}
        isDisabled={disabled}
      />
    </FormControl>
  )
}

export default ReactSelect
