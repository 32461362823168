import { IOption } from 'constants/common'
import { ELaborContractCategories } from 'constants/enum'
import { getFormattedDateWith_DDMMYYYY } from 'utils/dateFormatUtils'
import { ILaborContractData } from '../../constant'

export function getTagBackground(value: ELaborContractCategories | IOption): string {
  switch (value) {
    case ELaborContractCategories.OFFICIAL:
      return 'blue.50'
    case ELaborContractCategories.PROBATION:
      return 'yellow.50'
    default:
      return 'none'
  }
}

export function getTagColor(value: ELaborContractCategories | IOption): string {
  switch (value) {
    case ELaborContractCategories.OFFICIAL:
      return 'blue.500'
    case ELaborContractCategories.PROBATION:
      return 'yellow.500'
    default:
      return 'none'
  }
}

export function getWorkingDate(contract: ILaborContractData): string {
  const fromDate: string = getFormattedDateWith_DDMMYYYY(contract?.workingFromDate)
  const endDate: string = getFormattedDateWith_DDMMYYYY(contract?.workingEndDate)
  return contract?.workingEndDate ? `${fromDate} - ${endDate}` : fromDate
}
