import { useEffect, useState } from 'react'
import { HStack, Text, VStack } from '@chakra-ui/react'
import { DiagramSchema } from 'beautiful-react-diagrams/@types/DiagramSchema'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { THistory } from 'types/common'
import { EDiagramLinkActiveClassNames } from 'containers/Diagram/constant'
import { isValidArray } from 'utils/commonUtils'
import { useStores } from 'utils/hooks/useStores'
import MoreOptionButton from './components/MoreOptionButton'
import SidebarProject from './components/SidebarProject'
import SidebarUser from './components/SidebarUser'
import { ISidebarInfo } from './constant'
import { getUserNodeDetail, getNodeDetailInformation } from './utils'

import './styles.scss'

interface INodeDetailProps {
  schema: DiagramSchema<unknown>
  onChange: (schema: DiagramSchema<unknown>) => void
}

const NodeDetail = (props: INodeDetailProps) => {
  const { schema, onChange } = props
  const { adminDiagramStore } = useStores()
  const { currentNodeLinks, diagramNodes, currentNode } = adminDiagramStore
  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false)
  const [userNodeDetail, setUserNodeDetail] = useState<ISidebarInfo>()
  const history: THistory = useHistory()
  const searchParams: URLSearchParams = new URLSearchParams(history.location.search)
  const isProjectDetail: boolean = currentNode?.className?.includes(EDiagramLinkActiveClassNames.PROJECT)
  const totalWorkingDaysWithoutWeekend = diagramNodes?.totalWorkingDaysWithoutWeekend
  const startDate = dayjs(diagramNodes?.startDate).format('MMM-DD')
  const endDate = dayjs(diagramNodes?.endDate).format('MMM-DD')

  const nodeDetailInfo: ISidebarInfo = getNodeDetailInformation(
    diagramNodes,
    isProjectDetail,
    currentNodeLinks,
    currentNode
  )
  const title: string = currentNode
    ? nodeDetailInfo?.title ?? currentNode?.content.toString()
    : 'Select any node to see detail'
  const isActiveSideBar: boolean = isValidArray(nodeDetailInfo?.content) || Boolean(currentNode?.content.toString())
  const isUserNodeDetail: boolean = isValidArray(userNodeDetail?.content)
  let sidebarType: string
  if (isProjectDetail) {
    sidebarType = 'project'
  } else {
    sidebarType = 'user'
  }
  let sidebarDetail: JSX.Element
  switch (sidebarType) {
    case 'project':
      sidebarDetail = (
        <SidebarProject
          projectDetail={nodeDetailInfo}
          totalWorkingDaysWithoutWeekend={totalWorkingDaysWithoutWeekend}
          startDate={startDate}
          endDate={endDate}
          schema={schema}
          onChange={onChange}
        />
      )
      break
    case 'user':
      sidebarDetail = (
        <SidebarUser
          userDetail={nodeDetailInfo}
          totalWorkingDaysWithoutWeekend={totalWorkingDaysWithoutWeekend}
          startDate={startDate}
          endDate={endDate}
          schema={schema}
          onChange={onChange}
        />
      )
      break
    default:
      sidebarDetail = <div></div>
  }

  useEffect(() => {
    const userId: string = searchParams.get('userId')
    if (userId) {
      const nodeDetail: ISidebarInfo = getUserNodeDetail(userId, diagramNodes)
      if (nodeDetail?.id) {
        setUserNodeDetail(nodeDetail)
        setIsOpenEditModal(true)
      }
    } else {
      setUserNodeDetail(null)
    }
  }, [diagramNodes, currentNode])

  return (
    <VStack alignItems="flex-start">
      <HStack
        width="300px"
        alignItems="flex-start"
        justifyContent="space-between"
        spacing={0}
        height="100%"
        borderRadius="6px"
        gap={8}
        flex={1}>
        <Text
          id={nodeDetailInfo.title || currentNode?.content.toString() ? 'sidebar-title' : ''}
          marginY={2}
          fontSize="md"
          fontWeight={400}
          fontStyle="italic"
          color="gray.500">
          {title}
        </Text>
        {(isActiveSideBar || isUserNodeDetail) && (
          <MoreOptionButton
            isProjectDetail={isProjectDetail}
            nodeDetailInformation={userNodeDetail?.id ? userNodeDetail : nodeDetailInfo}
            isOpenEditModal={isOpenEditModal}
            setIsOpenEditModal={setIsOpenEditModal}
          />
        )}
      </HStack>
      {isActiveSideBar && sidebarDetail}
    </VStack>
  )
}

export default observer(NodeDetail)
