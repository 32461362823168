import React, { useEffect, useState } from 'react'
import { VStack, Container } from '@chakra-ui/layout'
import { Flex } from '@chakra-ui/react'
import Button from 'components/Button'
import JSEditor from 'components/JSEditor'
import { observer } from 'mobx-react'
import { useStores } from 'utils/hooks/useStores'

interface IUserDataProps {
  name?: string
  disabled?: boolean
}
const UserData = (props: IUserDataProps) => {
  const { userProfileStore } = useStores()
  const [userData, setUserData] = useState(null)
  const { profileDetail } = userProfileStore
  const { name, disabled } = props
  const currentUserData: string = String(profileDetail?.userData ?? '')
  const submitHandler = () => {
    userProfileStore.updateUserData(userData)
  }
  useEffect(() => {
    if (profileDetail) {
      let data = profileDetail?.userData ?? `{}`
      setUserData(data)
    }
  }, [profileDetail])
  return (
    <Container background="white" width="full" maxWidth="unset" padding={0} borderRadius={8}>
      <Flex justifyContent={'flex-end'}>
        <Button disabled={userData === currentUserData} leftIcon="save-white.svg" onClick={submitHandler}>
          Save
        </Button>
      </Flex>
      <VStack alignItems={'initial'} paddingTop={3}>
        <JSEditor name={name} isDisable={disabled} onChange={(value) => setUserData(value)} value={userData} />
      </VStack>
    </Container>
  )
}

export default observer(UserData)
