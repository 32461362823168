import { Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, ModalCloseButton, Box } from '@chakra-ui/react'
import { borderGray200 } from 'theme/globalStyles'
import SkillAnalysisHistoryContent from '../SkillAnalysisHistoryContent'
import { ISkillAnalysisHistoryModalProps } from './interfaces'

const SkillAnalysisHistoryModal = (props: ISkillAnalysisHistoryModalProps) => {
  const { isOpen, closeHandler, skillAnalysisHistoryData = [] } = props

  return (
    <Modal onClose={closeHandler} isOpen={isOpen} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent maxHeight="690px">
        <ModalHeader borderBottom="1px solid" borderColor={borderGray200} fontWeight={500}>
          Update History
        </ModalHeader>
        <ModalCloseButton color={'gray'} background="none" cursor="pointer" mt="2" />
        <ModalBody overflowY="auto" padding="0px 10px 12px 28px">
          <Box borderLeft="2px solid" borderColor={borderGray200} height="fit-content">
            <SkillAnalysisHistoryContent skillAnalysisHistoryData={skillAnalysisHistoryData} />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default SkillAnalysisHistoryModal
