import { IProjectNameResponse } from 'API/project/constants'
import { IUserNameResponse } from 'API/user/constants'
import { capitalize } from 'lodash'
import { ICreatableOption } from 'types/creatableOption'
import { EPerformanceReportTypes, EWorkingHourPeriods } from 'constants/enum'
import { ETypeOfFilterOfHeatMap } from 'constants/enum'

export interface IStatisticFilterFormData {
  period: IOption<EWorkingHourPeriods | 'month-to-date'>
  date: Date
  selectedProject?: IOption<string>
}

export interface ISkillFilterFormData {
  defaultValues: {
    selectedProject?: IOption<string>
    selectedTitle?: IOption<string | JSX.Element>
    selectedLevel?: IOption<string | JSX.Element>
  }
  selectedProject?: IOption<string>
  selectedTitle?: IOption<string | JSX.Element>
  selectedLevel?: IOption<string | JSX.Element>
}

export interface ISkillSummaryFilter {
  project?: string
  title?: string
  level?: string
  page?: number
  perPage?: number
}

export interface IHeatmapFilterFormData {
  typeOfFilter: IOption<string>
  selectedProject?: IOption<string>
  selectedUser?: IOption<string>
}

export interface IStatisticFilterFormProps {
  projectNameList: IProjectNameResponse[]
  viewAnalyticHandler?: (data: IStatisticFilterFormData) => void
  isLoading?: boolean
}

export interface IStatisticFilterFormForAdminProps {
  projectOptions: IOption[]
  viewAnalyticHandler?: (data: IStatisticFilterFormData) => void
  isLoading?: boolean
}

export interface ISkillFilterFormProps {
  filter: ISkillSummaryFilter
  creatableOptionList: ICreatableOption[]
  projectNameList: IProjectNameResponse[]
  submitHandler?: (data: ISkillFilterFormData) => void
  isLoading?: boolean
}

export interface IHeatmapFilterFormProps {
  projectNameList: IProjectNameResponse[]
  userNameList: IUserNameResponse[]
  viewAnalyticHandler?: (data: IHeatmapFilterFormData) => void
  isSupervisor?: boolean
}

export interface IOption<T = string> {
  label: string
  value: T
}

export const typeOfFilterOfHeadMap: IOption<ETypeOfFilterOfHeatMap>[] = [
  { label: capitalize(ETypeOfFilterOfHeatMap.FILTER_BY_PROJECT), value: ETypeOfFilterOfHeatMap.FILTER_BY_PROJECT },
  { label: capitalize(ETypeOfFilterOfHeatMap.FILTER_BY_USER), value: ETypeOfFilterOfHeatMap.FILTER_BY_USER },
]

export const workingPeriodOptionsOfStatistic: IOption<EWorkingHourPeriods | 'month-to-date'>[] = [
  { label: capitalize(EWorkingHourPeriods.MONTH), value: EWorkingHourPeriods.MONTH },
]

export const workingPeriodOptions: IOption<EWorkingHourPeriods | 'month-to-date'>[] = [
  { label: capitalize(EWorkingHourPeriods.WEEK), value: EWorkingHourPeriods.WEEK },
  { label: capitalize(EWorkingHourPeriods.MONTH), value: EWorkingHourPeriods.MONTH },
  { label: 'Month to date', value: 'month-to-date' },
]

export const performanceReportTypeOptions: IOption<EPerformanceReportTypes>[] = [
  { label: 'Logged Time (hrs)', value: EPerformanceReportTypes.LOGGED_TIME },
  { label: 'Salary (VND)', value: EPerformanceReportTypes.SALARY },
]

export const defaultProjectColors: string[] = [
  '#08C8F6',
  '#4D5DFB',
  '#38B2AC',
  '#ECC94B',
  '#ED8936',
  '#CBD5E0',
  '#4299E1',
  '#9F7AEA',
  '#ED64A6',
]

export const nivoTheme: any = {
  textColor: '#2D3748',
  fontSize: 12,
  axis: {
    ticks: {
      text: {
        color: '#2D3748',
        fontSize: 12,
      },
    },
  },
  labels: {
    text: {
      fill: '#2D3748',
    },
  },
}

export const skillSummaryDataTable = [
  {
    Header: 'DEVELOPER',
    accessor: 'fullName',
  },
  {
    Header: 'TITLE',
    accessor: 'title',
  },
  {
    Header: 'LEVEL',
    accessor: 'level',
  },
  {
    Header: 'SKILLS',
    columns: [
      {
        Header: 'ATTITUDE WORKING',
        accessor: 'workingAttitude',
      },
      {
        Header: 'Technical Skills',
        accessor: 'technicalSkills',
      },
      {
        Header: 'MEET DEADLINES',
        accessor: 'meetDeadlines',
      },
      {
        Header: 'PROBLEM SOLVING',
        accessor: 'problemSolving',
      },
      {
        Header: 'COMMUNICATION',
        accessor: 'communication',
      },
      {
        Header: 'TEAM PLAYER',
        accessor: 'teamPlayer',
      },
      {
        Header: 'ENGLISH',
        accessor: 'english',
      },
    ],
  },
]
