import { Container, HStack, Text, useMediaQuery, Box } from '@chakra-ui/react'
import Button from 'components/Button'
import MoreDropdown from 'components/Table/DesktopTable/components/MoreDropdown'
import StatusCell from 'components/Table/DesktopTable/components/StatusCell/index'
import { boxShadow } from 'theme/globalStyles'
import { IRowActionItem } from 'types/table'
import { maxMobileSize } from 'constants/common'

interface IHeaderSectionProps {
  fullName: string
  status?: string
  isEdit: boolean
  isDisabledOptions: boolean
  handleEdit: () => void
  handleCancel: () => void
  actions: IRowActionItem[]
}

const HeaderSection = (props: IHeaderSectionProps) => {
  const { fullName, isEdit, handleEdit, handleCancel, actions, status = '' } = props
  const [isMobile] = useMediaQuery(maxMobileSize)

  return (
    <Container maxWidth="full" padding={0}>
      {isMobile ? (
        <HStack justify="space-between" margin="16px 16px 0px 16px">
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Text fontSize="lg" fontWeight={500} lineHeight={7} color="gray.700" margin={0} paddingRight={4}>
              {fullName}
            </Text>
            <StatusCell status={status}>{status}</StatusCell>
          </Box>
          {actions.length !== 0 && (
            <Box>
              {!isEdit ? (
                <HStack>
                  <Button variant="solid" leftIcon="edit-white.svg" leftIconSize={16} onClick={handleEdit}></Button>
                  <Box
                    background="white"
                    height={10}
                    borderRadius={6}
                    display="flex"
                    alignItems="center"
                    paddingRight={'12px'}
                    paddingLeft={'4px'}>
                    {actions.length !== 0 && <MoreDropdown actions={actions} />}
                  </Box>
                </HStack>
              ) : (
                <Button type="submit" variant="solid">
                  Save
                </Button>
              )}
            </Box>
          )}
        </HStack>
      ) : (
        <HStack
          paddingX={6}
          paddingY={4}
          borderRadius={6}
          background="white"
          justifyContent="space-between"
          boxShadow={boxShadow}>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Text
              fontSize="lg"
              color="gray.700"
              margin={0}
              paddingRight={4}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis">
              {fullName}
            </Text>
            <StatusCell status={status}>{status}</StatusCell>
          </Box>
          {actions.length !== 0 && (
            <Box>
              {!isEdit ? (
                <HStack>
                  <Button variant="solid" leftIcon="edit-white.svg" onClick={handleEdit} leftIconSize={16}>
                    Edit User
                  </Button>
                  {actions.length !== 0 && <MoreDropdown isBackground actions={actions} />}
                </HStack>
              ) : (
                <HStack>
                  <Button variant="outline" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button type="submit" variant="solid">
                    Save
                  </Button>
                </HStack>
              )}
            </Box>
          )}
        </HStack>
      )}
    </Container>
  )
}

export default HeaderSection
