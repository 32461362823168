export const policy = {
  // Policy Page
  value: '/policy',
  list: {
    value: '/policy/list',
  },
  create: {
    value: '/policy/create',
  },
  update: {
    value: '/policy/update',
    policyId: {
      value: (policyId: string) => `/policy/update/${policyId}`,
    },
  },
  delete: {
    value: '/policy/delete',
    policyId: {
      value: (policyId: string) => `/policy/delete/${policyId}`,
    },
  },
}
