import React from 'react'
import { Text, VStack } from '@chakra-ui/react'
import { ReactComponent as Empty } from 'assets/images/svg/empty.svg'

interface IEmptyContentBoxProps {
  text: string
  position?: 'top' | 'bottom'
  height?: string
}
const EmptyContentBox = (props: IEmptyContentBoxProps) => {
  const { text, position = 'top', height = '300px' } = props
  return (
    <VStack
      backgroundColor="white"
      height={height}
      paddingBottom={{ sm: '10px' }}
      spacing="72px"
      align="center"
      justifyContent="center"
      width="full">
      {position === 'top' && (
        <Text as={'i'} color={'gray.600'}>
          {text}
        </Text>
      )}
      <Empty />
      {position === 'bottom' && (
        <Text as={'i'} color={'gray.600'}>
          {text}
        </Text>
      )}
    </VStack>
  )
}

export default EmptyContentBox
