import forEach from 'lodash/forEach'
import moment from 'moment'
import { EStatusOfLateLoggingTime, EStatusOfDuration } from 'constants/enum'
import { getIndicesOf, insertString } from 'utils/commonUtils'

export function handleUpdateDescription(description: string) {
  const imageIndexes = getIndicesOf('<img', description, true)
  let offset = 0
  let newDescription = description

  forEach(imageIndexes, (imageIndex) => {
    newDescription = insertString(newDescription, imageIndex + 4 + offset, ' width="500"')
    offset += ' width="500"'.length
  })
  return newDescription
}

export function calculateLateLoggingTime(timesheet) {
  const endMoment = moment(timesheet?.createdAt)
  const startMoment = moment(timesheet?.startTime)
  const diff = endMoment.diff(startMoment, 'days')
  let status = EStatusOfLateLoggingTime.NORMAL
  if (diff > 9) {
    status = EStatusOfLateLoggingTime.EMERGENCY
  }

  if (diff > 4) {
    status = EStatusOfLateLoggingTime.WARNING
  }

  return {
    status,
    delayDate: diff,
  }
}

export function formatDurationRow(duration) {
  let result = EStatusOfDuration.NORMAL
  if (3 <= duration && duration < 6) {
    result = EStatusOfDuration.WARNING
  }
  if (6 <= duration) {
    result = EStatusOfDuration.EMERGENCY
  }
  return result
}
