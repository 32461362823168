import { HStack, Text } from '@chakra-ui/layout'
import { useFormContext, useWatch } from 'react-hook-form'
import { IProjectItem } from '../../../Dropdown/DropdownButton'

const BudgetRemainSection = () => {
  const { control } = useFormContext()
  const projectWatcher: IProjectItem = useWatch({
    control,
    name: 'project',
  })
  const budgetRemain: number = projectWatcher?.budgetRemain ?? 0
  const formatBudgetRemain: string = new Intl.NumberFormat().format(budgetRemain)
  const currencyName: string = projectWatcher?.currencyName ?? ''

  return (
    <HStack justifyContent="flex-end">
      <Text fontWeight="medium">
        Budget Remain: {formatBudgetRemain} {currencyName}
      </Text>
    </HStack>
  )
}

export default BudgetRemainSection
