import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Box,
  Checkbox,
} from '@chakra-ui/react'
import Button from 'components/Button'
import Table from 'components/Table/CustomExpandedTable'
import { observer } from 'mobx-react'
import { FormProvider, useForm } from 'react-hook-form'
import { Messages } from 'constants/index'
import { getValidArray } from 'utils/commonUtils'
import { getFormattedDateWith_YYYYMMDD } from 'utils/dateFormatUtils'
import { useStores } from 'utils/hooks/useStores'
interface IUnarchiveUserModalProps {
  isOpen?: boolean
  content?: string
  closeHandler?: () => void
  actionType?: string
  saveHandler?: (itemArray: string[]) => void
  isSubmitSuccess?: boolean
}

const UnarchiveUserModal = (props: IUnarchiveUserModalProps) => {
  const { isOpen, closeHandler, saveHandler } = props
  const { adminProjectStore } = useStores()
  const { projectsList } = adminProjectStore
  const [selectedUserIds, setSelectedUserIds] = useState([])
  const methods = useForm()

  const tableData = getValidArray(projectsList).map((data) => {
    return {
      ...data,
      applyDate: data.applyDate ? getFormattedDateWith_YYYYMMDD(data.applyDate) : '',
    }
  })

  const subTable = React.useCallback(({ row }) => {
    return (
      <Box backgroundColor={'blue.50'} padding={'16px'} width={'inherit'}>
        <Text fontWeight={'bold'}>More Information</Text>
        <Box>
          {row.original.paymentCategory && <Text>Payment category: {row.original.paymentCategory}</Text>}
          <Text>Apply date of project rate: {row.original.applyDate}</Text>
        </Box>
      </Box>
    )
  }, [])

  function selectedItemHandler(isChecked: boolean, itemId: string) {
    if (isChecked) {
      setSelectedUserIds((prevState) => [...prevState, itemId])
    } else {
      setSelectedUserIds((prevState) => prevState.filter((id) => id !== itemId))
    }
  }

  function getSelectedUserListHeader() {
    const headers = [
      {
        Header: 'CLICK TO SELECT PROJECT',
        id: 'checkbox',
        Cell: ({ row }) => {
          const isChecked = selectedUserIds.includes(row.original.id)
          return (
            <Checkbox
              key={row.original.id}
              colorScheme="green"
              borderColor={'gray.400'}
              isChecked={isChecked}
              onChange={(e) => selectedItemHandler(e.target.checked, row.original.id)}
            />
          )
        },
      },
      {
        Header: 'PROJECT',
        accessor: 'name',
        Cell: ({ row }) => <span {...row.getToggleRowExpandedProps()}>{row.original?.name}</span>,
      },
      {
        Header: 'PROJECT RATE',
        Cell: ({ row }) => row.original.hourlyRate || row.original.fixedRate || '-',
      },
      {
        Header: 'CLIENT RATE',
        accessor: 'clientRate',
        Cell: ({ value }) => value || '-',
      },
    ]
    return headers
  }

  const expandedRowObj = {
    expanded: {},
  }

  return (
    <Modal isOpen={isOpen} onClose={closeHandler} isCentered size="3xl">
      <ModalOverlay />
      <FormProvider {...methods}>
        <form className="full-form">
          <ModalContent maxHeight="550px">
            <ModalHeader>Unarchive User</ModalHeader>
            <ModalCloseButton color={'gray'} background="none" />
            <ModalBody paddingY={0}>
              <Text>{Messages.unarchivedUserGuide}</Text>
              <Table
                tableData={tableData}
                headerList={getSelectedUserListHeader()}
                subComponent={subTable}
                expandRows={true}
                expandedRowObj={[expandedRowObj]}
              />
            </ModalBody>
            <ModalFooter>
              <Button variant="outline" customStyles={{ marginRight: '12px' }} onClick={closeHandler}>
                Cancel
              </Button>
              <Button onClick={() => saveHandler(selectedUserIds)} colorScheme="red">
                Next
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default observer(UnarchiveUserModal)
