import { useRef } from 'react'
import { Td, Tr } from '@chakra-ui/react'
import Icon from 'components/Icon'
import { useDrag, useDrop } from 'react-dnd'
import { Cell as ICell } from 'react-table'
import Cell from './components/Cell'

const DND_ITEM_TYPE = 'row'

const Row = ({ row, index, moveRow }) => {
  const dropRef = useRef(null)
  const dragRef = useRef(null)

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item: { index: number }, monitor) {
      if (!dropRef.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = dropRef.current.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      moveRow(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag, preview] = useDrag({
    type: DND_ITEM_TYPE,
    canDrag: true,
    item: () => {
      document.body.classList.add('dragging')
      return { type: DND_ITEM_TYPE, index }
    },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    end: () => {
      document.body.classList.remove('dragging')
    },
  })
  preview(drop(dropRef))
  drag(dragRef)

  return (
    <Tr ref={dropRef} opacity={isDragging ? 0.5 : 1}>
      <Td ref={dragRef} py={['3px', 2]} px={['2px', 2]} cursor={'grab'}>
        <Icon iconName="hamburger.svg" />
      </Td>
      {row.cells.map((cell: ICell, _index: number) => (
        <Cell key={`cell${_index}`} cell={cell} />
      ))}
    </Tr>
  )
}

export default Row
