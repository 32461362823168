import apiV2 from 'API/api'
import { APIRouters } from 'API/APIRouters'
import { AxiosResponse } from 'axios'
import {
  ITaskTypeListResponse,
  ITaskType,
  ITaskTypeListPaginationRequest,
  ITaskTypeDataFromCreateForm,
} from './constants'

export async function getTaskTypeListAPI(): Promise<ITaskTypeListResponse> {
  try {
    const url: string = APIRouters.taskType.list.value
    const response: AxiosResponse = await apiV2.get(url)
    return {
      taskTypes: response?.data?.taskTypes || [],
      count: response?.data?.count || 0,
    }
  } catch (error) {
    throw error
  }
}

export async function getTaskTypeListWithPaginationAPI(
  payload: ITaskTypeListPaginationRequest
): Promise<ITaskTypeListResponse> {
  try {
    const url: string = APIRouters.taskType.list.value
    const response: AxiosResponse = await apiV2.post(url, payload)
    return {
      taskTypes: response?.data?.taskTypes || [],
      count: response?.data?.count || 0,
    }
  } catch (error) {
    throw error
  }
}

export async function createTaskTypeAPI(taskType: ITaskTypeDataFromCreateForm): Promise<string> {
  try {
    const url: string = APIRouters.taskType.value
    const response: AxiosResponse = await apiV2.post(url, taskType)
    return response?.data?.message || ''
  } catch (error) {
    throw error
  }
}

export async function updateTaskTypeAPI(taskType: ITaskType): Promise<string> {
  try {
    const url: string = APIRouters.taskType.taskTypeId.value(taskType.id)
    const response: AxiosResponse = await apiV2.patch(url, taskType)
    return response?.data?.message || ''
  } catch (error) {
    throw error
  }
}

export async function deleteTaskTypeAPI(taskTypeId: string): Promise<string> {
  try {
    const url: string = APIRouters.taskType.taskTypeId.value(taskTypeId)
    const response: AxiosResponse = await apiV2.delete(url)
    return response?.data?.message || ''
  } catch (error) {
    throw error
  }
}
