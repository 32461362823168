import { auth } from './auth.router'
import { contract } from './contract.router'
import { country } from './country.router'
import { creatableOption } from './creatableOption.router'
import { currency } from './currency.router'
import { diagramNode } from './diagramNode.router'
import { fcm } from './fcm.router'
import { fixedProjectRate } from './fixedProjectRate.router'
import { guest } from './guest.router'
import { hourlyProjectRate } from './hourlyProjectRate.router'
import { information } from './information.router'
import { internalDocument } from './internalDocument.router'
import { longPolling } from './longPolling.router'
import { notification } from './notification.router'
import { partner } from './partner.router'
import { payment } from './payment.router'
import { policy } from './policy.router'
import { project } from './project.router'
import { projectType } from './projectType.router'
import { report } from './report.router'
import { skill } from './skill.router'
import { summarize } from './summarize.router'
import { taskType } from './taskType.router'
import { technology } from './technology.router'
import { timesheet } from './timesheet.router'
import { todoOfHR } from './todoOfHR.router'
import { upload } from './upload.router'
import { user } from './user.router'

// This router for Backend V2 (With Loopback)
export const APIRouters = {
  auth,
  summarize,
  contract,
  policy,
  todoOfHR,
  information,
  technology,
  projectType,
  creatableOption,
  currency,
  country,
  taskType,
  user,
  guest,
  partner,
  internalDocument,
  notification,
  fcm,
  upload,
  hourlyProjectRate,
  fixedProjectRate,
  longPolling,
  project,
  payment,
  diagramNode,
  timesheet,
  report,
  skill,
}
