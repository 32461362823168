import { ITechnology } from 'types/technology'
import { IOption } from 'constants/common'
import { IPartner } from 'constants/schema'
import { getValidArray, getDisplayName } from 'utils/commonUtils'

const isPartnerIncluded = (partner, partners) => !partners.includes(String(partner?._id ?? ''))

export function getPartnerOptionSelect(partnerList?: IPartner[]): IOption[] {
  let partnerOptions: IOption[] = []
  let partners: string[] = []
  getValidArray(partnerList).forEach((partner: IPartner) => {
    const name = getDisplayName(partner)
    const category = partner?.category
    const label = `${name} | ${category}`

    if (isPartnerIncluded(partner, partners) && name && category) {
      partners = partners.concat(String(partner?.id))
      partnerOptions = [
        ...partnerOptions,
        {
          value: String(partner?.id),
          label: label,
        },
      ]
    }
  })
  return partnerOptions
}

export function getTechnologyOptionSelect(technologyList?: ITechnology[]): IOption[] {
  let technologyOptions: IOption[] = []
  let technologies: string[] = []
  getValidArray(technologyList).forEach((technology: ITechnology) => {
    if (!technologies.includes(String(technology?.id ?? '')) && technology?.value) {
      technologies = technologies.concat(String(technology?.id))
      technologyOptions = [
        ...technologyOptions,
        {
          value: String(technology?.id),
          label: technology?.value,
        },
      ]
    }
  })
  return technologyOptions
}
