import { Text, VStack, SimpleGrid, Container, Divider, useMediaQuery } from '@chakra-ui/react'
import InputTag from 'components/InputTag'
import LabelForm from 'components/LabelForm'
import { generatePath, useHistory } from 'react-router-dom'
import { IOption } from 'constants/common'
import { maxMobileSize } from 'constants/common'
import { EYesNoOption } from 'constants/enum'
import { frontendRoutes } from 'constants/routes'
import { getValidArray } from 'utils/commonUtils'
import { IDetailUserFormProps } from './interface'

const DetailUserForm = (props: IDetailUserFormProps) => {
  const { methods, name, isEdit } = props
  const history = useHistory()
  const { getValues } = methods
  const technologies = getValues(`${name}.technologies`)
  const projectsOfUser = getValues(`${name}.projects`)
  const extraProjects: IOption[] = getValues(`${name}.extraProjects`)
  const externalProjects: IOption[] = getValues(`${name}.externalProjects`)
  const projectsOfSupervisor: IOption[] = getValues(`${name}.projectsOfSupervisor`)
  const isSupervisor: string = getValues(`${name}.isSupervisor`)
  const [isMobile] = useMediaQuery(maxMobileSize)

  function onProjectClick(id: string): void {
    const pathname: string = generatePath(frontendRoutes.projectPage.projectAdmin.detail.id.value, { id: id })
    history.push(pathname)
  }
  if (isEdit) {
    return null
  }

  return (
    <Container id="UserProfileDetail" background="white" width="full" maxWidth="unset" padding={0} borderRadius={8}>
      <VStack
        alignItems="initial"
        margin={{ base: 6, lg: 6 }}
        marginTop={{ base: 0, lg: 0 }}
        height="full"
        gap={{ base: 0, md: 6, lg: 6 }}>
        <Text fontWeight={500} marginBottom={0} lineHeight={7} fontSize="lg">
          User Profile Detail
        </Text>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 0, md: 8, lg: 8 }}>
          <LabelForm name={`${name}.partnerId`} label="Partner" readonly={true} isHighligh />
          <LabelForm name={`${name}.displayName`} label="Display Name" readonly={true} />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 0, md: 8, lg: 8 }}>
          <LabelForm name={`${name}.email`} label="Email" readonly={true} isHighligh />
          <LabelForm name={`${name}.phone`} label="Phone Number" readonly={true} isHighligh />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 0, md: 8, lg: 8 }}>
          <LabelForm name={`${name}.bankName`} label="Bank Name" readonly={true} type="textarea" />
          <LabelForm name={`${name}.bankAccount`} label="Bank Account" readonly={true} />
        </SimpleGrid>
        {!isMobile && <Divider color={'gray/200'} />}
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 0, md: 8, lg: 8 }}>
          <LabelForm name={`${name}.fullName`} label="Full Name" readonly={true} />
          <LabelForm name={`${name}.gender`} label="Gender" readonly />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 0, md: 8, lg: 8 }}>
          <LabelForm name={`${name}.placeOfBirth`} label="Place Of Origin" readonly />
          <LabelForm name={`${name}.dateOfBirth`} label="Date of Birth" readonly />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 0, md: 8, lg: 8 }}>
          <LabelForm name={`${name}.address`} label="Place of Residence" readonly />
          <LabelForm name={`${name}.nationality`} label="Nationality" readonly />
        </SimpleGrid>
        <SimpleGrid columns={1}>
          <LabelForm name={`${name}.IDInformation`} label="ID Information" readonly />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 0, md: 8, lg: 8 }}>
          <LabelForm name={`${name}.education`} label="Education" readonly />
          <LabelForm name={`${name}.major`} label="Major" readonly />
        </SimpleGrid>
        {!isMobile && <Divider color={'gray/200'} />}
        <SimpleGrid columns={{ base: 2, md: 2, lg: 2 }} gap={{ base: 0, md: 8, lg: 8 }}>
          <LabelForm name={`${name}.joinDate`} label="Join Date" type="date" readonly={true} />
          <LabelForm name={`${name}.role`} label="Role" readonly={true} />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 2, md: 2, lg: 2 }} gap={{ base: 0, md: 8, lg: 8 }}>
          <LabelForm name={`${name}.title`} label="Title" readonly={true} isBadgeImage={true} />
          <LabelForm name={`${name}.level`} label="Level" readonly={true} isBadgeImage={true} />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 2, md: 2, lg: 2 }} gap={{ base: 0, md: 8, lg: 8 }}>
          <LabelForm name={`${name}.isSupervisor`} label="Is Supervisor ?" readonly={true} />
          {isSupervisor?.toLowerCase() === EYesNoOption.YES && (
            <InputTag data={getValidArray<IOption>(projectsOfSupervisor)} label="Supervisor of Projects" />
          )}
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 0, md: 8, lg: 8 }}>
          <LabelForm name={`${name}.paymentCategoryId`} label="Payment Category" readonly={true} />
          <LabelForm name={`${name}.allowToDownloadCV`} label="Can Does Guest Download CV ?" readonly={true} />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 2, md: 2, lg: 2 }} gap={{ base: 0, lg: 8 }} width="full">
          <LabelForm name={`${name}.fixedSalary`} label="Net Salary (VND)" readonly={true} isSalary={true} />
          <LabelForm name={`${name}.defaultRating`} label="Default Rate" readonly={true} />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 1, lg: 1 }} gap={{ base: 0, md: 8, lg: 8 }} width="full" marginTop={2}>
          <InputTag data={getValidArray<IOption>(technologies)} label="Technologies" />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 1, lg: 1 }} gap={{ base: 0, md: 8, lg: 8 }} width="full" marginTop={2}>
          <InputTag data={getValidArray<IOption>(projectsOfUser)} label="Projects" handleOnClick={onProjectClick} />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 1 }} gap={{ base: 6, md: 8 }} width="full" marginTop={2}>
          <InputTag
            data={getValidArray<IOption>(extraProjects)}
            label="Extra Projects"
            handleOnClick={onProjectClick}
          />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 1 }} gap={{ base: 6, md: 8 }} width="full" marginTop={2}>
          <InputTag
            data={getValidArray<IOption>(externalProjects)}
            label="External Projects"
            handleOnClick={onProjectClick}
          />
        </SimpleGrid>
      </VStack>
    </Container>
  )
}
export default DetailUserForm
