import { useContext } from 'react'
import { TimeIcon } from '@chakra-ui/icons'
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  InputGroup,
  InputRightElement,
  RadioGroup,
  VStack,
} from '@chakra-ui/react'
import 'react-quill/dist/quill.snow.css'
import DatePicker from 'components/DatePicker'
import SingleSelect from 'components/SingleSelect'
import dayjs from 'dayjs'
import { useFormContext } from 'react-hook-form'
import { NumberFormatValues } from 'react-number-format'
import { HOUR_MINUTE_PATTERN } from 'constants/regexp'
import FormInputWithMask from '../../../FormInputWithMask'
import { ETimeSheetMode, ITimesheetModalContext } from '../../constants'
import { TimesheetModalContext } from '../../context/timesheetModal.context'
import '../../styles.scss'
import { getDateTimeFromTimeInput } from '../../utils'

const TimeInfoSection = () => {
  const timesheetModalContext: ITimesheetModalContext = useContext(TimesheetModalContext)
  const { startDate, setStartDate, endDate, setEndDate, timesheetMode, setTimesheetMode, startTime, totalTimeOptions } =
    timesheetModalContext
  const {
    setValue: setFormValue,
    formState: { errors },
  } = useFormContext()

  const isHideEndTimeInput: boolean = dayjs(startDate).isSame(endDate, 'day')

  function onChangeTimesheetMode(currentMode: string): void {
    setTimesheetMode(currentMode as ETimeSheetMode)
  }

  function onStartTimeChange(data: NumberFormatValues): void {
    const isValidTime: boolean = HOUR_MINUTE_PATTERN.test(data.formattedValue)
    if (isValidTime) {
      const currentStartDate: Date = getDateTimeFromTimeInput(data, startDate)

      setFormValue('startDate', currentStartDate)
      setStartDate(currentStartDate)
    }
  }

  return (
    <>
      <HStack>
        <FormControl>
          <FormLabel fontSize="md" fontWeight="400">
            Start Date
          </FormLabel>
          <DatePicker selectedDate={startDate} onChange={setStartDate} />
        </FormControl>
        {!isHideEndTimeInput && (
          <FormControl>
            <FormLabel fontSize="md" fontWeight="400">
              End Date
            </FormLabel>
            <DatePicker selectedDate={endDate} onChange={setEndDate} />
          </FormControl>
        )}
      </HStack>
      <FormControl>
        <RadioGroup onChange={onChangeTimesheetMode} value={timesheetMode}>
          <VStack alignItems="flex-start">
            <HStack
              height={10}
              marginTop="20px !important"
              width="100%"
              marginBottom={Boolean(errors?.startTime?.message) ? '15px' : '0'}>
              {/* *INFO: enable when integrating the shift feature */}
              {/* <Radio value={ETimeSheetMode.MANUAL} size="sm" mr={2}></Radio> */}
              <HStack width="50%">
                <FormControl isInvalid={Boolean(errors?.startTime)}>
                  <InputGroup>
                    <FormInputWithMask
                      name="startTime"
                      label="Start time"
                      placeholder="HH:mm"
                      format="##:##"
                      mask={['H']}
                      defaultValue={startTime}
                      onValueChange={onStartTimeChange}
                    />
                    <InputRightElement
                      width="40px"
                      height="40px"
                      top="0px"
                      children={<TimeIcon color="rgba(0, 0, 0, 0.54)" width={6} />}
                    />
                  </InputGroup>
                  <FormErrorMessage position="absolute">{errors?.startTime?.message}</FormErrorMessage>
                </FormControl>
              </HStack>
              <HStack width="50%">
                <SingleSelect
                  name="timeTotal"
                  optionsData={totalTimeOptions}
                  isClearable={false}
                  isRequire
                  placeHolder="Choose time total"
                />
              </HStack>
            </HStack>
            {/* *INFO: enable when integrating the shift feature */}
            {/* <HStack h={10} w="100%" mt="16px !important">
              <Radio value={ETimeSheetMode.SHIFT} size="sm" isDisabled mr={2}></Radio>
              <CkSelect
                w="46%"
                isDisabled
                h={10}
                border="1px solid #E2E8F0"
                borderRadius="6px"
                _focus={{
                  boxShadow: 'none',
                }}
                _disabled={{
                  border: '1px solid #E2E8F0',
                }}>
                <option value="morning">Morning Shift</option>
              </CkSelect>
            </HStack> */}
          </VStack>
        </RadioGroup>
      </FormControl>
    </>
  )
}

export default TimeInfoSection
