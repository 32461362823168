import apiV2 from 'API/api'
import { APIRouters } from 'API/APIRouters'
import { AxiosResponse } from 'axios'
import { IAnnouncementGroupsByMonth, IAnnouncementGroupsByMonthAdminRequest } from 'types/announcement'
import { IInformation, ICreateInformationResponse } from './constants'

export async function createAnnouncementAPI(payload: IInformation): Promise<ICreateInformationResponse> {
  try {
    const url: string = APIRouters.information.create.value
    const response: AxiosResponse = await apiV2.post(url, payload)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function getAnnouncementGroupsByMonthAdminAPI(
  payload: IAnnouncementGroupsByMonthAdminRequest
): Promise<IAnnouncementGroupsByMonth[]> {
  try {
    const url = APIRouters.information.list.value
    const response: AxiosResponse = await apiV2.post(url, payload)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function getAnnouncementGroupsByMonthUserAPI(): Promise<IAnnouncementGroupsByMonth[]> {
  try {
    const url = APIRouters.information.list.value
    const response: AxiosResponse = await apiV2.get(url)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function updateAnnouncementAPI(payload: IInformation, informationId: string): Promise<void> {
  try {
    await apiV2.post(APIRouters.information.update.informationId.value(informationId), payload)
  } catch (error) {
    throw error
  }
}

export async function deleteAnnouncementAPI(informationId: string): Promise<void> {
  try {
    await apiV2.post(APIRouters.information.delete.informationId.value(informationId))
  } catch (error) {
    throw error
  }
}
