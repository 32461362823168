import {
  createCreatableOptionAPI,
  getCreatableOptionListWithPaginationAPI,
  deleteCreatableOptionAPI,
  updateCreatableOptionAPI,
  updateCreatableOptionsAPI,
} from 'API/creatableOption'
import { ICreatableOption, ICreatableOptionDataFromCreateForm } from 'API/creatableOption/constants'
import { handleError } from 'API/error'
import get from 'lodash/get'
import { makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'
import { ECreatableOptionTypes } from 'constants/enum'
import RootStore from '../rootStore'

class AdminCreatableOptionStore {
  rootStore: RootStore
  creatableOptionList: ICreatableOption[] = []
  titleList: ICreatableOption[] = []
  levelList: ICreatableOption[] = []
  documentTypeList: ICreatableOption[] = []
  listCount: number = 0
  isRefresh: boolean = false
  isLoadingCreatableOptionList: boolean = false

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public async getCreatableOptionListWithPagination(isReset?: boolean, history?: any, filter?: any): Promise<void> {
    try {
      const filterType = filter.type
      this.isLoadingCreatableOptionList = true
      const page: number = isReset ? 1 : Number(get(history, 'location.state.page') || 1)
      const payload = {
        page,
        pageSize: 10,
        filter,
      }
      const { creatableOptions, count } = await getCreatableOptionListWithPaginationAPI(payload)
      const creatableOptionListData = creatableOptions.sort(
        (previous, current) => (previous?.order || 0) - (current?.order || 0)
      )
      this.creatableOptionList = creatableOptionListData
      switch (filterType) {
        case ECreatableOptionTypes.TITLE_OF_USER: {
          this.titleList = creatableOptionListData
          break
        }
        case ECreatableOptionTypes.LEVEL_OF_USER: {
          this.levelList = creatableOptionListData
          break
        }
        case ECreatableOptionTypes.DOCTYPE_OF_INTERNAL_DOCS: {
          this.documentTypeList = creatableOptionListData
          break
        }
      }
      this.listCount = count
      this.isRefresh = false
    } catch (error) {
      handleError(error, 'src/store/admin/AdminCreatableOptionStore.ts', 'getCreatableOptionListWithPagination')
    } finally {
      this.isLoadingCreatableOptionList = false
    }
  }

  public async createCreatableOption(creatableOption: ICreatableOptionDataFromCreateForm): Promise<void> {
    try {
      const message = await createCreatableOptionAPI(creatableOption)
      toast.success(message)
      this.isRefresh = true
    } catch (error) {
      handleError(error, 'src/store/admin/AdminCreatableOptionStore.ts', 'createCreatableOption', false, true)
    }
  }

  public async updateCreatableOption(creatableOption: ICreatableOption): Promise<void> {
    try {
      const message = await updateCreatableOptionAPI(creatableOption)
      toast.success(message)
      this.isRefresh = true
    } catch (error) {
      handleError(error, 'src/store/admin/AdminCreatableOptionStore.ts', 'updateCreatableOption', false, true)
    }
  }

  public async updateCreatableOptions(creatableOptions: ICreatableOption[]): Promise<void> {
    try {
      this.creatableOptionList = creatableOptions
      await updateCreatableOptionsAPI(creatableOptions)
    } catch (error) {
      handleError(error, 'src/store/admin/AdminCreatableOptionStore.ts', 'updateCreatableOptions', false)
      this.isRefresh = true
    }
  }

  public async deleteCreatableOption(creatableOptionId: string, type?: string): Promise<void> {
    try {
      const message = await deleteCreatableOptionAPI(creatableOptionId, type)
      toast.success(message)
      this.isRefresh = true
    } catch (error) {
      handleError(error, 'src/store/admin/AdminCreatableOptionStore.ts', 'deleteCreatableOption', false, true)
    }
  }
}

export default AdminCreatableOptionStore
