import {
  Divider,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
  VStack,
  SimpleGrid,
} from '@chakra-ui/react'
import { backgroundGrey200 } from 'theme/globalStyles'
import { ILaborContractData } from 'containers/User/UserDetail/constant'
import { MobileTag } from 'containers/User/UserDetail/userDetail.styles'
import { getFormattedDateWith_DDMMYYYY } from 'utils/dateFormatUtils'
import FormView from '../../../FormView'
import { getTagColor, getTagBackground, getWorkingDate } from '../../utils'

interface IContractDetailProps {
  contract: ILaborContractData
  isOpen: boolean
  handleOnClose: () => void
}

const ContractDetail = (props: IContractDetailProps) => {
  const { contract, isOpen, handleOnClose } = props
  const contractTerm: string = contract?.laborContractTerm ? String(contract?.laborContractTerm) : ''

  return (
    <Drawer placement="bottom" isOpen={isOpen} onClose={handleOnClose}>
      <DrawerOverlay />
      <DrawerContent maxHeight="80vh" borderTopRadius={6}>
        <DrawerHeader
          fontSize="lg"
          fontWeight={500}
          lineHeight={7}
          padding={4}
          borderBottom={`1px solid ${backgroundGrey200}`}>
          Contract Detail
        </DrawerHeader>
        <DrawerCloseButton cursor="pointer" color="gray" background="none" />
        <DrawerBody padding="16px 16px 40px">
          <VStack spacing={6} alignItems="flex-start">
            <FormView label="Date" value={getWorkingDate(contract)} />
            <FormView label="Published Date" value={contract?.publishedDate} />
            <SimpleGrid columns={2} gap={4} width="full">
              <FormView label="Contract Term" value={contractTerm} />
            </SimpleGrid>
            <FormView label="Contract Category">
              {contract?.laborContractCategory && (
                <MobileTag
                  size="sm"
                  color={getTagColor(contract?.laborContractCategory)}
                  background={getTagBackground(contract?.laborContractCategory)}>
                  {contract?.laborContractCategory}
                </MobileTag>
              )}
            </FormView>
            <Divider color="gray.200" />
            <SimpleGrid columns={2} gap={4} width="full">
              <FormView label="ID Number" value={contract?.IDNumber} />
              <FormView label="Birthday" value={getFormattedDateWith_DDMMYYYY(contract?.dateOfBirth)} />
            </SimpleGrid>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default ContractDetail
