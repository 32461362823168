import { ChangeEvent } from 'react'
import { HStack } from '@chakra-ui/react'
import Button from 'components/Button'
import AddNewAssignmentModal from '../AddNewAssignmentModal'

export interface IHeaderSectionProps {
  toggleModalFilter: () => void
  onKeyWordChange: (event: ChangeEvent<HTMLInputElement>) => void
}

const HeaderSection = (props: IHeaderSectionProps) => {
  const { toggleModalFilter, onKeyWordChange } = props

  return (
    <HStack justifyContent="space-between" alignItems="center" width="100%">
      <HStack spacing={4}>
        {/* // *INFO: This will be used later when complete search logic
        <InputGroup borderRadius="6px" background="white">
          <InputLeftElement pointerEvents="none">
            <Search2Icon color="gray.400" />
          </InputLeftElement>
          <Input
            type="search"
            width="400px"
            fontSize="sm"
            border="1px solid #E2E8F0"
            lineHeight={5}
            _placeholder={{ color: 'rgba(0, 0, 0, 0.36)' }}
            placeholder="Search Project or User by name"
            onChange={onKeyWordChange}
          />
        </InputGroup> */}
        <Button variant="outline" leftIcon="filter.svg" onClick={toggleModalFilter}>
          Filter
        </Button>
      </HStack>
      <HStack spacing={4}>
        <AddNewAssignmentModal />
      </HStack>
    </HStack>
  )
}

export default HeaderSection
