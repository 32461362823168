import { HStack, Text } from '@chakra-ui/react'
import Icon from 'components/Icon'
import { observer } from 'mobx-react'
// make sure you include the timeline stylesheet or the timeline will not be styled
import 'react-calendar-timeline/lib/Timeline.css'
import moment from 'moment'
import Timeline from 'react-calendar-timeline'
import { Messages } from 'constants/index'
import { parsedItemTimeSheetV2 } from 'utils/formatDataUtils'
import { useStores } from 'utils/hooks/useStores'

const TimesheetOverviewCalendar = (props) => {
  const { selectItemTimesheetHandler, choosedDate } = props
  const { adminReportStore } = useStores()
  const { groups, timesheetList } = adminReportStore
  const timelineItems = parsedItemTimeSheetV2(timesheetList, selectItemTimesheetHandler)

  return (
    <div className="timsheet-overview-calendar">
      <h3>Timesheet Overview</h3>
      <HStack mb={2}>
        <Icon iconName="information-blue-fill-circle.svg" size={20} />{' '}
        <Text> {Messages.pressToZoomCalendarGuide} </Text>
      </HStack>
      <Timeline
        sidebarContent={<div>Developer</div>}
        groups={groups}
        items={timelineItems}
        defaultTimeStart={moment(choosedDate).add(-12, 'hour')}
        defaultTimeEnd={moment(choosedDate).add(12, 'hour')}
      />
    </div>
  )
}

export default observer(TimesheetOverviewCalendar)
