import { Dispatch, SetStateAction } from 'react'
import { useMediaQuery } from '@chakra-ui/react'
import Button from 'components/Button'
import { textGrey500, textWhite } from 'theme/globalStyles'
import { EContractProjectRatingSubTab } from '../../constants'

interface ISubTabItemProps {
  isActive: boolean
  tab: EContractProjectRatingSubTab
  setCurrentTab: Dispatch<SetStateAction<EContractProjectRatingSubTab>>
}

const SubTabItem = (props: ISubTabItemProps) => {
  const { isActive, tab, setCurrentTab } = props
  const [isTablet] = useMediaQuery('(max-width: 1200px)')

  return (
    <Button
      color={isActive ? textWhite : textGrey500}
      variant={isActive ? 'solid' : 'ghost'}
      colorScheme={isActive && 'cyan'}
      customStyles={{ borderRadius: '100px' }}
      onClick={() => {
        setCurrentTab(tab)
      }}>
      {isTablet ? String(tab).split(' ')[0] : String(tab)}
    </Button>
  )
}

export default SubTabItem
