import { useEffect } from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import { createRevenueChartForMonthlyFixedScopeProject } from './container'
import { IRevenueChartForMonthlyFixedScopeProps } from './interfaces'

am4core.useTheme(am4themes_animated)

const RevenueChartForMonthlyFixedScope = (props: IRevenueChartForMonthlyFixedScopeProps) => {
  const { chartData } = props
  useEffect(() => {
    const chart = createRevenueChartForMonthlyFixedScopeProject(chartData)
    return () => {
      chart.dispose()
    }
  }, [chartData])
  return <div id="revenue-chartdiv-fixed-scope" style={{ width: '100%', height: '500px' }} />
}

export default RevenueChartForMonthlyFixedScope
