import * as am4charts from '@amcharts/amcharts4/charts'
import * as am4core from '@amcharts/amcharts4/core'
import AdminDashboardStore from 'store/admin/dashboardStore'
import { IChartData, IFormattedDeveloperOfProject, IOptions } from 'types/dashboard'
import { reduceDataOfChart } from 'utils/formatDataUtils'

export function createXYChart() {
  return am4core.create('chartdiv', am4charts.XYChart)
}

export function addAxes(chart: am4charts.XYChart) {
  const dateAxis = chart.xAxes.push(new am4charts.DateAxis())
  dateAxis.title.text = 'Date'
  dateAxis.renderer.grid.template.location = 0

  const valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
  valueAxis.title.text = 'Time total'
  valueAxis.tooltip.disabled = true
}

export function createLegend(chart: am4charts.XYChart, adminDashboardStore: AdminDashboardStore) {
  chart.legend = new am4charts.Legend()
  chart.legend.itemContainers.template.events.on('toggled', (event) => {
    const currentUserId = (event.target.dataItem.dataContext as { id: string }).id
    if (!event.target.isActive) {
      adminDashboardStore.changeChartIndicators([...adminDashboardStore.activeUserIds, currentUserId])
    } else {
      adminDashboardStore.changeChartIndicators(adminDashboardStore.activeUserIds?.filter((id) => id !== currentUserId))
    }
  })
}

export function addChartCursor(chart: am4charts.XYChart) {
  chart.cursor = new am4charts.XYCursor()
  chart.scrollbarY = new am4core.Scrollbar()
  chart.scrollbarX = new am4core.Scrollbar()
}

export function handleChartEvent(chart: am4charts.XYChart, chartData: IChartData[], expandChartHandler: () => void) {
  if (chartData.length > 5) {
    chart.events.on('over', () => {
      expandChartHandler()
    })
  }
}

export function renderSeriesLines(
  seriesLines: IFormattedDeveloperOfProject[],
  chartData: IChartData[],
  options: IOptions,
  createSeries: any,
  chart: am4charts.XYChart
) {
  seriesLines.forEach((seriesLine) => {
    const currentData = chartData.find((item) => item._id === seriesLine._id)

    if (currentData) {
      const fullData = reduceDataOfChart(currentData.data, options?.period, options?.date, seriesLine)
      createSeries(chart, 'totalTime', seriesLine.name, fullData, seriesLine._id)
      chart.validateData()
    } else {
      createSeries(chart, 'totalTime', seriesLine.name, [], seriesLine._id)
      chart.validateData()
    }
  })
}

export function createBullet(series: am4charts.LineSeries) {
  const bullet = series.bullets.push(new am4charts.CircleBullet())
  bullet.circle.stroke = am4core.color('#fff')
  bullet.circle.strokeWidth = 2
}

export function setSeriesDataFields(series: am4charts.LineSeries, field: string) {
  series.dataFields.valueY = field
  series.dataFields.dateX = 'date'
}

export function setSeriesProperties(series: am4charts.LineSeries, name: string, id: string, data: any[]) {
  series.name = name
  series.id = id
  series.data = data
  series.strokeWidth = 2
  series.tooltipText = `[font-size: 10px; red][bold]{developerLevel}: {developer}[/]
  ------------------------
  Total: {valueY} ({formattedTotalTime})
  Tasks name: {tasksName}
  ------------------------
  `
}
