import { IAssignment, IAssignmentDetailQuery } from 'API/diagram/interface'
import { IOption } from 'constants/common'
import { EProjectTypes } from 'constants/enum'
import { projectTypeOptions } from 'containers/Diagram/constant'
import { getValidArray } from 'utils/commonUtils'
import { ISidebarContent } from '../NodeDetail/constant'
import { IRowData } from './types'

export function getEditAssignmentTableData(assignments: IAssignment[]): IRowData[] {
  return getValidArray(assignments).map((assignmentDetail: IAssignment) => {
    const projectType: IOption | undefined = getValidArray(projectTypeOptions).find((projectType: IOption) => {
      return projectType.value === assignmentDetail?.projectType
    })

    return {
      ...assignmentDetail,
      projectHourlyRate: assignmentDetail?.projectHourlyRate?.toString(),
      projectClientRate: assignmentDetail?.projectClientRate?.toString(),
      commitTime: assignmentDetail?.commitTime?.toString(),
      projectType: projectType ?? projectTypeOptions[0],
    }
  })
}

export function getAssignmentsPayload(content: ISidebarContent[]): IAssignmentDetailQuery[] {
  return getValidArray(content).map((item: ISidebarContent) => {
    return {
      project: item?.project,
      user: item?.user,
      projectPaymentCategory: item?.projectPaymentCategory,
    }
  })
}

export function getEditAssignmentsPayload(assignments: IRowData[]): IAssignment[] {
  return getValidArray(assignments).map((assignmentDetail: IRowData) => {
    return {
      ...assignmentDetail,
      projectHourlyRate: Number(assignmentDetail?.projectHourlyRate),
      projectClientRate: Number(assignmentDetail?.projectClientRate),
      commitTime: Number(assignmentDetail?.commitTime),
      projectType: assignmentDetail?.projectType?.value as EProjectTypes,
    }
  })
}
