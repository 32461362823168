import { ITableHeader } from 'types/table'

export function getHourlyProjectHeaderList() {
  const headers: ITableHeader[] = [
    {
      Header: 'PROJECT',
      accessor: 'projectName',
    },
    {
      Header: 'CURRENT',
      columns: [
        {
          Header: 'HOURLY RATE',
          accessor: 'currentHourlyRate',
        },
        {
          Header: 'APPLY DATE',
          accessor: 'currentApplyDate',
        },
      ],
    },
    {
      Header: 'FUTURE',
      columns: [
        {
          Header: 'HOURLY RATE',
          accessor: 'futureHourlyRate',
        },
        {
          Header: 'APPLY DATE',
          accessor: 'futureApplyDate',
        },
      ],
    },
  ]

  return headers
}

export function getFixedProjectHeaderList() {
  const headers: ITableHeader[] = [
    {
      Header: 'PROJECT',
      accessor: 'projectName',
    },
    {
      Header: 'CURRENT',
      columns: [
        {
          Header: 'FIXED RATE',
          accessor: 'currentFixedRate',
        },
        {
          Header: 'APPLY DATE',
          accessor: 'currentApplyDate',
        },
      ],
    },
    {
      Header: 'FUTURE',
      columns: [
        {
          Header: 'FIXED RATE',
          accessor: 'futureFixedRate',
        },
        {
          Header: 'APPLY DATE',
          accessor: 'futureApplyDate',
        },
      ],
    },
  ]

  return headers
}
