import { useEffect, useState } from 'react'
import { Stack } from '@chakra-ui/react'
import Button from 'components/Button'
import { useFormContext } from 'react-hook-form'
import { useStores } from 'utils/hooks/useStores'
import { IHeatmapFilterFormProps, typeOfFilterOfHeadMap } from '../../constant'
import { renderHeatmapFilterOption } from './container'

const HeatmapFilterFormForAdmin = (props: IHeatmapFilterFormProps) => {
  const { projectNameList, viewAnalyticHandler, userNameList } = props
  const { userReportStore } = useStores()
  const [filterType, setFilterType] = useState(typeOfFilterOfHeadMap[0])
  const { handleSubmit, getValues } = useFormContext()
  const [isValidForm, setIsValidForm] = useState(false)
  const formValues = getValues()
  const { selectedProject, typeOfFilter, selectedUser } = formValues
  useEffect(() => {
    const isValid = selectedProject || selectedUser
    setFilterType(typeOfFilter)
    setIsValidForm(isValid)
  }, [formValues])

  return (
    <Stack width="100%" background="white">
      <Stack direction={{ lg: 'row', sm: 'column' }} padding={'16px'}>
        {renderHeatmapFilterOption(projectNameList, userNameList, filterType)}
        <Button
          height="42px"
          variant="solid"
          width="full"
          disabled={!isValidForm || userReportStore.isHeatmapLoading}
          isLoading={userReportStore.isHeatmapLoading}
          customStyles={{ minWidth: '180px', alignSelf: 'flex-end' }}
          rightIcon="arrow-right-line-white.svg"
          onClick={handleSubmit(viewAnalyticHandler)}>
          View HeatMap
        </Button>
      </Stack>
    </Stack>
  )
}

export default HeatmapFilterFormForAdmin
