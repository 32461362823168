import { ITableHeader } from 'types/table'

export const headerListMobile: ITableHeader[] = [
  {
    Header: 'Start time - End time',
    accessor: 'startEndTime',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: '',
    accessor: 'actions',
  },
]

export const headerListDesktop: ITableHeader[] = [
  {
    Header: 'Start time - End time',
    accessor: 'startEndTime',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: '',
    accessor: 'actions',
  },
]

export function getHeaderList(isMobile: boolean): ITableHeader[] {
  return isMobile ? headerListMobile : headerListDesktop
}
