import { ISuccessResponse } from 'API/constants'
import { handleError } from 'API/error'
import { getPartnersAPI } from 'API/partner'
import { IPartnerListRequest, IPartnerListResponse } from 'API/partner/constants'
import {
  archiveProjectV2API,
  createProjectV2API,
  deleteProjectV2API,
  getProjectListAPI,
  getProjectListWithPaginationAPI,
  unarchiveProjectAPI,
  getAllNameProjectListOfAdminAPI,
  getAllNameProjectListOfAGivenUserForAdminAPI,
  getProjectDetailOfAdminV2API,
  updateProjectV2API,
  getRemovedProjectsListOfUserAPI,
} from 'API/project'
import {
  IProjectDetailResponse,
  IProjectFilter,
  IProjectListWithPaginationResponse,
  IProjectMessageResponse,
  IProjectsOfUserResponse,
  IProjectNameListResponse,
  IProjectNameResponse,
} from 'API/project/constants'
import get from 'lodash/get'
import set from 'lodash/set'
import { makeAutoObservable } from 'mobx'
import queryString from 'query-string'
import { toast } from 'react-toastify'
import { THistory } from 'types/common'
import { ITechnology } from 'types/technology'
import { EPaymentCategoryOfProject } from 'constants/enum'
import { limitItemPerPage } from 'constants/index'
import { ICurrency, IPartner, IProject, IProjectType, IUser } from 'constants/schema'
import { getInQuery } from 'utils/commonUtils'
import { getParsedProjectValueBeforeSendBE } from 'utils/formatDataUtils'
import RootStore from '../rootStore'

class AdminProjectStore {
  rootStore: RootStore
  currentPage: number = 1
  count: number = 0
  projectsList: IProject[] = []
  projectNameList: IProjectNameResponse[] = []
  projectNameListOfAGivenUser: IProjectNameResponse[] = []
  projectsListWithoutFilter: IProject[] = []
  projectDetail
  partnerListAll: IPartner[] = []
  partnerList: IPartner[] = []
  technologiesList: ITechnology[] = []
  projectTypes: IProjectType[] = []
  currencyList: ICurrency[] = []
  usersList: IUser[] = []
  rawProjectDetailData
  isLoadNewProjectList: boolean
  isLoadingProject: boolean

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public async getAllNameProjectList(condition: string, callback?: Function): Promise<void> {
    try {
      const response: ISuccessResponse<IProjectNameListResponse> = await getAllNameProjectListOfAdminAPI(condition)
      this.projectNameList = response?.data?.projects
      if (callback && typeof callback === 'function') {
        callback()
      }
    } catch (error) {
      handleError(error, 'src/store/admin/projectStore.ts', 'getAllNameProjectList')
    }
  }

  public async getAllNameProjectListOfAGivenUser(condition: string, userId: string): Promise<void> {
    try {
      const response: ISuccessResponse<IProjectNameListResponse> = await getAllNameProjectListOfAGivenUserForAdminAPI(
        condition,
        userId
      )
      this.projectNameListOfAGivenUser = response?.data?.projects
    } catch (error) {
      handleError(error, 'src/store/admin/projectStore.ts', 'getAllNameProjectList')
    }
  }

  public async getProjectListWithoutFilter(): Promise<void> {
    try {
      const response: ISuccessResponse<IProjectsOfUserResponse> = await getProjectListAPI()
      this.projectsListWithoutFilter = response?.data?.projects
    } catch (error) {
      handleError(error, 'src/store/admin/projectStore.ts', 'getProjectListWithoutFilter')
    }
  }

  public async getProjectListWithPagination(history?: THistory, filter?: IProjectFilter): Promise<void> {
    try {
      this.isLoadingProject = true
      const searchString = history.location.search
      const parsedQuery = queryString.parse(searchString)
      const currentPage: number = Number(get(parsedQuery, 'page', 1))
      const response: ISuccessResponse<IProjectListWithPaginationResponse> = await getProjectListWithPaginationAPI({
        currentPage,
        perPage: limitItemPerPage,
        filter,
      })
      this.projectsList = response?.data?.projects
      this.count = response?.data?.count
    } catch (error) {
      handleError(error, 'src/store/admin/projectStore.ts', 'getProjectListWithPagination')
    } finally {
      this.isLoadingProject = false
      this.isLoadNewProjectList = false
    }
  }

  public async getRemovedProjectsListOfUser(userId: string): Promise<void> {
    try {
      const response: ISuccessResponse<IProjectsOfUserResponse> = await getRemovedProjectsListOfUserAPI(userId)
      this.projectsList = response?.data?.projects
    } catch (error) {
      handleError(error, 'src/store/admin/projectStore.ts', 'getRemovedProjectsListOfUser')
    }
  }

  public async getProjectDetailOfAdminV2(projectId: string): Promise<void> {
    try {
      const response: ISuccessResponse<IProjectDetailResponse> = await getProjectDetailOfAdminV2API(projectId)
      this.rawProjectDetailData = response?.data?.project
    } catch (error) {
      handleError(error, 'src/store/admin/projectStore.ts', 'getProjectDetail')
    }
  }

  public async getPartnerList(partnerCategory: string[]): Promise<void> {
    try {
      const data: IPartnerListRequest = {
        filter: {
          isDeleted: false,
          isArchived: false,
          category: getInQuery(partnerCategory),
        },
      }
      const response: ISuccessResponse<IPartnerListResponse> = await getPartnersAPI(data)
      this.partnerList = response?.data?.partners
    } catch (error) {
      handleError(error, 'src/store/admin/projectStore.ts', 'getPartnerList')
    }
  }

  public async createProject(data: IProject): Promise<void> {
    try {
      if (data?.paymentCategory === EPaymentCategoryOfProject.HOURLY) {
        set(data, 'price', 0)
        delete data?.margin
      }
      const isUntilNow: boolean = data?.untilNow
      if (isUntilNow) {
        delete data?.endedDate
      }
      const parsedValues: IProject = getParsedProjectValueBeforeSendBE(data)
      const createProjectResponse: IProjectMessageResponse = await createProjectV2API(parsedValues)
      this.isLoadNewProjectList = true
      toast.success(createProjectResponse?.message)
    } catch (error) {
      handleError(error, 'src/store/admin/projectStore.ts', 'createProject')
    }
  }

  public async updateProjectV2(data: IProject): Promise<void> {
    try {
      if (data.paymentCategory === EPaymentCategoryOfProject.HOURLY) {
        delete data?.margin
      }
      const isUntilNow: boolean = data?.untilNow
      if (isUntilNow) {
        delete data?.endedDate
      }
      const projectId: string = data?._id
      const parsedValues: IProject = getParsedProjectValueBeforeSendBE(data)
      const response: ISuccessResponse<IProjectDetailResponse> = await updateProjectV2API(projectId, parsedValues)
      this.getProjectDetailOfAdminV2(projectId)
      toast.success(response?.data?.message)
    } catch (error) {
      handleError(error, 'src/store/admin/projectStore.ts', 'updateProject')
    }
  }

  public async deleteProject(id: string): Promise<void> {
    try {
      const message = await deleteProjectV2API(id)
      this.isLoadNewProjectList = true
      toast.success(message.data?.message)
    } catch (error) {
      handleError(error, 'src/store/admin/projectStore.ts', 'deleteProject')
    }
  }

  public async archiveProject(id: string): Promise<void> {
    try {
      const message = await archiveProjectV2API(id)
      this.isLoadNewProjectList = true
      toast.success(message)
    } catch (error) {
      handleError(error, 'src/store/admin/projectStore.ts', 'archiveProject')
    }
  }

  public async unArchiveProject(userList: string[], projectId: string): Promise<void> {
    try {
      const message = await unarchiveProjectAPI(projectId, userList)
      this.isLoadNewProjectList = true
      toast.success(message)
    } catch (error) {
      handleError(error, 'src/store/admin/projectStore.ts', 'unArchiveProject')
    }
  }
}

export default AdminProjectStore
