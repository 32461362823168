import {
  getCurrencyListAPI,
  createCurrencyAPI,
  getPaginatedCurrencyListAPI,
  deleteCurrencyAPI,
  updateCurrencyAPI,
} from 'API/currency'
import { ICurrency, ICurrencyCreationForm } from 'API/currency/constants'
import { handleError } from 'API/error'
import get from 'lodash/get'
import { makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'
import RootStore from '../rootStore'

class AdminCurrencyStore {
  rootStore: RootStore
  currencyList: ICurrency[] = []
  listCount: number = 0
  isLoadNewCurrencyList: boolean = false
  isLoadingCurrencyList: boolean = false

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public async getCurrencyList(): Promise<void> {
    try {
      const { currencies } = await getCurrencyListAPI()
      this.currencyList = currencies
    } catch (error) {
      handleError(error, 'src/store/admin/AdminCurrencyStore.ts', 'getCurrencyList')
    }
  }

  public async getPaginatedCurrencyList(isReset?: boolean, history?: any, filter?: any): Promise<void> {
    try {
      this.isLoadingCurrencyList = true
      const page: number = isReset ? 1 : Number(get(history, 'location.state.page') || 1)
      const payload = {
        page,
        pageSize: 10,
        filter,
      }
      const { currencies, count } = await getPaginatedCurrencyListAPI(payload)
      this.currencyList = currencies
      this.listCount = count
      this.isLoadNewCurrencyList = false
    } catch (error) {
      handleError(error, 'src/store/admin/AdminCurrencyStore.ts', 'getPaginatedCountries')
    } finally {
      this.isLoadingCurrencyList = false
    }
  }

  public async createCurrency(currencyData: ICurrencyCreationForm): Promise<void> {
    try {
      const message = await createCurrencyAPI(currencyData)
      toast.success(message)
      this.isLoadNewCurrencyList = true
    } catch (error) {
      handleError(error, 'src/store/admin/AdminCurrencyStore.ts', 'createCurrency', false, true)
    }
  }

  public async updateCurrency(currencyData: ICurrency): Promise<void> {
    try {
      const message = await updateCurrencyAPI(currencyData)
      toast.success(message)
      this.isLoadNewCurrencyList = true
    } catch (error) {
      handleError(error, 'src/store/admin/AdminCurrencyStore.ts', 'updateCurrency', false, true)
    }
  }

  public async deleteCurrency(technologyId: string): Promise<void> {
    try {
      const message = await deleteCurrencyAPI(technologyId)
      toast.success(message)
      this.isLoadNewCurrencyList = true
    } catch (error) {
      handleError(error, 'src/store/admin/AdminCurrencyStore.ts', 'deleteCurrency', false, true)
    }
  }
}

export default AdminCurrencyStore
