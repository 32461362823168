import { useState, useEffect } from 'react'
import { Text } from '@chakra-ui/react'
import { updateDiagramNodeAPI } from 'API/diagram'
import { handleError } from 'API/error'
import { Node } from 'beautiful-react-diagrams/@types/DiagramSchema'
import { observer } from 'mobx-react'
import { EDiagramNodeTypes } from 'constants/enum'
import { IDiagramNode } from 'constants/schema'
import { getValidArray } from 'utils/commonUtils'
import { useStores } from 'utils/hooks/useStores'
import { DiagramNodeWrapper } from '../../diagram.styles'
import { UPDATE_DELAY_VALUE } from './constants'

export interface DiagramNodeProps {
  nodeData: Omit<Node<unknown>, 'coordinates'>
  isUserNode?: boolean
  handleClick: (id: string, isUser: boolean) => void
}

const DiagramNode = (props: DiagramNodeProps) => {
  const { nodeData, isUserNode = false, handleClick } = props
  const { id, content } = nodeData
  const { adminDiagramStore } = useStores()
  const { currentNode } = adminDiagramStore
  const [isDragging, setIsDragging] = useState<boolean>(false)

  function toggleSchema(): void {
    handleClick(id, isUserNode)
  }

  function onDragStart(): void {
    setIsDragging(true)
  }

  function onDragEnd(): void {
    setTimeout(() => {
      setIsDragging(false)
    }, UPDATE_DELAY_VALUE)
  }

  async function updateNodeCoordinates(): Promise<void> {
    try {
      const { id, coordinates } = currentNode
      const [x, y] = getValidArray(coordinates)
      const payload: IDiagramNode = {
        id: id ?? '',
        xCoordinate: x ?? 0,
        yCoordinate: y ?? 0,
        type: isUserNode ? EDiagramNodeTypes.USER : EDiagramNodeTypes.PROJECT,
      }
      await updateDiagramNodeAPI(id, payload)
    } catch (error) {
      handleError(error, 'src/containers/Diagram/components/DiagramNode/index.tsx', 'updateNodeCoordinates')
    }
  }

  useEffect(() => {
    if (id === currentNode?.id && currentNode?.coordinates && isDragging) {
      updateNodeCoordinates()
    }
  }, [currentNode?.coordinates])

  return (
    <DiagramNodeWrapper isUserNode={isUserNode} onClick={toggleSchema} onMouseDown={onDragStart} onMouseUp={onDragEnd}>
      <Text color={isUserNode ? 'blue.500' : 'orange.500'} fontSize="md" fontWeight={400}>
        {content}
      </Text>
    </DiagramNodeWrapper>
  )
}

export default observer(DiagramNode)
