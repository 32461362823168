import apiV2 from 'API/api'
import { APIRouters } from 'API/APIRouters'
import { AxiosResponse } from 'axios'
import { ICountryListResponse, ICountry, ICountryListPaginationRequest, ICountryCreationForm } from './constants'

export async function getCountryListAPI(): Promise<ICountryListResponse> {
  try {
    const url: string = APIRouters.country.list.value
    const response: AxiosResponse = await apiV2.get(url)
    return {
      countries: response?.data?.countries || [],
      count: response?.data?.count || 0,
    }
  } catch (error) {
    throw error
  }
}

export async function getPaginatedCountriesAPI(payload: ICountryListPaginationRequest): Promise<ICountryListResponse> {
  try {
    const url: string = APIRouters.country.list.value
    const response: AxiosResponse = await apiV2.post(url, payload)
    return {
      countries: response?.data?.countries || [],
      count: response?.data?.count || 0,
    }
  } catch (error) {
    throw error
  }
}

export async function createCountryAPI(country: ICountryCreationForm): Promise<string> {
  try {
    const url: string = APIRouters.country.value
    const response: AxiosResponse = await apiV2.post(url, country)
    return response?.data?.message || ''
  } catch (error) {
    throw error
  }
}

export async function updateCountryAPI(country: ICountry): Promise<string> {
  try {
    const url: string = APIRouters.country.countryId.value(country.id)
    const response: AxiosResponse = await apiV2.patch(url, country)
    return response?.data?.message || ''
  } catch (error) {
    throw error
  }
}

export async function deleteCountryAPI(countryId: string): Promise<string> {
  try {
    const url: string = APIRouters.country.countryId.value(countryId)
    const response: AxiosResponse = await apiV2.delete(url)
    return response?.data?.message || ''
  } catch (error) {
    throw error
  }
}
