import { useState, useEffect } from 'react'
import { VStack, HStack } from '@chakra-ui/layout'
import SubTabItem from 'containers/Profile/components/SubTabItem'
import { useStores } from 'utils/hooks/useStores'
import { EContractProjectRatingSubTab } from '../../../constants'
import styles from '../../index.module.scss'
import FixedProjectRating from './FixedProjectRating'
import HourlyProjectRating from './HourlyProjectRating'

const ProjectRating = () => {
  const { userProfileStore } = useStores()
  const [currentTab, setCurrentTab] = useState<EContractProjectRatingSubTab>(
    EContractProjectRatingSubTab.HOURLY_PROJECT_RATING
  )
  useEffect(() => {
    userProfileStore.getProjectRatings()
  }, [])

  return (
    <VStack alignItems={'initial'} className={styles.section} marginLeft={4}>
      <HStack
        padding={{ lg: '0px 0px', md: '0px 10px', sm: '0px 10px' }}
        spacing={{ base: 6, lg: 8 }}
        justify="space-between"
        backgroundColor="white"
        mx={8}
        borderRadius="8px">
        <HStack spacing={3} alignSelf="flex-start" flex={2} margin={'20px 0'}>
          <SubTabItem
            tab={EContractProjectRatingSubTab.HOURLY_PROJECT_RATING}
            isActive={currentTab === EContractProjectRatingSubTab.HOURLY_PROJECT_RATING}
            setCurrentTab={setCurrentTab}
          />
          <SubTabItem
            tab={EContractProjectRatingSubTab.FIXED_PROJECT_RATING}
            isActive={currentTab === EContractProjectRatingSubTab.FIXED_PROJECT_RATING}
            setCurrentTab={setCurrentTab}
          />
        </HStack>
      </HStack>
      <HStack
        padding={{ lg: '0px 0px', md: '5px', sm: '5px' }}
        flex={5}
        alignItems="flex-start"
        width={{ lg: 'auto', md: 'auto', sm: 'auto' }}
        justifyContent="center">
        {currentTab === EContractProjectRatingSubTab.HOURLY_PROJECT_RATING && <HourlyProjectRating />}
        {currentTab === EContractProjectRatingSubTab.FIXED_PROJECT_RATING && <FixedProjectRating />}
      </HStack>
    </VStack>
  )
}

export default ProjectRating
