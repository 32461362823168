import { useEffect } from 'react'
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  HStack,
  useMediaQuery,
} from '@chakra-ui/react'
import Button from 'components/Button'
import { FormProvider, useForm } from 'react-hook-form'
import { borderGray200, backgroundGrey50 } from 'theme/globalStyles'
import { maxMobileSize } from 'constants/common'
import SkillAnalysisForm from '../SkillAnalysisForm'
import { ISkillAnalysisFormState } from '../SkillAnalysisForm/interfaces'
import SkillAnalysisHistoryContent from '../SkillAnalysisHistoryContent'
import { IEditSkillChartModalProps } from './interfaces'

const EditSkillChartModal = (props: IEditSkillChartModalProps) => {
  const { isOpen, closeHandler, saveHandler, skillAnalysisHistoryData = [] } = props
  const methods = useForm()
  const { handleSubmit, reset } = methods
  const activeSkill = skillAnalysisHistoryData[0]
  const [isMobile] = useMediaQuery(maxMobileSize)

  useEffect(() => {
    const formatedData = {
      workingAttitude: {
        value: activeSkill?.workingAttitude?.toString() ?? '0',
        label: activeSkill?.workingAttitude?.toString() ?? '0',
      },
      meetDeadlines: {
        value: activeSkill?.meetDeadlines?.toString() ?? '0',
        label: activeSkill?.meetDeadlines?.toString() ?? '0',
      },
      problemSolving: {
        value: activeSkill?.problemSolving?.toString() ?? '0',
        label: activeSkill?.problemSolving?.toString() ?? '0',
      },
      communication: {
        value: activeSkill?.communication?.toString() ?? '0',
        label: activeSkill?.communication?.toString() ?? '0',
      },
      teamPlayer: {
        value: activeSkill?.teamPlayer?.toString() ?? '0',
        label: activeSkill?.teamPlayer?.toString() ?? '0',
      },
      technicalSkills: {
        value: activeSkill?.technicalSkills?.toString() ?? '0',
        label: activeSkill?.technicalSkills?.toString() ?? '0',
      },
      english: {
        value: activeSkill?.english?.toString() ?? '0',
        label: activeSkill?.english?.toString() ?? '0',
      },
      comment: '',
    }
    reset(formatedData)
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} onClose={closeHandler} isCentered size="xl">
      <ModalOverlay />
      <FormProvider {...methods}>
        <form>
          <ModalContent maxHeight="800px" maxWidth="960px">
            <Box background="white" width="full">
              <ModalHeader borderBottom="1px solid" borderColor={borderGray200} fontWeight={500}>
                Edit Skill Chart
              </ModalHeader>
              <ModalCloseButton color={'gray'} background="none" cursor="pointer" mt="2" />
            </Box>
            <ModalBody
              paddingTop="0"
              paddingRight={{ sm: '24px', md: '0', lg: '0' }}
              position="relative"
              overflowY="auto">
              <HStack alignItems="start">
                <Box
                  width={{ lg: '60%', md: '60%', sm: '100%' }}
                  position="sticky"
                  top="0"
                  paddingRight="20px"
                  paddingTop="20px">
                  <SkillAnalysisForm isEdit activeSkill={activeSkill} />
                </Box>
                {!isMobile && (
                  <Box width="40%" background={backgroundGrey50} paddingRight="10px" paddingTop="20px">
                    <Box borderLeft="2px solid" borderColor={borderGray200} height="fit-content">
                      <SkillAnalysisHistoryContent skillAnalysisHistoryData={skillAnalysisHistoryData} />
                    </Box>
                  </Box>
                )}
              </HStack>
            </ModalBody>
            <ModalFooter borderTop="1px solid" borderColor={borderGray200}>
              <Button variant="outline" onClick={closeHandler} customStyles={{ marginRight: '16px' }}>
                Cancel
              </Button>
              <Button variant="solid" onClick={handleSubmit((data: ISkillAnalysisFormState) => saveHandler(data))}>
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </FormProvider>
    </Modal>
  )
}
export default EditSkillChartModal
