import { ITableHeader } from 'types/table'
import { IProject } from 'constants/schema'

export function getHeaderList(isMobile: boolean): ITableHeader[] {
  const headers: ITableHeader[] = isMobile
    ? [
        {
          Header: 'LOGO',
          accessor: 'logo',
        },
        {
          Header: 'PROJECT NAME',
          accessor: 'name',
        },
        {
          Header: '',
          accessor: 'actions',
        },
      ]
    : [
        {
          Header: 'LOGO',
          accessor: 'logo',
        },
        {
          Header: 'PROJECT NAME',
          accessor: 'name',
        },
        {
          Header: 'Status',
          accessor: 'status',
        },
        {
          Header: 'TECHNOLOGY',
          accessor: 'technologiesListFilter',
        },
        {
          Header: 'START DATE - END DATE',
          accessor: 'startedDateEndDate',
        },
        {
          Header: '',
          accessor: 'actions',
        },
      ]

  return headers
}
export interface IProjectFilter extends Omit<IProject, 'partnerId' | 'countryId' | 'technology' | 'user'> {
  partnerId?: string
  countryId?: string[]
  technology?: string[]
  user?: string[]
}
