import EmptyContentBox from 'components/EmptyContentBox'
import {
  IChartIndicators,
  IOptions,
  IProjectData,
  IRevenueChartData,
  IRevenueChartDataForMonthFixedScopeProject,
} from 'types/dashboard'
import { IManHourHeatMap } from 'types/summarize'
import { EChartType } from 'constants/enum'
import { Messages } from '../../constants'
import HeatMapChart from '../Dashboard/components/HeatMapChart'
import Chart from './components/Chart'
import RevenueChartForMonthFixedScope from './components/RevenueChart/RevenueChartForMonthlyFixedScope'
import RevenueChartForTimeAndMaterialProject from './components/RevenueChart/RevenueChartForTimeAndMaterialProject'

export const renderChart = (
  chartType: EChartType,
  expandedChart: boolean,
  options: IOptions,
  expandChartHandler: () => void,
  reduceChartHandler: () => void,
  chartIndicators?: IChartIndicators,
  projectData?: IProjectData,
  revenueChartDataForTimeAndMaterial?: IRevenueChartData[],
  revenueChartDataForMonthlyFixedScope?: IRevenueChartDataForMonthFixedScopeProject[]
) => {
  switch (chartType) {
    case EChartType.TIME_TOTAL_CHART:
      return (
        <div className={expandedChart ? 'chart expanded' : 'chart'}>
          {chartIndicators && chartIndicators.data && chartIndicators.data.length > 0 && (
            <Chart
              chartData={chartIndicators.data}
              seriesLines={chartIndicators.developers}
              options={options}
              expandChartHandler={expandChartHandler}
              reduceChartHandler={reduceChartHandler}
            />
          )}
        </div>
      )
    case EChartType.REVENUE_CHART_FOR_MONTHLY_FIXED_SCOPE: {
      if (projectData.projectPaymentCategory === 'Hourly') {
        return (
          <div className="chart">
            {revenueChartDataForMonthlyFixedScope && revenueChartDataForMonthlyFixedScope.length > 0 && (
              <RevenueChartForMonthFixedScope chartData={revenueChartDataForMonthlyFixedScope} />
            )}
          </div>
        )
      }
      return <p className="no-chart-message">{Messages.noProfitChart}</p>
    }
    case EChartType.REVENUE_CHART_FOR_TIME_AND_MATERIAL: {
      if (projectData.projectPaymentCategory === 'Hourly') {
        return (
          <div className="chart">
            {revenueChartDataForTimeAndMaterial && revenueChartDataForTimeAndMaterial.length > 0 && (
              <RevenueChartForTimeAndMaterialProject chartData={revenueChartDataForTimeAndMaterial} />
            )}
          </div>
        )
      }
      return <p className="no-chart-message">{Messages.noRevenueChart}</p>
    }
    default:
      return null
  }
}

export const RenderBodyHeatChart = ({ chartData }: { chartData: IManHourHeatMap[] }) => {
  if (chartData === undefined) {
    return <EmptyContentBox text={Messages.emptyBoxFilterMessage} position="bottom" />
  }

  if (chartData.length === 0) {
    return <EmptyContentBox text={Messages.noData} />
  }

  return <HeatMapChart chartData={chartData} />
}
