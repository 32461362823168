import { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Textarea,
  Text,
} from '@chakra-ui/react'
import Button from 'components/Button'
import { noticeForChangeOptionOfPayment } from 'constants/index'
import { IChangeRequestModalProp } from './types'

const RequestToChangePaymentModal = (props: IChangeRequestModalProp) => {
  const [reasonText, setReasonText] = useState('')
  const { isOpen, onClose, sendHandler } = props
  function onChangeReasonField(e) {
    let inputValue = e.target.value
    setReasonText(inputValue)
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Request For Changing</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Text fontWeight="" mb="0.5rem">
            {noticeForChangeOptionOfPayment}
          </Text>
          <FormControl>
            <FormLabel>Reason</FormLabel>
            <Textarea paddingLeft={2} paddingRight={0} onBlur={onChangeReasonField} />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose} variant="outline" customStyles={{ marginRight: '12px' }}>
            Cancel
          </Button>
          <Button onClick={() => sendHandler(reasonText)}>Send</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default RequestToChangePaymentModal
