export const upload = {
  value: '/upload',
  image: {
    value: '/upload/image',
    type: {
      value: (type: string) => `/upload/image/${type}`,
    },
  },
  timesheetImage: {
    value: '/upload/timesheet-image',
  },
  file: {
    value: '/upload/file',
    type: {
      value: (type: string) => `/upload/file/${type}`,
    },
    delete: {
      value: '/upload/file/delete',
      type: {
        value: (type: string) => `/upload/file/delete/${type}`,
      },
    },
  },
}
