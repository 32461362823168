import React, { useEffect } from 'react'
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons'
import { Container, Table as CkTable, Thead, Tbody, Tr, Th, Skeleton, Box, Stack } from '@chakra-ui/react'
import cx from 'classnames'
import { useTable, useExpanded, useSortBy, Column } from 'react-table'
import Cell from './components/Cell'
import Pagination from './components/Pagination'
import { defaultExcludedList } from './constant'
import { getExportFileBlob } from './container'
import { ITableProps } from './interface'
import styles from './table.module.scss'

const DesktopTable = (props: ITableProps) => {
  const {
    tableData,
    headerList,
    tableSize,
    subComponent,
    pagination = { includePagination: false, pageIndex: 1 },
    isLoading = false,
    hasNoSort,
    hasBottomHeader = true,
    excludedExport = defaultExcludedList,
    exportNow,
    isBorder = true,
    isStriped = true,
    isBorderBottomCell = true,
    toggleExport,
  } = props
  const pageTitle = window.localStorage.getItem('pageTitle')
  const columns: Column<object>[] = React.useMemo(() => headerList, [headerList]) || []
  const paginationComponent = pagination.includePagination ? <Pagination pagination={pagination} /> : null
  const loadingPaginationComponent = isLoading ? null : paginationComponent
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: tableData,
      //@ts-ignore-next-line
      getExportFileBlob,
    },
    !hasNoSort && useSortBy,
    useExpanded
  )

  useEffect(() => {
    if (exportNow) {
      getExportFileBlob(tableData, excludedExport, pageTitle)
      toggleExport(false)
    }
  }, [exportNow])

  return (
    <Stack width="inherit">
      <Container maxWidth="100%" padding="0px" margin="0px !important">
        <Box className={cx(styles.scrollAble, isBorder ? styles.borderTable : '')}>
          <CkTable
            size={tableSize ? tableSize : 'md'}
            variant={isStriped ? 'striped' : undefined}
            colorScheme="gray"
            outline="none"
            {...getTableProps()}>
            <Thead className={styles.thead}>
              {headerGroups.map((headerGroup) => {
                const { key, ...restHeaderGroup } = headerGroup.getHeaderGroupProps()
                return (
                  <Tr key={`tr-${key}`} {...restHeaderGroup}>
                    {headerGroup.headers.map((column: any) => {
                      const { key } = column.getHeaderProps()
                      const columnHeaderProps = column.Header
                        ? column.getHeaderProps(column.getSortByToggleProps)
                        : column.getHeaderProps()
                      return (
                        <Th
                          key={`th-${key}`}
                          {...columnHeaderProps}
                          whiteSpace="nowrap"
                          py={[2, 4]}
                          px={['2px', 2]}
                          color={column.render('Header') === 'LOGO' ? 'white' : 'gray.600'}>
                          {column.render('Header')}
                          &nbsp;
                          {hasNoSort ? (
                            ''
                          ) : (
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <TriangleDownIcon aria-label="sorted descending" />
                                ) : (
                                  <TriangleUpIcon aria-label="sorted ascending" />
                                )
                              ) : null}
                            </span>
                          )}
                        </Th>
                      )
                    })}
                  </Tr>
                )
              })}
            </Thead>
            <Tbody {...getTableBodyProps()} color="gray.700" fontWeight="400" fontSize="sm" className={styles.tbody}>
              {rows.map((row, index: number) => {
                prepareRow(row)
                return (
                  <React.Fragment key={`row-${index}_frag`}>
                    {isLoading && <Skeleton className={styles.loadingBar} height={80} />}
                    <Tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        const { key, ...restCell } = cell.getCellProps()
                        return (
                          <Cell key={`cell-${key}`} {...restCell} cell={cell} isBorderBottomCell={isBorderBottomCell} />
                        )
                      })}
                    </Tr>
                    {row.isExpanded ? subComponent({ row }) : null}
                  </React.Fragment>
                )
              })}
            </Tbody>
            {hasBottomHeader && rows.length > 4 && (
              <Thead className={cx(styles.thead, styles.bottomHeader)} whiteSpace="nowrap">
                {headerGroups.map((headerGroup) => {
                  const { key, ...restHeaderGroup } = headerGroup.getHeaderGroupProps()
                  return (
                    <Tr key={`tr-${key}`} {...restHeaderGroup}>
                      {headerGroup.headers.map((column: any) => {
                        const { key } = column.getHeaderProps()
                        const columnHeaderProps = column.Header
                          ? column.getHeaderProps(column.getSortByToggleProps)
                          : column.getHeaderProps()
                        return (
                          <Th key={`th-${key}`} {...columnHeaderProps} py={[2, 4]} px={['2px', 2]}>
                            {column.render('Header')}
                            &nbsp;
                            {hasNoSort ? (
                              ''
                            ) : (
                              <span>
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <TriangleDownIcon aria-label="sorted descending" />
                                  ) : (
                                    <TriangleUpIcon aria-label="sorted ascending" />
                                  )
                                ) : null}
                              </span>
                            )}
                          </Th>
                        )
                      })}
                    </Tr>
                  )
                })}
              </Thead>
            )}
          </CkTable>
        </Box>
        {loadingPaginationComponent}
      </Container>
    </Stack>
  )
}

export default DesktopTable
