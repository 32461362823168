import { getDiagramOverviewAPI } from 'API/diagram'
import { IDiagramOverview, IDiagramQuery } from 'API/diagram/interface'
import { handleError } from 'API/error'
import { Link, Node } from 'beautiful-react-diagrams/@types/DiagramSchema'
import { makeAutoObservable } from 'mobx'
import RootStore from 'store/rootStore'

class AdminDiagramStore {
  rootStore: RootStore
  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }
  currentNodeLinks: Link[]
  currentNode: Node<unknown>
  diagramNodes: IDiagramOverview

  public setCurrentNodeLinks(links: Link[]): void {
    this.currentNodeLinks = links
  }

  public setCurrentNode(node: Node<unknown>): void {
    this.currentNode = node
  }

  async fetchDiagramNodeOverview(query: IDiagramQuery): Promise<IDiagramOverview> {
    try {
      const diagramOverview: IDiagramOverview = await getDiagramOverviewAPI({
        where: query,
      })
      this.diagramNodes = diagramOverview
      return diagramOverview
    } catch (error) {
      handleError(error as Error, 'src/store/admin/diagramStore.ts', 'fetchDiagramNodeOverview')
      throw error
    }
  }
}

export default AdminDiagramStore
