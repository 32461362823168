import AppVersionPane from './components/AppVersionPane'
import NormalSidebarV2 from './components/NormalSidebarV2'
import './index.scss'

const Sidebar = (props) => {
  const { isCollapsed, actionTab, unReadFeedbackNumber } = props

  return (
    <div className={isCollapsed ? 'collapsed main-sidebar' : 'main-sidebar'}>
      <NormalSidebarV2 actionTab={actionTab} unReadFeedbackNumber={unReadFeedbackNumber} {...props} />
      <AppVersionPane isCollapsed={isCollapsed} />
    </div>
  )
}

export default Sidebar
