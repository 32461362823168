import { useContext, useState } from 'react'
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import ReactQuill from 'react-quill'
import { EDITOR_MODULES } from '../../constants'
import { TimesheetModalContext } from '../../context/timesheetModal.context'
import '../../styles.scss'

const DescriptionEditor = () => {
  const { taskDescription, setTaskDescription, quillRef, onBlurDescriptionEditor } = useContext(TimesheetModalContext)
  const {
    formState: { errors },
  } = useFormContext()
  const [isFocused, setIsFocused] = useState<boolean>(false)

  function handleFocus(): void {
    setIsFocused(true)
  }

  function handleBlur(): void {
    setIsFocused(false)
  }

  return (
    <FormControl mt={6} isInvalid={Boolean(errors?.description)} onBlur={onBlurDescriptionEditor}>
      <FormLabel fontSize="md" fontWeight="400">
        Task description
      </FormLabel>
      <ReactQuill
        ref={quillRef}
        theme="snow"
        value={taskDescription}
        onChange={setTaskDescription}
        modules={EDITOR_MODULES}
        placeholder="Describe what you did in detail"
        className={`textarea ${Boolean(errors?.description) ? 'description-error' : ''} ${isFocused ? 'focused' : ''}`}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <FormErrorMessage>{errors?.description?.message}</FormErrorMessage>
    </FormControl>
  )
}

export default DescriptionEditor
