import { VStack, SimpleGrid, Container } from '@chakra-ui/react'
import FormInput from 'components/FormInput'
import GroupRadio from 'components/GroupRadio'
import SingleSelect from 'components/SingleSelect'
import { observer } from 'mobx-react'
import { IOption } from 'constants/common'

interface IEditPartner {
  methods
  partnersType
  patnerCategoryOptions: IOption[]
}

const EditPartnerForm = (props: IEditPartner) => {
  const { methods, partnersType, patnerCategoryOptions } = props

  return (
    <Container background="white" width="full" maxWidth="unset" padding={0} borderRadius={8}>
      <VStack
        spacing={{ lg: 6, md: 6, sm: 2 }}
        alignItems={'initial'}
        margin={{ base: 6, lg: 6 }}
        height="full"
        background="white">
        <SimpleGrid columns={{ base: 1, md: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <FormInput name="number" label="Partner Number" isRequired />
          <FormInput name="fullName" label="Full Name" isRequired />
        </SimpleGrid>

        <SimpleGrid columns={{ base: 1, md: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <FormInput name="address" label="Address" isRequired />
          <GroupRadio name={'type'} isEdit data={partnersType} label="Partner Type" />
        </SimpleGrid>

        <SimpleGrid columns={{ base: 1, md: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <SingleSelect
            name="category"
            label="Partner Category"
            placeHolder="- Select partner category -"
            optionsData={patnerCategoryOptions}
            isRequire
          />
          <FormInput name="phone" label="Phone Number (Optional)" />
        </SimpleGrid>

        <SimpleGrid columns={{ base: 1, md: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <FormInput name="email" label="Email (Optional)" />
          <FormInput name="website" isWebsite label="Website (Optional)" />
        </SimpleGrid>

        <SimpleGrid columns={{ base: 1, md: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <FormInput name="taxCode" label="Tax Code (Optional)" />
          <FormInput name="billingAddress" label="Billing Address (Optional)" />
        </SimpleGrid>
      </VStack>
    </Container>
  )
}

export default observer(EditPartnerForm)
