import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
} from '@chakra-ui/react'
import Button from 'components/Button'

export interface IConfirmYesNoModal {
  isOpen?: boolean
  title?: string
  actionText?: string
  closeHandler?: () => void
  OKClickHandler?: () => void
}

const ConfirmYesNoModal = (props: IConfirmYesNoModal) => {
  const { isOpen, title, actionText, closeHandler, OKClickHandler } = props

  return (
    <Modal isOpen={isOpen} onClose={closeHandler} isCentered>
      <ModalOverlay />
      <ModalContent maxHeight="172px">
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton color={'gray'} background="none" />
        <ModalBody paddingY={0}>
          <Text marginY={0}>{actionText}</Text>
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" customStyles={{ marginRight: '16px' }} onClick={closeHandler}>
            No
          </Button>
          <Button onClick={OKClickHandler} colorScheme="red">
            Yes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ConfirmYesNoModal
