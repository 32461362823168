import apiV2 from 'API/api'
import { APIRouters } from 'API/APIRouters'
import { ISuccessResponse, IResponseWithMessage } from 'API/constants'
import { handleError } from 'API/error'
import { AxiosResponse } from 'axios'
import { IContract } from 'constants/schema'
import {
  CONTRACT_BASE_URL,
  IContractListResponse,
  IContractPayload,
  IContractDetailResponse,
  IContractListWithPaginationRequest,
  IContractListWithPaginationResponse,
} from './constant'

export async function litsOfContractAPI(): Promise<ISuccessResponse<IContractListResponse>> {
  try {
    return apiV2.get(APIRouters.contract.listOfUser.value)
  } catch (error) {
    throw error
  }
}

export async function getContractListOfUserAPI(userId: string): Promise<IContractListResponse> {
  try {
    const response: AxiosResponse = await apiV2.get(APIRouters.contract.listOfAGivenUser.userId.value(userId))
    return response.data
  } catch (error) {
    throw error
  }
}

export async function deleteContractAPI(contractId: string): Promise<IResponseWithMessage> {
  try {
    const response: AxiosResponse = await apiV2.get(APIRouters.contract.delete.contractId.value(contractId))
    return response.data
  } catch (error) {
    throw error
  }
}

export async function activeContractAPI(contractId: string): Promise<IResponseWithMessage> {
  try {
    const response: AxiosResponse = await apiV2.get(APIRouters.contract.active.contractId.value(contractId))
    return response.data
  } catch (error) {
    throw error
  }
}

export async function deActiveContractAPI(contractId: string): Promise<IResponseWithMessage> {
  try {
    const response: AxiosResponse = await apiV2.get(APIRouters.contract.deactive.contractId.value(contractId))
    return response.data
  } catch (error) {
    throw error
  }
}

export async function downloadContractFileAPI(
  contractId: string,
  filename: string,
  acceptType: string
): Promise<BlobPart> {
  try {
    const url = APIRouters.contract.downloadFileFromAdmin.contractId_fileName.value(contractId, filename)
    const response: AxiosResponse = await apiV2.get(url, {
      responseType: 'arraybuffer',
      headers: {
        Accept: acceptType,
      },
      skipEncryption: true,
    })
    return response.data
  } catch (error) {
    throw error
  }
}

export async function showContractFileForAdminAPI(
  contractId: string,
  filename: string,
  acceptType: string
): Promise<BlobPart> {
  try {
    const url = `${CONTRACT_BASE_URL}/show-file-from-admin/${contractId}/${filename}`
    const response: AxiosResponse = await apiV2.get(url, {
      responseType: 'arraybuffer',
      headers: {
        Accept: acceptType,
      },
      skipEncryption: true,
    })
    return response?.data
  } catch (error) {
    throw error
  }
}

export async function sendContractToEmailAPI(contractId: string, fileName: string): Promise<void> {
  try {
    const url = `${CONTRACT_BASE_URL}/send-email/${contractId}/${fileName}`
    await apiV2.get(url)
  } catch (error) {
    throw error
  }
}

export async function showContractFileForUserAPI(
  contractId: string,
  filename: string,
  acceptType: string
): Promise<BlobPart> {
  try {
    const url = `${CONTRACT_BASE_URL}/show-file-from-user/${contractId}/${filename}`
    const response: AxiosResponse = await apiV2.get(url, {
      responseType: 'arraybuffer',
      headers: {
        Accept: acceptType,
      },
      skipEncryption: true,
    })
    return response?.data
  } catch (error) {
    throw error
  }
}

export async function getContractListAdminAPI(): Promise<IContractListResponse> {
  try {
    const response: AxiosResponse = await apiV2.get(APIRouters.contract.listOfAdmin.value)
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/contract', 'getContractListAdmin')
    return {} as IContractListResponse
  }
}

export async function getContractListAdminWithPaginationAPI(
  payload: IContractListWithPaginationRequest
): Promise<ISuccessResponse<IContractListWithPaginationResponse>> {
  try {
    const url = APIRouters.contract.listOfAdmin.value
    return apiV2.post(url, payload)
  } catch (error) {
    throw error
  }
}

export async function createContractAPI(payload: IContractPayload): Promise<IContract> {
  try {
    const response: AxiosResponse = await apiV2.post(APIRouters.contract.create.value, payload)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function getContractDetailAPI(contractId: string): Promise<IContractDetailResponse> {
  try {
    const response: AxiosResponse = await apiV2.get(APIRouters.contract.detailOfAdmin.value(contractId))
    return response.data
  } catch (error) {
    throw error
  }
}

export async function updateContractAPI(payload: IContractPayload, contractId: string): Promise<IResponseWithMessage> {
  try {
    const response: AxiosResponse = await apiV2.post(APIRouters.contract.update.value(contractId), payload)
    return response.data
  } catch (error) {
    throw error
  }
}
