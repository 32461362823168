/* eslint-disable import/order */
//* INFO: import FullCalendar package must before import plugins
import { MutableRefObject } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import momentPlugin from '@fullcalendar/moment'
import styles from './calendar.module.scss'
import { Calendar } from '@fullcalendar/core'
import { ECalendarViewType, ICalendarClickEvent, ICalendarSelectInfo, IEventRenderInfo } from './constants'
import {
  customEventWithDayView,
  customEventWithMonthView,
  customEventWithWeekView,
  getHeaderHTMLWithMonthView,
  getHeaderHTMLWithWeekView,
  handleDayRender,
} from './utils'
import { DateInput } from '@fullcalendar/core/datelib/env'
import { ICalendarEvent } from 'containers/TimeSheet/TimeSheetBoard/components/CalendarSection/types'
import {
  DEFAULT_SLOT_DURATION,
  TIME_GRID_WEEK_SLOT_DURATION,
} from 'containers/TimeSheet/TimeSheetBoard/components/CalendarSection/constants'

interface ICalendarProps {
  calendarRef: MutableRefObject<FullCalendar>
  editable?: boolean
  defaultView?: ECalendarViewType
  selectable?: boolean
  events?: ICalendarEvent[]
  handleSelect?: (selectInfo: ICalendarSelectInfo) => void
  handleClickEvent?: (info: ICalendarClickEvent) => void
}

const CustomCalendar = (props: ICalendarProps) => {
  const {
    calendarRef,
    events,
    editable,
    selectable,
    handleClickEvent,
    handleSelect,
    defaultView = ECalendarViewType.TIME_GRID_WEEK,
  } = props
  const calendarApi: Calendar = calendarRef.current?.getApi()
  const viewType: string = calendarApi?.view.type

  function handleEventRender(info: IEventRenderInfo): void {
    switch (viewType) {
      case ECalendarViewType.DAY_GRID_MONTH:
        customEventWithMonthView(info)
        break
      case ECalendarViewType.TIME_GRID_WEEK:
        customEventWithWeekView(info)
        break
      case ECalendarViewType.TIME_GRID_DAY:
        customEventWithDayView(info)
        break
      default:
        break
    }
  }

  function handleColumnHeaderRender(date: DateInput): string {
    switch (viewType) {
      case ECalendarViewType.DAY_GRID_MONTH:
        return getHeaderHTMLWithMonthView(date)
      case ECalendarViewType.TIME_GRID_WEEK:
        return getHeaderHTMLWithWeekView(date)
      default:
        return getHeaderHTMLWithMonthView(date)
    }
  }

  function getContentHeight(): number | 'auto' {
    if (viewType === ECalendarViewType.TIME_GRID_WEEK) {
      // *INFO: using 15px height for Week view
      return 15
    }

    return 'auto'
  }

  function getSlotDuration(): string {
    if (viewType === ECalendarViewType.TIME_GRID_WEEK) {
      // *INFO: using 15 minutes duration for Week view
      return TIME_GRID_WEEK_SLOT_DURATION
    }

    return DEFAULT_SLOT_DURATION
  }

  return (
    <div className={styles.container}>
      <FullCalendar
        header={false}
        defaultView={defaultView}
        ref={calendarRef}
        firstDay={1}
        editable={editable}
        selectable={selectable}
        select={handleSelect}
        eventTimeFormat={{ hour: '2-digit', minute: '2-digit', hourCycle: 'h23' }}
        plugins={[interactionPlugin, timeGridPlugin, momentPlugin, dayGridPlugin]}
        allDaySlot={false}
        slotEventOverlap={false}
        slotLabelFormat={{ hour: '2-digit', minute: '2-digit', hourCycle: 'h23' }}
        slotDuration={getSlotDuration()}
        nowIndicator={true}
        views={{
          week: {
            titleFormat: { month: 'short', day: 'numeric' },
          },
        }}
        height={'parent'}
        contentHeight={getContentHeight()}
        events={events}
        eventRender={handleEventRender}
        dayRender={handleDayRender}
        columnHeaderHtml={handleColumnHeaderRender}
        eventClick={handleClickEvent}
      />
    </div>
  )
}

export default CustomCalendar
