import { FormControl, FormLabel, HStack, Link } from '@chakra-ui/react'
import NoDataText from 'components/NoDataText'
import { useFormContext } from 'react-hook-form'

export interface IWebLinkFormProps {
  name: string
  label: string
  isHighligh?: boolean
}

const WebLinkForm = (props: IWebLinkFormProps) => {
  const { name, label, isHighligh } = props
  const { getValues } = useFormContext()
  const currentValues: string = getValues(name)
  const formattedValue: string = currentValues?.includes('https://') ? currentValues : 'https://' + currentValues

  return (
    <FormControl padding={{ sm: '10px', md: '10px', lg: '0px' }}>
      <HStack spacing="14px" maxHeight={6} marginBottom={2} position="relative">
        <FormLabel fontWeight={'normal'} color="gray.700" lineHeight={6} marginBottom={0} marginInlineEnd={0}>
          {label}
        </FormLabel>
      </HStack>
      {!currentValues && <NoDataText />}
      {currentValues && (
        <Link
          href={formattedValue}
          target="_blank"
          fontSize="md"
          color={isHighligh ? 'blue.500' : 'gray.700'}
          fontWeight={'500'}
          width={'90%'}
          border={0}
          _hover={{
            bg: 'white',
          }}>
          {currentValues}
        </Link>
      )}
    </FormControl>
  )
}

export default WebLinkForm
