import { ITableHeader } from 'types/table'

export const headerListDesktop: ITableHeader[] = [
  {
    Header: 'Full name',
    accessor: 'fullName',
  },
  {
    Header: 'Birthday (MMM DD, YYYY)',
    accessor: 'birthday',
  },
]
