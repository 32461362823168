import React from 'react'
import { ButtonGroup, Menu, MenuItem, MenuButton, MenuList } from '@chakra-ui/react'
import Button from 'components/Button'
import { getValidArray } from 'utils/commonUtils'
import { IMoreAction, IAction } from './interfaces'

const MoreAction = (props: IMoreAction) => {
  const { actions, isDetail } = props

  const viewDetail = actions[0]
  const remainingActions = actions.slice(1)

  return (
    <>
      {getValidArray(actions).length > 0 && (
        <React.Fragment>
          <ButtonGroup paddingBottom="10px" size="sm" isAttached variant="outline">
            <Menu isLazy>
              <Button variant="solid" onClick={viewDetail.handler}>
                {viewDetail.name}
              </Button>
              {!isDetail && actions.length > 1 && (
                <>
                  <MenuButton variant="solid" as={Button} rightIcon="arrow-down-s-line.svg">
                    More
                  </MenuButton>
                  <MenuList paddingTop={{ sm: '0' }} paddingBottom={{ sm: 0 }} minWidth={{ sm: '6rem' }}>
                    {remainingActions.map((action: IAction, index) => {
                      return (
                        <MenuItem
                          borderBottom="1px solid #fafafa"
                          key={index}
                          onClick={action.handler}
                          color={action.name === 'Delete' ? 'red' : 'black'}>
                          {action.name}
                        </MenuItem>
                      )
                    })}
                  </MenuList>
                </>
              )}
            </Menu>
          </ButtonGroup>
        </React.Fragment>
      )}
    </>
  )
}

export default MoreAction
