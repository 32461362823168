import { MutableRefObject, useRef, useState, useEffect } from 'react'
import { VStack } from '@chakra-ui/react'
import { Calendar } from '@fullcalendar/core'
import FullCalendar from '@fullcalendar/react'
import { ITodoOfHR } from 'API/todoOfHR/constants'
import { ECalendarOfTodoListOfHRViewType } from 'components/Calendar/constants'
import ConfirmYesNoDeleteModal from 'components/ConfirmYesNoDeleteModal'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import { EConditionGetList } from 'constants/enum'
import { ELocalStorageKeys } from 'constants/localStorageKeys'
import { EUserLevel } from 'constants/user'
import { getYearFromDate, getMonthFromDate, getFormattedDateWith_MMMDDYYYY } from 'utils/dateFormatUtils'
import { useStores } from 'utils/hooks/useStores'
import AddTodoModal from '../components/AddTodoModal'
import { ITodoOfHRForm } from '../components/AddTodoModal/constants'
import CalendarSection from '../components/CalendarSection'
import HeaderSection from '../components/HeaderSectionOfTodo'
import TodoListOfHR from '../components/TodoListOfHR'
import YearCalendarSection from '../components/YearCalendarSection'
import { ITodoOfHRFilter } from './constants'

const ToDoListOfHR = () => {
  const [isCreateTodoOfHRModalOpen, setIsCreateTodoOfHRModalOpen] = useState<boolean>(false)
  const [isEditTodoOfHRModalOpen, setIsEditTodoOfHRModalOpen] = useState<boolean>(false)
  const [selectedTodoOfHR, setSelectedTodoOfHR] = useState<ITodoOfHR>()
  const [dateTitle, setDateTitle] = useState<string>(dayjs().format('MMMM, YYYY'))
  const [isDeleteTodoOfHRModalOpen, setIsDeleteTodoOfHRModalOpen] = useState<boolean>(false)
  const { userHumanResourcesStore, userTodoOfHRStore } = useStores()
  const { userNameListWithAGivenTitle } = userHumanResourcesStore
  const { selectedDateFromCalendar, todoOfHRGroupsByMonth } = userTodoOfHRStore
  const [filter, setFilter] = useState<ITodoOfHRFilter>({})
  const userLevel: string = window.localStorage.getItem(ELocalStorageKeys.USER_LEVEL)
  const filteredYear = getYearFromDate(selectedDateFromCalendar)
  const isManager = userLevel === EUserLevel.MANAGER ?? false
  const [viewMode, setViewMode] = useState<ECalendarOfTodoListOfHRViewType>(
    ECalendarOfTodoListOfHRViewType.DAY_GRID_MONTH
  )
  const calendarRefOfHR: MutableRefObject<FullCalendar> = useRef<FullCalendar>(null)

  function handleOpenCreateTodoOfHRModal(): void {
    setIsCreateTodoOfHRModalOpen(true)
  }
  function handleCloseCreateTodoOfHRModal(): void {
    setIsCreateTodoOfHRModalOpen(false)
  }
  async function createTodoOfHR(data: ITodoOfHRForm) {
    const formattedData = {
      title: data?.title,
      description: data?.description,
      assignee: `${data?.assignee?.value}`,
      priority: `${data?.priority?.value}`,
      startDate: data.startDate,
      dueDate: data.dueDate,
      isRecurringMonthly: data.isRecurringMonthly,
    }
    await userTodoOfHRStore.createTodoOfHR(formattedData)
    setIsCreateTodoOfHRModalOpen(false)
    await userTodoOfHRStore.getTodoOfHRGroupsByMonth(filter)
  }
  async function updateTodoOfHR(data: ITodoOfHRForm) {
    const formattedData = {
      id: data?.id,
      title: data?.title,
      description: data?.description,
      assignee: `${data?.assignee?.value}`,
      priority: `${data?.priority?.value}`,
      startDate: data.startDate,
      dueDate: data.dueDate,
      isRecurringMonthly: data.isRecurringMonthly,
    }
    await userTodoOfHRStore.editTodoOfHR(formattedData)
    setIsEditTodoOfHRModalOpen(false)
    await userTodoOfHRStore.getTodoOfHRGroupsByMonth(filter)
  }
  function openConfirmDeleteModal(todoOfHR: ITodoOfHR) {
    setSelectedTodoOfHR(todoOfHR)
    setIsDeleteTodoOfHRModalOpen(true)
  }
  function openEditTodoOfHRModal(todoOfHR: ITodoOfHR) {
    setSelectedTodoOfHR(todoOfHR)
    setIsEditTodoOfHRModalOpen(true)
  }
  function closeEditTodoOfHRModal() {
    setIsEditTodoOfHRModalOpen(false)
  }
  function closeDeleteTodoOfHRModal() {
    setIsDeleteTodoOfHRModalOpen(false)
  }
  async function changeStateHandler(newValue, todoOfHRId) {
    const newStateValue = newValue?.value
    await userTodoOfHRStore.changeStateHandler(newStateValue, todoOfHRId)
    await userTodoOfHRStore.getTodoOfHRGroupsByMonth(filter)
  }
  async function handleDeleteTodoOfHR() {
    await userTodoOfHRStore.deleteTodoOfHR(selectedTodoOfHR?.id)
    setIsDeleteTodoOfHRModalOpen(false)
    await userTodoOfHRStore.getTodoOfHRGroupsByMonth(filter)
  }

  function handleDateTitle(): void {
    const calendarApi: Calendar = calendarRefOfHR.current?.getApi()
    const currentDate: Date = calendarApi.getDate()
    const formattedDate: string = dayjs(currentDate).format('YYYY-MM-DD')
    userTodoOfHRStore.setSelectedDateFromCalendar(formattedDate)
    setDateTitle(getFormattedDateWith_MMMDDYYYY(currentDate))
  }

  function handleDateTitleOfYearCalendar(date: Date): void {
    const formattedDate: string = dayjs(date).format('YYYY-MM-DD')
    userTodoOfHRStore.setSelectedDateFromCalendar(formattedDate)
  }

  useEffect(() => {
    userHumanResourcesStore.getHRUserNameList(EConditionGetList.ACTIVE)
  }, [])
  useEffect(() => {
    const actualFilter: ITodoOfHRFilter = {}
    switch (viewMode) {
      case ECalendarOfTodoListOfHRViewType.DAY_GRID_MONTH:
        const month = getMonthFromDate(selectedDateFromCalendar)
        actualFilter.filteredDate = {
          start: new Date(filteredYear, month - 1, 1),
          end: new Date(filteredYear, month, 31),
        }
        break
      case ECalendarOfTodoListOfHRViewType.DAY_GRID_YEAR:
        actualFilter.filteredDate = {
          start: new Date(filteredYear, 0, 1),
          end: new Date(filteredYear, 11, 31),
        }
        break
      default:
        break
    }
    setFilter(actualFilter)
    userTodoOfHRStore.getTodoOfHRGroupsByMonth(actualFilter)
  }, [selectedDateFromCalendar, viewMode])

  return (
    <VStack marginTop={6} spacing={6} width="full">
      <HeaderSection
        viewMode={viewMode}
        setViewMode={setViewMode}
        dateTitle={dateTitle}
        setDateTitle={setDateTitle}
        handleDateTitle={handleDateTitle}
        handleDateTitleOfYearCalendar={handleDateTitleOfYearCalendar}
        calendarRef={calendarRefOfHR}
        handleOpenModal={handleOpenCreateTodoOfHRModal}
      />
      {viewMode === ECalendarOfTodoListOfHRViewType.DAY_GRID_MONTH ? (
        <CalendarSection calendarRef={calendarRefOfHR} />
      ) : (
        <YearCalendarSection selectedYear={filteredYear} setViewMode={setViewMode} handleDateTitle={handleDateTitle} />
      )}
      <TodoListOfHR
        dataGroupsByMonth={todoOfHRGroupsByMonth}
        isManager={isManager}
        openEditModal={openEditTodoOfHRModal}
        openConfirmDeleteModal={openConfirmDeleteModal}
        changeStateHandler={changeStateHandler}
      />
      <AddTodoModal
        isOpenModal={isCreateTodoOfHRModalOpen}
        userNameList={userNameListWithAGivenTitle}
        saveHandler={createTodoOfHR}
        closeHandler={handleCloseCreateTodoOfHRModal}
      />
      <AddTodoModal
        selectedItem={selectedTodoOfHR}
        isEdit={true}
        saveHandler={updateTodoOfHR}
        isOpenModal={isEditTodoOfHRModalOpen}
        userNameList={userNameListWithAGivenTitle}
        closeHandler={closeEditTodoOfHRModal}
      />
      <ConfirmYesNoDeleteModal
        isOpen={isDeleteTodoOfHRModalOpen}
        title="Todo"
        closeHandler={closeDeleteTodoOfHRModal}
        OKClickHandler={handleDeleteTodoOfHR}
        itemId={selectedTodoOfHR?.id}
      />
    </VStack>
  )
}
export default observer(ToDoListOfHR)
