import {
  getCountryListAPI,
  createCountryAPI,
  getPaginatedCountriesAPI,
  deleteCountryAPI,
  updateCountryAPI,
} from 'API/country'
import { ICountry, ICountryCreationForm } from 'API/country/constants'
import { handleError } from 'API/error'
import get from 'lodash/get'
import { makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'
import RootStore from '../rootStore'

class AdminCountryStore {
  rootStore: RootStore
  countryList: ICountry[] = []
  listCount: number = 0
  isLoadNewCountryList: boolean = false
  isLoadingCountryList: boolean = false

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public async getCountryList(): Promise<void> {
    try {
      const { countries, count } = await getCountryListAPI()
      this.countryList = countries
      this.listCount = count
    } catch (error) {
      handleError(error, 'src/store/admin/AdminCountryStore.ts', 'getCountryList')
    }
  }

  public async getPaginatedCountries(isReset?: boolean, history?: any, filter?: any): Promise<void> {
    try {
      this.isLoadingCountryList = true
      const page: number = isReset ? 1 : Number(get(history, 'location.state.page') || 1)
      const payload = {
        page,
        pageSize: 10,
        filter,
      }
      const { countries, count } = await getPaginatedCountriesAPI(payload)
      this.countryList = countries
      this.listCount = count
      this.isLoadNewCountryList = false
    } catch (error) {
      handleError(error, 'src/store/admin/AdminCountryStore.ts', 'getPaginatedCountries')
    } finally {
      this.isLoadingCountryList = false
    }
  }

  public async createCountry(countries: ICountryCreationForm): Promise<void> {
    try {
      const message = await createCountryAPI(countries)
      toast.success(message)
      this.isLoadNewCountryList = true
    } catch (error) {
      handleError(error, 'src/store/admin/AdminCountryStore.ts', 'createCountry', false, true)
    }
  }

  public async updateCountry(countries: ICountry): Promise<void> {
    try {
      const message = await updateCountryAPI(countries)
      toast.success(message)
      this.isLoadNewCountryList = true
    } catch (error) {
      handleError(error, 'src/store/admin/AdminCountryStore.ts', 'updateCountry', false, true)
    }
  }

  public async deleteCountry(technologyId: string): Promise<void> {
    try {
      const message = await deleteCountryAPI(technologyId)
      toast.success(message)
      this.isLoadNewCountryList = true
    } catch (error) {
      handleError(error, 'src/store/admin/AdminCountryStore.ts', 'deleteCountry', false, true)
    }
  }
}

export default AdminCountryStore
