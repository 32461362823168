import { AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Box, Text } from '@chakra-ui/react'

interface IAccordion {
  label?: string
  component?: JSX.Element
}

const ItemAccordion = (props: IAccordion) => {
  const { label, component } = props
  return (
    <AccordionItem>
      <Text>
        <AccordionButton bg={'white'}>
          <Box width={'auto'} color={'gray.500'} flex="1" textAlign="left">
            {label}
          </Box>
          <AccordionIcon color={'gray.500'} />
        </AccordionButton>
      </Text>
      <AccordionPanel width={'100%'} padding={{ base: '8px 16px', sm: '0px' }} pb={{ base: 4, sm: 0 }}>
        {component}
      </AccordionPanel>
    </AccordionItem>
  )
}

export default ItemAccordion
