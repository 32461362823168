import { IDeveloperNodeData, IDiagramOverview, IProjectNodeData } from 'API/diagram/interface'
import { DiagramSchema, Link, Node } from 'beautiful-react-diagrams/@types/DiagramSchema'
import AdminDiagramStore from 'store/admin/diagramStore'
import { getValidArray, getDisplayName } from 'utils/commonUtils'
import { EUserOfProjectRange, EStatusStyle, EProjectOfUserRange, ISidebarInfo, ISidebarContent } from './constant'

export function clearEventNode(
  schema: DiagramSchema<unknown>,
  adminDiagramStore: AdminDiagramStore,
  onChange: (schemaChanges: DiagramSchema<unknown>) => void
): void {
  schema.nodes.forEach((node) => {
    node.className = ''
  })
  schema.links.forEach((node) => {
    node.className = ''
  })
  onChange(schema)
  adminDiagramStore.setCurrentNodeLinks([])
  adminDiagramStore.setCurrentNode(null)
}

export function handleClassNameProject(value: string | number): string {
  if (Number(value) < EUserOfProjectRange.MIN) {
    return EStatusStyle.LOW
  }
  if (Number(value) > EUserOfProjectRange.MAX) {
    return EStatusStyle.HIGH
  }
  return EStatusStyle.STABLE
}

export function handleClassNameUser(value: string | number): string {
  if (value <= EProjectOfUserRange.MIN) {
    return EStatusStyle.STABLE
  }
  if (value > EProjectOfUserRange.MAX) {
    return EStatusStyle.HIGH
  }
  return EStatusStyle.LOW
}

export function getCommitTimeColor(workingHour: string, commitTime: string): string {
  return workingHour < commitTime ? 'red.500' : 'green.500'
}

export function getNodeDetailInformation(
  diagramNodes: IDiagramOverview,
  isProjectNode: boolean,
  currentNodeLinks: Link[],
  currentNode: Node<unknown>
): ISidebarInfo {
  let sideBarInfo: ISidebarInfo
  if (isProjectNode) {
    const selectedProject: IProjectNodeData = getValidArray(diagramNodes?.projects).find(
      (project: IProjectNodeData) => project?.diagramNode?.id === currentNode?.id
    )

    const nodeDetailContent: ISidebarContent[] = getValidArray(selectedProject?.developers).map(
      (developer: IDeveloperNodeData) => {
        return {
          project: selectedProject?.id,
          user: developer?.id,
          projectName: selectedProject?.name,
          userName: getDisplayName(developer),
          projectPaymentCategory: selectedProject?.paymentCategory,
          information: getDisplayName(developer),
          workingTime: developer?.averageWorkingTime?.toString(),
          commitTime: developer?.commitTime?.toString(),
          userTitle: developer?.userTitle,
        }
      }
    )

    sideBarInfo = {
      id: selectedProject?.id,
      totalWorkingTime: selectedProject?.totalWorkingTime.toString(),
      totalWorkingDate: selectedProject?.totalWorkingDate.toString(),
      title: selectedProject?.name,
      content: nodeDetailContent,
    }
  } else {
    const selectedDeveloper: IDeveloperNodeData = getValidArray(diagramNodes?.users).find(
      (developer: IDeveloperNodeData) => developer?.diagramNode?.id === currentNode?.id
    )

    sideBarInfo = {
      id: selectedDeveloper?.id,
      totalWorkingTime: selectedDeveloper?.totalWorkingTime.toString(),
      totalWorkingDate: selectedDeveloper?.totalWorkingDate.toString(),
      title: getDisplayName(selectedDeveloper),
      content: [],
    }

    getValidArray(currentNodeLinks).forEach((nodeLink) => {
      const project: IProjectNodeData = getValidArray(diagramNodes?.projects).find(
        (project: IProjectNodeData) => project.diagramNode.id === nodeLink?.input
      )

      getValidArray(project?.developers).forEach((developer) => {
        if (developer?.id === selectedDeveloper?.id) {
          sideBarInfo.content.push({
            project: project?.id,
            user: selectedDeveloper?.id,
            projectName: project?.name,
            userName: getDisplayName(selectedDeveloper),
            projectPaymentCategory: project?.paymentCategory,
            information: project?.name,
            workingTime: developer?.averageWorkingTime?.toString(),
            commitTime: developer?.commitTime?.toString(),
            userTitle: developer?.title?.value,
          })
        }
      })
    })
  }
  return sideBarInfo
}

export function getUserNodeDetail(userId: string, diagramNodes: IDiagramOverview): ISidebarInfo {
  const selectedDeveloper: IDeveloperNodeData = getValidArray(diagramNodes?.users).find(
    (user: IDeveloperNodeData) => user?.id === userId
  )

  const userNodeDetail: ISidebarInfo = {
    id: selectedDeveloper?.id,
    totalWorkingTime: selectedDeveloper?.totalWorkingTime?.toString(),
    title: getDisplayName(selectedDeveloper),
    content: [],
  }

  getValidArray(diagramNodes?.projects).forEach((project: IProjectNodeData) => {
    const foundDeveloper: IDeveloperNodeData = getValidArray(project?.developers).find(
      (developer: IDeveloperNodeData) => developer?.id === userId
    )
    if (foundDeveloper?.id) {
      userNodeDetail.content.push({
        project: project?.id,
        user: selectedDeveloper?.id,
        projectName: project?.name,
        userName: getDisplayName(selectedDeveloper),
        projectPaymentCategory: project?.paymentCategory,
        information: project?.name,
        workingTime: foundDeveloper?.averageWorkingTime?.toString(),
        commitTime: foundDeveloper?.commitTime?.toString(),
        userTitle: foundDeveloper?.title?.value,
      })
    }
  })

  return userNodeDetail
}
