import { ResponsiveRadialBar } from '@nivo/radial-bar'
import { observer } from 'mobx-react'
import { defaultProjectColors } from 'containers/Dashboard/constant'

export interface ILoggedTimeData {
  percent: number
  loggedTime: number
}
const LoggedTimeChart = (props: ILoggedTimeData) => {
  const { percent, loggedTime } = props
  const loggedTimeData = [
    {
      id: 'hour',
      data: [
        {
          x: 'Logged',
          y: loggedTime,
        },
      ],
    },
  ]

  const CenteredMetric = ({ center }) => {
    const [centerX, centerY] = center
    if (isNaN(percent) || percent === Infinity) {
      return null
    }

    return (
      <text
        x={centerX}
        y={centerY}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: '18px',
          fontWeight: 900,
          fill: '#718096',
          lineHeight: '28px',
          transform: 'translateY(-5%)',
        }}>
        {percent.toFixed(1)}%
      </text>
    )
  }

  return (
    <ResponsiveRadialBar
      data={loggedTimeData}
      margin={{ top: 10, right: 10, bottom: 20, left: 10 }}
      startAngle={-90}
      endAngle={90}
      innerRadius={0.7}
      tracksColor="#E2E8F0"
      padding={0}
      enableRadialGrid={false}
      enableCircularGrid={false}
      valueFormat={(e) => {
        const hour = Math.floor(Number(e))
        const min = Math.round((Number(e) - hour) * 60)
        return `${hour}h` + (min ? ` ${min}m` : '')
      }}
      maxValue={Math.max(loggedTime, (loggedTime / percent) * 100)}
      colors={defaultProjectColors}
      borderWidth={4}
      borderColor={{
        from: 'color',
      }}
      radialAxisStart={null}
      circularAxisOuter={null}
      layers={['grid', 'tracks', 'bars', 'labels', 'legends', CenteredMetric]}
    />
  )
}

export default observer(LoggedTimeChart)
