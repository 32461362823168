import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  VStack,
  Flex,
  HStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import TagItem from 'components/InputTag/TagItem'
import { observer } from 'mobx-react'
import { backgroundGrey200 } from 'theme/globalStyles'
import { IProject, ITechnology } from 'constants/schema'
import { getValidArray } from 'utils/commonUtils'
import { useStores } from 'utils/hooks/useStores'
import { combineStartedDateEndedDate, getProjectTechnologies } from '../../utils'
import { Title, Text } from './projectSummary.styles'

export interface IProjectSummary {
  projectData: IProject
  isOpen: boolean
  closeHandler: () => void
}

const ProjectSummary = (props: IProjectSummary) => {
  const { projectData, isOpen, closeHandler } = props
  const { adminTechnologyStore } = useStores()
  const { technologyList } = adminTechnologyStore

  const projectPeriod: string = combineStartedDateEndedDate(
    projectData?.startedDate,
    projectData?.endedDate,
    projectData?.untilNow
  )
  const technologiesOfProject: ITechnology[] = getProjectTechnologies(projectData?.technology, technologyList)

  return (
    <Drawer isOpen={isOpen} onClose={closeHandler} placement="bottom">
      <DrawerOverlay />
      <DrawerContent maxHeight="350px">
        <DrawerHeader borderBottom={`1px solid ${backgroundGrey200}`}>Project Summary</DrawerHeader>
        <DrawerCloseButton color={'gray'} background="none" />
        <DrawerBody padding={4}>
          <VStack spacing={6} alignItems="flex-start">
            <Flex direction="column">
              <Title>Project Name</Title>
              <Text>{projectData?.name}</Text>
            </Flex>
            <Flex direction="column">
              <Title>Technology</Title>
              <HStack>
                <Wrap>
                  {getValidArray(technologiesOfProject).map((item: ITechnology) => (
                    <WrapItem
                      key={item._id}
                      backgroundColor={item?.tagColor?.substring(0, item?.tagColor?.length - 1) ?? ''}>
                      <TagItem value={item?.value ?? ''} handleTextColor={(() => item?.tagColor) ?? ''} />
                    </WrapItem>
                  ))}
                </Wrap>
              </HStack>
            </Flex>
            <Flex direction="column">
              <Title>Start Date - End Date</Title>
              <Text>{projectPeriod}</Text>
            </Flex>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default observer(ProjectSummary)
