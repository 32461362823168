import get from 'lodash/get'
import queryString from 'query-string'
import { handleError } from '../../API/error'
import { ILeaveFilter } from '../../containers/Leave/FilterForm/interface'
import { THistory } from '../../types/common'
import RootStore from '../rootStore'

class AdminLeaveManagementStore {
  rootStore: RootStore
  userLeaveList: string
  isLoadingProject: boolean
  count: number

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public async getUserLeaveListWithPagination(history?: THistory, filter?: ILeaveFilter): Promise<void> {
    try {
      this.isLoadingProject = true
      const searchString = history.location.search
      const parsedQuery = queryString.parse(searchString)
      const currentPage: number = Number(get(parsedQuery, 'page', 1))
      //TODO: continue to write this function in Implement API ticket
    } catch (error) {
      handleError(error, 'src/store/admin/projectStore.ts', 'getProjectListWithPagination')
    } finally {
      this.isLoadingProject = false
    }
  }
}

export default AdminLeaveManagementStore
