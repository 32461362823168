import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Tag,
  Text,
  TagLabel,
} from '@chakra-ui/react'
import AvatarDevGroup from 'components/AvatarDevGroup'
import Button from 'components/Button'
import DropdownWithoutForm from 'components/Dropdown/DropdownWithoutForm'
import EmptyContentBox from 'components/EmptyContentBox'
import Icon from 'components/Icon'
import ReactQuill from 'react-quill'
import { backgroundPrimary, backgroundGrey50, backgroundGrey200, borderGray200 } from 'theme/globalStyles'
import { TypeOfState } from 'constants/defaultOptionList'
import { Messages } from 'constants/index'
import { isValidArray } from 'utils/commonUtils'
import { getFormattedDateWith_MMMDDYYYY } from 'utils/dateFormatUtils'
import { createOptionsOfSelect } from 'utils/dropdownOptionUtils'
import PriorityTag from '../PriorityTag'
import { ITodoListOfProps } from './interface'

const TodoListOfHR = (props: ITodoListOfProps) => {
  const { isManager, dataGroupsByMonth, openConfirmDeleteModal, openEditModal, changeStateHandler } = props
  const typesOfState = createOptionsOfSelect(TypeOfState)
  if (!isValidArray(dataGroupsByMonth)) return <EmptyContentBox text={Messages.noData} />
  const formattedDataGroupsByMonth = dataGroupsByMonth.map((item) => {
    const newItem = {
      ...item,
      data: item.data.map((todoOfHR) => {
        const newTodoOfHR = {
          ...todoOfHR,
        }
        const stateValue = todoOfHR.state
        const convertedStateValue = {
          value: stateValue,
          label: stateValue,
        }
        newTodoOfHR.state = convertedStateValue
        return newTodoOfHR
      }),
    }
    return newItem
  })
  return (
    <Box width={'inherit'}>
      {formattedDataGroupsByMonth?.map((item) => {
        return (
          <Box background="white" borderRadius="6px" marginTop={'20px'} width={'inherit'}>
            <Accordion allowMultiple key={item._id} padding={'24px'}>
              <Tag
                variant="subtle"
                colorScheme="blue"
                color="white"
                background={backgroundPrimary}
                userSelect="none"
                padding="4px 12px"
                marginBottom="16px">
                <Icon iconName="calendar-white.svg" />
                <TagLabel marginLeft="3px">{item?._id}</TagLabel>
              </Tag>
              {item?.data?.map((todoOfHR) => {
                return (
                  <AccordionItem
                    key={todoOfHR.id}
                    border={`1px solid ${borderGray200}`}
                    borderRadius="6px"
                    boxShadow="none"
                    backgroundColor={backgroundGrey50}
                    marginBottom="16px">
                    <AccordionButton
                      backgroundColor="white"
                      borderRadius="6px"
                      _expanded={{ background: `${backgroundGrey50}` }}>
                      <AccordionIcon color="black" />
                      <Box
                        display="flex" // Enable flexbox for the Box container
                        alignItems="center" // Vertically centers child elements
                        flex="1"
                        textAlign="left">
                        <Text
                          display={'inline-block'}
                          fontWeight={'500'}
                          fontSize={'16px'}
                          color={'black'}
                          paddingLeft={'10px'}>
                          {todoOfHR?.title}
                        </Text>
                        <PriorityTag priority={todoOfHR.priority} />
                      </Box>
                      <Box>
                        <AvatarDevGroup data={[todoOfHR.assigneeData]} name="assigneeData" />
                      </Box>
                      <Box width={'200px'} margin={'0 10px'}>
                        <DropdownWithoutForm
                          options={typesOfState}
                          value={todoOfHR.state}
                          onChange={(newValue) => changeStateHandler(newValue, todoOfHR.id)}
                        />
                      </Box>
                      <Tag size="md" variant="outline" colorScheme="gray" marginLeft="8px" fontWeight="400">
                        {getFormattedDateWith_MMMDDYYYY(todoOfHR?.startDate)}
                      </Tag>
                      <Tag size="md" variant="outline" colorScheme="gray" marginLeft="8px" fontWeight="400">
                        {getFormattedDateWith_MMMDDYYYY(todoOfHR?.dueDate)}
                      </Tag>
                    </AccordionButton>
                    <AccordionPanel paddingY="16px">
                      <ReactQuill className="task-description" value={todoOfHR.description} theme="bubble" readOnly />
                      {isManager && (
                        <HStack justifyContent="end" borderTop={`1px solid ${backgroundGrey200}`} paddingTop="16px">
                          <Button
                            variant="outline"
                            customStyles={{ color: 'red' }}
                            leftIcon="delete-bin-fill.svg"
                            onClick={() => openConfirmDeleteModal(todoOfHR)}>
                            Delete
                          </Button>
                          <Button variant="outline" leftIcon="edit-fill.svg" onClick={() => openEditModal(todoOfHR)}>
                            Edit
                          </Button>
                        </HStack>
                      )}
                    </AccordionPanel>
                  </AccordionItem>
                )
              })}
            </Accordion>
          </Box>
        )
      })}
    </Box>
  )
}
export default TodoListOfHR
