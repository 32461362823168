import { ITodoOfHRGroupsByMonth, ITodoOfHR } from 'API/todoOfHR/constants'
import dayjs from 'dayjs'
import { DEFAULT_PROJECT_COLOR } from 'constants/common'
import { statusColors } from 'constants/common'
import { isValidArray, getValidArray } from 'utils/commonUtils'
import { ICalendarEvent } from '../types'

export function generateEvents(
  todoOfHRList: ITodoOfHRGroupsByMonth[],
  selectedDateFromCalendar: string
): ICalendarEvent[] {
  if (!isValidArray(todoOfHRList)) {
    return []
  }

  // Parse `selectedDateFromCalendar` để lấy năm và tháng
  const selectedDate = dayjs(selectedDateFromCalendar)
  const selectedYear = selectedDate.year()
  const selectedMonth = selectedDate.month() // Tháng được tính từ 0 (0 = January, 11 = December)

  let finalTodoOfHRList: ICalendarEvent[] = []

  getValidArray(todoOfHRList).forEach((todoOfHRGroup) => {
    const data = todoOfHRGroup.data

    const filteredTodoOfHRList = getValidArray(data)
      .filter((todoOfHR: ITodoOfHR) => {
        const startDate = dayjs(todoOfHR?.startDate)
        return startDate.year() === selectedYear && startDate.month() === selectedMonth
      })
      .map((todoOfHR: ITodoOfHR) => {
        const state = todoOfHR?.state
        const projectColor = statusColors[state] ?? DEFAULT_PROJECT_COLOR
        return {
          id: todoOfHR?.id,
          title: todoOfHR?.title,
          start: dayjs(todoOfHR?.startDate).toISOString(),
          end: dayjs(todoOfHR?.startDate).toISOString(),
          projectColor: projectColor,
        }
      })

    // Nếu `filteredTodoOfHRList` có giá trị, push các phần tử vào `finalTodoOfHRList`
    if (filteredTodoOfHRList.length > 0) {
      finalTodoOfHRList.push(...filteredTodoOfHRList)
    }
  })

  return finalTodoOfHRList
}
