import { handleError } from 'API/error'
import {
  getProjectTypeListAPI,
  createProjectTypeAPI,
  getProjectTypeListWithPaginationAPI,
  deleteProjectTypeAPI,
  updateProjectTypeAPI,
} from 'API/projectType'
import { IProjectType, IProjectTypeDataFromCreateForm } from 'API/projectType/constants'
import get from 'lodash/get'
import { makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'
import RootStore from '../rootStore'

class AdminProjectTypeStore {
  rootStore: RootStore
  projectTypeList: IProjectType[] = []
  listCount: number = 0
  isLoadNewProjectTypeList: boolean = false
  isLoadingProjectTypeList: boolean = false

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public async getProjectTypeList(): Promise<void> {
    try {
      const { projectTypes, count } = await getProjectTypeListAPI()
      this.projectTypeList = projectTypes
      this.listCount = count
    } catch (error) {
      handleError(error, 'src/store/admin/AdminProjectTypeStore.ts', 'getProjectTypeList')
    }
  }

  public async getProjectTypeListWithPagination(isReset?: boolean, history?: any, filter?: any): Promise<void> {
    try {
      this.isLoadingProjectTypeList = true
      const page: number = isReset ? 1 : Number(get(history, 'location.state.page') || 1)
      const payload = {
        page,
        pageSize: 10,
        filter,
      }
      const { projectTypes, count } = await getProjectTypeListWithPaginationAPI(payload)
      this.projectTypeList = projectTypes
      this.listCount = count
      this.isLoadNewProjectTypeList = false
    } catch (error) {
      handleError(error, 'src/store/admin/AdminProjectTypeStore.ts', 'getProjectTypeListWithPagination')
    } finally {
      this.isLoadingProjectTypeList = false
    }
  }

  public async createProjectType(projectType: IProjectTypeDataFromCreateForm): Promise<void> {
    try {
      const message = await createProjectTypeAPI(projectType)
      toast.success(message)
      this.isLoadNewProjectTypeList = true
    } catch (error) {
      handleError(error, 'src/store/admin/AdminProjectTypeStore.ts', 'createProjectType', false, true)
    }
  }

  public async updateProjectType(projectType: IProjectType): Promise<void> {
    try {
      const message = await updateProjectTypeAPI(projectType)
      toast.success(message)
      this.isLoadNewProjectTypeList = true
    } catch (error) {
      handleError(error, 'src/store/admin/AdminProjectTypeStore.ts', 'updateProjectType', false, true)
    }
  }

  public async deleteProjectType(technologyId: string): Promise<void> {
    try {
      const message = await deleteProjectTypeAPI(technologyId)
      toast.success(message)
      this.isLoadNewProjectTypeList = true
    } catch (error) {
      handleError(error, 'src/store/admin/AdminProjectTypeStore.ts', 'deleteProjectType', false, true)
    }
  }
}

export default AdminProjectTypeStore
