import apiV2 from 'API/api'
import { APIRouters } from 'API/APIRouters'
import { AxiosResponse } from 'axios'
import { IInternalDocument } from 'constants/schema'
import { IInternalDocumentDetail, IInternalDocuments, IInternalDocumentsPayload } from './constants'

export async function getInternalDocumentsAPI(payload: IInternalDocumentsPayload): Promise<IInternalDocuments> {
  try {
    const url: string = APIRouters.internalDocument.list.value
    const response: AxiosResponse = await apiV2.post(url, payload)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function updateInternalDocumentAPI(id: string, payload: IInternalDocument): Promise<void> {
  try {
    const url: string = APIRouters.internalDocument.update.internalDocumentId.value(id)
    await apiV2.post(url, payload)
  } catch (error) {
    throw error
  }
}

export async function createInternalDocumentAPI(payload: IInternalDocument): Promise<IInternalDocuments> {
  try {
    const url: string = APIRouters.internalDocument.create.value
    const response: AxiosResponse = await apiV2.post(url, payload)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function getInternalDocumentDetailAPI(internalDocumentId: string): Promise<IInternalDocumentDetail> {
  try {
    const url: string = APIRouters.internalDocument.detail.internalDocumentId.value(internalDocumentId)
    const response: AxiosResponse = await apiV2.get(url)
    return response.data
  } catch (error) {
    throw error
  }
}
