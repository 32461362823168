import { DateInput } from '@fullcalendar/core/datelib/env'
import dayjs, { Dayjs } from 'dayjs'
import { generateHeaderHTML } from './common'

export function getHeaderHTMLWithMonthView(date: DateInput): string {
  const dateValue: Dayjs = dayjs(date.toString())
  const dayOfWeekText: string = dateValue.format('ddd')

  return generateHeaderHTML(dayOfWeekText)
}

export function getHeaderHTMLWithWeekView(date: DateInput): string {
  const dateValue: Dayjs = dayjs(date.toString())
  const dayOfWeekText: string = dateValue.format('ddd')
  const dayText: string = dateValue.format('DD')

  return generateHeaderHTML(dayOfWeekText, dayText)
}
