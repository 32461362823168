import { Text } from '@chakra-ui/react'
import { textGrey500 } from 'theme/globalStyles'
import { convertTextToColor } from 'utils/commonUtils'
import { isFunction } from '../container'
import { ITagItem } from './interfaces'

const TagItem = (props: ITagItem) => {
  const { value, className, handleTextColor, color } = props
  const isTextColor: boolean = isFunction(handleTextColor)
  const backgroundColor = convertTextToColor(color, 0.2)
  return (
    <Text
      className={className}
      border={`1px solid ${textGrey500}`}
      backgroundColor={backgroundColor}
      borderColor={color}
      borderRadius={'6px'}
      margin={{ lg: '0px', md: '2px', sm: '2px' }}
      padding={'4px 12px'}
      color={isTextColor ? handleTextColor(value ?? '') : undefined}>
      {value}
    </Text>
  )
}

export default TagItem
