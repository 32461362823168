import { ISuccessResponse } from 'API/constants'
import { handleError } from 'API/error'
import { getProjectDetailOfUserV2API } from 'API/project'
import { IProjectDetailResponse } from 'API/project/constants'
import {
  getDetailTimeSheetForUserV2API,
  getTimeSheetListOfProjectV2API,
  deleteTimesheetV2API,
  createTimesheetV2API,
  createSmartTimesheetV2API,
  updateTimesheetV2API,
} from 'API/timesheet'
import {
  IDetailTimesheetResponseData,
  IGroupTimeSheetToFE,
  ITimeSheetToFE,
  ITimesheet,
  ITimesheetRequest,
} from 'API/timesheet/constants'
import { ICreateSmartTimesheetResponseItem } from 'API/timesheet/constants'
import { ICalendarTimeData } from 'components/Calendar/constants'
import dayjs from 'dayjs'
import { makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'
import RootStore from 'store/rootStore'
import { IProject } from 'types/project'
import { EResponseStatus } from 'constants/enum'
import { Messages } from 'constants/index'
import { isValidArray } from 'utils/commonUtils'

class UserTimesheetStore {
  rootStore: RootStore
  project: IProject
  timesheetList: ITimeSheetToFE[]
  groups: IGroupTimeSheetToFE[]
  projects: IProject[]
  currentTimesheet: ITimesheet
  isDuplicate: boolean
  calendarTimeData: ICalendarTimeData
  isLoadNewTimesheetList: boolean = false
  selectedDateFromCalendar: string = dayjs().format('YYYY-MM-DD')
  errorOfCreateSmartTimesheet: ICreateSmartTimesheetResponseItem[] = []
  isSuccessCreatedAllSmartTimesheet: boolean = false

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public async fetchTimesheets(projectIds?: string[], currentDate?: string, period?: string): Promise<void> {
    try {
      const response = await getTimeSheetListOfProjectV2API(projectIds, currentDate, period)
      this.timesheetList = response?.data?.timesheets
      this.groups = response?.data?.groups
      this.isLoadNewTimesheetList = false
    } catch (error) {
      handleError(error as Error, 'src/store/user/timesheetStore.ts', 'fetchTimesheets')
    }
  }

  public async fetchProjectDetail(projectId: string): Promise<void> {
    try {
      const response: ISuccessResponse<IProjectDetailResponse> = await getProjectDetailOfUserV2API(projectId)
      this.project = response?.data?.project
    } catch (error) {
      handleError(error as Error, 'src/store/user/timesheetStore.ts', 'fetchProjectDetail')
    }
  }

  public async fetchTimesheetDetail(timesheetId: string): Promise<void> {
    try {
      const response: ISuccessResponse<IDetailTimesheetResponseData> = await getDetailTimeSheetForUserV2API(timesheetId)
      this.currentTimesheet = response?.data?.timesheet
    } catch (error) {
      handleError(error as Error, 'src/store/user/timesheetStore.ts', 'fetchProjectsOfUser')
    }
  }

  public async createTimesheetV2(payload: Partial<ITimesheet>, kachingAudio: HTMLAudioElement): Promise<void> {
    try {
      const message = await createTimesheetV2API(payload)
      toast.success(message)
      kachingAudio.play()
      this.isLoadNewTimesheetList = true
    } catch (error) {
      handleError(error, 'src/store/admin/AdminTimesheetStore.ts', 'createTimesheetV2', false, true)
    }
  }

  public async createSmartTimesheetV2(payload: Partial<ITimesheet>[], kachingAudio: HTMLAudioElement): Promise<void> {
    try {
      const responseDataArray = await createSmartTimesheetV2API(payload)
      let errorResponse = []
      let successResponse = []
      let haveSuccessResponse = false
      let haveAllSuccessResponse = true

      if (responseDataArray && isValidArray(responseDataArray)) {
        responseDataArray.forEach((responseData) => {
          if (responseData.data.status === EResponseStatus.ERROR) {
            errorResponse.push(responseData)
            haveAllSuccessResponse = false
          } else {
            successResponse.push(responseData)
            haveSuccessResponse = true
          }
        })
      }
      if (haveAllSuccessResponse) {
        toast.success(Messages.createAllSmartTimesheetSuccess)
        kachingAudio.play()
      } else if (!haveSuccessResponse) {
        toast.error(Messages.createAllSmartTimesheetFailed)
      } else if (errorResponse.length > 0 && successResponse.length > 0) {
        toast.warning(Messages.createPartialTimesheetSuccess)
      }
      this.isLoadNewTimesheetList = true
      this.errorOfCreateSmartTimesheet = errorResponse
      this.isSuccessCreatedAllSmartTimesheet = haveAllSuccessResponse
    } catch (error) {
      handleError(error, 'src/store/admin/AdminTimesheetStore.ts', 'createSmartTimesheetV2', false, true)
    }
  }

  public resetErrorOfCreateSmartTimesheet() {
    this.errorOfCreateSmartTimesheet = []
    this.isSuccessCreatedAllSmartTimesheet = false
  }

  public async updateTimesheetV2(
    timesheetId: string,
    payload: ITimesheetRequest,
    kachingAudio: HTMLAudioElement
  ): Promise<void> {
    try {
      const message = await updateTimesheetV2API(timesheetId, payload)
      toast.success(message)
      kachingAudio.play()
      this.isLoadNewTimesheetList = true
    } catch (error) {
      handleError(error, 'src/store/admin/AdminTimesheetStore.ts', 'updateTimesheetV2', false, true)
    }
  }

  public async deleteTimesheetV2(timesheetId: string): Promise<void> {
    try {
      const message = await deleteTimesheetV2API(timesheetId)
      toast.success(message)
      this.isLoadNewTimesheetList = true
    } catch (error) {
      handleError(error, 'src/store/user/UserTimesheetStore.ts', 'deleteTimesheetV2', false, true)
    }
  }

  public resetTimesheetDetail(): void {
    this.currentTimesheet = undefined
  }

  public setIsDuplicate(currentIsDuplicate: boolean): void {
    this.isDuplicate = currentIsDuplicate
  }

  public setCalendarTimeData(currentTimeData: ICalendarTimeData): void {
    this.calendarTimeData = currentTimeData
  }

  public setSelectedDateFromCalendar(currentSelectedDate: string): void {
    this.selectedDateFromCalendar = currentSelectedDate
  }

  public resetTimesheetList() {
    this.timesheetList = []
  }
}

export default UserTimesheetStore
