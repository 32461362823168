import { HStack, Icon, VStack, Text } from '@chakra-ui/react'
import { getValidArray } from 'utils/commonUtils'
import { ITooltipOption } from '../../constant'

interface ITooltipOptionProps {
  data: ITooltipOption[]
}

const TooltipOption = (props: ITooltipOptionProps) => {
  const { data } = props
  return (
    <VStack alignItems="flex-start">
      {getValidArray(Array.from(Array(3))).map((element, index) => {
        return (
          <HStack key={index}>
            <Icon key={data[index]?.color} viewBox="0 0 200 200" color={data[index]?.color}>
              <path fill="currentColor" d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0" />
            </Icon>
            <Text key={data[index]?.content}>{data[index]?.content}</Text>
          </HStack>
        )
      })}
    </VStack>
  )
}

export default TooltipOption
