import { chakra, Text } from '@chakra-ui/react'
import { textGrey600 } from 'theme/globalStyles'

export const TextTitle = chakra(Text, {
  baseStyle: () => ({
    margin: '0px',
    fontWeight: '700',
    fontSize: 'xs',
    color: `${textGrey600}`,
    textTransform: 'uppercase',
  }),
})
