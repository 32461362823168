import { useEffect, useState } from 'react'
import { Avatar } from '@chakra-ui/avatar'
import { Tooltip } from '@chakra-ui/tooltip'
import { getAvatarAPI } from 'API/user'
import { useHistory } from 'react-router-dom'
import { generateImageURL } from 'utils/imageUtils'

export interface AvatarBubbleProps {
  userId: string
}

const AvatarBubble = (props: AvatarBubbleProps) => {
  const { userId } = props
  const history = useHistory()
  const [avatarUrl, setAvatarUrl] = useState('')

  function handleBackToUserDetail() {
    const pathname = `/user-page/detail/${userId}?isShowQuickAccessBubble=true`
    history.push(pathname)
  }

  async function setAvatar() {
    const response = await getAvatarAPI(userId)
    const avatarFileName = response?.data?.avatar
    setAvatarUrl(generateImageURL(avatarFileName, 'avatar'))
  }

  useEffect(() => {
    if (userId) {
      setAvatar()
    }
  }, [userId])

  return (
    <>
      <Tooltip label="Back to user detail" placement="left" hasArrow offset={[0, 10]}>
        <Avatar
          src={avatarUrl}
          w="48px"
          h="48px"
          borderRadius="50%"
          cursor="pointer"
          objectFit="cover"
          onClick={handleBackToUserDetail}
        />
      </Tooltip>
    </>
  )
}
export default AvatarBubble
