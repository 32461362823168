import { useEffect, useState } from 'react'
import { Search2Icon } from '@chakra-ui/icons'
import { VStack, HStack, Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import { ITaskType, ITaskTypeDataFromCreateForm } from 'API/taskType/constants'
import Button from 'components/Button'
import ConfirmModal from 'components/ConfirmModal'
import { EModalType } from 'components/ConfirmModal/enums'
import Table from 'components/Table'
import OptionButtonsWithoutDropBox from 'components/Table/DesktopTable/components/OptionButtonsWithoutDropBox'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { backgroundGrey200 } from 'theme/globalStyles'
import { EActionType } from 'constants/enum'
import { frontendRoutes } from 'constants/routes'
import { getValidArray } from 'utils/commonUtils'
import { createOptionsOfSelect } from 'utils/dropdownOptionUtils'
import { useStores } from 'utils/hooks/useStores'
import TaskTypeModal from '../components/TaskTypeModal'
import { getTaskTypeHeaderList } from '../constants'
import styles from '../globalConfig.module.scss'
import { getUniqueKeywordsList } from '../utils'

const TaskType = () => {
  const { adminTaskTypeStore } = useStores()
  const history = useHistory()
  const { listCount, taskTypeList, isLoadNewTaskTypeList, isLoadingTaskTypeList } = adminTaskTypeStore
  const [isOpenCreateModal, setIsOpenCreateModal] = useState<boolean>(false)
  const [isOpenConfirmDeleteModal, setIsOpenConfirmDeleteModal] = useState<boolean>(false)
  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false)
  const [filter, setFilter] = useState({})
  const [selectedTaskType, setSelectedTaskType] = useState<ITaskType>()
  const keywordsList = getUniqueKeywordsList(taskTypeList)
  const keywordsOptions = createOptionsOfSelect(keywordsList)

  useEffect(() => {
    adminTaskTypeStore.getTaskTypeListWithPagination(true, history, filter)
  }, [filter, isLoadNewTaskTypeList])

  function openCreateModal(): void {
    setIsOpenCreateModal(true)
  }
  function closeCreateModal(): void {
    setIsOpenCreateModal(false)
    setSelectedTaskType(null)
  }
  function openConfirmDeleteModal(taskType: ITaskType): void {
    setSelectedTaskType(taskType)
    setIsOpenConfirmDeleteModal(true)
  }
  function closeConfirmDeleteModal(): void {
    setSelectedTaskType(null)
    setIsOpenConfirmDeleteModal(false)
  }
  function closeEditModal(): void {
    setSelectedTaskType(null)
    setIsOpenEditModal(false)
  }
  function openEditModal(taskType: ITaskType): void {
    setSelectedTaskType(taskType)
    setIsOpenEditModal(true)
  }
  function createNewTaskTypeHandler(item: ITaskTypeDataFromCreateForm): void {
    const itemData = {
      value: item.value,
      keywords: item.keywords,
    }
    adminTaskTypeStore.createTaskType(itemData)
    closeCreateModal()
    pagination.gotoPage(1)
  }
  function deleteTaskTypeHandler(id: string): void {
    adminTaskTypeStore.deleteTaskType(id)
    closeConfirmDeleteModal()
    pagination.gotoPage(1)
  }
  function editTaskTypeHandler(item: ITaskType): void {
    const itemData = {
      id: item.id,
      value: item.value,
      keywords: item.keywords,
    }
    adminTaskTypeStore.updateTaskType(itemData)
    closeEditModal()
    pagination.gotoPage(1)
  }

  const tableData = getValidArray(taskTypeList).map((taskType) => {
    const keywordsData = taskType.keywords
    const convertedKeywords = getValidArray(keywordsData).join('; ')
    return {
      ...taskType,
      keywords: convertedKeywords,
      actions: (
        <OptionButtonsWithoutDropBox
          isEdit={true}
          isDelete={true}
          editActionHandler={() => openEditModal(taskType)}
          deleteActionHandler={() => openConfirmDeleteModal(taskType)}
        />
      ),
    }
  })
  const pageIndex: number = Number(get(history, 'location.state.page', 1)) || 1
  const pagination = {
    includePagination: true,
    pageIndex,
    pageSize: 10,
    tableLength: listCount,
    gotoPage: (page: number) => {
      history.push({ pathname: frontendRoutes.settingPage.globalConfig.value, state: { page, filter } })
      adminTaskTypeStore.getTaskTypeListWithPagination(false, history, filter)
    },
  }

  const searchHandler = debounce((event) => {
    setFilter({
      ...filter,
      value: { $regex: event?.target?.value ?? '', $options: 'i' },
    })
  }, 1000)

  return (
    <VStack width="full">
      <HStack marginBottom={6} width={{ base: '100%', md: '100%' }} justifyContent={'space-between'}>
        <InputGroup
          border={`1px solid ${backgroundGrey200}`}
          borderRadius="6px"
          width={{ base: '100%', lg: '540px' }}
          background="white">
          <InputLeftElement pointerEvents="none">
            <Search2Icon color="gray.400" />
          </InputLeftElement>
          <Input type="search" placeholder="Search Task Type by name..." onChange={searchHandler} />
        </InputGroup>
        <Button variant="solid" leftIcon="add-white.svg" onClick={openCreateModal}>
          Create
        </Button>
      </HStack>
      <Table
        className={styles.fullWidth}
        isLoading={isLoadingTaskTypeList}
        headerList={getTaskTypeHeaderList()}
        tableData={tableData}
        isStriped
        pagination={pagination}
        isShowPagination={true}
      />
      <TaskTypeModal
        actionType={EActionType.CREATE}
        isOpen={isOpenCreateModal}
        closeHandler={closeCreateModal}
        saveHandler={createNewTaskTypeHandler}
        data={selectedTaskType}
        isSubmitSuccess={isLoadNewTaskTypeList}
        keywordsOptions={keywordsOptions}
      />
      <TaskTypeModal
        actionType={EActionType.EDIT}
        isOpen={isOpenEditModal}
        closeHandler={closeEditModal}
        saveHandler={editTaskTypeHandler}
        data={selectedTaskType}
        isSubmitSuccess={isLoadNewTaskTypeList}
        keywordsOptions={keywordsOptions}
      />
      <ConfirmModal
        data={selectedTaskType}
        isOpen={isOpenConfirmDeleteModal}
        closeHandler={closeConfirmDeleteModal}
        OKClickHandler={deleteTaskTypeHandler}
        title={'Task Type'}
        content={'name'}
        type={EModalType.DELETE}
      />
    </VStack>
  )
}

export default observer(TaskType)
