import { Dispatch, SetStateAction } from 'react'
import { useDisclosure } from '@chakra-ui/hooks'
import { Menu, MenuButton, MenuList, MenuItem, IconButton } from '@chakra-ui/react'
import { ReactComponent as DeleteIcon } from 'assets/images/svg/delete-bin-fill.svg'
import { ReactComponent as EditIcon } from 'assets/images/svg/edit-fill.svg'
import { ReactComponent as MoreDownIcon } from 'assets/images/svg/more-down.svg'
import startCase from 'lodash/startCase'
import { useHistory } from 'react-router-dom'
import { backgroundBlack100, backgroundGrey600 } from 'theme/globalStyles'
import { THistory } from 'types/common'
import { frontendRoutes } from 'constants/routes'
import { EEditAssignModalType } from 'containers/Diagram/constant'
import EditAssignModal from '../../../EditAssignModal'
import { ISidebarInfo } from '../../constant'
import DeActiveNodeModal from '../DeActiveNodeModal'

export interface IMoreOptionButtonProps {
  isProjectDetail: boolean
  nodeDetailInformation: ISidebarInfo
  isOpenEditModal: boolean
  setIsOpenEditModal: Dispatch<SetStateAction<boolean>>
}

const MoreOptionButton = (props: IMoreOptionButtonProps) => {
  const { isProjectDetail, nodeDetailInformation, isOpenEditModal, setIsOpenEditModal } = props
  const { isOpen: isOpenDeActiveModal, onOpen: onOpenDeActiveModal, onClose: onCloseDeActiveModal } = useDisclosure()
  const nodeType: EEditAssignModalType = isProjectDetail ? EEditAssignModalType.PROJECT : EEditAssignModalType.USER
  const history: THistory = useHistory()

  function handleOnClickEdit(): void {
    setIsOpenEditModal(true)
  }

  function handleCloseEditModel(): void {
    setIsOpenEditModal(false)
    history.replace(frontendRoutes.diagramPage.diagramAdmin.value)
  }

  function openDeActiveModal(): void {
    onOpenDeActiveModal()
  }

  function closeDeActiveModal(): void {
    onCloseDeActiveModal()
  }

  return (
    <>
      <Menu>
        <MenuButton borderRadius="9999px" as={IconButton} icon={<MoreDownIcon />} background="none" />
        <MenuList boxShadow={`0px 1px 3px ${backgroundBlack100}, 0px 1px 2px ${backgroundGrey600}`}>
          <MenuItem background="none" icon={<EditIcon />} onClick={handleOnClickEdit}>
            Edit Assignment
          </MenuItem>
          <MenuItem onClick={openDeActiveModal} background="none" icon={<DeleteIcon />}>
            Deactivate {startCase(nodeType)}
          </MenuItem>
        </MenuList>
      </Menu>
      {isOpenEditModal && (
        <EditAssignModal
          isProjectNode={isProjectDetail}
          isOpen={isOpenEditModal}
          onClose={handleCloseEditModel}
          nodeDetailInformation={nodeDetailInformation}
        />
      )}
      {isOpenDeActiveModal && (
        <DeActiveNodeModal
          modalType={nodeType}
          isOpen={isOpenDeActiveModal}
          onClose={closeDeActiveModal}
          nodeName={nodeDetailInformation?.title}
          nodeId={nodeDetailInformation?.id}
        />
      )}
    </>
  )
}

export default MoreOptionButton
