import { IProjectNameResponse } from 'API/project/constants'
import { IUserNameResponse } from 'API/user/constants'
import InputDropdown from 'components/Dropdown/InputDropdown'
import { IOption } from 'constants/common'
import { createOptionsOfReactSelectFromDB } from 'utils/dropdownOptionUtils'
import { typeOfFilterOfHeadMap } from '../../constant'

export function renderHeatmapFilterOption(
  projectNameList: IProjectNameResponse[],
  userNameList: IUserNameResponse[] | [],
  typeOfFilter: IOption = typeOfFilterOfHeadMap[0]
) {
  const projectOptions: IOption[] = [...createOptionsOfReactSelectFromDB(projectNameList)]
  const userOptions: IOption[] = [...createOptionsOfReactSelectFromDB(userNameList)]
  return (
    <>
      <InputDropdown
        name="typeOfFilter"
        placeHolder="Select Type"
        optionsData={typeOfFilterOfHeadMap}
        isClearable={false}
      />
      {typeOfFilter === typeOfFilterOfHeadMap[0] && (
        <InputDropdown
          name="selectedProject"
          placeHolder="Select Project"
          optionsData={projectOptions}
          isClearable={false}
        />
      )}
      {typeOfFilter === typeOfFilterOfHeadMap[1] && (
        <InputDropdown name="selectedUser" placeHolder="Select User" optionsData={userOptions} isClearable={false} />
      )}
    </>
  )
}
