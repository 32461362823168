export function renderStyle(element: HTMLElement, styles: Record<string, unknown>): void {
  for (let property of Object.keys(styles)) {
    element.style[property.toString()] = styles[property.toString()]
  }
}

export function generateHeaderHTML(dayOfWeekText: string, dayText?: string): string {
  return `<div class="custom-header-content">
    <p>${dayOfWeekText}</p>
    ${dayText ? `<b>${dayText}</b>` : ''}
  </div>`
}

// *INFO: `amount` is the value by which you want to increase the brightness. The amount value can be positive to increase brightness or negative to decrease brightness.
export function makeColorBolder(hexCode: string, amount?: number) {
  let currentHexCode: string = hexCode.replace('#', '')
  // *INFO: default brightness
  const currentAmount: number = amount ?? -60

  // *INFO: Convert the hex code to RGB values
  let red: number = parseInt(currentHexCode.substring(0, 2), 16)
  let green: number = parseInt(currentHexCode.substring(2, 4), 16)
  let blue: number = parseInt(currentHexCode.substring(4, 6), 16)

  // *INFO: Increase the brightness by the specified amount
  red = Math.min(red + currentAmount, 255)
  green = Math.min(green + currentAmount, 255)
  blue = Math.min(blue + currentAmount, 255)

  // *INFO: Convert the RGB values back to a hex code
  const newHexCode: string =
    '#' +
    ('0' + red.toString(16)).slice(-2) +
    ('0' + green.toString(16)).slice(-2) +
    ('0' + blue.toString(16)).slice(-2)

  return newHexCode
}
