export const skill = {
  value: '/skill',
  userId: {
    value: (userId: string) => `/skill/${userId}`,
  },
  average: {
    value: '/skill/average',
  },
  summary: {
    value: '/skill/summary',
    forAdmin: {
      value: '/skill/summary/for-admin',
    },
  },
}
