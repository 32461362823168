import { useState, useEffect } from 'react'
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  HStack,
  VStack,
  FormControl,
  FormLabel,
} from '@chakra-ui/react'
import Button from 'components/Button'
import DatePicker from 'components/DatePicker'
import GroupRadio from 'components/GroupRadio'
import dayjs from 'dayjs'
import { History } from 'history'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { textBlue500 } from 'theme/globalStyles'
import { generalStatus } from 'constants/general'
import { convertQueryStringToFilter } from 'utils/commonUtils'
import { handleDatePickerChange } from 'utils/dateFormatUtils'
import { IFilterData } from '../../components/FilterModal/interface'
import { IFilterModalProps } from './interface'

const FilterModal = (props: IFilterModalProps) => {
  const { isOpen, closeHandler, submitHandler } = props
  const methods = useForm()
  const { handleSubmit, control, reset, getValues, setValue } = methods
  const history: History = useHistory()
  const initialFilter = convertQueryStringToFilter(history.location.search)
  const [yearFilter, setYearFilter] = useState<string>(initialFilter.year ?? '')

  useEffect(() => {
    if (isOpen) {
      setValue(
        'status',
        initialFilter.published !== undefined ? (initialFilter.published ? 'published' : 'unpublished') : null
      )
    }
  }, [isOpen])

  function resetForm() {
    setYearFilter(dayjs().toDate().toString())
    reset({
      publishedYear: dayjs().toDate(),
      status: null,
    })
  }

  function onSubmit(data: IFilterData) {
    const { publishedYear, status } = getValues()

    if (!status && !publishedYear) {
      history.replace({ search: '' })
    } else {
      const filterQuery = new URLSearchParams()

      if (status) {
        filterQuery.set('status', status)
      }

      if (publishedYear) {
        filterQuery.set('year', dayjs(publishedYear).format('YYYY'))
      }

      history.replace({ search: filterQuery.toString() })
    }

    submitHandler(data)
    closeHandler()
  }

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={closeHandler} size="sm">
      <DrawerOverlay />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DrawerContent>
            <DrawerCloseButton color={'gray'} background="none" cursor="pointer" mt="2" />
            <DrawerHeader>Filter</DrawerHeader>
            <DrawerBody>
              <VStack gap={5}>
                <GroupRadio
                  name="status"
                  data={generalStatus}
                  label="Status"
                  defaultValue={
                    initialFilter.published !== undefined
                      ? initialFilter.published
                        ? 'published'
                        : 'unpublished'
                      : null
                  }
                />
                <FormControl>
                  <FormLabel fontWeight="400">Published Year</FormLabel>
                  <Controller
                    name="publishedYear"
                    control={control}
                    render={({ field }) => {
                      return (
                        <DatePicker
                          selectedDate={yearFilter ? dayjs(yearFilter).toDate() : dayjs(field.value).toDate()}
                          onChange={(date) => {
                            handleDatePickerChange(field, date)
                            setYearFilter(date.toISOString())
                          }}
                          placement="top"
                          showYearPicker={true}
                        />
                      )
                    }}
                  />
                </FormControl>
              </VStack>
            </DrawerBody>
            <DrawerFooter justifyContent="space-between">
              <Button variant="ghost" onClick={resetForm} customStyles={{ color: textBlue500 }}>
                Reset
              </Button>
              <HStack>
                <Button variant="outline" onClick={closeHandler}>
                  Cancel
                </Button>
                <Button variant="solid" type="submit">
                  Apply Filter
                </Button>
              </HStack>
            </DrawerFooter>
          </DrawerContent>
        </form>
      </FormProvider>
    </Drawer>
  )
}

export default FilterModal
