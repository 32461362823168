import { useState } from 'react'
import { HStack, VStack, Text, Stack } from '@chakra-ui/react'
import InfoBox from 'components/InfoBox'
import { observer } from 'mobx-react'
import { EChartType } from 'constants/enum'
import { useStores } from 'utils/hooks/useStores'
import { ReactComponent as WorkingTime } from '../../../../assets/images/svg/working-time.svg'
import { renderChart } from '../../container'

const StatisticContent = () => {
  const { userReportStore } = useStores()
  const selectedChart = EChartType.TIME_TOTAL_CHART
  const [expandedChart, setExpandedChart] = useState(false)
  const { chartIndicators, options, projectData } = userReportStore

  const expandChartHandler = () => {
    setExpandedChart(true)
  }

  const reduceChartHandler = () => {
    setExpandedChart(false)
  }

  return (
    <VStack width={'full'} padding="20px">
      <HStack width="full">
        <InfoBox
          title="Total Working Time"
          value={Number(projectData?.workingHours).toFixed(1)}
          isShowPercent={false}
          icon={<WorkingTime width="48px" height="48px" style={{ alignSelf: 'flex-start' }} />}
        />
        <InfoBox
          title="Working Developers Number"
          value={Number(projectData?.workingDeveloper).toFixed(1)}
          isShowPercent={false}
          icon={<WorkingTime width="48px" height="48px" style={{ alignSelf: 'flex-start' }} />}
        />
      </HStack>
      <VStack width="full" background="white">
        {chartIndicators && (
          <Stack className="chart-section" width="full">
            <Text
              padding={'16px'}
              alignSelf="flex-start"
              marginY={2}
              color="gray.700"
              fontSize="18px"
              fontWeight="500"
              lineHeight="28px">
              Time Total Chart
            </Text>
            <div className="chart">
              {renderChart(
                selectedChart,
                expandedChart,
                options,
                expandChartHandler,
                reduceChartHandler,
                chartIndicators
              )}
            </div>
          </Stack>
        )}
      </VStack>
    </VStack>
  )
}

export default observer(StatisticContent)
