import dayjs from 'dayjs'
import moment from 'moment'

export function getFormattedDateWith_DDMMYYYY(currentDate) {
  return currentDate ? moment(currentDate).format('DD/MM/YYYY') : ''
}

export function getFormattedDateWith_MMMDDYYYY(currentDate) {
  if (!currentDate) {
    return ''
  }
  const isValidDate = dayjs(currentDate, 'YYYY-MM-DD', true).isValid()
  return isValidDate ? dayjs(currentDate).format('MMM DD, YYYY') : ''
}

export function getFormattedDateWith_YYYYMMDD(currentDate) {
  return currentDate ? moment(currentDate).format('YYYY-MM-DD') : ''
}

export function getFormattedDateInEnglish(currentDate) {
  if (currentDate && dayjs(currentDate).isValid()) {
    return dayjs(currentDate).format('MMM DD, YYYY')
  }
  return '-'
}

export function getFormattedDateTimeInEnglish(currentDate) {
  if (currentDate && dayjs(currentDate).isValid()) {
    return dayjs(currentDate).format('MMM DD, YYYY HH:mm')
  }
  return '-'
}

export function getFormattedTimeDate(currentDate) {
  if (currentDate && dayjs(currentDate).isValid()) {
    return dayjs(currentDate).format('HH:mm MMM DD, YYYY')
  }
  return '-'
}

export function handleDatePickerChange(field, date) {
  const isValidDate = dayjs(date).isValid()
  if (isValidDate) {
    field.onChange(dayjs(date).format('YYYY-MM-DD'))
  }
}

export function showEndDateOfProjectHistory(data) {
  if (data.untilNow) {
    return 'In progress'
  }
  return moment(data.endDate).format('MMM Do YYYY ')
}

export function getYearFromDate(date: string) {
  const inputDate = new Date(date)
  return inputDate.getFullYear()
}

export function getMonthFromDate(date: string) {
  const inputDate = new Date(date)
  return inputDate.getMonth() // getMonth() trả về giá trị từ 0-11, nên cần +1
}
