import { MutableRefObject, useEffect, useRef, useState } from 'react'
import { VStack } from '@chakra-ui/react'
import FullCalendar from '@fullcalendar/react'
import { ECalendarViewType } from 'components/Calendar/constants'
import GroupChips from 'components/GroupChips'
import { observer } from 'mobx-react'
import { FormProvider, useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { IOption } from 'constants/common'
import { EConditionGetList, ETimesheetSubTitle, EWorkingHourPeriods } from 'constants/enum'
import { getValidArray, isValidArray } from 'utils/commonUtils'
import { createOptionsOfReactSelectFromDB } from 'utils/dropdownOptionUtils'
import { useStores } from 'utils/hooks/useStores'
import CalendarSection from './components/CalendarSection'
import HeaderSection from './components/HeaderSection'
import SmartTimesheetModal from './components/SmartTimesheetModal'
import TimeSheetModal from './components/TimeSheetModal'

const TimeSheetUser = () => {
  window.sessionStorage.setItem('subPageTitle', ETimesheetSubTitle.YOUR_TIMESHEET)
  window.dispatchEvent(new Event('storage'))

  const methods = useForm()
  const { watch, setValue } = methods
  const location = useLocation()

  const { userTimesheetStore, adminTaskTypeStore, userProjectStore } = useStores()
  const { projectNameList } = userProjectStore
  const { isLoadNewTimesheetList, selectedDateFromCalendar } = userTimesheetStore

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [isOpenSmartTimesheetModal, setIsOpenSmartTimesheetModal] = useState<boolean>(false)
  const [viewMode, setViewMode] = useState<ECalendarViewType>(ECalendarViewType.TIME_GRID_WEEK)

  const selectedProjectList: IOption[] = watch('projects')
  const calendarRef: MutableRefObject<FullCalendar> = useRef<FullCalendar>(null)

  function handleOpenModal(): void {
    setIsOpenModal(true)
  }

  function handleOpenSmartTimesheetModal(): void {
    setIsOpenSmartTimesheetModal(true)
  }

  async function fetchData(): Promise<void> {
    await userProjectStore.getAllNameProjectList(EConditionGetList.ACTIVE)
    await adminTaskTypeStore.getTaskTypeList()
    await fetchTimesheets()
  }

  async function fetchTimesheets(): Promise<void> {
    const projectIds: string[] = getValidArray(selectedProjectList)
      .filter((project: IOption) => project.value !== 'all')
      .map((project: IOption) => project.value)
    if (projectIds?.length !== 0) {
      let period: string
      switch (viewMode) {
        case ECalendarViewType.DAY_GRID_MONTH:
        case ECalendarViewType.TIME_GRID_DAY:
          period = EWorkingHourPeriods.MONTH
          break
        case ECalendarViewType.TIME_GRID_WEEK:
          period = EWorkingHourPeriods.WEEK
          break
        default:
          break // Optionally handle the default case or do nothing
      }
      await userTimesheetStore.fetchTimesheets(projectIds, selectedDateFromCalendar, period)
    } else {
      userTimesheetStore.resetTimesheetList()
    }
  }

  const projectsOption: IOption[] = [...createOptionsOfReactSelectFromDB(projectNameList)]

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (isValidArray(projectsOption)) {
      const defaultOption: IOption[] = [...projectsOption]
      setValue('projects', defaultOption)
    }
  }, [projectNameList])

  useEffect(() => {
    fetchTimesheets()
  }, [selectedProjectList, isLoadNewTimesheetList, selectedDateFromCalendar, viewMode])

  useEffect(() => {
    if (location.search === '?modal=smart-timesheet') {
      handleOpenSmartTimesheetModal()
    }
  }, [location])

  return (
    <VStack marginTop={6} spacing={6} width="full">
      <FormProvider {...methods}>
        <HeaderSection
          viewMode={viewMode}
          setViewMode={setViewMode}
          calendarRef={calendarRef}
          handleOpenModal={handleOpenModal}
          handleOpenSmartTimesheet={handleOpenSmartTimesheetModal}
        />
        <GroupChips fieldName="projects" />
        <CalendarSection calendarRef={calendarRef} handleOpenModal={handleOpenModal} />
        <TimeSheetModal
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          filteredProjects={selectedProjectList}
        />
        <SmartTimesheetModal
          isOpenModal={isOpenSmartTimesheetModal}
          setIsOpenModal={setIsOpenSmartTimesheetModal}
          projectOptions={projectsOption}
        />
      </FormProvider>
    </VStack>
  )
}

export default observer(TimeSheetUser)
