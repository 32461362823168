import { FormControl } from '@chakra-ui/form-control'
import { Box, SimpleGrid } from '@chakra-ui/layout'
import { FormLabel } from '@chakra-ui/react'
import DatePicker from 'components/DatePicker'
import Editor from 'components/Editor'
import FormInput from 'components/FormInput'
import GroupRadio from 'components/GroupRadio'
import dayjs from 'dayjs'
import { Controller } from 'react-hook-form'
import { generalStatus } from 'constants/general'
import { handleDatePickerChange } from 'utils/dateFormatUtils'
import { IAnnouncementFormProps } from './interface'

const AnnouncementForm = (props: IAnnouncementFormProps) => {
  const { methods } = props
  const { control } = methods
  return (
    <Box>
      <SimpleGrid columns={1} gap="24px">
        <FormInput label="Announcement title" name="title" placeholder="Enter Announcement title" />
        <Editor name="content" placeholder="Enter Announcement content" label="Content" />
      </SimpleGrid>
      <SimpleGrid columns={{ lg: 2, md: 2, sm: 1 }} marginY="24px" gap="24px">
        <FormControl>
          <FormLabel fontWeight="400">Published Date</FormLabel>
          <Controller
            name="publishedDate"
            control={control}
            render={({ field }) => (
              <DatePicker
                selectedDate={field.value ? dayjs(field.value).toDate() : undefined}
                onChange={(date) => handleDatePickerChange(field, date)}
                placement="top"
              />
            )}
          />
        </FormControl>
        <GroupRadio name="status" data={generalStatus} label="Status" defaultValue="published" />
      </SimpleGrid>
    </Box>
  )
}

export default AnnouncementForm
