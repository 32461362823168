import { ModalFooter } from '@chakra-ui/modal'
import Button from 'components/Button'
import { borderGray200 } from 'theme/globalStyles'
import { LeaveModalFooterProps } from './interface'

const LeaveModalFooter = ({ isEdit, isLoading, onCancel }: LeaveModalFooterProps) => (
  <ModalFooter borderTop="1px solid" borderColor={borderGray200}>
    <Button variant="outline" onClick={onCancel} customStyles={{ marginRight: '16px' }}>
      Cancel
    </Button>
    <Button variant="solid" type="submit" isLoading={isLoading}>
      {isEdit ? 'Save' : 'Submit'}
    </Button>
  </ModalFooter>
)

export default LeaveModalFooter
