import { useState } from 'react'
import { Box, Stack, Text, Grid, GridItem, VStack } from '@chakra-ui/react'
import Button from 'components/Button'
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form'
import { IFormChangePassword } from 'types/user'
import { useStores } from 'utils/hooks/useStores'
import CheckBoxField from './components/CheckBoxField'
import PasswordField from './components/PasswordFiled'
import { EChangePasswordField } from './constants'

const ChangePassword = () => {
  const { userProfileStore } = useStores()
  const [newPassword, setNewPassword] = useState('')
  const [isValidNewPassword, setIsValidNewPassword] = useState(false)
  const [isValidSubmit, setIsValidSubmit] = useState(false)
  const [isEnoughLength, setIsEnoughLength] = useState(false)
  const [isIncludedUpperAndLowerCase, setIsIncludedUpperAndLowerCase] = useState(false)
  const [isIncludedLetterAndNumber, setIsIncludedLetterAndNumber] = useState(false)
  const [isIncludedSpecialCharacter, setIsIncludedSpecialCharacter] = useState(false)
  const regExOfCheckCharacter: RegExp = /^(?=.*[a-zA-Z])(?=.*\d).+$/
  const regExOfUppercase: RegExp = /[A-Z]/
  const regExOfLowercase: RegExp = /[a-z]/
  const regExOfSpecialCharacter: RegExp = /[ !"#$%&'()*+,-.:;<=>?@[\\\]^_`{|}~]/
  const method: UseFormReturn<IFormChangePassword> = useForm<IFormChangePassword>({
    mode: 'onBlur',
  })
  const { handleSubmit, setValue, setError, clearErrors } = method

  async function submitHandler(data: IFormChangePassword): Promise<void> {
    userProfileStore.changePassword(data)
  }

  function onBlurInputFieldHandler(field): void {
    const fieldName = field.name
    const fieldValue = field.value

    if (fieldName === EChangePasswordField.CONFIRM_NEW_PASSWORD) {
      if (isValidNewPassword && newPassword === fieldValue) {
        setIsValidSubmit(true)
        clearErrors(EChangePasswordField.CONFIRM_NEW_PASSWORD)
      } else {
        setIsValidSubmit(false)
        setError(EChangePasswordField.CONFIRM_NEW_PASSWORD, {
          type: 'custom',
          message: 'The confirm password is not match with new password',
        })
      }
    }
    setValue(fieldName, fieldValue)
  }

  function onBlurInputNewPasswordFieldHandler(field): void {
    const fieldValue = field.value
    const fieldName = field.name
    validatePassword(fieldValue)
    setValue(fieldName, fieldValue)
  }

  function validatePassword(password: string): void {
    const length: number = password?.length
    let isValidNewPassword = false
    if (length >= 8) {
      setIsEnoughLength(true)
      const isValidCharacter: boolean = regExOfCheckCharacter.test(password)
      const isValidCheckUppercase: boolean = regExOfUppercase.test(password)
      const isValidCheckLowercase: boolean = regExOfLowercase.test(password)
      const isValidCheckCaseCharacter: boolean = isValidCheckUppercase && isValidCheckLowercase
      const isValidCheckSpecialCharacter: boolean = regExOfSpecialCharacter.test(password)
      if (isValidCharacter) {
        setIsIncludedLetterAndNumber(true)
      }
      if (isValidCheckUppercase && isValidCheckLowercase) {
        setIsIncludedUpperAndLowerCase(true)
      }
      if (isValidCheckSpecialCharacter) {
        setIsIncludedSpecialCharacter(true)
      }
      if (isValidCharacter && isValidCheckCaseCharacter && isValidCheckSpecialCharacter) {
        isValidNewPassword = true
      }
    }
    if (isValidNewPassword) {
      setIsValidNewPassword(true)
      setNewPassword(password)
      clearErrors(EChangePasswordField.NEW_PASSWORD)
    } else {
      setError(EChangePasswordField.NEW_PASSWORD, {
        type: 'custom',
        message: 'New password is not match with conditions',
      })
    }
  }

  return (
    <FormProvider {...method}>
      <VStack background="white" width="full" alignItems="flex-start" marginLeft={6}>
        <form onSubmit={handleSubmit((data) => submitHandler(data))} className="full-form">
          <Stack
            padding={{ lg: '0px', md: '10px', sm: '10px' }}
            direction={{ lg: 'row', md: 'column', sm: 'column' }}
            alignItems={'start'}
            justifyContent={{ lg: 'space-between', md: 'flex-start', sm: 'flex-start' }}
            width="full">
            <Box width={{ lg: '50%', md: '90%', sm: '90%' }}>
              <PasswordField
                name={EChangePasswordField.CURRENT_PASSWORD}
                label={'Current Password'}
                onBlurHandler={onBlurInputFieldHandler}
              />
              <PasswordField
                name={EChangePasswordField.NEW_PASSWORD}
                label={'New Password'}
                onBlurHandler={onBlurInputNewPasswordFieldHandler}
              />
              <PasswordField
                name={EChangePasswordField.CONFIRM_NEW_PASSWORD}
                label={'Confirm New Password'}
                onBlurHandler={onBlurInputFieldHandler}
              />
            </Box>
            <Button
              variant="solid"
              leftIcon="save-white.svg"
              type="submit"
              disabled={!isValidSubmit}
              customStyles={{ marginRight: '16px' }}>
              Save
            </Button>
          </Stack>
        </form>
        <VStack w="full" maxW="697px" padding={{ lg: '0px', md: '10px', sm: '10px' }} marginTop={'24px'} spacing="12px">
          <Text alignSelf={'flex-start'} mb={2} color={'gray.700'}>
            Password Instructions
          </Text>
          <Grid
            background="white"
            width="full"
            templateColumns="repeat(2, 1fr)"
            gap={{ base: 2, lg: 2 }}
            alignItems="flex-start">
            <GridItem colSpan={{ base: 2, lg: 1 }}>
              <CheckBoxField
                name={'lengthCharacter'}
                label={'At least 8 characters'}
                isDisabled
                isChecked={isEnoughLength}
              />
            </GridItem>
            <GridItem colSpan={{ base: 2, lg: 1 }}>
              <CheckBoxField
                name={'checkCharacter'}
                label={'Contains letters and numbers'}
                isDisabled
                isChecked={isIncludedLetterAndNumber}
              />
            </GridItem>
            <GridItem colSpan={{ base: 2, lg: 1 }}>
              <CheckBoxField
                name={'checkCaseCharacter'}
                label={'Contains uppercase and lowercase letters'}
                isDisabled
                isChecked={isIncludedUpperAndLowerCase}
              />
            </GridItem>
            <GridItem colSpan={{ base: 2, lg: 1 }}>
              <CheckBoxField
                name={'checkSpecialCharacter'}
                label={'Contains at least 1 special character'}
                isDisabled
                isChecked={isIncludedSpecialCharacter}
              />
            </GridItem>
          </Grid>
        </VStack>
      </VStack>
    </FormProvider>
  )
}

export default ChangePassword
