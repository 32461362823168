export enum ELocalStorageKeys {
  FULL_NAME = 'fullName',
  DISPLAY_NAME = 'displayName',
  EMAIL = 'email',
  AVATAR = 'avatar',
  USER_ROLE = 'userRole',
  USER_TITLE = 'userTitle',
  USER_LEVEL = 'userLevel',
  IS_COLLAPSED = 'isCollapsed',
  IS_FIRST_LOGOUT = 'isFirstLogout',
}
