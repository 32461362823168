import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from 'components/Button'
import Input from 'components/Input'
import CreatableSelect from 'components/Select/AutoCompleteSelect'
import { Field } from 'formik'
import { ICreatableOption, IPartner } from 'constants/schema'
import { createOptionsOfSelect, createOptionsOfReactSelectFromDB } from 'utils/dropdownOptionUtils'
import { InternalDocumentScope } from '../InternalDocumentForm/constant'

const styles = () => ({
  textField: {
    marginLeft: 20,
    marginRight: 20,
    width: 200,
    fontSize: 16,
  },
  menu: {
    width: 200,
  },
  submit: {
    marginLeft: 16,
  },
  button: {
    marginRight: 8,
  },
})

export interface IFilterProps {
  classes?: Record<string, unknown>
  partners?: IPartner[]
  doctypes?: ICreatableOption[]
  dirty?: boolean
  isSubmitting?: boolean
  handleSubmit?: any
  isLoading?: boolean
  clearForm?: () => void
}

class FilterForm extends React.Component<IFilterProps> {
  render() {
    const { classes, partners, doctypes, dirty, isSubmitting, handleSubmit, isLoading } = this.props
    const partnerOptions = createOptionsOfReactSelectFromDB(partners)
    const typeOptions = createOptionsOfReactSelectFromDB(doctypes)
    const scopeOptions = createOptionsOfSelect(InternalDocumentScope)

    return (
      <form onSubmit={handleSubmit}>
        <div className="container-fluid">
          <div className="box box-default">
            <div className="box-header">Filter</div>
            <div className="box-divider" />
            <div className={`box-body`}>
              <div className="container-fluid">
                <div className="fields">
                  <div className="field">
                    <Field
                      name="partnerId"
                      label="Belong To Partner"
                      className={classes.textField + '98width'}
                      margin="normal"
                      isMulti
                      options={partnerOptions}
                      isClearable={false}
                      component={CreatableSelect}
                      data={partners}
                    />
                  </div>
                  <div className="field">
                    <Field
                      name="typeId"
                      label="Type"
                      className={classes.textField}
                      margin="normal"
                      isMulti
                      isClearable={false}
                      component={CreatableSelect}
                      options={typeOptions}
                    />
                  </div>
                  <div className="field">
                    <Field
                      name="scope"
                      label="Scope"
                      className={classes.textField + '98width'}
                      margin="normal"
                      isMulti
                      options={scopeOptions}
                      isClearable={false}
                      component={CreatableSelect}
                    />
                  </div>
                </div>
                <div className="fields">
                  <div className="field">
                    <Field name="month" label="Month" className={classes.textField} margin="normal" component={Input} />
                  </div>
                  <div className="field">
                    <Field name="year" label="Year" className={classes.textField} margin="normal" component={Input} />
                  </div>
                </div>
                <div className={classes.submit as string}>
                  <Button type="submit" variant="solid" isLoading={isLoading} disabled={!dirty && isSubmitting}>
                    Filter
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }
}

export default withStyles(styles)(FilterForm)
