import { ITechnology } from 'types/technology'
import { getValidArray } from 'utils/commonUtils'
import { getFormattedDateWith_MMMDDYYYY } from 'utils/dateFormatUtils'

export function getProjectTechnologies(listTechnologyId: string[], technologiesList: ITechnology[]): ITechnology[] {
  const projectTechnologies: ITechnology[] = getValidArray(listTechnologyId).map((technologyId: string) => {
    const validItem: ITechnology = technologiesList.find((technology) => {
      return technology.id === technologyId
    })
    return validItem
  })
  return projectTechnologies
}

export function combineStartedDateEndedDate(startedDate: Date, endedDate: Date, isUntilNow: boolean): string {
  return isUntilNow === true
    ? getFormattedDateWith_MMMDDYYYY(startedDate ?? '') + ' - Now'
    : getFormattedDateWith_MMMDDYYYY(startedDate ?? '') + ' - ' + getFormattedDateWith_MMMDDYYYY(endedDate ?? '')
}
