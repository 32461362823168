export enum EChartType {
  TIME_TOTAL_CHART = 'timeTotalChart',
  REVENUE_CHART = 'revenueChart',
  PROFIT_CHART = 'profitChart',
}

export enum EFilterType {
  FILTER_BY_PROJECT = 'filter by project',
  FILTER_BY_USER = 'filter by user',
  FILTER_ALL = 'filter all',
}
