export const legendProps: any = {
  anchor: 'bottom',
  itemHeight: 16,
  direction: 'row',
  justify: false,
  translateX: 0,
  itemDirection: 'left-to-right',
  itemWidth: 100,
  itemTextColor: '#2D3748',
  symbolShape: 'square',
  itemOpacity: 1,
  effects: [
    {
      on: 'hover',
      style: {
        itemTextColor: '#000',
      },
    },
  ],
}
