import { Box } from '@chakra-ui/react'
import { borderGray300 } from 'theme/globalStyles'

interface IMenuProps {
  menuPlacement: 'auto' | 'bottom' | 'top'
  children?: React.ReactNode
}

const Menu = (props: IMenuProps) => {
  const { menuPlacement } = props
  const borderRadius = menuPlacement === 'top' ? '0px 0px 4px 4px' : '4px 4px 0px 0px'
  const top = menuPlacement === 'top' ? '-200%' : '100%'
  return (
    <Box
      width="full"
      backgroundColor="white"
      border="1px solid"
      borderColor={borderGray300}
      marginTop="2"
      borderRadius={borderRadius}
      position="absolute"
      top={top}
      zIndex="9999">
      {props.children}
    </Box>
  )
}
export default Menu
