import { handleError } from 'API/error'
import { getSkillSummaryForAdminAPI } from 'API/skill'
import { ISkillSummaryForAdminItem } from 'API/skill/constants'
import { getDashboardSummarizeAPI, getManHourHeatMapAPI } from 'API/summarize'
import { IAdminStatisticRequestData, IManHourHeatMapRequest, ISkillRequest } from 'API/summarize/constants'
import { makeAutoObservable } from 'mobx'
import RootStore from 'store/rootStore'
import { IChartData, IChartDataDetail, IChartIndicators, IOptions, IProjectData, IRevenueData } from 'types/dashboard'
import { IProject } from 'types/project'
import { IManHourHeatMap } from 'types/summarize'
import { EPaymentCategoryFromClientOfProject } from 'constants/enum'
import { limitItemPerPage } from 'constants/index'
import { IUser } from 'constants/schema'
import { isValidArray } from 'utils/commonUtils'

class AdminDashboardStore {
  rootStore: RootStore
  projects?: IProject[]
  projectData?: IProjectData
  chartIndicators?: IChartIndicators
  options?: IOptions
  haveData: boolean = false
  revenueChartData?: IRevenueData
  users?: IUser[]
  chartData?: IManHourHeatMap[]
  activeUserIds?: string[]
  isLoadingStatistic: boolean = false
  isLoadingHeatmap: boolean = false
  isLoadingSkill: boolean = false
  countTotalSkill: number = 0
  skillSummaryData?: ISkillSummaryForAdminItem[]

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public async changeChartIndicators(activeUserIds: string[]) {
    this.activeUserIds = activeUserIds
    if (isValidArray(this.chartIndicators?.data) && this.projectData) {
      const activeData = this.chartIndicators?.data?.filter((item) => activeUserIds.includes(item._id))
      this.projectData.workingHours = activeData?.reduce((total: number, item: IChartData) => {
        const totalTimeOfUser = item?.data?.reduce(
          (total: number, item: IChartDataDetail) => total + item?.totalTime,
          0
        )
        return total + totalTimeOfUser
      }, 0)
      this.projectData.salary = activeData?.reduce((total: number, item: IChartData) => {
        const totalSalaryOfUser = item?.data?.reduce(
          (total: number, item: IChartDataDetail) => total + item?.totalSalary,
          0
        )
        return total + totalSalaryOfUser
      }, 0)
      switch (this.projectData.projectPaymentCategoryFromClient) {
        case EPaymentCategoryFromClientOfProject.TIME_AND_MATERIAL:
          this.projectData.revenue = activeData?.reduce((total: number, item: IChartData) => {
            const totalRevenueOfUser = item?.data?.reduce(
              (total: number, item: IChartDataDetail) => total + item?.totalRevenue,
              0
            )
            return total + totalRevenueOfUser
          }, 0)
          break
        case EPaymentCategoryFromClientOfProject.MONTHLY_FIXED_SCOPE:
          break
        default:
          break
      }

      this.projectData.profit = this.projectData.revenue - this.projectData.salary
    }
  }

  public async getAdminStatisticData(data: IAdminStatisticRequestData): Promise<void> {
    try {
      this.isLoadingStatistic = true
      this.haveData = false
      const { chartIndicators, options, revenueChartData, projectData } = await getDashboardSummarizeAPI(data)
      this.chartIndicators = chartIndicators
      this.options = options
      this.revenueChartData = revenueChartData
      this.projectData = projectData
      this.haveData = true
    } catch (error) {
      handleError(error, 'src/store/admin/dashboardStore.ts', 'getAdminStatisticData', false, true)
    } finally {
      this.isLoadingStatistic = false
    }
  }
  public async getAdminHeatmapData(values: IManHourHeatMapRequest): Promise<void> {
    try {
      this.isLoadingHeatmap = true
      this.haveData = false
      const response = await getManHourHeatMapAPI(values)
      this.chartData = response?.data?.chartData
      this.haveData = true
    } catch (error) {
      handleError(error, 'src/store/admin/dashboardStore.ts', 'getAdminHeatmapData', false, true)
    } finally {
      this.isLoadingHeatmap = false
    }
  }
  public async getAdminSkillSummaryData(payload: ISkillRequest): Promise<void> {
    try {
      this.isLoadingSkill = true
      this.haveData = false
      const response = await getSkillSummaryForAdminAPI({ ...payload, perPage: limitItemPerPage })
      this.skillSummaryData = response.skillData
      this.countTotalSkill = response.count
      this.haveData = true
    } catch (error) {
      handleError(error, 'src/store/admin/dashboardStore.ts', 'getAdminHeatmapData', false, true)
    } finally {
      this.isLoadingSkill = false
    }
  }
}
export default AdminDashboardStore
