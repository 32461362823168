import { Box, Text, VStack } from '@chakra-ui/react'
import { getFormattedDateWith_MMMDDYYYY } from 'utils/dateFormatUtils'
import { getGroupSkill } from '../../container'
import SkillIndicator from '../SkillIndicator'
import { ISkillAnalysisHistoryContentProps } from './interfaces'
import styles from './styles.module.scss'

const SkillAnalysisHistoryContent = (props: ISkillAnalysisHistoryContentProps) => {
  const { skillAnalysisHistoryData = [] } = props
  return (
    <VStack spacing={5} align="stretch" paddingLeft="20px" className={styles.container}>
      {skillAnalysisHistoryData.map((item, index) => (
        <Box key={item?.id} position="relative">
          <Box className={styles.marker}></Box>
          <Text fontSize="sm" mb={1} color="gray.500">
            {item?.createdBy} • {getFormattedDateWith_MMMDDYYYY(item?.createdAt)}
          </Text>
          <Text fontSize="md" mb={3}>
            {item?.comment}
          </Text>
          <SkillIndicator
            index={index}
            groupSkill={getGroupSkill(item)}
            skillAnalysisHistoryData={skillAnalysisHistoryData}
          />
        </Box>
      ))}
    </VStack>
  )
}
export default SkillAnalysisHistoryContent
