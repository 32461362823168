export const summarize = {
  value: '/summarize',
  // Dashboard Admin Page
  dashboard: {
    value: '/summarize/dashboard',
  },
  manHourHeatMap: {
    value: '/summarize/man-hour-heat-map',
  },
  // Dashboard User Page
  supervisorStatistic: {
    value: '/summarize/supervisor-statistic',
  },
  manHourHeatMapOfUser: {
    value: '/summarize/man-hour-heat-map-of-user',
  },
}
