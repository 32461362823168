import { useEffect, useState } from 'react'
import { Search2Icon } from '@chakra-ui/icons'
import { VStack, HStack, Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import { IProjectType, IProjectTypeDataFromCreateForm } from 'API/projectType/constants'
import Button from 'components/Button'
import ConfirmModal from 'components/ConfirmModal'
import { EModalType } from 'components/ConfirmModal/enums'
import Table from 'components/Table'
import OptionButtonsWithoutDropBox from 'components/Table/DesktopTable/components/OptionButtonsWithoutDropBox'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { backgroundGrey200 } from 'theme/globalStyles'
import { EActionType } from 'constants/enum'
import { frontendRoutes } from 'constants/routes'
import { getValidArray } from 'utils/commonUtils'
import { useStores } from 'utils/hooks/useStores'
import ProjectTypeModal from '../components/ProjectTypeModal'
import { getProjectTypeHeaderList } from '../constants'
import styles from '../globalConfig.module.scss'

const ProjectType = () => {
  const { adminProjectTypeStore } = useStores()
  const history = useHistory()

  const { listCount, projectTypeList, isLoadNewProjectTypeList, isLoadingProjectTypeList } = adminProjectTypeStore
  const [isOpenCreateModal, setIsOpenCreateModal] = useState<boolean>(false)
  const [isOpenConfirmDeleteModal, setIsOpenConfirmDeleteModal] = useState<boolean>(false)
  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false)
  const [filter, setFilter] = useState({})
  const [selectedProjectType, setSelectedProjectType] = useState<IProjectType>()

  useEffect(() => {
    adminProjectTypeStore.getProjectTypeListWithPagination(true, history, filter)
  }, [filter, isLoadNewProjectTypeList])
  function openCreateModal(): void {
    setIsOpenCreateModal(true)
  }
  function closeCreateModal(): void {
    setIsOpenCreateModal(false)
    setSelectedProjectType(null)
  }
  function openConfirmDeleteModal(projectType: IProjectType): void {
    setSelectedProjectType(projectType)
    setIsOpenConfirmDeleteModal(true)
  }
  function closeConfirmDeleteModal(): void {
    setSelectedProjectType(null)
    setIsOpenConfirmDeleteModal(false)
  }
  function closeEditModal(): void {
    setSelectedProjectType(null)
    setIsOpenEditModal(false)
  }
  function openEditModal(projectType: IProjectType): void {
    setSelectedProjectType(projectType)
    setIsOpenEditModal(true)
  }
  function createNewProjectTypeHandler(item: IProjectTypeDataFromCreateForm): void {
    const itemData = {
      value: item.value,
    }
    adminProjectTypeStore.createProjectType(itemData)
    closeCreateModal()
    pagination.gotoPage(1)
  }
  function deleteProjectTypeHandler(id: string): void {
    adminProjectTypeStore.deleteProjectType(id)
    closeConfirmDeleteModal()
    pagination.gotoPage(1)
  }
  function editProjectTypeHandler(item: IProjectType): void {
    adminProjectTypeStore.updateProjectType(item)
    closeEditModal()
    pagination.gotoPage(1)
  }

  const tableData = getValidArray(projectTypeList).map((projectType) => {
    return {
      ...projectType,
      actions: (
        <OptionButtonsWithoutDropBox
          isEdit={true}
          isDelete={true}
          editActionHandler={() => openEditModal(projectType)}
          deleteActionHandler={() => openConfirmDeleteModal(projectType)}
        />
      ),
    }
  })
  const pageIndex: number = Number(get(history, 'location.state.page', 1)) || 1
  const pagination = {
    includePagination: true,
    pageIndex,
    pageSize: 10,
    tableLength: listCount,
    gotoPage: (page: number) => {
      history.push({ pathname: frontendRoutes.settingPage.globalConfig.value, state: { page, filter } })
      adminProjectTypeStore.getProjectTypeListWithPagination(false, history, filter)
    },
  }

  const searchHandler = debounce((event) => {
    setFilter({
      ...filter,
      value: { $regex: event?.target?.value ?? '', $options: 'i' },
    })
  }, 1000)

  return (
    <VStack width="full">
      <HStack marginBottom={6} width={{ base: '100%', md: '100%' }} justifyContent={'space-between'}>
        <InputGroup
          border={`1px solid ${backgroundGrey200}`}
          borderRadius="6px"
          width={{ base: '100%', lg: '540px' }}
          background="white">
          <InputLeftElement pointerEvents="none">
            <Search2Icon color="gray.400" />
          </InputLeftElement>
          <Input type="search" placeholder="Search Project Type by name..." onChange={searchHandler} />
        </InputGroup>
        <Button variant="solid" leftIcon="add-white.svg" onClick={openCreateModal}>
          Create
        </Button>
      </HStack>
      <Table
        className={styles.fullWidth}
        isLoading={isLoadingProjectTypeList}
        headerList={getProjectTypeHeaderList()}
        tableData={tableData}
        isStriped
        pagination={pagination}
        isShowPagination={true}
      />
      <ProjectTypeModal
        actionType={EActionType.CREATE}
        isOpen={isOpenCreateModal}
        closeHandler={closeCreateModal}
        saveHandler={createNewProjectTypeHandler}
        data={selectedProjectType}
        isSubmitSuccess={isLoadNewProjectTypeList}
      />
      <ProjectTypeModal
        actionType={EActionType.EDIT}
        isOpen={isOpenEditModal}
        closeHandler={closeEditModal}
        saveHandler={editProjectTypeHandler}
        data={selectedProjectType}
        isSubmitSuccess={isLoadNewProjectTypeList}
      />
      <ConfirmModal
        data={selectedProjectType}
        isOpen={isOpenConfirmDeleteModal}
        closeHandler={closeConfirmDeleteModal}
        OKClickHandler={deleteProjectTypeHandler}
        title={'Project Type'}
        content={'name'}
        type={EModalType.DELETE}
      />
    </VStack>
  )
}

export default observer(ProjectType)
