import { ILeave } from 'types/leave'
import { ELeaveDefaultReason, ELeaveRequestStatus } from '../constants/enum'
import { ILeaveAdmin } from '../constants/schema'

export const dummyLeaves: ILeave[] = [
  {
    id: '1',
    commitTime: '3', // Only for 'Remote'
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    startEndTime: '11:10 15/10/2024 - 22:22 22/10/2024',
    status: 'Approved',
    type: 'Remote',
    approver: 'Alice Johnson',
    createdDate: new Date('2024-09-19T00:00:00+0700'),
  },
  {
    id: '2',
    commitTime: undefined, // Not for 'Remote'
    content:
      'Annual leave for vacation. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    startEndTime: '09:00 20/09/2024 - 17:00 25/09/2024',
    status: 'Rejected', // Changed status to 'Rejected'
    type: 'Holiday',
    approver: 'Bob Smith',
    createdDate: new Date('2024-09-19T00:00:00+0700'),
  },
  {
    id: '3',
    commitTime: undefined, // Not for 'Remote'
    content:
      'Sick leave due to flu symptoms. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    startEndTime: '10:00 10/10/2024 - 15:00 12/10/2024',
    status: 'Pending',
    type: 'Sick',
    approver: 'Charlie Davis',
    createdDate: new Date('2024-09-19T00:00:00+0700'),
  },
  {
    id: '4',
    commitTime: '1', // Only for 'Remote'
    content:
      'Conference attendance at Tech Summit 2024. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    startEndTime: '08:30 05/10/2024 - 17:00 08/10/2024',
    status: 'Approved',
    type: 'Remote',
    approver: 'Diana Evans',
    createdDate: new Date('2024-09-19T00:00:00+0700'),
  },
  {
    id: '5',
    commitTime: undefined, // Not for 'Remote'
    content:
      'Doctor appointment for annual health check-up. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    startEndTime: '09:15 01/11/2024 - 12:00 01/11/2024',
    status: 'Rejected', // Changed status to 'Rejected'
    type: 'Sick',
    approver: 'Ethan Foster',
    createdDate: new Date('2024-10-15T00:00:00+0700'),
  },
  {
    id: '6',
    commitTime: '3', // Only for 'Remote'
    content:
      'Approved work from home for family reasons. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    startEndTime: '08:00 10/12/2024 - 17:00 10/12/2024',
    status: 'Approved',
    type: 'Remote',
    approver: 'Fiona Green',
    createdDate: new Date('2024-11-20T00:00:00+0700'),
  },
  {
    id: '7',
    commitTime: '2', // Only for 'Remote'
    content:
      'Client meeting offsite at their headquarters. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    startEndTime: '09:00 15/11/2024 - 12:30 15/11/2024',
    status: 'Rejected', // Changed status to 'Rejected'
    type: 'Remote',
    approver: 'George Harris',
    createdDate: new Date('2024-10-10T00:00:00+0700'),
  },
  {
    id: '8',
    commitTime: '4', // Only for 'Remote'
    content:
      'Working remotely due to adverse weather conditions. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    startEndTime: '10:00 20/11/2024 - 17:30 21/11/2024',
    status: 'Approved',
    type: 'Remote',
    approver: 'Hannah Irving',
    createdDate: new Date('2024-11-15T00:00:00+0700'),
  },
  {
    id: '9',
    commitTime: undefined, // Not for 'Remote'
    content:
      'Personal leave for family event. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    startEndTime: '12:00 02/11/2024 - 18:00 03/11/2024',
    status: 'Pending',
    type: 'Holiday',
    approver: 'Ian Jacobs',
    createdDate: new Date('2024-10-01T00:00:00+0700'),
  },
  {
    id: '10',
    commitTime: '1', // Only for 'Remote'
    content:
      'Training day for professional development. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    startEndTime: '09:00 29/10/2024 - 16:00 29/10/2024',
    status: 'Approved',
    type: 'Remote',
    approver: 'Julia King',
    createdDate: new Date('2024-10-10T00:00:00+0700'),
  },
  {
    id: '11',
    commitTime: '6', // Only for 'Remote'
    content:
      'Work from home for personal reasons. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    startEndTime: '08:00 01/12/2024 - 17:00 01/12/2024',
    status: 'Rejected', // Changed status to 'Rejected'
    type: 'Remote',
    approver: 'Kevin Lee',
    createdDate: new Date('2024-11-28T00:00:00+0700'),
  },
  {
    id: '12',
    commitTime: undefined, // Not for 'Remote'
    content:
      'Business trip to New York for client meetings. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    startEndTime: '06:00 18/10/2024 - 22:00 22/10/2024',
    status: 'Approved',
    type: 'Holiday',
    approver: 'Laura Martinez',
    createdDate: new Date('2024-09-18T00:00:00+0700'),
  },
  {
    id: '13',
    commitTime: undefined, // Not for 'Remote'
    content:
      'Personal emergency leave due to unforeseen circumstances. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    startEndTime: '09:00 05/11/2024 - 17:00 07/11/2024',
    status: 'Pending',
    type: 'Sick',
    approver: 'Michael Nguyen',
    createdDate: new Date('2024-10-20T00:00:00+0700'),
  },
  {
    id: '14',
    commitTime: '2', // Only for 'Remote'
    content:
      'Project deadline extension work for ongoing projects. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    startEndTime: '10:00 05/12/2024 - 18:00 06/12/2024',
    status: 'Approved',
    type: 'Remote',
    approver: "Nina O'Connor",
    createdDate: new Date('2024-11-25T00:00:00+0700'),
  },
  {
    id: '15',
    commitTime: undefined, // Not for 'Remote'
    content:
      'Team building event. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    startEndTime: '09:30 10/11/2024 - 17:00 10/11/2024',
    status: 'Approved',
    type: 'Holiday',
    approver: 'Oscar Perez',
    createdDate: new Date('2024-10-28T00:00:00+0700'),
  },
]

export const sampleData: ILeaveAdmin[] = [
  {
    _id: 'leave_001',
    approverName: 'Nguyễn Văn A',
    createdAt: '2024-09-10T08:30:00Z',
    startDate: new Date('2024-09-15'),
    endDate: new Date('2024-09-20'),
    fullName: 'TRẦN THỊ TRIỀU TIÊN',
    reason: ELeaveDefaultReason.SICK,
    status: ELeaveRequestStatus.PENDING,
  },
  {
    _id: 'leave_002',
    approverName: 'Trần Văn B',
    createdAt: '2024-09-12T10:00:00Z',
    startDate: new Date('2024-09-22'),
    endDate: new Date('2024-09-25'),
    fullName: 'NGUYỄN THỊ ANH THƯ',
    reason: ELeaveDefaultReason.REMOTE,
    status: ELeaveRequestStatus.PENDING,
  },
  {
    _id: 'leave_003',
    approverName: 'Lê Văn C',
    createdAt: '2024-09-14T09:15:00Z',
    startDate: new Date('2024-09-18'),
    endDate: new Date('2024-09-22'),
    fullName: 'BÙI ĐOÀN THẾ SANG',
    reason: ELeaveDefaultReason.HOLIDAY,
    status: ELeaveRequestStatus.REJECTED,
  },
  {
    _id: 'leave_004',
    approverName: 'Phạm Thị D',
    createdAt: '2024-09-18T07:45:00Z',
    startDate: new Date('2024-09-25'),
    endDate: new Date('2024-09-30'),
    fullName: 'NGUYỄN MINH MẪN',
    reason: ELeaveDefaultReason.REMOTE,
    status: ELeaveRequestStatus.APPROVED,
  },
  {
    _id: 'leave_001',
    approverName: 'Nguyễn Văn A',
    createdAt: '2024-09-10T08:30:00Z',
    startDate: new Date('2024-09-15'),
    endDate: new Date('2024-09-20'),
    fullName: 'TRẦN THỊ TRIỀU TIÊN',
    reason: ELeaveDefaultReason.SICK,
    status: ELeaveRequestStatus.APPROVED,
  },
  {
    _id: 'leave_002',
    approverName: 'Trần Văn B',
    createdAt: '2024-09-12T10:00:00Z',
    startDate: new Date('2024-09-22'),
    endDate: new Date('2024-09-25'),
    fullName: 'NGUYỄN THỊ ANH THƯ',
    reason: ELeaveDefaultReason.REMOTE,
    status: ELeaveRequestStatus.PENDING,
  },
  {
    _id: 'leave_003',
    approverName: 'Lê Văn C',
    createdAt: '2024-09-14T09:15:00Z',
    startDate: new Date('2024-09-18'),
    endDate: new Date('2024-09-22'),
    fullName: 'BÙI ĐOÀN THẾ SANG',
    reason: ELeaveDefaultReason.HOLIDAY,
    status: ELeaveRequestStatus.REJECTED,
  },
  {
    _id: 'leave_004',
    approverName: 'Phạm Thị D',
    createdAt: '2024-09-18T07:45:00Z',
    startDate: new Date('2024-09-25'),
    endDate: new Date('2024-09-30'),
    fullName: 'NGUYỄN MINH MẪN',
    reason: ELeaveDefaultReason.REMOTE,
    status: ELeaveRequestStatus.APPROVED,
  },
  {
    _id: 'leave_001',
    approverName: 'Nguyễn Văn A',
    createdAt: '2024-09-10T08:30:00Z',
    startDate: new Date('2024-09-15'),
    endDate: new Date('2024-09-20'),
    fullName: 'TRẦN THỊ TRIỀU TIÊN',
    reason: ELeaveDefaultReason.SICK,
    status: ELeaveRequestStatus.APPROVED,
  },
  {
    _id: 'leave_002',
    approverName: 'Trần Văn B',
    createdAt: '2024-09-12T10:00:00Z',
    startDate: new Date('2024-09-22'),
    endDate: new Date('2024-09-25'),
    fullName: 'NGUYỄN THỊ ANH THƯ',
    reason: ELeaveDefaultReason.REMOTE,
    status: ELeaveRequestStatus.PENDING,
  },
]
