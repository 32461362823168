import { Text, VStack, SimpleGrid, Container } from '@chakra-ui/react'
import LabelForm from 'components/LabelForm'
import { observer } from 'mobx-react'

const DetailPartnerForm = () => {
  return (
    <Container background="white" width="full" maxWidth="unset" padding={6} borderRadius={8}>
      <VStack spacing={6} alignItems="flex-start" height="full">
        <Text fontWeight={600} fontSize="xl">
          Partner Detail
        </Text>
        <SimpleGrid columns={{ base: 1, md: 2 }} gap={8} width="full">
          <LabelForm name="number" label="Partner Number" readonly={true} isHighligh />
          <LabelForm name="fullName" label="Full Name" readonly={true} isHighligh />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2 }} gap={8} width="full">
          <LabelForm name="phone" label="Phone Number (Optional)" readonly={true} isHighligh />
          <LabelForm name="address" label="Address" readonly={true} isHighligh />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2 }} gap={8} width="full">
          <LabelForm name="type" label="Partner Type" readonly={true} />
          <LabelForm name="category" label="Partner Category" readonly={true} />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2 }} gap={8} width="full">
          <LabelForm name="email" label="Email" readonly={true} />
          <LabelForm name="website" label="Website" readonly={true} />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2 }} gap={8} width="full">
          <LabelForm name="taxCode" label="Tax Code" readonly={true} />
          <LabelForm name="billingAddress" label="Billing Address" readonly={true} />
        </SimpleGrid>
      </VStack>
    </Container>
  )
}

export default observer(DetailPartnerForm)
