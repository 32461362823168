import React, { useEffect } from 'react'
import { Box } from '@chakra-ui/react'
import FormModel from 'components/FormModel'
import Table from 'components/Table'
import { observer } from 'mobx-react'
import { IBirthday } from 'types/user'
import { getValidArray } from 'utils/commonUtils'
import { getFormattedDateWith_MMMDDYYYY } from 'utils/dateFormatUtils'
import { useStores } from 'utils/hooks/useStores'
import FilterForm from './components/FilterForm'
import { headerListDesktop } from './constants'

const BirthDayOfStaff = () => {
  const { adminUserStore } = useStores()
  const { birthdayList } = adminUserStore

  useEffect(() => {
    adminUserStore.getBirthdayList()
  }, [])

  const dataInTable = getValidArray(birthdayList).map((item: IBirthday) => {
    return {
      ...item,
      birthday: getFormattedDateWith_MMMDDYYYY(item?.dateOfBirth ?? ''),
    }
  })

  function filterSubmitHandler(data) {
    adminUserStore.getBirthdayList(data)
  }

  return (
    <Box bg="white" p={6} borderRadius="6px" border="1px solid #E2E8F0">
      <FormModel initialValues={{}} handleSubmit={filterSubmitHandler} component={<FilterForm />} />
      <Table
        headerList={headerListDesktop}
        isLoading={adminUserStore.isBirthDayListLoading}
        tableData={dataInTable}
        isStriped
      />
    </Box>
  )
}

export default observer(BirthDayOfStaff)
