import { useEffect, useState } from 'react'
import { HStack, VStack, Text, Box, Stack } from '@chakra-ui/react'
import Button from 'components/Button'
import RadarChart from 'components/RadarChart'
import { COLOR } from 'components/RadarChart/constants'
import { get } from 'lodash'
import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import { ISkill } from 'types/user'
import { EUserDetailTitle } from 'constants/enum'
import { isValidArray, getDisplayName } from 'utils/commonUtils'
import { useStores } from 'utils/hooks/useStores'
import Section from '../Section'
import AddSkillChartModal from './components/AddSkillChartModal'
import EditSkillChartModal from './components/EditSkillChartModal'
import { ISkillAnalysisFormState } from './components/SkillAnalysisForm/interfaces'
import SkillAnalysisHistoryModal from './components/SkillAnalysisHistoryModal'

const SkillAnalysis = () => {
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState<boolean>(false)
  const [isAddSkillChartOpen, setIsAddSkillChartOpen] = useState<boolean>(false)
  const [isEditSkillChartOpen, setIsEditSkillChartOpen] = useState<boolean>(false)
  const params = useParams()
  const userId = get(params, 'id', '')
  const { adminUserStore } = useStores()
  const { userDetail, averageSkill, skills } = adminUserStore
  const isAlreadyHaveSkills = !isValidArray(skills)

  function openHistoryModal() {
    setIsHistoryModalOpen(true)
  }

  function closeHistoryModal() {
    setIsHistoryModalOpen(false)
  }

  function openAddSkillChartModal() {
    setIsAddSkillChartOpen(true)
  }

  function closeAddSkillChartModal() {
    setIsAddSkillChartOpen(false)
  }

  function openEditSkillChartModal() {
    setIsEditSkillChartOpen(true)
  }

  function closeEditSkillChartModal() {
    setIsEditSkillChartOpen(false)
  }

  async function addSkillChart(data: ISkillAnalysisFormState) {
    const formatedData: Partial<ISkill> = {
      workingAttitude: Number(data.workingAttitude.value),
      meetDeadlines: Number(data.meetDeadlines.value),
      problemSolving: Number(data.problemSolving.value),
      communication: Number(data.communication.value),
      teamPlayer: Number(data.teamPlayer.value),
      technicalSkills: Number(data.technicalSkills.value),
      english: Number(data.english.value),
      comment: data.comment,
    }
    await adminUserStore.createSkill(userId, formatedData)
    await adminUserStore.getSkillsByUserId(userId)
    await adminUserStore.getAverageSkill()
    closeEditSkillChartModal()
    closeAddSkillChartModal()
  }

  useEffect(() => {
    adminUserStore.getAverageSkill()
    adminUserStore.getSkillsByUserId(userId)
  }, [])

  return (
    <Section title={EUserDetailTitle.SKILL_ANALYSIS}>
      <Stack position="relative" direction={{ lg: 'row', md: 'column', sm: 'column' }}>
        <VStack width={{ lg: '80%', md: '100%', sm: '100%' }}>
          <RadarChart
            data={{
              user: skills[0],
              average: averageSkill,
            }}
          />
        </VStack>
        <Stack
          direction={{ lg: 'column', md: 'row', sm: 'column' }}
          width={{ lg: '20%', md: '100%', sm: '100%' }}
          alignItems={{ lg: 'start', md: 'start', sm: 'center' }}
          placeItems="start"
          alignSelf="start"
          justifyContent="space-around"
          paddingTop="40px">
          <Box d="flex" alignItems="center" mb={2}>
            <Box h="12px" w="12px" bg={COLOR.userRadarChart} borderRadius="50%" mr={2} />
            <Text margin="0">{getDisplayName(userDetail?.profileDetailData)}</Text>
          </Box>
          <Box d="flex" alignItems="center">
            <Box h="12px" w="12px" bg={COLOR.averageRadarChart} borderRadius="50%" mr={2} />
            <Text margin="0">UDT Average Index</Text>
          </Box>
        </Stack>
        <HStack
          position={{ lg: 'absolute', md: 'absolute', sm: 'static' }}
          right="0"
          top="-58"
          width={{ sm: '100%', md: 'auto', lg: 'auto' }}
          justifyContent="space-around"
          mt="12px">
          {!isAlreadyHaveSkills && (
            <Button leftIcon="clock.svg" variant="outline" onClick={openHistoryModal}>
              History
            </Button>
          )}
          {isAlreadyHaveSkills && (
            <Button leftIcon="add.svg" variant="outline" onClick={openAddSkillChartModal}>
              Add chart
            </Button>
          )}
          {!isAlreadyHaveSkills && (
            <Button variant="outline" leftIcon="edit.svg" onClick={openEditSkillChartModal}>
              Edit chart
            </Button>
          )}
        </HStack>
      </Stack>
      <SkillAnalysisHistoryModal
        isOpen={isHistoryModalOpen}
        closeHandler={closeHistoryModal}
        skillAnalysisHistoryData={skills}
      />
      <AddSkillChartModal
        isOpen={isAddSkillChartOpen}
        closeHandler={closeAddSkillChartModal}
        saveHandler={addSkillChart}
      />
      <EditSkillChartModal
        isOpen={isEditSkillChartOpen}
        closeHandler={closeEditSkillChartModal}
        saveHandler={addSkillChart}
        skillAnalysisHistoryData={skills}
      />
    </Section>
  )
}

export default observer(SkillAnalysis)
