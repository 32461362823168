import { VStack, HStack, Text, Tooltip, Box } from '@chakra-ui/react'
import { ReactComponent as TooltipIcon } from 'assets/images/svg/tooltip.svg'
import { DiagramSchema } from 'beautiful-react-diagrams/@types/DiagramSchema'
import Button from 'components/Button'
import { observer } from 'mobx-react'
import { getValidArray } from 'utils/commonUtils'
import { useStores } from 'utils/hooks/useStores'
import { roundNumberToFixedDigits } from 'utils/numberUtils'
import { ISidebarContent, ISidebarInfo, ITooltipOption } from '../../constant'
import { clearEventNode, handleClassNameUser } from '../../utils'
import { getCommitTimeColor } from '../../utils'
import TooltipOption from '../Tooltip'
import { TextTitle } from './sidebarUser.styles'

interface ISidebarUserProps {
  userDetail?: ISidebarInfo
  schema: DiagramSchema<unknown>
  totalWorkingDaysWithoutWeekend: number
  startDate: string
  endDate: string
  onChange: (schema: DiagramSchema<unknown>) => void
}

const SidebarUser = (props: ISidebarUserProps) => {
  const { userDetail, totalWorkingDaysWithoutWeekend, startDate, endDate, schema, onChange } = props
  const { adminDiagramStore } = useStores()
  const isIncludeWeekend: boolean = Number(userDetail.totalWorkingDate) > totalWorkingDaysWithoutWeekend
  const contentTooltip: ITooltipOption[] = [
    {
      color: 'green.500',
      content: 'Stable',
    },
    {
      color: 'yellow.500',
      content: 'High',
    },
    {
      color: 'red.500',
      content: 'Very High',
    },
  ]

  return (
    <VStack width="full">
      <HStack width="full" justifyContent="space-between">
        <HStack>
          <TextTitle>total working time</TextTitle>
          <Tooltip
            label={
              <Box>
                <Text>From: {startDate}</Text>
                <Text>To: {endDate}</Text>
                <HStack>
                  <Box>Duration: {userDetail.totalWorkingDate} days </Box>
                  {isIncludeWeekend && <Box color={'yellow'}>(include weekend)</Box>}
                </HStack>
              </Box>
            }>
            <TooltipIcon />
          </Tooltip>
        </HStack>
        <Text>{roundNumberToFixedDigits(userDetail?.totalWorkingTime)}</Text>
      </HStack>
      <HStack width="full" justifyContent="space-between">
        <HStack>
          <TextTitle>Number of Projects</TextTitle>
          <Tooltip label={<TooltipOption data={contentTooltip} />} placement="right-start">
            <TooltipIcon />
          </Tooltip>
        </HStack>
        <Text className={handleClassNameUser(getValidArray(userDetail?.content).length)}>
          {getValidArray(userDetail?.content).length}
        </Text>
      </HStack>
      <VStack width="100%">
        <HStack width="full">
          <TextTitle>
            avg. working (hrs/day)
            <Text margin={0} fontStyle="italic" fontWeight="normal">
              Actual / Commit
            </Text>
          </TextTitle>
        </HStack>
        {getValidArray(userDetail?.content).map((user: ISidebarContent, index: number) => (
          <HStack key={index} width="full" justifyContent="space-between">
            <Text margin={0} key={user?.information}>
              {user?.information}
            </Text>
            <HStack height="24px">
              <Text key={index}>{roundNumberToFixedDigits(user?.workingTime)}</Text>
              <Text
                color={getCommitTimeColor(user?.workingTime[0], user?.commitTime[0])}
                key={index}>{`(${user?.commitTime})`}</Text>
            </HStack>
          </HStack>
        ))}
      </VStack>
      <Button
        variant="outline"
        leftIcon="arrow-left-s-line.svg"
        width="full"
        onClick={() => clearEventNode(schema, adminDiagramStore, onChange)}>
        All Nodes
      </Button>
    </VStack>
  )
}

export default observer(SidebarUser)
