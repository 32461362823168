import { Button as ChakraButton } from '@chakra-ui/button'
import Icon from 'components/Icon'
import { getButtonStyles } from './container'
import { IButtonProps } from './interface'

const Button = (props: IButtonProps) => {
  const {
    children,
    color,
    variant,
    type = 'button',
    backgroundColor,
    width,
    height,
    disabled,
    colorScheme,
    isLoading,
    customStyles,
    leftIcon,
    leftIconSize,
    rightIcon,
    rightIconSize,
    onClick,
  } = props

  return (
    <ChakraButton
      width={width}
      height={height}
      color={color}
      cursor="pointer"
      disabled={disabled}
      type={type}
      variant={variant}
      colorScheme={colorScheme}
      isLoading={isLoading}
      fontWeight="500"
      fontFamily="Roboto"
      {...getButtonStyles(variant, colorScheme, color)}
      leftIcon={leftIcon && <Icon iconName={leftIcon} size={leftIconSize} />}
      rightIcon={rightIcon && <Icon iconName={rightIcon} size={rightIconSize} />}
      style={customStyles}
      backgroundColor={backgroundColor}
      sx={
        !children && {
          'span.chakra-button__icon': {
            margin: 0,
          },
        }
      }
      onClick={onClick}>
      {children}
    </ChakraButton>
  )
}
export default Button
