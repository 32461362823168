export const selectStyles = (
  container: Record<string, unknown>,
  control: Record<string, unknown>,
  option: Record<string, unknown>,
  menu: Record<string, unknown>,
  menuList: Record<string, unknown>
) => ({
  container: (provided: Record<string, unknown>) => ({
    ...provided,
    width: 'full',
    cursor: 'pointer',
    ...container,
  }),
  control: (provided: Record<string, unknown>) => ({
    ...provided,
    width: 'full',
    margin: 8,
    ...control,
  }),
  option: (provided: Record<string, unknown>) => ({
    ...provided,
    cursor: 'pointer',
    ...option,
  }),
  menu: (provided: Record<string, unknown>) => ({
    ...provided,
    width: '100%',
    borderRadius: '0 0 4px 4px',
    ...menu,
  }),
  menuList: (provided: Record<string, unknown>) => ({
    ...provided,
    width: '100%',
    ...menuList,
  }),
})
