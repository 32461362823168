import { useEffect, useState } from 'react'
import { VStack, HStack } from '@chakra-ui/react'
import { enablePushNotificationAPI, disablePushNotificationAPI } from 'API/fcm'
import RadioButton from 'components/RadioButton/RadioButtonWithoutFormik'
import { NotificationId, UserId } from 'constants/common'
import { firebaseCloudMessaging } from 'utils/fcm'

const PushNotification = () => {
  const [notificationId, setNotificationId] = useState(null)
  const [enablePushNotification, setEnablePushNotification] = useState(false)
  useEffect(() => {
    const notificationId = window.localStorage.getItem(NotificationId)
    if (!!notificationId) {
      setNotificationId(notificationId)
      setEnablePushNotification(true)
    }
    if (!notificationId) {
      setEnablePushNotification(false)
    }
  }, [])
  async function enablePushNotificationHandler() {
    await firebaseCloudMessaging.init()
    const token = firebaseCloudMessaging.tokenInLocalStorage()
    if (token) {
      setNotificationId(token)
      setEnablePushNotification(true)
      const data = {
        userId: window.localStorage.getItem(UserId),
        fcmToken: token,
      }
      enablePushNotificationAPI(data)
    }
  }

  async function disablePushNotificationHandler() {
    const data = {
      userId: window.localStorage.getItem(UserId),
      fcmToken: notificationId,
    }
    setNotificationId(null)
    window.localStorage.removeItem(NotificationId)
    setEnablePushNotification(false)
    disablePushNotificationAPI(data)
  }
  function onClickPushNotification() {
    if (enablePushNotification) {
      disablePushNotificationHandler()
    } else {
      enablePushNotificationHandler()
    }
  }

  return (
    <VStack width="full">
      <HStack marginBottom={6} width={{ base: '100%', md: '100%' }} justifyContent={'space-between'}>
        <RadioButton
          label="Enable notification in this browser"
          value={enablePushNotification}
          clickHandler={onClickPushNotification}
        />
      </HStack>
    </VStack>
  )
}

export default PushNotification
