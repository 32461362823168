import { Box } from '@chakra-ui/layout'
import { ModalHeader, ModalCloseButton } from '@chakra-ui/modal'
import { borderGray200 } from 'theme/globalStyles'
import { LeaveModalHeaderProps } from './interface'

const LeaveModalHeader = ({ isSummary, onClose }: LeaveModalHeaderProps) => (
  <Box background="white" width="full">
    <ModalHeader borderBottom="1px solid" borderColor={borderGray200} fontWeight={500}>
      {isSummary ? 'Leave request' : 'Create leave request'}
    </ModalHeader>
    <ModalCloseButton color={'gray'} background="none" cursor="pointer" mt="2" onClick={onClose} />
  </Box>
)

export default LeaveModalHeader
