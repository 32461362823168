import { Table as CkTable, Tbody, Tr, Td, Container, Text } from '@chakra-ui/react'
import Pagination from '../DesktopTable/components/Pagination'
import MoreAction from './components/MoreAction'
import { ITabletTable, ETabletTableActionName, ETechnologyListFilter } from './interfaces'
import styles from './styles.module.scss'

const TabletTable = (props: ITabletTable) => {
  const { tableData, headerList, isDetail, pagination, isShowPagination } = props

  const renderRowContent = (key, item) => {
    if (key === ETabletTableActionName.ACTIONS_TABLET) {
      return <MoreAction isDetail={isDetail} actions={item?.actionsTablet} />
    }

    if (key === ETechnologyListFilter.TECHNOLOGY_LIST_FILTER) {
      return item?.technologiesListFilter.map((tech) => (
        <Text
          as="span"
          className={styles.techSpan}
          key={tech}
          bgColor={tech?.tagColor?.substring(0, tech?.tagColor?.length - 1)}
          color={tech?.tagColor}
          border="1px solid"
          borderColor={tech?.tagColor}>
          {tech?.value}
        </Text>
      ))
    }

    return item[key]
  }

  function renderRow(item): JSX.Element {
    return (
      <Tr width={{ sm: 'auto' }} key={item?._id}>
        {headerList &&
          Object.entries(headerList).map(([key, value]) => (
            <Td data-label={value} key={`${item?._id}-${key}`}>
              {renderRowContent(key, item)}
            </Td>
          ))}
      </Tr>
    )
  }

  function renderBody(): JSX.Element {
    return <Tbody width={{ sm: 'auto' }}>{tableData.map(renderRow)}</Tbody>
  }

  return (
    <Container padding="0px" maxWidth="unset" margin="0" background="white" width="full">
      <CkTable className={styles.responsiveTable}>{renderBody()}</CkTable>
      {isShowPagination && <Pagination pagination={pagination} />}
    </Container>
  )
}

export default TabletTable
