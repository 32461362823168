import { useEffect, useState } from 'react'
import { Box } from '@chakra-ui/layout'
import ConfirmModal from 'components/ConfirmModal'
import { EModalType } from 'components/ConfirmModal/enums'
import GeneralList from 'components/GeneralList'
import LoadingChakra from 'components/LoadingChakra'
import { History } from 'history'
import { debounce } from 'lodash'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { IPolicyFilter } from 'types/policy'
import { IPolicy } from 'constants/schema'
import { convertQueryStringToFilter, convertFilterToQueryString } from 'utils/commonUtils'
import { useStores } from 'utils/hooks/useStores'
import HeaderSection from '../components/HeaderSection'
import PolicyFilterModal from './components/PolicyFilterModal'
import { IPolicyFilterData } from './components/PolicyFilterModal/interface'
import { IPolicyFormData } from './components/PolicyForm/interface'
import PolicyModal from './components/PolicyModal'

const PolicyAdministration = () => {
  const { adminPolicyStore } = useStores()
  const { policyGroupsByMonth, isLoading } = adminPolicyStore
  const [isCreatePolicyModalOpen, setIsCreatePolicyModalOpen] = useState<boolean>(false)
  const [isEditPolicyModalOpen, setIsEditPolicyModalOpen] = useState<boolean>(false)
  const [isDeletePolicyModalOpen, setIsDeletePolicyModalOpen] = useState<boolean>(false)
  const [isFilterPolicyModalOpen, setIsFilterPolicyModalOpen] = useState<boolean>(false)
  const [selectedPolicy, setSelectedPolicy] = useState<IPolicy>()
  const history: History = useHistory()

  const [filter, setFilter] = useState<IPolicyFilter>(() => {
    const initialFilter = convertQueryStringToFilter(history.location.search)
    return initialFilter ?? {}
  })

  useEffect(() => {
    const queryStringFromFilter = convertFilterToQueryString({
      ...filter,
      title: typeof filter.title === 'string' ? filter.title : '',
    })
    history.replace({ search: queryStringFromFilter })
  }, [filter])

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action === 'POP') {
        const newFilter = convertQueryStringToFilter(location.search)
        setFilter(newFilter)
      }
    })
    return () => {
      unlisten()
    }
  }, [history])

  useEffect(() => {
    const actualFilter: IPolicyFilter = {
      ...filter,
    }

    if (filter.year) {
      const publishedYear = Number(filter.year)
      actualFilter.publishedDate = {
        start: new Date(publishedYear, 0, 1),
        end: new Date(publishedYear, 11, 31),
      }
    }

    if (filter.title) {
      actualFilter.title = { $regex: filter.title.toString(), $options: 'i' }
    }

    adminPolicyStore.getPolicyGroupsByMonth(actualFilter)
  }, [filter])

  function openCreatePolicyModal() {
    setIsCreatePolicyModalOpen(true)
  }

  function closeCreatePolicyModal() {
    setIsCreatePolicyModalOpen(false)
  }

  function openConfirmDeleteModal(policy: IPolicy) {
    setSelectedPolicy(policy)
    setIsDeletePolicyModalOpen(true)
  }

  function closeDeletePolicyModal() {
    setIsDeletePolicyModalOpen(false)
  }

  function openEditPolicyModal(policy: IPolicy) {
    setSelectedPolicy(policy)
    setIsEditPolicyModalOpen(true)
  }

  function closeEditPolicyModal() {
    setIsEditPolicyModalOpen(false)
  }

  function openFilterPolicyModal() {
    setIsFilterPolicyModalOpen(true)
  }

  function closeFilterPolicyModal() {
    setIsFilterPolicyModalOpen(false)
  }

  async function handleDeletePolicy() {
    adminPolicyStore.isLoading = true
    await adminPolicyStore.deletePolicy(selectedPolicy?._id)
    setIsDeletePolicyModalOpen(false)
    await adminPolicyStore.getPolicyGroupsByMonth()
  }

  async function createPolicy(data: IPolicyFormData) {
    const formattedData = {
      title: data.title,
      content: data.content,
      publishedDate: data.publishedDate,
      published: data.status === 'published',
    }
    adminPolicyStore.isLoading = true
    await adminPolicyStore.createPolicy(formattedData)
    closeCreatePolicyModal()
    await adminPolicyStore.getPolicyGroupsByMonth()
  }

  async function updatePolicy(data: IPolicyFormData) {
    const formattedData = {
      title: data.title,
      content: data.content,
      publishedDate: data.publishedDate,
      published: data.status === 'published',
    }
    adminPolicyStore.isLoading = true
    await adminPolicyStore.updatePolicy(formattedData, selectedPolicy?._id)
    closeEditPolicyModal()
    await adminPolicyStore.getPolicyGroupsByMonth()
  }

  async function filterPolicy(data: IPolicyFilterData) {
    const newFilter: IPolicyFilter = {
      ...filter,
    }

    if (data.status !== null) {
      newFilter.published = data.status === 'published'
    }

    if (data.publishedYear) {
      const publishedYear = new Date(data.publishedYear).getFullYear()
      newFilter.year = publishedYear.toString()
    }

    setFilter(newFilter)

    closeFilterPolicyModal()
  }

  const handleNameChange = debounce((event: { target: { value: string } }) => {
    const searchValue = event?.target?.value ?? ''
    setFilter((previousFilter) => ({
      ...previousFilter,
      title: searchValue,
    }))
  }, 500)

  return (
    <Box background="white" padding="24px" borderRadius="6px">
      <HeaderSection
        openCreateModal={openCreatePolicyModal}
        openFilterModal={openFilterPolicyModal}
        changeName={handleNameChange}
      />
      <Box paddingY="6">
        <LoadingChakra isLoading={isLoading}>
          <GeneralList
            isAdmin={true}
            dataGroupsByMonth={policyGroupsByMonth}
            openConfirmDeleteModal={openConfirmDeleteModal}
            openEditModal={openEditPolicyModal}
          />
        </LoadingChakra>
        <PolicyModal
          isLoading={isLoading}
          isOpen={isCreatePolicyModalOpen}
          closeHandler={closeCreatePolicyModal}
          saveHandler={createPolicy}
        />
        <PolicyModal
          isLoading={isLoading}
          isEdit={true}
          isOpen={isEditPolicyModalOpen}
          closeHandler={closeEditPolicyModal}
          saveHandler={updatePolicy}
          data={selectedPolicy}
        />
        <ConfirmModal
          isLoading={isLoading}
          data={selectedPolicy}
          OKClickHandler={handleDeletePolicy}
          isOpen={isDeletePolicyModalOpen}
          closeHandler={closeDeletePolicyModal}
          title={'Policy'}
          type={EModalType.DELETE}
          content="title"
        />
        <PolicyFilterModal
          isOpen={isFilterPolicyModalOpen}
          isLoading={isLoading}
          closeHandler={closeFilterPolicyModal}
          submitHandler={filterPolicy}
        />
      </Box>
    </Box>
  )
}

export default observer(PolicyAdministration)
