import React from 'react'
import { HStack } from '@chakra-ui/react'
import TagColorInput from './components/TagColorInput'

interface ITagColorSelectProps {
  selectedColor: string
  selectTagColor: (color: string) => void
  actionType?: string
}
const TagColorSelect = (props: ITagColorSelectProps) => {
  const { selectedColor = '', selectTagColor, actionType } = props
  const colorList = [
    'gray.500',
    'red.500',
    'orange.500',
    'yellow.500',
    'green.500',
    'teal.500',
    'blue.500',
    'purple.500',
    'pink.500',
  ]
  return (
    <HStack spacing={2} marginBottom={0}>
      {colorList.map((color, index) => {
        return (
          <TagColorInput
            key={index}
            color={color}
            selectedColor={selectedColor}
            selectTagColor={selectTagColor}
            actionType={actionType}
          />
        )
      })}
    </HStack>
  )
}

export default TagColorSelect
