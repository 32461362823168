import { Box, Spinner } from '@chakra-ui/react'

const LoadingChakra = ({ isLoading, children }: { isLoading: boolean; children: React.ReactNode }) => {
  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner />
      </Box>
    )
  }
  return <>{children}</>
}

export default LoadingChakra
