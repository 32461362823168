import { useState, useContext } from 'react'
import {
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { removeAssignmentAPI } from 'API/diagram'
import { IAssignmentDetailQuery } from 'API/diagram/interface'
import { handleError } from 'API/error'
import Button from 'components/Button'
import startCase from 'lodash/startCase'
import { toast } from 'react-toastify'
import { textGrey700 } from 'theme/globalStyles'
import { ISidebarContent } from 'containers/Diagram/components/NodeDetail/constant'
import { EEditAssignModalType } from 'containers/Diagram/constant'
import { DiagramContext } from 'containers/Diagram/diagram.context'
export interface IRemoveUserModalProps {
  modalType: EEditAssignModalType
  isOpen: boolean
  onClose: () => void
  rowContent: ISidebarContent
}

const RemoveUserModal = (props: IRemoveUserModalProps) => {
  const { modalType, isOpen, onClose, rowContent } = props
  const [allowRemoveUser, setAllowRemoveUser] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { fetchDiagramData } = useContext(DiagramContext)

  function onInputChange(event: { target: { value: string } }): void {
    const inputValue: string = event?.target?.value
    setAllowRemoveUser(inputValue === rowContent?.userName)
  }

  async function handleRemoveUser(): Promise<void> {
    try {
      setIsLoading(true)
      const filter: IAssignmentDetailQuery = {
        project: rowContent?.project,
        user: rowContent?.user,
        projectPaymentCategory: rowContent?.projectPaymentCategory,
      }
      await removeAssignmentAPI({
        where: filter,
      })
      await fetchDiagramData()
      onClose()
      toast.success('Remove user successfully')
    } catch (error) {
      toast.error('Remove user failed')
      handleError(
        error,
        'src/containers/Diagram/components/EditAssignModal/components/RemoveUserModal/index.tsx',
        'handleRemoveUser'
      )
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent height="fit-content !important" maxWidth="448px">
        <ModalHeader boxSize="border-box" fontWeight={500} borderBottom={`1px solid white`}>
          Remove {startCase(modalType)}
        </ModalHeader>
        <ModalCloseButton background="white" color={textGrey700} marginTop={2} />
        <ModalBody>
          <Text marginTop={0}>Are you sure you want to remove this user from {rowContent?.projectName}?</Text>
          <FormControl>
            <FormLabel fontWeight={400}>
              Please type <b>{rowContent?.userName}</b> in the{' '}
              <Text display="inline" fontWeight={700}>
                User Full Name
              </Text>{' '}
              to confirm
            </FormLabel>
            <Input boxSizing="border-box" onChange={onInputChange} />
          </FormControl>
        </ModalBody>
        <ModalFooter height="72px">
          <Button variant="outline" customStyles={{ marginRight: '12px' }} onClick={onClose} isLoading={isLoading}>
            Cancel
          </Button>
          <Button colorScheme="red" onClick={handleRemoveUser} disabled={!allowRemoveUser} isLoading={isLoading}>
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default RemoveUserModal
