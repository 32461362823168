import { handleError } from 'API/error'
import {
  getTechnologyListAPI,
  createTechnologyAPI,
  getTechnologyListWithPaginationAPI,
  deleteTechnologyAPI,
  updateTechnologyAPI,
} from 'API/technology'
import { ITechnologyDataFromCreateForm } from 'API/technology/constants'
import get from 'lodash/get'
import { makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'
import { ITechnology } from 'types/technology'
import RootStore from '../rootStore'

class AdminTechnologyStore {
  rootStore: RootStore
  technologyList: ITechnology[] = []
  listCount: number = 0
  isLoadNewTechnologyList: boolean = false
  isLoadingTechnologyList: boolean = false

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public async getTechnologyList(): Promise<void> {
    try {
      const { technologies, count } = await getTechnologyListAPI()
      this.technologyList = technologies
      this.listCount = count
    } catch (error) {
      handleError(error, 'src/store/admin/AdminTechnologyStore.ts', 'getTechnologyList')
    }
  }

  public async getTechnologyListWithPagination(isReset?: boolean, history?: any, filter?: any): Promise<void> {
    try {
      this.isLoadingTechnologyList = true
      const page: number = isReset ? 1 : Number(get(history, 'location.state.page') || 1)
      const payload = {
        page,
        pageSize: 10,
        filter,
      }
      const { technologies, count } = await getTechnologyListWithPaginationAPI(payload)
      this.technologyList = technologies
      this.listCount = count
      this.isLoadNewTechnologyList = false
    } catch (error) {
      handleError(error, 'src/store/admin/AdminTechnologyStore.ts', 'getTechnologyListWithPagination')
    } finally {
      this.isLoadingTechnologyList = false
    }
  }

  public async createTechnology(technology: ITechnologyDataFromCreateForm): Promise<void> {
    try {
      const message = await createTechnologyAPI(technology)
      toast.success(message)
      this.isLoadNewTechnologyList = true
    } catch (error) {
      handleError(error, 'src/store/admin/AdminTechnologyStore.ts', 'createTechnology', false, true)
    }
  }

  public async updateTechnology(technology: ITechnology): Promise<void> {
    try {
      const message = await updateTechnologyAPI(technology)
      toast.success(message)
      this.isLoadNewTechnologyList = true
    } catch (error) {
      handleError(error, 'src/store/admin/AdminTechnologyStore.ts', 'updateTechnology', false, true)
    }
  }

  public async deleteTechnology(technologyId: string): Promise<void> {
    try {
      const message = await deleteTechnologyAPI(technologyId)
      toast.success(message)
      this.isLoadNewTechnologyList = true
    } catch (error) {
      handleError(error, 'src/store/admin/AdminTechnologyStore.ts', 'deleteTechnology', false, true)
    }
  }
}

export default AdminTechnologyStore
