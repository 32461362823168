import React, { useEffect, useState } from 'react'
import { VStack, HStack } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import EmptyContentBox from 'components/EmptyContentBox'
import LoadingChakra from 'components/LoadingChakra'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import { FormProvider, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { EConditionGetList, EDashboardSubTitle } from 'constants/enum'
import { Messages } from 'constants/index'
import { useStores } from 'utils/hooks/useStores'
import StatisticContent from '../../components/StatisticContent'
import StatisticFilterForm from '../../components/StatisticFilterForm'
import { IStatisticFilterFormData, workingPeriodOptions } from '../../constant'

const Statistic = () => {
  window.sessionStorage.setItem('subPageTitle', EDashboardSubTitle.STATISTIC)
  window.dispatchEvent(new Event('storage'))
  const { userProjectStore, userReportStore, userProfileStore } = useStores()
  const { isSupervisor } = userProfileStore
  const [isValidAnalysis, setIsValidAnalysis] = useState(false)
  const { projectNameListForSupervisor } = userProjectStore
  const { haveData, isGetSupervisorStatisticLoading } = userReportStore

  const methods = useForm<IStatisticFilterFormData>({
    resolver: yupResolver(getValidationSchema()),
    defaultValues: {
      period: workingPeriodOptions[1],
      date: !Boolean(JSON.parse(localStorage.getItem('isPickPastMonth')))
        ? dayjs().toDate()
        : dayjs().subtract(1, 'month').endOf('month').toDate(),
    },
  })

  const { getValues } = methods

  const { selectedProject } = getValues()

  function getValidationSchema() {
    const schema = yup.object({
      selectedProject: yup.object().required(Messages.fieldRequired),
    })
    return schema
  }

  function viewAnalyticHandler(data: IStatisticFilterFormData) {
    const { selectedProject, period, date } = data

    const requestData = {
      project: selectedProject.value,
      period: period.value,
      date: dayjs(date).format('YYYY-MM-DD'),
    }
    userReportStore.getSupervisorStatistic(requestData)
  }

  useEffect(() => {
    userProjectStore.getNameProjectListForSupervisor(EConditionGetList.ACTIVE)
  }, [])

  useEffect(() => {
    if (haveData && selectedProject) {
      setIsValidAnalysis(true)
    } else {
      setIsValidAnalysis(false)
    }
  }, [haveData])
  if (!isSupervisor) {
    return null
  }
  return (
    <VStack width={'full'}>
      <FormProvider {...methods}>
        <StatisticFilterForm
          isLoading={isGetSupervisorStatisticLoading}
          projectNameList={projectNameListForSupervisor}
          viewAnalyticHandler={viewAnalyticHandler}
        />
        <LoadingChakra isLoading={isGetSupervisorStatisticLoading}>
          {isValidAnalysis && <StatisticContent />}
          {!isValidAnalysis && (
            <HStack width="full" background="white" minHeight={'80vh'}>
              <EmptyContentBox text={Messages.emptyBoxFilterMessage} position="bottom" />
            </HStack>
          )}
        </LoadingChakra>
      </FormProvider>
    </VStack>
  )
}

export default observer(Statistic)
