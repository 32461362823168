import { useDisclosure } from '@chakra-ui/hooks'
import { CloseIcon } from '@chakra-ui/icons'
import { Box, Grid, Text } from '@chakra-ui/react'
import CheckboxField from 'components/CheckBoxField'
import FormNumberInput from 'components/FormNumberInput'
import SingleSelect from 'components/SingleSelect'
import { EPaymentCategoryOfProject } from 'constants/enum'
import { projectTypeOptions } from 'containers/Diagram/constant'
import { IDataRowProps } from '../../types'
import RemoveUserModal from '../RemoveUserModal'

const DataRow = (props: IDataRowProps) => {
  const { content, isDisabledField = false, index, fieldName } = props
  const workingTime: string = content.workingTime.split('')[0]
  const isFixedPriceCategory: boolean = content?.projectPaymentCategory === EPaymentCategoryOfProject.FIXED_PRICE
  const {
    isOpen: isOpenRemoveUserModal,
    onOpen: onOpenRemoveUserModal,
    onClose: onCloseRemoveUserModal,
  } = useDisclosure()

  function openRemoveUserModal(): void {
    onOpenRemoveUserModal()
  }

  return (
    <Grid width="full" alignItems="center" templateColumns={{ base: 'repeat(8, 1fr)' }} marginTop="0 !important">
      <Box>
        <Text
          fontSize="sm"
          width="120px"
          lineHeight="20px"
          margin={0}
          boxSizing="border-box"
          paddingLeft={2}
          color="gray.700">
          {content.information}
        </Text>
      </Box>
      <FormNumberInput
        name={`${fieldName}[${index}].projectHourlyRate`}
        defaultValue="0"
        isDisabledField={isDisabledField}
      />
      <FormNumberInput name={`${fieldName}[${index}].projectClientRate`} />
      <FormNumberInput name={`${fieldName}[${index}].projectBillableMonthlyAmount`} />
      <FormNumberInput
        name={`${fieldName}[${index}].commitTime`}
        isDisabledField={isDisabledField}
        defaultValue={workingTime}
      />
      <Box padding={2}>
        <SingleSelect
          name={`${fieldName}[${index}].projectType`}
          optionsData={projectTypeOptions}
          placeHolder="Type"
          widthCustom={'170px'}
          isDisabledClearIndicator
          isClearable={true}
        />
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" width="94px">
        <CheckboxField name={`${fieldName}[${index}].isSupervisor`} />
      </Box>
      <Box textAlign="center" width={12} onClick={openRemoveUserModal}>
        <CloseIcon cursor="pointer" color="red.500" />
      </Box>
      {isOpenRemoveUserModal && (
        <RemoveUserModal
          isOpen={isOpenRemoveUserModal}
          modalType={fieldName}
          onClose={onCloseRemoveUserModal}
          rowContent={content}
        />
      )}
    </Grid>
  )
}

export default DataRow
