import { IProjectNameResponse } from 'API/project/constants'
import { IUserNameResponse } from 'API/user/constants'
import { capitalize } from 'lodash'
import { IOption } from 'constants/common'
import { ETypeOfFilter, EWorkingHourPeriods } from 'constants/enum'

export interface ISupervisorFilterFormData {
  period: IOption<EWorkingHourPeriods | 'month-to-date'>
  date: Date
  selectedProject?: IOption<string>
  selectedUser: IOption<string>[]
  selectedProjectsToExport?: IOption<string>[]
}

export const workingPeriodOptions: IOption<EWorkingHourPeriods | 'month-to-date'>[] = [
  { label: capitalize(EWorkingHourPeriods.WEEK), value: EWorkingHourPeriods.WEEK },
  { label: capitalize(EWorkingHourPeriods.MONTH), value: EWorkingHourPeriods.MONTH },
]

export interface ISuperviseFilterFormProps {
  projectNameList: IProjectNameResponse[]
  userNameList: IUserNameResponse[]
  isAnalysisLoading: boolean
  reportWithProjectHandler?: (data: IReportFilterWithProjectFormData) => void
  reportWithUserHandler?: (data: IReportFilterWithUserFormData) => void
  exportDataHandler?: (data: ISupervisorFilterFormData) => void
  typeOfFilter: ETypeOfFilter
  setTypeOfFilter: (value: ETypeOfFilter) => void
}

export interface IUserFilterFormProps {
  projectNameList: IProjectNameResponse[]
  isAnalysisLoading: boolean
  reportWithProjectHandler?: (data: IReportFilterWithProjectFormData) => void
}

export interface IReportFilterWithUserFormData {
  period: IOption<EWorkingHourPeriods | 'month-to-date'>
  date: Date
  selectedProject: IOption<string>[]
  selectedUser: IOption<string>
  isFilterByProject: boolean
}

export interface IReportFilterWithProjectFormData {
  period: IOption<EWorkingHourPeriods | 'month-to-date'>
  date: Date
  selectedProject: IOption<string>
  selectedUser: IOption<string>[]
  isFilterByProject: boolean
}
