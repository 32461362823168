import * as Sentry from '@sentry/react'
import { get } from 'lodash'
import isEmpty from 'lodash/isEmpty'
import { toast } from 'react-toastify'
import { EEnvironment } from 'constants/enum'
import { beforeLogoutHandler } from 'utils/commonUtils'

export enum EErrorType {
  BAD_REQUEST = '400',
  UNAUTHORIZED = '401',
  FORBIDDEN = '403',
  NOT_FOUND = '404',
  UNPROCESSABLE_ENTITY = '422',
  TOO_MANY_REQUESTS = '429',
  INTERNAL_SERVER_ERROR = '500',
}

const errorTypes: EErrorType[] = [
  EErrorType.BAD_REQUEST,
  EErrorType.UNAUTHORIZED,
  EErrorType.FORBIDDEN,
  EErrorType.NOT_FOUND,
  EErrorType.UNPROCESSABLE_ENTITY,
  EErrorType.TOO_MANY_REQUESTS,
  EErrorType.INTERNAL_SERVER_ERROR,
]

export function getErrorMessage(errorResponse: Error): string {
  const errorMessage: string = get(errorResponse, 'response.data.error.message')

  return errorMessage
}

export function handleError(
  error: Error,
  filePath: string,
  functionName: string,
  keepThrowing: boolean = false,
  showToast: boolean = true,
  group: string = ''
): void {
  const errorPath: string = `Error: ${filePath} -> ${functionName} -> error: ${error}`
  const errorMessage = getErrorMessage(error)
  console.error(errorPath, JSON.stringify(error))

  if (!errorTypes.some((type) => errorPath.includes(type))) {
    if (process.env.REACT_APP_ENVIRONMENT !== EEnvironment.DEVELOPMENT) {
      if (!isEmpty(group)) {
        Sentry.setTag('group', group)
      }
      Sentry.captureException({
        errorPath,
        error: JSON.stringify(error),
      })
    }
  }

  if (showToast) {
    toast.error(errorMessage)
  }
  const errorStatusCode = get(error, 'response.data.error.statusCode')
  if (errorStatusCode) {
    const convertedErrorStatusCode = String(errorStatusCode)
    if (convertedErrorStatusCode === EErrorType.UNAUTHORIZED) {
      beforeLogoutHandler()
    }
  }
  if (keepThrowing) {
    throw error
  }
}
