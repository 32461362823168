import apiV2 from 'API/api'
import { APIRouters } from 'API/APIRouters'
import { ISkillSummaryForAdminResponse } from 'API/skill/constants'
import { ISkillRequest } from 'API/summarize/constants'
import { ISkill } from 'types/user'

export async function getAverageSkillAPI(): Promise<ISkill> {
  try {
    const url = APIRouters.skill.average.value
    const response = await apiV2.get(url)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function getSkillAPI(): Promise<ISkill> {
  try {
    const url = APIRouters.skill.value
    const response = await apiV2.get(url)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function getSkillsByUserIdAPI(userId: string): Promise<ISkill[]> {
  try {
    const url = APIRouters.skill.userId.value(userId)
    const response = await apiV2.get(url)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function createSkillAPI(userId: string, payload: Partial<ISkill>): Promise<string> {
  try {
    const url = APIRouters.skill.userId.value(userId)
    const response = await apiV2.post(url, payload)
    return response?.data?.message
  } catch (error) {
    throw error
  }
}

export async function getSkillSummaryForAdminAPI(payload: ISkillRequest): Promise<ISkillSummaryForAdminResponse> {
  try {
    const url = APIRouters.skill.summary.forAdmin.value
    const response = await apiV2.post(url, payload)
    return response?.data
  } catch (error) {
    throw error
  }
}
