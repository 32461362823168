import { useState } from 'react'
import { CloseIcon } from '@chakra-ui/icons'
import { Tag } from '@chakra-ui/react'
import { useFormContext, useWatch } from 'react-hook-form'
import { borderGray500, transparent } from 'theme/globalStyles'
import { IOption } from 'constants/common'
import { getNewList } from './container'
import { IBadgeImageTagProps } from './interfaces'

const BadgeImageTag = (props: IBadgeImageTagProps) => {
  const { label, fieldName, selectDataHandler } = props
  const { setValue, control } = useFormContext()
  const [isHovered, setIsHovered] = useState<boolean>(false)
  const dataList: IOption[] = useWatch({ control, name: fieldName })

  function handleRemoveTag(): void {
    const newList = getNewList(dataList, label)
    setValue(fieldName, newList)
    if (selectDataHandler) {
      selectDataHandler(newList)
    }
  }

  return (
    <Tag
      width="fit-content"
      border="1px solid"
      borderColor={isHovered ? `${borderGray500}` : `${transparent}`}
      backgroundColor="transparent"
      paddingX={3}
      paddingY={1}
      marginRight={4}
      marginBottom={2}
      borderRadius="6px"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      _hover={{ borderColor: `${borderGray500}` }}>
      {label}
      <CloseIcon
        marginLeft={2}
        width={2}
        height={2}
        cursor="pointer"
        onClick={handleRemoveTag}
        color={isHovered ? `${borderGray500}` : `${transparent}`}
      />
    </Tag>
  )
}

export default BadgeImageTag
