export const country = {
  // Global Config Page - Country &
  // [Project List Page & Project Detail & Project Create - Country]
  value: '/country',
  list: {
    value: '/country/list',
  },
  countryId: {
    value: (countryId: string) => `/country/${countryId}`,
  },
}
