export const diagramNode = {
  value: '/diagram-node',
  overview: {
    value: '/diagram-node/overview',
  },
  diagramNodeId: {
    value: (diagramNodeId: string) => `/diagram-node/${diagramNodeId}`,
  },
  reInitialize: {
    value: '/diagram-node/re-initialize',
  },
  assign: {
    value: '/diagram-node/assign',
  },
  assignment: {
    value: '/diagram-node/assignment',
    list: {
      value: '/diagram-node/assignment/list',
    },
    update: {
      value: '/diagram-node/assignment/update',
    },
    remove: {
      value: '/diagram-node/assignment/remove',
    },
  },
}
