import apiV2 from 'API/api'
import { APIRouters } from 'API/APIRouters'
import { ISuccessResponse } from 'API/constants'
import { AxiosResponse } from 'axios'
import { IDashboardUser } from 'types/dashboard-user'
import { IFormChangePassword, IUser } from 'types/user'
import { EWorkingHourPeriods } from 'constants/enum'
import {
  IBirthdayFilter,
  IBirthdayList,
  IChangePasswordResponse,
  ICreateUserRequest,
  IUpdateUserProfileResponse,
  IUpdateUserRequest,
  IUserListWithPaginationRequest,
  IUserProfileResponse,
  IUserNameListResponse,
  IUserListWithPaginationResponse,
  IGetAvatarResponse,
} from './constants'

// Birthday
export async function getBirthdayListAPI(payload: IBirthdayFilter): Promise<IBirthdayList> {
  try {
    const url: string = APIRouters.user.birthdayList.value
    const response: AxiosResponse = await apiV2.post(url, payload)
    return response.data
  } catch (error) {
    throw error
  }
}
// Profile
export async function changePasswordAPI(
  payload: IFormChangePassword
): Promise<ISuccessResponse<IChangePasswordResponse>> {
  try {
    return apiV2.post(APIRouters.user.profile.changePassword.value, payload)
  } catch (error) {
    throw error
  }
}

export async function getIsSupervisorAPI(): Promise<boolean> {
  try {
    const url: string = APIRouters.user.profile.isSupervisor.value
    const response: AxiosResponse = await apiV2.get(url)
    return response?.data?.isSupervisor
  } catch (error) {
    throw error
  }
}

export async function getUserProfileAPI(): Promise<ISuccessResponse<IUserProfileResponse>> {
  try {
    const url = APIRouters.user.profile.detail.value
    return apiV2.get(url)
  } catch (error) {
    throw error
  }
}

// *INFO: using "object" type because the API response empty object
export async function updateUserProfileAPI(payload: IUser): Promise<ISuccessResponse<object>> {
  try {
    const url = APIRouters.user.profile.update.value
    return apiV2.post(url, payload)
  } catch (error) {
    throw error
  }
}

export async function updateUserDataAPI(payload: object): Promise<ISuccessResponse<IUpdateUserProfileResponse>> {
  try {
    const url = APIRouters.user.profile.updateUserData.value
    return apiV2.post(url, payload)
  } catch (error) {
    throw error
  }
}

export async function getCvFileAPI(cvFileName: string): Promise<ISuccessResponse<BlobPart>> {
  try {
    const url = APIRouters.user.getCVFile.cvFileName.value(cvFileName)
    return apiV2.get(url, {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf',
      },
      skipEncryption: true,
    })
  } catch (error) {
    throw error
  }
}

export async function getAvatarAPI(userId: string): Promise<ISuccessResponse<IGetAvatarResponse>> {
  try {
    const url = APIRouters.user.avatar.userId.value(userId)
    return apiV2.get(url)
  } catch (error) {
    throw error
  }
}

export async function downloadCVFromAdminAPI(userId: string, cvFileName: string): Promise<ISuccessResponse<BlobPart>> {
  try {
    const url = APIRouters.user.downloadCV.adminDownload.userId_cvFileName.value(userId, cvFileName)
    return apiV2.get(url, {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf',
      },
      skipEncryption: true,
    })
  } catch (error) {
    throw error
  }
}

// User

export async function getAllNameUserListAPI(condition: string): Promise<ISuccessResponse<IUserNameListResponse>> {
  try {
    const url = APIRouters.user.nameListForAdmin.condition.value(condition)
    return apiV2.get(url)
  } catch (error) {
    throw error
  }
}

export async function getAllNameUserListOfSupervisorAPI(
  condition: string
): Promise<ISuccessResponse<IUserNameListResponse>> {
  try {
    const url = APIRouters.user.nameListForSupervisor.condition.value(condition)
    return apiV2.get(url)
  } catch (error) {
    throw error
  }
}

export async function getAllNameUserLisWithAGivenTitleAPI(
  title: string,
  condition: string
): Promise<ISuccessResponse<IUserNameListResponse>> {
  try {
    const url = APIRouters.user.nameLisWithAGivenTitle.title_condition.value(title, condition)
    return apiV2.get(url)
  } catch (error) {
    throw error
  }
}

export async function getUserListWithPaginationAPI(
  payload?: IUserListWithPaginationRequest
): Promise<ISuccessResponse<IUserListWithPaginationResponse>> {
  try {
    const url = APIRouters.user.list.value
    return apiV2.post(url, payload)
  } catch (error) {
    throw error
  }
}

export async function createUserV2API(createUser: ICreateUserRequest): Promise<string> {
  try {
    const url = APIRouters.user.create.value
    const response: AxiosResponse = await apiV2.post(url, createUser)
    return response.data?.message
  } catch (error) {
    throw error
  }
}

export async function deleteUserV2API(userId: string): Promise<string> {
  try {
    const url = APIRouters.user.delete.userId.value(userId)
    const response = await apiV2.delete(url)
    return response?.data?.message
  } catch (error) {
    throw error
  }
}

export async function archivedUserAPI(userId: string): Promise<string> {
  try {
    const url = APIRouters.user.archive.userId.value(userId)
    const response = await apiV2.get(url)
    return response?.data?.messages
  } catch (error) {
    throw error
  }
}

export async function unArchivedUserAPI(userId: string, selectedProjectIds?: string[]): Promise<string> {
  try {
    const payload = {
      selectedProjectIds: selectedProjectIds,
    }
    const url = APIRouters.user.unarchive.userId.value(userId)
    const response: AxiosResponse = await apiV2.post(url, payload)
    return response?.data?.messages
  } catch (error) {
    throw error
  }
}

export async function activeUserAPI(userId: string): Promise<string> {
  try {
    const url = APIRouters.user.active.userId.value(userId)
    const response = await apiV2.get(url)
    return response?.data?.messages
  } catch (error) {
    throw error
  }
}

export async function deactivateUserAPI(userId: string): Promise<string> {
  try {
    const url = APIRouters.user.deActive.userId.value(userId)
    const response = await apiV2.get(url)
    return response?.data?.messages
  } catch (error) {
    throw error
  }
}

export async function getUserDetailV2API(userId: string): Promise<IUserProfileResponse> {
  try {
    const url = APIRouters.user.detail.userId.value(userId)
    const response: AxiosResponse = await apiV2.get(url)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function updateUserDetailV2API(userId: string, payload: IUpdateUserRequest): Promise<string> {
  try {
    const url = APIRouters.user.update.userId.value(userId)
    const response: AxiosResponse = await apiV2.post(url, payload)
    return response?.data?.message || ''
  } catch (error) {
    throw error
  }
}

export async function updateUserProjectRatingV2API(userId: string, payload: IUpdateUserRequest): Promise<void> {
  try {
    const url = APIRouters.user.updateProjectRate.userId.value(userId)
    await apiV2.post(url, payload)
  } catch (error) {
    throw error
  }
}

export async function getCVNameByUserIdAPI(userId: string): Promise<string> {
  const url: string = APIRouters.user.detail.cv.userId.value(userId)
  const response: AxiosResponse = await apiV2.get(url)
  return response?.data?.data?.cv
}

export async function getUserListOfUnarchiveProjectAPI(projectId: string) {
  const url: string = APIRouters.user.listOfUnarchiveProject.projectId.value(projectId)
  const response: AxiosResponse = await apiV2.get(url)
  return response?.data
}

// Dashboard
export async function getDashboardUser(
  period: EWorkingHourPeriods,
  date: string,
  userId: string
): Promise<IDashboardUser> {
  try {
    const url = APIRouters.user.dashboard.value
    const fullUrl: string = `${url}?period=${period}&date=${date}&userId=${userId}`
    const response: AxiosResponse = await apiV2.get(fullUrl)
    return response?.data
  } catch (error) {
    return {
      displayName: '',
      fullName: '',
      totalSalary: 0,
      totalWorkingTime: 0,
      lastTotalSalary: 0,
      lastTotalWorkingTime: 0,
      numberOfProjects: 0,
      lastNumberOfProjects: 0,
      commitTimes: [],
      timesheetData: [],
      projectWorkingTimePieData: [],
      performanceLineData: [],
      salaryBarData: [],
    }
  }
}
