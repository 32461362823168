export const todoOfHR = {
  // TodoOfHR Page
  value: '/todoOfHR',
  list: {
    value: '/todoOfHR/list',
  },
  create: {
    value: '/todoOfHR/create',
  },
  update: {
    value: '/todoOfHR/update',
    todoOfHRId: {
      value: (todoId) => `/todoOfHR/update/${todoId}`,
    },
  },
  changeState: {
    value: '/todoOfHR/update',
    todoOfHRId: {
      value: (todoId) => `/todoOfHR/change-state/${todoId}`,
    },
  },
  delete: {
    value: '/todoOfHR/delete',
    todoOfHRId: {
      value: (todoId) => `/todoOfHR/delete/${todoId}`,
    },
  },
}
