import { Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel } from '@chakra-ui/accordion'
import Icon from '@chakra-ui/icon'
import { InfoOutlineIcon } from '@chakra-ui/icons'
import { Text } from '@chakra-ui/layout'
import { Box } from '@chakra-ui/layout'
import { borderBlue500, backgroundGrey50, backgroundBlue50, textBlue500, textGrey700 } from 'theme/globalStyles'

export const ReportSpecification = () => {
  return (
    <Box mt={8}>
      <Accordion allowToggle>
        <AccordionItem
          border={`1px solid ${borderBlue500}`}
          borderRadius="6px"
          boxShadow="none"
          outline="none"
          backgroundColor={backgroundGrey50}>
          <AccordionButton
            cursor="pointer"
            boxShadow="none !important"
            outline="none"
            backgroundColor={backgroundBlue50}
            _expanded={{ background: `${backgroundBlue50}` }}
            _hover={{ background: `${backgroundBlue50}` }}
            borderRadius="6px">
            <Icon as={InfoOutlineIcon} color={textBlue500} />
            <Box
              as="span"
              flex="1"
              textAlign="left"
              color={textBlue500}
              paddingLeft="10px"
              fontWeight="500"
              fontSize="16px">
              REPORT SPECIFICATION
            </Box>
            <AccordionIcon color={textBlue500} />
          </AccordionButton>
          <AccordionPanel paddingY="16px" backgroundColor={backgroundBlue50}>
            <Text fontWeight={600} margin={0}>
              Format:
            </Text>
            <Text fontSize="14px" color={textGrey700} whiteSpace="pre-line" lineHeight="20px" mt={0}>
              Daily Report {'{dd/mm}'} {'\n'} Done:{'\n'} {'{Task code}'} | {'{Task Name}'}: {'{Task Description}'} |{' '}
              {'{startTime in XhYm 24h format}'} | {'{duration in XhYm 24h format}'}
            </Text>
            <Text margin={0} fontWeight={600}>
              Example:
            </Text>
            <Text fontSize="14px" color={textGrey700} whiteSpace="pre-line" lineHeight="20px" mt={0}>
              Daily Report 01/01 {'\n'} Done:{'\n'} UP-1 | Implement UI Smart Timesheet: Modal Smart Timesheet | 12h00m
              | 1h30m
            </Text>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  )
}
