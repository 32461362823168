import { chakra, Input } from '@chakra-ui/react'

export const ModalTextInput = chakra(Input, {
  baseStyle: () => ({
    height: 10,
    border: '1px solid #E2E8F0',
    borderRadius: '6px',
    _focus: {
      borderColor: 'transparent',
      boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.6)',
    },
    _disabled: {
      border: '1px solid #E2E8F0',
    },
  }),
})
