import { VStack, HStack, Text, Tooltip, Box } from '@chakra-ui/react'
import { ReactComponent as TooltipIcon } from 'assets/images/svg/tooltip.svg'
import { DiagramSchema } from 'beautiful-react-diagrams/@types/DiagramSchema'
import Button from 'components/Button'
import { observer } from 'mobx-react'
import { getValidArray } from 'utils/commonUtils'
import { useStores } from 'utils/hooks/useStores'
import { roundNumberToFixedDigits } from 'utils/numberUtils'
import { ISidebarContent, ISidebarInfo, ITooltipOption } from '../../constant'
import { clearEventNode, handleClassNameProject } from '../../utils'
import TooltipOption from '../Tooltip'
import { TextTitle } from './sidebarProject.styles'

interface ISidebarProjectProps {
  projectDetail?: ISidebarInfo
  totalWorkingDaysWithoutWeekend: number
  startDate: string
  endDate: string
  schema: DiagramSchema<unknown>
  onChange: (schema: DiagramSchema<unknown>) => void
}

const SidebarProject = (props: ISidebarProjectProps) => {
  const { projectDetail, totalWorkingDaysWithoutWeekend, startDate, endDate, schema, onChange } = props
  const { adminDiagramStore } = useStores()
  const isIncludeWeekend: boolean = Number(projectDetail.totalWorkingDate) > totalWorkingDaysWithoutWeekend
  const contentTooltip: ITooltipOption[] = [
    {
      color: 'yellow.500',
      content: 'Low',
    },
    {
      color: 'green.500',
      content: 'Stable',
    },
    {
      color: 'red.500',
      content: 'High',
    },
  ]
  const projectDetailContent = getValidArray(projectDetail?.content)
  function groupByUserTitle(content: Array<{ userTitle?: string }>) {
    return content.reduce((titleCounts: Record<string, number>, project) => {
      const { userTitle } = project
      if (userTitle) {
        titleCounts[userTitle] = (titleCounts[userTitle] || 0) + 1
      }
      return titleCounts
    }, {})
  }

  const groupedContent = groupByUserTitle(projectDetailContent)

  return (
    <VStack width="full">
      <HStack width="full" justifyContent="space-between">
        <HStack>
          <TextTitle>total working time (hours)</TextTitle>
          <Tooltip
            label={
              <Box>
                <Text>From: {startDate}</Text>
                <Text>To: {endDate}</Text>
                <HStack>
                  <Box>Duration: {projectDetail.totalWorkingDate} days </Box>
                  {isIncludeWeekend && <Box color={'yellow'}>(include weekend)</Box>}
                </HStack>
              </Box>
            }>
            <TooltipIcon />
          </Tooltip>
        </HStack>
        <Text>{roundNumberToFixedDigits(projectDetail?.totalWorkingTime ?? 0)}</Text>
      </HStack>
      <VStack width="100%">
        <HStack width="full" justifyContent="space-between">
          <TextTitle>human resources of project</TextTitle>
          <Text>{getValidArray(projectDetail?.content).length}</Text>
        </HStack>
        {Object.keys(groupedContent).map((userTitle) => (
          <HStack marginTop={2} key={userTitle} width="full" justifyContent="space-between">
            <Text margin={0}>{userTitle}</Text>
            <Text className={handleClassNameProject(groupedContent[userTitle])}>{groupedContent[userTitle]}</Text>
          </HStack>
        ))}
      </VStack>
      <VStack width="100%">
        <HStack width="full">
          <TextTitle>avg. working time (hrs/day)</TextTitle>
          <Tooltip label={<TooltipOption data={contentTooltip} />} placement="right-start">
            <TooltipIcon />
          </Tooltip>
        </HStack>
        {getValidArray(projectDetail?.content).map((project: ISidebarContent, index: number) => (
          <HStack marginTop={2} key={index} width="full" justifyContent="space-between">
            <Text margin={0} key={project?.information}>
              {project?.information}
            </Text>
            <Text className={handleClassNameProject(roundNumberToFixedDigits(project?.workingTime))} key={index}>
              {roundNumberToFixedDigits(project?.workingTime)}
            </Text>
          </HStack>
        ))}
      </VStack>
      <Button
        width="full"
        variant="outline"
        leftIcon="arrow-left-s-line.svg"
        onClick={() => clearEventNode(schema, adminDiagramStore, onChange)}>
        All Nodes
      </Button>
    </VStack>
  )
}

export default observer(SidebarProject)
