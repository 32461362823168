import { handleError } from 'API/error'
import { filterPaymentsUserAPI, editPaymentUserAPI, sendRequestAPI } from 'API/payment'
import { IFilterPaymentsPayload } from 'API/payment/constants'
import { makeAutoObservable } from 'mobx'
import { IPaymentList } from 'types/payment'
import { IProject } from 'types/project'
import RootStore from '../rootStore'

class UserPaymentStore {
  rootStore: RootStore
  projects: IProject[]
  payments: IPaymentList[]
  isPaymentsLoading: boolean = false
  filterPayload: IFilterPaymentsPayload

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public async filterPayments(payload: IFilterPaymentsPayload): Promise<void> {
    try {
      this.isPaymentsLoading = true
      this.filterPayload = payload
      const { paymentsList } = await filterPaymentsUserAPI(payload)
      this.payments = paymentsList
    } catch (error) {
      handleError(error, 'src/store/user/paymentStore.ts', 'filterPaymentsUser')
    } finally {
      this.isPaymentsLoading = false
    }
  }

  public async editPayment(id: string, field: string, currentValue): Promise<void> {
    try {
      await editPaymentUserAPI({
        id,
        field,
        value: !currentValue,
      })
    } catch (error) {
      handleError(error, 'src/store/user/paymentStore.ts', 'editPaymentUser')
    }
  }

  public async sendRequest(id: string, reason: string): Promise<void> {
    try {
      await sendRequestAPI({ id, reason })
    } catch (error) {
      handleError(error, 'src/store/user/paymentStore.ts', 'sendRequest')
    }
  }
}

export default UserPaymentStore
