import React, { useEffect } from 'react'
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons'
import { Container, Table as CkTable, Thead, Tbody, Tr, Th, Td, Box, Stack } from '@chakra-ui/react'
import EmptyContentBox from 'components/EmptyContentBox'
import PropTypes from 'prop-types'
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useFilters,
  useResizeColumns,
  useFlexLayout,
  useExpanded,
  usePagination,
  Column,
} from 'react-table'
import { textGrey700 } from 'theme/globalStyles'
import { Messages } from 'constants/index'
import styles from '../DesktopTable/table.module.scss'

const CustomExpandedTable = ({
  headerList,
  tableData,
  subComponent,
  rowOnClick,
  rowClickHandler,
  headerColor,
  expandedRowObj,
}) => {
  const expandedItemIndex = expandedRowObj.indexOf(true)
  const columns: Column<object>[] = React.useMemo(() => headerList, [headerList]) || []
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, toggleRowExpanded } = useTable(
    {
      columns: columns,
      data: tableData,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useResizeColumns,
    useExpanded,
    usePagination,
    useFlexLayout
  )

  function handleExpandedRowEffect() {
    if (expandedItemIndex >= 0) {
      toggleRowExpanded(expandedItemIndex, true)
    }
  }

  useEffect(handleExpandedRowEffect, [expandedRowObj, toggleRowExpanded])

  return (
    <Stack width="inherit">
      <Container maxWidth="100%" padding="0px" margin="0px !important">
        <Box className={styles.scrollAble}>
          <CkTable size={'md'} variant="striped" colorScheme="gray" outline="none" {...getTableProps()}>
            <Thead>
              {headerGroups.map((headerGroup) => (
                <React.Fragment key={headerGroup.headers.length + '_hfrag'}>
                  <Tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <Th
                        key={column.id}
                        className={`p-2 table-header ${headerColor ? 'primary-' + headerColor : 'primary-deq'}`}
                        {...column.getHeaderProps()}>
                        <span {...column.getSortByToggleProps()}>
                          {column.render('Header')}
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <TriangleDownIcon aria-label="sorted descending" />
                            ) : (
                              <TriangleUpIcon aria-label="sorted ascending" />
                            )
                          ) : null}
                        </span>
                      </Th>
                    ))}
                  </Tr>
                </React.Fragment>
              ))}
            </Thead>

            {rows.length === 0 ? (
              <Box marginTop={8}>
                <EmptyContentBox height="200px" position="bottom" text={Messages.noData} />
              </Box>
            ) : (
              <Tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row)
                  return (
                    <React.Fragment key={row.original.id + '_frag'}>
                      <Tr
                        {...row.getRowProps()}
                        id={row.original.id}
                        onClick={rowOnClick ? () => rowClickHandler(row.original) : () => ''}>
                        {row.cells.map((cell) => (
                          <Td {...cell.getCellProps()} fontSize={14} color={textGrey700}>
                            {cell.render('Cell')}
                          </Td>
                        ))}
                      </Tr>
                      {row.isExpanded ? subComponent({ row }) : null}
                    </React.Fragment>
                  )
                })}
              </Tbody>
            )}
          </CkTable>
        </Box>
      </Container>
    </Stack>
  )
}

CustomExpandedTable.defaultProps = {
  rowOnClick: false,
  showPagination: false,
  expandRows: false,
  expandedRowObj: [],
}

CustomExpandedTable.propTypes = {
  /** Specified if pagination should show or not */
  showPagination: PropTypes.bool.isRequired,

  /** Specifies if there should be a row onClick action */
  rowOnClick: PropTypes.bool.isRequired,

  /** OPTIONAL: The onClick Action to be taken */
  rowClickHandler: PropTypes.func,

  /** header color background. There are six possible choices. Refer to ReadMe file for specifics */
  headerColor: PropTypes.string,

  /** The data for the table */
  tableData: PropTypes.arrayOf(PropTypes.object).isRequired,

  /** The headers of the table */
  headerList: PropTypes.arrayOf(PropTypes.object).isRequired,

  /** Expanded rows object to control which rows are expanded */
  expandedRowObj: PropTypes.arrayOf(PropTypes.bool).isRequired,

  /** Sub-component to render for expanded rows */
  subComponent: PropTypes.func,
}

export default CustomExpandedTable
