import { useEffect, useState } from 'react'
import { Box, useMediaQuery } from '@chakra-ui/react'
import { IProjectFilter as IFormattedFilter } from 'API/project/constants'
import Table from 'components/Table'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { backgroundGrey200 } from 'theme/globalStyles'
import { maxMobileSize } from 'constants/common'
import { frontendRoutes } from 'constants/routes'
import { IProject } from 'constants/schema'
import { useStores } from 'utils/hooks/useStores'
import { getHeaderList } from '../constant'
import ProjectSummary from '../ProjectsListAdmin/components/ProjectSummary'
import HeaderSection from './components/HeaderSection'
import { setLocalStorage, setupDataInTable } from './container'
import styles from './index.module.scss'

const ProjectListOfUser = () => {
  const [filter, setFilter] = useState<IFormattedFilter>({})
  const [openSummaryModal, setOpenSummaryModal] = useState<boolean>(false)
  const [selectedProject, setSelectedProject] = useState<IProject>()
  const { userProjectStore, adminTechnologyStore, userProfileStore } = useStores()
  const { projectsList, count } = userProjectStore
  const { profileDetail } = userProfileStore
  const { technologyList } = adminTechnologyStore
  const history = useHistory()
  const [isMobile]: boolean[] = useMediaQuery(maxMobileSize)
  const pageIndex: number = Number(get(history, 'location.state.page', 1)) || 1
  const pagination = {
    includePagination: true,
    pageIndex,
    tableLength: count,
    gotoPage: (page: number) => {
      history.push({ pathname: frontendRoutes.projectPage.projectUser.list.value, state: { page, filter } })
      userProjectStore.getProjectList(false, history, filter)
    },
  }
  setLocalStorage()

  const dataInTable = setupDataInTable(
    projectsList,
    history,
    technologyList,
    profileDetail?.userProjects,
    toggleOpenSummaryModalHandler
  )

  const changeName = debounce((event: { target: { value: string } }) => {
    setFilter({ ...filter, name: { $regex: event?.target?.value ?? '', $options: 'i' } })
  }, 1000)

  function toggleOpenSummaryModalHandler(project: IProject): void {
    setSelectedProject(project)
    setOpenSummaryModal(!openSummaryModal)
  }

  function toggleOpenSummary(): void {
    setOpenSummaryModal(!openSummaryModal)
  }
  useEffect(() => {
    adminTechnologyStore.getTechnologyList()
    userProfileStore.getProfileDetail()
  }, [])

  useEffect(() => {
    userProjectStore.getProjectList(true, history, filter)
  }, [filter])

  return (
    <Box>
      <HeaderSection changeName={changeName} />
      <Box
        className={styles.internalDocumentContainer}
        paddingY={isMobile ? 0 : 4}
        paddingX={isMobile ? 0 : 4}
        background={isMobile ? 'none' : 'white'}
        borderRadius="6px"
        border={isMobile ? 'none' : `1px solid ${backgroundGrey200}`}>
        <Table
          headerList={getHeaderList(isMobile)}
          isLoading={userProjectStore.isProjectListLoading}
          tableData={dataInTable}
          isStriped
          pagination={pagination}
          hasBottomHeader={!isMobile}
        />
        <ProjectSummary isOpen={openSummaryModal} closeHandler={toggleOpenSummary} projectData={selectedProject} />
      </Box>
    </Box>
  )
}

export default observer(ProjectListOfUser)
