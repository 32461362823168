import { ModalContent } from '@chakra-ui/modal'
import LeaveModalBody from '../LeaveModalBody'
import LeaveModalFooter from '../LeaveModalFooter'
import LeaveModalHeader from '../LeaveModalHeader'
import { LeaveModalContentProps } from './interface'

const LeaveModalContent = ({
  isSummary,
  isEdit,
  isLoading,
  methods,
  leaveSelectedData,
  onClose,
}: LeaveModalContentProps) => (
  <ModalContent top={{ sm: isSummary ? '135px' : '25px' }} position="fixed" maxHeight="90vh" borderRadius="6px">
    <LeaveModalHeader isSummary={isSummary} onClose={onClose} />
    <LeaveModalBody isSummary={isSummary} methods={methods} leaveSelectedData={leaveSelectedData} />
    {!isSummary && <LeaveModalFooter isEdit={isEdit} isLoading={isLoading} onCancel={onClose} />}
  </ModalContent>
)

export default LeaveModalContent
