import { Image, Text } from '@chakra-ui/react'
import NoDataText from 'components/NoDataText'
import { ICreatableOption } from 'types/creatableOption'
import { EUploadedImageType } from 'constants/enum'
import { generateImageURL, getDefaultImageURL } from 'utils/imageUtils'

export interface IBadgeImageProps {
  data: ICreatableOption
}

const BadgeImage = (props: IBadgeImageProps) => {
  const { data } = props
  if (!data) return null
  const defaultImageURL = getDefaultImageURL(EUploadedImageType.BADGE_IMAGE)
  if (data?.badgeImage) {
    return (
      <Image
        role="img"
        src={generateImageURL(data?.badgeImage, EUploadedImageType.BADGE_IMAGE)}
        alt={`${data?.type}-image`}
        maxHeight="32px"
        onError={(e) => {
          e.target.src = defaultImageURL
        }}
        title={`${data.value}`}
      />
    )
  }

  if (data?.value) {
    return <Text fontWeight={500}>{data?.value}</Text>
  }

  return <NoDataText />
}

export default BadgeImage
