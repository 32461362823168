import { Box, SimpleGrid, Text, HStack, Circle } from '@chakra-ui/react'
import { ECalendarOfTodoListOfHRViewType } from 'components/Calendar/constants'
import { months, statusColors } from 'constants/common'
import { groupTodosByMonth } from 'containers/HumanResources/utils'
import { useStores } from 'utils/hooks/useStores'
import { IYearCalendarSectionProps } from './interface'

function CalendarGrid(props: IYearCalendarSectionProps) {
  const { userTodoOfHRStore } = useStores()
  const { todoOfHRGroupsByMonth } = userTodoOfHRStore
  const ticketData = groupTodosByMonth(todoOfHRGroupsByMonth)
  const { selectedYear, setViewMode } = props
  const currentMonth = new Date().toLocaleString('default', { month: 'long' })

  function clickMonth(month: string) {
    setViewMode(ECalendarOfTodoListOfHRViewType.DAY_GRID_MONTH)
  }

  return (
    <Box p={5} color="white">
      <SimpleGrid columns={6} spacing={5}>
        {months.map(({ name, color }) => (
          <Box
            key={name}
            bg="white"
            color="black"
            borderRadius="md"
            boxShadow="lg"
            width="220px"
            height="250px"
            cursor="pointer"
            border={name === currentMonth ? '2px solid cyan' : '1px solid gray'}
            onClick={() => clickMonth(name)}>
            {/* Phần màu nền đầu ô chiếm toàn bộ chiều rộng */}
            <Box
              bg={color}
              h="50px"
              w="100%" // Đặt chiều rộng đầy đủ
              display="flex"
              alignItems="center"
              justifyContent="center">
              <Text fontWeight="bold" color="white" fontSize="lg">
                {name.toUpperCase()} {selectedYear}
              </Text>
            </Box>

            {/* Nội dung bên trong ô tháng */}
            <Box mt={3} px={2}>
              {ticketData[name] && (
                <>
                  {Object.entries(ticketData[name]).map(([status, count]) => (
                    <HStack spacing={2} mb={2} key={status}>
                      <Circle size="8px" bg={statusColors[status] || 'gray.400'} />
                      <Text fontSize="sm">{`${status.charAt(0).toUpperCase() + status.slice(1)}: ${count} tasks`}</Text>
                    </HStack>
                  ))}
                </>
              )}
            </Box>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  )
}

export default CalendarGrid
