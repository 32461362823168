import numeral from 'numeral'
import { EAbbreviatedContractTypesVI } from 'constants/enum'
import { EContractTypes } from 'constants/enum'
import { toUpperCaseWithUnderscore } from 'utils/commonUtils'

export function convertToNumber(number) {
  let result = number
  if (typeof number !== 'number') {
    result = numeral(number).value() // Chuyển đổi giá trị về số
  }
  return result
}

export function getFormattedNumber(number: any, character: number = 3) {
  let format
  switch (character) {
    case 0: {
      format = '0,0'
      break
    }
    case 1: {
      format = '0,0.0'
      break
    }
    case 2: {
      format = '0,0.00'
      break
    }
    case 3: {
      format = '0,0.000'
      break
    }
    case 4: {
      format = '0,0.0000'
      break
    }
    default:
      return null
  }

  // Kiểm tra và chuyển đổi giá trị thành số nếu cần thiết
  if (typeof number !== 'number') {
    number = numeral(number).value() // Chuyển đổi giá trị về số
  }

  if (number !== null && !isNaN(number)) {
    return numeral(number).format(format)
  }

  return '-'
}

export function getFormattedNumberOfChartData(number: number, character: number = 3) {
  let format
  switch (character) {
    case 0: {
      format = '0,0'
      break
    }
    case 1: {
      format = '0,0.0'
      break
    }
    case 2: {
      format = '0,0.00'
      break
    }
    case 3: {
      format = '0,0.000'
      break
    }
    default:
      return null
  }
  if (number) {
    return numeral(number).format(format)
  }
  return 0
}

export function getFormattedSalary(number: number) {
  if (number) {
    return numeral(number).format('0,0')
  }
  return '-'
}

export function getValue(number: number) {
  if (number) {
    return numeral(number).value()
  }
  return 0
}

export function formatContractNumber(number: string, type: EContractTypes): string {
  const formattedType: string = toUpperCaseWithUnderscore(type)
  return `${number}/${EAbbreviatedContractTypesVI[formattedType]}`
}

export function roundNumberToFixedDigits(number: string | number, digits = 2): string {
  return Number.parseFloat(`${number}`).toFixed(digits)
}
