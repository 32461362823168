import React from 'react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { HStack, VStack, Text, Box, Link } from '@chakra-ui/react'
import { IReportData } from 'API/report/constants'
import Icon from 'components/Icon'
import SummaryFilterInfoBox from 'components/SummaryFilterInfoBox'
import Table from 'components/Table/CustomExpandedTable'
import HighlightTable from 'components/Table/HighlightTable'
import { observer } from 'mobx-react'
import { Messages } from 'constants/index'
import { getValidArray } from 'utils/commonUtils'
import { getFormattedTimeDate } from 'utils/dateFormatUtils'
import { getFormattedNumber } from 'utils/numberUtils'
import { calculateLateLoggingTime, formatDurationRow } from '../../../utils'
import { getReportSectionHeaderListWithFilterByProject, getReportSectionHeaderListWithFilterByUser } from './container'

interface IReportSectionProps {
  reportData: IReportData
  isFilterByProject?: boolean
}
const ReportSection = (props: IReportSectionProps) => {
  const { reportData, isFilterByProject } = props
  const { timesheetList, highlightData, developerName, startDateOfFilter, endDateOfFilter } = reportData
  const { currencyName } = highlightData
  const expandedRowObj = {
    expanded: {},
  }
  let headerList = []
  if (isFilterByProject) {
    headerList = getReportSectionHeaderListWithFilterByProject(currencyName)
  } else {
    headerList = getReportSectionHeaderListWithFilterByUser(currencyName)
  }
  const tableData = getValidArray(timesheetList).map((timesheet) => {
    const lateLoggingTimeData = calculateLateLoggingTime(timesheet)
    const durationStatusData = formatDurationRow(timesheet.workingTime)
    return {
      ...timesheet,
      taskTypes: timesheet.taskTypesName.map((taskType) => taskType).join(', '),
      duration: getFormattedNumber(timesheet.workingTime, 1),
      durationStatus: durationStatusData,
      salary: getFormattedNumber(timesheet.salary, 1),
      startTime: getFormattedTimeDate(timesheet.startTime),
      endTime: getFormattedTimeDate(timesheet.endTime),
      lateLoggingTime: lateLoggingTimeData.delayDate,
      lateLoggingTimeStatus: lateLoggingTimeData.status,
    }
  })

  const subTable = React.useCallback(({ row }) => {
    return (
      <Box backgroundColor={'blue.50'} padding={'16px'} width={'inherit'}>
        {row.original.taskLink && (
          <Box>
            <Link href={row.original.taskLink} isExternal>
              Task link <ExternalLinkIcon mx="2px" />
            </Link>
          </Box>
        )}
        {row.original.taskTypes && (
          <Box>
            <Text fontWeight={'bold'}>Task Types</Text>
            {row.original.taskTypes}
          </Box>
        )}
      </Box>
    )
  }, [])

  return (
    <HStack width="inherit" background="white" style={{ marginBottom: '10px !important' }}>
      <VStack width="-webkit-fill-available" padding={'16px'} alignItems={'flex-start'}>
        <h3>Report</h3>
        <SummaryFilterInfoBox
          developerName={developerName}
          projectName={highlightData.projectName}
          startDate={startDateOfFilter}
          endDate={endDateOfFilter}
        />
        <Box minWidth={'55vw'} marginTop={'10px'}>
          <HighlightTable data={highlightData} />
        </Box>
        <Box width={'inherit'}>
          <h3>Timesheet List</h3>
          <HStack mb={2}>
            <Icon iconName="information-blue-fill-circle.svg" size={20} />{' '}
            <Text> {Messages.pressToExpandTimesheetDetailGuide} </Text>
          </HStack>
          <VStack width={'inherit'} maxHeight={'1000px'} overflowY={'scroll'}>
            <Table
              tableData={tableData}
              headerList={headerList}
              subComponent={subTable}
              expandRows={true}
              expandedRowObj={[expandedRowObj]}
            />
          </VStack>
        </Box>
      </VStack>
    </HStack>
  )
}

export default observer(ReportSection)
