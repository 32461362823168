import { Search2Icon } from '@chakra-ui/icons'
import { Flex, HStack, Input, InputGroup, InputLeftElement, useMediaQuery } from '@chakra-ui/react'
import Button from 'components/Button'
import { History } from 'history'
import { useHistory } from 'react-router-dom'
import { backgroundGrey200 } from 'theme/globalStyles'
import { maxMobileSize } from 'constants/common'
import { convertQueryStringToFilter } from 'utils/commonUtils'

interface IHeaderSectionProps {
  openCreateModal: () => void
  openFilterModal?: () => void
  changeName?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const HeaderSection = (props: IHeaderSectionProps) => {
  const { openCreateModal, openFilterModal, changeName } = props
  const [isMobile]: boolean[] = useMediaQuery(maxMobileSize)

  const history: History = useHistory()

  const initialFilter = convertQueryStringToFilter(history.location.search)

  return (
    <Flex justifyContent="space-between" flexWrap="wrap" gap="5px">
      <HStack>
        <InputGroup
          border={`1px solid ${backgroundGrey200}`}
          borderRadius="6px"
          background="white"
          width={{ sm: '160px', md: '300px', lg: '400px' }}>
          <InputLeftElement pointerEvents="none">
            <Search2Icon color="gray.400" />
          </InputLeftElement>
          <Input
            type="search"
            placeholder={isMobile ? 'Search' : 'Search Announcement by title...'}
            onChange={changeName}
            border="none"
            defaultValue={initialFilter.title ? initialFilter.title.toString() : ''}
          />
        </InputGroup>
        <Button variant="outline" leftIcon="filter.svg" onClick={openFilterModal}>
          {!isMobile && 'Filter'}
        </Button>
      </HStack>
      <HStack>
        <Button leftIcon="add-white.svg" variant="solid" onClick={openCreateModal}>
          {!isMobile && 'Create Announcement'}
        </Button>
      </HStack>
    </Flex>
  )
}
export default HeaderSection
