import React, { Component } from 'react'
import Layout from 'components/Layout'
import ThemeSwitcher from 'react-css-vars'
import { Route, Switch, withRouter, RouteComponentProps } from 'react-router-dom'
import Smartlook from 'smartlook-client'
import { ERoleOfUser } from 'constants/enum'
import { AppConfig } from 'constants/index'
import { frontendRoutes } from 'constants/routes'
import ForgotPassword from 'containers/ForgotPassword'
import ResetPassword from 'containers/ResetPassword'
import { checkAccessFE } from 'utils/commonUtils'
import { firebaseCloudMessaging } from 'utils/fcm'
import Login from '../Login'
import NotFound from '../NotFound'
import Routes from '../routes/index'
import ShareCV from '../ShareCV'

interface AppState {
  notifications: any[]
  unReadFeedbackNumber: number | undefined
}

interface AppProps extends RouteComponentProps {}

const userTheme = {
  primary: '#1A365D',
  active: '#3F5676',
  hover: '#2C466A',
}

const adminTheme = {
  primary: '#2D3748',
  active: '#4F5765',
  hover: '#3D4756',
}

class App extends Component<AppProps, AppState> {
  private eventInterval: any

  constructor(props: AppProps) {
    super(props)
    this.state = {
      notifications: [],
      unReadFeedbackNumber: undefined,
    }
  }

  async componentDidMount() {
    const isNotificationSupported = 'Notification' in window
    //*INFO: Implement in the future
    // this.subscribeNotificationHandler()
    // this.setIntervalCallSubscriptionNotification()
    isNotificationSupported && (await Notification.requestPermission())
    const firebase = firebaseCloudMessaging.getFirebase()
    if (firebase.messaging.isSupported()) {
      firebase.messaging().onMessage((payload: any) => {
        const title = payload.notification.title
        const icon = payload.notification.icon
        const body = payload.notification.body
        const options = {
          icon,
          body,
        }
        isNotificationSupported && new Notification(title, options)
      })
      window.addEventListener('beforeunload', this.handleRefresh)
    }

    Smartlook.init(AppConfig.SMART_LOOK_KEY)
  }

  async componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleRefresh)
  }

  handleRefresh() {
    sessionStorage.clear()
  }
  //*INFO: Implement in the future
  // setIntervalCallSubscriptionNotification = () => {
  //   this.eventInterval = setInterval(
  //     function (self: App) {
  //       self.subscribeNotificationHandler()
  //     },
  //     AppConfig.TIME_OUT_LONGPOLLING,
  //     this
  //   )
  // }

  // subscribeNotificationHandler = async () => {
  //   const message = await subscribeNotificationAPI()
  //   if (message === Messages.longPollingSuccess) {
  //     this.getNotificationsList(true)
  //     if (this.eventInterval) {
  //       clearInterval(this.eventInterval)
  //       this.setIntervalCallSubscriptionNotification()
  //     }
  //   }
  // }

  // getNotificationsList = async (socket: boolean) => {
  //   const response = await getListAPI()
  //   this.setState({
  //     notifications: response?.data?.notificationList,
  //   })
  // }

  isLoginRequired(pathname: string) {
    const routes = [
      frontendRoutes.forgotPasswordPage.value,
      frontendRoutes.resetPasswordPage.value,
      frontendRoutes.loginPage.value,
      frontendRoutes.shareCVPage.value,
    ]
    return routes.every((route) => !pathname.includes(route))
  }

  render() {
    const { history } = this.props
    const { notifications, unReadFeedbackNumber } = this.state
    window.localStorage.removeItem('pageTitle')
    window.localStorage.removeItem('subPageTitle')
    const isRememberMe = JSON.parse(window.localStorage.getItem('isRememberMe'))
    const aToken = isRememberMe ? window.localStorage.getItem('aTokenV2') : window.sessionStorage.getItem('aTokenV2')
    const rToken = isRememberMe ? window.localStorage.getItem('rTokenV2') : window.sessionStorage.getItem('rTokenV2')
    const role = window.localStorage.getItem('userRole')
    const { pathname, search } = window.location
    const isLoggedIn = checkAccessFE(aToken, rToken)
    if (isLoggedIn) {
      if (pathname === '/' || pathname === frontendRoutes.loginPage.value) {
        if (!pathname.includes(frontendRoutes.adminDashboardPage.value) && role === ERoleOfUser.ADMIN) {
          history.replace(frontendRoutes.adminDashboardPage.statistic.value)
        } else if (!pathname.includes(frontendRoutes.timesheetPage.board.value) && role === ERoleOfUser.USER) {
          history.replace(frontendRoutes.timesheetPage.board.value)
        }
      }
    } else {
      window.localStorage.clear()
      if (this.isLoginRequired(pathname)) {
        if (search) {
          history.replace({ pathname: frontendRoutes.loginPage.value, state: { from: pathname, search: search } })
        } else {
          history.replace(frontendRoutes.loginPage.value)
        }
      }
    }
    return (
      <ThemeSwitcher theme={role === ERoleOfUser.ADMIN ? adminTheme : userTheme}>
        <Switch>
          <Route exact path={frontendRoutes.loginPage.value} component={Login} />
          <Route exact path={frontendRoutes.forgotPasswordPage.value} component={ForgotPassword} />
          <Route path={frontendRoutes.resetPasswordPage.resetPasswordToken.value} component={ResetPassword} />
          <Route exact path={frontendRoutes.shareCVPage.fileId_userId_sharedCode.value} component={ShareCV} />
          {/* INFO: Use when update notification */}
          {/* {RoutesAuth(notifications, unReadFeedbackNumber, () => this.getNotificationsList(false))} */}
          {RoutesAuth(notifications, unReadFeedbackNumber, () => {})}
          <Route component={NotFound} />
        </Switch>
      </ThemeSwitcher>
    )
  }
}

const RoutesAuth = (
  notifications: any[],
  unReadFeedbackNumber: number | undefined,
  getNotificationHandler: () => void
) => {
  const role = window.localStorage.getItem('userRole')
  window.sessionStorage.removeItem('subPageTitle')

  return (
    role &&
    Routes[role] &&
    Routes[role].map((item) => (
      <Route
        exact={item.exact}
        key={item.key}
        path={item.path}
        render={(props) => (
          <Layout
            component={item.component}
            title={item.title}
            getNotificationsList={getNotificationHandler}
            notifications={notifications}
            unReadFeedbackNumber={unReadFeedbackNumber}
            {...props}
          />
        )}
      />
    ))
  )
}

export default withRouter(App)
