import upperCase from 'lodash/upperCase'
import { EAbbreviatedContractTypesVI, EContractTypes, EGenderVI, EUserEducationVI } from 'constants/enum'
import { IUser, IContract } from 'constants/schema'
import { getFormattedDateWith_YYYYMMDD } from 'utils/dateFormatUtils'
import { IContractForm } from './constants'

export function generateFormData(user: IUser, currentFormData: IContract): IContractForm {
  const formData: IContractForm = {
    ...currentFormData,
    IDIssueDate: getFormattedDateWith_YYYYMMDD(user?.IDIssueDate),
    IDNumber: user?.IDNumber,
    dateOfBirth: getFormattedDateWith_YYYYMMDD(user?.dateOfBirth),
    fullNameVI: user?.fullName,
    genderVI: { label: EGenderVI[upperCase(user?.gender)], value: EGenderVI[upperCase(user?.gender)] },
    nationalityVI: user?.nationality,
    addressVI: user?.address,
    placeOfBirthVI: user?.placeOfBirth,
    IDIssuePlaceVI: { label: user?.IDIssuePlace, value: user?.IDIssuePlace },
    educationLevelVI: EUserEducationVI[upperCase(user?.education)] ?? '',
    majorVI: user?.major,
  }
  return formData
}

export function formatContractType(type: EContractTypes): EAbbreviatedContractTypesVI | null {
  if (type === EContractTypes.LABOR_CONTRACT) {
    return EAbbreviatedContractTypesVI.LABOR_CONTRACT
  }
  if (type === EContractTypes.FREELANCER_CONTRACT) {
    return EAbbreviatedContractTypesVI.FREELANCER_CONTRACT
  }
  if (type === EContractTypes.CONFIDENTIALITY_AGREEMENT) {
    return EAbbreviatedContractTypesVI.CONFIDENTIALITY_AGREEMENT
  }
  return null
}
