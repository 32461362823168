import { EUploadedImageType } from 'constants/enum'
import { AppConfig } from 'constants/index'

export function generateImageURL(url: string, type: string) {
  if (url !== 'undefined' && url !== undefined && url !== 'null' && url !== null) {
    if (AppConfig.API_URL_V2.includes('localhost')) {
      return `${AppConfig.IMAGES_STORAGE_URL}/${url}`
    }
    return `/images/${url}`
  }
  switch (type) {
    case EUploadedImageType.LOGO:
      return '/assets/images/no-image-available.jpg'
    case EUploadedImageType.AVATAR:
      return '/assets/images/no-image-avatar.png'
    case EUploadedImageType.BADGE_IMAGE:
      return '/assets/images/no-image-available.jpg'
    default:
      return ''
  }
}

export function getDefaultImageURL(type: string) {
  switch (type) {
    case EUploadedImageType.LOGO:
      return '/assets/images/no-image-available.jpg'
    case EUploadedImageType.AVATAR:
      return '/assets/images/no-image-avatar.png'
    case EUploadedImageType.BADGE_IMAGE:
      return '/assets/images/no-image-badge.png'
    default:
      return '/assets/images/no-image-available.jpg'
  }
}

export function generateTimeSheetImageURL(fileName: string) {
  if (fileName !== 'undefined' && fileName !== undefined) {
    if (AppConfig.API_URL_V2.includes('localhost')) {
      return `${AppConfig.TIMESHEET_IMAGES_STORAGE_URL}/${fileName}`
    }
    return `/timesheet-images/${fileName}`
  }
  return '/assets/images/no-image-available.jpg'
}
