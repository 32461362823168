import { MutableRefObject, useEffect, useRef, useState } from 'react'
import { Button, HStack, Menu, MenuButton, MenuItemOption, MenuList } from '@chakra-ui/react'
import { Calendar as CalendarAPI } from '@fullcalendar/core'
import { getErrorMessage } from 'API/error'
import Calendar from 'components/Calendar'
import {
  ECalendarViewType,
  ICalendarClickEvent,
  ICalendarSelectInfo,
  ICalendarTimeData,
} from 'components/Calendar/constants'
import ConfirmYesNoDeleteModal from 'components/ConfirmYesNoDeleteModal'
import dayjs from 'dayjs'
import isEmpty from 'lodash/isEmpty'
import { observer } from 'mobx-react'
import { toast } from 'react-toastify'
import { useStores } from 'utils/hooks/useStores'
import { ICalendarSectionProps } from './interface'
import { ICalendarEvent } from './types'
import { generateEvents } from './utils'

const CalendarSection = ({ calendarRef, handleOpenModal }: ICalendarSectionProps) => {
  const { userTimesheetStore } = useStores()
  const { timesheetList, projects, groups } = userTimesheetStore
  const [events, setEvents] = useState<ICalendarEvent[]>([])
  const [currentInfo, setCurrentInfo] = useState<ICalendarClickEvent>()
  const [isMounted, setIsMounted] = useState<boolean>(false)
  const [isOpenDeleteConfirmDialog, setIsOpenDeleteConfirmDialog] = useState<boolean>(false)
  const menuButtonRef: MutableRefObject<undefined> = useRef()
  const calendarApi: CalendarAPI = calendarRef.current?.getApi()
  const viewType: string = calendarApi?.view.type

  function handleClickEvent(info: ICalendarClickEvent): void {
    setCurrentInfo(info)
    const current: HTMLElement = menuButtonRef?.current as HTMLElement
    current.click()
  }

  async function handleClickEditTimesheet(): Promise<void> {
    const timesheetId: string = currentInfo?.event?.id
    await userTimesheetStore.fetchTimesheetDetail(timesheetId)
    handleOpenModal()
  }

  async function handleClickDuplicateTimesheet(): Promise<void> {
    const timesheetId: string = currentInfo?.event?.id

    await userTimesheetStore.fetchTimesheetDetail(timesheetId)
    userTimesheetStore.setIsDuplicate(true)
    handleOpenModal()
  }

  function closeDeleteConfirmDialog(): void {
    setIsOpenDeleteConfirmDialog(false)
  }

  function openDeleteConfirmModalHanlder(): void {
    setIsOpenDeleteConfirmDialog(true)
  }

  async function handDeleteTimesheet(id: string): Promise<void> {
    try {
      await userTimesheetStore.deleteTimesheetV2(id)
      userTimesheetStore.resetTimesheetDetail()
      userTimesheetStore.setIsDuplicate(false)
      setIsOpenDeleteConfirmDialog(false)
    } catch (error) {
      const errorMessage: string = getErrorMessage(error)
      toast.error(errorMessage)
    }
  }

  function handleSelectTimeRange(selectInfo: ICalendarSelectInfo): void {
    const currentTimeData: ICalendarTimeData = {
      start: selectInfo?.start,
      end: selectInfo?.end,
    }

    if (viewType === ECalendarViewType.DAY_GRID_MONTH) {
      const defaultStartTime: Date = dayjs(selectInfo?.start).set('hour', 9).set('minute', 0).toDate()
      const defaultEndTime: Date = dayjs(defaultStartTime).add(1, 'hour').toDate()
      currentTimeData.start = defaultStartTime
      currentTimeData.end = defaultEndTime
    }

    userTimesheetStore.setCalendarTimeData(currentTimeData)
    handleOpenModal()
  }

  useEffect(() => {
    const currentEvents: ICalendarEvent[] = generateEvents(timesheetList)

    setEvents(currentEvents)
  }, [timesheetList])

  useEffect(() => {
    if (!isEmpty(projects) && !isMounted) {
      const nowIndicatorElement: Element = document.getElementsByClassName('fc-now-indicator-arrow ').item(0)
      nowIndicatorElement.scrollIntoView()
      setIsMounted(true)
    }
  }, [projects])

  return (
    <HStack width={'full'} marginTop={6}>
      <Calendar
        calendarRef={calendarRef}
        events={events}
        editable={false}
        selectable={groups && groups[0]?.isActive}
        // *INFO: implement select time range for rest views later
        handleClickEvent={handleClickEvent}
        handleSelect={handleSelectTimeRange}
      />
      <Menu colorScheme="white" closeOnSelect>
        <MenuButton
          ref={menuButtonRef}
          as={Button}
          colorScheme="blue"
          position="absolute"
          opacity={0}
          top={currentInfo?.jsEvent.pageY - 20}
          // *INFO: 260px is width of side bar
          left={currentInfo?.jsEvent.pageX - 260}
          zIndex={99}
          height={1}>
          Active menu
        </MenuButton>
        <MenuList zIndex={99} minW={140}>
          <MenuItemOption onClick={handleClickEditTimesheet} background="white" cursor="pointer">
            Edit
          </MenuItemOption>
          <MenuItemOption background="white" onClick={handleClickDuplicateTimesheet} cursor="pointer">
            Duplicate
          </MenuItemOption>
          <MenuItemOption color="red.500" background="white" onClick={openDeleteConfirmModalHanlder} cursor="pointer">
            Delete
          </MenuItemOption>
        </MenuList>
      </Menu>
      <ConfirmYesNoDeleteModal
        isOpen={isOpenDeleteConfirmDialog}
        title="timesheet"
        closeHandler={closeDeleteConfirmDialog}
        OKClickHandler={handDeleteTimesheet}
        itemId={currentInfo?.event?.id}
      />
    </HStack>
  )
}

export default observer(CalendarSection)
