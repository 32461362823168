import React from 'react'
import { SimpleGrid, Text } from '@chakra-ui/react'
import FormInput from 'components/FormInput'
import GroupRadio from 'components/GroupRadio'
import SingleSelect from 'components/SingleSelect'
import { TypeOfContract, YesNoOption } from 'constants/defaultOptionList'
import { EContractTypes, EYesNoOption } from 'constants/enum'
import { IPartner } from 'constants/schema'
import { createOptionsOfSelect, createOptionsOfReactSelectFromDB } from 'utils/dropdownOptionUtils'
import styles from '../../../contract.module.scss'
import { formatContractType } from '../../utils'

export interface ICommonInformationProps {
  partnerList: IPartner[]
  isLaborContractDefiniteTerm: boolean
  contractType?: EContractTypes
}

const CommonInformation = (props: ICommonInformationProps) => {
  const { partnerList, isLaborContractDefiniteTerm, contractType } = props
  const partnersOptions = createOptionsOfReactSelectFromDB(partnerList)
  const contractTypesOptions = createOptionsOfSelect(TypeOfContract)

  return (
    <React.Fragment>
      <Text alignSelf={'flex-start'} className={styles.headerBox}>
        Common Information
      </Text>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
        <SingleSelect
          name="partnerId"
          label="Belongs To Partner"
          optionsData={partnersOptions}
          placeHolder={'- Select Partner -'}
          isRequire
        />
        <FormInput name="fullNameVI" label="Full Name" isRequired />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
        <SingleSelect
          name="type"
          label="Contract Type"
          optionsData={contractTypesOptions}
          placeHolder={'- Select Project Type -'}
          isRequire
        />
        <FormInput
          name="belongsContractNumber"
          label="Belongs Contract Number"
          helperText="With Confidentiality Agreement. Please enter <Number> or <First number, Second number> | Ex: 54/2024/HDDV-UDT or 53/2024/HDLD-UDT"
        />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
        <FormInput name="number" label="Contract Number" isRequired rightAddonText={formatContractType(contractType)} />
        <FormInput name="publishedDate" label="Published Date" type="date" isRequired />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
        <FormInput name="IDIssueDate" label="ID Issue Date (optional)" type="date" />
        <FormInput name="IDNumber" label="ID Number (optional)" />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
        <FormInput name="workingFromDate" label="Working From Date (optional)" type="date" />
        <FormInput
          name="workingEndDate"
          label={`Working End Date ${isLaborContractDefiniteTerm ? '' : '(optional)'}`}
          type="date"
          isRequired={isLaborContractDefiniteTerm}
        />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
        <FormInput name="dateOfBirth" label="Birth Date (optional)" type="date" />
        <GroupRadio
          name={'isCreateInternalDoc'}
          data={YesNoOption}
          label="Create Internal Document?"
          isEdit={false}
          defaultValue={EYesNoOption.YES}
        />
      </SimpleGrid>
    </React.Fragment>
  )
}

export default CommonInformation
