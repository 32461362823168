import { get } from 'lodash'
import { ISkill } from 'types/user'
export function getSkillColor(currentValue: number, previousValue: number) {
  if (currentValue > previousValue) return { bgColor: 'green.50', color: 'green.500', borderColor: 'green.500' }
  if (currentValue < previousValue) return { bgColor: 'red.50', color: 'red.500', borderColor: 'red.500' }
  return { bgColor: 'transparent', color: 'gray.500', borderColor: 'gray.400' }
}
export function getDisplaySkillName(skill: string) {
  const displaySkillNames = {
    workingAttitude: 'Working attitude',
    meetDeadlines: 'Meet deadlines',
    problemSolving: 'Problem solving',
    communication: 'Communication',
    teamPlayer: 'Team player',
    technicalSkills: 'Technical skills',
    english: 'English',
  }
  return displaySkillNames[skill] || skill
}

export function getPreviousValue(index: number, historyData: ISkill[], skill: string, value: number) {
  if (index < historyData.length - 1) {
    return get(historyData[index + 1], skill)
  }
  return value
}
