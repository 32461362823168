import apiV2 from 'API/api'
import { APIRouters } from 'API/APIRouters'
import { ISuccessResponse } from 'API/constants'
import { AxiosResponse } from 'axios'
import { IPartner, IUser } from 'constants/schema'
import {
  IPartnerListRequest,
  IPartnerListResponse,
  ICreatePartnerResponse,
  IPartnerDetailResponse,
  IUpdatePartnerResponse,
  IPartnerNameListResponse,
  IPartnerListWithPaginationResponse,
  IPartnerListWithPaginationRequest,
} from './constants'

export async function getPartnersAPI(payload: IPartnerListRequest): Promise<ISuccessResponse<IPartnerListResponse>> {
  try {
    const url = APIRouters.partner.listWithFilter.value
    return apiV2.post(url, payload)
  } catch (error) {
    throw error
  }
}

export async function getAllNamePartnerListOfAdminAPI(
  condition: string
): Promise<ISuccessResponse<IPartnerNameListResponse>> {
  try {
    const url = APIRouters.partner.nameListForAdmin.condition.value(condition)
    return apiV2.get(url)
  } catch (error) {
    throw error
  }
}

export async function getPartnerListWithPaginationAPI(
  payload: IPartnerListWithPaginationRequest
): Promise<ISuccessResponse<IPartnerListWithPaginationResponse>> {
  try {
    const url: string = APIRouters.partner.list.value
    return apiV2.post(url, payload)
  } catch (error) {
    throw error
  }
}

export async function getPartnerDetailAPI(partnerId: string): Promise<IPartnerDetailResponse> {
  try {
    const url = APIRouters.partner.detail.partnerId.value(partnerId)
    const response: AxiosResponse = await apiV2.get(url)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function updatePartnerAPI(payload: Partial<IPartner>, partnerId: string): Promise<IUpdatePartnerResponse> {
  try {
    const url = APIRouters.partner.update.partnerId.value(partnerId)
    const response: AxiosResponse = await apiV2.post(url, payload)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function createPartnerAPI(payload: Partial<IPartner>): Promise<ICreatePartnerResponse> {
  try {
    const url = APIRouters.partner.create.value
    const response: AxiosResponse = await apiV2.post(url, payload)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function getUserBelongToPartner(partnerId: string): Promise<IUser> {
  try {
    const url = APIRouters.partner.getUserData.partnerId.value(partnerId)
    const response: AxiosResponse = await apiV2.get(url)
    return response.data
  } catch (error) {
    throw error
  }
}
