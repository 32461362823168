import { ISuccessResponse } from 'API/constants'
import { handleError } from 'API/error'
import { getAllNameUserLisWithAGivenTitleAPI } from 'API/user'
import { IUserNameListResponse, IUserNameResponse } from 'API/user/constants'
import { makeAutoObservable } from 'mobx'
import RootStore from 'store/rootStore'
import { EUserTitle } from 'constants/user'

class UserHumanResourcesStore {
  rootStore: RootStore
  userNameListWithAGivenTitle: IUserNameResponse[] = []
  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public async getHRUserNameList(condition: string) {
    try {
      const response: ISuccessResponse<IUserNameListResponse> = await getAllNameUserLisWithAGivenTitleAPI(
        EUserTitle.HR,
        condition
      )
      this.userNameListWithAGivenTitle = response.data.users
    } catch (error) {
      handleError(error, 'src/store/user/humanResourcesStore.ts', 'getHRUsers')
    }
  }
}

export default UserHumanResourcesStore
