import { ISuccessResponse } from 'API/constants'
import { handleError } from 'API/error'
import {
  getProjectDetailOfUserV2API,
  getAllNameProjectListOfUserAPI,
  getProjectListOfUserWithPaginationAPI,
  getNameListForSupervisorAPI,
} from 'API/project'
import {
  IProjectDetailOfUserResponse,
  IProjectNameListResponse,
  IProjectNameResponse,
  IProjectFilter,
  IProjectListWithPaginationResponse,
} from 'API/project/constants'
import get from 'lodash/get'
import { makeAutoObservable } from 'mobx'
import { THistory } from 'types/common'
import { IProject } from 'constants/schema'
import { getParsedProjectValueBeforeRenderFE } from 'utils/formatDataUtils'
import RootStore from '../rootStore'

class UserProjectStore {
  rootStore: RootStore
  currentPage: number = 1
  count: number = 0
  projectsList: IProject[] = []
  projectNameList: IProjectNameResponse[] = []
  projectNameListForSupervisor: IProjectNameResponse[] = []
  isProjectListLoading: boolean = false
  projectDetail

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public async getProjectList(isReset?: boolean, history?: THistory, filter?: IProjectFilter): Promise<void> {
    try {
      this.isProjectListLoading = true
      const currentPage: number = isReset ? 1 : Number(get(history, 'location.state.page') || 1)
      const response: ISuccessResponse<IProjectListWithPaginationResponse> =
        await getProjectListOfUserWithPaginationAPI({
          currentPage,
          perPage: 8,
          filter,
        })
      this.projectsList = response?.data?.projects
      this.count = response?.data?.count
    } catch (error) {
      handleError(error, 'src/store/admin/projectStore.ts', 'getProjectList')
    } finally {
      this.isProjectListLoading = false
    }
  }

  public async getProjectDetailOfUser(projectId: string): Promise<void> {
    try {
      const response: ISuccessResponse<IProjectDetailOfUserResponse> = await getProjectDetailOfUserV2API(projectId)
      const currentProjectData: IProject = response?.data?.project
      const parsedProjectValue = getParsedProjectValueBeforeRenderFE(currentProjectData, false)
      this.projectDetail = parsedProjectValue
    } catch (error) {
      handleError(error, 'src/store/user/projectStore.ts', 'getProjectDetailOfUser')
    }
  }
  public async getAllNameProjectList(condition: string): Promise<void> {
    try {
      const response: ISuccessResponse<IProjectNameListResponse> = await getAllNameProjectListOfUserAPI(condition)
      this.projectNameList = response?.data?.projects
    } catch (error) {
      handleError(error, 'src/store/admin/projectStore.ts', 'getAllNameProjectList')
    }
  }

  public async getNameProjectListForSupervisor(condition: string): Promise<void> {
    try {
      const response: ISuccessResponse<IProjectNameListResponse> = await getNameListForSupervisorAPI(condition)
      this.projectNameListForSupervisor = response?.data?.projects
    } catch (error) {
      handleError(error, 'src/store/admin/projectStore.ts', 'getNameProjectListForSupervisor')
    }
  }
}

export default UserProjectStore
