import { FunctionComponent } from 'react'
import { Icon } from '@chakra-ui/react'
import { Tooltip } from '@chakra-ui/tooltip'

export interface IQuickAccessItemProps {
  label?: string
  icon: FunctionComponent
  onClick?: () => void
}

const QuickAccessItem = (props: IQuickAccessItemProps) => {
  const { label = '', icon, onClick } = props

  return (
    <>
      <Tooltip label={label} placement="left" hasArrow offset={[0, 10]}>
        <Icon as={icon} w="48px" h="48px" onClick={onClick} cursor="pointer" borderRadius="50%" />
      </Tooltip>
    </>
  )
}

export default QuickAccessItem
