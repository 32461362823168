import { ITableHeader } from 'types/table'
import { IPartner } from 'constants/schema'

export interface Props {
  history: any
  match: any
  handleCloseConfirmDialog?: () => void
  handleOpenSnackbar?: ({ variant, message }) => void
}

export interface State {
  partners: unknown[]
  count: number
  filter?: {
    category: { label: string; value: string }[]
    fullName: any
  }
  open?: boolean
  exportNow?: boolean
  openDeleteModal?: boolean
  openArchiveModal?: boolean
  selectedPartner?: IPartner
  isCorrectName?: boolean
  isLoading?: boolean
}

export const headerDesktop: ITableHeader[] = [
  {
    Header: 'Number',
    accessor: 'number',
  },
  {
    Header: 'Full Name',
    accessor: 'fullName',
  },
  {
    Header: 'Partner Type',
    accessor: 'type',
  },
  {
    Header: 'Category',
    accessor: 'category',
  },
  {
    Header: 'Address',
    accessor: 'address',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: '',
    accessor: 'actions',
  },
]

export const headerTabletAndMobile: ITableHeader[] = [
  {
    Header: 'Full Name',
    accessor: 'fullName',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: '',
    accessor: 'actions',
  },
]

export function getHeaderList(isMobile: boolean): ITableHeader[] {
  return isMobile ? headerTabletAndMobile : headerDesktop
}
