import { useState } from 'react'
import { CloseIcon } from '@chakra-ui/icons'
import { Tag } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import { ISingleTagProps } from './interfaces'

const SingleTag = (props: ISingleTagProps) => {
  const { fieldName } = props
  const { setValue, watch } = useFormContext()
  const [isHovered, setIsHovered] = useState(false)

  function handleRemoveTag(): void {
    setValue(fieldName, null)
  }

  const data = watch(fieldName)
  if (!data?.label) return null

  return (
    <Tag
      width="fit-content"
      border="1px solid"
      borderColor={isHovered ? 'gray.500' : 'transparent'}
      backgroundColor="transparent"
      paddingX={3}
      paddingY={1}
      marginRight={4}
      marginBottom={2}
      borderRadius="6px"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      _hover={{ borderColor: 'gray.500' }}>
      {data?.label}
      <CloseIcon
        marginLeft={2}
        width={2}
        height={2}
        cursor="pointer"
        onClick={handleRemoveTag}
        color={isHovered ? 'gray.500' : 'transparent'}
      />
    </Tag>
  )
}

export default SingleTag
