import { set } from 'lodash'
import { generatePath } from 'react-router'
import { IUser, IUserFilter } from 'types/user'
import { frontendRoutes } from 'constants/routes'
import { getValueFromOption } from 'utils/dropdownOptionUtils'

export function generateUserPath(user: IUser, route: string) {
  const id = user?._id ?? ''

  switch (route) {
    case frontendRoutes.userPage.detail.id.value:
      return generatePath(route, { id }).concat('?isShowQuickAccessBubble=true')
    case frontendRoutes.userDashboardPage.detail.id.value:
      return generatePath(route, { id })
    default:
      return ''
  }
}

export function formatFilterData(data: IUserFilter) {
  const isActive: boolean = data.isActive
  const isArchived: boolean = data.isArchived
  const isDeleted: boolean = data.isDeleted
  const normalizedFullName: string = data.normalizedFullName
  const role: string = data.role
  const titleId: string[] = getValueFromOption(data.titleId)
  const levelId: string[] = getValueFromOption(data.levelId)
  const paymentCategoryId: string[] = getValueFromOption(data.paymentCategoryId)
  const technologies: string[] = getValueFromOption(data.technologies)

  const formattedFilter = {
    isActive,
    isArchived,
    isDeleted,
    titleId,
    levelId,
    paymentCategoryId,
    technologies,
  }
  role !== 'all' && set(formattedFilter, 'role', role)
  normalizedFullName && set(formattedFilter, 'normalizedFullName', normalizedFullName)
  return formattedFilter
}
