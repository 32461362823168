import { useEffect, useState } from 'react'
import { Search2Icon } from '@chakra-ui/icons'
import { VStack, HStack, Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import { ICreatableOption, ICreatableOptionDataFromCreateForm } from 'API/creatableOption/constants'
import Button from 'components/Button'
import ConfirmModal from 'components/ConfirmModal'
import { EModalType } from 'components/ConfirmModal/enums'
import Table from 'components/Table'
import OptionButtonsWithoutDropBox from 'components/Table/DesktopTable/components/OptionButtonsWithoutDropBox'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { backgroundGrey200 } from 'theme/globalStyles'
import { EActionType, ECreatableOptionTypes, EUploadBadgeImageModalType } from 'constants/enum'
import { frontendRoutes } from 'constants/routes'
import { getValidArray } from 'utils/commonUtils'
import { useStores } from 'utils/hooks/useStores'
import UploadBadgeImageModal from '../components/UploadBadgeImageModal'
import { getTitleHeaderList } from '../constants'
import styles from '../globalConfig.module.scss'

const Title = () => {
  const { adminCreatableOptionStore } = useStores()
  const history = useHistory()
  const { listCount, titleList, isRefresh, isLoadingCreatableOptionList } = adminCreatableOptionStore
  const [isOpenCreateModal, setIsOpenCreateModal] = useState<boolean>(false)
  const [isOpenConfirmDeleteModal, setIsOpenConfirmDeleteModal] = useState<boolean>(false)
  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false)
  const [filter, setFilter] = useState({ type: ECreatableOptionTypes.TITLE_OF_USER, isDeleted: false })
  const [selectedCreatableOption, setSelectedCreatableOption] = useState<ICreatableOption>()

  useEffect(() => {
    adminCreatableOptionStore.getCreatableOptionListWithPagination(true, history, filter)
  }, [filter, isRefresh])
  function openCreateModal(): void {
    setIsOpenCreateModal(true)
  }
  function closeCreateModal(): void {
    setIsOpenCreateModal(false)
    setSelectedCreatableOption(null)
  }
  function openConfirmDeleteModal(creatableOption: ICreatableOption): void {
    setSelectedCreatableOption(creatableOption)
    setIsOpenConfirmDeleteModal(true)
  }
  function closeConfirmDeleteModal(): void {
    setSelectedCreatableOption(null)
    setIsOpenConfirmDeleteModal(false)
  }
  function closeEditModal(): void {
    setSelectedCreatableOption(null)
    setIsOpenEditModal(false)
  }
  function openEditModal(creatableOption: ICreatableOption): void {
    setSelectedCreatableOption(creatableOption)
    setIsOpenEditModal(true)
  }
  function createNewCreatableOptionHandler(item: ICreatableOptionDataFromCreateForm): void {
    const itemData = {
      value: item.value,
      type: ECreatableOptionTypes.TITLE_OF_USER,
      badgeImage: item.badgeImage,
    }
    adminCreatableOptionStore.createCreatableOption(itemData)
    setSelectedCreatableOption(null)
    closeCreateModal()
    pagination.gotoPage(1)
  }
  function deleteCreatableOptionHandler(id: string): void {
    adminCreatableOptionStore.deleteCreatableOption(id, ECreatableOptionTypes.TITLE_OF_USER)
    closeConfirmDeleteModal()
    pagination.gotoPage(1)
  }
  function editCreatableOptionHandler(item: ICreatableOption): void {
    const itemData = {
      id: item.id,
      value: item.value,
      badgeImage: item.badgeImage,
      scope: selectedCreatableOption?.scope,
      type: ECreatableOptionTypes.TITLE_OF_USER,
    }
    adminCreatableOptionStore.updateCreatableOption(itemData)
    closeEditModal()
    pagination.gotoPage(1)
  }

  const tableData = getValidArray(titleList).map((creatableOption) => {
    return {
      ...creatableOption,
      actions: (
        <OptionButtonsWithoutDropBox
          isEdit={true}
          isDelete={true}
          editActionHandler={() => openEditModal(creatableOption)}
          deleteActionHandler={() => openConfirmDeleteModal(creatableOption)}
        />
      ),
    }
  })
  const pageIndex: number = Number(get(history, 'location.state.page', 1)) || 1
  const pagination = {
    includePagination: true,
    pageIndex,
    pageSize: 10,
    tableLength: listCount,
    gotoPage: (page: number) => {
      history.push({ pathname: frontendRoutes.settingPage.globalConfig.value, state: { page, filter } })
      adminCreatableOptionStore.getCreatableOptionListWithPagination(false, history, filter)
    },
  }

  const searchHandler = debounce((event) => {
    setFilter({
      ...filter,
      value: { $regex: event?.target?.value ?? '', $options: 'i' },
      type: ECreatableOptionTypes.TITLE_OF_USER,
    })
  }, 1000)

  return (
    <VStack width="full">
      <HStack marginBottom={6} width={{ base: '100%', md: '100%' }} justifyContent={'space-between'}>
        <InputGroup
          border={`1px solid ${backgroundGrey200}`}
          borderRadius="6px"
          width={{ base: '100%', lg: '540px' }}
          background="white">
          <InputLeftElement pointerEvents="none">
            <Search2Icon color="gray.400" />
          </InputLeftElement>
          <Input type="search" placeholder="Search Title by name..." onChange={searchHandler} />
        </InputGroup>
        <Button variant="solid" leftIcon="add-white.svg" onClick={openCreateModal}>
          Create
        </Button>
      </HStack>
      <Table
        className={styles.fullWidth}
        headerList={getTitleHeaderList()}
        isLoading={isLoadingCreatableOptionList}
        tableData={tableData}
        isStriped
        pagination={pagination}
        isShowPagination={true}
      />
      <UploadBadgeImageModal
        actionType={EActionType.CREATE}
        isOpen={isOpenCreateModal}
        closeHandler={closeCreateModal}
        saveHandler={createNewCreatableOptionHandler}
        data={selectedCreatableOption}
        isSubmitSuccess={isRefresh}
        type={EUploadBadgeImageModalType.TITLE}
      />
      <UploadBadgeImageModal
        actionType={EActionType.EDIT}
        isOpen={isOpenEditModal}
        closeHandler={closeEditModal}
        saveHandler={editCreatableOptionHandler}
        data={selectedCreatableOption}
        isSubmitSuccess={isRefresh}
        type={EUploadBadgeImageModalType.TITLE}
      />
      <ConfirmModal
        data={selectedCreatableOption}
        isOpen={isOpenConfirmDeleteModal}
        closeHandler={closeConfirmDeleteModal}
        OKClickHandler={deleteCreatableOptionHandler}
        title={'Project Type'}
        content={'name'}
        type={EModalType.DELETE}
      />
    </VStack>
  )
}

export default observer(Title)
