import * as yup from 'yup'
import { HOUR_MINUTE_PATTERN } from 'constants/regexp'
import { Messages } from '../../../../../../constants'

export function getValidationSchema() {
  const schema = yup.object({
    isFixedPriceProject: yup.boolean(),
    taskName: yup.string().required(Messages.fieldRequired),
    startDate: yup.date().required(Messages.fieldRequired),
    endDate: yup.date().required(Messages.fieldRequired),
    timeTotal: yup.object().required(Messages.fieldRequired).nullable(true),
    startTime: yup.string().required(Messages.fieldRequired).matches(HOUR_MINUTE_PATTERN, Messages.inValidStartTimeV2),
    description: yup.string().required(Messages.fieldRequired),
    project: yup.object().required(Messages.fieldRequired),
    // TODO: Implement in the Future, when has fixed Price
    // remuneration: yup.string().when('isFixedPriceProject', {
    //   is: true,
    //   then: (schema) => schema.required(Messages.fieldRequired),
    // }),
  })

  return schema
}
