export const internalDocument = {
  value: '/internal-document',
  create: {
    value: '/internal-document/create',
  },
  list: {
    value: '/internal-document/list',
  },
  detail: {
    value: `/internal-document/detail`,
    internalDocumentId: {
      value: (internalDocumentId: string) => `/internal-document/detail/${internalDocumentId}`,
    },
  },
  update: {
    value: `/internal-document/update`,
    internalDocumentId: {
      value: (internalDocumentId: string) => `/internal-document/update/${internalDocumentId}`,
    },
  },
}
