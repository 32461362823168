import { useEffect } from 'react'
import {
  Drawer,
  DrawerOverlay,
  DrawerHeader,
  DrawerContent,
  DrawerFooter,
  DrawerBody,
  DrawerCloseButton,
  Box,
  HStack,
  VStack,
} from '@chakra-ui/react'
import Button from 'components/Button'
import GroupCheckBox from 'components/GroupCheckBox'
import queryString from 'query-string'
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form'
import { backgroundGrey200, textBlue500 } from 'theme/globalStyles'
import { IOption } from 'constants/common'
import { PartnerCategory, statusOptions, partnerTypes } from 'constants/defaultOptionList'
import { getArrayValueFromParsedQuery } from 'utils/commonUtils'
import { createOptionsOfSelect } from 'utils/dropdownOptionUtils'
import { IFilterProps, IPartnerFilter } from './interface'

const FilterForm = (props: IFilterProps) => {
  const { isDirty, isSubmitting, openModalFilterForm, filterSubmit, setOpenFilterForm, queryParamNow } = props
  const partnerCategoryOptions: IOption[] = createOptionsOfSelect(PartnerCategory)

  const methods: UseFormReturn = useForm()
  const { handleSubmit, reset } = methods

  const initialFilter = queryString.parse(queryParamNow, { parseBooleans: true })

  const selectedPartnerType = getArrayValueFromParsedQuery(initialFilter, 'type')
  const selectedPartnerCategory = getArrayValueFromParsedQuery(initialFilter, 'category')
  const selectedPartnerStatus = []

  function mapOptionWithChecked(option, selectedOptions) {
    return {
      ...option,
      checked: selectedOptions.includes(option.value),
    }
  }

  const selectedPartnerTypeOptions = partnerTypes.map(function (option) {
    return mapOptionWithChecked(option, selectedPartnerType)
  })

  const selectedPartnerCategoryOptions = partnerCategoryOptions.map(function (option) {
    return mapOptionWithChecked(option, selectedPartnerCategory)
  })

  const selectedPartnerStatusOptions = statusOptions.map(function (option) {
    return mapOptionWithChecked(option, selectedPartnerStatus)
  })

  function closeModal(): void {
    setOpenFilterForm(false)
  }

  useEffect(
    function setFilterEachTimeOpenForm() {
      if (openModalFilterForm) {
        var selectedPartnerStatus = getArrayValueFromParsedQuery(initialFilter, 'status')

        var isArchived = selectedPartnerStatus.includes('archived')
        var isDeleted = selectedPartnerStatus.includes('deleted')

        const mappedTypeOptions = selectedPartnerTypeOptions.map(function (option) {
          return mapOptionWithChecked(option, selectedPartnerType)
        })

        const mappedCategoryOptions = selectedPartnerCategoryOptions.map(function (option) {
          return mapOptionWithChecked(option, selectedPartnerCategory)
        })

        const mappedStatusOptions = selectedPartnerStatusOptions.map(function (option) {
          return mapOptionWithChecked(option, selectedPartnerStatus)
        })

        reset({
          type: mappedTypeOptions,
          category: mappedCategoryOptions,
          status: mappedStatusOptions,
          isArchived: isArchived,
          isDeleted: isDeleted,
        })
      }
    },
    [openModalFilterForm]
  )

  function resetForm() {
    function mapToUncheckedOption(option) {
      return {
        label: option.label,
        value: option.value,
        checked: false,
      }
    }

    var mappedTypeOptions = partnerTypes.map(mapToUncheckedOption)
    var mappedCategoryOptions = partnerCategoryOptions.map(mapToUncheckedOption)
    var mappedStatusOptions = statusOptions.map(mapToUncheckedOption)

    reset({
      type: mappedTypeOptions,
      category: mappedCategoryOptions,
      status: mappedStatusOptions,
      isArchived: false,
      isDeleted: false,
    })
  }

  function handleFormSubmit(data: IPartnerFilter) {
    function filterAndMapOptions(options) {
      return options
        ? options
            .filter(function (option) {
              return option.checked
            })
            .map(function (option) {
              return option.value
            })
        : []
    }

    const formattedData = {
      ...data,
      type: filterAndMapOptions(data.type),
      category: filterAndMapOptions(data.category),
      status: filterAndMapOptions(data.status),
    }

    formattedData.isArchived = formattedData.status.includes('archived')
    formattedData.isDeleted = formattedData.status.includes('deleted')

    filterSubmit(formattedData)
  }

  return (
    <HStack
      spacing={4}
      alignSelf="flex-end"
      marginBottom={6}
      display={{ base: 'none', md: 'flex' }}
      marginLeft="auto"
      marginRight={4}>
      <Drawer size="md" isOpen={openModalFilterForm} onClose={closeModal}>
        <DrawerOverlay />
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <DrawerContent>
              <DrawerCloseButton cursor="pointer" color="gray" onClick={closeModal} />
              <DrawerHeader borderBottom={`1px solid ${backgroundGrey200}`}>Filter</DrawerHeader>
              <DrawerBody paddingY={6}>
                <VStack spacing={4} alignItems="stretch">
                  <VStack alignItems="flex-start" spacing={4} width="full">
                    <GroupCheckBox name="type" label="Partner Type" data={partnerTypes} />
                  </VStack>
                  <VStack alignItems="flex-start" spacing={4} width="full">
                    <GroupCheckBox name="category" label="Partner Category" data={partnerCategoryOptions} />
                  </VStack>
                  <VStack alignItems="flex-start" spacing={4} width="full">
                    <GroupCheckBox name="status" label="Status" data={statusOptions} />
                  </VStack>
                </VStack>
              </DrawerBody>
              <DrawerFooter justifyContent="space-between" borderTop={`1px solid ${backgroundGrey200}`}>
                <Button variant="ghost" customStyles={{ color: textBlue500 }} onClick={resetForm}>
                  Reset
                </Button>
                <Box>
                  <Button variant="outline" customStyles={{ marginRight: '12px' }} onClick={closeModal}>
                    Cancel
                  </Button>
                  <Button type="submit" disabled={!isDirty && isSubmitting}>
                    Apply Filter
                  </Button>
                </Box>
              </DrawerFooter>
            </DrawerContent>
          </form>
        </FormProvider>
      </Drawer>
    </HStack>
  )
}

export default FilterForm
