export const taskType = {
  // Global Config Page - Task Type &
  // [Create Timesheet, Edit Timesheet - Task Type]
  value: '/task-type',
  list: {
    value: '/task-type/list',
  },
  taskTypeId: {
    value: (taskTypeId: string) => `/task-type/${taskTypeId}`,
  },
}
