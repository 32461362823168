import React, { useState, useEffect } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
} from '@chakra-ui/react'
import { ICreatableOption } from 'API/creatableOption/constants'
import Button from 'components/Button'
import UploadBadgeImage from 'components/UploadBadgeImage/UploadBadgeImageWithoutFormik'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import { toast } from 'react-toastify'
import { EUploadedImageType, EUploadBadgeImageModalType } from 'constants/enum'
import { getModalTitle } from 'utils/commonUtils'

interface IUploadBadgeImageModal {
  isOpen?: boolean
  content?: string
  closeHandler?: () => void
  actionType?: string
  saveHandler?: (item: object) => void
  data?: ICreatableOption
  isSubmitSuccess?: boolean
  type: EUploadBadgeImageModalType
}

const UploadBadgeImageModal = (props: IUploadBadgeImageModal) => {
  const { isOpen, closeHandler, saveHandler, actionType, data, isSubmitSuccess, type } = props
  const [valueField, setValueField] = useState<any>(null)
  const [badgeImage, setBadgeImage] = useState<any>(null)
  const [errors, setErrors] = useState<any>({})
  const [isValidBadgeImage, setIsValidBadgeImage] = useState<boolean>(true)

  useEffect(() => {
    if (data) {
      setValueField(data.value)
      setBadgeImage(data.badgeImage)
    } else {
      setValueField(null)
      setBadgeImage(null)
    }
    if (isSubmitSuccess) {
      setValueField(null)
      setBadgeImage(null)
    }
  }, [data, isSubmitSuccess])

  function valueInputOnChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const inputValue = event.target.value
    if (inputValue) {
      setErrors({
        ...errors,
        value: null,
      })
    }
    setValueField(inputValue)
  }

  function setBadgeImageValue(image: any): void {
    setBadgeImage(image)
  }

  function OKClickHandler(): void {
    let isValidForm = true
    const data = {
      value: valueField,
      id: get(props, 'data.id', ''),
      badgeImage: badgeImage,
    }
    let newErrors = {}
    if (!valueField) {
      isValidForm = false
      newErrors = {
        ...errors,
        value: {
          type: 'required',
          message: 'Level name is required',
        },
      }
    }
    setErrors(newErrors)
    if (!isValidBadgeImage) {
      isValidForm = false
      toast.error('Badge image size must be less than 100KB')
    }

    if (isValidForm) {
      saveHandler(data)
    }
  }

  let modalTitle = getModalTitle(actionType, type === EUploadBadgeImageModalType.LEVEL ? 'Level' : 'Title')

  return (
    <Modal isOpen={isOpen} onClose={closeHandler} isCentered>
      <ModalOverlay />
      <ModalContent maxHeight="320px">
        <ModalHeader>{modalTitle}</ModalHeader>
        <ModalCloseButton color={'gray'} background="none" cursor="pointer" />
        <ModalBody paddingY={0}>
          <FormControl isInvalid={!!get(errors, 'value') ? true : false}>
            <FormLabel fontWeight={'normal'} marginTop={4}>
              {type === EUploadBadgeImageModalType.LEVEL ? 'Level' : 'Title'} Name
            </FormLabel>
            <Input
              value={valueField}
              autoFocus={true}
              placeholder="Enter Level"
              onChange={valueInputOnChange}
              boxSizing="border-box"
            />
            <FormErrorMessage>{get(errors, 'value.message', '')}</FormErrorMessage>
          </FormControl>
          <FormControl>
            <FormLabel fontWeight={'normal'} marginTop={4}>
              Badge Image (optional)
            </FormLabel>
            <UploadBadgeImage
              data={badgeImage}
              type={EUploadedImageType.BADGE_IMAGE}
              afterUploadHandler={(value) => {
                setBadgeImageValue(value)
              }}
              setIsValidBadgeImage={(isValidForm: boolean) => {
                setIsValidBadgeImage(isValidForm)
              }}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" customStyles={{ marginRight: '10px' }} onClick={closeHandler}>
            Cancel
          </Button>
          <Button onClick={OKClickHandler} variant="solid">
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default observer(UploadBadgeImageModal)
