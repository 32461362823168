import { useEffect } from 'react'
import { FormControl, FormLabel, Stack, Radio, RadioGroup } from '@chakra-ui/react'
import { capitalize } from 'lodash'
import { observer } from 'mobx-react'
import { useFormContext, useWatch, Controller } from 'react-hook-form'
import { getValidArray } from 'utils/commonUtils'

export interface IGroupRadio {
  name: string
  label?: any
  isEdit?: boolean
  data?: string[]
  defaultValue?: string
  onChange?: (value: string | null) => void
}

const GroupRadio = (props: IGroupRadio) => {
  const { name, label, data, onChange, defaultValue } = props
  const { control, setValue } = useFormContext()

  const currentValue: string = useWatch({ control, name, defaultValue })

  useEffect(() => {
    if (currentValue === '' && defaultValue) {
      setValue(name, defaultValue)
    }
  }, [currentValue, name, setValue, defaultValue])

  const handleChange = (value: string | null) => {
    setValue(name, value)
    if (onChange) {
      onChange(value)
    }
  }

  return (
    <FormControl id={name} width="full" padding={{ sm: '0px', md: '10px', lg: '0' }}>
      <FormLabel fontWeight={'normal'} mb={2} color="gray.700" paddingBottom="12px">
        {label}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <RadioGroup {...field} value={currentValue ?? ''} onChange={(value) => handleChange(value || null)}>
            <Stack direction="row">
              {getValidArray(data).map((item) => (
                <Radio key={item} value={item} colorScheme="blue" width="50%">
                  {capitalize(item)}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        )}
      />
    </FormControl>
  )
}

export default observer(GroupRadio)
