import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  HStack,
  Tag,
  TagLabel,
} from '@chakra-ui/react'
import Button from 'components/Button'
import EmptyContentBox from 'components/EmptyContentBox'
import Icon from 'components/Icon'
import { backgroundPrimary, backgroundGrey50, backgroundGrey200, borderGray200 } from 'theme/globalStyles'
import { Messages } from 'constants/index'
import { stripHtml, isValidArray } from 'utils/commonUtils'
import { getFormattedDateWith_MMMDDYYYY } from 'utils/dateFormatUtils'
import { IGeneralListProps } from './interface'

const GeneralList = (props: IGeneralListProps) => {
  const { isAdmin, dataGroupsByMonth, openConfirmDeleteModal } = props
  if (!isValidArray(dataGroupsByMonth)) return <EmptyContentBox text={Messages.noData} />
  return (
    <Box>
      {dataGroupsByMonth?.map((item) => {
        return (
          <Accordion allowMultiple key={item._id}>
            <Tag
              variant="subtle"
              colorScheme="blue"
              color="white"
              background={backgroundPrimary}
              userSelect="none"
              padding="4px 12px"
              marginBottom="16px">
              <Icon iconName="calendar-white.svg" />
              <TagLabel marginLeft="3px">{item?._id}</TagLabel>
            </Tag>
            {item?.data?.map((policy) => {
              return (
                <AccordionItem
                  key={policy._id}
                  border={`1px solid ${borderGray200}`}
                  borderRadius="6px"
                  boxShadow="none"
                  backgroundColor={backgroundGrey50}
                  marginBottom="16px">
                  <AccordionButton
                    backgroundColor="white"
                    borderRadius="6px"
                    _expanded={{ background: `${backgroundGrey50}` }}>
                    <AccordionIcon color="black" />
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      color="black"
                      paddingLeft="10px"
                      fontWeight="500"
                      fontSize="16px">
                      {policy?.title}
                      {!policy?.published && (
                        <Tag size="md" variant="outline" colorScheme="gray" marginLeft="8px" fontWeight="400">
                          Unpublished
                        </Tag>
                      )}
                    </Box>
                    <Box as="span" color="gray.500" marginLeft="4px">
                      {getFormattedDateWith_MMMDDYYYY(policy?.publishedDate)}
                    </Box>
                  </AccordionButton>
                  <AccordionPanel paddingY="16px">
                    <Text paddingX="5px">{stripHtml(policy?.content)}</Text>
                    {isAdmin && (
                      <HStack justifyContent="end" borderTop={`1px solid ${backgroundGrey200}`} paddingTop="16px">
                        <Button
                          variant="outline"
                          customStyles={{ color: 'red' }}
                          leftIcon="delete-bin-fill.svg"
                          onClick={() => openConfirmDeleteModal(policy)}>
                          Delete
                        </Button>
                        <Button variant="outline" leftIcon="edit-fill.svg" onClick={() => props.openEditModal(policy)}>
                          Edit
                        </Button>
                      </HStack>
                    )}
                  </AccordionPanel>
                </AccordionItem>
              )
            })}
          </Accordion>
        )
      })}
    </Box>
  )
}
export default GeneralList
