import { generatePath } from 'react-router'
import { ILeave } from 'types/leave'
import { frontendRoutes } from 'constants/routes'

export function generateLeavePath(leave: ILeave, route: string) {
  const id = leave.id

  switch (route) {
    case frontendRoutes.leaveManagementPage.leaveManagementForUser.myLeave.value:
      return generatePath(route, { id })
    default:
      return ''
  }
}
