import { ResponsiveBar } from '@nivo/bar'
import { observer } from 'mobx-react-lite'
import { defaultProjectColors, nivoTheme } from 'containers/Dashboard/constant'
import { getProjectShortName } from 'containers/Dashboard/DashboardUser/utils'
import { getValidArray } from 'utils/commonUtils'
import { useStores } from 'utils/hooks/useStores'

const legendProps: any = {
  dataFrom: 'keys',
  anchor: 'bottom',
  direction: 'row',
  justify: false,
  itemWidth: 100,
  itemHeight: 20,
  itemTextColor: '#2D3748',
  itemDirection: 'left-to-right',
  itemOpacity: 1,
  effects: [
    {
      on: 'hover',
      style: {
        itemTextColor: '#000',
      },
    },
  ],
}
const SalaryChart = () => {
  const { userProfileStore } = useStores()
  const { dashboardData } = userProfileStore
  const { salaryBarData } = dashboardData
  const keys: string[] = Object.keys(salaryBarData[0]).filter((item) => item !== 'project')
  const keysNoTotal: string[] = keys.filter((item) => item !== 'Total')
  return (
    <ResponsiveBar
      theme={nivoTheme}
      data={getValidArray(salaryBarData)}
      keys={keys}
      indexBy="project"
      margin={{ top: 20, right: 20, bottom: 100, left: 80 }}
      padding={0.4}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={getValidArray(keys).map((item) => {
        if (item === 'Total') return 'transparent'
        return defaultProjectColors[keys.indexOf(item) + 1]
      })}
      maxValue={Math.max(...getValidArray(salaryBarData).map((item) => Number(item?.['Total']))) * 1.2}
      innerPadding={1}
      isInteractive
      borderColor={{
        from: 'color',
      }}
      enableLabel={false}
      layers={[
        'grid',
        'axes',
        'bars',
        'markers',
        'legends',
        'annotations',
        ...getValidArray(salaryBarData).map((item, index) =>
          item?.['Total'] > 0
            ? ({ bars }) => {
                const valueData = bars.filter((bar) => String(bar?.key)?.includes('Total'))?.[index]
                if (!valueData) return null

                return (
                  <text
                    x={valueData?.x + valueData?.width / 2}
                    y={valueData?.y + valueData?.height - 8}
                    textAnchor="middle"
                    dominantBaseline="central"
                    style={{
                      fontSize: '11px',
                      fontWeight: 900,
                      fill: '#718096',
                    }}>
                    {String(valueData?.data?.formattedValue)}
                  </text>
                )
              }
            : null
        ),
      ]}
      valueFormat={(e) => e.toLocaleString() + ' VND'}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 0,
        tickPadding: 0,
        tickRotation: 0,
      }}
      axisLeft={{
        format: (value) => value.toLocaleString(),
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
      }}
      enableGridX={true}
      enableGridY={true}
      gridXValues={[0]}
      gridYValues={[0]}
      legends={
        getValidArray(keysNoTotal).length <= 4
          ? [
              {
                ...legendProps,
                data: getValidArray(keysNoTotal).map((item) => ({
                  id: item,
                  label: getProjectShortName(item),
                  color: defaultProjectColors[keysNoTotal.indexOf(item) + 1],
                })),
                translateY: 63,
                itemsSpacing: 2,
                symbolSize: 20,
              },
            ]
          : [
              {
                ...legendProps,
                data: getValidArray(keysNoTotal)
                  .slice(0, Math.floor(keysNoTotal.length / 2))
                  .map((item) => ({
                    id: item,
                    label: getProjectShortName(item),
                    color: defaultProjectColors[keysNoTotal.indexOf(item) + 1],
                  })),
                translateY: 63,
                symbolSize: 20,
                itemsSpacing: 16,
              },
              {
                ...legendProps,
                data: getValidArray(keysNoTotal)
                  .slice(Math.floor(keysNoTotal.length / 2))
                  .map((item) => ({
                    id: item,
                    label: getProjectShortName(item),
                    color: defaultProjectColors[keysNoTotal.indexOf(item) + 1],
                  })),
                translateY: 97,
                symbolSize: 20,
                itemsSpacing: 16,
              },
            ]
      }
      role="application"
    />
  )
}

export default observer(SalaryChart)
