import { Box, Flex, Stack, Link } from '@chakra-ui/react'
import Button from 'components/Button'
import InputField from 'components/InputField'
import { useForm, FormProvider, UseFormReturn } from 'react-hook-form'
import { ILoginForm } from 'types/user'
import * as Yup from 'yup'
import { frontendRoutes } from 'constants/routes'
import { useYupValidationResolver } from 'utils/hooks/useYupValidationResolver'
import { Messages } from '../../../../constants'
import { RememberMe } from '../RememberMe'

const validationSchema = Yup.object().shape({
  email: Yup.string().email(Messages.invalidEmail).required(Messages.fieldRequired),
  password: Yup.string().required(Messages.fieldRequired),
})

const defaultValues = {
  email: '',
  password: '',
}

interface ILoginFormProps {
  onSubmit: (data: ILoginForm) => Promise<void>
}

const LoginForm = (props: ILoginFormProps) => {
  const { onSubmit } = props
  const resolver = useYupValidationResolver(validationSchema)
  const methods: UseFormReturn<ILoginForm> = useForm<ILoginForm>({ defaultValues, resolver })
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing="6">
          <InputField
            label="Your Email"
            type="email"
            name="email"
            errors={errors}
            placeholder="Enter your email"
            {...register('email')}
          />
          <Box>
            <InputField
              label="Password"
              name="password"
              errors={errors}
              isPassword
              placeholder="Enter your password"
              {...register('password')}
            />
            <Flex direction={'row'} mt="6" align="center" justify="space-between">
              <Box>
                <RememberMe label="Remember me" />
              </Box>
              <Link href={frontendRoutes.forgotPasswordPage.value}>
                <Box
                  display="inline-block"
                  textDecoration={'none'}
                  color={'blue.500'}
                  fontWeight="400"
                  fontSize="md"
                  mt="3"
                  margin={{ base: 'auto 0', md: '0' }}>
                  Forgot password?
                </Box>
              </Link>
            </Flex>
          </Box>
        </Stack>
        <Stack mt={6}>
          <Button width="full" type="submit">
            Sign in
          </Button>
        </Stack>
      </form>
    </FormProvider>
  )
}

export default LoginForm
