export const frontendRoutes = {
  value: '/',
  loginPage: {
    value: '/login-page',
  },
  resetPasswordPage: {
    value: '/reset-password-page',
    resetPasswordToken: {
      value: '/reset-password-page/:resetPasswordToken',
    },
  },
  forgotPasswordPage: {
    value: '/forgot-password-page',
  },
  adminDashboardPage: {
    value: '/admin-dashboard-page',
    statistic: {
      value: '/admin-dashboard-page/statistic',
    },
    heatmap: {
      value: '/admin-dashboard-page/heatmap',
    },
    skill: {
      value: '/admin-dashboard-page/skill',
    },
  },
  userDashboardPage: {
    value: '/user-dashboard-page',
    personal: {
      value: '/user-dashboard-page/personal',
    },
    statistic: {
      value: '/user-dashboard-page/statistic',
    },
    heatmap: {
      value: '/user-dashboard-page/heatmap',
    },
    detail: {
      value: '/user-dashboard-page/detail',
      id: {
        value: '/user-dashboard-page/detail/:id',
      },
    },
  },
  contractPage: {
    value: '/contract-page',
    list: {
      value: '/contract-page/list',
    },
    create: {
      value: '/contract-page/create',
    },
    contractDetail: {
      value: '/contract-page/contract-detail',
      id: {
        value: '/contract-page/contract-detail/:id',
      },
    },
  },
  projectPage: {
    projectAdmin: {
      list: {
        value: '/project-page/project-admin/list',
      },
      create: {
        value: '/project-page/project-admin/create',
      },
      detail: {
        value: '/project-page/project-admin/detail',
        id: {
          value: '/project-page/project-admin/detail/:id',
        },
      },
    },
    projectUser: {
      list: {
        value: '/project-page/project-user/list',
      },
      detail: {
        value: '/project-page/project-user/detail',
        id: {
          value: '/project-page/project-user/detail/:id',
        },
      },
    },
  },
  policyPage: {
    policyAdmin: {
      value: '/policy-page/policy-admin',
    },
    policyUser: {
      value: '/policy-page/policy-user',
    },
  },
  announcementPage: {
    announcementAdmin: {
      value: '/announcement-page/announcement-admin',
    },
    announcementUser: {
      value: '/announcement-page/announcement-user',
    },
  },
  timesheetPage: {
    board: {
      value: '/timesheet-page/board',
    },
    timesheetAdmin: {
      overview: {
        value: '/timesheet-page/timesheet-admin/overview',
      },
      report: {
        value: '/timesheet-page/timesheet-admin/report',
      },
      billReport: {
        value: '/timesheet-page/timesheet-admin/bill-report',
      },
      history: {
        value: '/timesheet-page/timesheet-admin/history',
      },
    },
    timesheetUser: {
      report: {
        value: '/timesheet-page/timesheet-user/report',
      },
      history: {
        value: '/timesheet-page/timesheet-user/history',
      },
      supervise: {
        value: '/timesheet-page/timesheet-user/supervise',
      },
    },
  },
  profilePage: {
    value: '/profile-page',
  },
  diagramPage: {
    diagramAdmin: {
      value: '/diagram-page/diagram-admin',
    },
    diagramAdminUser: {
      value: '/diagram-page/diagram-user',
    },
  },
  settingPage: {
    globalConfig: {
      value: '/setting-page/global-config',
    },
  },
  shareCVPage: {
    value: '/share-cv-page',
    fullAccess: {
      value: '/share-cv-page/full-access',
    },
    fileId_userId_sharedCode: {
      value: '/share-cv-page/:fileId/:sharedCode/:userId',
    },
  },
  paymentPage: {
    paymentAdmin: {
      value: '/payment-page/payment-admin',
    },
    paymentUser: {
      value: '/payment-page/payment-user',
    },
  },
  partnerPage: {
    list: {
      value: '/partner-page/list',
    },
    create: {
      value: '/partner-page/create',
    },
    detail: {
      value: '/partner-page/detail',
      id: {
        value: '/partner-page/detail/:id',
      },
    },
  },
  internalDocument: {
    list: {
      value: '/internal-document-page/list',
    },
    create: {
      value: '/internal-document-page/create',
    },
    detail: {
      value: '/internal-document-page/detail',
      id: {
        value: '/internal-document-page/detail/:id',
      },
    },
  },
  userPage: {
    list: {
      value: '/user-page/list',
    },
    create: {
      value: '/user-page/create',
    },
    detail: {
      id: {
        value: '/user-page/detail/:id',
      },
      cv: {
        id: {
          value: '/user-page/detail/cv/:id',
        },
      },
    },
  },
  birthdayPage: {
    value: '/birthday-page',
  },
  qrCodePage: {
    value: '/qr-code-page',
  },
  leaveManagementPage: {
    leaveManagementForUser: {
      value: '/leave-management-page',
      myLeave: {
        value: '/leave-management-page/my-leave',
      },
    },
    leaveManagementForAdmin: {
      value: '/leave-management-page',
      myLeave: {
        value: '/leave-management-page/my-leave-admin',
      },
    },
  },
  todoListOfHRPage: {
    value: '/todo-list-ofHR-page',
  },
}
