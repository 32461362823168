import { Dispatch, SetStateAction, useEffect } from 'react'
import Button from 'components/Button'
import { textGrey500, textGrey700 } from 'theme/globalStyles'
import { EProfileTab } from 'containers/Profile/constants'
import { setSubTabTitle } from './container'

interface ISubTabItemProps {
  iconName?: string
  isActive: boolean
  tab: EProfileTab
  setCurrentTab: Dispatch<SetStateAction<EProfileTab>>
}

const TabItem = (props: ISubTabItemProps) => {
  const { isActive, tab, iconName, setCurrentTab } = props
  useEffect(() => {
    setSubTabTitle(tab, isActive)
  })
  return (
    <Button
      variant={isActive ? 'solid' : 'ghost'}
      colorScheme={isActive && 'gray'}
      color={isActive ? textGrey700 : textGrey500}
      height="48px"
      width="full"
      leftIcon={iconName}
      customStyles={{ justifyContent: 'flex-start' }}
      onClick={() => {
        setCurrentTab(tab)
      }}>
      {String(tab)}
    </Button>
  )
}

export default TabItem
