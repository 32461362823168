import dayjs from 'dayjs'
import { DEFAULT_PROJECT_COLOR } from 'constants/common'
import { hexToRgbA } from 'utils/commonUtils'
import { IEventRenderInfo } from '../constants'
import { makeColorBolder, renderStyle } from './common'
const duration = require('dayjs/plugin/duration')
dayjs.extend(duration)

export function customEventWithMonthView(info: IEventRenderInfo): void {
  const { el: eventElement, event } = info
  const { extendedProps } = event

  const projectBackground: string = extendedProps?.projectColor ?? DEFAULT_PROJECT_COLOR
  const projectBorderColor: string = makeColorBolder(projectBackground)

  const contentElement: ChildNode = eventElement?.firstChild

  //* INFO: remove event time element
  contentElement.removeChild(contentElement.firstChild)
  //* INFO: render dot project element
  const dotProjectElement = document.createElement('span')
  renderStyle(dotProjectElement, {
    minHeight: '12px',
    minWidth: '12px',
    boxSizing: 'border-box',
    backgroundColor: projectBackground,
    borderRadius: '50%',
    border: `2px solid ${projectBorderColor}`,
    display: 'inline-block',
    cursor: 'pointer',
  })
  contentElement.insertBefore(dotProjectElement, contentElement.firstChild)
}

export function customEventWithWeekView(info: IEventRenderInfo): void {
  const { el: eventElement, event } = info
  const { extendedProps } = event
  const MIN_DURATION_HOURS: number = 0.5
  const projectColor: string = extendedProps?.projectColor ?? DEFAULT_PROJECT_COLOR
  const projectBackground: string = hexToRgbA(projectColor, 0.7)
  const projectBorderColor: string = makeColorBolder(projectColor)

  const contentElement: Element = eventElement.children[0]
  const durationHours: number = dayjs(event?.end).diff(event?.start, 'hours', true)

  renderStyle(eventElement, {
    backgroundColor: projectBackground,
    borderLeft: `4px solid ${projectBorderColor}`,
    cursor: 'pointer',
  })

  //* INFO when duration hours smaller than 2, the event will be remove time info
  if (durationHours <= MIN_DURATION_HOURS || Number.isNaN(durationHours)) {
    contentElement.removeChild(contentElement.firstChild)
    renderStyle(eventElement, {
      padding: '2px 12px 0px',
    })
  }
}

export function customEventWithDayView(info: IEventRenderInfo): void {
  const { el: eventElement, event } = info
  const { extendedProps } = event
  const MIN_DURATION_HOURS: number = 1

  const projectBackground: string = extendedProps?.projectColor ?? DEFAULT_PROJECT_COLOR
  const projectBorderColor: string = makeColorBolder(projectBackground, 10)

  const contentElement: Element = eventElement.children[0]
  const durationHours: number = dayjs(event?.end).diff(event?.start, 'hours', true)

  renderStyle(eventElement, {
    backgroundColor: projectBackground,
    borderLeft: `4px solid ${projectBorderColor}`,
    cursor: 'pointer',
  })

  //* INFO: when duration hours smaller than 1, the event will be remove time info
  if (durationHours <= MIN_DURATION_HOURS || Number.isNaN(durationHours)) {
    contentElement.removeChild(contentElement.firstChild)
  }
}
