import { IProjectNameResponse } from 'API/project/constants'
import { IUserNameResponse } from 'API/user/constants'
import { EProjectType } from 'enums/developerOnProject'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import { IDeveloperOnProject } from 'types/developerOnProject'
import { IOption, IProjectsInfo, IProfileDetailOfDetailForm, IProjectsInfoV2 } from 'types/user'
import { EUserStatusLabel } from 'constants/enum'
import { IUser, IUserV2 } from 'constants/schema'
import { getValidArray } from 'utils/commonUtils'

export function getUserStatusLabel(user: IUser): string {
  if (user?.isArchived) {
    return EUserStatusLabel.ARCHIVED
  }

  if (user?.isDeleted) {
    return EUserStatusLabel.DELETED
  }

  return ''
}

export function getUserStatus(user: IProfileDetailOfDetailForm): string {
  switch (true) {
    case user?.isDeleted:
      return 'Deleted'
    case user?.isArchived:
      return 'Archived'
    case user?.isActive:
      return 'Active'
    case !user?.isActive || user?.isActive !== 'Yes':
      return 'Inactive'
    default:
      return ''
  }
}

export function generateUserOptions(users: IUserNameResponse[]): IOption[] {
  const sortedUsers: IUser[] = orderBy(getValidArray(users), ['isDeleted', 'isArchived'], ['asc', 'asc'])

  return getValidArray(sortedUsers).map<IOption>((user: IUserNameResponse) => {
    const fullName: string = user?.name ?? ''
    const statusLabel: string = getUserStatusLabel(user)

    return {
      value: user?.id,
      label: `${fullName}${statusLabel && ` (${statusLabel}) `}`,
    }
  })
}

export function getProjectStatus(project: IProjectNameResponse): string {
  if (project?.isDeleted) {
    return 'Deleted'
  }

  if (project?.isArchived) {
    return 'Archived'
  }

  return ''
}

export function generateProjectOptions(projects: IProjectNameResponse[]) {
  return projects.map((project: IProjectNameResponse) => {
    const projectName: string = project.name ?? ''
    const statusLabel: string = getProjectStatus(project)

    return {
      value: project.id,
      label: `${projectName}${statusLabel && ` (${statusLabel}) `}`,
    }
  })
}

export function getUserLabel(user: IUser): string {
  const statusLabel: string = getUserStatusLabel(user)
  const fullName: string = user?.fullName ?? ''

  return `${fullName}${statusLabel && ` (${statusLabel}) `}`
}

export function getProjectsIdsByProjectType(
  developerOnProjects: IDeveloperOnProject[],
  projectType?: EProjectType
): string[] {
  let filteredProjects: IDeveloperOnProject[] = []
  if (projectType) {
    filteredProjects = getValidArray(developerOnProjects).filter((item: IDeveloperOnProject) => {
      return item?.projectType === projectType
    })
  } else {
    filteredProjects = developerOnProjects
  }

  return map(filteredProjects, 'project') ?? []
}

export function getProjectsByProjectTypeV2(
  developerOnProjects: IDeveloperOnProject[],
  projectType?: EProjectType
): IDeveloperOnProject[] {
  let filteredProjects: IDeveloperOnProject[] = []
  if (projectType) {
    filteredProjects = getValidArray(developerOnProjects).filter((item: IDeveloperOnProject) => {
      return item?.projectType === projectType
    })
  } else {
    filteredProjects = developerOnProjects
  }

  return filteredProjects
}

export function getSupervisorProjectIds(developerOnProjects: IDeveloperOnProject[]): string[] {
  const filteredDeveloperOnProjects: IDeveloperOnProject[] = getValidArray(developerOnProjects).filter(
    (item: IDeveloperOnProject) => {
      return item?.isSupervisor
    }
  )
  return map(filteredDeveloperOnProjects, 'project') ?? []
}

export function getSupervisorProjectV2(developerOnProjects: IDeveloperOnProject[]): IDeveloperOnProject[] {
  const filteredDeveloperOnProjects: IDeveloperOnProject[] = getValidArray(developerOnProjects).filter(
    (item: IDeveloperOnProject) => {
      return item?.isSupervisor
    }
  )
  return filteredDeveloperOnProjects
}

export function getProjectsInfo(user: IUser): IProjectsInfo {
  const developerOnProjectsData: IDeveloperOnProject[] = user?.developerOnProjectsData ?? []

  return {
    projects: getProjectsIdsByProjectType(developerOnProjectsData),
    externalProjects: getProjectsIdsByProjectType(developerOnProjectsData, EProjectType.EXTERNAL_PROJECT),
    extraProjects: getProjectsIdsByProjectType(developerOnProjectsData, EProjectType.EXTRA_PROJECT),
    supervisorProjects: getSupervisorProjectIds(developerOnProjectsData),
  }
}

export function getProjectsInfoV2(user: IUserV2): IProjectsInfoV2 {
  const developerOnProjectsData: IDeveloperOnProject[] = user?.userProjects ?? []

  return {
    projects: getProjectsByProjectTypeV2(developerOnProjectsData),
    externalProjects: getProjectsByProjectTypeV2(developerOnProjectsData, EProjectType.EXTERNAL_PROJECT),
    extraProjects: getProjectsByProjectTypeV2(developerOnProjectsData, EProjectType.EXTRA_PROJECT),
    supervisorProjects: getSupervisorProjectV2(developerOnProjectsData),
  }
}

export function getValidDevelopers(developers: IUser[]): IUser[] {
  return getValidArray(developers).filter((developer) => {
    return !developer?.isArchived && !developer?.isDeleted && developer?.isActive
  })
}
