import { useEffect, useState } from 'react'
import { HStack } from '@chakra-ui/react'
import Button from 'components/Button'
import { useFormContext } from 'react-hook-form'
import { useStores } from 'utils/hooks/useStores'
import { IHeatmapFilterFormProps, typeOfFilterOfHeadMap } from '../../constant'
import { renderHeatmapFilterOption } from './container'

const HeatmapFilterForm = (props: IHeatmapFilterFormProps) => {
  const { projectNameList, viewAnalyticHandler, isSupervisor, userNameList } = props
  const { userReportStore } = useStores()
  const [filterType, setFilterType] = useState(typeOfFilterOfHeadMap[0])
  const { handleSubmit, getValues } = useFormContext()
  const [isValidForm, setIsValidForm] = useState(false)
  const formValues = getValues()
  const { selectedProject, typeOfFilter, selectedUser } = formValues
  useEffect(() => {
    const isValid = selectedProject || selectedUser
    setFilterType(typeOfFilter)
    setIsValidForm(isValid)
  }, [formValues])

  return (
    <HStack width="inherit" background="white" margin={{ base: 2, lg: '16px' }}>
      {renderHeatmapFilterOption(isSupervisor, projectNameList, userNameList, filterType)}
      <HStack padding={'16px'}>
        <Button
          variant="solid"
          disabled={!isValidForm || userReportStore.isHeatmapLoading}
          isLoading={userReportStore.isHeatmapLoading}
          rightIcon="arrow-right-line-white.svg"
          onClick={handleSubmit(viewAnalyticHandler)}>
          View HeatMap
        </Button>
      </HStack>
    </HStack>
  )
}

export default HeatmapFilterForm
