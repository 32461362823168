import { ISuccessResponse } from 'API/constants'
import {
  activeContractAPI,
  createContractAPI,
  deActiveContractAPI,
  deleteContractAPI,
  downloadContractFileAPI,
  getContractDetailAPI,
  getContractListAdminAPI,
  getContractListAdminWithPaginationAPI,
  getContractListOfUserAPI,
  showContractFileForAdminAPI,
  updateContractAPI,
  sendContractToEmailAPI,
} from 'API/contract'
import { IContractListWithPaginationResponse, IContractPayload } from 'API/contract/constant'
import { handleError } from 'API/error'
import { getPartnersAPI } from 'API/partner'
import { IPartnerListResponse } from 'API/partner/constants'
import { saveAs } from 'file-saver'
import get from 'lodash/get'
import omit from 'lodash/omit'
import { makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'
import { THistory } from 'types/common'
import { EYesNoOption } from 'constants/enum'
import { IPartner, IContract } from 'constants/schema'
import { IContractForm } from 'containers/Contract/Create/constants'
import { getInQuery, toBase64 } from 'utils/commonUtils'
import { getContractDetailFormData } from 'utils/formatDataUtils'
import { formatContractNumber } from 'utils/numberUtils'
import RootStore from '../rootStore'

class AdminContractStore {
  rootStore: RootStore
  partnerList: IPartner[] = []
  contractList: IContract[] = []
  contractListOfUser: IContract[] = []
  count: number = 0
  currentContractContent: string = ''
  contractDetail: IContract
  isContractListLoading: boolean = false

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public async getPartnerList(partnerCategory: string[]): Promise<void> {
    try {
      const payload = { filter: { isDeleted: false, isArchived: false, category: getInQuery(partnerCategory) } }
      const response: ISuccessResponse<IPartnerListResponse> = await getPartnersAPI(payload)
      this.partnerList = response?.data?.partners
    } catch (error) {
      handleError(error, 'src/store/admin/contractStore.ts', 'getPartnerList')
    }
  }

  public async createContract(data: IContractForm): Promise<IContract> {
    try {
      const payload: IContractPayload = {
        ...data,
        isCreateInternalDoc: get(data, 'isCreateInternalDoc', EYesNoOption.YES) === EYesNoOption.YES,
        number: formatContractNumber(data?.number, get(data, 'type.value', '')),
        partnerId: get(data, 'partnerId.value', ''),
        laborContractCategory: get(data, 'laborContractCategory.value', ''),
        laborContractTerm: get(data, 'laborContractTerm.value', ''),
        type: get(data, 'type.value', ''),
        IDIssuePlaceVI: data?.IDIssuePlaceVI?.value ?? '',
      }
      const contract: IContract = await createContractAPI(payload)
      this.contractDetail = contract
      return contract
    } catch (error) {
      handleError(error, 'src/store/admin/contractStore.ts', 'createContract')
      return {} as IContract
    }
  }

  public async getContractList(): Promise<void> {
    try {
      const { contractList } = await getContractListAdminAPI()
      this.contractList = contractList
    } catch (error) {
      handleError(error, 'src/store/admin/contractStore.ts', 'getContractList')
    }
  }

  public async getContractListWithPagination(isReset?: boolean, history?: THistory): Promise<void> {
    try {
      this.isContractListLoading = true
      const currentPage: number = isReset ? 1 : Number(get(history, 'location.state.page') || 1)
      const response: ISuccessResponse<IContractListWithPaginationResponse> =
        await getContractListAdminWithPaginationAPI({ currentPage, perPage: 8 })
      this.contractList = response.data.contractList
      this.count = response.data.count
    } catch (error) {
      handleError(error, 'src/store/admin/contractStore.ts', 'getContractListWithPagination')
    } finally {
      this.isContractListLoading = false
    }
  }

  public async getContractListOfUser(userId: string): Promise<void> {
    try {
      const { contractList } = await getContractListOfUserAPI(userId)
      this.contractListOfUser = contractList
    } catch (error) {
      handleError(error, 'src/store/admin/contractStore.ts', 'getContractListOfUser')
    }
  }

  public async getContractDetail(contractId: string): Promise<void> {
    try {
      const { contractDetail } = await getContractDetailAPI(contractId)
      if (contractDetail) {
        const formattedContract: IContract = getContractDetailFormData(contractDetail)
        this.contractDetail = formattedContract
      }
    } catch (error) {
      handleError(error, 'src/store/admin/contractStore.ts', 'getContractDetail')
    }
  }

  public async updateContract(contractId: string, data: IContractPayload): Promise<void> {
    try {
      const payload: IContractPayload = {
        ...omit(data, 'partnerData'),
        partnerId: get(data, 'partnerId.value', ''),
        laborContractCategory: get(data, 'laborContractCategory.value', ''),
        laborContractTerm: get(data, 'laborContractTerm.value', ''),
        type: get(data, 'type.value', ''),
      }
      const { message } = await updateContractAPI(payload, contractId)
      toast.success(message)
    } catch (error) {
      handleError(error, 'src/store/admin/contractStore.ts', 'updateContract')
    }
  }

  public async deleteContract(id: string): Promise<void> {
    try {
      const { message } = await deleteContractAPI(id)
      toast.success(message)
    } catch (error) {
      handleError(error, 'src/store/admin/contractStore.ts', 'deleteContract')
    }
  }

  public async activeContract(id: string): Promise<void> {
    try {
      const { message } = await activeContractAPI(id)
      toast.success(message)
    } catch (error) {
      handleError(error, 'src/store/admin/contractStore.ts', 'activeContract')
    }
  }

  public async deactiveContract(id: string): Promise<void> {
    try {
      const { message } = await deActiveContractAPI(id)
      toast.success(message)
    } catch (error) {
      handleError(error, 'src/store/admin/contractStore.ts', 'deactiveContract')
    }
  }

  public async downloadContractFile(id: string, fileName: string): Promise<void> {
    try {
      const acceptType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      const contractFile: BlobPart = await downloadContractFileAPI(id, fileName, acceptType)
      const result: string = (await toBase64(new Blob([contractFile], { type: acceptType }))) as string
      saveAs(result, fileName)
    } catch (error) {
      handleError(error, 'src/store/admin/contractStore.ts', 'downloadContractFile')
    }
  }

  public async showContractFile(id: string, fileName: string): Promise<void> {
    try {
      const acceptType = 'application/pdf'
      const contractFile: BlobPart = await showContractFileForAdminAPI(id, fileName, acceptType)
      const result: string = (await toBase64(new Blob([contractFile], { type: acceptType }))) as string
      this.currentContractContent = result
    } catch (error) {
      handleError(error, 'src/store/admin/contractStore.ts', 'showContractFile')
    }
  }

  public async sendContractToEmail(id: string, fileName: string): Promise<void> {
    try {
      await sendContractToEmailAPI(id, fileName)
      toast.success('Send contract to email successfully')
    } catch (error) {
      handleError(error, 'src/store/admin/contractStore.ts', 'sendContractToEmail')
    }
  }
}

export default AdminContractStore
