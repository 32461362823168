import { Box, Collapse, useMediaQuery } from '@chakra-ui/react'
import { useHistory, Link } from 'react-router-dom'
import { maxMobileSize } from 'constants/common'
import { getValidArray } from 'utils/commonUtils'
import { IMenuList } from '../../constant'

interface ISubMenuProps {
  subMenuList: IMenuList[]
  isOpen: boolean
  className?: string
  children?: React.ReactNode
  toggleCollapsedSidebar: () => void
}

const SubMenu = (props: ISubMenuProps) => {
  const { subMenuList, isOpen, children, toggleCollapsedSidebar } = props
  const history = useHistory()
  const [isMobile] = useMediaQuery(maxMobileSize)

  function renderSidebarClassName(router: string): string {
    const pathName = history.location.pathname
    let isSubMenuActive: boolean = false
    if (pathName === router) {
      isSubMenuActive = true
    }
    return isSubMenuActive ? 'sub-menu active' : 'sub-menu'
  }

  function redirectToSubmenu(router: string): void {
    history.replace(router)
  }

  return (
    <>
      {children}
      <Collapse in={isOpen} animateOpacity>
        {getValidArray(subMenuList).map((subMenu, index) => (
          <Box
            key={`sub-menu-${index}`}
            margin={1}
            className={renderSidebarClassName(subMenu?.router)}
            onClick={() => redirectToSubmenu(subMenu?.router)}>
            <Link to={''} onClick={isMobile && toggleCollapsedSidebar}>
              {subMenu.name}
            </Link>
          </Box>
        ))}
      </Collapse>
    </>
  )
}

export default SubMenu
