import * as am4charts from '@amcharts/amcharts4/charts'
import * as am4core from '@amcharts/amcharts4/core'
import { IManHourHeatMap } from 'types/summarize'

export function createXAxis(chart: am4charts.XYChart) {
  const xAxis = chart.xAxes.push(new am4charts.CategoryAxis())
  xAxis.dataFields.category = 'weekday'
  xAxis.renderer.grid.template.disabled = true
  xAxis.renderer.minGridDistance = 40
  return xAxis
}

export function createYAxis(chart: am4charts.XYChart) {
  const yAxis = chart.yAxes.push(new am4charts.CategoryAxis())
  yAxis.dataFields.category = 'hour'
  yAxis.renderer.grid.template.disabled = true
  yAxis.renderer.inversed = true
  yAxis.renderer.minGridDistance = 30
  return yAxis
}

export function createSeries(chart: am4charts.XYChart) {
  const series = chart.series.push(new am4charts.ColumnSeries())
  series.dataFields.categoryX = 'weekday'
  series.dataFields.categoryY = 'hour'
  series.dataFields.value = 'value'
  series.sequencedInterpolation = true
  series.defaultState.transitionDuration = 3000

  const bgColor = new am4core.InterfaceColorSet().getFor('background')

  const columnTemplate = series.columns.template
  columnTemplate.strokeWidth = 1
  columnTemplate.strokeOpacity = 0.2
  columnTemplate.stroke = bgColor
  columnTemplate.tooltipText = '{weekday}, {hour}: {value.workingValue.formatNumber("#.")}'
  columnTemplate.width = am4core.percent(100)
  columnTemplate.height = am4core.percent(100)

  series.heatRules.push({
    target: columnTemplate,
    property: 'fill',
    min: am4core.color(bgColor),
    max: chart.colors.getIndex(0),
  })

  return { series, columnTemplate }
}

export function createHeatLegend(chart: am4charts.XYChart, series: am4charts.ColumnSeries) {
  const heatLegend = chart.bottomAxesContainer.createChild(am4charts.HeatLegend)
  heatLegend.width = am4core.percent(100)
  heatLegend.series = series
  heatLegend.valueAxis.renderer.labels.template.fontSize = 9
  heatLegend.valueAxis.renderer.minGridDistance = 30
  return heatLegend
}

export function handleHover(column: any, heatLegend: am4charts.HeatLegend) {
  if (!isNaN(column.dataItem.value)) {
    heatLegend.valueAxis.showTooltipAt(column.dataItem.value)
  } else {
    heatLegend.valueAxis.hideTooltip()
  }
}

export const createChart = (chartData: IManHourHeatMap[]) => {
  const chart = am4core.create('heatmap-chartdiv', am4charts.XYChart)
  chart.maskBullets = false
  createXAxis(chart)
  createYAxis(chart)
  const { series } = createSeries(chart)
  const heatLegend = createHeatLegend(chart, series)
  series.columns.template.events.on('over', (event) => {
    handleHover(event.target, heatLegend)
  })
  series.columns.template.events.on('hit', (event) => {
    handleHover(event.target, heatLegend)
  })
  series.columns.template.events.on('out', () => {
    heatLegend.valueAxis.hideTooltip()
  })
  chart.data = chartData

  return chart
}
