import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from 'components/Button'
import DatePicker from 'components/DatePickerOld'
import { Field } from 'formik'

const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
    fontSize: 16,
  },
})

interface IFilterFormOfPaymentProps {
  classes?: Record<string, string>
  dirty?: boolean
  isSubmitting?: boolean
  isPaymentsLoading?: boolean
  handleSubmit?: React.FormEventHandler<HTMLFormElement>
}

const FilterFormOfPayment = (props: IFilterFormOfPaymentProps) => {
  const { classes, dirty, isSubmitting, handleSubmit, isPaymentsLoading } = props
  return (
    <form onSubmit={handleSubmit}>
      <div className="container-fluid">
        <div className="box box-default">
          <div className="box-header">Filter</div>
          <div className="box-divider" />
          <div className={`box-body`}>
            <div className="container-fluid">
              <div className="fields">
                <div className="field">
                  <Field
                    name="date"
                    label="Date*"
                    className={classes.textField}
                    margin="normal"
                    component={DatePicker}
                  />
                </div>
              </div>
              <div className="submit-option">
                <Button isLoading={isPaymentsLoading} type="submit" disabled={!dirty && isSubmitting}>
                  Filter
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default withStyles(styles)(FilterFormOfPayment)
