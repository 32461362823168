import React from 'react'
import { Box } from '@chakra-ui/react'
import { ITagColorCellProps } from './interfaces'
import styles from './tagColorCell.module.scss'

const TagColorCell = (props: ITagColorCellProps) => {
  const { color = '' } = props

  return <Box className={styles.tagColorCell} backgroundColor={color}></Box>
}

export default TagColorCell
