import React from 'react'
import { HStack, Text, useMediaQuery } from '@chakra-ui/react'
import cx from 'classnames'
import Button from 'components/Button'
import { FaEllipsisV } from 'react-icons/fa'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { maxTabletSize } from 'constants/common'
import { getValidArray } from 'utils/commonUtils'
import { ReactComponent as ArchiveIcon } from '../../../../../assets/images/svg/archive-fill.svg'
import { ReactComponent as SendIcon } from '../../../../../assets/images/svg/arrow-right-up-line.svg'
import { ReactComponent as ActiveIcon } from '../../../../../assets/images/svg/collapse.svg'
import { ReactComponent as DashboardIcon } from '../../../../../assets/images/svg/dashboard.svg'
import { ReactComponent as DeleteIcon } from '../../../../../assets/images/svg/delete-bin-fill.svg'
import { ReactComponent as EditIcon } from '../../../../../assets/images/svg/edit-fill.svg'
import { ReactComponent as DeactiveIcon } from '../../../../../assets/images/svg/error-warning-fill.svg'
import { ReactComponent as EyeIcon } from '../../../../../assets/images/svg/eye-fill.svg'
import { IAction, IMoreDropdown } from './interfaces'
import styles from './moreDropdown.module.scss'

const MoreDropdown = (props: IMoreDropdown) => {
  const {
    actions = [],
    noPadding = false,
    isDetail,
    isAccepted,
    isReject,
    detailActionHandler,
    isBackground,
    isDownload,
    downloadActionHandler,
    isSummary,
    summaryActionHandler,
    acceptActionHandler,
    rejectActionHandler,
  } = props

  const [isTablet]: boolean[] = useMediaQuery(maxTabletSize)

  return (
    <div className={styles.container}>
      <UncontrolledDropdown className={isBackground ? styles.groupButtonBackground : styles.groupButton}>
        {isSummary && isTablet && (
          <Button
            leftIcon="information-line.svg"
            variant="ghost"
            leftIconSize={16}
            customStyles={{ padding: '0px' }}
            backgroundColor="transparent"
            onClick={summaryActionHandler}></Button>
        )}
        {isAccepted && (
          <Button
            leftIcon="checkbox-circle-fill-green.svg"
            variant="ghost"
            leftIconSize={16}
            customStyles={{ padding: '0px' }}
            backgroundColor="transparent"
            onClick={acceptActionHandler}></Button>
        )}
        {isReject && (
          <Button
            leftIcon="cancel-circle-fill-red.svg"
            variant="ghost"
            leftIconSize={16}
            customStyles={{ padding: '0px' }}
            backgroundColor="transparent"
            onClick={rejectActionHandler}></Button>
        )}
        {isDetail && (
          <Button
            leftIcon="eye-fill.svg"
            variant="ghost"
            leftIconSize={16}
            customStyles={{ padding: '0px' }}
            backgroundColor="transparent"
            onClick={detailActionHandler}></Button>
        )}
        {isDownload && (
          <Button
            leftIcon="export.svg"
            variant="ghost"
            leftIconSize={18}
            customStyles={{ padding: '0px' }}
            backgroundColor="transparent"
            onClick={downloadActionHandler}></Button>
        )}
        {getValidArray(actions).length > 0 && (
          <React.Fragment>
            <DropdownToggle className={cx({ [styles.noPadding]: noPadding })} nav>
              <FaEllipsisV color="gray" />
            </DropdownToggle>
            <DropdownMenu right className={styles.menu}>
              {actions.map((action: IAction, index) => {
                const customClassNames = action.customClassNames || []
                const customStyles = customClassNames.map((className) => styles[className])
                const name = String(action.name)
                const Icon = () => {
                  let renderIcon = null
                  if (name.toLowerCase().includes('archive')) {
                    renderIcon = <ArchiveIcon style={{ marginRight: '8px' }} />
                  } else if (name.includes('Delete')) {
                    renderIcon = <DeleteIcon style={{ marginRight: '8px' }} />
                  } else if (name.includes('Edit')) {
                    renderIcon = <EditIcon width="20px" height="20px" style={{ marginRight: '8px' }} />
                  } else if (name.includes('detail')) {
                    renderIcon = <EyeIcon width="20px" height="20px" style={{ marginRight: '8px' }} />
                  } else if (name.includes('Deactivate')) {
                    renderIcon = <DeactiveIcon style={{ marginRight: '8px' }} />
                  } else if (name.includes('Activate')) {
                    renderIcon = <ActiveIcon style={{ marginRight: '8px' }} />
                  } else if (name.includes('View Dashboard')) {
                    renderIcon = <DashboardIcon width="20px" height="20px" style={{ marginRight: '8px' }} />
                  } else if (name.includes('Send')) {
                    renderIcon = <SendIcon style={{ marginRight: '8px' }} />
                  }
                  return renderIcon
                }
                return (
                  <DropdownItem key={index} onClick={action.handler} className={styles.item}>
                    <HStack>
                      <Icon />
                      <Text className={cx(styles.selection, [...customStyles])} size="md" color="gray.700">
                        {action.name}
                      </Text>
                    </HStack>
                  </DropdownItem>
                )
              })}
            </DropdownMenu>
          </React.Fragment>
        )}
      </UncontrolledDropdown>
    </div>
  )
}

export default MoreDropdown
