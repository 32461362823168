import { HStack, FormControl, VStack, Box as ChakraBox } from '@chakra-ui/react'
import Box from 'components/Box'
import Button from 'components/Button'
import DatePicker from 'components/DatePicker'
import InputDropdown from 'components/Dropdown/InputDropdown'
import dayjs from 'dayjs'
import { useFormContext, Controller } from 'react-hook-form'
import { borderSecondary } from 'theme/globalStyles'
import { IOption } from 'constants/common'
import { generateProjectOptions } from 'utils/user'
import { workingPeriodOptions } from '../../constants'
import { IUserFilterFormProps } from '../../constants'

const UserFilterForm = (props: IUserFilterFormProps) => {
  const { projectNameList, reportWithProjectHandler, isAnalysisLoading } = props
  const { control, setValue, handleSubmit } = useFormContext()
  let newProjectNameList = projectNameList
  const projectOptions: IOption[] = generateProjectOptions(newProjectNameList)
  function pickDate(date: Date): void {
    setValue('date', date)
    localStorage.setItem('isPickPastMonth', String(dayjs(date).month() < dayjs().month()))
  }

  return (
    <Box>
      <HStack width="inherit">
        <VStack width="inherit" padding={'20px'}>
          <HStack width="inherit">
            <HStack width="inherit" display="flex" spacing="20px">
              <ChakraBox flex={1}>
                <InputDropdown
                  name="selectedProject"
                  placeHolder="Select Project"
                  optionsData={projectOptions}
                  isClearable={false}
                  borderColor={borderSecondary}
                />
              </ChakraBox>
              <ChakraBox flex={1}>
                <InputDropdown
                  name="period"
                  placeHolder="Select Period"
                  optionsData={workingPeriodOptions}
                  isClearable={false}
                  borderColor={borderSecondary}
                />
              </ChakraBox>
              <ChakraBox flex={1}>
                <FormControl>
                  <Controller
                    name="date"
                    control={control}
                    render={({ field: { value } }) => <DatePicker selectedDate={value} onChange={pickDate} />}
                  />
                </FormControl>
              </ChakraBox>
              <ChakraBox>
                <Button
                  height="44px"
                  rightIcon="arrow-right-line-white.svg"
                  isLoading={isAnalysisLoading}
                  disabled={isAnalysisLoading}
                  onClick={handleSubmit(reportWithProjectHandler)}>
                  View Report
                </Button>
              </ChakraBox>
            </HStack>
          </HStack>
        </VStack>
      </HStack>
    </Box>
  )
}

export default UserFilterForm
