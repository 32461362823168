import apiV2 from 'API/api'
import { APIRouters } from 'API/APIRouters'
import { AxiosResponse } from 'axios'
import {
  IProjectTypeListResponse,
  IProjectType,
  IProjectTypeListPaginationRequest,
  IProjectTypeDataFromCreateForm,
} from './constants'

export async function getProjectTypeListAPI(): Promise<IProjectTypeListResponse> {
  try {
    const url: string = APIRouters.projectType.list.value
    const response: AxiosResponse = await apiV2.get(url)
    return {
      projectTypes: response?.data?.projectTypes || [],
      count: response?.data?.count || 0,
    }
  } catch (error) {
    throw error
  }
}

export async function getProjectTypeListWithPaginationAPI(
  payload: IProjectTypeListPaginationRequest
): Promise<IProjectTypeListResponse> {
  try {
    const url: string = APIRouters.projectType.list.value
    const response: AxiosResponse = await apiV2.post(url, payload)
    return {
      projectTypes: response?.data?.projectTypes || [],
      count: response?.data?.count || 0,
    }
  } catch (error) {
    throw error
  }
}

export async function createProjectTypeAPI(projectType: IProjectTypeDataFromCreateForm): Promise<string> {
  try {
    const url: string = APIRouters.projectType.value
    const response: AxiosResponse = await apiV2.post(url, projectType)
    return response?.data?.message || ''
  } catch (error) {
    throw error
  }
}

export async function updateProjectTypeAPI(projectType: IProjectType): Promise<string> {
  try {
    const url: string = APIRouters.projectType.projectTypeId.value(projectType.id)
    const response: AxiosResponse = await apiV2.patch(url, projectType)
    return response?.data?.message || ''
  } catch (error) {
    throw error
  }
}

export async function deleteProjectTypeAPI(projectTypeId: string): Promise<string> {
  try {
    const url: string = APIRouters.projectType.projectTypeId.value(projectTypeId)
    const response: AxiosResponse = await apiV2.delete(url)
    return response?.data?.message || ''
  } catch (error) {
    throw error
  }
}
