export function checkReportData(summaryReport, billReportList) {
  if ((!summaryReport || Object.keys(summaryReport).length === 0) && billReportList.length === 0) {
    return false
  }
  return true
}

export function checkSummaryData(summaryReport) {
  if (Object.values(summaryReport || {}).every((value) => value === 0)) {
    return false
  }
  return true
}
