import { ITableHeader } from 'types/table'
import { IRowActionItem } from 'types/table'
import { IOption } from 'constants/common'
import { EUserEducation, EYesNoOptionWithCapitalization } from 'constants/enum'
import { IContract } from 'constants/schema'
export const isSupervisor = [EYesNoOptionWithCapitalization.YES, EYesNoOptionWithCapitalization.NO]
export const isDownloadCV = [EYesNoOptionWithCapitalization.YES, EYesNoOptionWithCapitalization.NO]
export const needToSendEmail = [EYesNoOptionWithCapitalization.YES, EYesNoOptionWithCapitalization.NO]

export interface ILaborContractData extends Omit<IContract, 'isDeleted' | 'publishedDate'> {
  status: string
  publishedDate: string
  actions: JSX.Element
  actionsTablet: IRowActionItem[]
}

export function getHeaderLaborContractList() {
  const headers: ITableHeader[] = [
    {
      Header: 'Full name',
      accessor: 'fullNameVI',
    },
    {
      Header: 'Contract No.',
      accessor: 'number',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Languages',
      accessor: 'language',
    },
    {
      Header: '',
      accessor: 'actions',
    },
  ]

  return headers
}

export enum ETechnologyItem {
  NODE_JS = 'Node.js',
  REACT_JS = 'React.js',
  REACT_NATIVE = 'React Native',
  LOOPBACK_4 = 'Loopback 4',
  MONGODB = 'MongoDB',
}
export interface IUserProfile {
  _id?: string
  partnerId?: string
  fullName?: string
  email?: string
  phone?: string
  gender?: string
  placeOfBirth?: string
  dateOfBirth?: string
  address?: string
  nationality: string
  IDNumber?: string
  IDIssueDate?: string
  IDIssuePlace?: string
  IDInformation?: string
  education?: EUserEducation
  major?: string
  joinDate?: string
  bankAccount?: string
  bankName?: string
  titleId?: string
  levelId?: string
  paymentCategoryId?: string
  role?: string
  isSupervisor?: string
  fixedSalary?: string
  defaultRating?: string
  projectsOfSupervisor?: IOption[]
  projects?: IOption[]
  extraProjects?: IOption[]
  externalProjects?: IOption[]
}
