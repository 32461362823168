import { useEffect } from 'react'
import { Stack, Text, Box, VStack } from '@chakra-ui/react'
import RadarChart from 'components/RadarChart'
import { COLOR } from 'components/RadarChart/constants'
import { observer } from 'mobx-react'
import { getDisplayName } from 'utils/commonUtils'
import { useStores } from 'utils/hooks/useStores'

const SkillAnalysis = () => {
  const { userProfileStore } = useStores()
  const { averageSkill, skill, profileDetail } = userProfileStore

  useEffect(() => {
    userProfileStore.getAverageSkill()
    userProfileStore.getSkill()
  }, [userProfileStore])

  return (
    <Stack width="100%" direction={{ md: 'column', lg: 'row', sm: 'column' }}>
      <VStack width={{ lg: '80%', md: '100%', sm: '100%' }}>
        <RadarChart data={{ user: skill, average: averageSkill }} />
      </VStack>
      <Stack
        direction={{ lg: 'column', md: 'row', sm: 'column' }}
        width={{ lg: '20%', md: '100%', sm: '100%' }}
        alignItems={{ lg: 'start', md: 'start', sm: 'center' }}
        placeItems="start"
        alignSelf="start"
        justifyContent="space-around"
        paddingTop="40px">
        <Box d="flex" alignItems="center" mb={2}>
          <Box h="12px" w="12px" bg={COLOR.userRadarChart} borderRadius="50%" mr={2} />
          <Text margin="0">{getDisplayName(profileDetail)}</Text>
        </Box>
        <Box d="flex" alignItems="center">
          <Box h="12px" w="12px" bg={COLOR.averageRadarChart} borderRadius="50%" mr={2} />
          <Text margin="0">UDT Average Index</Text>
        </Box>
      </Stack>
    </Stack>
  )
}

export default observer(SkillAnalysis)
