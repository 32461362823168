import { EPaymentCategoryOfProject } from 'constants/enum'

export enum EStatusStyle {
  LOW = 'low',
  STABLE = 'stable',
  HIGH = 'high',
}

// *INFO: https://www.figma.com/file/ZAOTVtOxsw7TQoE81yxihk/UDT-ADMIN?node-id=3336%3A30482&t=54w1c45QvW9WoY20-0
export enum EUserOfProjectRange {
  MIN = 2,
  MAX = 6,
}

// *INFO: https://www.figma.com/file/ZAOTVtOxsw7TQoE81yxihk/UDT-ADMIN?node-id=3336%3A30485&t=twa8hPLMz7zh6YCT-0
export enum EProjectOfUserRange {
  MIN = 2,
  MAX = 3,
}

export interface ISidebarContent {
  project: string
  user: string
  projectName: string
  userName: string
  projectPaymentCategory: EPaymentCategoryOfProject
  information: string
  workingTime: string
  commitTime: string
  userTitle: string
}

export interface ISidebarInfo {
  id: string
  totalWorkingTime: string
  totalWorkingDate: string
  title: string
  content: ISidebarContent[]
}

export interface ITooltipOption {
  color: string
  content: string
}

export const editFormWidth = '125.5px'
