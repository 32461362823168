import { useEffect, useState } from 'react'
import { Stack, HStack, VStack, Container, Text } from '@chakra-ui/react'
import LoadingChakra from 'components/LoadingChakra'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { backgroundGrey100 } from 'theme/globalStyles'
import { useStores } from 'utils/hooks/useStores'
import LogoProject from '../components/LogoProject'
import ProjectDetailForm from '../components/ProjectDetailFormOfUser'
import SettingProject from '../components/SettingProject'

const ProjectDetail = () => {
  const { userProjectStore } = useStores()
  const { projectDetail } = userProjectStore
  const methods = useForm()
  const params = useParams()
  const projectId = get(params, 'id', '')
  const { reset, setValue } = methods
  const [isLoading, setIsLoading] = useState<boolean>(false)

  function getProjectDetailOfUser(): void {
    setIsLoading(true)
    userProjectStore.getProjectDetailOfUser(projectId)
  }

  useEffect(getProjectDetailOfUser, [projectId])

  useEffect(() => {
    if (projectDetail) {
      reset(projectDetail)
      setIsLoading(false)
    }
  }, [projectDetail])

  return (
    <LoadingChakra isLoading={isLoading}>
      <FormProvider {...methods}>
        <form>
          <VStack gap="12px">
            <Container
              background={{ base: backgroundGrey100, lg: 'white' }}
              width="full"
              maxWidth="unset"
              padding={0}
              borderRadius={8}>
              <HStack
                margin={{ sm: '20px 16px 4px ', md: '6px 16px ', lg: 6 }}
                height="full"
                background={{ base: backgroundGrey100, lg: 'white' }}
                justifyContent="space-between">
                <Text fontSize="lg" color="gray.700" margin={0}>
                  {projectDetail ? projectDetail.name : ''}
                </Text>
              </HStack>
            </Container>
            <Stack
              direction={{ base: 'column', lg: 'row' }}
              width="full"
              justifyContent="space-between"
              alignItems="flex-start"
              gap="12px">
              <VStack width="100%">
                <ProjectDetailForm methods={methods} isEdit={false} />
              </VStack>
              <VStack width={{ base: 'full', lg: '300px' }} height="100%" gap="12px">
                <LogoProject
                  type="logo"
                  name="logo"
                  isDetail={true}
                  afterUploadHandler={(value) => {
                    setValue('logo', value)
                  }}
                />
                <SettingProject isDisabled={true} />
              </VStack>
            </Stack>
          </VStack>
        </form>
      </FormProvider>
    </LoadingChakra>
  )
}

export default observer(ProjectDetail)
