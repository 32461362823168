export const creatableOption = {
  // Global Config Page - Technology
  // [User Detail Page, Create User Page, Profile Page] - Title & Level
  value: '/creatable-option',
  list: {
    value: '/creatable-option/list',
  },
  listWithFilter: {
    value: '/creatable-option/list-with-filter',
  },
  creatableOptionId: {
    value: (creatableOptionId: string) => `/creatable-option/${creatableOptionId}`,
  },
  updateMany: {
    value: '/creatable-option/update-many',
  },
}
