import { ITableHeader } from 'types/table'

export enum EContractTabs {
  CONTRACT_CREATE = 'Contract Create',
  LABOR_CONTRACT = 'Labor Contract',
  FREELANCER_CONTRACT = 'Freelancer Contract',
  CONFIDENTIALITY_AGREEMENT = 'Confidentiality Agreement',
  CONTRACT_LIST = 'Contract List',
}

export const headerDesktop: ITableHeader[] = [
  {
    Header: 'Full name',
    accessor: 'fullNameVI',
  },
  {
    Header: 'Contract No.',
    accessor: 'number',
  },
  {
    Header: 'Type',
    accessor: 'type',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Languages',
    accessor: 'language',
  },
  {
    Header: '',
    accessor: 'actions',
  },
]

export const headerTabletAndMobile: ITableHeader[] = [
  {
    Header: 'Full name',
    accessor: 'fullNameVI',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: '',
    accessor: 'actions',
  },
]
