import { useEffect } from 'react'
import { Text, VStack, SimpleGrid, Container, FormLabel, FormControl } from '@chakra-ui/react'
import CheckboxFieldWithLabel from 'components/CheckboxFieldWithLabel'
import DatePicker from 'components/DatePicker'
import FormInput from 'components/FormInput'
import GroupChips from 'components/GroupChips'
import GroupRadio from 'components/GroupRadio'
import MultipleSelect from 'components/MultipleSelect'
import SingleSelect from 'components/SingleSelect'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import { Controller, useFormContext } from 'react-hook-form'
import { backgroundGrey100 } from 'theme/globalStyles'
import { IUser } from 'types/user'
import { PaymentCategories, PaymentCategoriesFromClient } from 'constants/defaultOptionList'
import { EPaymentCategoryFromClientOfProject } from 'constants/enum'
import { handleDatePickerChange } from 'utils/dateFormatUtils'
import { createOptionsOfReactSelectFromDB } from 'utils/dropdownOptionUtils'
import AvatarDevGroup from '../ProjectDetailForm/components/avatarDevGroup'
import { getValidDevelopers } from '../ProjectDetailForm/utils'
import styles from './styles.module.scss'

const ProjectEditForm = (props) => {
  const { partnerList, projectTypes, countryList, technologyList, currencyList, methods } = props
  const { control } = useFormContext()
  const title: string = 'Project Detail'
  window.sessionStorage.setItem('subPageTitle', title)
  window.dispatchEvent(new Event('storage'))
  const partnersOptions = createOptionsOfReactSelectFromDB(partnerList)
  const projectTypesOptions = createOptionsOfReactSelectFromDB(projectTypes)
  const countryListOptions = createOptionsOfReactSelectFromDB(countryList)
  const technologyListOptions = [...createOptionsOfReactSelectFromDB(technologyList)]
  const currencyOptions = createOptionsOfReactSelectFromDB(currencyList)
  const { watch, setValue, getValues } = methods
  const listDeveloper = getValues('developerDetailOnProject')
  const validDevelopers: IUser[] = getValidDevelopers(listDeveloper)
  const isCheckUntilNow = watch('untilNow')
  const watchPaymentCategoryFromClient = watch('paymentCategoryFromClient')

  function renderPaymentCategoryFromClientInputField(paymentType: string) {
    switch (paymentType) {
      case EPaymentCategoryFromClientOfProject.TIME_AND_MATERIAL:
        return <FormInput name="clientRate" label="Client Rate" placeholder="Enter Client Rate" type="number" />

      case EPaymentCategoryFromClientOfProject.MONTHLY_FIXED_SCOPE:
        return (
          <FormInput
            name="billableMonthlyAmount"
            label="Billable Monthly Amount"
            placeholder="Enter Billable Monthly Amount"
            type="number"
          />
        )

      default:
        return ''
    }
  }

  useEffect(() => {
    if (isCheckUntilNow) {
      setValue('endedDate', null)
    }
  }, [isCheckUntilNow])

  useEffect(() => {
    setValue('paymentCategoryFromClient', watchPaymentCategoryFromClient)
  }, [watchPaymentCategoryFromClient])

  return (
    <Container background="white" width="full" maxWidth="unset" padding={0} borderRadius={8}>
      <VStack
        background={{ sm: backgroundGrey100, base: 'white', lg: 'white' }}
        padding={{ md: 0, lg: 6 }}
        borderRadius="8px"
        gap="12px"
        overflowX="hidden">
        <VStack
          background="white"
          paddingLeft={{ lg: '0px', md: '10px', sm: '10px' }}
          paddingBottom="24px"
          borderRadius={{ md: '8px', lg: '0' }}
          className={`${styles.groupInformation} ${styles.border}`}
          gap={{ sm: 0, md: '12px', lg: '12px' }}>
          <Text alignSelf={'flex-start'} className={styles.headerBox}>
            General Information
          </Text>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ sm: 4, md: 6, lg: 8 }} width="full">
            <FormInput name="name" label="Project Name" isRequired placeholder="Enter Project name" />
            <FormInput name="companyName" label="Company Name (Optional)" placeholder="Enter Company name" />
          </SimpleGrid>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ sm: 4, md: 6, lg: 8 }} width="full">
            <FormInput
              name="companyWebsite"
              label="Company Website (Optional)"
              isWebsite
              placeholder="example.com"
              type="tel"
            />
            <FormInput name="companyPhone" label="Company Phone Number (Optional)" type="tel" />
          </SimpleGrid>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ sm: 4, md: 6, lg: 8 }} width="full">
            <SingleSelect
              name="country"
              label="Country"
              placeHolder="- Select country -"
              optionsData={countryListOptions}
              isRequire
            />
            <FormInput name="address" label="Address Detail (Optional)" placeholder="Enter Address detail" />
          </SimpleGrid>
        </VStack>
        <VStack
          background="white"
          paddingLeft={{ lg: '0px', md: '10px', sm: '10px' }}
          paddingBottom="24px"
          borderRadius={{ md: '8px', lg: '0' }}
          className={`${styles.groupInformation} ${styles.border}`}
          gap={{ sm: 0, md: '12px', lg: '12px' }}>
          <Text alignSelf={'flex-start'} className={`${styles.headerBox} ${styles.border}`}>
            Customer Information
          </Text>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ sm: 4, md: 6, lg: 8 }} width="full">
            <FormInput name="customer" label="Customer Name" placeholder="Enter Customer name" isRequired />
            <FormInput name="customerEmail" label="Customer Email (Optional)" placeholder="Enter Customer email" />
          </SimpleGrid>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ sm: 4, md: 6, lg: 8 }} width="full">
            <FormInput name="customerPhone" label="Customer Phone Number (Optional)" />
          </SimpleGrid>
        </VStack>
        <VStack
          background="white"
          paddingLeft={{ lg: '0px', md: '10px', sm: '10px' }}
          paddingBottom="24px"
          borderRadius={{ md: '8px', lg: '0' }}
          className={`${styles.groupInformation} ${styles.border}`}
          gap={{ sm: 0, md: '12px', lg: '12px' }}>
          <Text alignSelf={'flex-start'} className={`${styles.headerBox} ${styles.border}`}>
            Payment From Client
          </Text>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ sm: 4, md: 6, lg: 8 }} width="full">
            <GroupRadio
              name={'paymentCategoryFromClient'}
              isEdit
              data={PaymentCategoriesFromClient}
              label="Category of Payment from Client"
            />
            {renderPaymentCategoryFromClientInputField(watchPaymentCategoryFromClient)}
          </SimpleGrid>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ sm: 4, md: 6, lg: 8 }} width="full">
            <SingleSelect
              name="currency"
              label="Currency"
              placeHolder={'- Select Currency - '}
              optionsData={currencyOptions}
              isRequire={true}
            />
            <FormInput
              name="paymentPeriod"
              label="Payment Period (days)"
              placeholder="Enter Payment Period"
              isRequired
              type="number"
            />
          </SimpleGrid>
        </VStack>
        <VStack
          background="white"
          paddingLeft={{ lg: '0px', md: '10px', sm: '10px' }}
          paddingBottom="24px"
          borderRadius={{ md: '8px', lg: '0' }}
          className={`${styles.groupInformation} ${styles.border}`}
          gap={{ sm: 0, md: '12px', lg: '12px' }}>
          <Text alignSelf={'flex-start'} className={`${styles.headerBox} ${styles.border}`}>
            Payment For Staff
          </Text>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ sm: 4, md: 6, lg: 8 }} width="full">
            <GroupRadio name={'paymentCategory'} isEdit data={PaymentCategories} label="Category of Payment" />
          </SimpleGrid>
        </VStack>
        <VStack
          background="white"
          paddingLeft={{ lg: '0px', md: '10px', sm: '10px' }}
          paddingBottom="24px"
          borderRadius={{ md: '8px', lg: '0' }}
          className={styles.groupInformation}
          gap={{ sm: 0, md: '12px', lg: '12px' }}>
          <Text alignSelf={'flex-start'} className={`${styles.headerBox} ${styles.border}`}>
            Working Information
          </Text>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ sm: 4, md: 6, lg: 8 }} width="full">
            <FormControl>
              <FormLabel fontWeight="400">Start Date</FormLabel>
              <Controller
                name="startedDate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    selectedDate={field.value ? dayjs(field.value).toDate() : undefined}
                    onChange={(date) => handleDatePickerChange(field, date)}
                  />
                )}
              />
            </FormControl>
            {!isCheckUntilNow && (
              <FormControl>
                <FormLabel fontWeight="400">End Date (Optional)</FormLabel>
                <Controller
                  name="endedDate"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      selectedDate={field.value ? dayjs(field.value).toDate() : undefined}
                      onChange={(date) => handleDatePickerChange(field, date)}
                    />
                  )}
                />
              </FormControl>
            )}
            <CheckboxFieldWithLabel name="untilNow" label="Until Now" />
          </SimpleGrid>
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 2 }}
            spacingY="10px"
            gap={{ sm: 4, md: 6, lg: 8 }}
            width="full"></SimpleGrid>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ sm: 4, md: 6, lg: 8 }} width="full">
            <FormInput
              name="commitedHours"
              label="Commited Hours (hrs/week) (Optional)"
              placeholder="Enter Commited hours"
              type="number"
            />
            <VStack padding={{ lg: '0px 0px 10px 0px', md: '10px 30px 10px 10px', sm: '10px 30px 10px 10px' }}>
              <MultipleSelect
                name="technology"
                options={technologyListOptions}
                label="Technology (Optional)"
                placeholder="Select Technology"
                selectedEntityName="Technology"
              />
              <VStack alignSelf={'flex-start'}>
                <GroupChips fieldName="technology" />
              </VStack>
            </VStack>
          </SimpleGrid>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ sm: 4, md: 8, lg: 8 }} width="full">
            <FormInput name="neededDeveloperNumber" label="Needed Developer Number (Optional)" type="number" />
            <AvatarDevGroup name="listDeveloper" data={validDevelopers} label="Working Developers" />
          </SimpleGrid>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ sm: 4, md: 6, lg: 8 }} width="full">
            <SingleSelect
              name="partnerId"
              label="Belongs To Partner"
              optionsData={partnersOptions}
              placeHolder={'- Select Partner -'}
              isRequire
            />
            <VStack padding={{ lg: '0px 0px 10px 0px', md: '10px 30px 10px 10px', sm: '10px 30px 10px 10px' }}>
              <MultipleSelect
                name="type"
                label="Project Type (Optional)"
                placeholder="Select Project Type"
                options={projectTypesOptions}
                menuPlacement="top"
                selectedEntityName="Project Types"
              />
              <VStack alignSelf={'flex-start'}>
                <GroupChips fieldName="type" defaultColor="gray.500" />
              </VStack>
            </VStack>
          </SimpleGrid>
        </VStack>
      </VStack>
    </Container>
  )
}
export default observer(ProjectEditForm)
