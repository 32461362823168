import apiV2 from 'API/api'
import { APIRouters } from 'API/APIRouters'
import { IFCMDataRequest } from './constants'

export async function enablePushNotificationAPI(data: IFCMDataRequest): Promise<void> {
  try {
    const url: string = APIRouters.fcm.enable.value
    await apiV2.post(url, data)
  } catch (error) {
    throw error
  }
}

export async function disablePushNotificationAPI(data: IFCMDataRequest): Promise<void> {
  try {
    const url: string = APIRouters.fcm.disable.value
    await apiV2.post(url, data)
  } catch (error) {
    throw error
  }
}
