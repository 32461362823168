import { useCallback, useEffect, useState } from 'react'
import { useDisclosure } from '@chakra-ui/hooks'
import { HStack, VStack } from '@chakra-ui/react'
import { reInitializeNodesCoordinatesAPI } from 'API/diagram'
import { IDeveloperNodeData, IDiagramOverview, IDiagramQuery, IProjectNodeData } from 'API/diagram/interface'
import { handleError } from 'API/error'
import { createSchema, useSchema } from 'beautiful-react-diagrams'
import { Node, Link, DiagramSchema } from 'beautiful-react-diagrams/@types/DiagramSchema'
import debounce from 'lodash/debounce'
import { observer } from 'mobx-react'
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form'
import { toast } from 'react-toastify'
import { EConditionGetList, EWorkingHourPeriods } from 'constants/enum'
import { isValidArray, getValidArray, getDisplayName } from 'utils/commonUtils'
import { useStores } from 'utils/hooks/useStores'
import { roundNumberToFixedDigits } from 'utils/numberUtils'
import DiagramFilter from './components/DiagramFilter'
import DiagramNode from './components/DiagramNode'
import HeaderSection from './components/HeaderSection'
import NodeDetail from './components/NodeDetail'
import ReInitializeDiagramModal from './components/ReInitializeDiagramModal'
import UncontrolledDiagram from './components/UncontrolledDiagram'
import {
  EDiagramNodeActiveClassNames,
  EDiagramLinkActiveClassNames,
  DELAY_RENDER_TIME,
  initialDiagramQuery,
} from './constant'
import { DiagramContext } from './diagram.context'
import { validateNodesCoordinates } from './utils'

const Diagram = () => {
  const { adminDiagramStore, adminTechnologyStore, adminPartnerStore } = useStores()
  const { partnerNameList } = adminPartnerStore
  const { technologyList } = adminTechnologyStore
  const methods: UseFormReturn = useForm()
  const [schema, { onChange }] = useSchema(
    createSchema({
      nodes: [],
      links: [],
    })
  )
  const [openModalFilter, setOpenModalFilter] = useState<boolean>(false)
  const [isReInitializing, setIsReInitializing] = useState<boolean>(false)
  const [period, setPeriod] = useState<EWorkingHourPeriods>(EWorkingHourPeriods.MONTH)
  const [date, setDate] = useState<string>(new Date().toISOString())
  const [partners, setPartners] = useState<string[]>([])
  const [technologies, setTechnologies] = useState<string[]>([])
  const [isInactive, setIsInactive] = useState<boolean>(false)
  const [keyword, setKeyword] = useState<string>('')
  const {
    isOpen: isOpenReInitializeModal,
    onOpen: openReInitializeModal,
    onClose: closeReInitializeModal,
  } = useDisclosure()
  const handleChangeKeyWord = useCallback(
    debounce((event: { target: { value: string } }) => {
      setKeyword(event?.target?.value ?? '')
    }, 1000),
    []
  )

  function toggleModalFilter(): void {
    setOpenModalFilter((previous) => !previous)
  }

  function toggleNode(nodes: Node<unknown>[], nodeId: string, isUserNode: boolean): void {
    getValidArray(nodes).forEach((node) => {
      if (node.id === nodeId) {
        if (isUserNode) {
          node.className = EDiagramNodeActiveClassNames.USER
        } else {
          node.className = EDiagramNodeActiveClassNames.PROJECT
        }
        adminDiagramStore.setCurrentNode(node)
      } else {
        node.className = ''
      }
    })
  }

  function toggleNodeLinks(links: Link[], nodeId: string, isUserNode: boolean): void {
    const projectNodeLinks: Link[] = []
    const userNodeLinks: Link[] = []
    getValidArray(links).forEach((link) => {
      if (isUserNode) {
        if (link.output === nodeId) {
          link.className = EDiagramLinkActiveClassNames.USER
          userNodeLinks.push(link)
        } else {
          link.className = ''
        }
      } else {
        if (link.input === nodeId) {
          link.className = EDiagramLinkActiveClassNames.PROJECT
          projectNodeLinks.push(link)
        } else {
          link.className = ''
        }
      }
    })
    if (isValidArray(projectNodeLinks)) {
      adminDiagramStore.setCurrentNodeLinks(projectNodeLinks)
    } else {
      adminDiagramStore.setCurrentNodeLinks(userNodeLinks)
    }
  }

  async function fetchDiagramData(query: IDiagramQuery = initialDiagramQuery): Promise<void> {
    try {
      const getInitialLinks = (diagramData: IDiagramOverview): Link[] => {
        const links: Link[] = []
        getValidArray(diagramData?.projects).forEach((project: IProjectNodeData) => {
          getValidArray(project?.developers).forEach((developer: IDeveloperNodeData) => {
            links.push({
              input: project?.diagramNode?.id,
              output: developer?.diagramNode?.id,
              label: `${roundNumberToFixedDigits(developer.averageWorkingTime)} hrs/day`,
              readonly: true,
            })
          })
        })
        return links
      }

      const getInitialNodes = (diagramData: IDiagramOverview): Node<unknown>[] => {
        const projectNodes: Node<unknown>[] = getValidArray(diagramData?.projects).map((project: IProjectNodeData) => ({
          id: project?.diagramNode?.id,
          content: project?.name,
          coordinates: [project?.diagramNode?.xCoordinate, project?.diagramNode?.yCoordinate],
          render: (data) => <DiagramNode nodeData={data} handleClick={handleSelectNode} />,
        }))

        const userNodes: Node<unknown>[] = getValidArray(diagramData?.users).map((user: IDeveloperNodeData) => ({
          id: user?.diagramNode?.id,
          content: getDisplayName(user),
          coordinates: [user?.diagramNode?.xCoordinate, user.diagramNode?.yCoordinate],
          render: (data) => <DiagramNode nodeData={data} isUserNode handleClick={handleSelectNode} />,
        }))
        const nodes = [...projectNodes, ...userNodes]
        return nodes
      }
      const diagramOverview: IDiagramOverview = await adminDiagramStore.fetchDiagramNodeOverview(query)
      const nodes: Node<unknown>[] = getInitialNodes(diagramOverview)
      const isInvalidNodesCoordinates: boolean = validateNodesCoordinates(nodes)
      if (isInvalidNodesCoordinates) {
        openReInitializeModal()
        return
      }
      const links: Link[] = getInitialLinks(diagramOverview)
      const initialSchema: DiagramSchema<unknown> = createSchema({ links, nodes })
      onChange(initialSchema)

      const handleSelectNode = (nodeId: string, isUserNode: boolean): void => {
        toggleNode(initialSchema.nodes, nodeId, isUserNode)
        toggleNodeLinks(initialSchema.links, nodeId, isUserNode)
        onChange(initialSchema)
      }

      //* INFO: set default selected node and show schema's links
      setTimeout(() => {
        onChange(initialSchema)
      }, DELAY_RENDER_TIME)
    } catch (error) {
      handleError(error as Error, 'src/containers/Diagram/index.tsx', 'fetchDiagramData')
    }
  }

  async function fetchFilterData(): Promise<void> {
    await Promise.all([
      adminPartnerStore.getAllNamePartnerList(EConditionGetList.ACTIVE),
      adminTechnologyStore.getTechnologyList(),
    ])
  }

  async function reInitializeDiagram(): Promise<void> {
    try {
      setIsReInitializing(true)
      await reInitializeNodesCoordinatesAPI()
      await fetchDiagramData()
      toast.success('Re-initialize diagram successfully')
      closeReInitializeModal()
    } catch (error) {
      toast.error('Re-initialize diagram failed')
      handleError(error as Error, 'src/containers/Diagram/index.tsx', 'reInitializeDiagram')
    } finally {
      setIsReInitializing(false)
    }
  }

  function setQueryParams(query: IDiagramQuery): void {
    const { period, date, partners, technologies, isInactive } = query
    setPeriod(period)
    setDate(date)
    setPartners(partners)
    setTechnologies(technologies)
    setIsInactive(isInactive)
  }

  useEffect(function fetchData() {
    adminDiagramStore.setCurrentNodeLinks([])
    adminDiagramStore.setCurrentNode(null)
    fetchDiagramData()
    fetchFilterData()
  }, [])

  return (
    <DiagramContext.Provider
      value={{
        fetchDiagramData,
      }}>
      <VStack paddingInline={6} spacing={0}>
        <FormProvider {...methods}>
          <DiagramFilter
            openModalFilter={openModalFilter}
            toggleModalFilter={toggleModalFilter}
            technologyList={technologyList}
            partnerList={partnerNameList}
            setQueryParams={setQueryParams}
          />
          <VStack
            alignItems="flex-start"
            spacing={0}
            width="100%"
            background="white"
            boxShadow="0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);"
            borderRadius="6px"
            padding={6}
            gap={6}>
            <HeaderSection toggleModalFilter={toggleModalFilter} onKeyWordChange={handleChangeKeyWord} />
            <HStack alignItems="flex-start" width="100%" spacing={6}>
              <UncontrolledDiagram schema={schema} onChange={onChange} />
              <NodeDetail schema={schema} onChange={onChange} />
            </HStack>
          </VStack>
        </FormProvider>
        <ReInitializeDiagramModal
          isOpen={isOpenReInitializeModal}
          onClose={closeReInitializeModal}
          onConfirm={reInitializeDiagram}
          isLoading={isReInitializing}
        />
      </VStack>
    </DiagramContext.Provider>
  )
}

export default observer(Diagram)
