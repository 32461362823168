import { IContractDetail } from 'API/contract/constant'
import capitalize from 'lodash/capitalize'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import omit from 'lodash/omit'
import omitBy from 'lodash/omitBy'
import { toJS } from 'mobx'
import moment from 'moment'
import { IOption } from 'types/creatableOption'
import { ITechnology } from 'types/technology'
import { IProjectsInfo, IUser } from 'types/user'
import { activities } from 'constants/activityInfos'
import { EUserEducation, EGender } from 'constants/enum'
import { IContract, IPartner, IProject, IProjectType } from 'constants/schema'
import { getValidArray, parseJson } from 'utils/commonUtils'
import { getFormattedDateTimeInEnglish } from 'utils/dateFormatUtils'
import { getFormattedDateWith_MMMDDYYYY, getFormattedDateWith_YYYYMMDD } from 'utils/dateFormatUtils'
import { getValueFromOption } from 'utils/dropdownOptionUtils'
import { getFormattedNumber, getFormattedNumberOfChartData, getValue, convertToNumber } from 'utils/numberUtils'
import { getProjectsInfoV2 } from './user'

export function parsedItemTimeSheetV2(items, editHandler) {
  return getValidArray(items).map((item) => {
    if (!(item.start_time instanceof moment)) {
      item.start_time = moment(item.startTime)
    }
    if (!(item.end_time instanceof moment)) {
      item.end_time = moment(item.endTime)
    }
    item.itemTouchSendsClick = true
    item.itemProps = {
      onDoubleClick: () => editHandler(item.id),
    }
    return item
  })
}

export function reduceDataOfRevenueChart(rawData) {
  const formattedData = rawData.map((data) => {
    return {
      date: data._id.startTime,
      salary: getFormattedNumber(data.salaryTotal, 0),
      revenue: getFormattedNumber(data.revenueTotal, 0),
      profit: getFormattedNumberOfChartData(data.revenueTotal - data.salaryTotal, 0),
    }
  })
  return formattedData
}

export function reduceDataOfRevenueChartOfMonthlyFixedScope(rawData) {
  const formattedData = rawData.map((data) => {
    let profit = 0
    if (data.salaryTotal && data.fixedRevenuePerDay) {
      profit = convertToNumber(data.fixedRevenuePerDay) - convertToNumber(data.salaryTotal)
    }
    return {
      date: data._id.startTime,
      salary: getFormattedNumber(data.salaryTotal, 0),
      revenue: getFormattedNumber(data.fixedRevenuePerDay, 0),
      profit: getFormattedNumber(profit, 0),
    }
  })
  return formattedData
}

export function reduceDataOfChart(rawData, period, date, developer) {
  let initialData = []
  const startDayOfPeriod = moment(date).startOf(period)
  const endDayOfPeriod = moment(date).endOf(period)
  const duration = Math.ceil(moment.duration(endDayOfPeriod.diff(startDayOfPeriod)).asDays())
  switch (period) {
    case 'week': {
      for (let day = 0; day < 7; day++) {
        let currentDay = moment(startDayOfPeriod.add(day, 'days'))
        initialData.push({
          date: currentDay,
          totalTime: 0,
        })
      }
      break
    }
    case 'month': {
      for (let day = 1; day <= duration; day++) {
        let currentDay
        if (period === 'week') {
          day = startDayOfPeriod.daysInMonth()
        }
        currentDay = moment(date).date(day).format('YYYY-MM-DD')
        initialData.push({
          date: currentDay,
          totalTime: 0,
        })
      }
      break
    }
    default:
      return null
  }
  const processedData = initialData.map((item) => {
    const newData = rawData.find((data) => {
      return data.date === item.date
    })
    item.developerLevel = developer.level
    item.developer = developer.name
    item.formattedTotalTime = `0 hours 0 minutes`
    if (newData) {
      const minutesTotal = newData.totalTime * 60
      const hours = Math.floor(minutesTotal / 60)
      const theRestOfMinutes = minutesTotal - hours * 60
      let theFormattedRestOfMinutes = 0
      if (theRestOfMinutes > 0) {
        theFormattedRestOfMinutes = getFormattedNumber(theRestOfMinutes, 0)
      }
      item.totalTime = newData.totalTime
      item.formattedTotalTime = `${hours} hours ${theFormattedRestOfMinutes} minutes`
      item.tasksName = newData.taskName.join(', ').slice(0, 60) + '...'
      item.totalSalary = newData.totalSalary
      item.totalRevenue = newData.totalRevenue
      item.totalProfit = newData.totalProfit
    }
    return item
  })
  return processedData
}

export function formattedExportHeader(paymentCategory, headers, isShowPrice) {
  let newHeaders = headers
  if (paymentCategory === 'Fixed Price') {
    // replace Hourly Rate by Remuneration
    newHeaders = headers.map((header) => {
      if (header.value === 'defaultRating') {
        header = { label: 'Remuneration', value: 'remuneration' }
      }
      return header
    })
  } else {
    // replace Remuneration by Hourly Rate
    newHeaders = headers.map((header) => {
      if (header.value === 'remuneration') {
        header = { label: 'Hourly Rate', value: 'defaultRating' }
      }
      return header
    })
  }
  if (!isShowPrice) {
    const removeList = ['remuneration', 'rateExchange', 'defaultRating', 'salary', 'totalSalary']
    newHeaders = headers.filter((header) => !removeList.some((item) => item === header.value))
  }
  return newHeaders
}

export function formattedExportData(data, isShowPrice, anonymous) {
  let workingTimeOfFresher = 0,
    workingTimeOfJunior = 0,
    workingTimeOfSenior = 0,
    workingTimeOfLeader = 0
  const formattedData = data.map((item) => {
    const paymentCategory = item.paymentCategory
    const itemDataArray = item.data
    let totalSalary = 0
    let totalWorkingTime = 0
    const formattedItemData = itemDataArray.map((itemData) => {
      const salaryValue = getValue(itemData.salary)
      totalSalary += salaryValue
      const totalWorkingValue = getValue(itemData.timeTotal)
      totalWorkingTime += totalWorkingValue
      let neededItem = {
        taskName: itemData.taskName,
        description: itemData.description,
        startTime: getFormattedDateTimeInEnglish(itemData.startTime),
        endTime: getFormattedDateTimeInEnglish(itemData.endTime),
        timeTotal: getFormattedNumber(itemData.timeTotal),
      }
      if (isShowPrice) {
        if (paymentCategory === 'Fixed Price') {
          neededItem['remuneration'] = itemData.remuneration
        } else {
          neededItem['defaultRating'] = itemData.defaultRating
        }
        neededItem.rateExchange = itemData.rateExchange
        neededItem.salary = itemData.salary
      }
      return neededItem
    })
    if (isShowPrice) {
      formattedItemData.push({
        totalSalary: totalSalary,
      })
    }
    formattedItemData.push({
      totalWorkingTime: totalWorkingTime,
    })
    switch (item.level) {
      case 'fresher': {
        workingTimeOfFresher += totalWorkingTime
        break
      }
      case 'junior': {
        workingTimeOfJunior += totalWorkingTime
        break
      }
      case 'senior': {
        workingTimeOfSenior += totalWorkingTime
        break
      }
      case 'lead': {
        workingTimeOfLeader += totalWorkingTime
        break
      }
      default: {
        break
      }
    }
    return {
      developer: item?.developer?.fullName,
      project: item.project,
      data: formattedItemData,
    }
  })
  if (!anonymous) {
    formattedData.push({
      type: 'totalSheet',
      data: [
        {
          fresher: workingTimeOfFresher,
          junior: workingTimeOfJunior,
          senior: workingTimeOfSenior,
          lead: workingTimeOfLeader,
        },
      ],
    })
  }

  return formattedData
}

export function formattedNotificationData(data, role) {
  return data.map((item) => {
    let content
    const activityInfo = activities[item.type]
    let attributeContent = `notificationContentOf_${role}`
    switch (item.objectType) {
      case 'timesheet': {
        content = activityInfo[attributeContent]
        break
      }
      case 'user': {
        content = activityInfo['notificationContentOf_broadCast']
        break
      }
      case 'project': {
        content = activityInfo['notificationContentOf_broadCast']
        break
      }
      case 'information': {
        content = activityInfo['notificationContentOf_broadCast']
        break
      }
      case 'policy': {
        content = activityInfo['notificationContentOf_broadCast']
        break
      }
      case 'setting': {
        content = activityInfo['notificationContentOf_admin']
        break
      }
      case 'feedback': {
        content = activityInfo[attributeContent]
        break
      }
      default:
        return null
    }
    return {
      _id: item._id,
      image: item.image,
      name: item.name,
      itemId: item.itemId,
      content: content,
      createdAt: item.createdAt,
      objectType: item.objectType,
      isRead: item.isRead,
    }
  })
}

export function getParsedUserValueBeforeSendBE(data) {
  const parsedValues = {
    ...data,
    userData: parseJson(data.userData as string),
  }
  if (!parsedValues) {
    return null
  }
  if (data?.titleId?.value) {
    parsedValues.titleId = get(data, 'titleId.value', '')
  } else {
    delete parsedValues?.titleId
  }
  if (data?.levelId?.value) {
    parsedValues.levelId = get(data, 'levelId.value', '')
  } else {
    delete parsedValues?.levelId
  }
  if (data?.paymentCategoryId?.value) {
    parsedValues.paymentCategoryId = get(data, 'paymentCategoryId.value', '')
  } else {
    delete parsedValues?.paymentCategoryId
  }
  if (data?.partnerId) {
    parsedValues.partnerId = get(data, 'partnerId.value', '')
  }
  if (data?.currentGender?.value) {
    parsedValues.gender = data?.currentGender?.value ?? ''
  }
  if (data?.education?.value) {
    parsedValues.education = get(data, 'education.value', '')
  } else {
    delete parsedValues?.education
  }
  if (data?.role) {
    parsedValues.role = get(data, 'role', '')
  }
  if (data?.isSupervisor) {
    const currentData = get(data, 'isSupervisor', '')
    if (capitalize(currentData) === 'Yes') {
      parsedValues.isSupervisor = true
    } else if (capitalize(currentData) === 'No') {
      parsedValues.isSupervisor = false
    } else {
      parsedValues.isSupervisor = currentData
    }
  }
  if (data?.allowToDownloadCV) {
    const currentData = get(data, 'allowToDownloadCV', '')
    if (currentData === 'Yes') {
      parsedValues.allowToDownloadCV = true
    } else if (currentData === 'No') {
      parsedValues.allowToDownloadCV = false
    } else {
      parsedValues.allowToDownloadCV = currentData
    }
  }
  if (data?.technologies) {
    parsedValues.technologies = getValueFromOption(data.technologies)
  }
  if (data?.projects) {
    parsedValues.projects = getValueFromOption(data.projects)
  }
  if (data?.extraProjects) {
    parsedValues.extraProjects = getValueFromOption(data.extraProjects)
  }
  if (data?.projectsOfSupervisor) {
    parsedValues.projectsOfSupervisor = getValueFromOption(data.projectsOfSupervisor)
  }
  if (data?.externalProjects) {
    parsedValues.externalProjects = getValueFromOption(data.externalProjects)
  }
  if (!parsedValues.partnerId) {
    delete parsedValues.partnerId
  }
  if (!parsedValues.gender) {
    delete parsedValues.gender
  }
  if (data?.IDIssuePlace) {
    parsedValues.IDIssuePlace = data?.IDIssuePlace?.value ?? data?.IDIssuePlace ?? ''
  }
  delete parsedValues.IDInformation
  return parsedValues
}

export function getParsedProjectValueBeforeSendBE(data) {
  const parsedValues = {
    ...data,
  }
  if (!parsedValues) {
    return null
  }
  if (data?.country) {
    parsedValues.country = get(data, 'country.value', '') || null
  }
  if (data?.currency) {
    parsedValues.currency = get(data, 'currency.value', '') || null
  }
  if (data?.paymentPeriod) {
    parsedValues.paymentPeriod = get(data, 'paymentPeriod', '')
  }
  if (data?.type) {
    const newTypeList = getValidArray(data.type).filter((item: IOption) => {
      return item.value !== 'all'
    })
    parsedValues.type = newTypeList.map((item: IOption) => item.value)
  }
  if (data?.paymentCategoryId) {
    parsedValues.paymentCategoryId = get(data, 'paymentCategoryId.value', '') || null
  }
  if (data?.partnerId) {
    parsedValues.partnerId = get(data, 'partnerId.value', '') || null
  }
  if (data?.technology) {
    const newTechnologyList = getValidArray(data.technology).filter((item: IOption) => {
      return item.value !== 'all'
    })
    parsedValues.technology = newTechnologyList.map((item: IOption) => item.value)
  }
  if (data?.userId) {
    parsedValues.userId = getValueFromOption(data.userId)
  }

  const filterNullValue: IProject = omitBy(parsedValues, isNil)
  return omit(filterNullValue, [
    'currencyId',
    'countryId',
    'projectTypeId',
    'partner',
    'technologyOnProjectData',
    'projectTypeData',
    'listDeveloper',
    'developerDetailOnProject',
    'untilNowProject',
    'workingDeveloperNumber',
    parsedValues.budgetProject === 'N/A' ? 'budgetProject' : '',
  ])
}

export function getParsedProjectValueBeforeRenderFE(currentProjectData, isEdit) {
  if (!currentProjectData) {
    return null
  }
  const paymentCategory = currentProjectData?.paymentCategory
  const partner = currentProjectData?.partner
  const country = currentProjectData?.countryId
  const currency = currentProjectData?.currencyId
  const developersList = []
  getValidArray(currentProjectData?.developerDetailOnProject).forEach((item: IUser) =>
    developersList.push({
      label: item.fullName,
      value: item._id,
    })
  )
  const technologiesList = []
  getValidArray(currentProjectData?.technologyOnProjectData).forEach((item: ITechnology) => {
    if (item) {
      technologiesList.push({
        label: item.value,
        value: item._id,
        color: item.tagColor,
      })
    }
  })
  const typeList = []
  getValidArray(currentProjectData?.projectTypeData).forEach((item: IProjectType) => {
    if (item) {
      typeList.push({
        label: item.value,
        value: item._id,
      })
    }
  })
  const partnerFieldValue = partner && partner?.fullName + ' | ' + capitalize(partner?.category)
  let newCurrency = currency?.value
  if (isEdit) {
    newCurrency = currency?.value ? { label: currency.value, value: currency._id } : null
  }
  const currentProject = {
    ...currentProjectData,
    country: isEdit ? { label: country?.value ?? '', value: country?._id ?? '' } : country?.value,
    currency: newCurrency,
    type: typeList,
    paymentCategory: isEdit ? paymentCategory : capitalize(paymentCategory),
    partnerId: isEdit ? { label: partner?.fullName ?? '', value: partner?._id ?? '' } : partnerFieldValue,
    startedDate: isEdit
      ? getFormattedDateWith_YYYYMMDD(currentProjectData.startedDate)
      : getFormattedDateWith_MMMDDYYYY(currentProjectData.startedDate ?? ''),
    endedDate: isEdit
      ? getFormattedDateWith_YYYYMMDD(currentProjectData.endedDate)
      : getFormattedDateWith_MMMDDYYYY(currentProjectData.endedDate ?? ''),
    userId: developersList,
    technology: technologiesList,
    listDeveloper: developersList.map((item) => item.avatar),
    untilNowProject: currentProjectData.untilNow ? 'Yes' : 'No',
    budgetProject: currentProjectData?.budgetRemain ?? 'N/A',
  }
  return currentProject
}
export function getParsedPartnerValueBeforeSendBE(data) {
  const parsedValues = {
    ...data,
  }
  if (!parsedValues) {
    return null
  }
  if (data?.category) {
    parsedValues.category = get(data, 'category.value', '')
  }
  if (data?.type) {
    parsedValues.type = get(data, 'type', '')
  }
  if (data?.levelId) {
    parsedValues.levelId = { value: get(data, 'levelId.value', '') }
  }
  if (data?.titleId) {
    parsedValues.titleId = { value: get(data, 'titleId.value', '') }
  }
  return parsedValues
}
export function getParsedPartnerValueBeforeRenderFE(currentPartnerData, isEdit) {
  if (!currentPartnerData) {
    return null
  }
  const category = currentPartnerData?.category
  const currentPartner = {
    ...currentPartnerData,
    category: isEdit ? { label: category ?? '', value: category ?? '' } : category,
  }
  return currentPartner
}

function convertRawDataOfProjectV2(allProjectList, projectListOfUser) {
  const projectIdListOfUser = getValidArray(projectListOfUser).map((item) => item.project)
  const newCommonItems = allProjectList
    .map((item1) => {
      if (projectIdListOfUser.includes(item1.id)) {
        return item1
      }
      return null
    })
    .filter((item) => item)
  const parsedArray = newCommonItems.map((commonItem) => {
    return {
      label: commonItem.name,
      value: commonItem.id,
      color: commonItem.color,
    }
  })
  return parsedArray
}

function convertRawDataOfTechnology(allTechnologyList, technologyListOfUser) {
  const technologyIdListOfUser = getValidArray(technologyListOfUser).map((item) => item.technology)
  const newCommonItems = allTechnologyList
    .map((item1) => {
      if (technologyIdListOfUser.includes(item1.id)) {
        return item1
      }
      return null
    })
    .filter((item) => item)
  const parsedArray = newCommonItems.map((commonItem) => {
    return {
      label: commonItem.value,
      value: commonItem.id,
      color: commonItem.tagColor,
    }
  })
  return parsedArray
}

export function formatIDInformation(fields: string[]): string {
  const IDInformation: string[] = getValidArray(fields).filter((field: string) => field)
  return IDInformation.join(' | ')
}

export function getParsedUserValueBeforeRenderFEV2(currentUserData, projectList, isEdit: boolean, technologyList) {
  const title = currentUserData?.title
  const level = currentUserData?.level
  const partner = currentUserData?.partner
  const role = currentUserData?.role
  const gender: EGender = currentUserData?.gender
  const education: EUserEducation = currentUserData?.education
  const paymentCategory = currentUserData?.paymentCategory
  const technologiesData = currentUserData?.userTechnologies
  const projectsInfo: IProjectsInfo = getProjectsInfoV2(currentUserData)
  const projectsOfUser: string[] = projectsInfo?.projects ?? []
  const supervisorProjectsOfUser: string[] = projectsInfo?.supervisorProjects ?? []
  const extraProjectsOfUser: string[] = projectsInfo?.extraProjects ?? []
  const externalProjectsOfUser: string[] = projectsInfo?.externalProjects ?? []
  const isSupervisor = currentUserData?.isSupervisor
  const isActive = currentUserData?.isActive
  const allowToDownloadCV = currentUserData?.allowToDownloadCV
  const IDNumber: string = currentUserData?.IDNumber
  const IDIssueDate: string = getFormattedDateWith_MMMDDYYYY(currentUserData?.IDIssueDate)
  const IDIssuePlace: string = currentUserData?.IDIssuePlace
  const IDInformation: string = formatIDInformation([IDNumber, IDIssueDate, IDIssuePlace])

  const partnerInfo: string = partner?.category
    ? `${partner?.fullName} | ${capitalize(partner?.category)}`
    : partner?.fullName

  const currentUser = {
    profileDetailData: {
      ...currentUserData,
      IDIssuePlace: IDIssuePlace,
      titleId: isEdit ? { label: title?.value ?? '', value: title?._id ?? '' } : title?.value,
      levelId: isEdit ? { label: level?.value ?? '', value: level?._id ?? '' } : level?.value,
      education: isEdit ? { label: education ?? '', value: education ?? '' } : education,
      currentGender: { label: gender ?? '', value: gender ?? '' },
      paymentCategoryId: isEdit
        ? { label: paymentCategory?.value ?? '', value: paymentCategory?._id ?? '' }
        : paymentCategory?.value,
      partner: isEdit ? { label: partner?.value ?? '', value: partner?._id ?? '' } : partner?.value,
      partnerId: isEdit
        ? { label: partner?.fullName ?? '', value: partner?._id ?? '' }
        : partner?.fullName
        ? partnerInfo
        : undefined,
      role: isEdit ? role : role,
      technologies: technologiesData?.length > 0 ? convertRawDataOfTechnology(technologyList, technologiesData) : [],
      projects: projectsOfUser.length > 0 ? convertRawDataOfProjectV2(toJS(projectList), projectsOfUser) : [],
      projectsOfUser: convertRawDataOfProjectV2(toJS(projectList), projectsOfUser),
      projectsOfSupervisor: supervisorProjectsOfUser
        ? convertRawDataOfProjectV2(toJS(projectList), toJS(supervisorProjectsOfUser))
        : [],
      extraProjects:
        extraProjectsOfUser.length > 0 ? convertRawDataOfProjectV2(toJS(projectList), toJS(extraProjectsOfUser)) : [],
      externalProjects:
        externalProjectsOfUser.length > 0
          ? convertRawDataOfProjectV2(toJS(projectList), toJS(externalProjectsOfUser))
          : [],
      dateOfBirth: isEdit
        ? getFormattedDateWith_YYYYMMDD(currentUserData?.dateOfBirth)
        : getFormattedDateWith_MMMDDYYYY(currentUserData?.dateOfBirth),
      IDIssueDate: isEdit
        ? getFormattedDateWith_YYYYMMDD(currentUserData?.IDIssueDate)
        : getFormattedDateWith_MMMDDYYYY(currentUserData?.IDIssueDate),
      joinDate: isEdit
        ? getFormattedDateWith_YYYYMMDD(currentUserData?.joinDate)
        : getFormattedDateWith_MMMDDYYYY(currentUserData?.joinDate),
      partnerCategory: capitalize(partner?.category) ?? '',
      userData: currentUserData?.userData ? JSON.stringify(currentUserData.userData, null, '\t') : null,
      cv: currentUserData?.cv,
      avatar: currentUserData?.avatar,
      isSupervisor: isSupervisor ? 'Yes' : 'No',
      isActive: isActive ? 'Yes' : 'No',
      allowToDownloadCV: allowToDownloadCV ? 'Yes' : 'No',
      IDInformation,
    },
  }
  return currentUser
}

export function getContractDetailFormData(currentContract: IContractDetail): IContract {
  const partner: IPartner | null = currentContract.partnerData?.length > 0 ? currentContract.partnerData[0] : null
  const formData: IContract = {
    ...currentContract,
    partnerId: { label: partner?.fullName, value: partner?._id },
    type: { label: String(currentContract?.type), value: String(currentContract?.type) },
    laborContractCategory: {
      label: String(currentContract?.laborContractCategory),
      value: String(currentContract?.laborContractCategory),
    },
    laborContractTerm: {
      label: String(currentContract?.laborContractTerm),
      value: String(currentContract?.laborContractTerm),
    },
    publishedDate: getFormattedDateWith_YYYYMMDD(currentContract?.publishedDate),
    IDIssueDate: getFormattedDateWith_YYYYMMDD(currentContract?.IDIssueDate),
    workingFromDate: getFormattedDateWith_YYYYMMDD(currentContract?.workingFromDate),
    workingEndDate: getFormattedDateWith_YYYYMMDD(currentContract?.workingEndDate),
    dateOfBirth: getFormattedDateWith_YYYYMMDD(currentContract?.dateOfBirth),
  }
  return formData
}
