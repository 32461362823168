export function getHourlyProjectHeaderList(isMobile: boolean) {
  const headers = isMobile
    ? [
        {
          Header: 'PROJECT',
          accessor: 'projectName',
        },
        {
          Header: 'HOURLY RATE',
          accessor: 'currentHourlyRate',
        },
        {
          Header: '',
          accessor: 'actions',
        },
      ]
    : [
        {
          Header: 'PROJECT',
          accessor: 'projectName',
        },
        {
          Header: 'PAYMENT CATEGORY FROM CLIENT',
          accessor: 'paymentCategoryFromClientOfProject',
        },
        {
          Header: 'CLIENT RATE',
          Cell: ({ row }) =>
            row.original.clientRate ? `${row.original.clientRate} (${row.original.currencyName}/hour)` : '',
        },
        {
          Header: 'BILLABLE MONTHLY AMOUNT',
          Cell: ({ row }) =>
            row.original.billableMonthlyAmount
              ? `${row.original.billableMonthlyAmount} (${row.original.currencyName}/month)`
              : '',
        },
        {
          Header: 'CURRENT',
          columns: [
            {
              Header: 'HOURLY RATE',
              accessor: 'currentHourlyRate',
            },
            {
              Header: 'APPLY DATE',
              accessor: 'currentApplyDate',
            },
          ],
        },
        {
          Header: 'FUTURE',
          columns: [
            {
              Header: 'HOURLY RATE',
              accessor: 'futureHourlyRate',
            },
            {
              Header: 'APPLY DATE',
              accessor: 'futureApplyDate',
            },
          ],
        },
        {
          Header: '',
          accessor: 'actions',
        },
      ]

  return headers
}
