export function getFixedProjectHeaderList(isMobile: boolean) {
  const headers = isMobile
    ? [
        {
          Header: 'PROJECT',
          accessor: 'projectName',
        },
        {
          Header: 'FIXED RATE',
          accessor: 'currentFixedRate',
        },
        {
          Header: '',
          accessor: 'actions',
        },
      ]
    : [
        {
          Header: 'PROJECT',
          accessor: 'projectName',
        },
        {
          Header: 'CURRENT',
          columns: [
            {
              Header: 'FIXED RATE',
              accessor: 'currentFixedRate',
            },
            {
              Header: 'APPLY DATE',
              accessor: 'currentApplyDate',
            },
          ],
        },
        {
          Header: 'FUTURE',
          columns: [
            {
              Header: 'FIXED RATE',
              accessor: 'futureFixedRate',
            },
            {
              Header: 'APPLY DATE',
              accessor: 'futureApplyDate',
            },
          ],
        },
        {
          Header: '',
          accessor: 'actions',
        },
      ]

  return headers
}
