import { useEffect, useState } from 'react'
import { Box, Text, VStack, Tooltip, HStack } from '@chakra-ui/react'
import { ISuccessResponse } from 'API/constants'
import { handleError } from 'API/error'
import { uploadFileCv } from 'API/upload'
import { IUploadFileResponse } from 'API/upload/constants'
import { ReactComponent as CvFile } from 'assets/images/svg/uploadCV.svg'
import CurriculumVitaeDetail from 'components/CurriculumVitaeDetail'
import Icon from 'components/Icon'
import { useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'
import { EUploadFileType } from 'types/upload'
import { Messages } from 'constants/index'
import styles from './index.module.scss'

const EmptyCV = () => {
  const [showButton, setShowButton] = useState(true)
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple: false,
    maxFiles: 1,
    maxSize: 5242880,
    accept: {
      'application/pdf': ['.pdf'],
    },
    onDropRejected: (items) => {
      const file = items[0].file
      if (file.size > 5242880) {
        toast.error('File is too large. Please upload a file smaller than 5MB')
      } else {
        toast.error('Only .pdf files are accepted')
      }
    },
  })

  const [selectedFile, setSelectFile] = useState<File | null>(null)

  function hideButton() {
    setShowButton(false)
  }

  async function uploadCv(formData: FormData): Promise<void> {
    try {
      const response: ISuccessResponse<IUploadFileResponse> = await uploadFileCv(formData, EUploadFileType.CV)
      const messages: string = response.data.messages
      toast.success(messages)
    } catch (error) {
      handleError(
        error,
        'src/containers/Profile/ProfileDetail/components/CurriculumVitae/components/EmptyCV/index.tsx',
        'uploadCv'
      )
    }
  }

  useEffect(() => {
    if (!acceptedFiles[0]) {
      return
    }

    setSelectFile(acceptedFiles[0])

    const formData = new FormData()
    formData.append('myFile', acceptedFiles[0])
    uploadCv(formData)
    const objectUrl = URL.createObjectURL(acceptedFiles[0])
    //* INFO: disable unnecessary eslint rule eslint-disable-next-line consistent-return
    // eslint-disable-next-line consistent-return
    return () => URL.revokeObjectURL(objectUrl)
  }, [acceptedFiles[0]])

  return (
    <Box>
      {!selectedFile ? (
        <VStack>
          <HStack spacing={1}>
            <Text as={'i'} color={'gray.600'}>
              Your CV is missing. Please upload your CV.
            </Text>
            <Tooltip label={Messages.pdfOnlyAcceptanceMessage} color="white" fontSize="sm" hasArrow placement="right">
              <Box textAlign="center" height="20px">
                <Icon iconName="information.svg" size={20} />
              </Box>
            </Tooltip>
          </HStack>
          <section className="container">
            <div {...getRootProps({ className: 'dropzone' })}>
              <VStack spacing={'24'}>
                <input {...getInputProps()} />
                <CvFile />
                <Text>
                  Drag and drop or <Text className={styles.textLink}>Browse your CV</Text>
                </Text>
              </VStack>
            </div>
          </section>
        </VStack>
      ) : (
        <CurriculumVitaeDetail isShowButton={showButton} hideButton={hideButton} cvFilePreview={acceptedFiles[0]} />
      )}
    </Box>
  )
}

export default EmptyCV
