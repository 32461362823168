import { useEffect, useContext, useState } from 'react'
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  DrawerCloseButton,
  useDisclosure,
  HStack,
  Flex,
} from '@chakra-ui/react'
import { createNewAssignmentAPI } from 'API/diagram'
import { IAssignment } from 'API/diagram/interface'
import { handleError } from 'API/error'
import Button from 'components/Button'
import FormInput from 'components/FormInput'
import SingleSelect from 'components/SingleSelect'
import SwitchControl from 'components/SwitchControl'
import { observer } from 'mobx-react'
import { useFormContext, useWatch } from 'react-hook-form'
import { toast } from 'react-toastify'
import { textGrey700, textGrey800, backgroundGrey200 } from 'theme/globalStyles'
import { IOption } from 'constants/common'
import { EConditionGetList, EPaymentCategoryOfProject, EPaymentCategoryFromClientOfProject } from 'constants/enum'
import { projectTypeOptions } from 'containers/Diagram/constant'
import { DiagramContext } from 'containers/Diagram/diagram.context'
import { createOptionsOfReactSelectFromDB } from 'utils/dropdownOptionUtils'
import { useStores } from 'utils/hooks/useStores'
import { INewAssignmentData } from './types'
import {
  getProjectPaymentCategory,
  generateAddAssignmentPayload,
  getProjectPaymentCategoryFromClient,
  getBillableMonthlyAmountValue,
  getClientRateValue,
  getProjectCurrencyName,
} from './utils'

const AddNewAssignmentModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { handleSubmit, control, reset, setValue } = useFormContext()
  const { adminUserStore, adminProjectStore } = useStores()
  const { userNameList } = adminUserStore
  const { projectsListWithoutFilter } = adminProjectStore
  const userOptions: IOption[] = createOptionsOfReactSelectFromDB(userNameList)
  const projectOptions: IOption[] = createOptionsOfReactSelectFromDB(projectsListWithoutFilter)
  const selectedProject: IOption | undefined = useWatch({ name: 'project', control })
  const { fetchDiagramData } = useContext(DiagramContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const projectPaymentCategory: EPaymentCategoryOfProject | undefined = getProjectPaymentCategory(
    selectedProject?.value,
    projectsListWithoutFilter
  )

  const currencyName: string | undefined = getProjectCurrencyName(selectedProject?.value, projectsListWithoutFilter)
  const projectPaymentCategoryFromClient: EPaymentCategoryFromClientOfProject | undefined =
    getProjectPaymentCategoryFromClient(selectedProject?.value, projectsListWithoutFilter)
  const clientRateValue: number = getClientRateValue(selectedProject?.value, projectsListWithoutFilter)

  const billableMonthlyAmountValue: number = getBillableMonthlyAmountValue(
    selectedProject?.value,
    projectsListWithoutFilter
  )

  function renderPaymentCategoryFromClientInputField(paymentType: string) {
    switch (paymentType) {
      case EPaymentCategoryFromClientOfProject.TIME_AND_MATERIAL:
        return (
          <FormInput
            name="clientRate"
            key="clientRateField"
            label={`Client Rate (${currencyName}/hour)`}
            defaultValue={clientRateValue}
          />
        )
      case EPaymentCategoryFromClientOfProject.MONTHLY_FIXED_SCOPE:
        return (
          <FormInput
            name="billableMonthlyAmount"
            key="billableMonthAmountField"
            label={`Billable Monthly Amount (${currencyName}/month)`}
            defaultValue={billableMonthlyAmountValue}
          />
        )

      default:
        return ''
    }
  }

  function handleOnChangeSupervisor(event: React.ChangeEvent<HTMLInputElement>): void {
    setValue('isSupervisor', event.target.checked)
  }

  function closeAddNewAssignmentModal(): void {
    reset({
      projectHourlyRate: '',
      projectClientRate: '',
      commitTime: '',
    })
    onClose()
  }

  async function fetchData(): Promise<void> {
    try {
      await Promise.all([
        adminUserStore.getAllNameUserList(EConditionGetList.ACTIVE),
        adminProjectStore.getProjectListWithoutFilter(),
      ])
    } catch (error) {
      handleError(error, 'src/containers/Diagram/components/AddNewAssignmentModal/index.tsx', 'fetchData')
    }
  }

  async function onSubmit(data: INewAssignmentData): Promise<void> {
    try {
      setIsLoading(true)
      const payload: IAssignment = generateAddAssignmentPayload(data, projectPaymentCategory)
      if (
        (payload?.projectClientRate !== undefined && isNaN(payload?.projectClientRate)) ||
        isNaN(payload?.projectHourlyRate) ||
        isNaN(payload?.commitTime)
      ) {
        throw new Error('Please enter valid number for Project Hourly Rate, Project Client Rate')
      }
      await createNewAssignmentAPI(payload)
      await fetchDiagramData()
      closeAddNewAssignmentModal()
      toast.success('Add new assignment successfully!')
    } catch (error) {
      const errorMessage: string = error?.message ?? ''
      handleError(
        error,
        'src/containers/Diagram/components/AddNewAssignmentModal/index.tsx',
        'onSubmit',
        false,
        !!errorMessage
      )
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <Button variant="solid" onClick={onOpen} leftIcon="connect.svg">
        Assign
      </Button>
      <Modal isOpen={isOpen} onClose={closeAddNewAssignmentModal} isCentered>
        <form>
          <ModalOverlay w="full" h="full">
            <ModalContent w={'80%'} h="full" maxWidth={{ md: '600px' }} maxHeight={{ base: '2xl', md: 'lg' }}>
              <ModalHeader fontSize="lg" fontWeight="medium" color={textGrey800}>
                <DrawerCloseButton cursor="pointer" color={textGrey700} background="white" />
                Add New Assignment
              </ModalHeader>
              <ModalBody p={6} borderTop="1px" borderBottom="1px" borderColor={backgroundGrey200}>
                <Flex gap={6} flexDirection="column">
                  <HStack gap={6} spacing={0} flexDirection={{ base: 'column', md: 'row' }}>
                    <SingleSelect
                      name="user"
                      label="User"
                      placeHolder="Select User"
                      optionsData={userOptions}
                      isRequire
                    />
                    <SingleSelect
                      name="project"
                      label="Project"
                      placeHolder="Select Project"
                      optionsData={projectOptions}
                      isRequire
                    />
                  </HStack>
                  <HStack gap={6} spacing={0} flexDirection={{ base: 'column', md: 'row' }}>
                    <FormInput name="projectHourlyRate" label="Project Hourly Rate ($/hr)" isRequired type="number" />
                    {renderPaymentCategoryFromClientInputField(projectPaymentCategoryFromClient)}
                  </HStack>
                  <HStack gap={6} spacing={0} align="flex-start" flexDirection={{ base: 'column', md: 'row' }}>
                    <FormInput name="commitTime" label="Commit Time (hrs/week)" isRequired type="number" />
                    <SingleSelect
                      name="projectType"
                      optionsData={projectTypeOptions}
                      placeHolder="Type"
                      label="Project Type"
                      isRequire
                    />
                  </HStack>
                  <SwitchControl name="isSupervisor" label="Is Supervisor?" handleOnChange={handleOnChangeSupervisor} />
                </Flex>
              </ModalBody>
              <ModalFooter padding={3} gap="16px">
                <Button isLoading={isLoading} onClick={closeAddNewAssignmentModal} variant="outline">
                  Cancel
                </Button>
                <Button isLoading={isLoading} onClick={handleSubmit(onSubmit)} variant="solid">
                  Add
                </Button>
              </ModalFooter>
            </ModalContent>
          </ModalOverlay>
        </form>
      </Modal>
    </>
  )
}

export default observer(AddNewAssignmentModal)
