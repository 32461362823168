import React from 'react'
import { Box } from '@chakra-ui/react'
import { IPaymentList } from 'types/payment'
import { isValidArray, getValidArray } from 'utils/commonUtils'
import PaymentSection from './components/PaymentSection'
interface IPaymentTableProps {
  paymentList: IPaymentList[]
  editHandler: (id: string, field: string, currentValue: boolean) => Promise<void>
  isAdmin: boolean
  triggerShowRequestPaymentTable?: (x: []) => void
}

interface IValidPayments {
  nonEmptyPayments: IPaymentList[]
  emptyPayments: IPaymentList[]
}

const PaymentTable = (props: IPaymentTableProps) => {
  const { paymentList, editHandler, isAdmin, triggerShowRequestPaymentTable } = props
  const { nonEmptyPayments, emptyPayments } = getValidPayment()

  function getValidPayment(): IValidPayments {
    const nonEmptyPaymentList: IPaymentList[] = []
    const emptyPaymentList: IPaymentList[] = []
    getValidArray(paymentList).forEach((payment: IPaymentList) => {
      const isValidPayment: boolean =
        isValidArray(payment?.paymentData) &&
        (payment?.developerInfo?.totalPayroll !== 0 || payment?.developerInfo?.totalFreelancerFee !== 0)
      if (isValidPayment) {
        nonEmptyPaymentList.push(payment)
      } else {
        emptyPaymentList.push(payment)
      }
    })
    return {
      nonEmptyPayments: nonEmptyPaymentList,
      emptyPayments: emptyPaymentList,
    }
  }

  return (
    <div>
      <Box>
        {getValidArray(nonEmptyPayments).map((payment) => (
          <PaymentSection
            payment={payment}
            isAdmin={isAdmin}
            editHandler={editHandler}
            triggerShowRequestPaymentTable={triggerShowRequestPaymentTable}
          />
        ))}
      </Box>
      <Box background="white">
        {getValidArray(emptyPayments).map((payment) => (
          <PaymentSection
            payment={payment}
            isAdmin={isAdmin}
            editHandler={editHandler}
            triggerShowRequestPaymentTable={triggerShowRequestPaymentTable}
          />
        ))}
      </Box>
    </div>
  )
}

export default PaymentTable
