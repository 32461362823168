import apiV2 from 'API/api'
import { APIRouters } from 'API/APIRouters'
import { ISuccessResponse } from 'API/constants'
import { AxiosResponse } from 'axios'
import { IProject } from 'constants/schema'
import {
  IProjectDetailOfUserResponse,
  IProjectListWithPaginationRequest,
  IProjectDetailResponse,
  IProjectMessageResponse,
  IProjectsOfUserResponse,
  IProjectListWithPaginationResponse,
  IProjectNameListResponse,
} from './constants'

export async function getRemovedProjectsListOfUserAPI(
  userId: string
): Promise<ISuccessResponse<IProjectsOfUserResponse>> {
  try {
    const url = APIRouters.project.listRemovedProjectsOfUser.userId.value(userId)
    return apiV2.get(url)
  } catch (error) {
    throw error
  }
}

export async function getProjectListOfUserWithPaginationAPI(
  payload: IProjectListWithPaginationRequest
): Promise<ISuccessResponse<IProjectListWithPaginationResponse>> {
  try {
    const url = APIRouters.project.listOfUser.value
    return apiV2.post(url, payload)
  } catch (error) {
    throw error
  }
}

export async function getProjectListAPI(): Promise<ISuccessResponse<IProjectsOfUserResponse>> {
  try {
    return apiV2.get(APIRouters.project.list.value)
  } catch (error) {
    throw error
  }
}

export async function getProjectListWithPaginationAPI(
  payload: IProjectListWithPaginationRequest
): Promise<ISuccessResponse<IProjectListWithPaginationResponse>> {
  try {
    const url = APIRouters.project.list.value
    return apiV2.post(url, payload)
  } catch (error) {
    throw error
  }
}

export async function getAllNameProjectListOfAdminAPI(
  condition: string
): Promise<ISuccessResponse<IProjectNameListResponse>> {
  try {
    const url = APIRouters.project.nameListForAdmin.condition.value(condition)
    return apiV2.get(url)
  } catch (error) {
    throw error
  }
}

export async function getAllNameProjectListOfUserAPI(
  condition: string
): Promise<ISuccessResponse<IProjectNameListResponse>> {
  try {
    const url = APIRouters.project.nameListForUser.condition.value(condition)
    return apiV2.get(url)
  } catch (error) {
    throw error
  }
}

export async function getAllNameProjectListOfAGivenUserForAdminAPI(
  condition: string,
  userId: string
): Promise<ISuccessResponse<IProjectNameListResponse>> {
  try {
    const url = APIRouters.project.nameListOfAGivenUserForAdmin.condition_userId.value(condition, userId)
    return apiV2.get(url)
  } catch (error) {
    throw error
  }
}

export async function getNameListForSupervisorAPI(
  condition: string
): Promise<ISuccessResponse<IProjectNameListResponse>> {
  try {
    const url = APIRouters.project.nameListForSupervisor.condition.value(condition)
    const response: ISuccessResponse<IProjectNameListResponse> = await apiV2.get(url)
    return response
  } catch (error) {
    throw error
  }
}

export async function getProjectDetailOfUserV2API(
  projectId: string
): Promise<ISuccessResponse<IProjectDetailOfUserResponse>> {
  try {
    const url = APIRouters.project.detailOfUser.projectId.value(projectId)
    return apiV2.get(url)
  } catch (error) {
    throw error
  }
}

export async function getProjectDetailOfAdminV2API(
  projectId: string
): Promise<ISuccessResponse<IProjectDetailResponse>> {
  try {
    const url = APIRouters.project.detail.projectId.value(projectId)
    return apiV2.get(url)
  } catch (error) {
    throw error
  }
}

export async function createProjectV2API(payload: IProject): Promise<IProjectMessageResponse> {
  try {
    const url = APIRouters.project.create.value
    const response: AxiosResponse = await apiV2.post(url, payload)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function archiveProjectV2API(id: string): Promise<ISuccessResponse<IProjectMessageResponse>> {
  try {
    const url = APIRouters.project.archive.projectId.value(id)
    const response = await apiV2.get(url)
    const message = response?.data?.message
    return message
  } catch (error) {
    throw error
  }
}

export async function unarchiveProjectAPI(projectId: string, userIds: string[]): Promise<ISuccessResponse<string>> {
  try {
    const payload = {
      selectedUserIds: userIds,
    }
    const url = APIRouters.project.unarchive.projectId.value(projectId)
    const response: AxiosResponse = await apiV2.post(url, payload)
    return response?.data?.message
  } catch (error) {
    throw error
  }
}

export async function deleteProjectV2API(projectId: string): Promise<ISuccessResponse<IProjectMessageResponse>> {
  try {
    const url = APIRouters.project.delete.projectId.value(projectId)
    return apiV2.delete(url)
  } catch (error) {
    throw error
  }
}

export async function updateProjectV2API(
  id: string,
  payload: IProject
): Promise<ISuccessResponse<IProjectDetailResponse>> {
  try {
    const url = APIRouters.project.update.projectId.value(id)
    return apiV2.post(url, payload)
  } catch (error) {
    throw error
  }
}
