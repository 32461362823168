import { Image } from '@chakra-ui/react'
import { EUploadedImageType } from 'constants/enum'
import { generateImageURL, getDefaultImageURL } from 'utils/imageUtils'
import { IImageCellProps } from './interfaces'

const ImageCell = (props: IImageCellProps) => {
  const { data, type } = props
  const defaultImageURL = getDefaultImageURL(type)
  switch (type) {
    case EUploadedImageType.LOGO:
      return (
        <Image
          height={'48px'}
          width="48px"
          src={generateImageURL(data, type)}
          alt="project-logo"
          onError={(e) => {
            e.target.src = defaultImageURL
          }}
        />
      )
    case EUploadedImageType.BADGE_IMAGE:
      return (
        <Image
          maxHeight={'30px'}
          src={generateImageURL(data, type)}
          alt="badge-image"
          onError={(e) => {
            e.target.src = defaultImageURL
          }}
        />
      )
    default:
      return <div></div>
  }
}

export default ImageCell
