import omit from 'lodash/omit'
import { IProfileDetailOfDetailForm } from 'types/user'
import { IOption } from 'constants/common'
import { EGender } from 'constants/enum'
import { IProfileForm } from './constant'

export function formatUserProfileDetail(profile: IProfileDetailOfDetailForm): IProfileForm {
  const { bankName = '', gender } = profile
  let formattedBankName: string[] = bankName?.split(' - ')
  let bankShortName: string = ''
  let bankCity: string = ''
  let bankBranch: string = ''
  let bankFullName: string = ''
  if (formattedBankName) {
    bankShortName = formattedBankName[0]
    bankCity = formattedBankName[1]
    bankBranch = formattedBankName[2]
    bankFullName = formattedBankName[3]
  }
  const currentGender: IOption<EGender> = { label: gender, value: gender }
  return { ...profile, bankShortName, bankCity, bankBranch, bankFullName, currentGender }
}

export function formatProfileFormData(data: IProfileForm): IProfileDetailOfDetailForm {
  const { bankShortName, bankCity, bankBranch, bankFullName, currentGender } = data
  const bankName: string = `${bankShortName} - ${bankCity} - ${bankBranch} - ${bankFullName}`
  const isActiveBoolean = data?.isActive === 'Yes' ? true : false

  return {
    ...omit(data, ['bankShortName', 'bankCity', 'bankBranch', 'bankFullName', 'currentGender']),
    bankName,
    gender: currentGender?.label,
    isActive: isActiveBoolean,
  }
}
