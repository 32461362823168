import { Avatar, FormControl, FormLabel, HStack, Tooltip, SimpleGrid, Wrap, WrapItem } from '@chakra-ui/react'
import { IUser } from 'types/user'
import { getValidArray, getDisplayName } from 'utils/commonUtils'
import { generateImageURL } from 'utils/imageUtils'

export interface IAvatarDevGroup {
  name?: string
  data?: IUser[]
  label?: string
}

const AvatarDevGroup = (props: IAvatarDevGroup) => {
  const { name, label, data } = props
  return (
    <FormControl id={name} width="100%" padding={{ lg: '0px', md: '10px', sm: '10px' }}>
      <HStack alignItems={'flex-start'}>
        <FormLabel fontWeight={'normal'} mb={2} color="gray.700">
          {label}
        </FormLabel>
      </HStack>
      <HStack justifyContent="space-between">
        <SimpleGrid columns={{ base: 1, md: 2, lg: 1 }} gap={{ base: 6, lg: 8 }} width="full">
          <Wrap>
            {getValidArray(data).map((item) => (
              <WrapItem key={item._id}>
                <Tooltip label={getDisplayName(item)}>
                  <Avatar
                    height={'24px'}
                    width={'24px'}
                    src={generateImageURL(item.avatar, 'avatar')}
                    _hover={{
                      cursor: 'pointer',
                    }}
                  />
                </Tooltip>
              </WrapItem>
            ))}
          </Wrap>
        </SimpleGrid>
      </HStack>
    </FormControl>
  )
}

export default AvatarDevGroup
