import { useEffect } from 'react'
import { Modal, ModalOverlay } from '@chakra-ui/modal'
import { FormProvider, useForm } from 'react-hook-form'
import { ELeaveStatus, ELeaveType } from 'constants/enum'
import { Messages } from 'constants/index'
import { stripHtmlTags, formatTimeToHourMinute } from 'utils/commonUtils'
import { formatStartEndTime, parseStartEndTime } from '../../../utils'
import { ISummaryLeaveModalProps } from './interface'
import LeaveModalContent from './LeaveModalContent'

const LeaveModal = (props: ISummaryLeaveModalProps) => {
  const { isSummary = false, isEdit = false, isOpen, closeHandler, saveHandler, leaveSelectedData, isLoading } = props
  const methods = useForm()
  const { handleSubmit, setError, reset } = methods

  useEffect(() => {
    if (isEdit && leaveSelectedData?.startEndTime) {
      const { startDate, startTime, endDate, endTime } = parseStartEndTime(leaveSelectedData.startEndTime)

      reset({
        type: leaveSelectedData?.type,
        commitTime: leaveSelectedData?.commitTime,
        startDate,
        startTime,
        endDate,
        endTime,
        content: leaveSelectedData?.content,
      })
    } else {
      reset()
    }
  }, [isOpen, leaveSelectedData, isEdit, reset])

  const onSubmit = async (formData) => {
    const { startDate, startTime, endDate, endTime } = formData

    if (!startDate || !startTime || !endDate || !endTime) {
      if (!startDate) setError('startDate', { type: 'manual', message: Messages.requireStartDate })
      if (!startTime) setError('startTime', { type: 'manual', message: Messages.requireStartTime })
      if (!endDate) setError('endDate', { type: 'manual', message: Messages.requireEndDate })
      if (!endTime) setError('endTime', { type: 'manual', message: Messages.requireEndTime })
      return
    }

    const formattedStartTime = formatTimeToHourMinute(formData.startTime)
    const formattedEndTime = formatTimeToHourMinute(formData.endTime)

    const [startHours, startMinutes] = formattedStartTime.split(':').map(Number)
    const [endHours, endMinutes] = formattedEndTime.split(':').map(Number)

    const startDateTime = new Date(startDate)
    startDateTime.setHours(startHours, startMinutes, 0, 0)
    const endDateTime = new Date(endDate)
    endDateTime.setHours(endHours, endMinutes, 0, 0)

    if (startDateTime > endDateTime) {
      setError('endDate', { type: 'manual', message: Messages.requireStartEndDate })
      setError('endTime', { type: 'manual', message: Messages.requireStartEndTime })
      return
    }

    if (startDateTime.toDateString() === endDateTime.toDateString() && startDateTime >= endDateTime) {
      setError('endTime', { type: 'manual', message: Messages.requireStartEndTime })
      return
    }

    const processedData = {
      commitTime: formData.commitTime || 'N/A',
      content: stripHtmlTags(formData.content) || 'N/A',
      startEndTime: formatStartEndTime(startDate, formattedStartTime, endDate, formattedEndTime),
      status: ELeaveStatus.PENDING,
      type: formData.type || ELeaveType.SICK,
      createdDate: new Date(),
      approver: 'Not yet approved',
    }

    try {
      await saveHandler(processedData)
      reset()
      closeHandler()
    } catch (error) {
      console.error('Error saving leave request:', error)
    }
  }

  function handleClose() {
    reset()
    closeHandler()
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="xl" motionPreset="slideInBottom">
      <ModalOverlay />
      {isSummary ? (
        <LeaveModalContent
          isSummary={isSummary}
          isEdit={isEdit}
          isLoading={isLoading}
          methods={methods}
          leaveSelectedData={leaveSelectedData}
          onClose={handleClose}
        />
      ) : (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <LeaveModalContent
              isSummary={isSummary}
              isEdit={isEdit}
              isLoading={isLoading}
              methods={methods}
              leaveSelectedData={leaveSelectedData}
              onClose={handleClose}
            />
          </form>
        </FormProvider>
      )}
    </Modal>
  )
}

export default LeaveModal
