import { useState } from 'react'
import { handleError } from 'API/error'
import KaChingAudioURL from 'assets/audio/ka-ching.mp3'
import EmptyContentBox from 'components/EmptyContentBox'
import FormModel from 'components/FormModel'
import LoadingChakra from 'components/LoadingChakra'
import { observer } from 'mobx-react'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { Messages } from 'constants/index'
import { isValidArray, getValidArray, getLastDayOfPreviousMonth } from 'utils/commonUtils'
import { useStores } from 'utils/hooks/useStores'
import FilterFormOfPayment from '../components/FilterFormPaymentOfUser'
import PaymentTable from '../components/PaymentTable'
import RequestToChangePaymentModal from '../components/RequestToChangePaymentModal'

const initialValues = {
  project: '',
  date: getLastDayOfPreviousMonth(),
}

const validationSchema = Yup.object().shape({
  date: Yup.string().required(Messages.fieldRequired),
})

interface IPaymentProps {}

const Payment = (props: IPaymentProps) => {
  const kaChingAudio = new Audio(KaChingAudioURL)
  const { userPaymentStore } = useStores()
  const { payments, filterPayload } = userPaymentStore
  const [isOpenOfChangeRequestModal, setOpenChangeRequestModal] = useState(false)
  const [requestedPaymentId, setRequestPaymentId] = useState('')

  function playKaChingAudio() {
    kaChingAudio.play()
  }

  function closeChangeRequestModalHandler() {
    setOpenChangeRequestModal(false)
  }

  async function handleEditPayment(id: string, field: string, currentValue): Promise<void> {
    try {
      if (field === 'isReceived' && !currentValue) {
        await userPaymentStore.editPayment(id, field, currentValue)
        toast.success(Messages.editRequestPaymentSuccess)
        await userPaymentStore.filterPayments(filterPayload)
        playKaChingAudio()
      } else {
        setOpenChangeRequestModal(true)
        setRequestPaymentId(id)
      }
    } catch (error) {
      toast.error(Messages.editRequestPaymentFailed)
      handleError(error, 'src/containers/Payment/Payment/index.tsx', 'handleEditPayment')
    }
  }

  async function handleSendRequest(reason: string): Promise<void> {
    try {
      await userPaymentStore.sendRequest(requestedPaymentId, reason)
      setOpenChangeRequestModal(false)
      toast.success(Messages.sendRequestToEditPaymentSuccess)
    } catch (error) {
      toast.error(Messages.sendRequestToEditPaymentFailed)
      handleError(error, 'src/containers/Payment/Payment/index.tsx', 'handleSendRequest')
    }
  }

  return (
    <div className="timesheet-page container">
      <div>
        <div>
          <FormModel
            initialValues={initialValues}
            schema={validationSchema}
            handleSubmit={userPaymentStore.filterPayments.bind(userPaymentStore)}
            component={<FilterFormOfPayment isPaymentsLoading={userPaymentStore.isPaymentsLoading} {...props} />}
          />
        </div>
        <LoadingChakra isLoading={userPaymentStore.isPaymentsLoading}>
          {getValidArray(payments).length === 0 ? (
            <EmptyContentBox text={Messages.emptyBoxFilterMessage} position="bottom" />
          ) : (
            <div className="timesheet-content">
              <div className="container-fluid">
                <div className="box box-default">
                  <div className="box-header">Payment List</div>
                  <div className="box-divider" />
                  <div className={`box-body`}>
                    {isValidArray(payments) && (
                      <PaymentTable paymentList={payments} editHandler={handleEditPayment} isAdmin={false} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </LoadingChakra>
        <RequestToChangePaymentModal
          isOpen={isOpenOfChangeRequestModal}
          onClose={closeChangeRequestModalHandler}
          sendHandler={handleSendRequest}
        />
      </div>
    </div>
  )
}

export default observer(Payment)
