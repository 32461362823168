import { Td } from '@chakra-ui/react'
import ImageCell from 'components/Table/DesktopTable/components/ImageCell'
import { Cell as ICell } from 'react-table'
import { EUploadedImageType } from 'constants/enum'

interface ICellProps {
  cell: ICell
}

export enum HEADER {
  BADGE_IMAGE = 'BADGE IMAGE',
}

const Cell = (props: ICellProps) => {
  const { cell } = props
  const header = cell?.column?.Header ?? ''
  let cellContent = cell.render('Cell')

  if (header === HEADER.BADGE_IMAGE) {
    cellContent = <ImageCell data={cell?.value} type={EUploadedImageType.BADGE_IMAGE} />
  }

  return (
    <Td {...cell.getCellProps()} py={['3px', 2]} px={['2px', 2]}>
      {cellContent}
    </Td>
  )
}
export default Cell
