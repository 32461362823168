import { useEffect } from 'react'
import { HStack, VStack, Container, Text } from '@chakra-ui/react'
import { handleError } from 'API/error'
import Button from 'components/Button'
import { observer } from 'mobx-react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { frontendRoutes } from 'constants/routes'
import { IProject } from 'constants/schema'
import { useStores } from 'utils/hooks/useStores'
import CreateProjectForm from '../components/CreateProjectForm'
import LogoProject from '../components/LogoProject'
import SettingProject from '../components/SettingProject'

const ProjectCreate = () => {
  const { adminProjectStore, adminProjectTypeStore, adminTechnologyStore, adminCountryStore, adminCurrencyStore } =
    useStores()
  const { partnerList } = adminProjectStore
  const { countryList } = adminCountryStore
  const { technologyList } = adminTechnologyStore
  const { projectTypeList } = adminProjectTypeStore
  const { currencyList } = adminCurrencyStore
  const methods = useForm()
  const history = useHistory()
  const { handleSubmit, reset, setValue } = methods
  const partnerCategory: string[] = ['owner', 'client']

  window.localStorage.removeItem('pageTitle')
  window.localStorage.setItem('pageTitle', 'Projects')
  useEffect(() => {
    adminProjectStore.getPartnerList(partnerCategory)
    adminTechnologyStore.getTechnologyList()
    adminProjectTypeStore.getProjectTypeList()
    adminCurrencyStore.getCurrencyList()
    adminCountryStore.getCountryList()
  }, [])

  function resetForm() {
    reset()
    history.push(frontendRoutes.projectPage.projectAdmin.list.value)
  }
  async function submitHandler(data: IProject) {
    try {
      await adminProjectStore.createProject(data)
      history.push(frontendRoutes.projectPage.projectAdmin.list.value)
    } catch (error) {
      handleError(error, 'src/containers/Projects/ProjectCreate/index.tsx', 'submitHandler')
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit((data) => submitHandler(data))}>
        <VStack>
          <Container background="white" width="full" maxWidth="unset" padding={0} borderRadius={8}>
            <HStack margin={{ base: 6, lg: 6 }} height="full" background="white" justifyContent="space-between">
              <Text fontSize="lg" color="gray.700" margin={0}>
                New Project
              </Text>
              <HStack>
                <Button variant="outline" onClick={resetForm}>
                  Cancel
                </Button>
                <Button variant="solid" type="submit">
                  Save
                </Button>
              </HStack>
            </HStack>
          </Container>

          <HStack width="full" justifyContent="space-between" alignItems="flex-start">
            <VStack width="100%">
              <CreateProjectForm
                partnerList={partnerList}
                projectTypes={projectTypeList}
                technologyList={technologyList}
                currencyList={currencyList}
                countryList={countryList}
                methods={methods}
              />
            </VStack>
            <VStack width="300px" height="100%">
              <LogoProject
                type="logo"
                name="logo"
                afterUploadHandler={(value: string) => {
                  setValue('logo', value)
                }}
              />
              <SettingProject isDisabled={false} />
            </VStack>
          </HStack>
        </VStack>
      </form>
    </FormProvider>
  )
}

export default observer(ProjectCreate)
