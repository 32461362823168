import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import Button from 'components/Button'
import ShowPDF from 'components/ShowPDF'
interface IViewContractModal {
  isOpen: boolean
  content
  fileName?: string
  onClose: () => void
}
const ViewContractModal = (props: IViewContractModal) => {
  const { isOpen, onClose, content, fileName } = props
  return (
    <Modal onClose={onClose} size="full" isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{fileName}</ModalHeader>
        <ModalCloseButton color="gray.500" />
        <ModalBody>
          <ShowPDF content={content} />
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ViewContractModal
