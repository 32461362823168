import { Box } from '@chakra-ui/react'
import cx from 'classnames'
import { getValidArray } from 'utils/commonUtils'
import styles from '../StatusCell/statusCell.module.scss'
import { ITagListCellProps } from './interfaces'

const TagListCell = (props: ITagListCellProps) => {
  const { values } = props
  return (
    <>
      {getValidArray(values).map(
        (item) =>
          item && (
            <Box
              key={item?._id}
              borderColor={item?.tagColor}
              bgColor={item?.tagColor?.substring(0, item?.tagColor?.length - 1)}
              color={item?.tagColor}
              className={cx({
                [styles.statusCell]: true,
                [styles['Technology']]: 'Technology' ? true : false,
              })}>
              {item?.value}
            </Box>
          )
      )}
    </>
  )
}

export default TagListCell
