import React from 'react'
import moment from 'moment'
import * as Yup from 'yup'
import omit from 'lodash/omit'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import set from 'lodash/set'
import queryString from 'query-string'
import { handleError } from 'API/error'
import { getAllNameProjectListOfAdminAPI } from 'API/project'
import { getBillReportAdminListAPI } from 'API/report'
import { isValidArray } from 'utils/commonUtils'
import { formattedExportHeader, formattedExportData } from 'utils/formatDataUtils'
import { Messages } from 'constants/index'
import { EConditionGetList, ETimesheetSubTitle } from 'constants/enum'
import EmptyContentBox from 'components/EmptyContentBox'
import LoadingChakra from 'components/LoadingChakra'
import FormModel from 'components/FormModel'
import {
  ExportCSVFileHeaders,
  ExportCSVFileHeadersOfTotalSheet,
} from '../../../../constants/defaultOptionList'
import BillReportTable from '../../components/BillReportTable'
import ReportTable from '../../components/BillReportTable/index'
import FilterForm from '../../components/FilterForm/FilterFormOfBillReport'
import ExportData from '../../components/ExportData'
import { checkReportData, checkSummaryData } from './container'

const today = moment().format('YYYY-MM-DD')

const validationSchema = Yup.object().shape({
  project: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .required(Messages.fieldRequired),
  date: Yup.string(),
})

class BillReport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      projects: [],
      billReportList: [],
      paymentCategory: undefined,
      isShowPriceInExportFile: false,
      exportDataList: [],
      dateOfReport: undefined,
      summaryReport: undefined,
      isBillReportLoading: false,
      initialValues: {
        project: '',
        period: 'month',
        date: today,
        viewHourlyMode: true,
        anonymous: false,
        showPrice: false,
        current: true,
      },
    }
  }

  componentDidMount() {
    this.handleDidMount()
  }

  handleDidMount = () => {
    const init = async () => {
      try {
        const { history } = this.props
        window.sessionStorage.setItem('subPageTitle', ETimesheetSubTitle.BILL_REPORT)
        window.dispatchEvent(new Event('storage'))

        const response = await getAllNameProjectListOfAdminAPI(EConditionGetList.ALL)
        const projects = response?.data?.projects
        this.setState({ projects })

        const query = history.location.search

        if (query) {
          const parsedQuery = queryString.parse(query)

          if (parsedQuery) {
            const config = {}

            Object.keys(parsedQuery).forEach((key) => {
              let value = parsedQuery[key]

              if (key === 'project') {
                const projectFoundByValue = projects.find(
                  (projectItem) => projectItem.value === value
                )
                const projectFoundById = projects.find(
                  (projectItem) => projectItem.id === value
                )

                if (projectFoundByValue) {
                  config.project = projectFoundByValue
                } else if (projectFoundById) {
                  config.project = { label: projectFoundById.name, value: value }
                } else {
                  config.project = { label: value, value: value }
                }
              } else if (key === 'date') {
                config.date = value
              } else if (key === 'current') {
                config.current = value === 'true'
              } else if (key === 'viewHourlyMode') {
                config.viewHourlyMode = value === 'true'
              } else if (key === 'anonymous') {
                config.anonymous = value === 'true'
              } else if (key === 'showPrice') {
                config.showPrice = value === 'true'
              } else {
                config[key] = value
              }
            })

            if (Object.keys(config).length > 0) {
              this.setState({ initialValues: config })
              this.filterSubmitHandler(config)
            }
          }
        }
      } catch (error) {
        handleError(error, 'src/containers/TimeSheet/TimeSheetAdmin/BillReport/index.js', 'handleDidMount')
      }
    }

    init()
  }

  addQueryToUrl = (filters) => {
    const { history } = this.props
    const currentPathName = history.location.pathname
    const parsedFilters = filters
    const queryStringFromFilters = queryString.stringify(parsedFilters)
    const newLocation = `${currentPathName}?${queryStringFromFilters}`
    history.replace(newLocation)
  }

  filterSubmitHandler = async (values) => {
    try {
      this.setState({ isBillReportLoading: true })
      const newValues = cloneDeep(values)

      if (newValues) {
        const queryFilters = {}

        Object.keys(newValues).forEach((key) => {
          let value = newValues[key]

          if (key === 'project' && value && value.value) {
            queryFilters[key] = value.value
          } else if (value !== undefined && value !== null) {
            queryFilters[key] = value
          }
        })

        this.addQueryToUrl(queryFilters)

        if (newValues?.current) {
          delete newValues.date
          set(newValues, 'date', today)
          delete newValues.current
        } else {
          delete newValues.current
        }

        if (newValues?.project) {
          newValues.project = get(newValues, 'project.value')
        }
      }

      const response = await getBillReportAdminListAPI(newValues)

      this.setState({
        billReportList: response?.billReportList,
        paymentCategory: response?.paymentCategory,
        isShowPriceInExportFile: response?.showPrice,
        exportDataList: response?.exportDataList,
        dateOfReport: response?.dateOfReport,
        anonymous: response?.anonymous,
        summaryReport: response?.summaryReport,
      })
    } catch (error) {
      handleError(error, 'src/containers/TimeSheet/TimeSheetAdmin/BillReport/index.js', 'filterSubmitHandler')
    } finally {
      this.setState({ isBillReportLoading: false })
    }
  }

  render() {
    const {
      projects,
      billReportList,
      paymentCategory,
      isShowPriceInExportFile,
      exportDataList,
      dateOfReport,
      anonymous,
      summaryReport,
    } = this.state

    let enabledExportData = false
    let title

    if (billReportList && billReportList.length) {
      enabledExportData = true
      title = paymentCategory === 'Fixed Price' ? 'Fixed Price' : 'Hourly Price'
    }

    const hasNoReportData = !checkReportData(summaryReport, billReportList)
    const hasNoSummaryData = !checkSummaryData(summaryReport)

    return (
      <div className="timesheet-page container">
        <div>
          <FormModel
            initialValues={this.state.initialValues}
            schema={validationSchema}
            handleSubmit={this.filterSubmitHandler}
            component={
              <FilterForm
                isBillReportLoading={this.state.isBillReportLoading}
                projects={projects}
                {...omit(this.props, 'classes')}
              />
            }
          />
        </div>
        <LoadingChakra isLoading={this.state.isBillReportLoading}>
          <>
            <div className="timesheet-content">
              {hasNoReportData ? (
                <EmptyContentBox text={Messages.emptyBoxFilterMessage} position="bottom" />
              ) : (
                <div className="container-fluid">
                  <div className="box box-default">
                    <div className="box-header bill-report">Summary</div>
                    <div className="box-divider" />
                    <div className="box-body">
                      {hasNoSummaryData ? (
                        <EmptyContentBox text={Messages.noData} />
                      ) : (
                        <ReportTable data={summaryReport} paymentCategory={paymentCategory} />
                      )}
                    </div>
                  </div>
                  <div className="box box-default">
                    <div className="box-header bill-report">
                      Timesheet Detail
                      {enabledExportData && (
                        <ExportData
                          data={formattedExportData(
                            exportDataList,
                            isShowPriceInExportFile,
                            anonymous
                          )}
                          headers={formattedExportHeader(
                            paymentCategory,
                            ExportCSVFileHeaders,
                            isShowPriceInExportFile
                          )}
                          headersOfTotalSheet={ExportCSVFileHeadersOfTotalSheet}
                          paymentCategory={paymentCategory}
                          dateOfReport={dateOfReport}
                        />
                      )}
                      {title && <p className="title">{title}</p>}
                    </div>
                    <div className="box-divider" />
                    <div className="box-body">
                      {!isValidArray(billReportList) && (
                        <EmptyContentBox text={Messages.noData} />
                      )}
                      {isValidArray(billReportList) && (
                        <BillReportTable billReportList={billReportList} />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        </LoadingChakra>
      </div>
    )
  }
}

export default BillReport
