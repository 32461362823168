import * as am4charts from '@amcharts/amcharts4/charts'
import * as am4core from '@amcharts/amcharts4/core'
import { toJS } from 'mobx'
import { backgroundRed500, backgroundBlueSecondary, backgroundYellow, backgroundWhite } from 'theme/globalStyles'
import { IRevenueChartData, IRevenueChartDataForMonthFixedScopeProject } from 'types/dashboard'
import { reduceDataOfRevenueChart, reduceDataOfRevenueChartOfMonthlyFixedScope } from 'utils/formatDataUtils'

export function setupAxes(chart: am4charts.XYChart): { dateAxis: am4charts.DateAxis; valueAxis: am4charts.ValueAxis } {
  const dateAxis = chart.xAxes.push(new am4charts.DateAxis())
  dateAxis.renderer.minGridDistance = 60
  dateAxis.startLocation = 0.5
  dateAxis.endLocation = 0.5
  dateAxis.baseInterval = {
    timeUnit: 'hour',
    count: 1,
  }
  const valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
  valueAxis.tooltip.disabled = true
  return { dateAxis, valueAxis }
}
export function setupAxesForMonthlyFixedScope(chart: am4charts.XYChart): {
  dateAxis: am4charts.DateAxis
  valueAxis: am4charts.ValueAxis
} {
  const dateAxis = chart.xAxes.push(new am4charts.DateAxis())
  dateAxis.renderer.minGridDistance = 50
  dateAxis.dateFormats.setKey('day', 'MMM dd') // Định dạng hiển thị cho ngày
  dateAxis.periodChangeDateFormats.setKey('day', 'MMM dd')
  dateAxis.baseInterval = { timeUnit: 'day', count: 1 } // Đảm bảo sử dụng đúng đơn vị ngày
  dateAxis.tooltipDateFormat = 'MMM dd' // Định dạng tooltip

  const valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
  return { dateAxis, valueAxis }
}

export function createLineSeries(
  chart: am4charts.XYChart,
  name: string,
  dateField: string,
  valueField: string,
  tooltipHTML: { icon: string }
): am4charts.LineSeries {
  const series = chart.series.push(new am4charts.LineSeries())
  series.name = name
  series.dataFields.dateX = dateField
  series.dataFields.valueY = valueField
  series.tooltipHTML = `<img src=${tooltipHTML.icon} style='vertical-align:bottom; margin-right: 10px; width:28px; height:21px;'><span style='font-size:14px; color:#000000;'><b>{valueY.value}</b></span>`
  series.tooltipText = '[#000]{valueY.value}[/]'
  series.tooltip.background.fill = am4core.color(backgroundWhite)
  series.tooltip.getStrokeFromObject = true
  series.tooltip.background.strokeWidth = 3
  series.tooltip.getFillFromObject = false
  series.fillOpacity = 0.6
  series.strokeWidth = 2
  series.stacked = true

  return series
}

export function setupCursor(chart: am4charts.XYChart, dateAxis: am4charts.DateAxis): am4charts.XYCursor {
  const cursor = new am4charts.XYCursor()
  cursor.xAxis = dateAxis
  chart.scrollbarX = new am4core.Scrollbar()
  return cursor
}

export function setUpLegend(chart: am4charts.XYChart): am4charts.Legend {
  const legend = new am4charts.Legend()
  legend.position = 'top'
  chart.legend = legend
  return legend
}

export function createColumnSeries(
  chart: am4charts.XYChart,
  name: string,
  dateField: string,
  valueField: string
): am4charts.ColumnSeries {
  const series = chart.series.push(new am4charts.ColumnSeries())
  series.name = name
  series.dataFields.dateX = dateField
  series.dataFields.valueY = valueField
  series.tooltipText = '{name}: [bold]{valueY}[/]'
  series.fillOpacity = 0.8
  series.strokeWidth = 0

  return series
}

function createCommonRevenueChart(
  chartDivId: string,
  chartData: any[],
  setupAxesFunction: (chart: any) => { dateAxis: any }
) {
  const chart = am4core.create(chartDivId, am4charts.XYChart)
  chart.data = chartData

  // Setup axes
  const { dateAxis } = setupAxesFunction(chart)

  // Create a column series for Revenue
  const revenueSeries = createColumnSeries(chart, 'Revenue', 'date', 'revenue')
  revenueSeries.columns.template.width = am4core.percent(30)
  revenueSeries.clustered = true
  revenueSeries.columns.template.fill = am4core.color(backgroundBlueSecondary) // Đặt màu xanh lá cây cho Revenue

  // Create a column series for Salary
  const salarySeries = createColumnSeries(chart, 'Salary', 'date', 'salary')
  salarySeries.columns.template.width = am4core.percent(30)
  salarySeries.clustered = true
  salarySeries.columns.template.fill = am4core.color(backgroundRed500) // Đặt màu cam cho Salary

  // Create a column series for Profit
  const profitSeries = createColumnSeries(chart, 'Profit', 'date', 'profit')
  profitSeries.columns.template.width = am4core.percent(30)
  profitSeries.clustered = true
  profitSeries.columns.template.fill = am4core.color(backgroundYellow) // Đặt màu xanh dương cho Profit

  // Setup cursor
  setupCursor(chart, dateAxis)

  // Setup legend
  setUpLegend(chart)

  return chart
}

export const createRevenueChartForTimeAndMaterial = (chartData: IRevenueChartData[]) => {
  return createCommonRevenueChart(
    'revenue-chartdiv-time-and-material',
    reduceDataOfRevenueChart(toJS(chartData)),
    setupAxesForMonthlyFixedScope // Pass the appropriate axes setup function
  )
}

export const createRevenueChartForMonthlyFixedScopeProject = (
  chartData: IRevenueChartDataForMonthFixedScopeProject[]
) => {
  try {
    return createCommonRevenueChart(
      'revenue-chartdiv-fixed-scope',
      reduceDataOfRevenueChartOfMonthlyFixedScope(toJS(chartData)),
      setupAxesForMonthlyFixedScope // Pass the appropriate axes setup function
    )
  } catch (error) {
    console.error('Error creating chart:', error)
    return ''
  }
}
