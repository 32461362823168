import { useEffect } from 'react'
import { convertResponseDataToChartData } from './container'
import { IRadarChartProps } from './interfaces'
import { setupSkillAnalysisRadarChart } from './setupChart'

const RadarChart = (props: IRadarChartProps) => {
  const { data } = props

  useEffect(() => {
    const chartData = convertResponseDataToChartData(data)
    let chart = setupSkillAnalysisRadarChart(chartData)
    return () => {
      chart.dispose()
    }
  }, [data])

  return <div id="chartdiv" style={{ width: '100%', height: '500px' }}></div>
}

export default RadarChart
