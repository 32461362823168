import {
  borderGray200,
  textGrey700,
  backgroundGrey100,
  backgroundGrey200,
  backgroundBlueSecondary,
  textWhite,
  backgroundBlue600,
  backgroundBlue800,
  backgroundWhite,
} from 'theme/globalStyles'

export function getButtonStyles(variant?: string, colorScheme?: string, color?: string) {
  if (colorScheme) {
    return {
      colorScheme,
    }
  }
  switch (variant) {
    case 'outline':
      return {
        border: '1px solid',
        borderColor: borderGray200,
        bg: backgroundWhite,
        color: color ?? textGrey700,
        _hover: {
          bg: backgroundGrey100,
        },
        _active: {
          bg: backgroundGrey200,
        },
        _disabled: {
          opacity: 0.5,
          cursor: 'not-allowed',
        },
      }
    case 'ghost':
      return {
        bg: backgroundWhite,
        color: color ?? textGrey700,
        _hover: {
          bg: backgroundGrey100,
        },
        _active: {
          bg: backgroundGrey200,
        },
        _disabled: {
          opacity: 0.5,
          cursor: 'not-allowed',
        },
      }
    default:
      return {
        bg: backgroundBlueSecondary,
        color: color ?? textWhite,
        _hover: {
          bg: backgroundBlue600,
        },
        _active: {
          bg: backgroundBlue800,
        },
        _disabled: {
          opacity: 0.5,
          cursor: 'not-allowed',
        },
      }
  }
}
