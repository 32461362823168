import apiV2 from 'API/api'
import { APIRouters } from 'API/APIRouters'
import { ISuccessResponse } from 'API/constants'
import { AxiosResponse } from 'axios'
import { ILoginForm } from 'types/user'
import { ILoginResponse, IRefreshTokenResponse, IForgotPasswordRequest, IResetPasswordRequest } from './constants'

export async function loginV2API(payload: Omit<ILoginForm, 'isRememberMe'>): Promise<ILoginResponse> {
  try {
    const url: string = APIRouters.auth.login.value
    const response: AxiosResponse = await apiV2.post(url, payload)
    return response.data
  } catch (error) {
    throw error
  }
}

// *INFO: using "object" type because the API response empty object
export async function logoutAPI(): Promise<ISuccessResponse<object>> {
  try {
    return apiV2.get(APIRouters.auth.logout.value)
  } catch (error) {
    throw error
  }
}

export async function refreshTokenAPI(email: string): Promise<ISuccessResponse<IRefreshTokenResponse>> {
  try {
    return apiV2.get(APIRouters.auth.refreshToken.value(email))
  } catch (error) {
    throw error
  }
}

export async function forgotPasswordAPI(data: IForgotPasswordRequest): Promise<ISuccessResponse<''>> {
  try {
    const response = await apiV2.post(APIRouters.auth.forgotPassword.value, data)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function resetPasswordAPI(data: IResetPasswordRequest): Promise<ISuccessResponse<IResetPasswordRequest>> {
  try {
    const response = await apiV2.post(APIRouters.auth.resetPassword.value, data)
    return response.data
  } catch (error) {
    throw error
  }
}
