import apiV2 from 'API/api'
import { APIRouters } from 'API/APIRouters'
import { ISuccessResponse } from 'API/constants'
import { AxiosResponse } from 'axios'
import {
  ITimesheet,
  IDetailTimesheetResponseData,
  ITimesheetHistoryResponse,
  ITimeSheetList,
  ITimesheetRequest,
  IProjectHistoryRequest,
  IProjectHistoryResponse,
  IUserHistoryRequest,
  IUserHistoryResponse,
  ICreateSmartTimesheetResponseItem,
} from './constants'

export async function getTimesheetHistoryAPI(): Promise<ISuccessResponse<ITimesheetHistoryResponse>> {
  try {
    const url: string = APIRouters.timesheet.historyUser.project.value
    return apiV2.post(url)
  } catch (error) {
    throw error
  }
}

export async function getUserHistoryAPI(payload: IUserHistoryRequest): Promise<ISuccessResponse<IUserHistoryResponse>> {
  try {
    const url = APIRouters.timesheet.historyAdmin.user.value
    return apiV2.post(url, payload)
  } catch (error) {
    throw error
  }
}

export async function getProjectHistoryAPI(
  payload: IProjectHistoryRequest
): Promise<ISuccessResponse<IProjectHistoryResponse>> {
  try {
    const url = APIRouters.timesheet.historyAdmin.project.value
    return apiV2.post(url, payload)
  } catch (error) {
    throw error
  }
}

export async function getTimeSheetListOfProjectV2API(
  projectID: string[],
  currentDate?: string,
  period?: string
): Promise<ISuccessResponse<ITimeSheetList>> {
  try {
    const url = APIRouters.timesheet.timesheetUser.list.value
    return apiV2.post(url, { projectID, currentDate, period })
  } catch (error) {
    throw error
  }
}

export async function updateTimesheetV2API(timesheetId: string, payload: ITimesheetRequest): Promise<string> {
  try {
    const url = APIRouters.timesheet.timesheetUser.timesheetId.value(timesheetId)
    const response: AxiosResponse = await apiV2.patch(url, payload)
    return response?.data?.message || ''
  } catch (error) {
    throw error
  }
}

export async function createTimesheetV2API(payload: Partial<ITimesheet>): Promise<string> {
  try {
    const url = APIRouters.timesheet.timesheetUser.create.value
    const response: AxiosResponse = await apiV2.post(url, payload)
    return response?.data?.message || ''
  } catch (error) {
    throw error
  }
}

export async function createSmartTimesheetV2API(
  payload: Partial<ITimesheet>[]
): Promise<ICreateSmartTimesheetResponseItem[]> {
  try {
    const url = APIRouters.timesheet.timesheetUser.createSmartTimesheet.value
    const response: AxiosResponse = await apiV2.post(url, payload)
    const responseData = response?.data
    return responseData
  } catch (error) {
    throw error
  }
}

export async function getDetailTimeSheetForUserV2API(
  timesheetId: string
): Promise<ISuccessResponse<IDetailTimesheetResponseData>> {
  try {
    const url = APIRouters.timesheet.detail.timesheetId.value(timesheetId)
    return apiV2.get(url)
  } catch (error) {
    throw error
  }
}

export async function deleteTimesheetV2API(timesheetId: string): Promise<string> {
  try {
    const url = APIRouters.timesheet.delete.timesheetId.value(timesheetId)
    const response: AxiosResponse = await apiV2.delete(url)
    return response?.data?.message || ''
  } catch (error) {
    throw error
  }
}
