import { Box } from '@chakra-ui/react'
import Table from 'components/Table'
import { observer } from 'mobx-react'
import { IPagination } from 'types/table'
import { getValidArray } from 'utils/commonUtils'
import { useStores } from 'utils/hooks/useStores'
import { skillSummaryDataTable } from '../../constant'

export interface ISkillContentForAdmin {
  pagination: IPagination
}

const SkillContentForAdmin = (props: ISkillContentForAdmin) => {
  const { pagination } = props
  const { adminDashboardStore } = useStores()
  const { skillSummaryData } = adminDashboardStore

  const dataInTable = getValidArray(skillSummaryData).map((skillData) => {
    return {
      ...skillData,
    }
  })

  return (
    <Box padding="10px">
      <Table headerList={skillSummaryDataTable} pagination={pagination} tableData={dataInTable} isStriped />
    </Box>
  )
}

export default observer(SkillContentForAdmin)
