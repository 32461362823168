import React from 'react'
import JSEditor from 'components/JSEditor'
import { useFormContext } from 'react-hook-form'
import { EUserDetailTitle } from 'constants/enum'
import Section from '../Section'

interface IUserDataProps {
  name: string
  disabled?: boolean
}
const UserData = (props: IUserDataProps) => {
  const { getValues, setValue } = useFormContext()
  const { name, disabled } = props
  const currentData = getValues(name)
  const data = currentData ? currentData : `{}`
  return (
    <Section title={EUserDetailTitle.USER_DATA}>
      <JSEditor name={name} isDisable={disabled} onChange={(value) => setValue(name, value)} value={data} />
    </Section>
  )
}

export default UserData
