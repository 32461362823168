export const user = {
  // User Page
  value: '/user',
  profile: {
    value: '/user/profile',
    changePassword: {
      value: '/user/profile/change-password',
    },
    isSupervisor: {
      value: '/user/profile/is-supervisor',
    },
    detail: {
      value: '/user/profile/detail',
    },
    update: {
      value: '/user/profile/update',
    },
    updateUserData: {
      value: '/user/profile/update-user-data',
    },
  },
  getCVFile: {
    value: '/user/get-cv-file',
    cvFileName: {
      value: (cvFileName: string) => `/user/get-cv-file/${cvFileName}`,
    },
  },
  avatar: {
    value: '/user/avatar',
    userId: {
      value: (userId: string) => `/user/avatar/${userId}`,
    },
  },
  downloadCV: {
    value: '/user/download-cv',
    adminDownload: {
      value: '/user/download-cv/admin-download',
      userId_cvFileName: {
        value: (userId: string, cvFileName: string) => `/user/download-cv/admin-download/${userId}/${cvFileName}`,
      },
    },
  },
  list: {
    value: '/user/list',
  },
  nameListForAdmin: {
    value: '/user/name-list-for-admin',
    condition: {
      value: (condition: string) => `/user/name-list-for-admin/${condition}`,
    },
  },
  nameListForSupervisor: {
    value: '/user/name-list-for-supervisor',
    condition: {
      value: (condition: string) => `/user/name-list-for-supervisor/${condition}`,
    },
  },
  nameLisWithAGivenTitle: {
    value: '/user/name-list-with-a-given-title',
    title_condition: {
      value: (userTitle: string, condition: string) => `/user/name-list-with-a-given-title/${userTitle}/${condition}`,
    },
  },
  create: {
    value: '/user/create',
  },
  delete: {
    value: '/user/delete',
    userId: {
      value: (userId: string) => `/user/delete/${userId}`,
    },
  },
  archive: {
    value: '/user/archive',
    userId: {
      value: (userId: string) => `/user/archive/${userId}`,
    },
  },
  unarchive: {
    value: '/user/unarchive',
    userId: {
      value: (userId: string) => `/user/unarchive/${userId}`,
    },
  },
  active: {
    value: '/user/active',
    userId: {
      value: (userId: string) => `/user/active/${userId}`,
    },
  },
  deActive: {
    value: '/user/de-active',
    userId: {
      value: (userId: string) => `/user/de-active/${userId}`,
    },
  },
  detail: {
    value: '/user/detail',
    cv: {
      value: '/user/detail/cv',
      userId: {
        value: (userId: string) => `/user/detail/cv/${userId}`,
      },
    },
    userId: {
      value: (userId: string) => `/user/detail/${userId}`,
    },
  },
  update: {
    value: '/user/update',
    userId: {
      value: (userId: string) => `/user/update/${userId}`,
    },
  },
  updateProjectRate: {
    value: '/user/update-project-rate',
    userId: {
      value: (userId: string) => `/user/update-project-rate/${userId}`,
    },
  },
  birthdayList: {
    value: '/user/birthday-list',
  },
  listOfUnarchiveProject: {
    value: '/user/list-of-unarchive-project',
    projectId: {
      value: (projectId: string) => `/user/list-of-unarchive-project/${projectId}`,
    },
  },
  dashboard: {
    value: '/user/dashboard',
  },
}
