export const information = {
  // Information Page
  value: '/information',
  listAdmin: {
    value: '/information/list-admin',
  },
  list: {
    value: '/information/list',
  },
  create: {
    value: '/information/create',
  },
  update: {
    value: '/information/update',
    informationId: {
      value: (informationId: string) => `/information/update/${informationId}`,
    },
  },
  delete: {
    value: '/information/delete',
    informationId: {
      value: (informationId: string) => `/information/delete/${informationId}`,
    },
  },
}
