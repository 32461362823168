import { ELeaveRequestStatus } from '../../../constants/enum'
import { ILeaveAdmin } from '../../../constants/schema'
import { combineStartedDateEndedDate } from '../../Projects/ProjectsListAdmin/utils'

export function convertLeaveDataToTableFormat(leaveData: ILeaveAdmin[]) {
  return leaveData.map(function (leaver: ILeaveAdmin) {
    let approverName = ''
    if (leaver.status === ELeaveRequestStatus.APPROVED || leaver.status === ELeaveRequestStatus.REJECTED) {
      approverName = leaver.approverName
    }

    return {
      dateCreated: leaver.createdAt,
      timeRange: combineStartedDateEndedDate(leaver.startDate, leaver.endDate, false),
      user: leaver.fullName,
      approver: approverName,
      leaveType: leaver.reason,
      status: leaver.status,
    }
  })
}
