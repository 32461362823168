import { ITaskType } from 'API/taskType/constants'
import { getValidArray } from 'utils/commonUtils'

export function getUniqueKeywordsList(taskTypeList: ITaskType[]) {
  const results = []
  getValidArray(taskTypeList).forEach((taskType) => {
    const keywords = taskType.keywords
    getValidArray(keywords).forEach((keyword) => {
      const haveIncludedKeywords = results.includes(keyword)
      if (!haveIncludedKeywords) {
        results.push(keyword)
      }
    })
  })
  return results
}
