import { HStack, VStack, Box } from '@chakra-ui/react'
import Table from 'components/Table'
import { observer } from 'mobx-react'
import { getValidArray } from 'utils/commonUtils'
import { useStores } from 'utils/hooks/useStores'
import { getFormattedNumber } from 'utils/numberUtils'
import { getOverviewHistoryHeaderList, getOverviewHistoryOfDeveloperHeaderList } from '../../constants'

const OverviewHistory = () => {
  const { adminReportStore } = useStores()
  const { historyDataOfProject } = adminReportStore
  if (!historyDataOfProject) return null
  const tableData = getValidArray([historyDataOfProject]).map((data) => {
    return {
      ...data,
      workingTimeTotal: getFormattedNumber(data.workingTimeTotal, 1),
      salaryTotal: getFormattedNumber(data.salaryTotal, 1),
    }
  })
  const developerTableData = getValidArray(historyDataOfProject.developers).map((data) => {
    return {
      ...data,
      user: data.developerName,
      workingTime: getFormattedNumber(data.workingTime, 1),
      salary: getFormattedNumber(data.salary, 1),
    }
  })
  return (
    <HStack width="inherit" background="white" margin={{ base: 2, lg: '16px' }}>
      <VStack width="inherit" padding={'16px'} alignItems={'flex-start'}>
        <h3>History</h3>
        <Box width={'full'} border={'1px solid #E2E8F0'} borderRadius={'8px'} marginTop={4}>
          <HStack width={'full'} alignItems={'flex-start'}>
            <Table
              headerList={getOverviewHistoryHeaderList()}
              tableData={tableData}
              isStriped={false}
              isBorderBottomCell={false}
              isBorder={false}
            />
            <Table
              headerList={getOverviewHistoryOfDeveloperHeaderList()}
              tableData={developerTableData}
              isStriped
              isBorder={false}
            />
          </HStack>
        </Box>
      </VStack>
    </HStack>
  )
}

export default observer(OverviewHistory)
