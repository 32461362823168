import { ITechnology } from 'API/technology/constants'
import MoreDropdown from 'components/Table/DesktopTable/components/MoreDropdown'
import get from 'lodash/get'
import { generatePath } from 'react-router'
import { IDeveloperOnProject } from 'types/developerOnProject'
import { frontendRoutes } from 'constants/routes'
import { IProject } from 'constants/schema'
import { getValidArray } from 'utils/commonUtils'
import { EProjectStatus } from '../enums'
import { combineStartedDateEndedDate, getProjectTechnologies } from '../ProjectsListAdmin/utils'

export function setLocalStorage() {
  window.localStorage.setItem('pageTitle', 'Projects')
}

export function setupDataInTable(
  projectList: IProject[],
  history: any,
  technologyList: ITechnology[],
  userProjects: IDeveloperOnProject[],
  toggleOpenSummaryModalHandler: (project: IProject) => void
) {
  return getValidArray(projectList).map((project) => {
    const documentId: string = project?.id ?? ''
    const pathname = generatePath(frontendRoutes.projectPage.projectUser.detail.id.value, { id: documentId })
    const actionDetailHandler = () => history.push(pathname)
    let statusText: string = getProjectStatus(project, userProjects)
    const typeData = get(project, 'typeData[0].value', '')
    const technologiesOfProject: ITechnology[] = getProjectTechnologies(project?.technology, technologyList)
    return {
      ...project,
      technologiesListFilter: technologiesOfProject,
      typeId: typeData,
      startedDateEndDate: combineStartedDateEndedDate(project?.startedDate, project?.endedDate, project?.untilNow),
      status: statusText,
      actions: (
        <MoreDropdown
          isDetail
          detailActionHandler={actionDetailHandler}
          isSummary
          summaryActionHandler={() => toggleOpenSummaryModalHandler(project)}
        />
      ),
    }
  })
}

export function getProjectStatus(project: IProject, developerOnProjects: IDeveloperOnProject[]) {
  if (project?.isDeleted) return EProjectStatus.DELETED
  if (project?.isArchived) return EProjectStatus.ARCHIVED
  if (project?.isActive) {
    const developerOnProject = developerOnProjects?.find(
      (developerOnProject) => developerOnProject.project === project.id
    )
    if (developerOnProject?.isDeleted || !developerOnProject) return EProjectStatus.REMOVED
    return EProjectStatus.ACTIVE
  }
  return ''
}
