import { useMediaQuery, VStack } from '@chakra-ui/react'
import EmptyContentBox from 'components/EmptyContentBox'
import withLoadingChakra from 'HOC/withLoadingChakra'
import { observer } from 'mobx-react'
import { tabletSize, maxMobileSize, minLaptopSize } from 'constants/common'
import { Messages } from 'constants/index'
import DesktopTable from './DesktopTable'
import { defaultExcludedList } from './DesktopTable/constant'
import { ITableProps } from './interfaces'
import TabletTable from './TabletTable'

const Table = (props: ITableProps) => {
  const {
    tableData,
    headerList,
    headerTabletList,
    isDetail,
    subComponent,
    isShowPagination,
    pagination = { includePagination: false, pageIndex: 1 },
    hasNoSort,
    hasBottomHeader = true,
    excludedExport = defaultExcludedList,
    exportNow,
    isStriped = true,
    isBorderBottomCell = true,
    isBorder = true,
    heightEmptyContentBox,
    mobileDifferentDesktop = false,
    tabletDifferentDesktop = false,
    toggleExport,
  } = props
  const [isTablet]: boolean[] = useMediaQuery(tabletSize)
  const [isMobile]: boolean[] = useMediaQuery(maxMobileSize)
  const [isDesktop]: boolean[] = useMediaQuery(minLaptopSize)

  return tableData.length === 0 ? (
    <EmptyContentBox text={Messages.noData} height={heightEmptyContentBox} />
  ) : (
    <VStack width="100%">
      {(isTablet && tabletDifferentDesktop) | (!isDesktop && mobileDifferentDesktop) && (
        <TabletTable
          isShowPagination={isShowPagination}
          pagination={pagination}
          isDetail={isDetail}
          tableData={tableData}
          headerList={headerTabletList}
        />
      )}
      {(isMobile && !mobileDifferentDesktop) | (isTablet && !tabletDifferentDesktop) | isDesktop && (
        <DesktopTable
          tableData={tableData}
          headerList={headerList}
          subComponent={subComponent}
          pagination={pagination}
          hasNoSort={hasNoSort}
          hasBottomHeader={hasBottomHeader}
          excludedExport={excludedExport}
          exportNow={exportNow}
          toggleExport={toggleExport}
          isStriped={isStriped}
          isBorderBottomCell={isBorderBottomCell}
          isBorder={isBorder}
        />
      )}
    </VStack>
  )
}
export default withLoadingChakra(observer(Table))
