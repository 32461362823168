import { DownloadIcon } from '@chakra-ui/icons'
import { HStack, Text, Box, VStack, Container } from '@chakra-ui/react'
import { downloadCVFromGuestAPI } from 'API/guest'
import CurriculumVitaeDetail from 'components/CurriculumVitaeDetailForGuest'
import { saveAs } from 'file-saver'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import { pdfjs } from 'react-pdf'
import { useParams } from 'react-router-dom'
import { AppConfig } from 'constants/index'
import { toBase64 } from 'utils/commonUtils'
import styles from './index.module.scss'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const ShareCVForGuest = () => {
  const params = useParams()
  const userId = get(params, 'userId', '')
  const fileId = get(params, 'fileId', '')
  const sharedCode = get(params, 'sharedCode', '')
  let isAllowedDownloadCV = false
  if (sharedCode === AppConfig.ENABLED_DOWNLOAD_CV) {
    isAllowedDownloadCV = true
  }
  async function downloadCVHandler() {
    const response = await downloadCVFromGuestAPI(userId, fileId)
    const cv = response.data
    const result = await toBase64(new Blob([cv], { type: 'application/pdf' }))
    saveAs(result, fileId)
  }
  return (
    <VStack>
      <Container background="white" width="full" maxWidth="unset" borderRadius={8} padding="15px 12px">
        <VStack
          alignItems="initial"
          margin={{ base: 6, lg: 6 }}
          marginTop={{ base: 0, lg: 0 }}
          height="full"
          gap={{ base: 0, md: 6, lg: 6 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" width={'100%'}>
            <Text
              fontWeight={500}
              lineHeight={7}
              color="gray.700"
              margin={0}
              fontSize={{ xl: '32px', lg: '32px', md: '24px', sm: '14px' }}>
              Curriculum Vitae
            </Text>
            {isAllowedDownloadCV && (
              <HStack>
                <Box onClick={downloadCVHandler} className={styles.cvButton}>
                  <DownloadIcon className={styles.svgIcon} />
                </Box>
              </HStack>
            )}
          </Box>
          <Box width={'100%'} border="2px solid #E2E8F0" borderRadius="8px" padding="50px 0">
            <CurriculumVitaeDetail isShowButton={false} cvFileName={fileId} />
          </Box>
        </VStack>
      </Container>
    </VStack>
  )
}

export default observer(ShareCVForGuest)
