import { FormControl, FormErrorMessage, FormLabel, HStack } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import { ModalTextInput } from '../../timeSheetModal.styles'

const RemunerationFormInput = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <FormControl mt={6} isInvalid={Boolean(errors?.remuneration)}>
      <FormLabel fontSize="md" fontWeight="400">
        Remuneration
      </FormLabel>
      <HStack>
        <ModalTextInput
          placeholder="Remuneration = rate * time total"
          onKeyPress={(event) => {
            // *INFO: prevent user enter letter
            if (!/^[\.0-9]*$/.test(event.key)) {
              event.preventDefault()
            }
          }}
          {...register('remuneration', { required: true })}
        />
      </HStack>
      <FormErrorMessage>{errors?.remuneration?.message}</FormErrorMessage>
    </FormControl>
  )
}

export default RemunerationFormInput
