import { useMediaQuery } from '@chakra-ui/react'
import Button from 'components/Button'
import { textGrey500, textGrey700 } from 'theme/globalStyles'

interface ISubTabItemProps {
  iconName?: string
  isActive: boolean
  tab: string
  index?: number
  getCurrentTabItem?: (name: string, index: number) => void
}

const TabItem = (props: ISubTabItemProps) => {
  const { isActive, tab, iconName, getCurrentTabItem, index } = props
  const [isTablet] = useMediaQuery('(max-width: 1200px)')

  return (
    <Button
      variant={isActive ? 'solid' : 'ghost'}
      colorScheme={isActive && 'gray'}
      color={isActive ? textGrey700 : textGrey500}
      height="48px"
      width="full"
      leftIcon={iconName}
      customStyles={{ justifyContent: 'flex-start' }}
      onClick={() => {
        getCurrentTabItem(tab, index)
      }}>
      {isTablet ? String(tab).split(' ')[0] : String(tab)}
    </Button>
  )
}

export default TabItem
