import { CheckIcon, CloseIcon } from '@chakra-ui/icons'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react'
import Button from 'components/Button'
import StatusCell from 'components/Table/DesktopTable/components/StatusCell'
import { EStatusOfPaymentRequest } from 'constants/enum'
import { getFormattedDateTimeInEnglish } from 'utils/dateFormatUtils'
import { IShowRequestPaymentListModalProp } from './types'

const ShowRequestPaymentListModal = (props: IShowRequestPaymentListModalProp) => {
  const { isOpen, onClose, data, editRequestPaymentHandler } = props

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="5xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Request Payment List</ModalHeader>
        <ModalBody pb={6}>
          <TableContainer borderWidth="1px" borderRadius="12px" borderStyle="solid">
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Reason Of Requesting</Th>
                  <Th>Requested Date</Th>
                  <Th>Reason Of Denying</Th>
                  <Th>Status</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data &&
                  data.length > 0 &&
                  data.map((item) => {
                    let isShowOptionButton = true
                    if (
                      item.status === EStatusOfPaymentRequest.APPROVED ||
                      item.status === EStatusOfPaymentRequest.DENIED
                    ) {
                      isShowOptionButton = false
                    }
                    return (
                      <Tr key={item._id}>
                        <Td>{item.requestReason}</Td>
                        <Td>{getFormattedDateTimeInEnglish(item.createdAt)}</Td>
                        <Td>{item.deniedReason}</Td>
                        <Td>
                          <StatusCell status={item?.status}>{item.status}</StatusCell>
                        </Td>
                        {isShowOptionButton && (
                          <Td>
                            <Button
                              colorScheme="green"
                              variant="solid"
                              customStyles={{ marginRight: '12px' }}
                              onClick={() => editRequestPaymentHandler(item._id, EStatusOfPaymentRequest.APPROVED)}>
                              <CheckIcon />
                            </Button>
                            <Button
                              colorScheme="red"
                              variant="solid"
                              onClick={() => editRequestPaymentHandler(item._id, EStatusOfPaymentRequest.DENIED)}>
                              <CloseIcon />
                            </Button>
                          </Td>
                        )}
                      </Tr>
                    )
                  })}
              </Tbody>
            </Table>
          </TableContainer>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ShowRequestPaymentListModal
