import MoreDropdown from 'components/Table/DesktopTable/components/MoreDropdown'
import get from 'lodash/get'
import { generatePath } from 'react-router'
import { IRowActionItem } from 'types/table'
import { ITechnology } from 'types/technology'
import { actionOnDataTable } from 'constants/index'
import { frontendRoutes } from 'constants/routes'
import { IProject } from 'constants/schema'
import { getValueFromOption } from 'utils/dropdownOptionUtils'
import { IPartnerFilter } from '../../Partner/FilterForm/interface'
import { IProjectFilterData } from './constants'
import { combineStartedDateEndedDate, getProjectTechnologies } from './utils'

export function setupDataInTable(
  projectsList: IProject[],
  history: any,
  openUnarchiveModalHandler: (project: IProject) => void,
  callOpenDialogConfirm: (project: IProject, nameOfEvent: string) => void,
  toggleOpenSummaryModalHandler: (project: IProject) => void,
  technologyList: ITechnology[]
) {
  return projectsList.map((project) => {
    const isArchived = project?.isArchived ?? false
    const isDeleted = project?.isDeleted ?? false
    const documentId: string = project?._id
    const pathname = generatePath(frontendRoutes.projectPage.projectAdmin.detail.id.value, {
      id: documentId,
      isArchived,
    })

    const actionDetailHandler = () => history.push(pathname)
    const actions: IRowActionItem[] = []
    if (isArchived) {
      !isDeleted &&
        actions.push({
          name: 'Unarchive Project',
          handler: () => openUnarchiveModalHandler(project),
        })
    } else {
      actions.push({
        name: 'Archive Project',
        handler: () => callOpenDialogConfirm(project, actionOnDataTable.ARCHIVE),
      })
    }
    !isDeleted &&
      actions.push({
        name: 'Delete Project',
        customClassNames: ['isDanger'],
        handler: () => callOpenDialogConfirm(project, actionOnDataTable.DELETE),
      })
    let statusText: string = 'Active'
    let archivedText: string = 'Not-Archived'
    if (isArchived) {
      statusText = 'Archived'
      archivedText = 'Archived'
    }
    if (isDeleted) {
      statusText = 'Deleted'
    }
    const typeData = get(project, 'typeData[0].value', '')
    const technologiesOfProject: ITechnology[] = getProjectTechnologies(project?.technology, technologyList)
    const actionsTablet: IRowActionItem[] = []
    !isDeleted &&
      actionsTablet.push(
        {
          name: 'Detail',
          handler: actionDetailHandler,
        },
        {
          name: !!isArchived ? 'Unarchive' : 'Archive',
          handler: () => callOpenDialogConfirm(project, actionOnDataTable.ARCHIVE),
        },
        {
          name: 'Delete',
          customClassNames: ['isDanger'],
          handler: () => callOpenDialogConfirm(project, actionOnDataTable.DELETE),
        }
      )
    return {
      ...project,
      technologiesListFilter: technologiesOfProject,
      typeId: typeData,
      startedDateEndDate: combineStartedDateEndedDate(project?.startedDate, project?.endedDate, project?.untilNow),
      status: statusText,
      archived: archivedText,
      actions: (
        <MoreDropdown
          isDetail
          isSummary
          detailActionHandler={actionDetailHandler}
          summaryActionHandler={() => toggleOpenSummaryModalHandler(project)}
          actions={actions}
        />
      ),
      actionsTablet,
    }
  })
}

export function convertToIPartnerFilter(data): IPartnerFilter {
  return {
    type: data.type || [],
    category: data.category || [],
    status: data.status || [],
    isArchived: data.isArchived ?? false,
    isDeleted: data.isDeleted ?? false,
  }
}

export function formatFilterData(data: IProjectFilterData) {
  const name: string = data.name
  const isActive: boolean = data.isActive
  const isArchived: boolean = data.isArchived
  const isDeleted: boolean = data.isDeleted
  const partnerId: string[] = getValueFromOption(data.partnerId)
  const userId: string[] = getValueFromOption(data.userId)
  const selectedCountryOptions = data?.countries?.filter((country) => country.checked === true)
  const country: string[] = getValueFromOption(selectedCountryOptions)
  const selectedTechnologyOptions = data?.technologies?.filter((technology) => technology.checked === true)
  const technology: string[] = getValueFromOption(selectedTechnologyOptions)

  return {
    name,
    isActive,
    isArchived,
    isDeleted,
    partnerId,
    userId,
    country,
    technology,
  }
}
