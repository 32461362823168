import { getAnnouncementGroupsByMonthUserAPI } from 'API/announcement'
import { handleError } from 'API/error'
import { makeAutoObservable } from 'mobx'
import RootStore from 'store/rootStore'
import { IAnnouncementGroupsByMonth } from 'types/announcement'

class UserAnnouncementStore {
  rootStore: RootStore
  announcementGroupsByMonth: IAnnouncementGroupsByMonth[]
  isLoading: boolean = false

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public async getAnnouncementGroupsByMonth() {
    try {
      this.isLoading = true
      const data = await getAnnouncementGroupsByMonthUserAPI()
      this.announcementGroupsByMonth = data
      this.isLoading = false
    } catch (error) {
      handleError(error, 'src/store/user/announcementStore.ts', 'getAnnouncementGroupsByMonth')
    }
  }
}

export default UserAnnouncementStore
