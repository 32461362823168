import { MutableRefObject } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { NumberFormatValues } from 'react-number-format'
import { IOption } from 'constants/common'

dayjs.extend(duration)

export function getDateTimeFromTimeInput(inputValue: NumberFormatValues, date: Date): Date {
  const { formattedValue } = inputValue
  const [hourString, minuteString] = formattedValue.split(':')
  const hour: number = formatTimeStringToTime(hourString)
  const minute: number = formatTimeStringToTime(minuteString)

  return dayjs(date).hour(hour).minute(minute).toDate()
}

export function formatTimeStringToTime(time: string): number {
  const formatTime: string = `${time ?? ''}`.trim() || '0'
  return parseInt(formatTime)
}

export function generateTotalTimeOptions(startDate: Date): IOption[] {
  const currentDate: Dayjs = dayjs(startDate)
  const endDate: Dayjs = dayjs(startDate).add(24, 'hours')
  const diffMinutes: number = endDate.diff(currentDate, 'minutes')

  const interval: number = 15
  const totalTimeOptions: IOption[] = []
  const MAX_MINUTES_INTERVAL: number = 45

  let totalMinutesDiff: number = interval

  while (totalMinutesDiff <= diffMinutes) {
    const currentAddDate: string = currentDate.add(totalMinutesDiff, 'minutes').format('HH:mm')
    let timeDurationLabel: string = ''

    if (totalMinutesDiff <= MAX_MINUTES_INTERVAL) {
      timeDurationLabel = `${totalMinutesDiff} mins`
    } else {
      const hourDuration: string = parseFloat(`${totalMinutesDiff / 60}`).toFixed(2)

      timeDurationLabel = `${hourDuration} hrs`
    }

    const currentOption: IOption = {
      label: `${currentAddDate} (${timeDurationLabel})`,
      value: `${totalMinutesDiff}`,
    }

    totalMinutesDiff += interval
    totalTimeOptions.push(currentOption)
  }

  return totalTimeOptions
}

export function getEditorTextLength(currentQuillRef: MutableRefObject<undefined>): number {
  // *INFO: using any for get unprivilegedEditor - ReactQuill
  const quillElement = currentQuillRef.current as any
  const editor = quillElement?.getEditor()
  const unprivilegedEditor = quillElement?.makeUnprivilegedEditor(editor)
  const textLength: number = unprivilegedEditor.getLength() ?? 0

  return textLength
}

export function formatDateToTimeString(date: Date): string {
  return dayjs(date).format('HH:mm')
}

export function calculateEndTime(startTimeStr: string | Date, durationHours: number) {
  const startTime = new Date(startTimeStr)
  const durationMinutes = durationHours * 60
  const endTime = new Date(startTime.getTime() + durationMinutes * 60000)
  const hours = endTime.getHours()
  const minutes = endTime.getMinutes()
  const formattedHours = hours.toString().padStart(2, '0')
  const formattedMinutes = minutes.toString().padStart(2, '0')
  return `${formattedHours}:${formattedMinutes}`
}

export function convertDuration(durationHours: number) {
  let result: string
  if (durationHours < 1) {
    const minutes = (durationHours * 60).toFixed(0)
    result = `(${minutes} mins)`
  } else {
    const hours = durationHours.toFixed(2)
    result = `(${hours} hrs)`
  }
  return result
}

export function convertHoursToMinutes(hours: number) {
  const minutes = hours * 60
  return minutes
}
