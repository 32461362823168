export const payment = {
  value: '/payment',
  paymentAdmin: {
    value: '/payment/payment-admin',
    list: {
      value: '/payment/payment-admin/list',
    },
    editRequest: {
      value: '/payment/payment-admin/edit-request',
    },
    edit: {
      value: '/payment/payment-admin/edit',
    },
  },
  paymentUser: {
    value: '/payment/payment-user',
    list: {
      value: '/payment/payment-user/list',
    },
    edit: {
      value: '/payment/payment-user/edit',
    },
    sendRequest: {
      value: '/payment/payment-user/send-request',
    },
  },
}
