/* eslint-disable max-lines */
import { useEffect, useState, useRef, MutableRefObject } from 'react'
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Modal,
  Tooltip,
  Box,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import 'react-quill/dist/quill.snow.css'
import { getErrorMessage } from 'API/error'
import { ITimesheetRequest, ITimesheet } from 'API/timesheet/constants'
import { uploadTimesheetImage } from 'API/upload'
import KachingAudioURL from 'assets/audio/ka-ching.mp3'
import GroupChips from 'components/GroupChips'
import Icon from 'components/Icon'
import dayjs from 'dayjs'
import isEmpty from 'lodash/isEmpty'
import { observer } from 'mobx-react'
import moment, { Moment } from 'moment'
import { useForm, useWatch, FormProvider, UseFormReturn } from 'react-hook-form'
import { toast } from 'react-toastify'
import { EPaymentCategoryOfProject } from 'constants/enum'
import { Messages } from 'constants/index'
import { isValidArray, getValidArray, appendFileExtension } from 'utils/commonUtils'
import { createOptionsOfReactSelectFromDB } from 'utils/dropdownOptionUtils'
import { useStores } from 'utils/hooks/useStores'
import { generateTimeSheetImageURL } from 'utils/imageUtils'
import { generateProjectItems } from '../../utils'
import { selectTaskTypeFromTaskNameAndDescription } from '../../utils/index'
import Dropdown from '../Dropdown'
import { IProjectItem } from '../Dropdown/DropdownButton'
import BudgetRemainSection from './components/BudgetRemainSection'
import ButtonOption from './components/ButtonOption'
import DescriptionEditor from './components/DescriptionEditor'
import RemunerationFormInput from './components/RemunerationFormInput'
import TimeInfoSection from './components/TimeInfoSection'
import { ETimeSheetMode, ITimeSheetForm, ITimeSheetModalProps } from './constants'
import { TimesheetModalContext } from './context/timesheetModal.context'
import { ModalTextInput } from './timeSheetModal.styles'
import {
  formatDateToTimeString,
  generateTotalTimeOptions,
  getEditorTextLength,
  calculateEndTime,
  convertDuration,
  convertHoursToMinutes,
} from './utils'
import { getValidationSchema } from './utils/validation'
import './styles.scss'
import { IOption } from 'constants/common'
import MultipleSelect from 'components/MultipleSelect'

const TimeSheetModal = (props: ITimeSheetModalProps) => {
  const { isOpenModal, setIsOpenModal, filteredProjects } = props
  const nowDate: Date = new Date()
  const nowTime: string = dayjs().format('HH:mm')
  const methods: UseFormReturn<ITimeSheetForm> = useForm<ITimeSheetForm>({
    resolver: yupResolver(getValidationSchema()),
    defaultValues: {
      startDate: nowDate,
      startTime: nowTime,
    },
  })
  const {
    setValue: setFormValue,
    getValues,
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods
  const { userTimesheetStore, adminTaskTypeStore, userProjectStore } = useStores()
  const { allTaskTypeList } = adminTaskTypeStore
  const { currentTimesheet, isDuplicate, calendarTimeData } = userTimesheetStore
  const { projectNameList } = userProjectStore
  const [startDate, setStartDate] = useState<Date>(nowDate)
  const [endDate, setEndDate] = useState<Date>(nowDate)
  const [startTime, setStartTime] = useState<string>(nowTime)
  const [taskDescription, setTaskDescription] = useState<string>('')
  const [timesheetMode, setTimesheetMode] = useState<string>(ETimeSheetMode.MANUAL)
  const [totalTimeOptions, setTotalTimeOptions] = useState<IOption[]>(generateTotalTimeOptions(nowDate))
  const [isLoading, setIsLoading] = useState<boolean>()
  const quillRef: MutableRefObject<undefined> = useRef()
  const projectDropdownItems: IProjectItem[] = generateProjectItems(projectNameList, filteredProjects)
  const projectWatcher: IProjectItem = useWatch({
    control,
    name: 'project',
  })
  const timeTotalWatcher: IOption = useWatch({ control, name: 'timeTotal' })
  const kachingAudio: HTMLAudioElement = new Audio(KachingAudioURL)
  const isEditMode: boolean = !isEmpty(currentTimesheet) && !isDuplicate
  const isFixedPriceProject: boolean = projectWatcher?.paymentCategory === EPaymentCategoryOfProject.FIXED_PRICE
  const taskTypesOptions: IOption[] = [...createOptionsOfReactSelectFromDB(allTaskTypeList)]

  async function onSubmitForm(data: ITimeSheetForm): Promise<void> {
    try {
      setIsLoading(true)
      const errorMessage: string = validationFormData(data)
      if (errorMessage) {
        toast.error(errorMessage)
        return
      }

      const timeTotal: number = parseInt(data?.timeTotal?.value) / 60
      const { taskTypes } = data
      const newTaskTypes = getValidArray(taskTypes).filter((item) => {
        return item.value !== 'all'
      })
      const taskTypesIds = newTaskTypes.map((item) => item.value)
      const timesheetData: Partial<ITimesheet> = {
        taskName: data?.taskName,
        description: await handleFormatDescription(data?.description),
        project: `${data?.project?.value}`,
        timeTotal,
        startTime: startDate,
        endTime: endDate,
        taskLink: data?.taskLink,
        taskTypes: taskTypesIds,
      }

      if (isFixedPriceProject) {
        timesheetData.remuneration = data?.remuneration ?? 0
      }

      if (isEditMode) {
        await userTimesheetStore.updateTimesheetV2(
          currentTimesheet?.id,
          {
            ...timesheetData,
            rateExchange: currentTimesheet?.rateExchange,
            defaultRating: currentTimesheet?.defaultRating,
          } as ITimesheetRequest,
          kachingAudio
        )
      } else {
        await userTimesheetStore.createTimesheetV2(timesheetData, kachingAudio)
      }
      handleCloseModal()
    } catch (error) {
      const errorMessage: string = getErrorMessage(error)
      toast.error(errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  function onBlurDescriptionEditor() {
    autoSelectTaskType()
  }

  function autoSelectTaskType(): void {
    const { taskName, description, taskTypes } = getValues()
    const newTaskTypeList = selectTaskTypeFromTaskNameAndDescription({
      taskName,
      description,
      currentTaskTypeList: taskTypes,
      taskTypeList: allTaskTypeList,
    })
    setFormValue('taskTypes', newTaskTypeList)
  }

  async function handleFormatDescription(description: string): Promise<string> {
    const doc: Document = new DOMParser().parseFromString(description, 'text/html')
    const images: NodeListOf<HTMLImageElement> = doc.querySelectorAll('img')
    let fileNameTimesheetImage = ''

    if (images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        const imageURL = images[i].getAttribute('src')
        try {
          const response = await fetch(imageURL)
          const blob = await response.blob()
          const fileName = imageURL.split('/').pop()
          const finalFileName = appendFileExtension(blob.type, fileName)
          const file = new File([blob], finalFileName, { type: blob.type })
          let formData = new FormData()
          formData.append('TimeSheetImage', file)
          const uploadResponse = await uploadTimesheetImage(formData)
          fileNameTimesheetImage = uploadResponse.data.fileName
          const newUrlImage = generateTimeSheetImageURL(fileNameTimesheetImage)
          images[i].setAttribute('src', newUrlImage)
        } catch (error) {
          console.error('Error when upload images to server', error)
        }
      }
      const newContent = doc.documentElement.innerHTML
      return newContent
    }
    return description
  }

  function validationFormData(formData: ITimeSheetForm): string {
    const currentStartTime: Moment = moment(formData?.startDate)
    const currentEndTime = moment(formData?.endDate)
    const differentTime: number = currentEndTime.diff(currentStartTime, 'minutes')
    if (differentTime <= 0) {
      return Messages.isValidDateTimeOfTimesheet
    }
    const budgetRemain: number = projectWatcher?.budgetRemain ?? 0
    const differentBudget: number = budgetRemain - formData?.remuneration ?? 0
    if (isFixedPriceProject && differentBudget < 0) {
      return Messages.inValidRemuneration
    }
    const editorTextLength: number = getEditorTextLength(quillRef)
    if (editorTextLength <= 1) {
      return Messages.fieldRequiredBy('description')
    }
    return ''
  }

  function handleCloseModal(): void {
    setIsOpenModal(false)
    setStartDate(nowDate)
    setTaskDescription('')
    setStartTime(nowTime)
    reset({ startDate: nowDate, startTime: nowTime })
    userTimesheetStore.resetTimesheetDetail()
    userTimesheetStore.setIsDuplicate(false)
    userTimesheetStore.setCalendarTimeData(undefined)
  }

  function handlePrefillForm(): void {
    const currentStartDate: Date = dayjs(currentTimesheet?.startTime).toDate()
    const timeTotal = currentTimesheet?.timeTotal
    const currentStartTime: string = dayjs(currentStartDate).format('HH:mm')
    const currentProjectOption: IProjectItem = getValidArray(projectDropdownItems).find(
      (projectOption: IProjectItem) => projectOption?.value === currentTimesheet?.project
    )
    const currentTotalTimeOptions: IOption[] = generateTotalTimeOptions(currentStartDate)
    let currentTotalTimeOption: IOption = getValidArray(currentTotalTimeOptions).find((item: IOption) => {
      const formatTimeValue: number = parseInt(item?.value) / 60
      return formatTimeValue === currentTimesheet?.timeTotal
    })
    if (!currentTotalTimeOption) {
      const endTime = calculateEndTime(currentStartDate, timeTotal)
      const totalMinutes = convertHoursToMinutes(timeTotal)
      const convertedDuration = convertDuration(timeTotal)
      currentTotalTimeOption = {
        label: `${endTime} ${convertedDuration}`,
        value: `${totalMinutes}`,
      }
    }
    setStartDate(currentStartDate)
    setStartTime(currentStartTime)
    setTaskDescription(currentTimesheet?.description)
    setTotalTimeOptions(currentTotalTimeOptions)

    reset({
      taskName: currentTimesheet?.taskName,
      taskLink: currentTimesheet?.taskLink,
      startDate: currentStartDate,
      startTime: currentStartTime,
      endDate: currentTimesheet?.endTime,
      description: currentTimesheet?.description,
      project: currentProjectOption,
      timeTotal: currentTotalTimeOption,
      remuneration: currentTimesheet?.remuneration,
      taskTypes: createOptionsOfReactSelectFromDB(currentTimesheet?.taskTypes),
    })
  }

  function handlePrefillSelectCalendar(): void {
    const currentStartDate: Date = calendarTimeData?.start
    const currentEndDate: Date = calendarTimeData?.end
    const currentStartTime: string = dayjs(currentStartDate).format('HH:mm')
    const currentTotalTimeOptions: IOption[] = generateTotalTimeOptions(currentStartDate)
    const currentTimeTotal: number = dayjs(currentEndDate).diff(currentStartDate, 'minutes')
    const currentTotalTimeOption: IOption = getValidArray(currentTotalTimeOptions).find((item: IOption) => {
      const formatTimeValue: number = parseInt(item?.value)
      return formatTimeValue === currentTimeTotal
    })

    setStartDate(currentStartDate)
    setEndDate(currentEndDate)
    setStartTime(currentStartTime)
    setTotalTimeOptions(currentTotalTimeOptions)

    reset({
      startDate: currentStartDate,
      endDate: currentEndDate,
      startTime: currentStartTime,
      timeTotal: currentTotalTimeOption,
    })
  }

  useEffect(() => {
    const totalMinutes: number = Number(timeTotalWatcher?.value ?? 0)
    const endDate: Date = dayjs(startDate).add(totalMinutes, 'minutes').toDate()

    setEndDate(endDate)
    setFormValue('endDate', endDate)
  }, [startDate, timeTotalWatcher])

  useEffect(() => {
    const isNewStartDate: boolean = currentTimesheet?.startTime
      ? formatDateToTimeString(currentTimesheet?.startTime) !== formatDateToTimeString(startDate)
      : formatDateToTimeString(calendarTimeData?.start) !== formatDateToTimeString(startDate)

    if (isNewStartDate) {
      setTotalTimeOptions(generateTotalTimeOptions(startDate))
      setFormValue('timeTotal', null)
    }
  }, [startDate])

  useEffect(() => {
    setFormValue('description', taskDescription)
  }, [taskDescription])

  useEffect(() => {
    if (isValidArray(projectDropdownItems) && !projectWatcher) {
      setFormValue('project', projectDropdownItems[0])
    }
  }, [projectDropdownItems])

  useEffect(() => {
    if (currentTimesheet) {
      handlePrefillForm()
    }
  }, [currentTimesheet])

  useEffect(() => {
    if (calendarTimeData) {
      handlePrefillSelectCalendar()
    }
  }, [calendarTimeData])

  useEffect(() => {
    setFormValue('isFixedPriceProject', isFixedPriceProject)
  }, [projectWatcher])

  return (
    <TimesheetModalContext.Provider
      value={{
        isLoading,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        timesheetMode,
        setTimesheetMode,
        startTime,
        setStartTime,
        totalTimeOptions,
        setTotalTimeOptions,
        taskDescription,
        setTaskDescription,
        onBlurDescriptionEditor,
        quillRef,
      }}>
      <Modal onClose={handleCloseModal} isOpen={isOpenModal} isCentered size="xl" closeOnOverlayClick={false}>
        <FormProvider {...methods}>
          <form name="add-timesheet-form" onSubmit={handleSubmit(onSubmitForm)}>
            <ModalOverlay />
            {/* *INFO: get heigh value base current inputs in add timesheet form */}
            <ModalContent maxH={800}>
              <ModalHeader fontSize="lg" fontWeight={500} lineHeight={7} borderBottom="1px solid #E2E8F0" bg="white">
                {isEditMode ? 'Edit' : 'Add'} Timesheet
                <ModalCloseButton color="black" background="none" mt="2" />
              </ModalHeader>
              <ModalBody overflowY="auto">
                {isFixedPriceProject && <BudgetRemainSection />}
                <TimeInfoSection />
                <FormControl mt={6}>
                  <HStack margin={2}>
                    <FormLabel fontSize="md" fontWeight="400" margin={0}>
                      Project
                    </FormLabel>
                    {isEditMode && (
                      <Tooltip
                        label={Messages.editProjectOfTimesheetNote}
                        color="white"
                        fontSize="sm"
                        hasArrow
                        placement="right">
                        <Box textAlign="center" height="20px">
                          <Icon iconName="warning.svg" size={20} />
                        </Box>
                      </Tooltip>
                    )}
                  </HStack>
                  {isValidArray(projectDropdownItems) && (
                    <Dropdown
                      options={projectDropdownItems}
                      name="project"
                      text="Project"
                      item={projectWatcher ?? projectDropdownItems[0]}
                      setValue={setFormValue}
                      maxWidth="100%"
                      isDisabled={isEditMode}
                    />
                  )}
                </FormControl>
                <FormControl mt={6} isInvalid={Boolean(errors?.taskName)}>
                  <FormLabel fontSize="md" fontWeight="400">
                    Task name
                  </FormLabel>
                  <HStack>
                    <ModalTextInput
                      placeholder="<Task code> | <Task title>"
                      {...register('taskName', { required: true })}
                      onBlur={autoSelectTaskType}
                    />
                  </HStack>
                  <FormErrorMessage>{errors?.taskName?.message}</FormErrorMessage>
                </FormControl>
                <DescriptionEditor />
                <FormControl mt={6}>
                  <FormLabel fontSize="md" fontWeight="400">
                    Task link
                  </FormLabel>
                  <HStack>
                    <ModalTextInput placeholder="https://" {...register('taskLink')} />
                  </HStack>
                </FormControl>
                {isFixedPriceProject && <RemunerationFormInput />}
                <FormControl mt={6}>
                  <MultipleSelect
                    label="Task Types"
                    name="taskTypes"
                    selectedEntityName="Task Types"
                    placeholder="Select Task Types"
                    options={taskTypesOptions}
                    menuPlacement="top"
                    menuStyles={{
                      borderRadius: '4px 4px 0 0',
                    }}
                  />
                </FormControl>
                <FormControl mt={6}>
                  <GroupChips fieldName="taskTypes" defaultColor="gray.700" />
                </FormControl>
              </ModalBody>
              <ModalFooter bottom="0" bg="white" borderTop="1px solid #E2E8F0">
                <ButtonOption onClose={handleCloseModal} isEditMode={isEditMode} />
              </ModalFooter>
            </ModalContent>
          </form>
        </FormProvider>
      </Modal>
    </TimesheetModalContext.Provider>
  )
}

export default observer(TimeSheetModal)
