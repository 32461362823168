import { Box } from '@chakra-ui/layout'
import { getSkillColor, getDisplaySkillName, getPreviousValue } from './container'
import { SkillIndicatorProps } from './interfaces'

const SkillIndicator = (props: SkillIndicatorProps) => {
  const { index, groupSkill, skillAnalysisHistoryData } = props
  return (
    <Box display="flex" flexWrap="wrap" gap={2}>
      {Object.entries(groupSkill).map(([skill, value]) => {
        const previousValue = getPreviousValue(index, skillAnalysisHistoryData, skill, Number(value))
        const { bgColor, color, borderColor } = getSkillColor(Number(value), previousValue)
        return (
          <Box
            key={skill}
            px={2}
            py={1}
            borderRadius="6px"
            bgColor={bgColor}
            color={color}
            border="1px solid"
            borderColor={borderColor}>
            {getDisplaySkillName(skill)}: {value ?? 0}
          </Box>
        )
      })}
    </Box>
  )
}
export default SkillIndicator
