import { ChangeEvent, useRef, useState, useEffect } from 'react'
import { VStack, Image, Text, Container, Input, HStack } from '@chakra-ui/react'
import { handleError } from 'API/error'
import { uploadImage } from 'API/upload'
import Button from 'components/Button'
import EmptyContentBox from 'components/EmptyContentBox'
import { useForm, useFormContext } from 'react-hook-form'
import { EUploadedImageType } from 'constants/enum'
import { generateImageURL, getDefaultImageURL } from 'utils/imageUtils'
import styles from './index.module.scss'

interface IUserAvatarProps {
  type?: string
  name?: string
  title?: string
  afterUploadHandler?: (value: string) => void
  isDetail?: boolean
}

const UserAvatar = (props: IUserAvatarProps) => {
  const { getValues } = useFormContext()
  const avatarRef = useRef<HTMLInputElement | null>(null)
  const [selectedImage, setSelectedImage] = useState<File | null>(null)
  const [preview, setPreview] = useState('')
  const [avatarURL, setAvatarURL] = useState(null)
  const { type, name, title, afterUploadHandler, isDetail } = props

  const avatarUrlForm = getValues(name)
  const fullAvatarURL = generateImageURL(avatarUrlForm, EUploadedImageType.AVATAR)
  const defaultAvatarURL = getDefaultImageURL(EUploadedImageType.AVATAR)

  const { register } = useForm()

  function handleChooseLogo(): void {
    avatarRef.current?.click()
  }

  function handleClearAvatar(): void {
    setAvatarURL(null)
    afterUploadHandler('')
  }

  async function handleChangeAvatar(event: ChangeEvent<HTMLInputElement>): Promise<void> {
    try {
      const file = event?.target?.files?.[0]

      const formData = new FormData()
      formData.append('myImage', file)
      const response = await uploadImage(formData, type)
      if (!!event?.target?.files?.[0]) {
        !!event?.target?.files?.[0] && setSelectedImage(event?.target?.files?.[0])
      }

      afterUploadHandler(response?.data?.fileName)
      setAvatarURL(response?.data?.fileName)
    } catch (error) {
      handleError(error, 'src/containers/User/UserDetail/components/UserAvatar/index.tsx', 'handleChangeAvatar')
    }
  }

  useEffect(() => {
    setAvatarURL(fullAvatarURL)
  }, [fullAvatarURL])

  useEffect(() => {
    if (!selectedImage) {
      setPreview('')
      return
    }

    const objectUrl = URL.createObjectURL(selectedImage)
    setPreview(objectUrl)

    //* INFO: disable unnecessary eslint rule eslint-disable-next-line consistent-return
    // eslint-disable-next-line consistent-return
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedImage])

  return (
    <Container background="white" width="full" maxWidth="unset" padding={0} borderRadius={{ sm: 0, md: 8, lg: 8 }}>
      <VStack className={styles.logoSection}>
        <Text
          alignSelf={'flex-start'}
          className={`${styles.headerBox}`}
          fontSize={{ base: '18px' }}
          paddingBottom={2}
          lineHeight={'28px'}>
          {title}
        </Text>
        {avatarURL ? (
          <Image
            src={preview.length === 0 ? avatarURL : preview}
            alt="user avatar"
            className={styles.avatarImage}
            onError={(e) => {
              e.target.src = defaultAvatarURL
            }}
          />
        ) : (
          <EmptyContentBox text="No Photo" height="100%" />
        )}
        {!isDetail && (
          <HStack>
            <Button colorScheme="blackAlpha" leftIcon="upload-white.svg" onClick={handleChooseLogo}></Button>
            <Input
              {...register('file')}
              type="file"
              ref={avatarRef}
              accept={'image/*'}
              display="none"
              onChange={handleChangeAvatar}
            />
            {avatarURL && (
              <Button
                colorScheme="blackAlpha"
                leftIcon="close-white.svg"
                leftIconSize={16}
                onClick={handleClearAvatar}></Button>
            )}
          </HStack>
        )}
      </VStack>
    </Container>
  )
}

export default UserAvatar
