import { useState, useContext } from 'react'
import {
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { handleError } from 'API/error'
import { archiveProjectV2API as archivedProject } from 'API/project'
import { archivedUserAPI } from 'API/user'
import Button from 'components/Button'
import startCase from 'lodash/startCase'
import { toast } from 'react-toastify'
import { textGrey700 } from 'theme/globalStyles'
import { EEditAssignModalType } from 'containers/Diagram/constant'
import { DiagramContext } from 'containers/Diagram/diagram.context'

export interface IDeActiveNodeModalProps {
  modalType: EEditAssignModalType
  isOpen: boolean
  onClose: () => void
  nodeName: string
  nodeId: string
}

const DeActiveNodeModal = (props: IDeActiveNodeModalProps) => {
  const { modalType, isOpen, onClose, nodeName, nodeId } = props
  const [allowDeActiveNode, setAllowDeActiveNode] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { fetchDiagramData } = useContext(DiagramContext)

  function onInputChange(event: { target: { value: string } }): void {
    const inputValue: string = event?.target?.value
    setAllowDeActiveNode(inputValue === nodeName)
  }

  async function handleDeActiveNode(): Promise<void> {
    try {
      setIsLoading(true)
      if (modalType === EEditAssignModalType.PROJECT) {
        await archivedProject(nodeId)
      } else {
        await archivedUserAPI(nodeId)
      }
      await fetchDiagramData()
      onClose()
      toast.success('De-active node successfully')
    } catch (error) {
      toast.error('De-active node failed')
      handleError(
        error,
        'src/containers/Diagram/components/NodeDetail/components/DeActiveNodeModal/index.tsx',
        'handleDeActiveNode'
      )
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent height="fit-content !important" maxWidth="448px">
        <ModalHeader boxSize="border-box" fontWeight={500} borderBottom={`1px solid white`}>
          De-active {startCase(modalType)}
        </ModalHeader>
        <ModalCloseButton background="white" color={textGrey700} marginTop={2} cursor="pointer" />
        <ModalBody>
          <Text marginTop={0}>Are you sure you want to de-active this {modalType}?</Text>
          <FormControl>
            <FormLabel fontWeight={400}>
              Please type <b>{nodeName}</b> in the input field to confirm
            </FormLabel>
            <Input boxSizing="border-box" onChange={onInputChange} />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" customStyles={{ marginRight: '12px' }} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="red" onClick={handleDeActiveNode} disabled={!allowDeActiveNode} isLoading={isLoading}>
            De-active
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default DeActiveNodeModal
