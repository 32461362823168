import { useEffect, useState } from 'react'
import { HStack, Stack, FormControl } from '@chakra-ui/react'
import Button from 'components/Button'
import DatePicker from 'components/DatePicker'
import InputDropdown from 'components/Dropdown/InputDropdown'
import dayjs from 'dayjs'
import { useFormContext, Controller } from 'react-hook-form'
import { borderSecondary } from 'theme/globalStyles'
import { workingPeriodOptionsOfStatistic } from '../../constant'
import { IStatisticFilterFormForAdminProps } from '../../constant'

const StatisticFilterForm = (props: IStatisticFilterFormForAdminProps) => {
  const { projectOptions, viewAnalyticHandler, isLoading } = props
  const { control, setValue, handleSubmit, getValues } = useFormContext()
  const [isValidForm, setIsValidForm] = useState(false)
  const formValues = getValues()
  const { selectedProject } = formValues
  function pickDate(date: Date): void {
    setValue('date', date)
    localStorage.setItem('isPickPastMonth', String(dayjs(date).month() < dayjs().month()))
  }

  useEffect(() => {
    const isValid = selectedProject
    setIsValidForm(isValid)
  }, [formValues])

  return (
    <HStack width="inherit" background="white" margin={{ base: 2, lg: '16px' }}>
      <Stack direction={{ sm: 'column', lg: 'row' }} width="inherit" padding={'16px'}>
        <InputDropdown
          name="selectedProject"
          placeHolder="Select Projects"
          optionsData={projectOptions}
          isClearable={false}
        />
        <InputDropdown
          name="period"
          placeHolder="Select Period"
          optionsData={workingPeriodOptionsOfStatistic}
          isClearable={false}
          borderColor={borderSecondary}
        />
        <FormControl background="white">
          <Controller
            name="date"
            control={control}
            render={({ field: { value } }) => (
              <DatePicker
                selectedDate={value}
                onChange={pickDate}
                style={{
                  border: '1px solid',
                  borderColor: borderSecondary,
                }}
              />
            )}
          />
        </FormControl>
        <Button
          isLoading={isLoading}
          disabled={!isValidForm || isLoading}
          rightIcon="arrow-right-line-white.svg"
          customStyles={{ minWidth: '160px' }}
          height="44px"
          onClick={handleSubmit(viewAnalyticHandler)}>
          View Analytics
        </Button>
      </Stack>
    </HStack>
  )
}

export default StatisticFilterForm
