import { capitalize } from 'lodash'
import { ICreatableOption } from 'types/creatableOption'
import { IOption } from 'constants/common'
import { EUploadedImageType } from 'constants/enum'
import { getValidArray } from 'utils/commonUtils'
import { getDefaultImageURL, generateImageURL } from 'utils/imageUtils'

export function createOptionsOfSelect(dataArray: string[]) {
  let result = []
  dataArray.forEach((item) => {
    result.push({
      value: item,
      label: capitalize(item),
      text: item,
    })
  })
  return result
}

export function createOptionsOfSelectFromDropdown(dataArray) {
  if (!dataArray) {
    return []
  }
  let result = []
  dataArray.forEach((item) => {
    result.push({
      value: item.value || item._id,
      label: item.label || item.name || item.fullName,
      color: item.color,
    })
  })
  return result
}

export function getValueFromOption(dataArray) {
  const filteredDataArray = getValidArray(dataArray).filter((item) => item || item?.value !== 'all')
  return filteredDataArray.map((item) => item.value)
}

export function createOptionsOfReactSelectFromDB(dataArray) {
  if (!dataArray) {
    return []
  }
  let result = []
  dataArray.forEach((item) => {
    if (item) {
      result.push({
        value: item._id || item.id,
        label: item.value || item.name || item.fullName,
        color: item.tagColor || item.color,
      })
    }
  })
  return result
}

export function createOptionsBadgeImageOfReactSelectFromDB(
  dataArray: ICreatableOption[] | null
): IOption<string | JSX.Element>[] {
  if (!dataArray) {
    return []
  }

  const defaultBadgeImage = getDefaultImageURL(EUploadedImageType.BADGE_IMAGE)
  let result: IOption<string | JSX.Element>[] = []

  dataArray.forEach((item) => {
    if (item) {
      result.push({
        value: item._id || item.id || '',
        label: item.badgeImage ? (
          <img
            style={{ maxHeight: '30px' }}
            src={generateImageURL(item.badgeImage, EUploadedImageType.BADGE_IMAGE)}
            onError={(e) => {
              ;(e.target as HTMLImageElement).src = defaultBadgeImage
            }}
            alt="badge image"
            title={`${item.value}`}
          />
        ) : (
          item.value
        ),
      })
    }
  })

  return result
}

export function getOptionsSelected(selectedData: string[], fullData: IOption[]) {
  return fullData.filter((item) => selectedData.includes(item.value))
}
