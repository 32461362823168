import { useEffect, useState } from 'react'
import { Box, useMediaQuery } from '@chakra-ui/react'
import ConfirmModal from 'components/ConfirmModal'
import { EModalType } from 'components/ConfirmModal/enums'
import ConfirmYesNoModal from 'components/ConfirmYesNoModal'
import Table from 'components/Table'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import set from 'lodash/set'
import { observer } from 'mobx-react'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { backgroundGrey200 } from 'theme/globalStyles'
import { maxMobileSize, maxTabletSize } from 'constants/common'
import { PartnerCategory } from 'constants/defaultOptionList'
import { EConditionGetList, EPartnerCategory } from 'constants/enum'
import { actionOnDataTable, limitItemPerPage } from 'constants/index'
import { frontendRoutes } from 'constants/routes'
import { IProject } from 'constants/schema'
import { getArrayValueFromParsedQuery } from 'utils/commonUtils'
import { createOptionsOfReactSelectFromDB } from 'utils/dropdownOptionUtils'
import { useStores } from 'utils/hooks/useStores'
import UnarchiveProjectModal from '../components/UnarchiveProjectModal'
import { getHeaderList } from '../constant'
import FilterForm from './components/FilterForm'
import HeaderSection from './components/HeaderSection'
import ProjectSummary from './components/ProjectSummary'
import { IProjectFilter, IProjectFilterData } from './constants'
import { formatFilterData, setupDataInTable } from './container'
import styles from './index.module.scss'

const ProjectListAdmin = () => {
  const history = useHistory()
  const { adminProjectStore, adminTechnologyStore, adminUserStore, adminCountryStore } = useStores()
  const { projectsList, count, partnerList, isLoadNewProjectList, isLoadingProject } = adminProjectStore
  const { countryList } = adminCountryStore
  const { userNameList, userListOfUnarchiveProject } = adminUserStore
  const { technologyList } = adminTechnologyStore

  window.localStorage.setItem('pageTitle', 'Projects')
  const URLSearchParams = history.location.search
  const initialFilter = queryString.parse(history.location.search, { parseBooleans: true })
  if (!URLSearchParams) {
    initialFilter.isActive = true
    initialFilter.isArchived = false
    initialFilter.isDeleted = false
  }

  initialFilter.partnerId = getArrayValueFromParsedQuery(initialFilter, 'partnerId')
  initialFilter.userId = getArrayValueFromParsedQuery(initialFilter, 'userId')
  initialFilter.country = getArrayValueFromParsedQuery(initialFilter, 'country')
  initialFilter.technology = getArrayValueFromParsedQuery(initialFilter, 'technology')

  const [isMobile]: boolean[] = useMediaQuery(maxMobileSize)
  const [isTablet]: boolean[] = useMediaQuery(maxTabletSize)
  const [filter, setFilter] = useState<IProjectFilter>(initialFilter ?? {})
  const [openModalFilterForm, setOpenFilterForm] = useState<boolean>(false)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [openArchiveModal, setOpenArchiveModal] = useState<boolean>(false)
  const [openConfirmYesNoModal, setOpenConfirmYesNoModal] = useState(false)
  const [openUnarchiveModal, setOpenUnarchiveModal] = useState<boolean>(false)
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([])
  const [selectedProject, setSelectedProject] = useState<IProject>()
  const [openSummaryModal, setOpenSummaryModal] = useState<boolean>(false)

  const pageIndex: number = Number(get(filter, 'page', 1))
  const pagination = {
    includePagination: true,
    pageIndex,
    pageSize: limitItemPerPage,
    tableLength: count,
    gotoPage: (page: number) => {
      const changedFilter = { ...filter, page }
      setFilter(changedFilter)
      history.push({
        pathname: frontendRoutes.projectPage.projectAdmin.list.value,
        state: { page, filter },
        search: queryString.stringify(changedFilter),
      })
      adminProjectStore.getProjectListWithPagination(history, filter)
    },
  }
  function toggleOpenDeleteModal(): void {
    setOpenDeleteModal(!openDeleteModal)
  }

  function toggleOpenFilterForm() {
    setOpenFilterForm(!openFilterForm)
  }

  function toggleOpenArchiveModal(): void {
    setOpenArchiveModal(!openArchiveModal)
  }

  function toggleOpenSummaryModal(): void {
    setOpenSummaryModal(!openSummaryModal)
  }

  function toggleOpenSummaryModalHandler(project: IProject): void {
    setSelectedProject(project)
    setOpenSummaryModal(!openSummaryModal)
  }

  function openUnarchiveModalHandler(project: IProject) {
    setSelectedProject(project)
    adminUserStore.getUserListOfUnarchiveProject(project._id)
    setOpenUnarchiveModal(true)
  }

  function closeUnarchiveModalHandler() {
    setOpenUnarchiveModal(false)
  }

  function toggleConfirmYesNoModal() {
    setOpenConfirmYesNoModal(!openConfirmYesNoModal)
  }

  async function handleConfirmInfo(selectedUserIds?: string[]) {
    setSelectedUserIds(selectedUserIds ?? [])
    closeUnarchiveModalHandler()
    toggleConfirmYesNoModal()
  }

  function callOpenDialogConfirm(project: IProject, nameOfEvent: string): void {
    set(project, 'shortName', project?.name ?? '')
    if (nameOfEvent === actionOnDataTable.DELETE) {
      setSelectedProject(project)
      toggleOpenDeleteModal()
    } else {
      setSelectedProject(project)
      toggleOpenArchiveModal()
    }
  }
  async function handleClickOKConfirmedDelete(id: string): Promise<void> {
    await adminProjectStore.deleteProject(id)
    setOpenDeleteModal(false)
  }
  async function handleClickOKOfUnarchiveConfirm(): Promise<void> {
    await adminProjectStore.unArchiveProject(selectedUserIds, selectedProject._id)
    closeUnarchiveModalHandler()
    toggleConfirmYesNoModal()
  }
  async function handleClickOKOfArchiveConfirm(id: string): Promise<void> {
    await adminProjectStore.archiveProject(id)
    setOpenArchiveModal(false)
  }

  function handleCreate(): void {
    history.push(frontendRoutes.projectPage.projectAdmin.create.value)
  }

  function openFilterForm(): void {
    setOpenFilterForm(true)
  }
  const changeName = debounce((event: { target: { value: string } }) => {
    const name: string = event.target.value
    const changedFilter = { ...filter, name, page: 1 }
    history.push({ search: queryString.stringify(changedFilter) })
    setFilter(changedFilter)
  }, 1000)

  const partnerCategoryOfProject: EPartnerCategory[] = PartnerCategory.slice(0, 2)
  const partnersOptions = createOptionsOfReactSelectFromDB(partnerList)
  const countriesOptions = createOptionsOfReactSelectFromDB(countryList)
  const technologiesOptions = createOptionsOfReactSelectFromDB(technologyList)
  const usersOptions = createOptionsOfReactSelectFromDB(userNameList)

  const dataInTable = setupDataInTable(
    projectsList,
    history,
    openUnarchiveModalHandler,
    callOpenDialogConfirm,
    toggleOpenSummaryModalHandler,
    technologyList
  )
  function filterSubmitHandler(data: IProjectFilterData): void {
    initialFilter.name && set(data, 'name', initialFilter.name)
    const formatedFilter = formatFilterData(data)
    history.push({ search: queryString.stringify(formatedFilter) })
    setFilter(formatedFilter)
    toggleOpenFilterForm()
  }

  useEffect(() => {
    adminProjectStore.getPartnerList(partnerCategoryOfProject)
    adminCountryStore.getCountryList()
    adminTechnologyStore.getTechnologyList()
    adminUserStore.getAllNameUserList(EConditionGetList.ACTIVE)
  }, [])

  useEffect(() => {
    adminProjectStore.getProjectListWithPagination(history, filter)
  }, [filter, isLoadNewProjectList])
  return (
    <Box>
      <Box
        className={styles.internalDocumentContainer}
        paddingY={isMobile ? 0 : 4}
        paddingX={isMobile ? 0 : 4}
        background={isMobile ? 'none' : 'white'}
        borderRadius="6px"
        border={isMobile ? 'none' : `1px solid ${backgroundGrey200}`}>
        <HeaderSection openFilterForm={openFilterForm} changeName={changeName} handleCreate={handleCreate} />
        <FilterForm
          partnersList={partnersOptions}
          countriesList={countriesOptions}
          technologiesList={technologiesOptions}
          usersList={usersOptions}
          openModalFilterForm={openModalFilterForm}
          setOpenFilterForm={setOpenFilterForm}
          filterSubmit={filterSubmitHandler}
        />
        <Table
          headerList={getHeaderList(isTablet)}
          isLoading={isLoadingProject}
          tableData={dataInTable}
          isStriped
          pagination={pagination}
          hasBottomHeader={!isMobile}
          //*TODO: integrate later
          // exportNow={exportNow}
          // toggleExport={this.toggleExport}
        />
        <ConfirmModal
          data={selectedProject}
          isOpen={openDeleteModal}
          closeHandler={toggleOpenDeleteModal}
          OKClickHandler={handleClickOKConfirmedDelete}
          title={'Project'}
          content={'Project Name'}
          type={EModalType.DELETE}
        />
        <ConfirmModal
          data={selectedProject}
          isOpen={openArchiveModal}
          closeHandler={toggleOpenArchiveModal}
          OKClickHandler={handleClickOKOfArchiveConfirm}
          title={'Project'}
          content={'Project Name'}
          type={EModalType.ARCHIVE}
        />
        <UnarchiveProjectModal
          isOpen={openUnarchiveModal}
          closeHandler={closeUnarchiveModalHandler}
          saveHandler={handleConfirmInfo}
          dataList={userListOfUnarchiveProject}
        />
        <ConfirmYesNoModal
          isOpen={openConfirmYesNoModal}
          title="Confirm Unarchive Project"
          actionText="Are you sure you want to unarchive this project?"
          closeHandler={toggleConfirmYesNoModal}
          OKClickHandler={handleClickOKOfUnarchiveConfirm}
        />
        <ProjectSummary isOpen={openSummaryModal} closeHandler={toggleOpenSummaryModal} projectData={selectedProject} />
      </Box>
    </Box>
  )
}

export default observer(ProjectListAdmin)
