import * as React from 'react'
import { FormControl, FormLabel, Input, InputGroup, InputRightElement } from '@chakra-ui/react'
import { ErrorMessage } from '@hookform/error-message'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { getInputType } from './container'
import { InputFieldProps } from './interfaces'
import styles from './styles.module.scss'

const InputField = React.forwardRef<HTMLInputElement, InputFieldProps>((props, ref) => {
  const { label, name, errors, isPassword, ...rest } = props
  const [showPassword, setShowPassword] = React.useState<boolean>(false)

  function handleClickShowPassword(): void {
    setShowPassword(!showPassword)
  }

  const inputType = getInputType(isPassword, showPassword)

  return (
    <FormControl>
      <FormLabel className={styles.labelForm}>{label}</FormLabel>
      <InputGroup size="md">
        <Input
          ref={ref}
          w="calc(100% - 34px)"
          name={name}
          size="lg"
          fontSize="md"
          type={inputType}
          className={styles.inputField}
          {...rest}
        />
        {isPassword && (
          <InputRightElement mr={'10px'}>
            <span className={styles.showPassword} onClick={handleClickShowPassword}>
              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </span>
          </InputRightElement>
        )}
      </InputGroup>
      <ErrorMessage
        errors={errors || {}}
        name={name}
        render={({ message }) => <p className={styles.errorMessage}>{message}</p>}
      />
    </FormControl>
  )
})

InputField.displayName = 'InputField'

export default InputField
