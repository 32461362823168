import Diagram from 'beautiful-react-diagrams'
import { DiagramSchema } from 'beautiful-react-diagrams/@types/DiagramSchema'
import { DiagramWrapper } from '../../diagram.styles'
import 'beautiful-react-diagrams/styles.css'
import './styles.scss'

interface IUncontrolledDiagramProps {
  schema: DiagramSchema<unknown>
  onChange: (schema: DiagramSchema<unknown>) => void
}

const UncontrolledDiagram = (props: IUncontrolledDiagramProps) => {
  const { schema, onChange } = props

  return (
    <DiagramWrapper>
      <Diagram schema={schema} onChange={onChange} />
    </DiagramWrapper>
  )
}

export default UncontrolledDiagram
