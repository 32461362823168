import React from 'react'
import { SimpleGrid, Text } from '@chakra-ui/react'
import FormInput from 'components/FormInput'
import SingleSelect from 'components/SingleSelect'
import { genderOptions } from 'constants/defaultOptionList'
import styles from '../../../contract.module.scss'
const ENInformation = () => {
  return (
    <React.Fragment>
      <Text alignSelf={'flex-start'} className={`${styles.headerBox} ${styles.border}`}>
        English Information
      </Text>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
        <FormInput name="nationalityEN" label="Nationality (optional)" />
        <SingleSelect name="genderEN" label="Gender (optional)" optionsData={genderOptions} />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
        <FormInput name="addressEN" label="Address (optional)" />
        <FormInput name="publishedDateInfoEN" label="Published Date And Place (optional)" />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
        <FormInput name="placeOfBirthEN" label="Place Of Birth (optional)" />
        <FormInput name="IDIssuePlaceEN" label="ID Issue Place (optional)" />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
        <FormInput name="educationLevelEN" label="Education (optional)" />
        <FormInput name="majorEN" label="Major (optional)" />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
        <FormInput name="jobTitleEN" label="Job title (optional)" />
      </SimpleGrid>
    </React.Fragment>
  )
}

export default ENInformation
