import * as am4charts from '@amcharts/amcharts4/charts'
import * as am4core from '@amcharts/amcharts4/core'
import { COLOR } from './constants'

export function setupSkillAnalysisRadarChart(data: { skill: string; userIndex: number; averageIndex: number }[]) {
  let chart = am4core.create('chartdiv', am4charts.RadarChart)

  chart.data = data

  let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis())
  categoryAxis.dataFields.category = 'skill'
  if (window.innerWidth >= 768) {
    categoryAxis.renderer.labels.template.fontSize = '14px'
  } else {
    categoryAxis.renderer.labels.template.fontSize = '12px'
  }

  let valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
  valueAxis.renderer.gridType = 'polygons'
  valueAxis.renderer.axisFills.template.fill = am4core.color(COLOR.axisColor)
  valueAxis.renderer.axisFills.template.fillOpacity = 0.2
  valueAxis.renderer.labels.template.disabled = true
  if (valueAxis instanceof am4charts.ValueAxis) {
    valueAxis.max = 5
    valueAxis.min = 0
  }
  let userSeries = chart.series.push(new am4charts.RadarSeries())
  userSeries.dataFields.valueY = 'userIndex'
  userSeries.dataFields.categoryX = 'skill'
  userSeries.strokeWidth = 2
  userSeries.fillOpacity = 0.1
  userSeries.stroke = am4core.color(COLOR.userRadarChart)
  userSeries.fill = am4core.color(COLOR.userRadarChart)

  let averageSeries = chart.series.push(new am4charts.RadarSeries())
  averageSeries.dataFields.valueY = 'averageIndex'
  averageSeries.dataFields.categoryX = 'skill'
  averageSeries.strokeWidth = 2
  averageSeries.fillOpacity = 0.1
  averageSeries.stroke = am4core.color(COLOR.averageRadarChart)
  averageSeries.fill = am4core.color(COLOR.averageRadarChart)

  return chart
}
