import { useEffect } from 'react'
import { Box } from '@chakra-ui/layout'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal'
import Button from 'components/Button'
import { FormProvider, useForm } from 'react-hook-form'
import { borderGray200 } from 'theme/globalStyles'
import PolicyForm from '../PolicyForm'
import { IEditPolicyModalProps } from './interface'

const PolicyModal = (props: IEditPolicyModalProps) => {
  const { isEdit = false, isOpen, isLoading, closeHandler, saveHandler, data } = props
  const methods = useForm()
  const { reset, handleSubmit } = methods

  useEffect(() => {
    reset(
      isEdit
        ? {
            title: data?.title,
            content: data?.content,
            publishedDate: new Date(data?.publishedDate),
            status: data?.published ? 'published' : 'unpublished',
          }
        : { publishedDate: new Date() }
    )
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} onClose={closeHandler} size="xl" isCentered>
      <ModalOverlay />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(saveHandler)}>
          <ModalContent maxHeight={{ lg: '600px', md: '600px', sm: '700px' }}>
            <Box background="white" width="full">
              <ModalHeader borderBottom="1px solid" borderColor={borderGray200} fontWeight={500}>
                {isEdit ? 'Edit Policy' : 'Create New Policy'}
              </ModalHeader>
              <ModalCloseButton color={'gray'} background="none" cursor="pointer" mt="2" />
            </Box>
            <ModalBody overflowY="auto" paddingTop="20px">
              <PolicyForm methods={methods} />
            </ModalBody>
            <ModalFooter borderTop="1px solid" borderColor={borderGray200}>
              <Button variant="outline" onClick={closeHandler} customStyles={{ marginRight: '16px' }}>
                Cancel
              </Button>
              <Button variant="solid" type="submit">
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default PolicyModal
