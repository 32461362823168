import React from 'react'
import LoadingChakra from 'components/LoadingChakra'

const withLoadingChakra = (WrappedComponent: React.ComponentType<any>) => {
  return class extends React.Component<{ isLoading: boolean }> {
    render() {
      const { isLoading, ...otherProps } = this.props
      return (
        <LoadingChakra isLoading={isLoading}>
          <WrappedComponent {...otherProps} />
        </LoadingChakra>
      )
    }
  }
}

export default withLoadingChakra
