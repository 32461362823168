import { Table, Tbody, Tr, Box, Td, Stack, Container } from '@chakra-ui/react'
import { getFormattedNumber } from 'utils/numberUtils'
import { extractData } from './container'
import { IHighlightTable } from './interfaces'
import styles from './table.module.scss'

const HighlightTableForSupervisor = (props: IHighlightTable) => {
  const { data } = props
  const { numberOfDeveloper, rateExchange, totalWorkingTime, rateExchangeUnit } = extractData(data)

  return (
    <Stack width="inherit">
      <Container maxWidth="100%" padding="0px" margin="0px !important">
        <Box className={styles.scrollAble}>
          <Table variant="striped" colorScheme="gray">
            <Tbody className={styles.tbody}>
              <Tr>
                <Td>NUMBER OF WORKING DEVELOPERS</Td>
                <Td isNumeric>{numberOfDeveloper}</Td>
              </Tr>
              <Tr>
                <Td>{`PROJECT’S CURRENT RATE EXCHANGE ${rateExchangeUnit}`}</Td>
                <Td isNumeric>{rateExchange}</Td>
              </Tr>
              <Tr>
                <Td>TOTAL WORKING (HOURS)</Td>
                <Td>{getFormattedNumber(totalWorkingTime, 1)}</Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>
      </Container>
    </Stack>
  )
}

export default HighlightTableForSupervisor
