export const hourlyProjectRate = {
  value: '/hourly-project-rate',
  list: {
    value: '/hourly-project-rate/list',
  },
  listAdmin: {
    value: '/hourly-project-rate/list-admin',
    userId: {
      value: (userId: string) => `/hourly-project-rate/list-admin/${userId}`,
    },
  },
  update: {
    value: '/hourly-project-rate/update',
    userId: {
      value: (userId: string) => `/hourly-project-rate/update/${userId}`,
    },
  },
}
