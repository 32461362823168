import { handleError } from 'API/error'
import { getOverviewAnalyticsAPI, getReportDetailAdminAPI } from 'API/report'
import {
  IOverviewReportRequest,
  IGroupOverviewCalendar,
  IFormattedTimeSheet,
  IOverviewHighlightData,
  IOverviewHistoryData,
  IAnalyticTaskTypeData,
  IAdminReportRequest,
  IReportData,
} from 'API/report/constants'
import { makeAutoObservable } from 'mobx'
import RootStore from '../rootStore'

class AdminReportStore {
  rootStore: RootStore
  groups: IGroupOverviewCalendar[] = []
  timesheetList: IFormattedTimeSheet[] = []
  highlightData: IOverviewHighlightData
  haveData: boolean = false
  choosedDate: string = ''
  historyDataOfProject: IOverviewHistoryData
  taskTypeData: IAnalyticTaskTypeData[] = []
  isViewAnalyticsLoading: boolean = false
  isReportLoading: boolean = false
  reportListData: IReportData[] = []

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public async getOverviewAnalytics(data: IOverviewReportRequest): Promise<void> {
    try {
      this.isViewAnalyticsLoading = true
      this.haveData = false
      const { date } = data
      this.choosedDate = date
      const response = await getOverviewAnalyticsAPI(data)
      const { groups, timesheets, highlightData, historyData, taskTypeData } = response.data
      this.groups = groups
      this.timesheetList = timesheets
      this.highlightData = highlightData
      this.haveData = true
      this.historyDataOfProject = historyData
      this.taskTypeData = taskTypeData
    } catch (error) {
      handleError(error, 'src/store/admin/AdminReportStore.ts', 'getCountryList')
    } finally {
      this.isViewAnalyticsLoading = false
    }
  }

  public async getReportAnalytics(data: IAdminReportRequest): Promise<void> {
    try {
      this.isReportLoading = true
      this.haveData = false
      const response = await getReportDetailAdminAPI(data)
      const reportListData = response.data
      const { reportList } = reportListData
      this.reportListData = reportList
      this.haveData = true
    } catch (error) {
      handleError(error, 'src/store/admin/AdminReportStore.ts', 'getCountryList')
    } finally {
      this.isReportLoading = false
    }
  }
}

export default AdminReportStore
