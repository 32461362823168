import { withStyles } from '@material-ui/core/styles'
import DatePicker from 'components/DatePickerOld'
import Input from 'components/Input'
import PasswordInput from 'components/Input/PasswordInput'
import RadioButton from 'components/RadioButton'
import { Field } from 'formik'
import capitalize from 'lodash/capitalize'
import PropTypes from 'prop-types'

const styles = () => ({
  textField: { marginLeft: 20, marginRight: 20, width: 200, fontSize: 16 },
})

const FormField = (props) => {
  const {
    classes,
    name,
    label,
    disabled,
    multiline,
    required,
    extraWidth,
    isDate,
    isOnlyDate,
    type,
    isRadio,
    onChange,
  } = props
  const inputLabel = label || `${capitalize(props.name)}${required ? '*' : ''}`
  const className: string = extraWidth ? classes.textField + '98width' : classes.textField
  const isPassword: boolean = String(name).includes('word')
  let component
  if (isDate || isOnlyDate) {
    component = DatePicker
  } else if (isRadio) {
    component = RadioButton
  } else if (isPassword) {
    component = PasswordInput
  } else {
    component = Input
  }
  return (
    <div className="field">
      <Field
        name={name}
        label={inputLabel}
        type={type}
        className={className}
        multiline={multiline}
        onlyDate={isOnlyDate}
        component={component}
        margin="normal"
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  )
}

FormField.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  multiline: PropTypes.bool,
  required: PropTypes.bool,
  extraWidth: PropTypes.bool,
  isOnlyDate: PropTypes.bool,
  isDate: PropTypes.bool,
  isRadio: PropTypes.bool,
  type: PropTypes.string,
}

export default withStyles(styles)(FormField)
