import { useState, useEffect } from 'react'
import { HStack, VStack } from '@chakra-ui/layout'
import { useMediaQuery, Accordion } from '@chakra-ui/react'
import ItemAccordion from 'components/ItemAccordion'
import { observer } from 'mobx-react'
import { EConditionGetList } from 'constants/enum'
import { useStores } from 'utils/hooks/useStores'
import TabItem from '../components/TabItem'
import { EProfileTab, EIconName } from '../constants'
import ChangePassword from './components/ChangePassword'
import CurriculumVitae from './components/CurriculumVitae'
import LaborContract from './components/LaborContract'
import ProfileDetail from './components/ProfileDetail'
import ProjectRating from './components/ProjectRating'
import SkillAnalysis from './components/SkillAnalysis'
import UserData from './components/UserData'
import style from './index.module.scss'

const Profile = () => {
  const [currentTab, setCurrentTab] = useState<EProfileTab>(EProfileTab.PROFILE_DETAIL)
  const { userProfileStore } = useStores()
  const { profileDetail } = userProfileStore
  const [isTablet] = useMediaQuery('(max-width: 820px)')
  useEffect(() => {
    userProfileStore.getTechnologyList()
    userProfileStore.getProjectNameList(EConditionGetList.ACTIVE, () => userProfileStore.getProfileDetail())
  }, [])
  return (
    <>
      {!isTablet ? (
        <HStack
          className={style.navigationTabar}
          alignItems={'flex-start'}
          backgroundColor="white"
          borderRadius="8px"
          p={{ base: 6, lg: 8 }}>
          <VStack spacing={3} alignItems="flex-start" flex={1.5}>
            <TabItem
              tab={EProfileTab.PROFILE_DETAIL}
              iconName={EIconName.PROFILE_DETAIL}
              isActive={currentTab === EProfileTab.PROFILE_DETAIL}
              setCurrentTab={setCurrentTab}
            />
            <TabItem
              tab={EProfileTab.LABOR_CONTRACT}
              iconName={EIconName.LABOR_CONTRACT}
              isActive={currentTab === EProfileTab.LABOR_CONTRACT}
              setCurrentTab={setCurrentTab}
            />
            <TabItem
              tab={EProfileTab.CHANGE_PASSWORD}
              iconName={EIconName.CHANGE_PASSWORD}
              isActive={currentTab === EProfileTab.CHANGE_PASSWORD}
              setCurrentTab={setCurrentTab}
            />
            <TabItem
              tab={EProfileTab.PROJECT_RATING}
              iconName={EIconName.PROJECT_RATING}
              isActive={currentTab === EProfileTab.PROJECT_RATING}
              setCurrentTab={setCurrentTab}
            />
            <TabItem
              tab={EProfileTab.SKILL_ANALYSIS}
              iconName={EIconName.SKILL_ANALYSIS}
              isActive={currentTab === EProfileTab.SKILL_ANALYSIS}
              setCurrentTab={setCurrentTab}
            />
            <TabItem
              tab={EProfileTab.CURRICULUM_VITAE}
              iconName={EIconName.CURRICULUM_VITAE}
              isActive={currentTab === EProfileTab.CURRICULUM_VITAE}
              setCurrentTab={setCurrentTab}
            />
            <TabItem
              tab={EProfileTab.USER_DATA}
              iconName={EIconName.USER_DATA}
              isActive={currentTab === EProfileTab.USER_DATA}
              setCurrentTab={setCurrentTab}
            />
          </VStack>
          <VStack flex={5} width={{ lg: '50%', md: '50%' }} alignItems="flex-start">
            {currentTab === EProfileTab.PROFILE_DETAIL && <ProfileDetail />}
            {currentTab === EProfileTab.LABOR_CONTRACT && <LaborContract />}
            {currentTab === EProfileTab.PROJECT_RATING && <ProjectRating />}
            {currentTab === EProfileTab.SKILL_ANALYSIS && <SkillAnalysis />}
            {currentTab === EProfileTab.CHANGE_PASSWORD && <ChangePassword />}
            {currentTab === EProfileTab.CURRICULUM_VITAE && <CurriculumVitae profileDetail={profileDetail} />}
            {currentTab === EProfileTab.USER_DATA && <UserData />}
          </VStack>
        </HStack>
      ) : (
        <Accordion allowToggle>
          <ItemAccordion label={EProfileTab.PROFILE_DETAIL} component={<ProfileDetail />} />
          <ItemAccordion label={EProfileTab.LABOR_CONTRACT} component={<LaborContract />} />
          <ItemAccordion label={EProfileTab.PROJECT_RATING} component={<ProjectRating />} />
          <ItemAccordion label={EProfileTab.SKILL_ANALYSIS} component={<SkillAnalysis />} />
          <ItemAccordion label={EProfileTab.CHANGE_PASSWORD} component={<ChangePassword />} />
          <ItemAccordion
            label={EProfileTab.CURRICULUM_VITAE}
            component={<CurriculumVitae profileDetail={profileDetail} />}
          />
          <ItemAccordion label={EProfileTab.USER_DATA} component={<UserData />} />
        </Accordion>
      )}
    </>
  )
}
export default observer(Profile)
