import { Box } from '@chakra-ui/react'
import BadgeImageTag from 'components/Tag/BadgeImageTag'
import FilterTag from 'components/Tag/FilterTag'
import { useFormContext } from 'react-hook-form'
import { IOption } from 'constants/common'
import { getValidArray } from 'utils/commonUtils'

interface IGroupChipsProps {
  fieldName: string
  defaultColor?: string
  isBadgeImage?: boolean
  selectDataHandler?: () => void
}

const GroupChips = (props: IGroupChipsProps) => {
  const { fieldName, defaultColor, selectDataHandler, isBadgeImage = false } = props
  const { watch } = useFormContext()
  const dataList: IOption[] = watch(fieldName)
  const formattedDataList = getValidArray(dataList)
    .filter((item) => item.value !== 'all')
    .map((element) => {
      return { label: element.label, value: element.value, color: element.color }
    })
  return (
    <Box alignItems="flex-start" width="full">
      {getValidArray(formattedDataList).map((element, index) => {
        const color = element.color ? element.color : defaultColor
        let textColor: string = color
        const fieldProjectsName = [
          'projects',
          'extraProjects',
          'projectsOfSupervisor',
          'externalProjects',
          'profileDetailData.projects',
          'profileDetailData.extraProjects',
          'profileDetailData.projectsOfSupervisor',
          'profileDetailData.externalProjects',
        ]
        if (fieldProjectsName.includes(fieldName)) {
          textColor = 'gray.700'
        }

        if (isBadgeImage) {
          return <BadgeImageTag key={index} label={element?.label} fieldName={fieldName} />
        }
        return (
          <FilterTag
            key={index}
            label={element?.label}
            fieldName={fieldName}
            color={color}
            textColor={textColor}
            selectDataHandler={selectDataHandler}
          />
        )
      })}
    </Box>
  )
}

export default GroupChips
