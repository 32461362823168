import { IAssignment } from 'API/diagram/interface'
import { EPaymentCategoryOfProject, EProjectTypes, EPaymentCategoryFromClientOfProject } from 'constants/enum'
import { IProject } from 'constants/schema'
import { getValidArray } from 'utils/commonUtils'
import { INewAssignmentData } from './types'

export function getProjectPaymentCategory(projectId: string, projects: IProject[]): EPaymentCategoryOfProject {
  const selectedProject: IProject | undefined = getValidArray(projects).find(
    (project: IProject) => project?.id === projectId
  )
  return selectedProject?.paymentCategory as EPaymentCategoryOfProject
}

export function getProjectPaymentCategoryFromClient(
  projectId: string,
  projects: IProject[]
): EPaymentCategoryFromClientOfProject {
  const selectedProject: IProject | undefined = getValidArray(projects).find(
    (project: IProject) => project?.id === projectId
  )
  return selectedProject?.paymentCategoryFromClient as EPaymentCategoryFromClientOfProject
}

export function getProjectCurrencyName(projectId: string, projects: IProject[]): string {
  const selectedProject: IProject | undefined = getValidArray(projects).find(
    (project: IProject) => project?.id === projectId
  )
  return selectedProject?.currencyName
}

export function getClientRateValue(projectId: string, projects: IProject[]): number {
  const selectedProject: IProject | undefined = getValidArray(projects).find(
    (project: IProject) => project?.id === projectId
  )
  return selectedProject?.clientRate ?? 0
}

export function getBillableMonthlyAmountValue(projectId: string, projects: IProject[]): number {
  const selectedProject: IProject | undefined = getValidArray(projects).find(
    (project: IProject) => project?.id === projectId
  )
  return selectedProject?.billableMonthlyAmount ?? 0
}

export function generateAddAssignmentPayload(
  formData: INewAssignmentData,
  projectPaymentCategory: EPaymentCategoryOfProject
): IAssignment {
  return {
    isSupervisor: formData?.isSupervisor,
    project: formData?.project?.value,
    user: formData?.user?.value,
    projectHourlyRate: Number(formData?.projectHourlyRate),
    commitTime: Number(formData?.commitTime),
    projectPaymentCategory,
    projectType: formData?.projectType?.value as EProjectTypes,
  }
}
