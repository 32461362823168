import { useEffect, useState } from 'react'
import { AddIcon, Search2Icon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Tooltip,
  useMediaQuery,
} from '@chakra-ui/react'
import ConfirmModal from 'components/ConfirmModal'
import { EModalType } from 'components/ConfirmModal/enums'
import Table from 'components/Table'
import MoreDropdown from 'components/Table/DesktopTable/components/MoreDropdown'
import { History } from 'history'
import { debounce, get } from 'lodash'
import set from 'lodash/set'
import { observer } from 'mobx-react'
import queryString from 'query-string'
import { FiDownload, FiFilter } from 'react-icons/fi'
import { generatePath } from 'react-router'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { backgroundBlueSecondary, backgroundGrey200 } from 'theme/globalStyles'
import { frontendRoutes } from 'constants/routes'
import { IPartner } from 'constants/schema'
import { getArrayValueFromParsedQuery, getValidArray, removeAccents } from 'utils/commonUtils'
import { actionOnDataTable, limitItemPerPage } from '../../../constants'
import { maxMobileSize } from '../../../constants/common'
import { useStores } from '../../../utils/hooks/useStores'
import { convertToIPartnerFilter } from '../../Projects/ProjectsListAdmin/container'
import FilterForm from '../FilterForm'
import { IFilterProps, IPartnerFilter } from '../FilterForm/interface'
import { getHeaderList } from './constant'
import styles from './partnerList.module.scss'

const PartnerList = () => {
  const history: History = useHistory()
  const [isOpenFilterForm, setIsOpenFilterForm] = useState<boolean>(false)
  const [isMobile]: boolean[] = useMediaQuery(maxMobileSize)
  const { adminPartnerStore } = useStores()
  const { count, partnerList } = adminPartnerStore

  function saveFilterToSession(filter: IPartnerFilter) {
    const filterKey = JSON.stringify(filter)

    window.sessionStorage.removeItem('previousFilters')

    const previousFilters = [filterKey]
    window.sessionStorage.setItem('previousFilters', JSON.stringify(previousFilters))
  }

  function getSavedFilterFromSession() {
    const previousFilters = JSON.parse(window.sessionStorage.getItem('previousFilters')) || []
    return previousFilters.length > 0 ? JSON.parse(previousFilters[0]) : null
  }

  function handleAddPartner() {
    window.sessionStorage.setItem('subPageTitle', 'Create')
    window.dispatchEvent(new Event('storage'))
    history.push(frontendRoutes.partnerPage.create.value)
  }

  const initialFilterFromSession = getSavedFilterFromSession()

  function shouldUpdateURL(filter: IPartnerFilter) {
    return (
      filter &&
      filter.type.length > 0 &&
      filter.category.length > 0 &&
      filter.status.length > 0 &&
      filter.isArchived !== false &&
      filter.isDeleted !== false
    )
  }

  if (shouldUpdateURL(initialFilterFromSession)) {
    const updatedQuery = queryString.stringify(initialFilterFromSession)
    history.replace({
      pathname: frontendRoutes.partnerPage.list.value,
      search: `?${updatedQuery}`,
    })
  }

  const initialFilter = initialFilterFromSession || queryString.parse(history.location.search, { parseBooleans: true })

  const URLSearchParams = history.location.search

  if (!URLSearchParams) {
    initialFilter.isArchived = false
    initialFilter.isDeleted = false
  }

  initialFilter.type = getArrayValueFromParsedQuery(initialFilter, 'type')
  initialFilter.category = getArrayValueFromParsedQuery(initialFilter, 'category')
  initialFilter.status = getArrayValueFromParsedQuery(initialFilter, 'status')

  const [filter, setFilter] = useState<IPartnerFilter>(initialFilter ?? {})

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false)

  const toggleOpenFilterForm = () => setIsOpenFilterForm(!isOpenFilterForm)
  const toggleOpenDeleteForm = () => setIsOpenDeleteModal(!isOpenDeleteModal)
  const toggleOpenArchivedForm = () => setIsOpenArchiveModal(!isOpenArchiveModal)

  const pageIndex: number = Number(get(history, 'location.state.page', 1)) || 1
  const pagination = {
    includePagination: true,
    pageIndex: pageIndex,
    pageSize: limitItemPerPage,
    tableLength: count,
    gotoPage: function gotoPageNum(page: number) {
      const changedFilter = { ...filter, page: page }
      setFilter(changedFilter)

      history.push({
        pathname: frontendRoutes.partnerPage.list.value,
        state: { page: page, filter: filter },
        search: queryString.stringify(changedFilter),
      })

      adminPartnerStore.getPartnerListWithPagination(history, changedFilter)
    },
  }

  const [exportNow, toggleExport] = useState<boolean>(false)
  const toggleExportNow = () => toggleExport(!exportNow)
  const [selectedPartner, setSelectedPartner] = useState<IPartner | null>(null)
  const [isOpenArchiveModal, setIsOpenArchiveModal] = useState(false)
  const [isOpenUnarchivedModal, setIsOpenUnarchivedModal] = useState(false)

  function callOpenDialogConfirm(partner: IPartner, nameOfEvent: string) {
    set(partner, 'shortName', partner?.fullName ?? '')

    switch (nameOfEvent) {
      case actionOnDataTable.DELETE:
        setSelectedPartner(partner)
        toggleOpenDeleteModal()
        break
      case actionOnDataTable.ARCHIVE:
        setSelectedPartner(partner)
        if (partner?.isArchived) {
          toggleOpenUnarchivedModal()
          break
        }
        toggleOpenArchiveModal()
        break
      case actionOnDataTable.UNARCHIVED:
        setSelectedPartner(partner)
        toggleOpenUnarchivedModal()
        break
      default:
        toast.error('Action not found')
    }
  }

  function toggleOpenDeleteModal() {
    setIsOpenDeleteModal(!isOpenDeleteModal)
  }

  function toggleOpenArchiveModal() {
    setIsOpenArchiveModal(!isOpenArchiveModal)
  }

  function toggleOpenUnarchivedModal() {
    setIsOpenUnarchivedModal(!isOpenUnarchivedModal)
  }

  function filterSubmitHandler(data: IFilterProps) {
    const formattedData = convertToIPartnerFilter(data)

    const convertParam = queryString.stringify(data)
    history.push({ search: convertParam })

    setFilter(formattedData)
    toggleOpenFilterForm()
  }

  function handleChangeName(event) {
    const fullName = removeAccents(event?.target?.value ?? '')
    const changedFilter = {
      ...filter,
      fullName,
      page: 1,
    }
    history.push({ search: queryString.stringify(changedFilter) })
    setFilter(changedFilter)
  }

  const changeName = debounce(handleChangeName, 1000)

  function createActionDetailHandler(partner) {
    const isDeleted = partner?.isDeleted ?? false
    const pathname = generatePath(frontendRoutes.partnerPage.detail.id.value, {
      id: partner?._id,
      isDeleted: isDeleted,
    })
    return function () {
      history.push(pathname)
    }
  }

  function createActions(partner) {
    const actions = []
    const isDeleted = partner?.isDeleted ?? false
    const isArchived = partner?.isArchived ?? false

    if (!isDeleted && !partner?.isDefault) {
      actions.push(
        {
          name: isArchived ? 'Unarchive' : 'Archive',
          handler: function () {
            callOpenDialogConfirm(partner, actionOnDataTable.ARCHIVE)
          },
        },
        {
          name: 'Delete',
          customClassNames: ['isDanger'],
          handler: function () {
            callOpenDialogConfirm(partner, actionOnDataTable.DELETE)
          },
        }
      )
    }
    return actions
  }

  function createActionsTablet(partner) {
    const actionsTablet = []
    const isDeleted = partner?.isDeleted ?? false
    const isArchived = partner?.isArchived ?? false

    if (!isDeleted) {
      actionsTablet.push(
        {
          name: 'Detail',
          handler: createActionDetailHandler(partner),
        },
        {
          name: isArchived ? 'Unarchive' : 'Archive',
          handler: function () {
            callOpenDialogConfirm(partner, actionOnDataTable.ARCHIVE)
          },
        },
        {
          name: 'Delete',
          customClassNames: ['isDanger'],
          handler: function () {
            callOpenDialogConfirm(partner, actionOnDataTable.DELETE)
          },
        }
      )
    }
    return actionsTablet
  }

  const dataInTable = getValidArray<IPartner>(partnerList).map(function (partner) {
    return {
      ...partner,
      status: partner?.isDeleted ?? false ? 'Deleted' : partner?.isArchived ?? false ? 'Archived' : 'Active',
      actions: (
        <MoreDropdown
          isDetail={true}
          detailActionHandler={createActionDetailHandler(partner)}
          actions={createActions(partner)}
        />
      ),
      actionsTablet: createActionsTablet(partner),
    }
  })

  async function handleClickOKConfirmedDelete(id: string) {
    await adminPartnerStore.deletePartner(id)
    toggleOpenDeleteModal()
    await adminPartnerStore.getPartnerListWithPagination(history, filter)
  }

  async function handleClickOKConfirmedArchive(id: string) {
    await adminPartnerStore.archivePartner(id)
    toggleOpenArchiveModal()
    await adminPartnerStore.getPartnerListWithPagination(history, filter)
  }

  async function handleClickOKConfirmedUnArchived(id: string) {
    await adminPartnerStore.unArchivePartner(id)
    toggleOpenUnarchivedModal()
    await adminPartnerStore.getPartnerListWithPagination(history, filter)
  }

  useEffect(
    function handleFetchData() {
      async function fetchData() {
        saveFilterToSession(filter)

        await adminPartnerStore.getPartnerListWithPagination(history, filter)
      }

      fetchData()
    },
    [filter]
  )

  return (
    <Box
      className={styles.partnerContainer}
      background="white"
      padding={6}
      borderRadius="6px"
      border={`1px solid ${backgroundGrey200}`}>
      <Flex justifyContent="space-between" flexWrap="wrap">
        <HStack marginBottom={6} width={{ base: '100%', md: 'auto' }}>
          <InputGroup
            border={`1px solid ${backgroundGrey200}`}
            borderRadius="6px"
            width={{ base: '100%', lg: '540px' }}
            background="white">
            <InputLeftElement pointerEvents="none">
              <Search2Icon color="gray.400" />
            </InputLeftElement>
            <Input type="search" placeholder="Search Partner by name..." onChange={changeName} />
          </InputGroup>
          <Button
            cursor="pointer"
            paddingY="10px"
            leftIcon={<FiFilter />}
            variant="outline"
            background="white"
            onClick={toggleOpenFilterForm}
            display={{ base: 'none', md: 'inline-flex' }}>
            Filter
          </Button>
          {isOpenFilterForm && (
            <FilterForm
              openModalFilterForm={isOpenFilterForm}
              setOpenFilterForm={toggleOpenFilterForm}
              queryParamNow={history.location.search}
              filterSubmit={filterSubmitHandler}
            />
          )}
        </HStack>
        <HStack marginBottom={6} width={{ base: '100%', md: 'auto' }} spacing={4}>
          <Tooltip
            label="Feature coming soon"
            hasArrow={true}
            sx={{
              fontSize: 'md',
              px: 4,
              py: 2,
              borderRadius: 'md',
              boxShadow: 'lg',
            }}>
            <Box>
              <Button
                cursor="pointer"
                leftIcon={<FiDownload />}
                onClick={() => {}}
                background="white"
                marginLeft={{ md: '0 !important' }}
                isDisabled={true}
                variant="outline">
                Export
              </Button>
            </Box>
          </Tooltip>
          <Button
            cursor="pointer"
            paddingY="10px"
            fontWeight={500}
            leftIcon={<AddIcon width={3} />}
            background={backgroundBlueSecondary}
            _hover={{
              background: backgroundBlueSecondary,
            }}
            onClick={handleAddPartner}>
            Add Partner
          </Button>
        </HStack>
      </Flex>

      <Table
        headerList={getHeaderList(isMobile)}
        isLoading={false}
        tableData={dataInTable}
        isStriped
        pagination={pagination}
        isShowPagination={true}
        exportNow={exportNow}
        toggleExport={toggleExportNow}
      />
      <ConfirmModal
        data={selectedPartner}
        isOpen={isOpenDeleteModal}
        closeHandler={toggleOpenDeleteForm}
        OKClickHandler={handleClickOKConfirmedDelete}
        title={'Partner'}
        content={'Full Name'}
        type={EModalType.DELETE}
      />
      <ConfirmModal
        data={selectedPartner}
        isOpen={isOpenArchiveModal}
        closeHandler={toggleOpenArchivedForm}
        OKClickHandler={handleClickOKConfirmedArchive}
        title={'Partner'}
        content={'Full Name'}
        type={EModalType.ARCHIVE}
      />
      <ConfirmModal
        data={selectedPartner}
        isOpen={isOpenUnarchivedModal}
        closeHandler={toggleOpenUnarchivedModal}
        OKClickHandler={handleClickOKConfirmedUnArchived}
        title={'Partner'}
        content={'Full Name'}
        type={EModalType.UNARCHIVED}
      />
    </Box>
  )
}

export default observer(PartnerList)
