import { useMemo, useState } from 'react'
import { FormControl, HStack, SimpleGrid, Text, Textarea } from '@chakra-ui/react'
import FormInput from 'components/FormInput'
import SingleSelect from 'components/SingleSelect'
import { observer } from 'mobx-react'
import { useFormContext, Controller } from 'react-hook-form'
import { getDisplayName } from 'utils/commonUtils'
import { useStores } from 'utils/hooks/useStores'
import { ISkillAnalysisFormProps } from './interfaces'

const SkillAnalysisForm = (props: ISkillAnalysisFormProps) => {
  const { isEdit, activeSkill } = props
  const { control, getValues, watch } = useFormContext()
  const [noteLength, setNoteLength] = useState(0)
  const { adminUserStore } = useStores()
  const { userDetail } = adminUserStore

  function handleNoteChange(event: { target: { value: string } }) {
    setNoteLength(event.target.value.length)
  }

  const options = [
    { value: '0', label: '0' },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
  ]

  const greaterStyle = {
    boxShadow: '0 0 0 2px green',
  }

  const lesserStyle = {
    boxShadow: '0 0 0 2px red',
  }

  function getControlStyle(name: string): Record<string, unknown> {
    if (isEdit) {
      if (activeSkill?.[name] < Number(getValues(name)?.value)) {
        return greaterStyle
      }
      if (activeSkill?.[name] > Number(getValues(name)?.value)) {
        return lesserStyle
      }
    }
    return {}
  }

  const watchedValues = watch([
    'workingAttitude',
    'meetDeadlines',
    'problemSolving',
    'communication',
    'teamPlayer',
    'technicalSkills',
    'english',
  ])

  const controlStyle = useMemo(
    () => ({
      workingAttitude: getControlStyle('workingAttitude'),
      meetDeadlines: getControlStyle('meetDeadlines'),
      problemSolving: getControlStyle('problemSolving'),
      communication: getControlStyle('communication'),
      teamPlayer: getControlStyle('teamPlayer'),
      technicalSkills: getControlStyle('technicalSkills'),
      english: getControlStyle('english'),
    }),
    [watchedValues]
  )

  return (
    <FormControl>
      <FormInput
        name="username"
        label="User"
        disabled={true}
        defaultValue={getDisplayName(userDetail?.profileDetailData)}
      />
      <SimpleGrid columns={2} spacing={4} mt={5}>
        <SingleSelect
          name="workingAttitude"
          label="Working attitude"
          optionsData={options}
          isRequire
          controlStyle={controlStyle.workingAttitude}
          widthCustom="100%"
        />
        <SingleSelect
          name="technicalSkills"
          label="Technical skills"
          optionsData={options}
          isRequire
          controlStyle={controlStyle.technicalSkills}
          widthCustom="100%"
        />
        <SingleSelect
          name="teamPlayer"
          label="Team player"
          optionsData={options}
          isRequire
          controlStyle={controlStyle.teamPlayer}
          widthCustom="100%"
        />
        <SingleSelect
          name="communication"
          label="Communication"
          optionsData={options}
          isRequire
          controlStyle={controlStyle.communication}
          widthCustom="100%"
        />

        <SingleSelect
          name="problemSolving"
          label="Problem solving"
          optionsData={options}
          isRequire
          controlStyle={controlStyle.problemSolving}
          widthCustom="100%"
        />
        <SingleSelect
          name="meetDeadlines"
          label="Meet deadlines"
          optionsData={options}
          isRequire
          controlStyle={controlStyle.meetDeadlines}
          widthCustom="100%"
        />

        <SingleSelect
          name="english"
          label="English"
          optionsData={options}
          isRequire
          controlStyle={controlStyle.english}
          menuPlacement="top"
          widthCustom="100%"
        />
      </SimpleGrid>
      <SimpleGrid columns={1}>
        <HStack justifyContent="space-between">
          <HStack>
            <Text>Note</Text>
            {isEdit && <Text color="red">*</Text>}
          </HStack>
          <Text color="gray.500">{noteLength}/500</Text>
        </HStack>
        <Controller
          name="comment"
          control={control}
          rules={{ required: isEdit, minLength: 0, maxLength: 500 }}
          render={({ field, fieldState }) => (
            <Textarea
              isInvalid={fieldState.invalid}
              width="95%"
              {...field}
              placeholder="Enter note"
              onChange={(e) => {
                field.onChange(e)
                handleNoteChange(e)
              }}
            />
          )}
        />
      </SimpleGrid>
    </FormControl>
  )
}

export default observer(SkillAnalysisForm)
