import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons'
import { HStack, Text, VStack } from '@chakra-ui/react'
import get from 'lodash/get'
import { useFormContext, useWatch } from 'react-hook-form'
import { EWorkingHourPeriods } from 'constants/enum'

interface IInfoBoxProps {
  title: string
  value: string
  icon: React.ReactNode
  percent?: string
  isShowPercent?: boolean
  valueFontSize?: string
}

const InfoBox = (props: IInfoBoxProps) => {
  const {
    title = 'Total Salary (VND)',
    value = '0',
    icon,
    percent = 0,
    isShowPercent = true,
    valueFontSize = '30px',
  } = props
  const { control } = useFormContext()
  const periodOption = useWatch({
    name: 'period',
    control,
  })
  const period: EWorkingHourPeriods = get(periodOption, 'value', EWorkingHourPeriods.MONTH) as EWorkingHourPeriods

  return (
    <HStack
      justifyContent="space-between"
      spacing={6}
      background="white"
      borderRadius="6px"
      boxShadow="base"
      padding={6}
      width={{ base: 'auto', lg: 'full' }}>
      <VStack spacing={4} alignItems="flex-start">
        <Text marginY={0} color="gray.700" fontSize="16px" fontWeight="400" lineHeight="24px">
          {title}
        </Text>
        <VStack spacing={0} alignItems="flex-start">
          <Text marginY={0} color="gray.500" fontSize={valueFontSize} fontWeight="700" lineHeight="36px">
            {value}
          </Text>
          {isShowPercent && (
            <HStack spacing={2}>
              <Text
                marginY={0}
                color={percent < 0 ? 'red.500' : 'green.500'}
                fontSize="14px"
                fontWeight="400"
                lineHeight="20px">
                {Number(percent) < 0 ? (
                  <TriangleDownIcon width="10px" height="20px" color={'red.500'} marginRight={1} />
                ) : (
                  <TriangleUpIcon width="10px" height="20px" color={'green.500'} marginRight={1} />
                )}
                {Math.abs(Number(percent)) || 0}%
              </Text>
              <Text marginY={0} color="gray.400" fontSize="14px" fontWeight="400" lineHeight="20px">
                since last {period}
              </Text>
            </HStack>
          )}
        </VStack>
      </VStack>
      {icon}
    </HStack>
  )
}

export default InfoBox
