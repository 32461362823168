import React from 'react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { HStack, VStack, Text, Box, Link } from '@chakra-ui/react'
import Icon from 'components/Icon'
import Table from 'components/Table/CustomExpandedTable'
import HighlightTable from 'components/Table/HighlightTable'
import { observer } from 'mobx-react'
import ReactQuill from 'react-quill'
import { Messages } from 'constants/index'
import { getValidArray } from 'utils/commonUtils'
import { getFormattedTimeDate } from 'utils/dateFormatUtils'
import { useStores } from 'utils/hooks/useStores'
import { getFormattedNumber } from 'utils/numberUtils'
import { handleUpdateDescription } from '../../../utils'

const OverviewReport = (props) => {
  const { adminReportStore } = useStores()
  const { timesheetList, highlightData } = adminReportStore
  const { expandedRows } = props

  function getOverviewReportHeaderList() {
    const headers = [
      {
        Header: 'TASK NAME',
        accessor: 'taskName',
        // id: "expander",
        Cell: ({ row }) => <span {...row.getToggleRowExpandedProps()}>{row.original.taskName}</span>,
      },
      {
        Header: 'USER',
        accessor: 'developerName',
        Cell: ({ row }) => <span {...row.getToggleRowExpandedProps()}>{row.original?.developerName[0]}</span>,
      },
      {
        Header: 'HOURLY RATE',
        accessor: 'defaultRating',
      },
      {
        Header: 'START - END TIME',
        accessor: 'startAndEndTime',
      },
      {
        Header: 'DURATION (HRS)',
        accessor: 'duration',
      },
      {
        Header: 'SALARY',
        accessor: 'salary',
      },
    ]
    return headers
  }
  const tableData = getValidArray(timesheetList).map((timesheet) => {
    return {
      ...timesheet,
      taskTypes: timesheet.taskTypesName.map((taskType) => taskType).join(', '),
      duration: getFormattedNumber(timesheet.workingTime, 1),
      salary: getFormattedNumber(timesheet.salary, 1),
      startAndEndTime: `${getFormattedTimeDate(timesheet.startTime)} - ${getFormattedTimeDate(timesheet.endTime)}`,
    }
  })

  const subTable = React.useCallback(({ row }) => {
    return (
      <Box backgroundColor={'blue.50'} padding={'16px'} width={'inherit'}>
        <Text fontWeight={'bold'}>Description</Text>
        <ReactQuill
          className="task-description"
          value={handleUpdateDescription(row.original.description)}
          theme="bubble"
          readOnly
        />
        {row.original.taskLink && (
          <Box>
            <Link href={row.original.taskLink} isExternal>
              Task link <ExternalLinkIcon mx="2px" />
            </Link>
          </Box>
        )}
        {row.original.taskTypes && (
          <Box>
            <Text fontWeight={'bold'}>Task Types</Text>
            {row.original.taskTypes}
          </Box>
        )}
      </Box>
    )
  }, [])

  return (
    <HStack width="inherit" background="white" margin={{ base: 2, lg: '16px' }}>
      <VStack width="inherit" padding={'16px'} alignItems={'flex-start'}>
        <h3>Report</h3>
        <Box maxWidth={'830px'}>
          <HighlightTable data={highlightData} />
        </Box>
        <HStack mb={2}>
          <Icon iconName="information-blue-fill-circle.svg" size={20} />{' '}
          <Text> {Messages.pressToExpandTimesheetDetailGuide} </Text>
        </HStack>
        <VStack width={'inherit'} maxHeight={'500px'} overflowY={'scroll'}>
          <Table
            tableData={tableData}
            headerList={getOverviewReportHeaderList()}
            subComponent={subTable}
            expandRows={true}
            expandedRowObj={expandedRows}
          />
        </VStack>
      </VStack>
    </HStack>
  )
}

export default observer(OverviewReport)
