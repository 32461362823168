import { ChangeEvent } from 'react'
import { Input, FormControl } from '@chakra-ui/react'
import get from 'lodash/get'
import { useFormContext, Controller } from 'react-hook-form'
import { editFormWidth } from 'containers/Diagram/components/NodeDetail/constant'

export interface IFormNumberInputProps {
  name: string
  isDisabledField?: boolean
  defaultValue?: string
  isHidden?: boolean
  height?: string
}

const FormNumberInput = (props: IFormNumberInputProps) => {
  const { name, isDisabledField, isHidden, defaultValue, height } = props
  const {
    formState: { errors },
    control,
    setValue,
  } = useFormContext()

  function onChange(event: ChangeEvent<HTMLInputElement>): void {
    const inputValue: string = event.target.value
    setValue(name, inputValue)
  }

  return (
    <FormControl isInvalid={!!get(errors, name, false)} hidden={isHidden} padding={2}>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue || '0'}
        render={({ field: { value } }) => (
          <Input
            width={editFormWidth}
            padding={2}
            height={height ?? '32px'}
            boxSizing="border-box"
            isDisabled={isDisabledField}
            onChange={onChange}
            value={value}
            type="number"
          />
        )}
      />
    </FormControl>
  )
}

export default FormNumberInput
