import React from 'react'
import { Badge, Box } from '@chakra-ui/react'
import Button from 'components/Button'
import RadioButton from 'components/RadioButton/RadioButtonWithoutFormik'
import { EStatusOfPaymentRequest } from 'constants/enum'
import { getFormattedDateTimeInEnglish } from 'utils/dateFormatUtils'
import { getFormattedNumber, getFormattedSalary } from 'utils/numberUtils'

function formatRow(index) {
  let result = 'even'
  if (index % 2 === 0) {
    result = 'odd'
  }
  return result
}

interface IPaymentTableProps {
  data
  editHandler
  triggerShowRequestPaymentTable?: (x: []) => void
  isAdmin
  tableName: string
  isNeedToPay: boolean
  isPayroll: boolean
}

const DetailPaymentTable = (props: IPaymentTableProps) => {
  const { data, editHandler, isAdmin, tableName, isNeedToPay, isPayroll, triggerShowRequestPaymentTable } = props

  let classNameOfTableName = 'red-color-text'
  if (isNeedToPay) {
    classNameOfTableName = 'green-color-text'
  }

  return (
    <React.Fragment>
      {Array.isArray(data) && data.length > 0 && (
        <div>
          <h5 className={`table-name ${classNameOfTableName}`}>{tableName}</h5>
          <table className="report-table">
            <thead>
              <tr>
                {!isPayroll && <th>Project Name</th>}
                {!isPayroll && <th>Time Total</th>}
                <th>Salary (VND)</th>
                {isNeedToPay && (
                  <React.Fragment>
                    <th>Is Paid</th>
                    <th>Paid Date</th>
                    <th>Is Received</th>
                    <th>Received Date</th>
                    {isAdmin && <th>Options</th>}
                  </React.Fragment>
                )}
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => {
                const requestPaymentList = item.requestPaymentList
                let isShowRequestPaymentList = false
                let isShowNewBadge = false
                if (requestPaymentList.length > 0) {
                  isShowRequestPaymentList = true
                  const newestRequest = requestPaymentList[0]
                  if (newestRequest.status === EStatusOfPaymentRequest.PENDING) {
                    isShowNewBadge = true
                  }
                }
                return (
                  <tr key={item._id} className={formatRow(index)}>
                    {!isPayroll && <td>{item.projectName}</td>}
                    {!isPayroll && <td>{getFormattedNumber(item.timeTotal, 1)}</td>}
                    <td>{getFormattedSalary(item.salary)}</td>
                    {isNeedToPay && (
                      <React.Fragment>
                        <td>
                          {isAdmin ? (
                            <RadioButton
                              value={item.isPaid}
                              clickHandler={() => editHandler(item._id, 'isPaid', item.isPaid)}
                            />
                          ) : (
                            <RadioButton value={item.isPaid} disabled />
                          )}
                        </td>
                        <td>{getFormattedDateTimeInEnglish(item.paidDate)}</td>
                        <td>
                          <RadioButton
                            value={item.isReceived}
                            clickHandler={() => editHandler(item._id, 'isReceived', item.isReceived)}
                          />
                        </td>
                        <td>{getFormattedDateTimeInEnglish(item.receivedDate)}</td>
                        {isAdmin && isShowRequestPaymentList && (
                          <td align="center">
                            <Box>
                              <Button
                                colorScheme="blue"
                                variant="outline"
                                onClick={() => triggerShowRequestPaymentTable(requestPaymentList)}>
                                Show Request List
                              </Button>
                              {isShowNewBadge && (
                                <Badge ml="1" colorScheme="green">
                                  New
                                </Badge>
                              )}
                            </Box>
                          </td>
                        )}
                      </React.Fragment>
                    )}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )}
    </React.Fragment>
  )
}

export default DetailPaymentTable
