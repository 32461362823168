import { IOverviewHighlightData } from 'API/report/constants'

export function extractData(data: IOverviewHighlightData) {
  const numberOfDeveloper = data?.numberOfDeveloper || 0
  const rateExchange = data?.rateExchange || 0
  const totalWorkingTime = data?.totalWorkingTime || 0
  const totalSalary = data?.totalSalary || 0
  const currencyName = data?.currencyName || 'VND'
  const rateExchangeUnit = `(VND/${currencyName})`

  return {
    numberOfDeveloper,
    rateExchange,
    totalWorkingTime,
    totalSalary,
    currencyName,
    rateExchangeUnit,
  }
}
