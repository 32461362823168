export const currency = {
  // Global Config Page - Currency &
  // [Project List Page & Project Detail & Project Create - Currency]
  value: '/currency',
  list: {
    value: '/currency/list',
  },
  currencyId: {
    value: (currencyId: string) => `/currency/${currencyId}`,
  },
}
