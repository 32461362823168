import { HStack, VStack, Stack } from '@chakra-ui/react'
import { SimpleGrid, Text } from '@chakra-ui/react'
import Button from 'components/Button'
import SingleSelect from 'components/SingleSelect'
import { FormProvider, useForm } from 'react-hook-form'
import { PartnerCategoryOfBirthday, MonthOfYear } from 'constants/defaultOptionList'
import { createOptionsOfSelect } from 'utils/dropdownOptionUtils'
import { useStores } from 'utils/hooks/useStores'
import styles from '../../index.module.scss'

const BirthdayFilterForm = () => {
  const methods = useForm()
  const { adminUserStore } = useStores()
  const { handleSubmit, reset } = methods
  const partnerCategoryOptions = createOptionsOfSelect(PartnerCategoryOfBirthday)

  function filterSubmitHandler(data) {
    adminUserStore.getBirthdayList(data)
  }

  function resetForm() {
    reset()
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit((data) => filterSubmitHandler(data))}>
        <VStack mb={3} p={6} paddingInline={{ base: 2, lg: 2 }} border="1px solid #E2E8F0" borderRadius="8px">
          <VStack padding={{ base: 2, lg: 2 }} width="full">
            <Text alignSelf={'flex-start'} className={styles.headerBox}>
              Filter
            </Text>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
              <SingleSelect
                name="month"
                label="Month"
                optionsData={MonthOfYear}
                placeHolder={'- Select month of year -'}
                isClearable={true}
              />
              <SingleSelect
                name="category"
                label="Partner Category"
                optionsData={partnerCategoryOptions}
                placeHolder={'- Select Partner Category -'}
                isClearable={true}
              />
            </SimpleGrid>
          </VStack>
          <Stack direction={{ xl: 'row', lg: 'row', md: 'row', sm: 'column' }}>
            <HStack>
              <Button colorScheme="red" onClick={resetForm} variant="outline">
                Cancel
              </Button>
              <Button variant="solid" type="submit">
                Submit
              </Button>
            </HStack>
          </Stack>
        </VStack>
      </form>
    </FormProvider>
  )
}

export default BirthdayFilterForm
