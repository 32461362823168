/* eslint-disable max-lines */
import { useEffect } from 'react'
import { SimpleGrid } from '@chakra-ui/layout'
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Modal,
} from '@chakra-ui/react'
import 'react-quill/dist/quill.snow.css'
import CheckboxFieldWithLabel from 'components/CheckboxFieldWithLabel'
import DatePicker from 'components/DatePicker'
import InputDropdown from 'components/Dropdown/InputDropdown'
import Editor from 'components/Editor'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import { useForm, FormProvider, UseFormReturn, Controller } from 'react-hook-form'
import { TypeOfPriority } from 'constants/defaultOptionList'
import { handleDatePickerChange } from 'utils/dateFormatUtils'
import { createOptionsOfSelect } from 'utils/dropdownOptionUtils'
import { generateUserOptions } from 'utils/user'
import DescriptionGuideline from '../DescriptionGuildline'
import ButtonOption from './components/ButtonOption'
import { ITodoOfHRForm, ITodoOfHRModalProps } from './constants'
import { ModalTextInput } from './todoOfHRModal.styles'
import './styles.scss'
import { IOption } from 'constants/common'

const TodoOfHRModal = (props: ITodoOfHRModalProps) => {
  const { isOpenModal, userNameList, selectedItem, isEdit, saveHandler, closeHandler } = props
  const nowDate: Date = new Date()
  const methods: UseFormReturn<ITodoOfHRForm> = useForm<ITodoOfHRForm>({
    defaultValues: {
      startDate: nowDate,
      dueDate: nowDate,
    },
  })
  const { control } = methods
  const {
    setValue: setFormValue,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods
  const priorityTypeOptions = createOptionsOfSelect(TypeOfPriority)
  const userOptions: IOption[] = generateUserOptions(userNameList)

  useEffect(() => {
    if (isEdit) {
      reset({
        id: selectedItem?.id,
        title: selectedItem?.title,
        description: selectedItem?.description,
        priority: { label: selectedItem?.priority, value: selectedItem?.priority },
        state: { label: selectedItem?.state, value: selectedItem?.state },
        startDate: selectedItem?.startDate,
        dueDate: selectedItem?.dueDate,
        assignee: { value: selectedItem?.assigneeData?.id, label: selectedItem?.assigneeData?.fullName },
        isRecurringMonthly: selectedItem?.isRecurringMonthly === true ?? false,
      })
    }
  }, [isOpenModal])

  return (
    <Modal onClose={closeHandler} isOpen={isOpenModal} isCentered size="xl" closeOnOverlayClick={false}>
      <FormProvider {...methods}>
        <form name="add-timesheet-form" onSubmit={handleSubmit(saveHandler)}>
          <ModalOverlay />
          {/* *INFO: get heigh value base current inputs in add timesheet form */}
          <ModalContent maxH={800}>
            <ModalHeader fontSize="lg" fontWeight={500} lineHeight={7} borderBottom="1px solid #E2E8F0" bg="white">
              {isEdit ? 'Edit Todo' : 'Create New Todo'}
              <ModalCloseButton color="black" background="none" mt="2" />
            </ModalHeader>
            <ModalBody overflowY="auto">
              <FormControl isInvalid={Boolean(errors?.taskName)}>
                <FormLabel fontSize="md" fontWeight="400">
                  Title
                </FormLabel>
                <HStack>
                  <ModalTextInput placeholder="Tile" {...register('title', { required: true })} />
                </HStack>
                <FormErrorMessage>{errors?.title?.message}</FormErrorMessage>
              </FormControl>
              <SimpleGrid columns={{ lg: 2, md: 2, sm: 1 }} marginY="24px" gap="24px">
                <FormControl>
                  <FormLabel fontWeight="400">Published Date</FormLabel>
                  <Controller
                    name="startDate"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        selectedDate={field.value ? dayjs(field.value).toDate() : undefined}
                        onChange={(date) => handleDatePickerChange(field, date)}
                        placement="top"
                      />
                    )}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel fontWeight="400">Published Date</FormLabel>
                  <Controller
                    name="dueDate"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        selectedDate={field.value ? dayjs(field.value).toDate() : undefined}
                        onChange={(date) => handleDatePickerChange(field, date)}
                        placement="top"
                      />
                    )}
                  />
                </FormControl>
              </SimpleGrid>
              <HStack mt="6">
                <FormControl>
                  <FormLabel fontSize="md" fontWeight="400">
                    Assignee
                  </FormLabel>
                  <InputDropdown
                    name="assignee"
                    placeHolder="Select User"
                    optionsData={userOptions}
                    isClearable={false}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel fontSize="md" fontWeight="400">
                    Priority
                  </FormLabel>
                  <InputDropdown
                    name="priority"
                    placeHolder="Select priority"
                    optionsData={priorityTypeOptions}
                    isClearable={false}
                  />
                </FormControl>
              </HStack>
              <HStack mt="6">
                <CheckboxFieldWithLabel name="isRecurringMonthly" label="Recurring Monthly" />
              </HStack>
              <Editor name="description" placeholder="Enter description" label="Content" />
              <DescriptionGuideline />
            </ModalBody>
            <ModalFooter bottom="0" bg="white" borderTop="1px solid #E2E8F0">
              <ButtonOption isEditMode={isEdit} onClose={closeHandler} />
            </ModalFooter>
          </ModalContent>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default observer(TodoOfHRModal)
