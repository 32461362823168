import { Checkbox } from '@chakra-ui/react'

export interface ICheckbox {
  name: string
  label: string
  isChecked?: boolean
  isDisabled?: boolean
}

const CheckboxField = (props: ICheckbox) => {
  const { name, label, isChecked, isDisabled } = props
  return (
    <Checkbox
      isChecked={isChecked}
      name={name}
      size="md"
      colorScheme="teal"
      pointerEvents="none"
      disabled={isDisabled}
      opacity={1}
      sx={{
        '.chakra-checkbox__control': {
          '&:not([data-checked])': { bg: 'white' },
          rounded: 'base',
          borderWidth: '1px',
        },
        '.chakra-checkbox__label': { fontSize: 'sm', color: 'gray.700' },
      }}>
      {label}
    </Checkbox>
  )
}

export default CheckboxField
