import { useState, useCallback, useMemo, useEffect } from 'react'
import { Container, Table as CkTable, Thead, Tbody, Tr, Th, Box, Stack } from '@chakra-ui/react'
import cx from 'classnames'
import { DndProvider } from 'react-dnd'
import { TouchBackend } from 'react-dnd-touch-backend'
import { useTable, Column } from 'react-table'
import Row from './components/Row'
import styles from './styles.module.scss'

export interface ITableDrapDropProps {
  tableData: any[]
  headers: Column<any>[]
  isBorder?: boolean
  hasBottomHeader?: boolean
  tableSize?: string
  isStriped?: boolean
  updateWhenMoveHandler?: (data) => void
}

const TableDrapDrop = (props: ITableDrapDropProps) => {
  const {
    headers,
    tableData,
    isBorder = true,
    hasBottomHeader = true,
    tableSize = 'md',
    isStriped = true,
    updateWhenMoveHandler,
  } = props
  const [records, setRecords] = useState([])

  useEffect(() => {
    setRecords(tableData)
  }, [tableData])

  const getRowId = useCallback((row) => {
    return row.id
  }, [])

  const columns = useMemo(() => headers, [headers] || [])
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    data: records,
    columns,
    getRowId,
  })

  const moveRow = (dragIndex: number, hoverIndex: number) => {
    const dragRecord = records[dragIndex]
    const newRecords = [...records]
    newRecords.splice(dragIndex, 1)
    newRecords.splice(hoverIndex, 0, dragRecord)
    updateWhenMoveHandler(newRecords)
    setRecords(newRecords)
  }

  return (
    <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
      <Stack width="inherit">
        <Container maxWidth="100%" padding="0px" margin="0px">
          <Box className={cx(styles.scrollAble, isBorder && styles.borderTable)}>
            <CkTable
              {...getTableProps()}
              size={tableSize}
              variant={isStriped ? 'striped' : undefined}
              colorScheme="gray"
              outline="none">
              <Thead className={styles.thead}>
                {headerGroups.map((headerGroup) => (
                  <Tr {...headerGroup.getHeaderGroupProps()}>
                    <Th></Th>
                    {headerGroup.headers.map((column) => (
                      <Th {...column.getHeaderProps()} whiteSpace="nowrap" py={[2, 4]} px={['2px', 2]}>
                        {column.render('Header')}
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody {...getTableBodyProps()} color="gray.700" fontWeight="400" fontSize="sm" className={styles.tbody}>
                {rows.map((row, index) => {
                  prepareRow(row)
                  return <Row key={`row-${index}`} index={index} row={row} moveRow={moveRow} {...row.getRowProps()} />
                })}
              </Tbody>
              {hasBottomHeader && rows.length > 4 && (
                <Thead className={cx(styles.thead, styles.bottomHeader)} whiteSpace="nowrap">
                  {headerGroups.map((headerGroup) => (
                    <Tr {...headerGroup.getHeaderGroupProps()}>
                      <Th></Th>
                      {headerGroup.headers.map((column) => (
                        <Th {...column.getHeaderProps()} whiteSpace="nowrap" py={[2, 4]} px={['2px', 2]}>
                          {column.render('Header')}
                        </Th>
                      ))}
                    </Tr>
                  ))}
                </Thead>
              )}
            </CkTable>
          </Box>
        </Container>
      </Stack>
    </DndProvider>
  )
}

export default TableDrapDrop
