import { ITableHeader } from 'types/table'
import * as Yup from 'yup'
import { IInternalDocument, IPartner, ICreatableOption } from 'constants/schema'

export const headerDesktop: ITableHeader[] = [
  {
    Header: 'Scope',
    accessor: 'scope',
  },
  {
    Header: 'Type',
    accessor: 'typeId',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'Number',
    accessor: 'number',
  },
  {
    Header: 'Belongs To Partner',
    accessor: 'partnerInfo', //name + category
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: '',
    accessor: 'actions',
  },
]

export const headerTabletAndMobile: ITableHeader[] = [
  {
    Header: 'Belongs To Partner',
    accessor: 'partnerInfo', //name + category
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: '',
    accessor: 'actions',
  },
]

export const initialValues = {}
export const validation = Yup.object().shape({
  year: Yup.number().min(1900).max(2100),
  month: Yup.number().min(1).max(12),
})
export interface Props {
  history: any
  match: any
  handleCloseConfirmDialog?: () => void
  handleOpenSnackbar?: ({ variant, message }) => void
}
export interface IDocumentFilter extends Omit<IInternalDocument, 'partnerId' | 'typeId' | 'scope'> {
  partnerId?: string[]
  typeId?: string[]
  scope?: string[]
}
export interface States {
  internalDocuments: IInternalDocument[]
  count: number
  filter?: IDocumentFilter
  partners?: IPartner[]
  doctypes?: ICreatableOption[]
  open?: boolean
  exportNow?: boolean
  isLoading?: boolean
}
