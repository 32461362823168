import TagWithoutFilter from 'components/Tag/TagWithoutFilter'
import { IOption } from 'constants/common'
import { getValidArray } from 'utils/commonUtils'

export interface ITagListProps {
  currentValues?: IOption[]
  name?: string
}

const TagList = (props: ITagListProps) => {
  const { currentValues, name } = props
  const fieldNameListHaveTextColor = ['type', 'technology']
  let textColor = 'gray.700'

  return getValidArray(currentValues).map((item: IOption) => {
    if (fieldNameListHaveTextColor.includes(name)) {
      textColor = item.color
    }
    return <TagWithoutFilter key={item.value} label={item.label} color={item.color} textColor={textColor} />
  })
}
export default TagList
