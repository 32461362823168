import React, { useState, useEffect } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
} from '@chakra-ui/react'
import { ICurrency } from 'API/currency/constants'
import Button from 'components/Button'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import { getModalTitle } from 'utils/commonUtils'

interface ICurrencyModal {
  isOpen?: boolean
  content?: string
  closeHandler?: () => void
  actionType?: string
  saveHandler?: (item: object) => void
  data?: ICurrency
  isSubmitSuccess?: boolean
}

const CurrencyModal = (props: ICurrencyModal) => {
  const { isOpen, closeHandler, saveHandler, actionType, data, isSubmitSuccess } = props
  const [valueField, setValueField] = useState<any>(null)
  const [rateExchangeField, setRateExchangeField] = useState<any>(null)
  const [errors, setErrors] = useState<any>({})

  useEffect(() => {
    if (data) {
      setValueField(data.value)
      setRateExchangeField(data.rateExchange)
    } else {
      setValueField(null)
      setRateExchangeField(null)
    }
    if (isSubmitSuccess) {
      setValueField(null)
      setRateExchangeField(null)
    }
  }, [data, isSubmitSuccess])

  function valueInputOnChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const inputValue = event.target.value
    if (inputValue) {
      setErrors({
        ...errors,
        value: null,
      })
    }
    setValueField(inputValue)
  }

  function rateExchangeInputOnChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const inputValue = event.target.value
    if (inputValue) {
      setErrors({
        ...errors,
        rateExchange: null,
      })
    }
    setRateExchangeField(inputValue)
  }

  function OKClickHandler(): void {
    let isValidForm = true
    const data = {
      value: valueField,
      rateExchange: rateExchangeField,
      id: get(props, 'data.id', ''),
    }
    let newErrors = {}
    if (!valueField) {
      isValidForm = false
      newErrors = {
        ...errors,
        value: {
          type: 'required',
          message: 'Currency name is required',
        },
      }
    }
    if (!rateExchangeField) {
      isValidForm = false
      newErrors = {
        ...newErrors,
        rateExchange: {
          type: 'required',
          message: 'Rate exchange is required',
        },
      }
    }
    setErrors(newErrors)

    if (isValidForm) {
      saveHandler(data)
    }
  }

  const modalTitle = getModalTitle(actionType, 'Currency')

  return (
    <Modal isOpen={isOpen} onClose={closeHandler} isCentered>
      <ModalOverlay />
      <ModalContent maxHeight="350px">
        <ModalHeader>{modalTitle}</ModalHeader>
        <ModalCloseButton color={'gray'} background="none" cursor="pointer" />
        <ModalBody paddingY={0}>
          <FormControl isInvalid={!!get(errors, 'value') ? true : false}>
            <FormLabel fontWeight={'normal'} marginTop={4}>
              Currency
            </FormLabel>
            <Input
              value={valueField}
              autoFocus={true}
              placeholder="Enter Currency Name"
              onChange={valueInputOnChange}
              boxSizing="border-box"
            />
            <FormErrorMessage>{get(errors, 'value.message', '')}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!get(errors, 'rateExchange') ? true : false}>
            <FormLabel fontWeight={'normal'} marginTop={4}>
              Rate Exchange
            </FormLabel>
            <Input
              value={rateExchangeField}
              autoFocus={false}
              type="number"
              placeholder="Enter Rate Exchange"
              onChange={rateExchangeInputOnChange}
              boxSizing="border-box"
            />
            <FormErrorMessage>{get(errors, 'rateExchange.message', '')}</FormErrorMessage>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" customStyles={{ marginRight: '10px' }} onClick={closeHandler}>
            Cancel
          </Button>
          <Button onClick={OKClickHandler} variant="solid">
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default observer(CurrencyModal)
