import { Input } from '@chakra-ui/react'
import styles from '../index.module.scss'

export interface IInputLabelFormProps {
  isMulti?: boolean
  formatedValue?: string
  isTagListFormat?: boolean
  isTagFormat?: boolean
  readonly?: boolean
  disabledProps?: any
  isHighligh?: boolean
  isBadgeImage?: boolean
}
const InputLabelForm = (props: IInputLabelFormProps) => {
  const { isMulti, formatedValue, isTagListFormat, isTagFormat, readonly, disabledProps, isHighligh, isBadgeImage } =
    props
  if (!isMulti && !!formatedValue && !isTagListFormat && !isBadgeImage) {
    return (
      <Input
        className={isTagFormat ? styles.tagFormat : ''}
        paddingLeft={'0px'}
        readOnly={readonly}
        {...disabledProps}
        value={formatedValue}
        fontSize="16px"
        color={isHighligh ? 'blue.500' : 'gray.700'}
        fontWeight={'500'}
        width={'90%'}
        border={'0px'}
        _hover={{
          bg: 'white',
        }}
      />
    )
  }
  return null
}
export default InputLabelForm
