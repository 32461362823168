import React from 'react'
import { SimpleGrid, Text } from '@chakra-ui/react'
import FormInput from 'components/FormInput'
import styles from '../../../contract.module.scss'

const ENInformation = () => {
  return (
    <React.Fragment>
      <Text alignSelf={'flex-start'} className={`${styles.headerBox} ${styles.border}`}>
        English Information
      </Text>
      <SimpleGrid columns={{ base: 1, md: 3, lg: 3 }} gap={{ base: 6, lg: 8 }} width="full">
        <FormInput name="fullNameEN" label="Full Name" />
        <FormInput name="genderEN" label="Gender" />
        <FormInput name="nationalityEN" label="Nationality" />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 3, lg: 3 }} gap={{ base: 6, lg: 8 }} width="full">
        <FormInput name="addressEN" label="Address" />
        <FormInput name="publishedDateInfoEN" label="Published Date And Place" />
        <FormInput name="placeOfBirthEN" label="Place Of Birth" />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 4, lg: 4 }} gap={{ base: 6, lg: 8 }} width="full">
        <FormInput name="IDIssuePlaceEN" label="ID Issue Place" />
        <FormInput name="educationLevelEN" label="Education" />
        <FormInput name="majorEN" label="Major" />
        <FormInput name="jobTitleEN" label="Job title" />
      </SimpleGrid>
    </React.Fragment>
  )
}

export default ENInformation
