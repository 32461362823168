import React from 'react'
import orderBy from 'lodash/orderBy'
import ReactQuill from 'react-quill'
import { getValidArray } from 'utils/commonUtils'
import { getUserLabel } from 'utils/user'
import { getFormattedNumber, getFormattedSalary } from 'utils/numberUtils'
import { getFormattedDateTimeInEnglish } from 'utils/dateFormatUtils'
import { handleUpdateDescription } from '../../utils'

function formatRow(index) {
  let result = 'even'
  if (index % 2 === 0) {
    result = 'odd'
  }
  return result
}

class BillReportTable extends React.Component {
  renderSalaryInfo = (item) => {
    const role = window.localStorage.getItem('userRole')
    if (role === 'admin') {
      return (
        <p>
          <span className="highlight">Salary total:</span> {getFormattedNumber(item.salaryTotal, 1)} (VND)
        </p>
      )
    }
  }
  renderHeaderTableWithDiffCategoryProject = (item) => {
    const { paymentCategory } = item
    if (paymentCategory === 'Fixed Price') {
      return <th>Remuneration</th>
    } else {
      return <th>Hourly Rate</th>
    }
  }
  renderBodyTableWithDiffCategoryProject = (item, reportDetail) => {
    const { paymentCategory } = item
    if (paymentCategory === 'Fixed Price') {
      return <td>{reportDetail.remuneration}</td>
    } else {
      return <td>{reportDetail.defaultRating}</td>
    }
  }
  render() {
    const { billReportList } = this.props
    const sortedBillReportList = orderBy(
      getValidArray(billReportList),
      ['developer.isDeleted', 'developer.isArchived'],
      ['asc', 'asc']
    )

    return (
      <div>
        {getValidArray(sortedBillReportList).map((item, indexOfItem) => {
          const developerLabel = getUserLabel(item?.developer)

          return (
            <div className="container-fluid" key={indexOfItem}>
              <div className="box box-default">
                <div className="box-header highlight">
                  {item?.project} {developerLabel && `| ${developerLabel}`}
                </div>
                <div className="box-divider" />
                <div className={`box-body`}>
                  <div className="highlight-indicators">
                    <p className="title">Highlight indicators</p>
                    <p>
                      <span className="highlight">Working time total:</span>{' '}
                      {getFormattedNumber(item.workingTimeTotal, 1)} (hours)
                    </p>
                    {item.paymentCategory === 'Fixed Price' && (
                      <p>
                        <span className="highlight">Remuneration total:</span>{' '}
                        {getFormattedNumber(item.remunerationTotal, 1)} ({item.currency})
                      </p>
                    )}
                    {this.renderSalaryInfo(item)}
                  </div>
                  <div className="table-content">
                    <table className="report-table">
                      <thead>
                        <tr>
                          <th>Task Name</th>
                          <th>Description</th>
                          <th>Start time</th>
                          <th>End Time</th>
                          <th>Time Total</th>
                          <th>Rate Exchange</th>
                          {this.renderHeaderTableWithDiffCategoryProject(item)}
                          <th>Salary (VND)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {item.data &&
                          item.data.map((reportDetail, index) => (
                            <tr key={reportDetail._id} className={formatRow(index)}>
                              <td>{reportDetail.taskName}</td>
                              <td className="description">
                                <ReactQuill
                                  className="task-description"
                                  value={handleUpdateDescription(reportDetail.description)}
                                  theme="bubble"
                                  readOnly
                                />
                              </td>
                              <td>{getFormattedDateTimeInEnglish(reportDetail.startTime)}</td>
                              <td>{getFormattedDateTimeInEnglish(reportDetail.endTime)}</td>
                              <td>{getFormattedNumber(reportDetail.timeTotal, 1)}</td>
                              <td>{reportDetail.rateExchange}</td>
                              {this.renderBodyTableWithDiffCategoryProject(item, reportDetail)}
                              <td>{getFormattedSalary(reportDetail.salary)}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}

export default BillReportTable
