import Button from 'components/Button'
import { UncontrolledDropdown } from 'reactstrap'
import { IOptionButton } from './interfaces'
import styles from './moreDropdown.module.scss'

const OptionButtonsWithoutDropBox = (props: IOptionButton) => {
  const { isEdit, editActionHandler, isBackground, isDelete, deleteActionHandler } = props

  return (
    <div className={styles.container}>
      <UncontrolledDropdown className={isBackground ? styles.groupButtonBackground : styles.groupButton}>
        {isEdit && (
          <Button
            variant="ghost"
            backgroundColor="transparent"
            customStyles={{ padding: '6px' }}
            leftIcon="edit-fill.svg"
            onClick={editActionHandler}></Button>
        )}
        {isDelete && (
          <Button
            variant="ghost"
            backgroundColor="transparent"
            customStyles={{ padding: '6px' }}
            leftIcon="delete-bin-fill.svg"
            onClick={deleteActionHandler}></Button>
        )}
      </UncontrolledDropdown>
    </div>
  )
}

export default OptionButtonsWithoutDropBox
