import { useState, useEffect } from 'react'
import { Box, useMediaQuery, Text } from '@chakra-ui/react'
import { getErrorMessage } from 'API/error'
import ConfirmYesNoDeleteModal from 'components/ConfirmYesNoDeleteModal'
import Icon from 'components/Icon'
import Table from 'components/Table'
import MoreDropdown from 'components/Table/DesktopTable/components/MoreDropdown'
import { History } from 'history'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { backgroundGrey200 } from 'theme/globalStyles'
import { ILeaveFilter } from 'types/leave'
import { ILeave } from 'types/leave'
import { IRowActionItem } from 'types/table'
import { maxMobileSize, maxTabletSize } from 'constants/common'
import { ELeaveManagementSubTitle } from 'constants/enum'
import { ELeaveStatus } from 'constants/enum'
import { limitItemPerPage } from 'constants/index'
import { Messages } from 'constants/index'
import { frontendRoutes } from 'constants/routes'
import { getValidArray } from 'utils/commonUtils'
import { useStores } from 'utils/hooks/useStores'
import HeaderSection from './components/HeaderSection'
import { ILeaveFormData } from './components/LeaveForm/interface'
import LeaveModal from './components/LeaveModal'
import { getHeaderList } from './constant'
import { generateLeavePath } from './container'
import styles from './userLeaveList.module.scss'

const UserLeaveList = () => {
  window.sessionStorage.setItem('subPageTitle', ELeaveManagementSubTitle.MY_LEAVE)
  window.dispatchEvent(new Event('storage'))
  const [isMobile] = useMediaQuery(maxMobileSize)
  const [isTablet] = useMediaQuery(maxTabletSize)
  const history: History = useHistory()
  const { leaveStore } = useStores()
  const { userLeaveList, countPagination, isLoading } = leaveStore
  const initialFilter = queryString.parse(history.location.search, { parseBooleans: true })
  const [selectedLeave, setSelectedLeave] = useState<ILeave>(null)

  const [filter, setFilter] = useState<ILeaveFilter>(initialFilter ?? {})
  const [isCreateLeaveModalOpen, setIsCreateLeaveModalOpen] = useState<boolean>(false)
  const [isSummaryModalOpen, setIsSummaryModalOpen] = useState<boolean>(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false)
  const [isOpenDeleteLeaveConfirm, setIsOpenDeleteLeaveConfirm] = useState<boolean>(false)

  function openCreateLeaveModal() {
    setIsCreateLeaveModalOpen(true)
  }

  function closeCreateLeaveModal() {
    setIsCreateLeaveModalOpen(false)
  }

  function openSummaryModal(leave: ILeave) {
    setSelectedLeave(leave)
    setIsSummaryModalOpen(true)
  }

  function closeSummaryModal() {
    setIsSummaryModalOpen(false)
  }

  function openEditModal(leave: ILeave) {
    setSelectedLeave(leave)
    setIsEditModalOpen(true)
  }

  function closeEditModal() {
    setIsEditModalOpen(false)
  }

  function openConfirmDeleteModal(leave: ILeave) {
    setSelectedLeave(leave)
    setIsOpenDeleteLeaveConfirm(true)
  }

  function closeConfirmDeleteModal() {
    setIsOpenDeleteLeaveConfirm(false)
  }

  async function handleCreateLeave(data: ILeaveFormData) {
    try {
      leaveStore.isLoading = true

      // Just when use dummy leaves on local storage
      let existingLeaves = JSON.parse(localStorage.getItem('dummyLeaves') || '[]')
      existingLeaves.unshift(data)
      await localStorage.setItem('dummyLeaves', JSON.stringify(existingLeaves))

      toast.success(Messages.createLeaveRequestSuccess)
      closeCreateLeaveModal()
      leaveStore.getUserLeaveListWithPagination(filter)
    } catch (error) {
      const errorMessage: string = getErrorMessage(error)
      toast.error(errorMessage)
    }
  }

  async function handleEditLeave(data: ILeaveFormData) {
    try {
      leaveStore.isLoading = true
      // Just when use dummy leaves on local storage
      let existingLeaves = JSON.parse(localStorage.getItem('dummyLeaves') || '[]')
      existingLeaves.unshift(data)
      await localStorage.setItem('dummyLeaves', JSON.stringify(existingLeaves))
      closeCreateLeaveModal()
      toast.success(Messages.editLeaveRequeSuccess)
      leaveStore.getUserLeaveListWithPagination(filter)
    } catch (error) {
      const errorMessage: string = getErrorMessage(error)
      toast.error(errorMessage)
    }
  }

  async function handleDeleteLeave(id: string): Promise<void> {
    try {
      await leaveStore.deleteLeave(id)
      setIsOpenDeleteLeaveConfirm(false)
      toast.success(Messages.deleteLeaveRequestSuccess)
    } catch (error) {
      const errorMessage: string = getErrorMessage(error)
      toast.error(errorMessage)
    }
  }

  const pageIndex: number = Number(get(filter, 'page', 1))
  const pagination = {
    includePagination: true,
    pageIndex,
    pageSize: limitItemPerPage,
    tableLength: countPagination,
    gotoPage: (page: number) => {
      const changedFilter = { ...filter, page }
      setFilter(changedFilter)
      history.push({
        pathname: frontendRoutes.leaveManagementPage.leaveManagementForUser.myLeave.value,
        state: { page, filter },
        search: queryString.stringify(changedFilter),
      })

      leaveStore.getUserLeaveListWithPagination(filter)
    },
  }

  const dataInTable = getValidArray(userLeaveList).map((leave) => {
    const pathname = generateLeavePath(leave, frontendRoutes.leaveManagementPage.leaveManagementForUser.myLeave.value)
    const actionDetailHandler = () => history.push(pathname)

    const actions: IRowActionItem[] = []
    actions.push(
      {
        name: 'Edit leave request',
        handler: () => openEditModal(leave),
      },
      {
        name: 'Delete Leave',
        customClassNames: ['isDanger'],
        handler: () => openConfirmDeleteModal(leave),
      }
    )

    return {
      ...leave,
      actions:
        leave.status === ELeaveStatus.PENDING ? (
          <MoreDropdown isSummary summaryActionHandler={() => openSummaryModal(leave)} actions={actions} />
        ) : (
          <MoreDropdown isSummary summaryActionHandler={() => openSummaryModal(leave)} />
        ),
    }
  })

  useEffect(() => {
    leaveStore.getUserLeaveListWithPagination(filter)
  }, [filter])

  return (
    <Box>
      <Box
        className={styles.leaveContainer}
        background={isMobile ? 'none' : 'white'}
        paddingY={isMobile ? 2 : 4}
        paddingX={isMobile ? 0 : 4}
        borderRadius="6px"
        border={isMobile ? 'none' : `1px solid ${backgroundGrey200}`}>
        <HeaderSection handleCreate={openCreateLeaveModal} />
        <Box className={styles.body} paddingY={3} paddingX={4}>
          <Icon iconName="information-blue-fill-circle.svg" size={19} />
          <Text margin="0 0 0 5px">
            Your remaining leave days: <span> 6 days </span>
          </Text>
          {isCreateLeaveModalOpen && (
            <LeaveModal
              isLoading={isLoading}
              isOpen={isCreateLeaveModalOpen}
              closeHandler={closeCreateLeaveModal}
              saveHandler={handleCreateLeave}
            />
          )}
          {isSummaryModalOpen && (
            <LeaveModal
              leaveSelectedData={selectedLeave}
              isLoading={isLoading}
              isSummary={true}
              isOpen={isSummaryModalOpen}
              closeHandler={closeSummaryModal}
            />
          )}
          {isEditModalOpen && (
            <LeaveModal
              leaveSelectedData={selectedLeave}
              isLoading={isLoading}
              isEdit={true}
              isOpen={isEditModalOpen}
              closeHandler={closeEditModal}
              saveHandler={handleEditLeave}
            />
          )}
          <ConfirmYesNoDeleteModal
            isOpen={isOpenDeleteLeaveConfirm}
            title="leave request"
            closeHandler={closeConfirmDeleteModal}
            OKClickHandler={handleDeleteLeave}
            itemId={selectedLeave?.id}
          />
        </Box>
        <Table
          headerList={getHeaderList(isTablet)}
          isLoading={leaveStore.isUserLeaveListLoading}
          tableData={dataInTable}
          isStriped
          pagination={pagination}
          hasBottomHeader={!isMobile}
        />
      </Box>
    </Box>
  )
}
export default observer(UserLeaveList)
