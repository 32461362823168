import { Box, SimpleGrid } from '@chakra-ui/layout'
import { VStack, HStack } from '@chakra-ui/react'
import { ELeaveType } from 'constants/enum'
import { stripHtmlTags, convertDateToString } from 'utils/commonUtils'
import { ILeaveSummaryProps } from './interface'
import { Information, Label, Flex, StatusText, TypeText } from './leaveRequestSummary.styles'

const LeaveSummary = (props: ILeaveSummaryProps) => {
  const { methods, leaveSelectedData } = props
  const {
    control,
    formState: { errors, isSubmitSuccessful },
    clearErrors,
  } = methods

  return (
    <Box>
      <SimpleGrid columns={1} gap="24px">
        <VStack spacing={6} alignItems="flex-start">
          <Flex>
            <Label>Start - End time</Label>
            <Information>{leaveSelectedData.startEndTime || 'N/A'}</Information>
          </Flex>
          <HStack width="100%">
            <HStack width="50%">
              <Flex>
                <Label>Created date</Label>
                <Information>{convertDateToString(leaveSelectedData.createdDate) || 'N/A'}</Information>
              </Flex>
            </HStack>
            <HStack width="50%">
              <Flex>
                <Label>Type</Label>
                <TypeText type={leaveSelectedData?.type}>{leaveSelectedData?.type || 'N/A'}</TypeText>
              </Flex>
            </HStack>
          </HStack>
          <Flex>
            <Label>Reason</Label>
            <Information width="100%">{stripHtmlTags(leaveSelectedData?.content || 'N/A')}</Information>
          </Flex>
          <HStack width="100%">
            <HStack width="50%">
              <Flex>
                <Label>Commit time</Label>
                <Information>
                  {leaveSelectedData?.type === ELeaveType.REMOTE ? leaveSelectedData?.commitTime || 'N/A' : '-'}
                </Information>
              </Flex>
            </HStack>
            <HStack width="50%">
              <Flex>
                <Label>Status</Label>
                <StatusText status={leaveSelectedData?.status}>{leaveSelectedData?.status || 'N/A'}</StatusText>
              </Flex>
            </HStack>
          </HStack>
          <Flex>
            <Label>Approver</Label>
            <Information>{leaveSelectedData?.approver || 'Not yet approved'}</Information>
          </Flex>
        </VStack>
      </SimpleGrid>
    </Box>
  )
}

export default LeaveSummary
