export const COLOR = { userRadarChart: '#ED64A6', averageRadarChart: '#4D5DFB', axisColor: '#DFDFDF' }
export const SKILL = [
  'workingAttitude',
  'technicalSkills',
  'teamPlayer',
  'communication',
  'problemSolving',
  'english',
  'meetDeadlines',
]
