import { useState } from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { Box, HStack, Text } from '@chakra-ui/react'
import { Calendar } from '@fullcalendar/core'
import Button from 'components/Button'
import { ECalendarOfTodoListOfHRViewType } from 'components/Calendar/constants'
import DatePicker from 'react-datepicker'
import { getLabelViewModeOfTodoListOfHR } from 'utils/commonUtils'
import { ViewModeSelectItem } from '../../humanResources.styles'

const HeaderSection = (props) => {
  const {
    viewMode,
    setViewMode,
    calendarRef,
    handleOpenModal,
    dateTitle,
    handleDateTitle,
    handleDateTitleOfYearCalendar,
  } = props
  const [startDate, setStartDate] = useState(new Date())
  const [datePickerVisible, setDatePickerVisible] = useState(false)

  function onClickNextNavigation(): void {
    const calendarApi: Calendar = calendarRef.current?.getApi()
    calendarApi.next()
    handleDateTitle()
  }
  function selectedDateHandler(date: Date): void {
    switch (viewMode) {
      case ECalendarOfTodoListOfHRViewType.DAY_GRID_MONTH:
        setDatePickerVisible(false)
        setStartDate(date)
        navigateToSelectedDate(date)
        break
      case ECalendarOfTodoListOfHRViewType.DAY_GRID_YEAR:
        setDatePickerVisible(false)
        setStartDate(date)
        handleDateTitleOfYearCalendar(date)
        break
    }
  }
  function renderDatePicker() {
    if (viewMode === ECalendarOfTodoListOfHRViewType.DAY_GRID_MONTH) {
      return <DatePicker selected={startDate} onChange={(date) => selectedDateHandler(date)} inline />
    }
    return (
      <DatePicker
        selected={startDate}
        onChange={(date) => selectedDateHandler(date)}
        showYearPicker
        dateFormat="yyyy"
      />
    )
  }
  function navigateToSelectedDate(date: Date): void {
    const calendarApi: Calendar = calendarRef.current?.getApi()
    calendarApi.gotoDate(date)
    handleDateTitle()
  }
  function onClickTodayNavigation(): void {
    const calendarApi: Calendar = calendarRef.current?.getApi()
    calendarApi.today()
    handleDateTitle()
  }

  function onClickPreviousNavigation(): void {
    const calendarApi: Calendar = calendarRef.current?.getApi()
    calendarApi.prev()
    handleDateTitle()
  }
  function onClickViewMode(currentView: ECalendarOfTodoListOfHRViewType): void {
    setViewMode(currentView)
  }
  function toggleDatePicker(): void {
    setDatePickerVisible(!datePickerVisible)
  }

  return (
    <HStack justifyContent="space-between" width="full" height={5} alignItems="center">
      <HStack>
        {Object.values(ECalendarOfTodoListOfHRViewType).map((item, index) => (
          <ViewModeSelectItem
            key={index}
            isActive={item === viewMode}
            onClick={() => {
              onClickViewMode(item)
            }}>
            {getLabelViewModeOfTodoListOfHR(item)}
          </ViewModeSelectItem>
        ))}
      </HStack>
      <HStack>
        <ChevronLeftIcon cursor="pointer" boxSize={6} onClick={onClickPreviousNavigation} />
        <Box style={{ position: 'relative' }}>
          <Text
            fontWeight="bold"
            cursor="pointer"
            color="gray.800"
            fontSize="md"
            onClick={toggleDatePicker}
            _hover={{
              backgroundColor: '#e2e8f0',
            }}>
            {dateTitle}
          </Text>
          {datePickerVisible && <Box style={{ position: 'absolute', zIndex: 9999 }}>{renderDatePicker()}</Box>}
        </Box>
        <Button variant="outline" onClick={onClickTodayNavigation}>
          Today
        </Button>
        <ChevronRightIcon cursor="pointer" boxSize={6} onClick={onClickNextNavigation} />
      </HStack>
      <HStack alignItems="center" spacing={4}>
        <Button leftIcon="add-white.svg" onClick={handleOpenModal}>
          Add Todo
        </Button>
      </HStack>
    </HStack>
  )
}

export default HeaderSection
