import { useEffect, useState } from 'react'
import { HStack, IconButton, Menu, MenuButton, MenuList } from '@chakra-ui/react'
import { ReactComponent as UserSearchIcon } from 'assets/images/svg/user-search.svg'
import { IChakraInputDropdownProps } from 'components/Dropdown/types'
import SingleSelect from 'components/SingleSelect'
import { useFormContext } from 'react-hook-form'
import { generatePath, useHistory } from 'react-router-dom'
import { frontendRoutes } from 'constants/routes'

const SingleSelectWithNavigation = (props: IChakraInputDropdownProps) => {
  const history = useHistory()
  const { watch } = useFormContext()
  const selectedUser = watch('selectedUser')
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect(() => {
    if (selectedUser) {
      const dashBoard = generatePath(frontendRoutes.userDashboardPage.detail.id.value, {
        id: selectedUser.value,
      })
      setIsOpen(false)
      history.push(dashBoard)
    }
  }, [selectedUser])

  return (
    <HStack>
      <Menu isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <MenuButton
          as={IconButton}
          icon={<UserSearchIcon />}
          aria-label="Select User"
          size="md"
          variant="ghost"
          rounded="full"
          zIndex={1000}
          onClick={() => setIsOpen((prev) => !prev)}
        />
        <MenuList padding={0}>
          <SingleSelect {...props} />
        </MenuList>
      </Menu>
    </HStack>
  )
}

export default SingleSelectWithNavigation
