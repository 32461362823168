import { Box as CKBox, Button as CKButton, StackItem as CKStackItem, chakra, styled } from '@chakra-ui/react'
import { BaseStyle } from 'types/common'
import { chakraShouldForwardProp } from 'utils/commonUtils'

export const DiagramWrapper = chakra(CKBox, {
  baseStyle: () => ({
    height: '80vh',
    width: '100%',
    border: '1px solid',
    borderColor: 'gray.200',
    borderRadius: '6px',
    flex: '4',
    overflow: 'scroll',
  }),
})

export const DiagramNodeWrapper = chakraShouldForwardProp(CKButton, (props: BaseStyle) => {
  const { isUserNode } = props
  return {
    background: isUserNode ? 'blue.50' : 'orange.50',
    border: '1px solid',
    borderColor: isUserNode ? 'blue.500' : 'orange.500',
    borderRadius: '6px',
    height: '6',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
})

export const StackItem = styled(chakra(CKStackItem), {
  baseStyle: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
})

export const Container = chakra(CKBox, {
  baseStyle: () => ({
    background: 'white',
    position: 'sticky',
    top: '64px',
  }),
})
