import { ModalBody } from '@chakra-ui/modal'
import LeaveForm from '../../LeaveForm/index'
import LeaveSummary from '../../LeaveSummary/index'
import { LeaveModalBodyProps } from './interface'

const LeaveModalBody = ({ isSummary, methods, leaveSelectedData }: LeaveModalBodyProps) => (
  <ModalBody overflowY="auto" paddingTop="20px">
    {isSummary ? (
      <LeaveSummary methods={methods} leaveSelectedData={leaveSelectedData} />
    ) : (
      <LeaveForm methods={methods} leaveSelectedData={leaveSelectedData} />
    )}
  </ModalBody>
)

export default LeaveModalBody
