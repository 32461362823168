import Table from 'components/Table'
import { observer } from 'mobx-react'
import { getValidArray } from 'utils/commonUtils'
import { getFormattedDateWith_MMMDDYYYY } from 'utils/dateFormatUtils'
import { useStores } from 'utils/hooks/useStores'
import { getFixedProjectHeaderList } from '../constants'

const FixedProjectRatings = () => {
  const { userProfileStore } = useStores()
  const { projectRatings } = userProfileStore
  const fixedProjectRatings = projectRatings?.fixedProjectRatings

  const tableData = getValidArray(fixedProjectRatings).map((projectRate) => {
    return {
      ...projectRate,
      futureApplyDate: projectRate.futureApplyDate ? getFormattedDateWith_MMMDDYYYY(projectRate.futureApplyDate) : '',
      currentApplyDate: projectRate.currentApplyDate
        ? getFormattedDateWith_MMMDDYYYY(projectRate.currentApplyDate)
        : '',
    }
  })
  return <Table headerList={getFixedProjectHeaderList()} tableData={tableData} isStriped hasBottomHeader={false} />
}

export default observer(FixedProjectRatings)
