import apiV2 from 'API/api'
import { APIRouters } from 'API/APIRouters'
import { AxiosResponse } from 'axios'
import {
  CURRENCY_BASE_URL,
  ICurrencyListResponse,
  ICurrency,
  ICurrencyListPaginationRequest,
  ICurrencyCreationForm,
} from './constants'

export async function getCurrencyListAPI(): Promise<ICurrencyListResponse> {
  try {
    const url: string = APIRouters.currency.list.value
    const response: AxiosResponse = await apiV2.get(url)
    return {
      currencies: response?.data?.currencies || [],
      count: response?.data?.count || 0,
    }
  } catch (error) {
    throw error
  }
}

export async function getPaginatedCurrencyListAPI(
  payload: ICurrencyListPaginationRequest
): Promise<ICurrencyListResponse> {
  try {
    const url: string = APIRouters.currency.list.value
    const response: AxiosResponse = await apiV2.post(url, payload)
    return {
      currencies: response?.data?.currencies || [],
      count: response?.data?.count || 0,
    }
  } catch (error) {
    throw error
  }
}

export async function createCurrencyAPI(currencyData: ICurrencyCreationForm): Promise<string> {
  try {
    const url: string = `${CURRENCY_BASE_URL}`
    const response: AxiosResponse = await apiV2.post(url, currencyData)
    return response?.data?.message || ''
  } catch (error) {
    throw error
  }
}

export async function updateCurrencyAPI(currency: ICurrency): Promise<string> {
  try {
    const url: string = APIRouters.currency.currencyId.value(currency.id)
    const response: AxiosResponse = await apiV2.patch(url, currency)
    return response?.data?.message || ''
  } catch (error) {
    throw error
  }
}

export async function deleteCurrencyAPI(currencyId: string): Promise<string> {
  try {
    const url: string = APIRouters.currency.currencyId.value(currencyId)
    const response: AxiosResponse = await apiV2.delete(url)
    return response?.data?.message || ''
  } catch (error) {
    throw error
  }
}
