import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, Text } from '@chakra-ui/react'
import Button from 'components/Button'
import { textGrey700 } from 'theme/globalStyles'

export interface IReInitializeDiagramModalProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  isLoading: boolean
}

const ReInitializeDiagramModal = (props: IReInitializeDiagramModalProps) => {
  const { isOpen, onClose, onConfirm, isLoading } = props

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false} closeOnEsc={false}>
      <ModalOverlay />
      <ModalContent height="fit-content !important">
        <ModalHeader lineHeight={7} color={textGrey700} fontWeight={700} fontSize="18px">
          Re-initialize Diagram Coordinations
        </ModalHeader>
        <ModalBody>
          <Text color={textGrey700} marginTop={0}>
            There are some nodes with missing coordinates. Please confirm to <b>re-initialize</b> the coordinates for{' '}
            <b>those nodes</b>.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onConfirm} isLoading={isLoading}>
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ReInitializeDiagramModal
