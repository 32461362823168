import { ResponsiveLine } from '@nivo/line'
import { observer } from 'mobx-react'
import { nivoTheme } from 'containers/Dashboard/constant'
import { getProjectShortName } from 'containers/Dashboard/DashboardUser/utils'
import { getValidArray } from 'utils/commonUtils'
import { useStores } from 'utils/hooks/useStores'
import { legendProps } from './constants'

const MonthlyPerformanceChart = () => {
  const { userProfileStore } = useStores()
  const { dashboardData } = userProfileStore
  const { performanceLineData } = dashboardData
  return (
    <ResponsiveLine
      data={getValidArray(performanceLineData).map((item) => ({
        ...item,
        id: item?.name,
      }))}
      theme={nivoTheme}
      margin={{ top: 20, right: 20, bottom: 110, left: 20 }}
      colors={(e) => e?.color}
      enableGridX={true}
      enableGridY={true}
      gridXValues={[0]}
      gridYValues={[0]}
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        stacked: false,
        reverse: false,
      }}
      yFormat={(e) => {
        const hour = Math.floor(Number(e))
        const min = Math.round((Number(e) - hour) * 60)
        return `${hour}h` + (min ? ` ${min}m` : '')
      }}
      enableSlices="x"
      isInteractive
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
      }}
      pointSize={8}
      pointColor={{ from: 'color', modifiers: [] }}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={-12}
      useMesh={true}
      legends={
        performanceLineData.length <= 4
          ? [
              {
                ...legendProps,
                data: getValidArray(performanceLineData)
                  .filter((item) => item.name !== 'Total')
                  .map((item) => ({
                    id: item?.name,
                    label: getProjectShortName(item?.name),
                    color: item?.color,
                  })),
                translateY: 80,
                symbolSize: 16,
                itemsSpacing: 16,
              },
            ]
          : [
              {
                ...legendProps,
                data: getValidArray(performanceLineData)
                  .filter((item) => item.name !== 'Total')
                  .slice(0, Math.floor(performanceLineData.length / 2))
                  .map((item) => ({
                    id: item?.name,
                    label: getProjectShortName(item?.name),
                    color: item?.color,
                  })),
                translateY: 80,
                symbolSize: 16,
                itemsSpacing: 16,
              },
              {
                ...legendProps,
                data: getValidArray(performanceLineData)
                  .filter((item) => item.name !== 'Total')
                  .slice(Math.floor(performanceLineData.length / 2))
                  .map((item) => ({
                    id: item?.name,
                    label: getProjectShortName(item?.name),
                    color: item?.color,
                  })),
                translateY: 104,
                symbolSize: 16,
                itemsSpacing: 16,
              },
            ]
      }
    />
  )
}

export default observer(MonthlyPerformanceChart)
