import ReactQuill from 'react-quill'
import { getFormattedNumber } from 'utils/numberUtils'
import { handleUpdateDescription } from '../../../utils'

export function getReportSectionHeaderListWithFilterByProject(currencyName) {
  const headers = [
    {
      Header: 'TASK NAME',
      accessor: 'taskName',
      // id: "expander",
      Cell: ({ row }) => <span {...row.getToggleRowExpandedProps()}>{row.original.taskName}</span>,
    },
    {
      Header: 'DESCRIPTION',
      accessor: 'description',
      // id: "expander",
      Cell: ({ row }) => (
        <td className="description" {...row.getToggleRowExpandedProps()}>
          <ReactQuill
            className="task-description"
            value={handleUpdateDescription(row.original.description)}
            theme="bubble"
            readOnly
          />
        </td>
      ),
    },
    {
      Header: 'HOURLY RATE',
      accessor: 'defaultRating',
      Cell: ({ row }) => (
        <p className="text-align-center" {...row.getToggleRowExpandedProps()}>
          {row.original.defaultRating}
        </p>
      ),
    },
    {
      Header: 'CLIENT RATE',
      Cell: ({ row }) => (row.original.clientRate ? `${row.original.clientRate} (${currencyName}/hour)` : ''),
    },
    {
      Header: 'BILLABLE MONTHLY AMOUNT (VND/month)',
      Cell: ({ row }) =>
        row.original.billableMonthlyAmount ? getFormattedNumber(row.original.billableMonthlyAmount, 1) : '',
    },
    {
      Header: 'START TIME',
      accessor: 'startTime',
    },
    {
      Header: 'END TIME',
      accessor: 'endTime',
    },
    {
      Header: 'DURATION (HRS)',
      accessor: 'duration',
      Cell: ({ row }) => (
        <span className={`duration ${row.original?.durationStatus}`} {...row.getToggleRowExpandedProps()}>
          {row.original?.duration}
        </span>
      ),
    },
    {
      Header: 'SALARY (VND)',
      accessor: 'salary',
    },
    {
      Header: 'LATE LOGGING TIME (DAYS)',
      accessor: 'lateLoggingTime',
      Cell: ({ row }) => (
        <span
          className={`late-logging-time-status ${row.original?.lateLoggingTimeStatus}`}
          {...row.getToggleRowExpandedProps()}>
          {row.original?.lateLoggingTime}
        </span>
      ),
    },
  ]
  return headers
}

export function getReportSectionHeaderListWithFilterByUser(currencyName) {
  const headers = [
    {
      Header: 'TASK NAME',
      accessor: 'taskName',
      // id: "expander",
      Cell: ({ row }) => <span {...row.getToggleRowExpandedProps()}>{row.original.taskName}</span>,
    },
    {
      Header: 'DESCRIPTION',
      accessor: 'description',
      // id: "expander",
      Cell: ({ row }) => (
        <td className="description" {...row.getToggleRowExpandedProps()}>
          <ReactQuill
            className="task-description"
            value={handleUpdateDescription(row.original.description)}
            theme="bubble"
            readOnly
          />
        </td>
      ),
    },
    {
      Header: 'HOURLY RATE',
      accessor: 'defaultRating',
      Cell: ({ row }) => (
        <p className="text-align-center" {...row.getToggleRowExpandedProps()}>
          {row.original.defaultRating}
        </p>
      ),
    },
    {
      Header: 'CLIENT RATE',
      Cell: ({ row }) => (row.original.clientRate ? `${row.original.clientRate} (${currencyName}/hour)` : ''),
    },
    {
      Header: 'BILLABLE MONTHLY AMOUNT',
      Cell: ({ row }) =>
        row.original.billableMonthlyAmount ? `${row.original.billableMonthlyAmount} (${currencyName}/month)` : '',
    },
    {
      Header: 'START TIME',
      accessor: 'startTime',
    },
    {
      Header: 'END TIME',
      accessor: 'endTime',
    },
    {
      Header: 'DURATION (HRS)',
      accessor: 'duration',
      Cell: ({ row }) => (
        <span className={`duration ${row.original?.durationStatus}`} {...row.getToggleRowExpandedProps()}>
          {row.original?.duration}
        </span>
      ),
    },
    {
      Header: 'SALARY (VND)',
      accessor: 'salary',
    },
    {
      Header: 'LATE LOGGING TIME (DAYS)',
      accessor: 'lateLoggingTime',
      Cell: ({ row }) => (
        <span
          className={`late-logging-time-status ${row.original?.lateLoggingTimeStatus}`}
          {...row.getToggleRowExpandedProps()}>
          {row.original?.lateLoggingTime}
        </span>
      ),
    },
  ]
  return headers
}
