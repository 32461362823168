import React, { ReactNode } from 'react'
import { Box, VStack, Text, useMediaQuery } from '@chakra-ui/react'
import { boxShadow } from 'theme/globalStyles'
import { maxMobileSize } from 'constants/common'

interface ISectionProps {
  title?: string
  children: ReactNode
}

const Section = (props: ISectionProps) => {
  const { title, children } = props
  const [isMobile] = useMediaQuery(maxMobileSize)

  return (
    <Box
      id={title.replace(/\s/g, '')}
      width="full"
      background="white"
      padding={isMobile ? 4 : 6}
      borderRadius={isMobile ? 0 : 6}
      boxSizing="border-box"
      boxShadow={boxShadow}>
      <VStack alignItems="initial" spacing={isMobile ? 4 : 6}>
        <Text
          fontSize={isMobile ? 'md' : 'lg'}
          fontWeight={isMobile ? 700 : 500}
          lineHeight={isMobile ? 6 : 7}
          color="gray.700"
          margin={0}>
          {title}
        </Text>
        {children}
      </VStack>
    </Box>
  )
}

export default Section
