import apiV2 from 'API/api'
import { APIRouters } from 'API/APIRouters'
import { AxiosResponse } from 'axios'
import { IPaymentPayload, IFilterPaymentsPayload, IPaymentListResponse } from './constants'

export async function filterPaymentsAdminAPI(payload: IFilterPaymentsPayload): Promise<IPaymentListResponse> {
  try {
    const response: AxiosResponse = await apiV2.post(APIRouters.payment.paymentAdmin.list.value, payload)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function editRequestPaymentAPI(payload: IPaymentPayload): Promise<void> {
  try {
    await apiV2.post(APIRouters.payment.paymentAdmin.editRequest.value, payload)
  } catch (error) {
    throw error
  }
}

export async function editPaymentAdminAPI(payload: IPaymentPayload): Promise<void> {
  try {
    await apiV2.post(APIRouters.payment.paymentAdmin.edit.value, payload)
  } catch (error) {
    throw error
  }
}

export async function filterPaymentsUserAPI(payload: IFilterPaymentsPayload): Promise<IPaymentListResponse> {
  try {
    const response: AxiosResponse = await apiV2.post(APIRouters.payment.paymentUser.list.value, payload)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function editPaymentUserAPI(payload: IPaymentPayload): Promise<IPaymentListResponse> {
  try {
    const response: AxiosResponse = await apiV2.post(APIRouters.payment.paymentUser.edit.value, payload)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function sendRequestAPI(payload: IPaymentPayload): Promise<void> {
  try {
    await apiV2.post(APIRouters.payment.paymentUser.sendRequest.value, payload)
  } catch (error) {
    throw error
  }
}
