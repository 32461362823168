export const CURRENCY_BASE_URL: string = '/currency'

export interface ICurrency {
  id: string
  value?: string
  rateExchange?: string
}
export interface ICurrencyListResponse {
  currencies: Pick<ICurrency, 'id' | 'value'>[]
  count?: number
}
export interface ICurrencyFilter {
  value: string
}
export interface ICurrencyListPaginationRequest {
  page: number
  pageSize: number
  filter: ICurrencyFilter
}
export interface ICurrencyCreationForm {
  value: string
  rateExchange: number
}
