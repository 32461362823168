import { FormControl, FormLabel, VStack } from '@chakra-ui/react'
import DatePicker from 'components/DatePicker'
import GroupChips from 'components/GroupChips'
import MultipleSelect from 'components/MultipleSelect'
import SingleSelect from 'components/SingleSelect'
import { Controller, useFormContext } from 'react-hook-form'
import { workingPeriodOptions } from './constants'
import { IDiagramFilterProps } from './interfaces'
import { getPartnerOptionSelect, getTechnologyOptionSelect } from './utils'

const DiagramFilterForm = (props: IDiagramFilterProps) => {
  const { partnerList, technologyList } = props
  const { control, setValue } = useFormContext()
  const partnerOptions = getPartnerOptionSelect(partnerList)
  const technologyOptions = getTechnologyOptionSelect(technologyList)

  function pickDate(date: Date): void {
    setValue('date', date)
  }

  return (
    <VStack fontWeight={400} spacing={6} marginTop={6}>
      <SingleSelect
        name="period"
        label="Period"
        placeHolder="Select Period"
        optionsData={workingPeriodOptions}
        isClearable={false}
      />
      <FormControl>
        <FormLabel fontWeight="400">Date</FormLabel>
        <Controller
          name="date"
          control={control}
          render={({ field: { value } }) => <DatePicker selectedDate={value} onChange={pickDate} />}
        />
      </FormControl>
      <VStack width="100%" alignItems="flex-start">
        <MultipleSelect name="partners" label="Partner" placeholder="Select Partner" options={partnerOptions} />
        <GroupChips fieldName="partners" />
      </VStack>
      <VStack width="100%" alignItems="flex-start">
        <MultipleSelect
          name="technologies"
          label="Project Technology"
          placeholder="Select Technology"
          options={technologyOptions}
          menuPlacement="top"
          selectedEntityName="Technology"
        />
        <GroupChips fieldName="technologies" />
      </VStack>
    </VStack>
  )
}

export default DiagramFilterForm
