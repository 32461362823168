import apiV2 from 'API/api'
import { APIRouters } from 'API/APIRouters'
import { AxiosResponse } from 'axios'
import { ITechnology } from 'types/technology'
import { ITechnologyListResponse, ITechnologyListPaginationRequest, ITechnologyDataFromCreateForm } from './constants'

export async function getTechnologyListAPI(): Promise<ITechnologyListResponse> {
  try {
    const url: string = APIRouters.technology.list.value
    const response: AxiosResponse = await apiV2.get(url)
    return {
      technologies: response?.data?.technologies || [],
      count: response?.data?.count || 0,
    }
  } catch (error) {
    throw error
  }
}

export async function getTechnologyListWithPaginationAPI(
  payload: ITechnologyListPaginationRequest
): Promise<ITechnologyListResponse> {
  try {
    const url: string = APIRouters.technology.list.value
    const response: AxiosResponse = await apiV2.post(url, payload)
    return {
      technologies: response?.data?.technologies || [],
      count: response?.data?.count || 0,
    }
  } catch (error) {
    throw error
  }
}

export async function createTechnologyAPI(technology: ITechnologyDataFromCreateForm): Promise<string> {
  try {
    const url: string = APIRouters.technology.value
    const response: AxiosResponse = await apiV2.post(url, technology)
    return response?.data?.message || ''
  } catch (error) {
    throw error
  }
}

export async function updateTechnologyAPI(technology: ITechnology): Promise<string> {
  try {
    const url: string = APIRouters.technology.technologyId.value(technology.id)
    const response: AxiosResponse = await apiV2.patch(url, technology)
    return response?.data?.message || ''
  } catch (error) {
    throw error
  }
}

export async function deleteTechnologyAPI(technologyId: string): Promise<string> {
  try {
    const url: string = APIRouters.technology.technologyId.value(technologyId)
    const response: AxiosResponse = await apiV2.delete(url)
    return response?.data?.message || ''
  } catch (error) {
    throw error
  }
}
