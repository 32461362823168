/* eslint-disable max-lines */
import { ISuccessResponse } from 'API/constants'
import { handleError } from 'API/error'
import { getUserLeaveListWithPaginationAPI } from 'API/leave'
import { IUserLeaveListWithPaginationResponse } from 'API/leave/constants'
import { get } from 'lodash'
import { makeAutoObservable, runInAction } from 'mobx'
import { ILeave, ILeaveFilter } from 'types/leave'
import { limitItemPerPage } from 'constants/index'
import RootStore from '../rootStore'

class LeaveStore {
  rootStore: RootStore
  userLeaveList: ILeave[]
  countPagination: number
  isUserLeaveListLoading: boolean = false
  isLoading: boolean = false

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public async getUserLeaveListWithPagination(filter?: ILeaveFilter) {
    try {
      this.isLoading = true
      const currentPage: number = Number(get(filter, 'page', 1))
      const response: ISuccessResponse<IUserLeaveListWithPaginationResponse> = await getUserLeaveListWithPaginationAPI({
        currentPage,
        perPage: limitItemPerPage,
        filter,
      })
      if (response.data) {
        this.userLeaveList = response.data.leaves
        this.countPagination = response.data.count
      }
    } catch (error: any) {
      handleError(error, 'src/store/admin/leaveStore.ts', 'getUserLeaveListWithPagination')
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  public async deleteLeave(id: string): Promise<void> {
    try {
      // TODO: call API delete leave when intergrate with backend
    } catch (error) {
      handleError(error, 'src/store/admin/leaveStore.ts', 'deleteLeave')
    }
  }
}

export default LeaveStore
