import { handleError } from 'API/error'
import { getSupervisorReportAPI, getExportDataForSupervisorAPI, getReportDetailUserAPI } from 'API/report'
import {
  IOverviewReportRequest,
  IFormattedTimeSheet,
  IOverviewHighlightData,
  IExportDataForSupervisorRequest,
  IReportData,
  IReportDetailUserPayload,
} from 'API/report/constants'
import { getSupervisorStatisticAPI } from 'API/summarize'
import { getManHourHeatMapOfUserAPI, getManHourHeatMapAPI } from 'API/summarize'
import { ISupervisorStatisticRequestData } from 'API/summarize/constants'
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import { makeAutoObservable } from 'mobx'
import { IChartIndicators, IOptions, IProjectDataOfUser } from 'types/dashboard'
import { IManHourHeatMap } from 'types/summarize'
import { getValidArray } from 'utils/commonUtils'
import { stripHtml } from 'utils/commonUtils'
import RootStore from '../rootStore'

class UserReportStore {
  rootStore: RootStore
  timesheetList: IFormattedTimeSheet[] = []
  highlightData: IOverviewHighlightData
  projectData?: IProjectDataOfUser
  haveData: boolean = false
  haveUserReportData: boolean = false
  choosedDate: string = ''
  chartIndicators?: IChartIndicators
  options?: IOptions
  chartData?: IManHourHeatMap[]
  isHeatmapLoading: boolean = false
  isGetSupervisorStatisticLoading: boolean = false
  isGetSupervisorReportLoading: boolean = false
  isReportLoading: boolean = false
  reportListDataOfSupervisor: IReportData[] = []
  reportListDataOfUser: IReportData[] = []

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public async getSupervisorReport(data: IOverviewReportRequest): Promise<void> {
    try {
      this.isReportLoading = true
      this.haveData = false
      const response = await getSupervisorReportAPI(data)
      const reportListData = response.data
      const { reportList } = reportListData
      this.reportListDataOfSupervisor = reportList
      this.haveData = true
    } catch (error) {
      handleError(error, 'src/store/admin/AdminReportStore.ts', 'getCountryList')
    } finally {
      this.isReportLoading = false
    }
  }

  public async getExportDataForSupervisor(data: IExportDataForSupervisorRequest) {
    try {
      const response = await getExportDataForSupervisorAPI(data)
      const exportData = response?.data?.exportData
      if (exportData) {
        const workbook = new ExcelJS.Workbook()
        getValidArray(exportData).forEach((exportDataItem) => {
          if (exportDataItem?.timesheetData) {
            const sheet = workbook.addWorksheet(exportDataItem.projectName)
            const headers = Object.keys(exportDataItem.timesheetData[0])

            const headerRow = sheet.addRow(headers)
            headerRow.eachCell((cell) => {
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFFFF00' },
                bgColor: { argb: 'FF0000FF' },
              }
              cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' },
              }
              cell.alignment = {
                vertical: 'middle',
                horizontal: 'center',
                wrapText: true,
              }
              cell.font = {
                bold: true,
              }
            })

            exportDataItem.timesheetData.forEach((dataItem) => {
              const cleanedData = headers.map((header) => {
                if (dataItem[header] === undefined || dataItem[header] === null) {
                  return ''
                }
                return stripHtml(dataItem[header].toString())
              })
              const row = sheet.addRow(cleanedData)
              row.eachCell((cell) => {
                cell.alignment = {
                  vertical: 'middle',
                  horizontal: 'left',
                  wrapText: true,
                }
                cell.border = {
                  top: { style: 'thin' },
                  left: { style: 'thin' },
                  bottom: { style: 'thin' },
                  right: { style: 'thin' },
                }
              })
            })

            sheet.columns.forEach((column) => {
              let maxLength = 0
              column.eachCell({ includeEmpty: true }, (cell) => {
                let length = cell.value ? cell.value.toString().length : 0
                if (length > maxLength) maxLength = length
              })
              column.width = maxLength > 50 ? 50 : Math.max(maxLength, 10)
            })
          }
        })

        const buffer = await workbook.xlsx.writeBuffer()
        saveAs(
          new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
          'DataForSupervisor.xlsx'
        )
      }
    } catch (error) {
      handleError(error, 'src/store/admin/AdminReportStore.ts', 'getExportDataForSupervisor')
    }
  }

  public async getSupervisorStatistic(data: ISupervisorStatisticRequestData): Promise<void> {
    try {
      this.isGetSupervisorStatisticLoading = true
      this.haveData = false
      const { date } = data
      this.choosedDate = date
      const response = await getSupervisorStatisticAPI(data)
      const { chartIndicators, options, projectData } = response
      this.chartIndicators = chartIndicators
      this.projectData = projectData
      this.options = options
      this.haveData = true
    } catch (error) {
      handleError(error, 'src/store/admin/AdminReportStore.ts', 'getCountryList')
    } finally {
      this.isGetSupervisorStatisticLoading = false
    }
  }

  public async getHeatmapOfUser(isSupervisor: boolean, values): Promise<void> {
    try {
      this.isHeatmapLoading = true
      this.haveData = false
      const parsedValues = { ...values }
      if (isSupervisor) {
        const response = await getManHourHeatMapAPI(parsedValues)
        this.chartData = response?.data?.chartData
      } else {
        const response = await getManHourHeatMapOfUserAPI(parsedValues)
        this.chartData = response?.data?.chartData
      }
      this.haveData = true
    } catch (error) {
      handleError(error, 'src/store/admin/dashboardStore.ts', 'filterSubmitHandlerOfHeatMap', false, true)
    } finally {
      this.isHeatmapLoading = false
    }
  }

  public async getUserReport(data: IReportDetailUserPayload): Promise<void> {
    try {
      this.isReportLoading = true
      this.haveData = false
      const response = await getReportDetailUserAPI(data)
      const reportListData = response.data
      const { reportList } = reportListData
      this.reportListDataOfUser = reportList
      this.haveUserReportData = true
    } catch (error) {
      handleError(error, 'src/store/admin/AdminReportStore.ts', 'getCountryList')
    } finally {
      this.isReportLoading = false
    }
  }
}

export default UserReportStore
