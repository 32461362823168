/* eslint-disable max-lines */
import { ISuccessResponse } from 'API/constants'
import { getListCreatableOptionsAPI } from 'API/creatableOption'
import { ICreatableOptionListWithFilterResponse } from 'API/creatableOption/constants'
import { handleError } from 'API/error'
import { getPartnersAPI } from 'API/partner'
import { IPartnerListResponse } from 'API/partner/constants'
import { getAllNameProjectListOfAdminAPI } from 'API/project'
import { IProjectNameListResponse, IProjectNameResponse } from 'API/project/constants'
import {
  getHourlyProjectRatingAdminV2API,
  getFixedProjectRatingAdminV2API,
  updateHourlyProjectRatingV2API,
  updateFixedProjectRatingV2API,
} from 'API/projectRating'
import { IFixedProjectResponseItem, IHourlyProjectResponseItem, IProjectRatings } from 'API/projectRating/constants'
import { createSkillAPI, getSkillsByUserIdAPI, getAverageSkillAPI } from 'API/skill'
import { getTechnologyListAPI } from 'API/technology/index'
import {
  createUserV2API,
  archivedUserAPI,
  unArchivedUserAPI,
  activeUserAPI,
  deactivateUserAPI,
  updateUserDetailV2API,
  getBirthdayListAPI,
  getAllNameUserListAPI,
  getUserDetailV2API,
  updateUserProjectRatingV2API,
  deleteUserV2API,
  getCVNameByUserIdAPI,
  getUserListOfUnarchiveProjectAPI,
  getUserListWithPaginationAPI,
  getAllNameUserListOfSupervisorAPI,
} from 'API/user'
import {
  IBirthdayFilter,
  IBirthdayList,
  ICreateUserRequest,
  IUserDetail,
  IUserProfileResponse,
  IUserNameListResponse,
  IUserNameResponse,
  IUserDataOfUnarchiveProject,
  IUserListWithPaginationResponse,
} from 'API/user/constants'
import { History } from 'history'
import { get } from 'lodash'
import delay from 'lodash/delay'
import { makeAutoObservable } from 'mobx'
import queryString from 'query-string'
import { toast } from 'react-toastify'
import { ITechnology } from 'types/technology'
import { IUser, IBirthday, ISkill, IUserFilter } from 'types/user'
import { IOption } from 'constants/common'
import { ECreatableOptionScope } from 'constants/enum'
import { limitItemPerPage } from 'constants/index'
import { IPartner, ICreatableOption } from 'constants/schema'
import { getParsedUserValueBeforeRenderFEV2 } from 'utils/formatDataUtils'
import { getParsedUserValueBeforeSendBE } from 'utils/formatDataUtils'
import { trackIdentify } from 'utils/tracking'
import RootStore from '../rootStore'

class AdminUserFormStore {
  rootStore: RootStore
  userList: IUser[]
  countPagination: number
  userListOfUnarchiveProject: IUserDataOfUnarchiveProject[]
  userNameList: IUserNameResponse[] = []
  userNameListOfSupervisor: IUserNameResponse[] = []
  projectNameList: IProjectNameResponse[] = []
  technologyList: ITechnology[] = []
  partnerList: IPartner[] = []
  creatableOptionList: ICreatableOption[] = []
  userDetail: IUserDetail
  cvFileName: string
  projectRatings: IProjectRatings
  birthdayList: IBirthday[]
  isLoadNewProjectRates: boolean = false
  isUserListLoading: boolean = false
  isBirthDayListLoading: boolean = false
  averageSkill: ISkill
  skills: ISkill[] = []

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public async getTechnologyList(): Promise<void> {
    try {
      const { technologies } = await getTechnologyListAPI()
      this.technologyList = technologies
    } catch (error) {
      handleError(error, 'src/store/admin/AdminTechnologyStore.ts', 'getTechnologyList')
    }
  }

  public async getUserListWithPagination(history: History, filter?: IUserFilter) {
    try {
      this.isUserListLoading = true
      const searchString = history.location.search
      const parsedQuery = queryString.parse(searchString)
      const currentPage: number = Number(get(parsedQuery, 'page', 1))

      const response: ISuccessResponse<IUserListWithPaginationResponse> = await getUserListWithPaginationAPI({
        currentPage,
        perPage: limitItemPerPage,
        filter,
      })
      this.userList = response?.data?.users
      this.countPagination = response?.data?.count
    } catch (error) {
      handleError(error, 'src/containers/User/UserList/index.tsx', 'getUserListWithPagination')
    } finally {
      this.isUserListLoading = false
    }
  }

  public async createUser(data: IUser): Promise<void> {
    try {
      const parsedValues: ICreateUserRequest = getParsedUserValueBeforeSendBE(data)
      if (!parsedValues) {
        return
      }
      const message = await createUserV2API(parsedValues)
      toast.success(message)
    } catch (error) {
      handleError(error, 'src/store/admin/userStore.ts', 'createUser')
    }
  }

  public async deleteUser(userId: string) {
    try {
      const message = await deleteUserV2API(userId)
      toast.success(message)
    } catch (error) {
      handleError(error, 'src/store/admin/userStore.ts', 'deleteUser')
    }
  }

  public async archivedUser(userId: string) {
    try {
      const message = await archivedUserAPI(userId)
      toast.success(message)
    } catch (error) {
      handleError(error, 'src/store/admin/userStore.ts', 'archivedUser')
    }
  }

  public async unArchivedUser(userId: string, selectedListIds: string[]) {
    try {
      const message = await unArchivedUserAPI(userId, selectedListIds)
      toast.success(message)
    } catch (error) {
      handleError(error, 'src/store/admin/userStore.ts', 'unArchivedUser')
    }
  }

  public async activeUser(userId: string) {
    try {
      const message = await activeUserAPI(userId)
      toast.success(message)
    } catch (error) {
      handleError(error, 'src/store/admin/userStore.ts', 'activeUser')
    }
  }

  public async deactivateUser(userId: string) {
    try {
      const message = await deactivateUserAPI(userId)
      toast.success(message)
    } catch (error) {
      handleError(error, 'src/store/admin/userStore.ts', 'unActiveUser')
    }
  }

  public async getBirthdayList(filter?: { category: IOption; month: IOption }): Promise<void> {
    try {
      this.isBirthDayListLoading = true
      let category = 'all'
      let month
      if (filter) {
        category = filter?.category?.value || 'all'
        month = Number(filter?.month?.value)
      }
      const data: IBirthdayFilter = {
        category,
        month,
      }
      const response: IBirthdayList = await getBirthdayListAPI(data)
      this.birthdayList = response?.birthdayList
    } catch (error) {
      handleError(error, 'src/store/admin/userStore.ts', 'getBirthdayList')
    } finally {
      this.isBirthDayListLoading = false
    }
  }

  public async updateUser(data: IUserDetail, userId: string, needToSendWelcomeEmail: string) {
    const profileDetailData = data.profileDetailData
    try {
      if (profileDetailData) {
        const parsedProfileDetailData = getParsedUserValueBeforeSendBE(profileDetailData)
        const data = {
          needToSendWelcomeEmail,
          updatedUserData: parsedProfileDetailData,
          isUpdateProjectRatings: false,
        }
        const messages = await updateUserDetailV2API(userId, data)
        toast.success(messages)
      }
    } catch (error) {
      handleError(error, 'src/store/admin/userStore.ts', 'updateUser', false)
    }
  }

  public async getAllNameUserList(condition: string): Promise<void> {
    try {
      const response: ISuccessResponse<IUserNameListResponse> = await getAllNameUserListAPI(condition)
      this.userNameList = response?.data?.users
    } catch (error) {
      handleError(error, 'src/store/admin/projectStore.ts', 'getAllNameUserList')
    }
  }

  public async getAllNameUserListOfSupervisor(condition: string): Promise<void> {
    try {
      const response: ISuccessResponse<IUserNameListResponse> = await getAllNameUserListOfSupervisorAPI(condition)
      this.userNameListOfSupervisor = response?.data?.users
    } catch (error) {
      handleError(error, 'src/store/admin/projectStore.ts', 'getAllNameUserList')
    }
  }

  public async getProjectNameList(condition: string, callback?: Function): Promise<void> {
    try {
      const response: ISuccessResponse<IProjectNameListResponse> = await getAllNameProjectListOfAdminAPI(condition)
      this.projectNameList = response?.data?.projects
      if (callback && typeof callback === 'function') {
        callback()
      }
    } catch (error) {
      handleError(error, 'src/store/admin/userStore.ts', 'getProjectNameList')
    }
  }

  public async getPartnerList(): Promise<void> {
    try {
      const response: ISuccessResponse<IPartnerListResponse> = await getPartnersAPI({
        filter: { isDeleted: false, isArchived: false },
      })
      this.partnerList = response?.data?.partners
    } catch (error) {
      handleError(error, 'src/store/admin/userStore.ts', 'getPartnerList')
    }
  }

  public async getOptionList(): Promise<void> {
    try {
      const response: ISuccessResponse<ICreatableOptionListWithFilterResponse> = await getListCreatableOptionsAPI({
        filter: { scope: ECreatableOptionScope.USER, isDeleted: false },
      })
      this.creatableOptionList = response?.data?.creatableOptions
    } catch (error) {
      handleError(error, 'src/store/admin/userStore.ts', 'getOptionList')
    }
  }

  public getUserDetailV2 = async (userId: string, isEdit: boolean) => {
    await this.getUserProfileDetailV2(isEdit, userId)
  }

  public async updateProjectRatings(userId: string, newProjects: string[], projectsNotAdded: string[]) {
    try {
      const data = {
        newProjects,
        projectsNotAdded,
      }
      // *TODO: fix asynchronous for updateUserProjectRating API and then remove delay time
      await updateUserProjectRatingV2API(userId, data)
      delay(() => {
        this.getUserProfileDetailV2(true, userId)
      }, 500)
      this.isLoadNewProjectRates = true
    } catch (error) {
      handleError(error, 'src/store/admin/userStore.ts', 'updateProjectRatings')
    }
  }

  public getProjectRatings = async (userId: string) => {
    const [hourlyProjectRatings, fixedProjectRatings] = await Promise.all([
      this.getHourlyRatePriceDataV2(userId),
      this.getFixedRatePriceDataV2(userId),
    ])
    this.projectRatings = {
      hourlyProjectRatings,
      fixedProjectRatings,
    }
    this.isLoadNewProjectRates = false
  }

  private async getHourlyRatePriceDataV2(userId: string) {
    const data = await getHourlyProjectRatingAdminV2API(userId)
    const hourlyRatingPriceData = data.hourlyRatingPriceData
    return hourlyRatingPriceData
  }

  private async getFixedRatePriceDataV2(userId: string) {
    const data = await getFixedProjectRatingAdminV2API(userId)
    const fixedRatingPriceData = data.fixedRatingPriceData
    return fixedRatingPriceData
  }

  private getUserProfileDetailV2 = async (isEdit: boolean, userId: string) => {
    try {
      const response: IUserProfileResponse = await getUserDetailV2API(userId)
      const currentUserData: IUser = response?.user
      const currentUser: IUserDetail = getParsedUserValueBeforeRenderFEV2(
        currentUserData,
        this.projectNameList,
        isEdit,
        this.technologyList
      )
      this.userDetail = {
        ...currentUser,
      }
      trackIdentify(userId, this.userDetail?.profileDetailData?.email, this.userDetail?.profileDetailData?.fullName)
    } catch (error) {
      handleError(error, 'src/store/admin/userStore.ts', 'getUserProfileDetail')
    }
  }

  public async editHourlyRatePriceV2(userId: string, payload: IHourlyProjectResponseItem) {
    try {
      const message = await updateHourlyProjectRatingV2API(userId, payload)
      this.isLoadNewProjectRates = true
      toast.success(message)
    } catch (error) {
      handleError(error, 'src/store/admin/userStore.ts', 'editHourlyRatePriceV2')
    }
  }

  public async editFixedRatePriceV2(userId: string, payload: IFixedProjectResponseItem) {
    try {
      const message = await updateFixedProjectRatingV2API(userId, payload)
      this.isLoadNewProjectRates = true
      toast.success(message)
    } catch (error) {
      handleError(error, 'src/store/admin/userStore.ts', 'editFixedRatePriceV2')
    }
  }

  public async getCVNameByUserId(userId: string): Promise<void> {
    try {
      const data = await getCVNameByUserIdAPI(userId)
      this.cvFileName = data
    } catch (error) {
      handleError(error, 'src/store/admin/userStore.ts', 'getCVNameByUserId')
    }
  }

  public async getUserListOfUnarchiveProject(projectId: string): Promise<void> {
    try {
      const data = await getUserListOfUnarchiveProjectAPI(projectId)
      this.userListOfUnarchiveProject = data
    } catch (error) {
      handleError(error, 'src/store/admin/userStore.ts', 'getUserListOfUnarchiveProject')
    }
  }

  public async createSkill(userId: string, data: Partial<ISkill>): Promise<void> {
    try {
      const message = await createSkillAPI(userId, data)
      toast.success(message)
    } catch (error) {
      handleError(error, 'src/store/admin/userStore.ts', 'createSkill')
    }
  }

  public async getSkillsByUserId(userId: string): Promise<void> {
    try {
      const data = await getSkillsByUserIdAPI(userId)
      this.skills = data
    } catch (error) {
      handleError(error, 'src/store/admin/userStore.ts', 'getListSkillOfUser')
    }
  }

  public async getAverageSkill(): Promise<void> {
    try {
      const data = await getAverageSkillAPI()
      this.averageSkill = data
    } catch (error) {
      handleError(error, 'src/store/admin/userStore.ts', 'getAverageSkill')
    }
  }
}

export default AdminUserFormStore
