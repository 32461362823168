import apiV2 from 'API/api'
import { APIRouters } from 'API/APIRouters'
import { ISuccessResponse } from 'API/constants'
import { AxiosResponse } from 'axios'
import {
  IManHourHeatMapRequest,
  IManHourHeatMapResponse,
  IDashboard,
  IAdminStatisticRequestData,
  ISupervisorStatisticRequestData,
} from './constants'

export async function getDashboardSummarizeAPI(payload: IAdminStatisticRequestData): Promise<IDashboard> {
  try {
    const url: string = APIRouters.summarize.dashboard.value
    const response: AxiosResponse = await apiV2.post(url, payload)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function getSupervisorStatisticAPI(payload: ISupervisorStatisticRequestData): Promise<IDashboard> {
  try {
    const url: string = APIRouters.summarize.supervisorStatistic.value
    const response: AxiosResponse = await apiV2.post(url, payload)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function getManHourHeatMapAPI(
  payload: IManHourHeatMapRequest
): Promise<ISuccessResponse<IManHourHeatMapResponse>> {
  try {
    return apiV2.post(APIRouters.summarize.manHourHeatMap.value, payload)
  } catch (error) {
    throw error
  }
}

export async function getManHourHeatMapOfUserAPI(
  payload: IManHourHeatMapRequest
): Promise<ISuccessResponse<IManHourHeatMapResponse>> {
  try {
    return apiV2.post(APIRouters.summarize.manHourHeatMapOfUser.value, payload)
  } catch (error) {
    throw error
  }
}
