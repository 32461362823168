import { useEffect, useState } from 'react'
import { HStack, FormControl } from '@chakra-ui/react'
import Button from 'components/Button'
import DatePicker from 'components/DatePicker'
import InputDropdown from 'components/Dropdown/InputDropdown'
import dayjs from 'dayjs'
import { useFormContext, Controller } from 'react-hook-form'
import { IOption } from 'constants/common'
import { createOptionsOfReactSelectFromDB } from 'utils/dropdownOptionUtils'
import { workingPeriodOptionsOfStatistic } from '../../constant'
import { IStatisticFilterFormProps } from '../../constant'

const StatisticFilterForm = (props: IStatisticFilterFormProps) => {
  const { projectNameList, viewAnalyticHandler, isLoading } = props
  const { control, setValue, handleSubmit, getValues } = useFormContext()
  const [isValidForm, setIsValidForm] = useState(false)
  const formValues = getValues()
  const { selectedProject } = formValues
  const projectOptions: IOption[] = [...createOptionsOfReactSelectFromDB(projectNameList)]
  function pickDate(date: Date): void {
    setValue('date', date)
    localStorage.setItem('isPickPastMonth', String(dayjs(date).month() < dayjs().month()))
  }

  useEffect(() => {
    const isValid = selectedProject
    setIsValidForm(isValid)
  }, [formValues])

  return (
    <HStack width="inherit" background="white" margin={{ base: 2, lg: '16px' }}>
      <HStack width="inherit" padding={'16px'}>
        <InputDropdown
          name="selectedProject"
          placeHolder="Select Projects"
          optionsData={projectOptions}
          isClearable={false}
        />
        <InputDropdown
          name="period"
          placeHolder="Select Period"
          optionsData={workingPeriodOptionsOfStatistic}
          isClearable={false}
          borderColor="#4D5DFB"
        />
        <FormControl background="white">
          <Controller
            name="date"
            control={control}
            render={({ field: { value } }) => (
              <DatePicker
                selectedDate={value}
                onChange={pickDate}
                style={{
                  border: '1px solid #4D5DFB',
                  borderColor: '#4D5DFB',
                }}
              />
            )}
          />
        </FormControl>
        <Button
          variant="solid"
          isLoading={isLoading}
          customStyles={{ minWidth: '160px' }}
          rightIcon="arrow-right-line-white.svg"
          disabled={!isValidForm || isLoading}
          onClick={handleSubmit(viewAnalyticHandler)}>
          View Analytics
        </Button>
      </HStack>
    </HStack>
  )
}

export default StatisticFilterForm
