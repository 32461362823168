import { ISkill } from 'types/user'

export function getGroupSkill(skill: ISkill) {
  return {
    workingAttitude: skill?.workingAttitude,
    technicalSkills: skill?.technicalSkills,
    teamPlayer: skill?.teamPlayer,
    communication: skill?.communication,
    problemSolving: skill?.problemSolving,
    meetDeadlines: skill?.meetDeadlines,
    english: skill?.english,
  }
}
