import { Box } from '@chakra-ui/react'
import CurriculumVitaeDetail from 'components/CurriculumVitaeDetail'
import { observer } from 'mobx-react'
import { pdfjs } from 'react-pdf'
import EmptyCV from './components/EmptyCV'
import { ICurriculumVitae } from './interfaces'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const CurriculumVitae = (props: ICurriculumVitae) => {
  const { profileDetail } = props
  return (
    <Box width={'100%'}>
      {profileDetail?.cv ? (
        <CurriculumVitaeDetail isShowButton={true} cvFileName={profileDetail?.cv} />
      ) : (
        <EmptyCV cvFileName={profileDetail?.cv} />
      )}
    </Box>
  )
}

export default observer(CurriculumVitae)
