import { useState, useEffect } from 'react'
import { VStack, HStack } from '@chakra-ui/react'
import EmptyContentBox from 'components/EmptyContentBox'
import LoadingChakra from 'components/LoadingChakra'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'
import { EConditionGetList, ETimesheetSubTitle } from 'constants/enum'
import { Messages } from 'constants/index'
import { EFilterType } from 'containers/Dashboard/enum'
import { getValidArray } from 'utils/commonUtils'
import { useStores } from 'utils/hooks/useStores'
import ReportFilterForm from '../components/ReportFilterForm'
import ReportSection from '../components/ReportSection'
import { IReportFilterWithUserFormData, IReportFilterWithProjectFormData, workingPeriodOptions } from '../constants'

const AdminTimesheetReport = () => {
  window.sessionStorage.setItem('subPageTitle', ETimesheetSubTitle.REPORT)
  window.dispatchEvent(new Event('storage'))

  const { adminProjectStore, adminUserStore, adminReportStore } = useStores()
  const { projectNameList } = adminProjectStore
  const { userNameList } = adminUserStore
  const { isReportLoading, haveData, reportListData } = adminReportStore
  const [isValidAnalysis, setIsValidAnalysis] = useState(false)
  const history = useHistory()
  const location = useLocation()
  const [initSelectedProject, setInitSelectedProject] = useState([])
  const [initSelectedUser, setInitSelectedUser] = useState([])
  const searchParams = new URLSearchParams(location.search)

  useEffect(() => {
    setIsValidAnalysis(haveData)
  }, [haveData])

  function updateQueryString(data) {
    const searchParams = new URLSearchParams(location.search)

    searchParams.delete('project')
    if (Array.isArray(data.projectsId) && data.projectsId.length > 0) {
      data.projectsId.forEach((projectId) => {
        searchParams.append('project', projectId)
      })
    } else if (data.projectsId) {
      searchParams.append('project', data.projectsId)
    }

    searchParams.delete('user')
    if (Array.isArray(data.usersId) && data.usersId.length > 0) {
      data.usersId.forEach((userId) => {
        searchParams.append('user', userId)
      })
    } else if (data.usersId) {
      searchParams.append('user', data.usersId)
    }

    searchParams.set('period', data.period ?? '')
    searchParams.set('date', dayjs(data.date).format('YYYY-MM-DD'))
    searchParams.set('filterByProject', data.filterType)

    history.replace({
      search: searchParams.toString(),
    })
  }

  function reportWithProjectHandler(data: IReportFilterWithProjectFormData): void {
    const projectsId = [data.selectedProject?.value]
    const usersId = getValidArray(data.selectedUser).map((user) => user.value)

    const requestData = {
      projectsId,
      usersId,
      period: data.period.value,
      date: dayjs(data.date).format('YYYY-MM-DD'),
      filterType: data.isFilterByProject ? EFilterType.FILTER_BY_PROJECT : EFilterType.FILTER_BY_USER,
    }

    updateQueryString(requestData)

    adminReportStore.getReportAnalytics(requestData)
  }

  function reportWithUserHandler(data: IReportFilterWithUserFormData): void {
    const usersId = [data.selectedUser?.value]
    const projectsId = getValidArray(data.selectedProject).map((project) => project.value)

    const requestData = {
      projectsId,
      usersId,
      period: data.period.value,
      date: dayjs(data.date).format('YYYY-MM-DD'),
      filterType: data.isFilterByProject ? EFilterType.FILTER_BY_PROJECT : EFilterType.FILTER_BY_USER,
    }

    updateQueryString(requestData)

    adminReportStore.getReportAnalytics(requestData)
  }

  function initializeData() {
    const selectedProjectIds = searchParams.getAll('project')
    const selectedUserIds = searchParams.getAll('user')
    const periodValue = searchParams.get('period') || workingPeriodOptions[1].value
    const dateValue = searchParams.get('date') || dayjs().format('YYYY-MM-DD')
    const filterByProjectValue = searchParams.get('filterByProject') || EFilterType.FILTER_BY_PROJECT
    const isFilterByProject = filterByProjectValue === EFilterType.FILTER_BY_PROJECT

    const selectedProjects = selectedProjectIds.map((id) => {
      const projects = projectNameList.find((project) => project.value === id)
      const choosedProjectData = projectNameList.find((item) => item.id === id)
      return projects || { value: id, label: choosedProjectData?.name ?? '' }
    })

    const selectedUsers = selectedUserIds.map((id) => {
      const users = userNameList.find((user) => user.value === id)
      const choosedUserData = userNameList.find((item) => item.id === id)
      return users || { value: id, label: choosedUserData?.name ?? '' }
    })

    setInitSelectedProject(selectedProjects)
    setInitSelectedUser(selectedUsers)

    methods.reset({
      selectedProject: isFilterByProject ? selectedProjects : null,
      selectedUser: !isFilterByProject ? selectedUsers : null,
      period: workingPeriodOptions.find((p) => p.value === periodValue),
      date: dayjs(dateValue).toDate(),
      isFilterByProject: isFilterByProject,
    })

    const requestData = {
      projectsId: selectedProjectIds,
      usersId: selectedUserIds,
      period: periodValue,
      date: dateValue,
      filterType: filterByProjectValue,
    }

    adminReportStore.getReportAnalytics(requestData)
  }

  useEffect(() => {
    initializeData()
  }, [location.search, projectNameList, userNameList])

  useEffect(() => {
    adminProjectStore.getAllNameProjectList(EConditionGetList.ALL)
    adminUserStore.getAllNameUserList(EConditionGetList.ALL)
  }, [])

  const methods = useForm({
    defaultValues: {
      selectedProject: null,
      selectedUser: null,
      isFilterByProject:
        searchParams.get('filterByProject') === EFilterType.FILTER_BY_PROJECT || !searchParams.get('filterByProject'),
      period: workingPeriodOptions[1],
      date: !Boolean(JSON.parse(localStorage.getItem('isPickPastMonth')))
        ? dayjs().toDate()
        : dayjs().subtract(1, 'month').endOf('month').toDate(),
    },
  })

  const { getValues } = methods
  const { isFilterByProject } = getValues()

  return (
    <VStack width="full">
      <FormProvider {...methods}>
        <ReportFilterForm
          projectNameList={projectNameList}
          userNameList={userNameList}
          reportWithProjectHandler={reportWithProjectHandler}
          reportWithUserHandler={reportWithUserHandler}
          isAnalysisLoading={isReportLoading}
          initSelectedProject={initSelectedProject}
          initSelectedUser={initSelectedUser}
        />
        <LoadingChakra isLoading={isReportLoading}>
          <>
            {isValidAnalysis &&
              getValidArray(reportListData).map((reportData) => {
                return (
                  <ReportSection key={reportData.id} reportData={reportData} isFilterByProject={isFilterByProject} />
                )
              })}
            {!isValidAnalysis && (
              <HStack width="inherit" background="white" margin={{ base: 2, lg: '16px' }}>
                <HStack width="inherit" padding={'16px'} minHeight={'80vh'}>
                  <EmptyContentBox text={Messages.emptyBoxFilterMessage} position="bottom" />
                </HStack>
              </HStack>
            )}
          </>
        </LoadingChakra>
      </FormProvider>
    </VStack>
  )
}

export default observer(AdminTimesheetReport)
