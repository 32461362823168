import { Text, Box, Select, Stack, useMediaQuery } from '@chakra-ui/react'
import Button from 'components/Button'
import isNaN from 'lodash/isNaN'
import min from 'lodash/min'
import { textGrey600, textWhite } from 'theme/globalStyles'
import { maxMobileSize } from 'constants/common'
import { limitItemPerPage } from 'constants/index'
import { truncatePagination } from './container'
import { IPaginationProps } from './interfaces'

const Pagination = (props: IPaginationProps) => {
  const { pagination } = props
  const { gotoPage, pageIndex, tableLength, pageSize = limitItemPerPage } = pagination
  const startPointTable: number = pageSize * (pageIndex - 1) + 1
  const endPointTable: number = min([pageSize * pageIndex, tableLength])
  const numberOfPages: number = Math.ceil(tableLength / pageSize)
  const [isMobile]: boolean[] = useMediaQuery(maxMobileSize)
  const truncatedPagination: string[] = isMobile
    ? [pageIndex.toString()]
    : truncatePagination(Number(pageIndex), Number(numberOfPages))
  const pageNumbers = Array.from({ length: numberOfPages }, (_, i) => i + 1)
  return (
    <Stack direction={{ xl: 'row', lg: 'row', md: 'row', sm: 'row' }} justifyContent="space-between">
      {!isMobile && (
        <Text alignSelf="center" color="gray.600">
          {tableLength === 0 ? `Show 0 of 0` : `Show ${startPointTable} - ${endPointTable} of ${tableLength}`}
        </Text>
      )}
      <Box marginLeft={4} marginTop={[3, 0]} gap={2} alignSelf={['center', 'center']}>
        <Button
          disabled={pageIndex === 1}
          leftIcon="arrow-left-s-line.svg"
          variant="outline"
          customStyles={{ padding: '0px', marginRight: '6px' }}
          onClick={() => gotoPage(pageIndex - 1)}></Button>
        {Array.isArray(truncatedPagination) &&
          truncatedPagination.map((item: string, index: number) => {
            if (isNaN(Number(item))) {
              return (
                <Button variant={'outline'} key={item} customStyles={{ marginRight: '6px' }}>
                  {item}
                </Button>
              )
            }
            const isActive = pageIndex === Number(item)
            return (
              <Button
                key={item}
                color={isActive ? textWhite : textGrey600}
                variant={isActive ? 'solid' : 'outline'}
                colorScheme={isActive && 'cyan'}
                customStyles={{ marginRight: '6px' }}
                onClick={() => (isActive ? {} : gotoPage(Number(item)))}>
                {item}
              </Button>
            )
          })}
        <Button
          disabled={pageIndex === numberOfPages}
          leftIcon="arrow-right-s-line.svg"
          variant="outline"
          customStyles={{ padding: '0px' }}
          onClick={() => gotoPage(pageIndex + 1)}></Button>
      </Box>
      {isMobile && (
        <Box>
          <Select
            value={pageIndex}
            onChange={(e) => gotoPage(Number(e.target.value))}
            alignSelf={'end'}
            width={'150px'}
            height={'40px'}
            backgroundColor={'white'}
            marginRight={4}
            marginTop={3}>
            {pageNumbers.map((pageNumber) => (
              <option key={pageNumber} value={pageNumber}>
                Page {pageNumber}
              </option>
            ))}
          </Select>
        </Box>
      )}
    </Stack>
  )
}

export default Pagination
