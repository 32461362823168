export const guest = {
  value: '/guest',
  downloadCV: {
    value: '/guest/download-cv',
    guestDownload: {
      value: '/guest/download-cv/guest-download',
      userId_cvFileName: {
        value: (userId: string, cvFileName: string) => `/guest/download-cv/guest-download/${userId}/${cvFileName}`,
      },
    },
  },
  getCVFile: {
    value: '/guest/get-cv-file',
    forGuest: {
      value: '/guest/get-cv-file/for-guest',
      cvFileName: {
        value: (cvFileName: string) => `/guest/get-cv-file/for-guest/${cvFileName}`,
      },
    },
  },
}
