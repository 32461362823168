import { chakra, Text } from '@chakra-ui/react'
import { BaseStyle } from 'types/common'

export const ViewModeSelectItem = chakra(Text, {
  baseStyle: (props: BaseStyle) => ({
    color: props.isActive ? 'white' : 'gray.600',
    background: props.isActive ? '#08C8F6' : 'transparent',
    borderRadius: '9999px',
    fontSize: 'md',
    paddingX: 4,
    paddingY: 2,
    margin: 0,
    cursor: 'pointer',
    textTransform: 'capitalize',
  }),
})
