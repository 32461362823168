import { handleError } from 'API/error'
import {
  getTaskTypeListAPI,
  createTaskTypeAPI,
  getTaskTypeListWithPaginationAPI,
  deleteTaskTypeAPI,
  updateTaskTypeAPI,
} from 'API/taskType'
import { ITaskType, ITaskTypeDataFromCreateForm } from 'API/taskType/constants'
import get from 'lodash/get'
import { makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'
import RootStore from '../rootStore'

class AdminTaskTypeStore {
  rootStore: RootStore
  taskTypeList: ITaskType[] = []
  allTaskTypeList: ITaskType[] = []
  listCount: number = 0
  isLoadNewTaskTypeList: boolean = false
  isLoadingTaskTypeList: boolean = false

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public async getTaskTypeList(): Promise<void> {
    try {
      const { taskTypes, count } = await getTaskTypeListAPI()
      this.allTaskTypeList = taskTypes
      this.listCount = count
    } catch (error) {
      handleError(error, 'src/store/admin/AdminTaskTypeStore.ts', 'getTaskTypeList')
    }
  }

  public async getTaskTypeListWithPagination(isReset?: boolean, history?: any, filter?: any): Promise<void> {
    try {
      this.isLoadingTaskTypeList = true
      const page: number = isReset ? 1 : Number(get(history, 'location.state.page') || 1)
      const payload = {
        page,
        pageSize: 10,
        filter,
      }
      const { taskTypes, count } = await getTaskTypeListWithPaginationAPI(payload)
      this.taskTypeList = taskTypes
      this.listCount = count
      this.isLoadNewTaskTypeList = false
    } catch (error) {
      handleError(error, 'src/store/admin/AdminTaskTypeStore.ts', 'getTaskTypeListWithPagination')
    } finally {
      this.isLoadingTaskTypeList = false
    }
  }

  public async createTaskType(taskType: ITaskTypeDataFromCreateForm): Promise<void> {
    try {
      const message = await createTaskTypeAPI(taskType)
      toast.success(message)
      this.isLoadNewTaskTypeList = true
    } catch (error) {
      handleError(error, 'src/store/admin/AdminTaskTypeStore.ts', 'createTaskType', false, true)
    }
  }

  public async updateTaskType(taskType: ITaskType): Promise<void> {
    try {
      const message = await updateTaskTypeAPI(taskType)
      toast.success(message)
      this.isLoadNewTaskTypeList = true
    } catch (error) {
      handleError(error, 'src/store/admin/AdminTaskTypeStore.ts', 'updateTaskType', false, true)
    }
  }

  public async deleteTaskType(technologyId: string): Promise<void> {
    try {
      const message = await deleteTaskTypeAPI(technologyId)
      toast.success(message)
      this.isLoadNewTaskTypeList = true
    } catch (error) {
      handleError(error, 'src/store/admin/AdminTaskTypeStore.ts', 'deleteTaskType', false, true)
    }
  }
}

export default AdminTaskTypeStore
