import { SKILL } from './constants'
import { ISkillAnalysisData } from './interfaces'

export function convertSkillNameToDisplayName(skill: string) {
  const conversions = {
    workingAttitude: 'Working attitude',
    meetDeadlines: 'Meet\ndeadlines',
    problemSolving: 'Problem\nsolving',
    communication: 'Communication',
    teamPlayer: 'Team\nplayer',
    technicalSkills: 'Technical\nskills',
    english: 'English',
  }
  return conversions[skill] || skill
}

export function convertResponseDataToChartData(data: ISkillAnalysisData) {
  return SKILL.map((skill) => {
    return {
      skill: convertSkillNameToDisplayName(skill),
      userIndex: data?.user?.[skill] || 0,
      averageIndex: data?.average?.[skill] || 0,
    }
  })
}
