import { useEffect } from 'react'
import {
  SimpleGrid,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  HStack,
  VStack,
} from '@chakra-ui/react'
import Button from 'components/Button'
import GroupCheckBox from 'components/GroupCheckBox'
import GroupChips from 'components/GroupChips'
import MultipleSelect from 'components/MultipleSelect'
import SwitchControl from 'components/SwitchControl'
import get from 'lodash/get'
import queryString from 'query-string'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { textBlue500 } from 'theme/globalStyles'
import { getOptionsOfCheckboxGroup, getArrayValueFromParsedQuery } from 'utils/commonUtils'
import { getOptionsSelected } from 'utils/dropdownOptionUtils'
import { IFilterForm } from './interface'

const FilterForm = (props: IFilterForm) => {
  const {
    openModalFilterForm,
    setOpenFilterForm,
    partnersList,
    countriesList,
    technologiesList,
    usersList,
    filterSubmit,
  } = props

  const methods = useForm()
  const { register, handleSubmit, reset, setValue } = methods
  const history = useHistory()
  const URLSearchParams = history.location.search

  const filterValues = queryString.parse(URLSearchParams, { parseBooleans: true })

  const isActive = get(filterValues, 'isActive', true)
  const isArchived = get(filterValues, 'isArchived', false)
  const isDeleted = get(filterValues, 'isDeleted', false)

  const selectedPartnerIds = getArrayValueFromParsedQuery(filterValues, 'partnerId')
  const selectedUserIds = getArrayValueFromParsedQuery(filterValues, 'userId')
  const selectedCountryIds = getArrayValueFromParsedQuery(filterValues, 'country')
  const selectedTechnologyIds = getArrayValueFromParsedQuery(filterValues, 'technology')

  const selectedPartnerOptions = getOptionsSelected(selectedPartnerIds, partnersList)
  const selectedUserOptions = getOptionsSelected(selectedUserIds, usersList)
  const selectedCountriesOptions = getOptionsOfCheckboxGroup(selectedCountryIds, countriesList)
  const selectedTechnologiesOptions = getOptionsOfCheckboxGroup(selectedTechnologyIds, technologiesList)

  function closeModal(): void {
    setOpenFilterForm(false)
  }
  useEffect(() => {
    reset({
      isActive: isActive,
      isArchived: isArchived,
      isDeleted: isDeleted,
      partnerId: selectedPartnerOptions,
      userId: selectedUserOptions,
      countries: selectedCountriesOptions,
      technologies: selectedTechnologiesOptions,
    })
  }, [partnersList, usersList, technologiesList, countriesList])

  const handleSetActive = () => {
    setValue('isActive', true)
    setValue('isArchived', false)
    setValue('isDeleted', false)
  }

  const handleSetArchived = () => {
    setValue('isActive', false)
    setValue('isArchived', true)
    setValue('isDeleted', false)
  }

  const handleSetDeleted = () => {
    setValue('isActive', false)
    setValue('isArchived', true)
    setValue('isDeleted', true)
  }

  function resetForm() {
    reset({
      technologies: technologiesList,
      countries: countriesList,
      partnerId: [],
      userId: [],
      isActive: true,
      isArchived: false,
      isDeleted: false,
    })
  }

  return (
    <HStack spacing={4} alignSelf="flex-start" display={{ base: 'none', md: 'flex' }}>
      <Drawer isOpen={openModalFilterForm} placement="right" onClose={closeModal} size={'lg'}>
        <DrawerOverlay />
        <FormProvider {...methods}>
          <form className="full-form" onSubmit={handleSubmit((data) => filterSubmit(data))}>
            <DrawerContent>
              <DrawerCloseButton color={'gray'} background="none" cursor="pointer" mt="2" />
              <DrawerHeader>Filter</DrawerHeader>
              <div className="box-divider" />
              <DrawerBody>
                <SimpleGrid columns={{ base: 1, md: 3, lg: 3 }} gap={{ base: 6, lg: 8 }} width="full" marginBottom={5}>
                  <SwitchControl name="isActive" label="Is Active?" handleOnChange={handleSetActive} />
                  <SwitchControl name="isArchived" label="Is Archived?" handleOnChange={handleSetArchived} />
                  <SwitchControl name="isDeleted" label="Is Deleted?" handleOnChange={handleSetDeleted} />
                </SimpleGrid>
                <SimpleGrid width="full" marginBottom={5}>
                  <VStack>
                    <MultipleSelect
                      name="partnerId"
                      label="Belong To Partner"
                      options={partnersList}
                      selectedEntityName="Partners"
                    />
                    <VStack alignSelf="start">
                      <GroupChips fieldName="partnerId" />
                    </VStack>
                  </VStack>
                </SimpleGrid>
                <SimpleGrid width="full" marginBottom={5}>
                  <VStack>
                    <MultipleSelect
                      name="userId"
                      label="Working Developers"
                      options={usersList}
                      selectedEntityName="Developers"
                    />
                    <VStack alignSelf="start">
                      <GroupChips fieldName="userId" />
                    </VStack>
                  </VStack>
                </SimpleGrid>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 2, lg: 2 }} width="full" marginBottom={5}>
                  <VStack alignItems={'normal'}>
                    <GroupCheckBox {...register('countries')} name={'countries'} label="Country" />
                  </VStack>
                </SimpleGrid>
                <GroupCheckBox
                  {...register('technologies')}
                  name={'technologies'}
                  label="Technology"
                  data={technologiesList}
                />
              </DrawerBody>
              <div className="box-divider" />
              <DrawerFooter justifyContent={'space-between'}>
                <Button variant="ghost" onClick={resetForm} customStyles={{ color: textBlue500 }}>
                  Reset
                </Button>
                <HStack>
                  <Button onClick={closeModal} variant="outline">
                    Cancel
                  </Button>
                  <Button variant="solid" type="submit">
                    Apply Filter
                  </Button>
                </HStack>
              </DrawerFooter>
            </DrawerContent>
          </form>
        </FormProvider>
      </Drawer>
    </HStack>
  )
}

export default FilterForm
