export const project = {
  value: '/project',
  listOfUser: {
    value: '/project/list-of-user',
  },
  listRemovedProjectsOfUser: {
    value: '/project/list-removed-projects-of-user',
    userId: {
      value: (userId: string) => `/project/list-removed-projects-of-user/${userId}`,
    },
  },
  nameListForAdmin: {
    value: '/project/name-list-for-admin',
    condition: {
      value: (condition: string) => `/project/name-list-for-admin/${condition}`,
    },
  },
  nameListForUser: {
    value: '/project/name-list-for-user',
    condition: {
      value: (condition: string) => `/project/name-list-for-user/${condition}`,
    },
  },
  nameListOfAGivenUserForAdmin: {
    value: '/project/name-list-of-a-given-user-for-admin',
    condition_userId: {
      value: (condition: string, userId: string) =>
        `/project/name-list-of-a-given-user-for-admin/${condition}/${userId}`,
    },
  },
  nameListForSupervisor: {
    value: '/project/name-list-for-supervisor',
    condition: {
      value: (condition: string) => `/project/name-list-for-supervisor/${condition}`,
    },
  },
  list: {
    value: '/project/list',
  },
  detailOfUser: {
    value: '/project/detail-of-user',
    projectId: {
      value: (projectId: string) => `/project/detail-of-user/${projectId}`,
    },
  },
  detail: {
    value: '/project/detail',
    projectId: {
      value: (projectId: string) => `/project/detail/${projectId}`,
    },
  },
  create: {
    value: '/project/create',
  },
  archive: {
    value: '/project/archive',
    projectId: {
      value: (projectId: string) => `/project/archive/${projectId}`,
    },
  },
  unarchive: {
    value: '/project/unarchive',
    projectId: {
      value: (projectId: string) => `/project/unarchive/${projectId}`,
    },
  },
  delete: {
    value: '/project/delete',
    projectId: {
      value: (projectId: string) => `/project/delete/${projectId}`,
    },
  },
  update: {
    value: '/project/update',
    projectId: {
      value: (projectId: string) => `/project/update/${projectId}`,
    },
  },
}
