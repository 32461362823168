import { HStack, FormLabel, FormControl, Wrap, WrapItem } from '@chakra-ui/react'
import { IOption } from 'constants/common'
import { getValidArray } from 'utils/commonUtils'
import { isFunction } from './container'
import { IInputTagProps } from './interfaces'
import TagItem from './TagItem'

const InputTag = (props: IInputTagProps) => {
  const { data, label, handleTextColor, handleOnClick } = props
  const isCanClick: boolean = isFunction(handleOnClick)
  return (
    <FormControl padding={{ lg: '0px', md: '10px', sm: '10px' }}>
      <FormLabel fontWeight="normal" color="gray.700" lineHeight={6} marginBottom={3} marginInlineEnd={0}>
        {label}
      </FormLabel>
      <HStack>
        <Wrap>
          {getValidArray(data).map((item: IOption, index: number) => {
            return (
              <WrapItem
                key={index}
                onClick={isCanClick ? () => handleOnClick(item?.value) : undefined}
                cursor={isCanClick ? 'pointer' : 'default'}>
                <TagItem key={index} value={item?.label ?? ''} handleTextColor={handleTextColor} color={item.color} />
              </WrapItem>
            )
          })}
        </Wrap>
      </HStack>
    </FormControl>
  )
}

export default InputTag
