import { useEffect } from 'react'
import { FormControl, FormLabel, SimpleGrid } from '@chakra-ui/react'
import CheckboxField from 'components/CheckBoxField'
import { useFormContext, useFieldArray } from 'react-hook-form'
import { IOption } from 'types/creatableOption'

export interface IGroupCheckBox {
  label?: string
  name?: string
  data?: IOption[]
}

const GroupCheckBox = (props: IGroupCheckBox) => {
  const { name, label, data = [] } = props
  const { control, register } = useFormContext()
  const { fields, append } = useFieldArray({
    control,
    name: name,
  })

  function updateData() {
    if (data.length > 0 && fields.length === 0) {
      const appendItem = (item) => {
        append({
          ...item,
          checked: false,
        })
      }
      data.forEach(function (item) {
        appendItem(item)
      })
    }
  }

  useEffect(
    function makeSureDataFieldUsed() {
      updateData()
    },
    [data, fields.length, append]
  )

  return (
    <FormControl marginTop={'10px'} marginBottom={'10px'}>
      <FormLabel color="gray.700" lineHeight={6} marginBottom={'10px'} marginInlineEnd={0}>
        {label}
      </FormLabel>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 2, lg: 2 }} width="full">
        {fields &&
          fields.length > 0 &&
          fields.map((field, index) => {
            return <CheckboxField name={`${name}.${index}.checked`} label={field} />
          })}
      </SimpleGrid>
    </FormControl>
  )
}
export default GroupCheckBox
