import apiV2 from 'API/api'
import { APIRouters } from 'API/APIRouters'
import { AxiosResponse } from 'axios'
import {
  IHourlyProjectRatingList,
  IFixedProjectRatingList,
  IUpdateHourlyPayload,
  IUpdateFixedPayload,
} from './constants'

export async function getHourlyProjectRatingV2API(): Promise<IHourlyProjectRatingList> {
  try {
    const response: AxiosResponse = await apiV2.get(APIRouters.hourlyProjectRate.list.value)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function getHourlyProjectRatingAdminV2API(userId: string): Promise<IHourlyProjectRatingList> {
  try {
    const url = APIRouters.hourlyProjectRate.listAdmin.userId.value(userId)
    const response: AxiosResponse = await apiV2.get(url)
    return response?.data
  } catch (error) {
    throw error
  }
}

export async function updateHourlyProjectRatingV2API(userId: string, payload: IUpdateHourlyPayload): Promise<string> {
  try {
    const url = APIRouters.hourlyProjectRate.update.userId.value(userId)
    const response: AxiosResponse = await apiV2.post(url, payload)
    return response?.data?.message
  } catch (error) {
    throw error
  }
}

export async function getFixedProjectRatingV2API(): Promise<IFixedProjectRatingList> {
  try {
    const response: AxiosResponse = await apiV2.get(APIRouters.fixedProjectRate.list.value)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function getFixedProjectRatingAdminV2API(userId: string): Promise<IFixedProjectRatingList> {
  try {
    const url = APIRouters.fixedProjectRate.listAdmin.userId.value(userId)
    const response: AxiosResponse = await apiV2.get(url)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function updateFixedProjectRatingV2API(userId: string, payload: IUpdateFixedPayload): Promise<string> {
  try {
    const url = APIRouters.fixedProjectRate.update.userId.value(userId)
    const response: AxiosResponse = await apiV2.post(url, payload)
    return response?.data?.message
  } catch (error) {
    throw error
  }
}
