import { EStateType } from 'constants/enum'

export interface IOption<T = string> {
  label: T
  value: string
  text?: string
  color?: string
}

export interface IOptionWithElement {
  value: string
  label: JSX.Element
}

export const maxMobileSize = '(max-width: 576px)'
export const tabletSize = '(min-width: 577px) and (max-width: 1199px)'
export const maxTabletSize = '(max-width: 1199px)'
export const laptopSize = '(max-width: 1200px)'
export const minLaptopSize = '(min-width: 1200px)'
export const NotificationId = 'notificationId'
export const UserId = 'userId'
export const DEFAULT_PROJECT_COLOR = '#eeeee4'

export const months = [
  { name: 'January', color: 'purple.600' },
  { name: 'February', color: 'pink.600' },
  { name: 'March', color: 'orange.600' },
  { name: 'April', color: 'yellow.500' },
  { name: 'May', color: 'yellow.600' },
  { name: 'June', color: 'orange.400' },
  { name: 'July', color: 'red.500' },
  { name: 'August', color: 'pink.400' },
  { name: 'September', color: 'green.600' },
  { name: 'October', color: 'teal.600' },
  { name: 'November', color: 'blue.500' },
  { name: 'December', color: 'cyan.500' },
]

// Màu sắc cho các trạng thái khác nhau, khớp với enum EStateType
export const statusColors = {
  [EStateType.TODO]: '#6B7280', // gray.500
  [EStateType.IN_PROGRESS]: '#ED8936', // orange.500
  [EStateType.NEED_REVIEW]: '#D53F8C', // pink.500
  [EStateType.FIXING]: '#E53E3E', // red.500
  [EStateType.COMPLETE]: '#38A169', // green.500
}
