import { ITodoOfHRGroupsByMonth } from 'API/todoOfHR/constants'
import { EStateType } from 'constants/enum'

export interface ITicketData {
  [key: string]: {
    [key: string]: number
  }
}

// Mapping từ tên viết tắt sang tên đầy đủ của tháng, khớp với `months`
const monthMapping: { [key: string]: string } = {
  Jan: 'January',
  Feb: 'February',
  Mar: 'March',
  Apr: 'April',
  May: 'May',
  Jun: 'June',
  Jul: 'July',
  Aug: 'August',
  Sep: 'September',
  Oct: 'October',
  Nov: 'November',
  Dec: 'December',
}

export function groupTodosByMonth(todos: ITodoOfHRGroupsByMonth[]): ITicketData {
  const ticketData: ITicketData = {}
  if (!todos || todos.length === 0) return {}

  todos.forEach((monthGroup) => {
    const monthName = monthGroup._id || '' // e.g., "Oct 2024"

    // Extract month abbreviation and year
    const [shortMonth, year] = monthName.split(' ') // e.g., ["Oct", "2024"]

    // Convert short month name to full month name using the mapping object
    const fullMonthName = monthMapping[shortMonth] ?? monthName

    if (!ticketData[fullMonthName]) {
      ticketData[fullMonthName] = {} // Initialize the object for each month
    }

    monthGroup.data?.forEach((todo) => {
      const state = todo.state || EStateType.TODO // Default state to "Todo" if none provided

      // Check if the state already exists in the month, increment the count, else initialize
      if (ticketData[fullMonthName][state]) {
        ticketData[fullMonthName][state] += 1
      } else {
        ticketData[fullMonthName][state] = 1
      }
    })
  })

  return ticketData
}
