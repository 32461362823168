import { useEffect } from 'react'
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import Button from 'components/Button'
import { useForm, FormProvider } from 'react-hook-form'
import { borderGray200 } from 'theme/globalStyles'
import SkillAnalysisForm from '../SkillAnalysisForm'
import { ISkillAnalysisFormState } from '../SkillAnalysisForm/interfaces'
import { IAddSkillChartModalProps } from './interfaces'

const AddSkillChartModal = (props: IAddSkillChartModalProps) => {
  const { isOpen, closeHandler, saveHandler } = props
  const methods = useForm()
  const { handleSubmit, reset } = methods
  useEffect(() => {
    reset()
  }, [isOpen])
  return (
    <Modal isOpen={isOpen} onClose={closeHandler} isCentered size="xl">
      <ModalOverlay />
      <FormProvider {...methods}>
        <form>
          <ModalContent maxHeight="800px">
            <Box background="white" width="full">
              <ModalHeader borderBottom="1px solid" borderColor={borderGray200} fontWeight={500}>
                Add Skill Chart
              </ModalHeader>
              <ModalCloseButton color={'gray'} background="none" cursor="pointer" mt="2" />
            </Box>
            <ModalBody overflowY="auto" paddingTop="20px">
              <SkillAnalysisForm />
            </ModalBody>
            <ModalFooter borderTop="1px solid" borderColor={borderGray200}>
              <Button variant="outline" customStyles={{ marginRight: '16px' }} onClick={closeHandler}>
                Cancel
              </Button>
              <Button variant="solid" onClick={handleSubmit((data: ISkillAnalysisFormState) => saveHandler(data))}>
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </FormProvider>
    </Modal>
  )
}
export default AddSkillChartModal
