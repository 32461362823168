export const report = {
  value: '/report',
  reportTimesheet: {
    value: '/report/report-timesheet',
    billReport: {
      value: '/report/report-timesheet/bill-report',
      admin: {
        value: '/report/report-timesheet/bill-report/admin',
        list: {
          value: '/report/report-timesheet/bill-report/admin/list',
        },
      },
    },
    admin: {
      value: '/report/report-timesheet/admin',
      detail: {
        value: '/report/report-timesheet/admin/detail',
      },
    },
    user: {
      value: '/report/report-timesheet/user',
      detail: {
        value: '/report/report-timesheet/user/detail',
      },
    },
    overviewAnalytic: {
      value: '/report/report-timesheet/overview-analytic',
    },
    supervisor: {
      value: '/report/report-timesheet/supervisor',
      list: {
        value: '/report/report-timesheet/supervisor/list',
      },
    },
    exportDataForSupervisor: {
      value: '/report/report-timesheet/export-data-for-supervisor',
    },
  },
}
