import { Box } from '@chakra-ui/react'
import TagWithoutFilter from 'components/Tag/TagWithoutFilter'
import { EPriorityType } from 'constants/enum'
// Usage of the Tag component
const PriorityTag = ({ priority }: { priority: EPriorityType }) => {
  function getColorProps(priority: EPriorityType) {
    switch (priority) {
      case EPriorityType.HIGH:
        return { color: 'red.500', borderColor: 'red.500' } // Red for High priority
      case EPriorityType.MEDIUM:
        return { color: 'yellow.500', borderColor: 'yellow.500' } // Yellow for Medium priority
      case EPriorityType.LOW:
        return { color: 'blue.500', borderColor: 'blue.500' } // Blue for Low priority
      default:
        return { color: 'gray.500', borderColor: 'gray.500' } // Default gray color
    }
  }
  const { color, borderColor } = getColorProps(priority)
  return (
    <Box width={'fit-content'} height={'fit-content'} display={'inline-block'} marginLeft={'8px'}>
      <TagWithoutFilter key={priority} label={priority} color={borderColor} textColor={color} />
    </Box>
  )
}

export default PriorityTag
