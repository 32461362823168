export const projectType = {
  // Global Config Page - Technology
  // [Project List Page & Project Detail & Project Create - Project Type]
  value: '/project-type',
  list: {
    value: '/project-type/list',
  },
  projectTypeId: {
    value: (projectTypeId: string) => `/project-type/${projectTypeId}`,
  },
}
