import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import CryptoJS from 'crypto-js'
import get from 'lodash/get'
import { EYesNoOption } from 'constants/enum'
import { AppConfig, encryptDataVariables } from 'constants/index'
import { EAuthStorageKey, IHeader } from './constants'

declare module 'axios' {
  export interface AxiosRequestConfig {
    skipEncryption?: boolean
  }
}

const apiV2: AxiosInstance = axios.create({
  baseURL: AppConfig.API_URL_V2,
})

apiV2.interceptors.request.use(function (config: AxiosRequestConfig) {
  const authHeaderV2: IHeader = getAuthHeaderV2()
  config.headers = {
    ...authHeaderV2,
  }
  return config
})

apiV2.interceptors.response.use(
  function (response: AxiosResponse) {
    const doNeedToEncryptData = encryptDataVariables.doNeedToEncryptData
    const skipEncryption = response.config.skipEncryption

    if (doNeedToEncryptData === EYesNoOption.YES && !skipEncryption) {
      const responseData = get(response, 'data', response)
      const decryptedResponseData = CryptoJS.AES.decrypt(responseData, encryptDataVariables.encryptSecretKey)
      const decryptedResponseString = decryptedResponseData.toString(CryptoJS.enc.Utf8)
      if (decryptedResponseString) {
        try {
          const parsedResponseData = JSON.parse(decryptedResponseString)
          return {
            status: response?.status,
            data: get(parsedResponseData, 'data', parsedResponseData),
          }
        } catch (error) {
          throw error
        }
      } else {
        return null
      }
    }
    return {
      status: response?.status,
      data: get(response?.data, 'data', response?.data),
    }
  },
  function (error: AxiosError) {
    console.error('Axios error:', error)
    throw error
  }
)

function getAuthHeaderV2(): IHeader {
  const isRememberMe: boolean = JSON.parse(window.localStorage.getItem(EAuthStorageKey.IS_REMEMBER_ME))

  const requestToken: string = isRememberMe
    ? window.localStorage.getItem(EAuthStorageKey.REQUEST_TOKEN_V2)
    : window.sessionStorage.getItem(EAuthStorageKey.REQUEST_TOKEN_V2)

  return {
    Authorization: 'Bearer ' + requestToken,
  }
}

export function getUploadHeader(): IHeader {
  const authHeader: IHeader = getAuthHeaderV2()
  return {
    ...authHeader,
    'Content-Type': 'application/x-www-form-urlencoded',
  }
}

export default apiV2
