import React from 'react'
import { getFormattedDateInEnglish } from 'utils/dateFormatUtils'
import { getFormattedNumber } from 'utils/numberUtils'
import { STATUS_COLORS } from '../../../../constants';

function formatRow(index) {
  let result = 'even'
  if (index % 2 === 0) {
    result = 'odd'
  }
  return result
}

function getStatusStyle(status) {
  return { color: STATUS_COLORS[status] || '#777777' };
}
class ProjectTable extends React.Component {

  render() {
    const { data } = this.props
    return (
      <table className="report-table">
        <thead>
          <tr>
            <th>Project</th>
            <th>Time Total</th>
            <th>Started Date</th>
            <th>Working Time On Project</th>
            <th>Project status</th>
            <th>Salary Total</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, index) => (
              <tr key={index} className={formatRow(index)}>
                <td>{item.projectName}</td>
                <td>{getFormattedNumber(item.timeTotal, 1)}</td>
                <td>{getFormattedDateInEnglish(item.startedDate)}</td>
                <td>{getFormattedNumber(item.workingTimeOnProject, 0)}</td>
                <td style={getStatusStyle(item.projectStatus)} >
                    {item.projectStatus}
                </td>
                <td>{getFormattedNumber(item.salaryTotal, 0)}</td>
              </tr>
            ))}
        </tbody>
      </table>
    )
  }
}

export default ProjectTable
