import { Stack } from '@chakra-ui/react'
import { resetPasswordAPI } from 'API/auth'
import { IResetPasswordRequest } from 'API/auth/constants'
import Button from 'components/Button'
import InputField from 'components/InputField'
import { EServerError } from 'enums/serverErrors'
import { FormProvider, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { Messages } from 'constants/index'

const defaultValues = {
  newPassword: '',
  confirmNewPassword: '',
}

const ResetPasswordForm = ({ setIsSubmitted, resetPasswordToken }) => {
  const method = useForm({
    defaultValues,
  })

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = method

  async function onSubmit(data: IResetPasswordRequest): Promise<void> {
    try {
      await resetPasswordAPI({ ...data, resetPasswordToken })
      setIsSubmitted(true)
      toast.success('Reset password successfully')
    } catch (error) {
      switch (error?.message) {
        case EServerError.TokenExpired:
          toast.error('Token expired. Please try again.')
          break
        case EServerError.PasswordShort:
          toast.error('Password must have at least 8 characters.')
          break
        case EServerError.PasswordMismatch:
          toast.error('Password does not match.')
          break
        case EServerError.TokenNotExist:
          toast.error('Token does not exist.')
          break
        default:
          toast.error('Something wrong happened. Please try again.')
          break
      }
    }
  }

  return (
    <FormProvider {...method}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing="6">
          <InputField
            label="New password"
            name="newPassword"
            isPassword
            placeholder="Enter your new password"
            errors={errors}
            {...register('newPassword', {
              required: Messages.fieldRequired,
            })}
          />
          <InputField
            label="Confirm password"
            name="confirmNewPassword"
            isPassword
            placeholder="Confirm new password"
            errors={errors}
            {...register('confirmNewPassword', {
              required: Messages.fieldRequired,
            })}
          />
        </Stack>
        <Stack mt={6}>
          <Button type="submit">Reset password</Button>
        </Stack>
      </form>
    </FormProvider>
  )
}

export default ResetPasswordForm
