import { ISuccessResponse } from 'API/constants'
import { getListCreatableOptionsAPI } from 'API/creatableOption'
import { ICreatableOptionListWithFilterResponse } from 'API/creatableOption/constants'
import { handleError } from 'API/error'
import {
  getPartnerDetailAPI,
  updatePartnerAPI,
  createPartnerAPI,
  getAllNamePartnerListOfAdminAPI,
  getPartnerListWithPaginationAPI,
} from 'API/partner'
import {
  IPartnerNameResponse,
  IPartnerNameListResponse,
  IPartnerListWithPaginationResponse,
} from 'API/partner/constants'
import get from 'lodash/get'
import omit from 'lodash/omit'
import { makeAutoObservable } from 'mobx'
import queryString from 'query-string'
import { generatePath } from 'react-router'
import { toast } from 'react-toastify'
import { THistory } from 'types/common'
import { ECreatableOptionScope, EYesNoOptionWithCapitalization } from 'constants/enum'
import { frontendRoutes } from 'constants/routes'
import { IPartner } from 'constants/schema'
import { getParsedPartnerValueBeforeSendBE, getParsedPartnerValueBeforeRenderFE } from 'utils/formatDataUtils'
import { limitItemPerPage } from '../../constants'
import { IPartnerFilter } from '../../containers/Partner/FilterForm/interface'
import RootStore from '../rootStore'

class AdminPartnerStore {
  rootStore: RootStore
  currentPage: number = 1
  count: number = 0
  partnerDetail
  partnerNameList: IPartnerNameResponse[]
  creatableOptionList
  isLoadingPartnerDetail: boolean = false
  isLoadingProject: boolean
  partnerList: IPartner[]

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public async getPartnerDetail(partnerId: string, isEdit: boolean): Promise<void> {
    try {
      this.isLoadingPartnerDetail = true
      const { partner } = await getPartnerDetailAPI(partnerId)
      this.partnerDetail = getParsedPartnerValueBeforeRenderFE(partner, isEdit)
    } catch (error) {
      handleError(error, 'src/store/admin/partnerStore.ts', 'getPartnerDetail')
    } finally {
      this.isLoadingPartnerDetail = false
    }
  }

  public async getOptionList(): Promise<void> {
    try {
      const response: ISuccessResponse<ICreatableOptionListWithFilterResponse> = await getListCreatableOptionsAPI({
        filter: { scope: ECreatableOptionScope.USER },
      })
      this.creatableOptionList = response.data.creatableOptions
    } catch (error) {
      handleError(error, 'src/store/admin/partnerStore.ts', 'getOptionList')
    }
  }

  public async createPartner(data: IPartner, history?: THistory): Promise<void> {
    try {
      const formattedPartner: Partial<IPartner> = getParsedPartnerValueBeforeSendBE(data)
      const { userId, messages } = await createPartnerAPI(formattedPartner)
      if (userId) {
        const pathnameUserDetail: string = generatePath(frontendRoutes.userPage.detail.id.value, { id: userId })
        window.localStorage.setItem('isFirstTimeUpdateUser', EYesNoOptionWithCapitalization.YES)
        history.push(pathnameUserDetail)
        toast.success(messages?.createUser)
      } else {
        toast.success(messages?.createPartner)
        const pathnamePartnerList: string = generatePath(frontendRoutes.partnerPage.list.value)
        history.push(pathnamePartnerList)
      }
    } catch (error) {
      handleError(error, 'src/store/admin/partnerStore.ts', 'createPartner')
    }
  }

  public async updatePartner(data: IPartner, partnerId: string): Promise<string> {
    try {
      const formattedPartner: Partial<IPartner> = getParsedPartnerValueBeforeSendBE(
        omit(data, ['id', 'createdAt', 'updatedAt', 'createdBy', 'updatedBy'])
      )
      const { messages } = await updatePartnerAPI(formattedPartner, partnerId)
      return messages
    } catch (error) {
      handleError(error, 'src/store/admin/partnerStore.ts', 'updatePartner')
      return ''
    }
  }

  public async deletePartner(partnerId: string): Promise<void> {
    try {
      const { messages } = await updatePartnerAPI({ isDeleted: true }, partnerId)
      toast.success(messages)
    } catch (error) {
      handleError(error, 'src/store/admin/partnerStore.ts', 'deletePartner')
    }
  }

  public async archivePartner(partnerId: string): Promise<void> {
    try {
      const { messages } = await updatePartnerAPI({ isArchived: true }, partnerId)
      toast.success(messages)
    } catch (error) {
      handleError(error, 'src/store/admin/partnerStore.ts', 'archivePartner')
    }
  }

  public async unArchivePartner(partnerId: string): Promise<void> {
    try {
      const { messages } = await updatePartnerAPI({ isArchived: false }, partnerId)
      toast.success(messages)
    } catch (error) {
      handleError(error, 'src/store/admin/partnerStore.ts', 'unArchivePartner')
    }
  }
  public async getAllNamePartnerList(condition: string): Promise<void> {
    try {
      const response: ISuccessResponse<IPartnerNameListResponse> = await getAllNamePartnerListOfAdminAPI(condition)
      this.partnerNameList = response?.data?.partners
    } catch (error) {
      handleError(error, 'src/store/admin/projectStore.ts', 'getAllNameProjectList')
    }
  }

  public async getPartnerListWithPagination(history?: THistory, filter?: IPartnerFilter): Promise<void> {
    try {
      this.isLoadingProject = true
      const searchString = history.location.search
      const parsedQuery = queryString.parse(searchString)
      const currentPage: number = Number(get(parsedQuery, 'page', 1))
      const response: ISuccessResponse<IPartnerListWithPaginationResponse> = await getPartnerListWithPaginationAPI({
        currentPage,
        perPage: limitItemPerPage,
        filter,
      })

      if (response?.data?.partners) {
        this.partnerList = response.data.partners
        this.count = response.data.count
      }
    } catch (error) {
      handleError(error, 'src/store/admin/projectStore.ts', 'getProjectListWithPagination')
    } finally {
      this.isLoadingProject = false
    }
  }
}

export default AdminPartnerStore
