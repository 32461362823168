import { Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel } from '@chakra-ui/accordion'
import Icon from '@chakra-ui/icon'
import { InfoOutlineIcon } from '@chakra-ui/icons'
import { Text } from '@chakra-ui/layout'
import { Box } from '@chakra-ui/layout'
import { borderBlue500, backgroundGrey50, backgroundBlue50, textBlue500, textGrey700 } from 'theme/globalStyles'

const DescriptionGuideline = () => {
  return (
    <Box mt={8}>
      <Accordion allowToggle>
        <AccordionItem
          border={`1px solid ${borderBlue500}`}
          borderRadius="6px"
          boxShadow="none"
          outline="none"
          backgroundColor={backgroundGrey50}>
          <AccordionButton
            cursor="pointer"
            boxShadow="none !important"
            outline="none"
            backgroundColor={backgroundBlue50}
            _expanded={{ background: `${backgroundBlue50}` }}
            _hover={{ background: `${backgroundBlue50}` }}
            borderRadius="6px">
            <Icon as={InfoOutlineIcon} color={textBlue500} />
            <Box
              as="span"
              flex="1"
              textAlign="left"
              color={textBlue500}
              paddingLeft="10px"
              fontWeight="500"
              fontSize="16px">
              DESCRIPTION GUILDELINE
            </Box>
            <AccordionIcon color={textBlue500} />
          </AccordionButton>
          <AccordionPanel paddingY="16px" backgroundColor={backgroundBlue50}>
            <Text fontWeight={600} margin={0}>
              Nội dung chi tiết:
            </Text>
            <Text fontSize="14px" color={textGrey700} whiteSpace="pre-line" lineHeight="20px" mt={0}>
              Ghi rõ yêu cầu công việc
            </Text>
            <Text margin={0} fontWeight={600}>
              Cơ sở pháp luật
            </Text>
            <Text fontSize="14px" color={textGrey700} whiteSpace="pre-line" lineHeight="20px" mt={0}>
              Ghi các điều luật căn cứ để thực hiện công việc
            </Text>
            <Text margin={0} fontWeight={600} fontStyle={'italic'}>
              Ví dụ
            </Text>
            <Text fontWeight={600} margin={0}>
              Nội dung chi tiết:
            </Text>
            <Text fontSize="14px" color={textGrey700} whiteSpace="pre-line" lineHeight="20px" mt={0}>
              Khám sức khỏe định kỳ cho nhân viên 1 năm 1 lần. Liên hệ với bệnh viện, hoặc phòng khám để hỏi báo giá các
              dịch vụ. Sau đó lên báo giá, trình lên cấp trên xét duyệt
            </Text>
            <Text fontWeight={600} margin={0}>
              Cơ sở pháp luật
            </Text>
            <Text fontSize="14px" color={textGrey700} whiteSpace="pre-line" lineHeight="20px" mt={0}>
              Căn cứ khoản 6 Điều 21{' '}
              <a
                target="_blank"
                href="https://store.udt.group/d/s/10XOlzuLQ3lPTaK1INzd8kdoxWOtuOMu/4uVsbPC3Q6NMEZRXSI5UD4cMobhORdeB-cbpAzuOyvws"
                rel="noreferrer">
                {' '}
                Luật An toàn, vệ sinh lao động 2015
              </a>
            </Text>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  )
}

export default DescriptionGuideline
