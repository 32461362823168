import { useEffect, useState } from 'react'
import {
  FormControl,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Modal,
  Stack,
  Text,
} from '@chakra-ui/react'

import 'react-quill/dist/quill.snow.css'
import { getErrorMessage } from 'API/error'
import KachingAudioURL from 'assets/audio/ka-ching.mp3'
import Button from 'components/Button'
import FormInput from 'components/FormInput'
import SingleSelect from 'components/SingleSelect'
import { observer } from 'mobx-react'
import { useForm, FormProvider, UseFormReturn } from 'react-hook-form'
import { toast } from 'react-toastify'
import { borderGray200, textBlue600, textGrey700 } from 'theme/globalStyles'
import { messageErrorShowOnInput } from 'constants/dailyReportErrors'
import { isValidArray, getValidArray } from 'utils/commonUtils'
import { useStores } from 'utils/hooks/useStores'
import { selectTaskTypeFromTaskNameAndDescription } from '../../utils/index'
import { AutoDetectingErrors } from './components/AutoDetectingErrors'
import { ReportSpecification } from './components/ReportSpecification'
import { ITimeSheetForm, ITimeSheetModalProps } from './constants'
import { IDailyReportData, ISmartTimesheetForm, IErrorDailyReport } from './constants'
import { getTimesheetDataFromDailyReport, getErrorTimesheetDataFromDailyReport } from './container'

const SmartTimesheetModal = (props: ITimeSheetModalProps) => {
  const { isOpenModal, setIsOpenModal, projectOptions } = props
  const methods: UseFormReturn<ITimeSheetForm> = useForm<ITimeSheetForm>({})
  const { setValue: setFormValue, reset, setError, handleSubmit } = methods
  const { userTimesheetStore, adminTaskTypeStore } = useStores()
  const { allTaskTypeList } = adminTaskTypeStore
  const { errorOfCreateSmartTimesheet, isSuccessCreatedAllSmartTimesheet } = userTimesheetStore
  const [isLoading, setIsLoading] = useState<boolean>()
  const [errorDailyReport, setErrorDailyReport] = useState<IErrorDailyReport[]>([])

  const kachingAudio: HTMLAudioElement = new Audio(KachingAudioURL)

  async function onSubmitForm(data: ISmartTimesheetForm): Promise<void> {
    try {
      const errorTimesheetData = getErrorTimesheetDataFromDailyReport(data.dailyReport)
      if (isValidArray(errorTimesheetData)) {
        setErrorDailyReport(errorTimesheetData)
        toast.error(errorTimesheetData[0].errorTitle)
        return
      }
      setErrorDailyReport([])
      setIsLoading(true)

      const rawTimesheetData: IDailyReportData = {
        dailyReport: data.dailyReport,
        project: data?.project?.value ?? '',
      }
      const timesheetDataFromDailyReport = getTimesheetDataFromDailyReport(rawTimesheetData)
      const finalData = timesheetDataFromDailyReport.map((timesheetData) => {
        const { taskName, description } = timesheetData
        const newTaskTypeList = selectTaskTypeFromTaskNameAndDescription({
          taskName,
          description,
          currentTaskTypeList: undefined,
          taskTypeList: allTaskTypeList,
        })
        const taskTypesIds = getValidArray(newTaskTypeList)
          .filter((taskTypeItem) => taskTypeItem.value !== 'all')
          .map((taskTypeItem) => taskTypeItem.value)
        return {
          ...timesheetData,
          taskTypes: taskTypesIds,
        }
      })
      if (isValidArray(finalData)) {
        await userTimesheetStore.createSmartTimesheetV2(finalData, kachingAudio)
      }
    } catch (error) {
      const errorMessage = getErrorMessage(error)
      toast.error(errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  function handleCloseModal(): void {
    setIsOpenModal(false)
    userTimesheetStore.resetErrorOfCreateSmartTimesheet()
    reset()
    setErrorDailyReport([])
  }

  useEffect(() => {
    if (isSuccessCreatedAllSmartTimesheet) {
      setFormValue('project', null)
      setFormValue('dailyReport', '')
    }
    userTimesheetStore.resetErrorOfCreateSmartTimesheet()
  }, [isSuccessCreatedAllSmartTimesheet])

  useEffect(() => {
    if (isValidArray(errorDailyReport)) {
      setError('dailyReport', {
        type: 'manual',
        message: messageErrorShowOnInput,
      })
    }
  }, [errorDailyReport])

  useEffect(
    function handleSmartTimesheetErrors() {
      if (isValidArray(errorOfCreateSmartTimesheet)) {
        const errorResponseFromBE = [...errorDailyReport]
        errorOfCreateSmartTimesheet.forEach((errorResponse) => {
          errorResponseFromBE.push({
            errorTitle: errorResponse.data.taskName,
            errorDescription: errorResponse.data.message,
          })
        })
        setErrorDailyReport(errorResponseFromBE)
      }
    },
    [errorOfCreateSmartTimesheet]
  )

  return (
    <Modal onClose={handleCloseModal} isOpen={isOpenModal} isCentered size="xl" closeOnOverlayClick={false}>
      <FormProvider {...methods}>
        <form name="add-timesheet-form" onSubmit={handleSubmit(onSubmitForm)}>
          <ModalOverlay />
          <ModalContent maxWidth="800px" maxHeight="700px">
            <ModalHeader borderBottom="1px solid" borderColor={borderGray200} fontWeight={500}>
              Add Timesheet
              <ModalCloseButton cursor={'pointer'} color={textGrey700} background="none" mt="2" />
            </ModalHeader>
            <ModalBody py={6} overflowY="auto">
              <FormControl mb={6}>
                <Text position="absolute" right="0" top="-10px" fontSize="14px" textColor={textBlue600}>
                  You can not edit project when edit timesheet
                </Text>
                <SingleSelect
                  label="Project"
                  placeHolder="Select Project"
                  name="project"
                  optionsData={projectOptions}
                  isRequire
                />
              </FormControl>
              <FormControl>
                <Text position="absolute" fontSize="14px" right="0" top="-10px" color={textBlue600}>
                  Follow the specification below strictly
                </Text>
                <Stack>
                  <FormInput
                    label="Report detail"
                    id="dailyReportTextarea"
                    name="dailyReport"
                    isRequired
                    type="textarea"
                    minHeightOfTextarea="144px"
                  />
                </Stack>
              </FormControl>
              <AutoDetectingErrors errors={errorDailyReport} />
              <ReportSpecification />
            </ModalBody>
            <ModalFooter borderTop="1px solid" borderColor={borderGray200}>
              <Button
                variant="outline"
                onClick={handleCloseModal}
                isLoading={isLoading}
                customStyles={{ marginRight: '12px' }}>
                Cancel
              </Button>
              <Button type="submit" isLoading={isLoading}>
                Add timesheet
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default observer(SmartTimesheetModal)
