export const notFoundDailyReportMessage = `Please use keywords \`Daily Report dd/mm\``
export const notFoundDailyReportTitle = `NOT FOUND DAILY REPORTS`
export const invalidStartTimeTitle = (title: string) => `START TIME OF ${title} IS INVALID`
export const invalidStartTimeMessage = `
  Please use <b>dd/mm</b> for date format in paragraph <b><i>Daily Report </i></b>. </br>
  And use <b>h|hr|hrs</b>  for hour maker and <b>m|min|mins|ms</b> for minutes maker. </br>
  And make sure your start time is less than 24h.  </br>
`
export const invalidDurationTitle = (title: string) => `DURATION OF ${title} IS INVALID`
export const invalidDurationMessage = `
  Please use <b>h|hr|hrs</b> for hour maker and <b>m|min|mins|ms</b> for minutes maker. </br>
  And make sure your duration is lesser than 24h. </br>
`
export const haveDailyReportButNotFoundDoneTasksTitle = `DAILY REPORT FOUND, DONE TASKS MISSING`
export const haveDailyReportButNotFoundDoneTasksMessage = `
 There is a Daily Report but the Done tasks cannot be found.
 Please use keywords \`Done:\`
`
export const notFoundDoneTasksTitle = `NOT FOUND DONE TASK`
export const notFoundDoneTasksMessage = `
  Please use keywords \`Done:\`
`
export const invalidFormatDoneTaskMessage = `
  Please use format <b><i>{Task code} | {Task Name}: {Task Description} | {startTime in hh:mm 24h format} | {duration in hh:mm 24h format} </i></b>
`
export const invalidFormatDoneTaskTitle = `FORMAT DONE TASK IS INVALID`
export const invalidDailyReportMessage = `Found <b><i>Daily Report</i></b> without a date in <b>dd/mm</b> format.`
export const invalidDailyReportTitle = `Invalid Daily Report`
export const messageErrorShowOnInput = 'The report format is invalid, please check again'
