import React from 'react'
import { Box } from '@chakra-ui/react'
import { EActionType } from 'constants/enum'
import styles from './tagColorCell.module.scss'

interface ITagColorInputProps {
  color: string
  selectedColor: string
  selectTagColor: (color: string) => void
  actionType?: string
}

const TagColorInput = (props: ITagColorInputProps) => {
  const { color = '', selectedColor, selectTagColor, actionType } = props
  let isSelected = color === selectedColor ? true : false
  let isBlur: boolean = true
  if (actionType === EActionType.CREATE && !selectedColor) {
    isBlur = false
  }
  if (actionType === EActionType.CREATE && selectedColor && isSelected) {
    isBlur = false
  }
  if (actionType === EActionType.EDIT && isSelected) {
    isBlur = false
  }

  return (
    <Box
      className={styles.tagColorCellOutside}
      backgroundColor={isSelected ? 'blue.500' : 'transparent'}
      opacity={isBlur ? 0.5 : 1}
      _hover={{
        cursor: 'pointer',
        borderStyle: 'solid',
        backgroundColor: 'blue.500',
      }}
      onClick={() => selectTagColor(color)}>
      <Box
        className={styles.tagColorCellInside}
        backgroundColor={color}
        borderStyle={'solid'}
        borderWidth={'2px'}
        borderColor={'gray.200'}></Box>
    </Box>
  )
}

export default TagColorInput
