import React, { useEffect } from 'react'
import { Stack, HStack, VStack, Container, Text } from '@chakra-ui/react'
import Button from 'components/Button'
import { observer } from 'mobx-react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { IPartner } from 'types/partner'
import { PartnerCategory, PartnerType } from 'constants/defaultOptionList'
import { ECreatableOptionTypes } from 'constants/enum'
import { frontendRoutes } from 'constants/routes'
import { ICreatableOption } from 'constants/schema'
import { getValidArray } from 'utils/commonUtils'
import {
  createOptionsOfSelect,
  createOptionsOfReactSelectFromDB,
  createOptionsBadgeImageOfReactSelectFromDB,
} from 'utils/dropdownOptionUtils'
import { useStores } from 'utils/hooks/useStores'
import PartnerCreateForm from '../PartnerCreate/components/PartnerCreateForm'

const PartnerCreate = () => {
  const { adminPartnerStore } = useStores()
  const { creatableOptionList } = adminPartnerStore
  const history = useHistory()
  const methods = useForm()
  const { handleSubmit, reset } = methods
  const options = getValidArray(creatableOptionList)
  const titleOptions = createOptionsBadgeImageOfReactSelectFromDB(
    options.filter((option: ICreatableOption) => option?.type === ECreatableOptionTypes.TITLE_OF_USER)
  )
  const levelOptions = createOptionsBadgeImageOfReactSelectFromDB(
    options
      .filter((option: ICreatableOption) => option?.type === ECreatableOptionTypes.LEVEL_OF_USER)
      .sort((previous: ICreatableOption, current: ICreatableOption) => (previous?.order || 0) - (current?.order || 0))
  )
  const paymentCategoryOptions = createOptionsOfReactSelectFromDB(
    options.filter((option: ICreatableOption) => option?.type === ECreatableOptionTypes.PAYMENT_CATEGORY_OF_USER)
  )
  const partnerCategoryOptions = createOptionsOfSelect(PartnerCategory)
  useEffect(() => {
    adminPartnerStore.getOptionList()
  }, [])
  const submitHandler = async (data: IPartner) => {
    await adminPartnerStore.createPartner(data, history)
  }
  function resetForm() {
    reset()
    history.push(frontendRoutes.partnerPage.list.value)
  }
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit((data: IPartner) => submitHandler(data))}>
        <VStack>
          <Container background="white" width="full" maxWidth="unset" padding={0} borderRadius={8}>
            <Stack
              direction={{ xl: 'row', lg: 'row', md: 'row', sm: 'column' }}
              margin={{ base: 6, lg: 6 }}
              height="full"
              background="white"
              alignItems="center"
              justifyContent="space-between">
              <Text fontSize="lg" color="gray.700" margin={0}>
                New Partner
              </Text>
              <HStack>
                <Button variant="outline" onClick={resetForm}>
                  Cancel
                </Button>
                <Button type="submit">Save</Button>
              </HStack>
            </Stack>
          </Container>
          <HStack width="full" justifyContent="space-between" alignItems="flex-start">
            <VStack width="100%">
              <PartnerCreateForm
                partnersType={PartnerType}
                patnerCategoryOptions={partnerCategoryOptions}
                titleOptions={titleOptions}
                levelOptions={levelOptions}
                paymentCategoryOptions={paymentCategoryOptions}
                methods={methods}
              />
            </VStack>
          </HStack>
        </VStack>
      </form>
    </FormProvider>
  )
}

export default observer(PartnerCreate)
