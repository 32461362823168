import { useEffect, useState } from 'react'
import { Stack, HStack, VStack, Container, Text, useMediaQuery } from '@chakra-ui/react'
import Button from 'components/Button'
import ConfirmModal from 'components/ConfirmModal'
import { EModalType } from 'components/ConfirmModal/enums'
import ConfirmYesNoModal from 'components/ConfirmYesNoModal'
import LoadingChakra from 'components/LoadingChakra'
import MoreDropdown from 'components/Table/DesktopTable/components/MoreDropdown'
import get from 'lodash/get'
import set from 'lodash/set'
import { observer } from 'mobx-react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import { backgroundGrey100 } from 'theme/globalStyles'
import { IRowActionItem } from 'types/table'
import { maxMobileSize } from 'constants/common'
import { EConditionGetList } from 'constants/enum'
import { actionOnDataTable } from 'constants/index'
import { frontendRoutes } from 'constants/routes'
import { IProject } from 'constants/schema'
import { getParsedProjectValueBeforeRenderFE } from 'utils/formatDataUtils'
import { useStores } from 'utils/hooks/useStores'
import LogoProject from '../components/LogoProject'
import ProjectDetailForm from '../components/ProjectDetailForm'
import ProjectEditForm from '../components/ProjectEditForm'
import SettingProject from '../components/SettingProject'
import UnarchiveProjectModal from '../components/UnarchiveProjectModal'

const ProjectDetailAdmin = () => {
  const title: string = 'Project Detail'
  window.sessionStorage.setItem('subPageTitle', title)
  window.localStorage.setItem('pageTitle', 'Projects')
  window.dispatchEvent(new Event('storage'))
  const {
    adminProjectStore,
    adminProjectTypeStore,
    adminTechnologyStore,
    adminUserStore,
    adminCountryStore,
    adminCurrencyStore,
  } = useStores()
  const { rawProjectDetailData, partnerList } = adminProjectStore
  const { currencyList } = adminCurrencyStore
  const { countryList } = adminCountryStore
  const { userNameList, userListOfUnarchiveProject } = adminUserStore
  const { technologyList } = adminTechnologyStore
  const { projectTypeList } = adminProjectTypeStore
  const methods = useForm()
  const history = useHistory()
  const params = useParams()
  const projectId = get(params, 'id', '')
  const { handleSubmit, reset, setValue } = methods
  const partnerCategory: string[] = ['owner', 'client']
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [openArchiveModal, setOpenArchiveModal] = useState<boolean>(false)
  const [openConfirmYesNoModal, setOpenConfirmYesNoModal] = useState(false)
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([])
  const [projectDetail, setProjectDetail] = useState<IProject>()
  const [openUnarchiveModal, setOpenUnarchiveModal] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isMobile] = useMediaQuery(maxMobileSize)

  function getProjectDetail(): void {
    setIsLoading(true)
    adminProjectStore.getProjectDetailOfAdminV2(projectId)
  }

  useEffect(() => {
    adminUserStore.getAllNameUserList(EConditionGetList.ACTIVE)
    adminUserStore.getUserListOfUnarchiveProject(projectId)
    adminProjectStore.getPartnerList(partnerCategory)
    adminTechnologyStore.getTechnologyList()
    adminProjectTypeStore.getProjectTypeList()
    adminCurrencyStore.getCurrencyList()
    adminCountryStore.getCountryList()
  }, [])

  useEffect(() => {
    setProjectDetail(getParsedProjectValueBeforeRenderFE(rawProjectDetailData, isEdit))
  }, [isEdit, rawProjectDetailData])

  useEffect(getProjectDetail, [projectId])

  useEffect(() => {
    if (projectDetail) {
      reset(projectDetail)
      setIsLoading(false)
    }
  }, [projectDetail])

  function toggleOpenDeleteModal() {
    setOpenDeleteModal(!openDeleteModal)
  }

  function toggleOpenArchiveModal() {
    setOpenArchiveModal(!openArchiveModal)
  }

  function closeUnarchiveModalHandler() {
    setOpenUnarchiveModal(false)
  }

  function toggleConfirmYesNoModal() {
    setOpenConfirmYesNoModal(!openConfirmYesNoModal)
  }

  async function handleConfirmInfo(selectedUserIds?: string[]) {
    setSelectedUserIds(selectedUserIds ?? [])
    closeUnarchiveModalHandler()
    toggleConfirmYesNoModal()
  }

  function callOpenDialogConfirm(project: IProject, nameOfEvent: string) {
    set(project, 'shortName', project?.name ?? '')
    if (nameOfEvent === actionOnDataTable.DELETE) {
      toggleOpenDeleteModal()
    } else {
      toggleOpenArchiveModal()
    }
  }
  function handleClickOKConfirmedDelete(id: string) {
    adminProjectStore.deleteProject(id)
    history.push(frontendRoutes.projectPage.projectAdmin.list.value)
  }
  async function handleClickOKOfUnarchiveConfirm() {
    await adminProjectStore.unArchiveProject(selectedUserIds, projectId)
    closeUnarchiveModalHandler()
    toggleConfirmYesNoModal()
    history.push(frontendRoutes.projectPage.projectAdmin.list.value)
  }
  function handleClickOKOfArchiveConfirm(id: string) {
    adminProjectStore.archiveProject(id)
    history.push(frontendRoutes.projectPage.projectAdmin.list.value)
  }
  function submitHandler(data: any) {
    adminProjectStore.updateProjectV2(data)
    setIsEdit(false)
  }
  function editHandler() {
    setIsEdit(true)
  }
  function cancelHandler() {
    setIsEdit(false)
  }
  const isArchived: boolean = projectDetail?.isArchived ?? false
  const isDeleted: boolean = projectDetail?.isDeleted ?? false
  let actions: IRowActionItem[] = []
  if (isArchived) {
    actions.push({
      name: 'Unarchive',
      handler: () => setOpenUnarchiveModal(true),
    })
  }
  if (!isArchived) {
    actions.push({
      name: 'Archive',
      handler: () => callOpenDialogConfirm(projectDetail, actionOnDataTable.ARCHIVE),
    })
  }
  if (!isDeleted) {
    actions.push({
      name: 'Delete',
      customClassNames: ['isDanger'],
      handler: () => callOpenDialogConfirm(projectDetail, actionOnDataTable.DELETE),
    })
  }

  if (isDeleted) {
    actions = []
  }

  return (
    <LoadingChakra isLoading={isLoading}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit((data) => submitHandler(data))}>
          <VStack gap="12px">
            <Container
              background={{ base: backgroundGrey100, lg: 'white' }}
              width="full"
              maxWidth="unset"
              padding={0}
              borderRadius={8}>
              <HStack
                margin={{ sm: '20px 16px 4px', md: '6px 16px', lg: 6 }}
                height="full"
                background={{ base: backgroundGrey100, lg: 'white' }}
                justifyContent="space-between">
                <Text fontSize="lg" color="gray.700" margin={0}>
                  {projectDetail ? projectDetail.name : ''}
                </Text>
                <HStack>
                  {!isEdit ? (
                    <Button onClick={editHandler} disabled={isDeleted} leftIcon="edit-white.svg" leftIconSize={16}>
                      {isMobile ? '' : 'Edit Project'}
                    </Button>
                  ) : (
                    <>
                      <Button variant="outline" type="button" onClick={cancelHandler}>
                        Cancel
                      </Button>
                      <Button type="submit">Save</Button>
                    </>
                  )}
                  <MoreDropdown isBackground actions={actions} />
                </HStack>
              </HStack>
            </Container>
            <Stack
              direction={{ base: 'column', lg: 'row' }}
              width="full"
              justifyContent="space-between"
              alignItems="flex-start"
              gap="12px">
              <VStack width="100%">
                {isEdit ? (
                  <ProjectEditForm
                    partnerList={partnerList}
                    userList={userNameList}
                    projectTypes={projectTypeList}
                    technologyList={technologyList}
                    currencyList={currencyList}
                    countryList={countryList}
                    submitHandler={submitHandler}
                    methods={methods}
                    isEdit={isEdit}
                  />
                ) : (
                  <VStack width="100%">
                    <ProjectDetailForm methods={methods} />
                  </VStack>
                )}
              </VStack>
              <VStack
                width={{ base: 'full', lg: '300px' }}
                height="100%"
                gap="12px"
                style={{ position: 'sticky', top: 90 }}>
                <LogoProject
                  type="logo"
                  name="logo"
                  isDetail={!isEdit}
                  afterUploadHandler={(value) => {
                    setValue('logo', value)
                  }}
                />
                <SettingProject isDisabled={!isEdit} />
              </VStack>
            </Stack>
          </VStack>
        </form>
        <ConfirmModal
          data={projectDetail}
          isOpen={openDeleteModal}
          closeHandler={toggleOpenDeleteModal}
          OKClickHandler={handleClickOKConfirmedDelete}
          title={'Project'}
          content={'Project name'}
          type={EModalType.DELETE}
        />
        <ConfirmModal
          data={projectDetail}
          isOpen={openArchiveModal}
          closeHandler={toggleOpenArchiveModal}
          OKClickHandler={handleClickOKOfArchiveConfirm}
          title={'Project'}
          content={'Project name'}
          type={EModalType.ARCHIVE}
        />
        <UnarchiveProjectModal
          isOpen={openUnarchiveModal}
          closeHandler={closeUnarchiveModalHandler}
          saveHandler={handleConfirmInfo}
          dataList={userListOfUnarchiveProject}
        />
        <ConfirmYesNoModal
          isOpen={openConfirmYesNoModal}
          title="Confirm Unarchive Project"
          actionText="Are you sure you want to unarchive this project?"
          closeHandler={toggleConfirmYesNoModal}
          OKClickHandler={handleClickOKOfUnarchiveConfirm}
        />
      </FormProvider>
    </LoadingChakra>
  )
}

export default observer(ProjectDetailAdmin)
