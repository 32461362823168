import { workingPeriodOptions } from '../DiagramFilterForm/constants'
import { IFilterForm } from './types'

export const initialFormValue: IFilterForm = {
  period: workingPeriodOptions[0],
  date: new Date(),
  partners: [],
  technologies: [],
  isInactive: false,
}
