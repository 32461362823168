import React from 'react'
import { SimpleGrid, Text } from '@chakra-ui/react'
import FormInput from 'components/FormInput'
import SingleSelect from 'components/SingleSelect'
import { LaborContractCategory, LaborContractTerm } from 'constants/defaultOptionList'
import { createOptionsOfSelect } from 'utils/dropdownOptionUtils'
import styles from '../../../contract.module.scss'

const laborContractCategoriesOption = createOptionsOfSelect(LaborContractCategory)
const laborContractTermOption = createOptionsOfSelect(LaborContractTerm)

const LaborContractDetail = () => {
  return (
    <React.Fragment>
      <Text alignSelf={'flex-start'} className={`${styles.headerBox} ${styles.border}`}>
        Labor Contract Detail
      </Text>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
        <SingleSelect
          name="laborContractCategory"
          label="Labor Contract Category"
          optionsData={laborContractCategoriesOption}
          placeHolder={'- Select Project Type -'}
          isRequire
        />
        <SingleSelect
          name="laborContractTerm"
          label="Labor Contract Term"
          optionsData={laborContractTermOption}
          placeHolder={'- Select Project Type -'}
          isRequire
        />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
        <FormInput name="basicSalary" label="Basic Salary (optional)" />
        <FormInput name="fuelAndPhoneAllowance" label="Fuel and phone allowance (optional)" />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
        <FormInput name="midShiftMealAllowance" label="Mid-shift meal allowance (optional)" />
        <FormInput name="uniformAllowance" label="Uniform allowance (optional)" />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
        <FormInput name="departmentVI" label="Bộ phận công tác (optional)" />
        <FormInput name="departmentEN" label="Department (optional)" />
      </SimpleGrid>
    </React.Fragment>
  )
}

export default LaborContractDetail
