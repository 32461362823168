import React from 'react'
import { SimpleGrid, Text } from '@chakra-ui/react'
import FormInput from 'components/FormInput'
import SingleSelect from 'components/SingleSelect'
import { citizenIssuePlaceOptions, genderVIOptions } from 'constants/defaultOptionList'
import styles from '../../../contract.module.scss'

const VNInformation = () => {
  return (
    <React.Fragment>
      <Text alignSelf={'flex-start'} className={`${styles.headerBox} ${styles.border}`}>
        Vietnamese Information
      </Text>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
        <FormInput name="nationalityVI" label="Quốc tịch (optional)" />
        <SingleSelect name="genderVI" label="Giới tính (optional)" optionsData={genderVIOptions} />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
        <FormInput name="addressVI" label="Địa chỉ thường trú (optional)" />
        <FormInput
          name="publishedDateInfoVI"
          label="Thời gian và địa điểm ký văn bản (optional). (Ex: TP. Hồ Chí Minh, ngày 2 tháng 3 năm 2024)"
        />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
        <FormInput name="placeOfBirthVI" label="Quê quán (optional)" />
        <SingleSelect
          placeHolder="- Chọn nơi cấp CMND/CCCD -"
          name="IDIssuePlaceVI"
          label="Nơi cấp CCCD/CMND (optional)"
          optionsData={citizenIssuePlaceOptions}
          isCreatable
        />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
        <FormInput name="educationLevelVI" label="Trình độ văn hóa (optional)" />
        <FormInput name="majorVI" label="Chuyên ngành (optional)" />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
        <FormInput name="jobTitleVI" label="Chức vụ (optional)" />
      </SimpleGrid>
    </React.Fragment>
  )
}

export default VNInformation
