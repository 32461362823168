import { useEffect, useState } from 'react'
import { Box, FormControl, FormLabel, HStack, SimpleGrid, Stack, Text, Tooltip, VStack } from '@chakra-ui/react'
import Button from 'components/Button'
import DatePicker from 'components/DatePicker'
import FormInput from 'components/FormInput'
import GroupChips from 'components/GroupChips'
import Icon from 'components/Icon'
import LabelForm, { ILabelFormItemProps } from 'components/LabelForm'
import MultipleSelect from 'components/MultipleSelect'
import SingleSelect from 'components/SingleSelect'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { IProfileDetailOfDetailForm } from 'types/user'
import { educationOptions, genderOptions } from 'constants/defaultOptionList'
import { EYesNoOption } from 'constants/enum'
import { Messages } from 'constants/index'
import { handleDatePickerChange } from 'utils/dateFormatUtils'
import { createOptionsOfReactSelectFromDB } from 'utils/dropdownOptionUtils'
import { useStores } from 'utils/hooks/useStores'
import UserAvatar from '../../../components/UserAvatar'
import { IProfileForm } from './constant'
import { formatProfileFormData, formatUserProfileDetail } from './utils'

const ProfileDetail = () => {
  const [isChangeAvatar, setIsChangeAvatar] = useState<boolean>(false)
  const methods = useForm()
  const { userProfileStore, userTechnologyStore } = useStores()
  const { profileDetail } = userProfileStore
  const { technologyList } = userTechnologyStore

  const technologiesOptions = createOptionsOfReactSelectFromDB(technologyList)

  const {
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods
  const isSupervisor: string = getValues('isSupervisor')
  function editUserHandler(data: IProfileForm): void {
    const formattedData: IProfileDetailOfDetailForm = formatProfileFormData(data)
    userProfileStore.updateProfile(formattedData)
  }

  useEffect(() => {
    if (profileDetail) {
      const formData: IProfileForm = formatUserProfileDetail(profileDetail)
      reset(formData)
      setIsChangeAvatar(false)
    }
  }, [profileDetail])

  useEffect(() => {
    userTechnologyStore.getTechnologyList()
  }, [])

  const CustomLabelForm = (props: ILabelFormItemProps) => {
    return <LabelForm {...props} padding={{ sm: '10px 0' }} />
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(editUserHandler)} className="full-form">
        <VStack
          paddingInline={{ base: 6, lg: 8, sm: 2 }}
          paddingStart={{ base: '27px', lg: '27px', sm: '0px' }}
          spacing="0px">
          <VStack
            padding={{ sm: '0 30px 0', lg: 0 }}
            background="white"
            spacing={{ base: 6, lg: 6, sm: 4 }}
            width={{ sm: '90%', lg: 'full' }}
            borderRadius="8px"
            alignItems={'initial'}>
            <Text
              padding={{ sm: '10px 0', lg: '0px' }}
              margin={'0px'}
              color={'gray.500'}
              fontStyle={'italic'}
              fontSize={{ base: '16px', sm: '13px' }}>
              Attention, the information is filled in according to the your ID (CCCD in Viet Nam). There are serveral
              read-only fileds, if you want to adjust these information, please request to your manager.
              {/* TODO : IMPLEMENT LATER
               <Text
                cursor="pointer"
                display="inline"
                color={'blue.400'}
                fontStyle="normal"
                textDecoration="underline"
                fontWeight={700}>
                here
              </Text> */}
            </Text>
            <Stack
              direction={{ lg: 'row', md: 'column', sm: 'column' }}
              alignItems={{ lg: 'inherit', sm: 'center' }}
              justifyContent={{ lg: 'space-between', md: 'flex-start', sm: 'flex-start' }}>
              <UserAvatar
                type="avatar"
                name="avatar"
                afterUploadHandler={(value) => {
                  setValue('avatar', value)
                  setIsChangeAvatar(true)
                }}
              />
              <Button leftIcon="save-white.svg" type="submit" disabled={!isDirty && !isChangeAvatar}>
                Save
              </Button>
            </Stack>
            <div className="box-divider" />
            <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
              <FormInput name="displayName" label="Display Name" helperText={Messages.displayNameNote} />
              <FormInput name="fullName" label="Full Name" isRequired helperText={Messages.fullNameNote} />
              <FormInput name="email" label="Email" isRequired disabled />
              <FormInput name="phone" label="Phone Number" type="tel" />
              <FormInput name="bankAccount" label="Bank Account" />
            </SimpleGrid>
            <VStack align="flex-start" spacing={{ base: 0 }}>
              <HStack>
                <Text margin={0}>Bank Name</Text>
                <Tooltip label={Messages.bankNameNote} color="white" fontSize="sm" hasArrow placement="right">
                  <Box textAlign="center" height="20px">
                    <Icon iconName="information.svg" size={20} />
                  </Box>
                </Tooltip>
              </HStack>
              <SimpleGrid width="full" columns={{ base: 1, md: 2, lg: 4 }} gap={{ base: 0, lg: 6 }}>
                <FormInput name="bankShortName" placeholder="Short name" isRequired />
                <FormInput name="bankCity" placeholder="City" isRequired />
                <FormInput name="bankBranch" placeholder="Branch" isRequired />
                <FormInput name="bankFullName" placeholder="Full name" isRequired />
              </SimpleGrid>
            </VStack>
            <div className="box-divider" />
            <SimpleGrid columns={{ base: 1, md: 2 }} gap={{ base: 6, lg: 8 }} width="full">
              <Stack width={{ base: 'full', lg: 'full' }}>
                <SingleSelect name="currentGender" label="Gender" optionsData={genderOptions} widthCustom="full" />
              </Stack>
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
              <FormInput name="placeOfBirth" label="Place Of Origin" />
              <FormControl>
                <FormLabel fontWeight="400">Date Of Birth</FormLabel>
                <Controller
                  name="dateOfBirth"
                  control={methods.control}
                  render={({ field }) => (
                    <DatePicker
                      selectedDate={field.value ? dayjs(field.value).toDate() : undefined}
                      onChange={(date) => handleDatePickerChange(field, date)}
                    />
                  )}
                />
              </FormControl>
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
              <FormInput name="address" label="Place of Residence" />
              <FormInput name="nationality" label="Nationality" />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} gap={{ base: 6, lg: 8 }} width="full">
              <FormInput name="IDNumber" label="ID Number" />
              <FormControl>
                <FormLabel>ID Issue Date</FormLabel>
                <Controller
                  name="IDIssueDate"
                  control={methods.control}
                  render={({ field }) => (
                    <DatePicker
                      selectedDate={field.value ? dayjs(field.value).toDate() : undefined}
                      onChange={(date) => handleDatePickerChange(field, date)}
                    />
                  )}
                />
              </FormControl>
              <FormInput name="IDIssuePlace" label="ID Issue Place" />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
              <Stack width={{ base: 'full', lg: 'full' }}>
                <SingleSelect
                  name="education"
                  label="Education"
                  optionsData={educationOptions}
                  isDisabledClearIndicator
                  widthCustom="full"
                />
              </Stack>
              <FormInput name="major" label="Major" />
            </SimpleGrid>
            <SimpleGrid>
              <VStack>
                <MultipleSelect
                  name="technologies"
                  label="Technology"
                  options={technologiesOptions}
                  placeholder="Select Technology"
                  selectedEntityName="Technology"
                />
                <VStack alignSelf="start">
                  <GroupChips fieldName="technologies" />
                </VStack>
              </VStack>
            </SimpleGrid>
            <div className="box-divider" />
            <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
              <SimpleGrid width="full">
                <CustomLabelForm name="joinDate" label="Join Date" readonly={true} />
              </SimpleGrid>
              <CustomLabelForm name="role" label="Role" readonly={true} />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
              <CustomLabelForm name="title" label="Title" readonly={true} isBadgeImage={true}></CustomLabelForm>
              <CustomLabelForm name="level" label="Level" readonly={true} isBadgeImage={true}></CustomLabelForm>
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
              <CustomLabelForm name="isSupervisor" label="Is Supervisor ?" readonly={true}></CustomLabelForm>
              <CustomLabelForm
                name="paymentCategoryId.label"
                label="Payment Category"
                type="textarea"
                readonly={true}></CustomLabelForm>
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
              <CustomLabelForm name="defaultRating" label="Default Rate" readonly={true}></CustomLabelForm>
              <CustomLabelForm
                name="fixedSalary"
                label="Net Salary (VND)"
                readonly={true}
                isSalary={true}></CustomLabelForm>
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
              <CustomLabelForm name="partnerCategory" label="Partner Category" readonly={true}></CustomLabelForm>
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 1, lg: 1 }} gap={{ base: 6, md: 8, lg: 8 }} width="full">
              <CustomLabelForm name="projects" label="Projects" isTagFormat readonly={true} isTagListFormat />
            </SimpleGrid>
            {isSupervisor?.toLowerCase() === EYesNoOption.YES && (
              <CustomLabelForm
                name="projectsOfSupervisor"
                label="Projects of Supervisor"
                isTagFormat
                readonly={true}
                isTagListFormat
              />
            )}
            <SimpleGrid columns={{ base: 1, md: 1, lg: 1 }} gap={{ base: 6, md: 8, lg: 8 }} width="full">
              <CustomLabelForm
                name="extraProjects"
                label="Extra Projects"
                isTagFormat
                readonly={true}
                isTagListFormat
              />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 1, lg: 1 }} gap={{ base: 6, lg: 8 }} width="full">
              <CustomLabelForm
                name="externalProjects"
                label="External Projects"
                isTagFormat
                readonly={true}
                isTagListFormat
              />
            </SimpleGrid>
          </VStack>
        </VStack>
      </form>
    </FormProvider>
  )
}
export default observer(ProfileDetail)
