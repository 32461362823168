import { useState } from 'react'
import React from 'react'
import { useMediaQuery } from '@chakra-ui/react'
import Table from 'components/Table'
import OptionButtonsWithoutDropBox from 'components/Table/DesktopTable/components/OptionButtonsWithoutDropBox'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import { maxMobileSize } from 'constants/common'
import { getValidArray } from 'utils/commonUtils'
import { getFormattedDateWith_MMMDDYYYY } from 'utils/dateFormatUtils'
import { useStores } from 'utils/hooks/useStores'
import ProjectRatingModal from '../components/HourlyProjectRatingsModal'
import { getHourlyProjectHeaderList } from '../container'

const HourlyProjectRating = () => {
  const [isMobile]: boolean[] = useMediaQuery(maxMobileSize)
  const { adminUserStore } = useStores()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [currentProjectRatings, setCurrentProjectRatings] = useState(undefined)
  const { projectRatings } = adminUserStore
  const hourlyProjectRatingsData = projectRatings?.hourlyProjectRatings
  const params = useParams()
  const userId = get(params, 'id', '')

  function openEditModal(projectName: string) {
    const selectedItems = getValidArray(hourlyProjectRatingsData).filter((projectRating) => {
      return projectRating.projectName === projectName
    })
    if (getValidArray(selectedItems)) {
      setCurrentProjectRatings(selectedItems[0])
      setIsOpenModal(true)
    }
  }

  function closeModal() {
    setIsOpenModal(false)
  }

  function editProjectRatingHandler(data) {
    adminUserStore.editHourlyRatePriceV2(userId, data)
    closeModal()
  }

  const tableData = getValidArray(hourlyProjectRatingsData).map((projectRate) => {
    return {
      ...projectRate,
      futureApplyDate: projectRate.futureApplyDate ? getFormattedDateWith_MMMDDYYYY(projectRate.futureApplyDate) : '',
      currentApplyDate: projectRate.currentApplyDate
        ? getFormattedDateWith_MMMDDYYYY(projectRate.currentApplyDate)
        : '',
      actions: (
        <OptionButtonsWithoutDropBox isEdit={true} editActionHandler={() => openEditModal(projectRate.projectName)} />
      ),
    }
  })
  return (
    <React.Fragment>
      <Table
        headerList={getHourlyProjectHeaderList(isMobile)}
        tableData={tableData}
        isStriped
        hasBottomHeader={false}
      />
      <ProjectRatingModal
        isOpen={isOpenModal}
        closeHandler={closeModal}
        data={currentProjectRatings}
        saveHandler={editProjectRatingHandler}
      />
    </React.Fragment>
  )
}

export default observer(HourlyProjectRating)
