export enum EProfileTab {
  PROFILE_DETAIL = 'Profile Detail',
  LABOR_CONTRACT = 'Labor Contract',
  CHANGE_PASSWORD = 'Change Password',
  PROJECT_RATING = 'Project Ratings',
  SKILL_ANALYSIS = 'Skill Analysis',
  CURRICULUM_VITAE = 'Curriculum Vitae',
  USER_DATA = 'User Data',
}

export enum EContractProjectRatingSubTab {
  ACTIVE_CONTRACT = 'Active contract',
  ARCHIVED_CONTRACT = 'Archived Contract',
  HOURLY_PROJECT_RATING = 'Hourly Project Rating',
  FIXED_PROJECT_RATING = 'Fixed Project Rating',
}

export enum EIconName {
  PROFILE_DETAIL = 'user-profile.svg',
  LABOR_CONTRACT = 'project.svg',
  CHANGE_PASSWORD = 'password.svg',
  PROJECT_RATING = 'project-rate.svg',
  SKILL_ANALYSIS = 'skill-analysis.svg',
  CURRICULUM_VITAE = 'cv.svg',
  USER_DATA = 'user-data.svg',
}
