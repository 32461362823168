import React from 'react'
import { getFormattedDateInEnglish, showEndDateOfProjectHistory } from 'utils/dateFormatUtils'
import { getFormattedNumber } from 'utils/numberUtils'
import { STATUS_COLORS } from '../../../../constants';


function getStatusStyle(status) {
  return { color: STATUS_COLORS[status] || '#777777' };
}
class TimesheetTable extends React.Component {
  render() {
    const { data } = this.props
    return (
      <div className="table-content">
        <table className="report-table">
          <thead>
            <tr>
              <th>Developers Total Number</th>
              <th>Started Date</th>
              <th>End Date</th>
              <th>Working Time Total</th>
              <th>Project status</th>
              <th>Total Salary</th>
            </tr>
          </thead>
          <tbody>
            {data && (
              <tr>
                <td>{data.developerTotalNumber}</td>
                <td>{getFormattedDateInEnglish(data.startedDate)}</td>
                <td>{showEndDateOfProjectHistory(data)}</td>
                <td>{getFormattedNumber(data.workingTimeTotal, 0)}</td>
                <td style={getStatusStyle(data.projectStatus)}>{data.projectStatus}</td>
                <td>{getFormattedNumber(data.salaryTotal, 0)}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    )
  }
}

export default TimesheetTable
