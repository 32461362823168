import { useEffect, useContext } from 'react'
import {
  chakra,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  HStack,
} from '@chakra-ui/react'
import { IDiagramQuery } from 'API/diagram/interface'
import { handleError } from 'API/error'
import { IPartnerNameResponse } from 'API/partner/constants'
import Button from 'components/Button'
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form'
import { borderGray200, textBlue500 } from 'theme/globalStyles'
import { ITechnology } from 'types/technology'
import { initialDiagramQuery } from 'containers/Diagram/constant'
import { DiagramContext } from 'containers/Diagram/diagram.context'
import { Container } from 'containers/Diagram/diagram.styles'
import DiagramFilterForm from '../DiagramFilterForm'
import { initialFormValue } from './constants'
import { IFilterForm } from './types'
import { generateDiagramQuery } from './utils'

export interface IDiagramFilterProps {
  openModalFilter: boolean
  toggleModalFilter: () => void
  partnerList: IPartnerNameResponse[]
  technologyList: ITechnology[]
  setQueryParams: (query: IDiagramQuery) => void
}

const DiagramFilter = (props: IDiagramFilterProps) => {
  const { openModalFilter, toggleModalFilter, partnerList, technologyList, setQueryParams } = props
  const methods: UseFormReturn = useForm<IFilterForm>()
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods
  const { fetchDiagramData } = useContext(DiagramContext)

  async function onSubmit(data: IFilterForm): Promise<void> {
    try {
      const query: IDiagramQuery = generateDiagramQuery(data)
      setQueryParams(query)
      await fetchDiagramData(query)
      toggleModalFilter()
    } catch (error) {
      handleError(error, 'frontend/src/containers/Diagram/components/DiagramFilter/index.tsx', 'onSubmit')
    }
  }

  function resetModal(): void {
    reset(initialFormValue)
    setQueryParams(initialDiagramQuery)
  }

  useEffect(resetModal, [])

  return (
    <Container>
      <FormProvider {...methods}>
        <chakra.form onSubmit={handleSubmit(onSubmit)} id="filter-form">
          <Drawer isOpen={openModalFilter} size="sm" placement="right" onClose={toggleModalFilter}>
            <DrawerOverlay />
            <DrawerContent>
              <ModalHeader borderBottom="1px solid" borderColor={borderGray200} fontWeight={500}>
                Filter
              </ModalHeader>
              <ModalCloseButton color={'gray'} background="none" mt="2" cursor="pointer" />
              <ModalBody flex="auto">
                <DiagramFilterForm partnerList={partnerList} technologyList={technologyList} />
              </ModalBody>
              <ModalFooter
                paddingTop={4}
                paddingBottom={4}
                borderTop="1px"
                borderColor="gray.200"
                justifyContent="space-between">
                <Button
                  isLoading={isSubmitting}
                  variant="ghost"
                  customStyles={{ color: textBlue500 }}
                  onClick={resetModal}>
                  Reset
                </Button>
                <HStack spacing={4}>
                  <Button isLoading={isSubmitting} onClick={toggleModalFilter} variant="outline">
                    Cancel
                  </Button>
                  <Button isLoading={isSubmitting} onClick={handleSubmit(onSubmit)} variant="solid">
                    Apply Filter
                  </Button>
                </HStack>
              </ModalFooter>
            </DrawerContent>
          </Drawer>
        </chakra.form>
      </FormProvider>
    </Container>
  )
}

export default DiagramFilter
