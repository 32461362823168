import { IDiagramQuery } from 'API/diagram/interface'
import { IOption } from 'constants/common'
import { EWorkingHourPeriods } from 'constants/enum'
import { getValidArray } from 'utils/commonUtils'
import { IFilterForm } from './types'

export function generateDiagramQuery(formData: IFilterForm): IDiagramQuery {
  return {
    ...formData,
    period: formData?.period?.value as EWorkingHourPeriods,
    date: formData?.date?.toISOString(),
    partners: getValidArray(formData?.partners).map((item: IOption) => item?.value),
    technologies: getValidArray(formData?.technologies).map((item: IOption) => item?.value),
  }
}
