import {
  createAnnouncementAPI,
  deleteAnnouncementAPI,
  getAnnouncementGroupsByMonthAdminAPI,
  updateAnnouncementAPI,
} from 'API/announcement'
import { handleError } from 'API/error'
import { makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'
import RootStore from 'store/rootStore'
import { IAnnouncementFilter, IAnnouncementGroupsByMonth } from 'types/announcement'
import { Messages } from 'constants/index'
import { IAnnouncement } from 'constants/schema'

class AdminAnnouncementStore {
  rootStore: RootStore
  announcementGroupsByMonth: IAnnouncementGroupsByMonth[]
  isLoading: boolean = false

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public async createAnnouncement(payload: IAnnouncement) {
    try {
      await createAnnouncementAPI(payload)
      toast.success(Messages.createAnnouncementSuccess)
    } catch (error) {
      handleError(error, 'src/store/admin/announcementStore.ts', 'createAnnouncement', false, true)
    }
  }

  public async getAnnouncementGroupsByMonth(filter?: IAnnouncementFilter) {
    try {
      this.isLoading = true
      const data = await getAnnouncementGroupsByMonthAdminAPI({ filter })
      this.announcementGroupsByMonth = data
      this.isLoading = false
    } catch (error) {
      handleError(error, 'src/store/admin/announcementStore.ts', 'getAnnouncementGroupsByMonth', false, true)
    }
  }

  public async updateAnnouncement(payload: IAnnouncement, announcementId: string) {
    try {
      await updateAnnouncementAPI(payload, announcementId)
    } catch (error) {
      handleError(error, 'src/store/admin/announcementStore.ts', 'updateAnnouncement')
    }
  }

  public async deleteAnnouncement(announcementId: string) {
    try {
      await deleteAnnouncementAPI(announcementId)
      toast.success(Messages.deleteAnnouncementSuccess)
    } catch (error) {
      handleError(error, 'src/store/admin/announcementStore.ts', 'deleteAnnouncement', false, true)
    }
  }
}

export default AdminAnnouncementStore
