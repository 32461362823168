import { Box, Text } from '@chakra-ui/layout'
import { textGrey700 } from 'theme/globalStyles'
import { IErrorDailyReport } from '../../../../constants'

interface IErrorItemProps {
  error: IErrorDailyReport
}

export const ErrorItem = (props: IErrorItemProps) => {
  const { error } = props
  return (
    <Box>
      <Text fontWeight="700" color={textGrey700} mb={2}>
        {error.errorTitle}
      </Text>
      <Text color={textGrey700} mt={2} dangerouslySetInnerHTML={{ __html: error.errorDescription }} />
    </Box>
  )
}
