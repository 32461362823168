import apiV2 from 'API/api'
import { APIRouters } from 'API/APIRouters'
import { AxiosResponse } from 'axios'
import { IFilter } from 'types/query'
import { IDiagramNode } from 'constants/schema'
import { IAssignment, IAssignmentDetailQuery, IDiagramOverview, IDiagramQuery } from './interface'

export async function getDiagramOverviewAPI(filter: IFilter<IDiagramQuery>): Promise<IDiagramOverview> {
  try {
    const url = APIRouters.diagramNode.overview.value
    const fullUrl: string = `${url}?filter=${JSON.stringify(filter)}`
    const response: AxiosResponse = await apiV2.get(fullUrl)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function updateDiagramNodeAPI(diagramNodeId: string, payload: IDiagramNode): Promise<void> {
  try {
    const url: string = APIRouters.diagramNode.diagramNodeId.value(diagramNodeId)
    await apiV2.patch(url, payload)
  } catch (error) {
    throw error
  }
}

export async function reInitializeNodesCoordinatesAPI(): Promise<void> {
  try {
    const url: string = APIRouters.diagramNode.reInitialize.value
    await apiV2.get(url)
  } catch (error) {
    throw error
  }
}

export async function createNewAssignmentAPI(payload: IAssignment): Promise<void> {
  try {
    const url: string = APIRouters.diagramNode.assign.value
    await apiV2.post(url, payload)
  } catch (error) {
    throw error
  }
}

export async function getAssignmentsAPI(payload: IAssignmentDetailQuery[]): Promise<IAssignment[]> {
  try {
    const url: string = APIRouters.diagramNode.assignment.list.value
    const response: AxiosResponse = await apiV2.post(url, payload)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function updateAssignmentsAPI(payload: IAssignment[]): Promise<void> {
  try {
    const url: string = APIRouters.diagramNode.assignment.update.value
    await apiV2.patch(url, payload)
  } catch (error) {
    throw error
  }
}

export async function removeAssignmentAPI(filter: IFilter<IAssignmentDetailQuery>): Promise<void> {
  try {
    const url: string = APIRouters.diagramNode.assignment.remove.value
    const fullUrl: string = `${url}?filter=${JSON.stringify(filter)}`
    await apiV2.delete(fullUrl)
  } catch (error) {
    throw error
  }
}
