import { useEffect } from 'react'
import { HStack, Stack } from '@chakra-ui/react'
import Button from 'components/Button'
import SingleSelect from 'components/SingleSelect'
import { useFormContext } from 'react-hook-form'
import { IOption } from 'constants/common'
import { ECreatableOptionTypes } from 'constants/enum'
import { ICreatableOption } from 'constants/schema'
import { getValidArray } from 'utils/commonUtils'
import { createOptionsOfReactSelectFromDB, createOptionsBadgeImageOfReactSelectFromDB } from 'utils/dropdownOptionUtils'
import { ISkillFilterFormProps } from '../../constant'

const SkillFilterForm = (props: ISkillFilterFormProps) => {
  const { filter, projectNameList, submitHandler, isLoading, creatableOptionList } = props

  const { handleSubmit, setValue } = useFormContext()
  const options = getValidArray(creatableOptionList)

  const projectOptions: IOption[] = createOptionsOfReactSelectFromDB(projectNameList)
  const titleOptionsData: IOption<string | JSX.Element>[] = createOptionsBadgeImageOfReactSelectFromDB(
    options.filter((option) => option?.type === ECreatableOptionTypes.TITLE_OF_USER)
  )
  const levelOptionsData: IOption<string | JSX.Element>[] = createOptionsBadgeImageOfReactSelectFromDB(
    options.filter((option: ICreatableOption) => option?.type === ECreatableOptionTypes.LEVEL_OF_USER)
  )

  const selectedProject: IOption = projectOptions.find((option) => option.value === filter?.project)
  const selectedTitle: IOption<string | JSX.Element> = titleOptionsData.find((option) => option.value === filter?.title)
  const selectedLevel: IOption<string | JSX.Element> = levelOptionsData.find((option) => option.value === filter?.level)

  useEffect(() => {
    setValue('selectedProject', selectedProject)
    setValue('selectedTitle', selectedTitle)
    setValue('selectedLevel', selectedLevel)
  })
  return (
    <HStack width="inherit" background="white">
      <Stack direction={{ lg: 'row', sm: 'column' }} width="inherit" padding={'16px'}>
        <SingleSelect label="Project" name="selectedProject" optionsData={projectOptions} isClearable={true} />
        <SingleSelect
          name="selectedTitle"
          label="Title"
          optionsData={titleOptionsData}
          containerStyle={{ lineHeight: '0px' }}
          isClearable={true}
        />
        <SingleSelect
          name="selectedLevel"
          label="Level"
          optionsData={levelOptionsData}
          isClearable={true}
          containerStyle={{ lineHeight: '0px' }}
        />
        <Button
          variant="solid"
          height="42px"
          width="full"
          customStyles={{ minWidth: '180px', alignSelf: 'flex-end' }}
          isLoading={isLoading}
          rightIcon="arrow-right-line-white.svg"
          onClick={handleSubmit(submitHandler)}>
          View Summary
        </Button>
      </Stack>
    </HStack>
  )
}

export default SkillFilterForm
