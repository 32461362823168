import React from 'react'
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import { getValidArray } from 'utils/commonUtils'
import { stripHtml } from 'utils/commonUtils'
import { DownloadProps } from './interfaces'
import '../../TimeSheetAdmin/index.scss'

class Download extends React.Component<DownloadProps> {
  createAndDownloadExcel = async () => {
    const { data, headers, headersOfTotalSheet } = this.props
    const workbook = new ExcelJS.Workbook()

    getValidArray(data).forEach((item) => {
      const sheet = workbook.addWorksheet(item.type === 'totalSheet' ? 'Total' : item.developer)
      const currentHeaders = item.type === 'totalSheet' ? headersOfTotalSheet : headers

      const headerRow = sheet.addRow(currentHeaders.map((h) => h.label))
      headerRow.eachCell((cell) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFFFF00' },
          bgColor: { argb: 'FF0000FF' },
        }
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        }
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'center',
          wrapText: true,
        }
        cell.font = {
          bold: true,
        }
      })

      item.data.forEach((dataItem) => {
        const rowData = currentHeaders.map((header) => {
          const cellValue = dataItem[header.value]
          return typeof cellValue === 'string' ? stripHtml(cellValue) : cellValue
        })
        const row = sheet.addRow(rowData)
        row.eachCell((cell) => {
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'left',
            wrapText: true,
          }
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }
        })
      })

      sheet.columns.forEach((column) => {
        let maxLength = 0
        column.eachCell({ includeEmpty: true }, (cell) => {
          const cellLength = cell.value ? cell.value.toString().length : 0
          if (cellLength > maxLength) maxLength = cellLength
        })
        column.width = maxLength > 50 ? 50 : Math.max(maxLength, 10)
      })

      const totalWorkingTimeColumnIndex = currentHeaders.findIndex((h) => h.label === 'Total Working Time(hours)') + 1
      if (totalWorkingTimeColumnIndex > 0) {
        sheet.getColumn(totalWorkingTimeColumnIndex).eachCell((cell) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }
        })
      }
    })

    const buffer = await workbook.xlsx.writeBuffer()
    saveAs(
      new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
      `${this.props.data[0].project}_${this.props.dateOfReport}_${this.props.paymentCategory}.xlsx`
    )
  }

  render() {
    const { data } = this.props
    if (!data || data.length === 0) {
      return null
    }
    return (
      <button className="primary-button export-csv-button" onClick={this.createAndDownloadExcel}>
        Download Data
      </button>
    )
  }
}

export default Download
