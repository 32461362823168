import { ELeaveStatus } from 'constants/enum'

export const typeStyles = {
  Remote: {
    border: '1px solid #3182ce',
    background: '#ebf8ff',
    color: '#3182ce',
  },
  Holiday: {
    background: '#fffff0',
    border: '1px solid #d69e2e',
    color: '#d69e2e',
  },
  Sick: {
    border: '1px solid #e53e3e',
    background: '#fed7d7',
    color: '#e53e3e',
  },
}

export const statusStyles = {
  [ELeaveStatus.APPROVED]: {
    background: '#f0fff4',
    border: '1px solid #38a169',
    color: '#38a169',
  },
  [ELeaveStatus.PENDING]: {
    background: '#ebf8ff',
    border: '1px solid #3182ce',
    color: '#3182ce',
  },
  [ELeaveStatus.REJECTED]: {
    background: '#f7fafc',
    border: '1px solid #718096',
    color: '#718096',
  },
}
