import { Table, Tbody, Tr, Box, Td, Stack, Container } from '@chakra-ui/react'
import { textGrey600, textSecondary } from 'theme/globalStyles'
import { getFormattedNumber } from 'utils/numberUtils'
import { extractData } from './container'
import { IHighlightTable } from './interfaces'
import styles from './table.module.scss'

const HighlightTable = (props: IHighlightTable) => {
  const { data } = props
  const { numberOfDeveloper, rateExchange, totalWorkingTime, currencyName, totalSalary, rateExchangeUnit } =
    extractData(data)

  return (
    <Stack width="inherit">
      <Container maxWidth="100%" padding="0px" margin="0px !important">
        <Box className={styles.scrollAble}>
          <Table variant="striped" colorScheme="gray">
            <Tbody className={styles.tbody}>
              <Tr>
                <Td color={textGrey600} fontWeight={700} fontSize={12} lineHeight={16 / 12}>
                  NUMBER OF WORKING DEVELOPERS
                </Td>
                <Td fontSize={14} isNumeric alignContent="start">
                  {numberOfDeveloper}
                </Td>
              </Tr>
              <Tr>
                <Td color={textGrey600} fontWeight={700} fontSize={12} lineHeight={16 / 12}>
                  {`PROJECT’S CURRENT RATE EXCHANGE ${rateExchangeUnit}`}
                </Td>
                <Td fontSize={14} isNumeric>
                  {getFormattedNumber(rateExchange, 0)}
                </Td>
              </Tr>
              <Tr>
                <Td color={textGrey600} fontWeight={700} fontSize={12} lineHeight={16 / 12}>
                  TOTAL WORKING (HOURS)
                </Td>
                <Td fontSize={14}>{getFormattedNumber(totalWorkingTime, 1)}</Td>
              </Tr>
              <Tr>
                <Td color={textGrey600} fontWeight={700} fontSize={12} lineHeight={16 / 12}>
                  TOTAL SALARY ({currencyName})
                </Td>
                <Td color={textSecondary} fontSize={14}>
                  {getFormattedNumber(totalSalary, 1)}
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>
      </Container>
    </Stack>
  )
}

export default HighlightTable
