import { VStack } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { frontendRoutes } from 'constants/routes'
import './index.scss'
import NotFoundBackgroundImage from 'assets/images/background/404notfound_page.png'

const NotFound = () => {
  const role = window.localStorage.getItem('userRole')
  let linkToHome = frontendRoutes.timesheetPage.board.value
  if (role === 'admin') {
    linkToHome = frontendRoutes.adminDashboardPage.statistic.value
  }
  return (
    <VStack backgroundImage={NotFoundBackgroundImage} className="not-found-page">
      <div className="not-found-content">
        <Link to={linkToHome}>Take me home</Link>
      </div>
    </VStack>
  )
}

export default NotFound
