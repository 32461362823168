import { IPaymentList } from 'types/payment'
import { getFormattedSalary } from 'utils/numberUtils'
import DetailPaymentTable from '../DetailPaymentTable'

interface IPaymentSectionProps {
  payment: IPaymentList
  isAdmin: boolean
  editHandler: (id: string, field: string, currentValue: boolean) => Promise<void>
  triggerShowRequestPaymentTable?: (x: []) => void
}

const PaymentSection = (props: IPaymentSectionProps) => {
  const { payment, isAdmin, editHandler, triggerShowRequestPaymentTable } = props
  const { developerInfo, paymentData } = payment
  const paymentOfPayroll = paymentData.filter((item) => item.isPayroll)
  const needToPaymentOfFreelancerFee = paymentData.filter((item) => item.isNeedToPaySalary && item.isFreelancerFee)
  const unNeedToPaymentOfFreelancerFee = paymentData.filter(
    (item) => !item.isNeedToPaySalary && item.isFreelancerFee && !item.isBelongsToExternalProject
  )
  const unNeedToPayOfExternalProjects = paymentData.filter(
    (item) => !item.isNeedToPaySalary && item.isBelongsToExternalProject
  )

  return (
    <div>
      <div className="box box-default">
        <div className="box-header blue-color-text">
          <h3 className="blue-color-text">Payment List Of {developerInfo.fullName}</h3>
        </div>
        <div className="box-divider" />
        <div className={`box-body`}>
          <h4 className="table-name yellow-color-text"> A. Highlight Information </h4>
          <table className="report-table">
            <thead>
              <tr>
                <th>Bank Account</th>
                <th>Bank Name</th>
                <th>Total Payroll (VND)</th>
                <th>Total Freelancer Fee (VND)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{developerInfo.bankAccount}</td>
                <td>{developerInfo.bankName}</td>
                <td>{getFormattedSalary(developerInfo.totalPayroll)}</td>
                <td>{getFormattedSalary(developerInfo.totalFreelancerFee)}</td>
              </tr>
            </tbody>
          </table>
          <div>
            <h4 className="table-name yellow-color-text"> B. Detail Report Of Payment </h4>
            <div>
              {Array.isArray(paymentOfPayroll) && paymentOfPayroll.length > 0 && (
                <DetailPaymentTable
                  data={paymentOfPayroll}
                  tableName="Payroll (Paid Payment)"
                  isPayroll={true}
                  isAdmin={isAdmin}
                  isNeedToPay={true}
                  editHandler={editHandler}
                  triggerShowRequestPaymentTable={triggerShowRequestPaymentTable}
                />
              )}
              {Array.isArray(unNeedToPaymentOfFreelancerFee) && unNeedToPaymentOfFreelancerFee.length > 0 && (
                <DetailPaymentTable
                  data={unNeedToPaymentOfFreelancerFee}
                  tableName="Detail Of Payroll (Don't Paid Payment)"
                  isAdmin={isAdmin}
                  isNeedToPay={false}
                  isPayroll={false}
                  editHandler={editHandler}
                />
              )}
              {Array.isArray(needToPaymentOfFreelancerFee) && needToPaymentOfFreelancerFee.length > 0 && (
                <DetailPaymentTable
                  data={needToPaymentOfFreelancerFee}
                  tableName="Freelancer Fee (Paid Payment)"
                  isAdmin={isAdmin}
                  isNeedToPay={true}
                  isPayroll={false}
                  editHandler={editHandler}
                  triggerShowRequestPaymentTable={triggerShowRequestPaymentTable}
                />
              )}
              {Array.isArray(unNeedToPayOfExternalProjects) && unNeedToPayOfExternalProjects.length > 0 && (
                <DetailPaymentTable
                  data={unNeedToPayOfExternalProjects}
                  tableName="External Project Fee (Don't Paid Payment)"
                  isAdmin={isAdmin}
                  isNeedToPay={false}
                  isPayroll={false}
                  editHandler={editHandler}
                  triggerShowRequestPaymentTable={triggerShowRequestPaymentTable}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentSection
