import { useEffect } from 'react'
import { Box } from '@chakra-ui/layout'
import GeneralList from 'components/GeneralList'
import LoadingChakra from 'components/LoadingChakra'
import { observer } from 'mobx-react'
import { useStores } from 'utils/hooks/useStores'

const PolicyUser = () => {
  const { userPolicyStore } = useStores()
  const { policyGroupsByMonth, isLoading } = userPolicyStore

  useEffect(() => {
    userPolicyStore.getPolicyGroupsByMonth()
  }, [])

  return (
    <Box background="white" padding="24px" borderRadius="6px">
      <LoadingChakra isLoading={isLoading}>
        <GeneralList dataGroupsByMonth={policyGroupsByMonth} />
      </LoadingChakra>
    </Box>
  )
}
export default observer(PolicyUser)
