import { IDiagramQuery } from 'API/diagram/interface'
import dayjs from 'dayjs'
import { IOption } from 'constants/common'
import { EProjectTypes, EWorkingHourPeriods } from 'constants/enum'
import { ISidebarInfo } from './components/NodeDetail/constant'

export const DELAY_RENDER_TIME = 200

export const editAssignFields = [
  { label: 'USER', width: '120px' },
  { label: 'HOURLY RATE ($/HR)' },
  { label: 'CLIENT RATE ($/HR)' },
  { label: 'BILLABLE MONTHLY AMOUNT ($/MONTH)' },
  { label: 'COMMIT TIME (HRS/WEEK)' },
  { label: 'TYPE' },
  { label: 'SUPERVISOR', width: '94px' },
]

export enum EProjectTypeLabels {
  MAIN = 'Main',
  EXTRA = 'Extra',
  EXTERNAL = 'External',
}

export const projectTypeOptions: IOption[] = [
  { label: EProjectTypeLabels.MAIN, value: EProjectTypes.NORMAL_PROJECT },
  { label: EProjectTypeLabels.EXTRA, value: EProjectTypes.EXTRA_PROJECT },
  { label: EProjectTypeLabels.EXTERNAL, value: EProjectTypes.EXTERNAL_PROJECT },
]

export interface IEditAssignModalProps {
  isProjectNode: boolean
  nodeDetailInformation: ISidebarInfo
  isOpen: boolean
  onClose: () => void
}

export enum EEditAssignModalType {
  PROJECT = 'project',
  USER = 'user',
}

export enum EDiagramNodeActiveClassNames {
  PROJECT = 'node-project-active',
  USER = 'node-user-active',
}

export enum EDiagramLinkActiveClassNames {
  PROJECT = 'project-active',
  USER = 'user-active',
}

export const initialDiagramQuery: IDiagramQuery = {
  period: EWorkingHourPeriods.WEEK,
  date: dayjs().toISOString(),
  partners: [],
  technologies: [],
  isInactive: false,
}
