import { backgroundGrey600 } from 'theme/globalStyles'

export const DropdownStylesWithoutCondition = () => ({
  container: (provided: Record<string, unknown>) => ({
    ...provided,
    width: 'full',
    cursor: 'pointer',
  }),
  option: (provided: Record<string, unknown>) => ({
    ...provided,
    width: 'auto',
    cursor: 'pointer',
  }),
  dropdownIndicator: (provided: Record<string, unknown>) => ({
    ...provided,
    bg: 'transparent',
    px: 2,
    cursor: 'pointer',
    color: 'gray.700',
  }),
  indicatorSeparator: (provided: Record<string, unknown>) => ({
    ...provided,
    display: 'none',
  }),
  clearIndicator: (provided: Record<string, unknown>) => ({
    ...provided,
    display: 'none',
  }),
  multiValueRemove: (provided: Record<string, unknown>) => ({
    ...provided,
    color: 'gray.700',
  }),
  placeholder: (provided: Record<string, unknown>) => ({
    ...provided,
  }),
  menu: (provided: Record<string, unknown>) => ({
    ...provided,
    boxShadow: `0 0 0 1px ${backgroundGrey600}`,
    zIndex: 9999,
  }),
  menuList: (provided: Record<string, unknown>) => ({
    ...provided,
    width: '100%',
    minWidth: 'unset',
    zIndex: 9999,
  }),
})

export const DropdownStyles = (isDisabled: boolean, optionStyle, placeholderStyle, containerStyle) => ({
  container: (provided: Record<string, unknown>) => ({
    ...provided,
    width: 'full',
    cursor: 'pointer',
    ...containerStyle,
  }),
  noOptionsMessage: (provided: Record<string, unknown>) => ({
    ...provided,
    width: 'fit-content',
  }),
  option: (provided: Record<string, unknown>) => ({
    ...provided,
    width: 'auto',
    cursor: 'pointer',
    ...optionStyle,
  }),
  dropdownIndicator: (provided: Record<string, unknown>) => ({
    ...provided,
    bg: 'transparent',
    px: 2,
    cursor: 'pointer',
    display: isDisabled ? 'none' : undefined,
  }),
  indicatorSeparator: (provided: Record<string, unknown>) => ({
    ...provided,
    display: 'none',
  }),
  clearIndicator: (provided: Record<string, unknown>) => ({
    ...provided,
    color: 'gray.700',
    background: 'transparent',
    cursor: 'pointer',
    display: 'flex',
  }),
  placeholder: (provided: Record<string, unknown>) => ({
    ...provided,
    ...placeholderStyle,
  }),
  menu: (provided: Record<string, unknown>) => ({
    ...provided,
    boxShadow: `0 0 0 1px ${backgroundGrey600}`,
    width: '100%',
    zIndex: 9999,
  }),
  menuList: (provided: Record<string, unknown>) => ({
    ...provided,
    width: '100%',
    minWidth: 'unset',
    zIndex: 9999,
  }),
})
