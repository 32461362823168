import { useState, useEffect } from 'react'
import { FormControl } from '@chakra-ui/form-control'
import { TimeIcon } from '@chakra-ui/icons'
import { Box, SimpleGrid } from '@chakra-ui/layout'
import { FormLabel, VStack, HStack, InputRightElement, InputGroup, FormErrorMessage, Text } from '@chakra-ui/react'
import DatePicker from 'components/DatePicker'
import Editor from 'components/Editor'
import FormInput from 'components/FormInput'
import GroupRadio from 'components/GroupRadio'
import { Controller } from 'react-hook-form'
import { ELeaveType } from 'constants/enum'
import FormInputWithMask from 'containers/TimeSheet/TimeSheetBoard/components/FormInputWithMask'
import { ILeaveFormProps } from './interface'

const LeaveForm = (props: ILeaveFormProps) => {
  const { methods, leaveSelectedData } = props
  const {
    control,
    formState: { errors, isSubmitSuccessful },
    clearErrors,
  } = methods

  const [selectedLeaveType, setSelectedLeaveType] = useState(leaveSelectedData?.type)
  const leaveType = [ELeaveType.SICK, ELeaveType.HOLIDAY, ELeaveType.REMOTE]

  function handleLeaveTypeChange(value: string) {
    setSelectedLeaveType(value)
    if (value !== ELeaveType.REMOTE) {
      clearErrors('commitTime')
      methods.setValue('commitTime', '')
    }
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      setSelectedLeaveType(ELeaveType.SICK)
    }
  }, [isSubmitSuccessful])

  return (
    <Box>
      <SimpleGrid columns={1} gap="24px">
        <FormControl isInvalid={Boolean(errors?.type)}>
          <GroupRadio
            name="type"
            data={leaveType}
            label="Leave Type"
            defaultValue={leaveSelectedData?.type || ELeaveType.SICK}
            onChange={handleLeaveTypeChange}
          />
          <FormErrorMessage>{errors?.type?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={Boolean(errors?.commitTime)}>
          <FormInput
            name="commitTime"
            label="Commit time (remote leave only)"
            placeholder="Enter your commit time"
            disabled={selectedLeaveType !== ELeaveType.REMOTE}
            isRequired={selectedLeaveType === ELeaveType.REMOTE}
            type="number"
          />
        </FormControl>
        <FormControl isInvalid={Boolean(errors?.startDate || errors?.startTime)}>
          <FormLabel color={'gray.700'} fontWeight={400} marginBottom={0}>
            Start Date
          </FormLabel>
          <VStack alignItems="flex-start">
            <HStack
              height={10}
              marginTop="20px !important"
              width="100%"
              marginBottom={Boolean(errors?.startDate?.message || errors?.startTime?.message) ? '15px' : '0'}>
              <HStack width="50%">
                <Controller
                  control={control}
                  name="startDate"
                  render={({ field }) => (
                    <DatePicker
                      selectedDate={field.value}
                      onChange={(date) => {
                        field.onChange(date)
                      }}
                    />
                  )}
                />
              </HStack>
              <HStack width="50%">
                <InputGroup>
                  <FormInputWithMask
                    name="startTime"
                    label="Start time"
                    placeholder="Choose time..."
                    format="##:##"
                    mask={['H']}
                    isRequired
                  />
                  <InputRightElement
                    width="40px"
                    height="40px"
                    top="0px"
                    children={<TimeIcon color="rgba(0, 0, 0, 0.54)" width={6} />}
                  />
                </InputGroup>
              </HStack>
            </HStack>
            <FormErrorMessage>{errors?.startDate?.message || errors?.startTime?.message}</FormErrorMessage>
          </VStack>
        </FormControl>
        <FormControl isInvalid={Boolean(errors?.endDate || errors?.endTime)}>
          <FormLabel color={'gray.700'} fontWeight={400} marginBottom={0}>
            End Date
          </FormLabel>
          <VStack alignItems="flex-start">
            <HStack
              height={10}
              marginTop="20px !important"
              width="100%"
              marginBottom={Boolean(errors?.endDate?.message || errors?.endTime?.message) ? '15px' : '0'}>
              <HStack width="50%">
                <Controller
                  control={control}
                  name="endDate"
                  rules={{ required: 'End date is required' }}
                  render={({ field }) => (
                    <DatePicker
                      selectedDate={field.value}
                      onChange={(date) => {
                        field.onChange(date)
                      }}
                      isRequired={true}
                    />
                  )}
                />
              </HStack>
              <HStack width="50%">
                <InputGroup>
                  <FormInputWithMask
                    name="endTime"
                    label="End time"
                    placeholder="Choose time..."
                    format="##:##"
                    mask={['H']}
                    isRequired
                  />
                  <InputRightElement
                    width="40px"
                    height="40px"
                    top="0px"
                    children={<TimeIcon color="rgba(0, 0, 0, 0.54)" width={6} />}
                  />
                </InputGroup>
              </HStack>
            </HStack>
            <FormErrorMessage>{errors?.endDate?.message || errors?.endTime?.message}</FormErrorMessage>
          </VStack>
        </FormControl>
        <FormControl isInvalid={Boolean(errors?.content)}>
          <FormLabel color={'gray.700'} fontWeight={400}>
            Reason
          </FormLabel>
          <Text color={'blue.600'} fontSize={'14px'} margin={0}>
            Your reason must be greater than 20 characters and less than 200 characters
          </Text>
          <Controller
            control={control}
            name="content"
            rules={{
              minLength: { value: 20, message: 'Reason must be at least 20 characters' },
              maxLength: { value: 200, message: 'Reason must not exceed 200 characters' },
            }}
            render={({ field }) => <Editor {...field} placeholder="Enter your reason" isRequired />}
          />
        </FormControl>
      </SimpleGrid>
    </Box>
  )
}

export default LeaveForm
