import { useEffect } from 'react'
import * as am4charts from '@amcharts/amcharts4/charts'
import * as am4core from '@amcharts/amcharts4/core'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import { observer } from 'mobx-react'
import { IOptions, IChartData, IFormattedDeveloperOfProject } from 'types/dashboard'
import { useStores } from 'utils/hooks/useStores'
import {
  createXYChart,
  addAxes,
  renderSeriesLines,
  createLegend,
  addChartCursor,
  handleChartEvent,
  setSeriesDataFields,
  setSeriesProperties,
  createBullet,
} from './container'
import { IChartProps } from './interfaces'
am4core.useTheme(am4themes_animated)

const Chart = (props: IChartProps) => {
  const { seriesLines, chartData, options, expandChartHandler, reduceChartHandler } = props
  const { adminDashboardStore } = useStores()

  const createChart = (
    seriesLines: IFormattedDeveloperOfProject[],
    chartData: IChartData[],
    options: IOptions,
    expandChartHandler: () => void
  ) => {
    const chart = createXYChart()
    addAxes(chart)
    renderSeriesLines(seriesLines, chartData, options, createSeries, chart)
    createLegend(chart, adminDashboardStore)
    addChartCursor(chart)
    handleChartEvent(chart, chartData, expandChartHandler)
    return chart
  }

  const createSeries = (
    chart: am4charts.XYChart,
    field: string,
    name: string,
    data: any[],
    id: string
  ): am4charts.LineSeries => {
    const series = chart.series.push(new am4charts.LineSeries())
    setSeriesDataFields(series, field)
    setSeriesProperties(series, name, id, data)
    createBullet(series)
    return series
  }

  useEffect(() => {
    const chart = createChart(seriesLines, chartData, options, expandChartHandler)
    adminDashboardStore.changeChartIndicators(seriesLines.map((item) => item._id))
    return () => {
      chart.dispose()
      reduceChartHandler()
      adminDashboardStore.changeChartIndicators(seriesLines.map((item) => item._id))
    }
  }, [seriesLines, chartData, options])

  return <div id="chartdiv" style={{ width: '100%', height: '500px' }} />
}

export default observer(Chart)
