import { IPagination } from 'types/table'

export interface ITabletTable {
  headerList?: object
  tableData?: object[]
  isShowPagination?: boolean
  pagination?: IPagination
  isDetail?: boolean
  detailActionHandler?: (e?) => void
}

export enum ETabletTableActionName {
  ACTIONS_TABLET = 'actionsTablet',
}
export enum ETechnologyListFilter {
  TECHNOLOGY_LIST_FILTER = 'technologiesListFilter',
}
