export const auth = {
  value: '/auth',
  //Login Page
  login: {
    value: '/auth/login',
  },
  // Reset Password Page
  resetPassword: {
    value: '/auth/reset-password',
  },
  // Forgot Password Page
  forgotPassword: {
    value: '/auth/forgot-password',
  },
  // Header Component (Logout Function)
  logout: {
    value: '/auth/logout',
  },
  // Refresh Token
  refreshToken: {
    value: (email: string) => `/auth/refresh-token/${email}`,
  },
}
