import { Stack } from '@chakra-ui/react'
import { forgotPasswordAPI } from 'API/auth'
import { IForgotPasswordRequest } from 'API/auth/constants'
import Button from 'components/Button'
import InputField from 'components/InputField'
import { useForm, FormProvider, UseFormReturn } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { Messages } from 'constants/index'
import { useYupValidationResolver } from 'utils/hooks/useYupValidationResolver'
const validationSchema = Yup.object().shape({
  email: Yup.string().email(Messages.invalidEmail).required(Messages.fieldRequired),
})

const defaultValues = {
  email: '',
}

const ForgotPasswordForm = ({ setIsSubmitted }) => {
  const resolver = useYupValidationResolver(validationSchema)
  const method: UseFormReturn<IForgotPasswordRequest> = useForm<IForgotPasswordRequest>({ defaultValues, resolver })
  async function onSubmit(data: IForgotPasswordRequest): Promise<void> {
    try {
      await forgotPasswordAPI(data)
      setIsSubmitted(true)
    } catch (error) {
      if (error?.message.includes('email-address-is-not-registered-please-check-again')) {
        toast.error('Email not found or not registered. Please check again.', { autoClose: 3000 })
      } else {
        toast.error('An error occurred. Please try again later.', { autoClose: 3000 })
      }
    }
  }
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = method

  return (
    <FormProvider {...method}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing="6">
          <InputField
            label="Your Email"
            type="email"
            name="email"
            errors={errors}
            placeholder="Enter your email"
            {...register('email')}
          />
        </Stack>
        <Stack mt={6}>
          <Button width="full" type="submit">
            Send
          </Button>
        </Stack>
      </form>
    </FormProvider>
  )
}

export default ForgotPasswordForm
