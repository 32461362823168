import { HStack, Box, Text } from '@chakra-ui/react'
import Icon from 'components/Icon'

interface ISummaryFilterInfoBoxProps {
  developerName: string
  projectName: string
  startDate: string
  endDate: string
}
const SummaryFilterInfoBox = (props: ISummaryFilterInfoBoxProps) => {
  const { developerName, projectName, startDate, endDate } = props
  return (
    <HStack backgroundColor={'gray.100'} borderRadius={'6px'} padding={'16px'} alignSelf={'stretch'}>
      <Box flex={1}>
        <HStack mb={2} margin={0}>
          <Icon iconName="user-profile.svg" size={20} /> <Text> {developerName} </Text>
        </HStack>
      </Box>
      <Box flex={1}>
        <HStack mb={2} margin={0}>
          <Icon iconName="project.svg" size={20} /> <Text> {projectName} </Text>
        </HStack>
      </Box>
      <Box flex={1}>
        <HStack mb={2} margin={0}>
          <Icon iconName="calendar.svg" size={20} /> <Text> {`${startDate} - ${endDate}`} </Text>
        </HStack>
      </Box>
    </HStack>
  )
}

export default SummaryFilterInfoBox
