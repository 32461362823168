import { useEffect, useState } from 'react'
import { useMediaQuery, VStack } from '@chakra-ui/react'
import { handleError } from 'API/error'
import ConfirmModal from 'components/ConfirmModal'
import { EModalType } from 'components/ConfirmModal/enums'
import Table from 'components/Table'
import MoreDropdown from 'components/Table/DesktopTable/components/MoreDropdown'
import ViewContractModal from 'components/ViewContractModal'
import { get } from 'lodash'
import set from 'lodash/set'
import { observer } from 'mobx-react'
import moment from 'moment'
import { generatePath } from 'react-router'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { IRowActionItem } from 'types/table'
import { maxTabletSize } from 'constants/common'
import { actionOnDataTable } from 'constants/index'
import { frontendRoutes } from 'constants/routes'
import { IContract } from 'constants/schema'
import { getValidArray } from 'utils/commonUtils'
import { useStores } from 'utils/hooks/useStores'
import { headerDesktop, headerTabletAndMobile } from '../constants'

const ContractList = () => {
  const { adminContractStore } = useStores()
  const [isOpenModal, setOpenModal] = useState(false)
  const [contractName, setContractName] = useState('')
  const { contractList, count, currentContractContent } = adminContractStore
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [openActiveModal, setOpenActiveModal] = useState<boolean>(false)
  const [selectedContract, setSelectedProject] = useState<IContract>()
  const history = useHistory()
  const [isTablet] = useMediaQuery(maxTabletSize)
  const pageIndex: number = Number(get(history, 'location.state.page', 1)) || 1
  const pagination = {
    includePagination: true,
    pageIndex,
    tableLength: count,
    gotoPage: (page: number) => {
      history.push({ pathname: frontendRoutes.contractPage.list.value, state: { page } })
      adminContractStore.getContractListWithPagination(false, history)
    },
  }
  useEffect(() => {
    adminContractStore.getContractListWithPagination(false, history)
  }, [])

  async function handleDownloadFile(id: string, fileName: string): Promise<void> {
    try {
      await adminContractStore.downloadContractFile(id, fileName)
    } catch (error) {
      handleError(error as Error, 'src/containers/Contract/ContractList/index.tsx', 'handleDownloadFile')
    }
  }

  async function handleShowFile(id: string, fileName: string): Promise<void> {
    try {
      setOpenModal(true)
      setContractName(fileName)
      await adminContractStore.showContractFile(id, fileName)
    } catch (error) {
      handleError(error as Error, 'src/containers/Contract/ContractList/index.tsx', 'handleShowFile')
    }
  }

  function onCloseModal() {
    setOpenModal(false)
  }

  function toggleOpenDeleteModal() {
    setOpenDeleteModal(!openDeleteModal)
  }

  function toggleOpenActiveModal() {
    setOpenActiveModal(!openDeleteModal)
  }

  async function handleClickOKConfirmedDelete(id: string): Promise<void> {
    try {
      await adminContractStore.deleteContract(id)
      adminContractStore.getContractListWithPagination(false, history)
      setOpenDeleteModal(false)
    } catch (error) {
      toast.error('Delete contract failed!')
      handleError(error as Error, 'src/containers/Contract/ContractList/index.tsx', 'handleClickOKConfirmedDelete')
    }
  }

  async function handleClickOKConfirmedActive(id: string): Promise<void> {
    try {
      await adminContractStore.activeContract(id)
      adminContractStore.getContractListWithPagination(false, history)
      setOpenActiveModal(false)
    } catch (error) {
      toast.error('Active contract failed!')
      handleError(error as Error, 'src/containers/Contract/ContractList/index.tsx', 'handleClickOKConfirmedActive')
    }
  }

  async function handleClickOKConfirmedDeactive(id: string): Promise<void> {
    try {
      await adminContractStore.deactiveContract(id)
      adminContractStore.getContractListWithPagination(false, history)
      setOpenActiveModal(false)
    } catch (error) {
      toast.error('Deactive contract failed!')
      handleError(error as Error, 'src/containers/Contract/ContractList/index.tsx', 'handleClickOKConfirmedDeactive')
    }
  }

  function callOpenDialogConfirm(contract: IContract, nameOfEvent: string) {
    set(contract, 'shortName', contract?.number ?? '')
    if (nameOfEvent === actionOnDataTable.DELETE) {
      setSelectedProject(contract)
      toggleOpenDeleteModal()
    } else {
      setSelectedProject(contract)
      toggleOpenActiveModal()
    }
  }

  const dataInTable = getValidArray(contractList).map((contract: IContract) => {
    const isActive: boolean = contract?.isActive ?? false
    const isDeleted: boolean = contract?.isDeleted
    const actions: IRowActionItem[] = []
    const type = `${contract?.type} ${contract?.laborContractCategory ? `| ${contract.laborContractCategory}` : ''}`
    let statusText: String = 'Not-Active'
    let deletedText: String = 'Not-Deleted'
    const pathname = generatePath(frontendRoutes.contractPage.contractDetail.id.value, { id: contract.id })
    const actionDetailHandler = () => history.push(pathname)
    if (isActive) {
      statusText = 'Active'
      actions.push({
        name: 'Send email',
        handler: () => adminContractStore.sendContractToEmail(contract.id, contract.fileName),
      })
      actions.push({
        name: 'Deactivate',
        customClassNames: ['isWarning'],
        handler: () => callOpenDialogConfirm(contract, actionOnDataTable.ACTIVE),
      })
      actions.push({
        name: 'Delete',
        customClassNames: ['isDanger'],
        handler: () => callOpenDialogConfirm(contract, actionOnDataTable.DELETE),
      })
      actions.push({
        name: 'Edit',
        handler: () => actionDetailHandler(),
      })
    }
    if (isDeleted) {
      statusText = 'Deleted'
      deletedText = 'Deleted'
    }
    if (!isActive && !isDeleted) {
      actions.push({
        name: 'Active',
        customClassNames: ['isSafe'],
        handler: () => callOpenDialogConfirm(contract, actionOnDataTable.ACTIVE),
      })
    }
    return {
      ...contract,
      status: statusText,
      type: type,
      isDeleted: deletedText,
      publishedDate: moment(contract?.publishedDate ?? '').format('DD/MM/YYYY'),
      actionsTablet: actions,
      actions: (
        <MoreDropdown
          isDownload={true}
          downloadActionHandler={() => handleDownloadFile(contract.id, contract.fileName)}
          isDetail={true}
          detailActionHandler={() => handleShowFile(contract.id, contract.fileName)}
          actions={actions}
        />
      ),
    }
  })

  return (
    <VStack width="full">
      <Table
        headerList={isTablet ? headerTabletAndMobile : headerDesktop}
        isLoading={adminContractStore.isContractListLoading}
        tableData={dataInTable}
        isStriped
        pagination={pagination}
      />
      <ViewContractModal
        isOpen={isOpenModal}
        onClose={onCloseModal}
        content={currentContractContent}
        fileName={contractName}
      />
      <ConfirmModal
        data={selectedContract}
        isOpen={openDeleteModal}
        closeHandler={toggleOpenDeleteModal}
        OKClickHandler={handleClickOKConfirmedDelete}
        title={'Contract'}
        content={'Contract Number'}
        type={EModalType.DELETE}
      />
      <ConfirmModal
        data={selectedContract}
        isOpen={openActiveModal}
        closeHandler={toggleOpenActiveModal}
        OKClickHandler={selectedContract?.isActive ? handleClickOKConfirmedDeactive : handleClickOKConfirmedActive}
        title={'Contract'}
        content={'Contract Number'}
        type={EModalType.ACTIVE}
      />
    </VStack>
  )
}

export default observer(ContractList)
