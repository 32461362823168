import { useEffect, useState } from 'react'
import { Search2Icon } from '@chakra-ui/icons'
import { VStack, HStack, Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import { ICountry, ICountryCreationForm } from 'API/country/constants'
import Button from 'components/Button'
import ConfirmModal from 'components/ConfirmModal'
import { EModalType } from 'components/ConfirmModal/enums'
import Table from 'components/Table'
import OptionButtonsWithoutDropBox from 'components/Table/DesktopTable/components/OptionButtonsWithoutDropBox'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { backgroundGrey200 } from 'theme/globalStyles'
import { EActionType } from 'constants/enum'
import { frontendRoutes } from 'constants/routes'
import { getValidArray } from 'utils/commonUtils'
import { useStores } from 'utils/hooks/useStores'
import CountryModal from '../components/CountryModal'
import { getCountryHeaderList } from '../constants'
import styles from '../globalConfig.module.scss'

const Country = () => {
  const { adminCountryStore } = useStores()
  const history = useHistory()

  const { listCount, countryList, isLoadNewCountryList, isLoadingCountryList } = adminCountryStore
  const [isOpenCreateModal, setIsOpenCreateModal] = useState<boolean>(false)
  const [isOpenConfirmDeleteModal, setIsOpenConfirmDeleteModal] = useState<boolean>(false)
  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false)
  const [filter, setFilter] = useState({})
  const [selectedCountry, setSelectedCountry] = useState<ICountry>()

  useEffect(() => {
    adminCountryStore.getPaginatedCountries(true, history, filter)
  }, [filter, isLoadNewCountryList])

  function openCreateModal(): void {
    setIsOpenCreateModal(true)
  }
  function closeCreateModal(): void {
    setIsOpenCreateModal(false)
    setSelectedCountry(null)
  }
  function openConfirmDeleteModal(country: ICountry): void {
    setSelectedCountry(country)
    setIsOpenConfirmDeleteModal(true)
  }
  function closeConfirmDeleteModal(): void {
    setSelectedCountry(null)
    setIsOpenConfirmDeleteModal(false)
  }
  function closeEditModal(): void {
    setSelectedCountry(null)
    setIsOpenEditModal(false)
  }
  function openEditModal(country: ICountry): void {
    setSelectedCountry(country)
    setIsOpenEditModal(true)
  }
  function createNewCountryHandler(item: ICountryCreationForm): void {
    const itemData = {
      value: item.value,
    }
    adminCountryStore.createCountry(itemData)
    closeCreateModal()
    pagination.gotoPage(1)
  }
  function deleteCountryHandler(id: string): void {
    adminCountryStore.deleteCountry(id)
    closeConfirmDeleteModal()
    pagination.gotoPage(1)
  }
  function editCountryHandler(item: ICountry): void {
    adminCountryStore.updateCountry(item)
    closeEditModal()
    pagination.gotoPage(1)
  }

  const tableData = getValidArray(countryList).map((country) => {
    return {
      ...country,
      actions: (
        <OptionButtonsWithoutDropBox
          isEdit={true}
          isDelete={true}
          editActionHandler={() => openEditModal(country)}
          deleteActionHandler={() => openConfirmDeleteModal(country)}
        />
      ),
    }
  })
  const pageIndex: number = Number(get(history, 'location.state.page', 1)) || 1
  const pagination = {
    includePagination: true,
    pageIndex,
    pageSize: 10,
    tableLength: listCount,
    gotoPage: (page: number) => {
      history.push({ pathname: frontendRoutes.settingPage.globalConfig.value, state: { page, filter } })
      adminCountryStore.getPaginatedCountries(false, history, filter)
    },
  }

  const searchHandler = debounce((event) => {
    setFilter({
      ...filter,
      value: { $regex: event?.target?.value ?? '', $options: 'i' },
    })
  }, 1000)

  return (
    <VStack width="full">
      <HStack marginBottom={6} width={{ base: '100%', md: '100%' }} justifyContent={'space-between'}>
        <InputGroup
          border={`1px solid ${backgroundGrey200}`}
          borderRadius="6px"
          width={{ base: '100%', lg: '540px' }}
          background="white">
          <InputLeftElement pointerEvents="none">
            <Search2Icon color="gray.400" />
          </InputLeftElement>
          <Input type="search" placeholder="Search Country by name..." onChange={searchHandler} />
        </InputGroup>
        <Button variant="solid" leftIcon="add-white.svg" onClick={openCreateModal}>
          Create
        </Button>
      </HStack>
      <Table
        className={styles.fullWidth}
        isLoading={isLoadingCountryList}
        headerList={getCountryHeaderList()}
        tableData={tableData}
        isStriped
        pagination={pagination}
        isShowPagination={true}
      />
      <CountryModal
        actionType={EActionType.CREATE}
        isOpen={isOpenCreateModal}
        closeHandler={closeCreateModal}
        saveHandler={createNewCountryHandler}
        data={selectedCountry}
        isSubmitSuccess={isLoadNewCountryList}
      />
      <CountryModal
        actionType={EActionType.EDIT}
        isOpen={isOpenEditModal}
        closeHandler={closeEditModal}
        saveHandler={editCountryHandler}
        data={selectedCountry}
        isSubmitSuccess={isLoadNewCountryList}
      />
      <ConfirmModal
        data={selectedCountry}
        isOpen={isOpenConfirmDeleteModal}
        closeHandler={closeConfirmDeleteModal}
        OKClickHandler={deleteCountryHandler}
        title={'Country'}
        content={'name'}
        type={EModalType.DELETE}
      />
    </VStack>
  )
}

export default observer(Country)
