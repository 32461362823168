import { Box as ChakraBox } from '@chakra-ui/layout'
import { IBox } from './interface'

const Box = (props: IBox) => {
  const { children } = props

  return (
    <ChakraBox
      width="inherit"
      background="white"
      borderRadius="6px"
      shadow="0 1px 2px rgb(0 0 0 / 6%), 0 1px 3px rgb(0 0 0 / 10%)">
      {children}
    </ChakraBox>
  )
}

export default Box
