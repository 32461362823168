import React from 'react'
import * as Yup from 'yup'
import { withStyles } from '@material-ui/core/styles'
import SelectField from 'components/SelectField'
import FormModel from 'components/FormModel'
import { Messages } from 'constants/index'
import { generateUserOptions } from 'utils/user'
import Button from 'components/Button'

const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
    fontSize: 16
  }
})

const FilterFormDetail = (props) => {
  const { dirty, users, isSubmitting, handleSubmit, isReportLoading } = props
  const usersOptions = generateUserOptions(users)

  return (
    <form onSubmit={handleSubmit}>
      <div className="container-fluid">
        <div className="box box-default">
          <div className="box-header">Generate User History</div>
          <div className="box-divider" />
          <div className={`box-body`}>
            <div className="container-fluid">
              <div className="fields">
                <div className="field">
                  <SelectField
                    name="user"
                    label="User*"
                    options={usersOptions}
                    isReactSelect
                    data={users}
                  />
                </div>
              </div>
              <div className="submit-option">
                <Button isLoading={isReportLoading} type="submit" disabled={!dirty && isSubmitting}>
                  Generate
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

const FilterForm = (props) => {
  const { users, filterSubmitHandler, initialValues } = props

  if (!users || users.length === 0) {
    return null
  }

  const usersOptions = generateUserOptions(users)

  const selectedUser = usersOptions.find(option => option.value === initialValues.user) || ''

  const initialValuesWithUser = {
    user: selectedUser
  }

  const validationSchema = Yup.object().shape({
    user: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string()
      })
      .required(Messages.fieldRequired)
  })

  return (
    <FormModel
      initialValues={initialValuesWithUser}
      schema={validationSchema}
      handleSubmit={filterSubmitHandler}
      component={<FilterFormDetail users={users} {...props} />}
    />
  )
}

export default withStyles(styles)(FilterForm)
