import { useEffect } from 'react'
import { Box } from '@chakra-ui/layout'
import GeneralList from 'components/GeneralList'
import LoadingChakra from 'components/LoadingChakra'
import { observer } from 'mobx-react'
import { useStores } from 'utils/hooks/useStores'

const AnnouncementUser = () => {
  const { userAnnouncementStore } = useStores()
  const { announcementGroupsByMonth, isLoading } = userAnnouncementStore

  useEffect(() => {
    userAnnouncementStore.getAnnouncementGroupsByMonth()
  }, [])

  return (
    <Box background="white" padding="24px" borderRadius="6px">
      <LoadingChakra isLoading={isLoading}>
        <GeneralList dataGroupsByMonth={announcementGroupsByMonth} />
      </LoadingChakra>
    </Box>
  )
}
export default observer(AnnouncementUser)
