import React from 'react'
import { HStack, VStack, Text, Box } from '@chakra-ui/react'
import Table from 'components/Table'
import { observer } from 'mobx-react'
import { useStores } from 'utils/hooks/useStores'
import { getFormattedNumber } from 'utils/numberUtils'

const TaskTypeAnalytics = () => {
  const { adminReportStore } = useStores()
  const { taskTypeData } = adminReportStore
  function getTaskTypeHeaderList() {
    const headers = [
      {
        Header: 'TYPE',
        accessor: 'taskType',
      },
      {
        Header: 'WORKING TIME (HRS)',
        accessor: 'workingTime',
        Cell: ({ row }) => (
          <Text margin={0}>
            {row.original.workingTime}
            <Text margin={0} marginLeft={'10px'} color={'#4D5DFB'} display={'inline-block'}>
              {row.original.workingTimePercent}
            </Text>
          </Text>
        ),
      },
      {
        Header: 'TOTAL SALARY (VND))',
        accessor: 'salary',
        Cell: ({ row }) => (
          <Text margin={0}>
            {row.original.salary}
            <Text margin={0} marginLeft={'10px'} color={'#4D5DFB'} display={'inline-block'}>
              {row.original.salaryPercent}
            </Text>
          </Text>
        ),
      },
    ]

    return headers
  }
  if (!taskTypeData) {
    return null
  }
  const tableData = taskTypeData.map((item) => {
    return {
      ...item,
      taskType: item.taskName,
      salary: getFormattedNumber(item.salary, 1),
      salaryPercent: `(${getFormattedNumber(item.salaryPercent, 1)}%)`,
      workingTime: `${getFormattedNumber(item.workingTime, 1)}`,
      workingTimePercent: `(${getFormattedNumber(item.workingTimePercent, 1)}%)`,
    }
  })
  return (
    <HStack width="inherit" background="white" margin={{ base: 2, lg: '16px' }}>
      <VStack width="inherit" padding={'16px'} alignItems={'flex-start'}>
        <h3>Task Type Analytics</h3>
        <Box width={'full'}>
          <Table headerList={getTaskTypeHeaderList()} tableData={tableData} isStriped />
        </Box>
      </VStack>
    </HStack>
  )
}

export default observer(TaskTypeAnalytics)
