import { ITaskType } from 'API/taskType/constants'
import { IOption } from 'components/Dropdown/types'

export enum EProjectStage {
  DELETED = 'Deleted',
  REMOVED = 'Removed',
  ARCHIVED = 'Archived',
  ACTIVE = 'Active',
  DISABLED = 'Disabled',
}

export const STATUS_COLORS = {
  [EProjectStage.ACTIVE]: '#4fad33',
  [EProjectStage.DELETED]: '#777777',
  [EProjectStage.REMOVED]: '#777777',
  [EProjectStage.ARCHIVED]: '#777777',
  [EProjectStage.DISABLED]: '#777777',
}

export interface IPayloadOfAutoSelectTaskType {
  taskName?: string
  description?: string
  taskTypeList: ITaskType[]
  currentTaskTypeList?: IOption[]
}
