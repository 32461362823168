import { ISuccessResponse } from 'API/constants'
import { litsOfContractAPI } from 'API/contract'
import { showContractFileForUserAPI } from 'API/contract'
import { IContractListResponse } from 'API/contract/constant'
import { handleError } from 'API/error'
import { makeAutoObservable } from 'mobx'
import { IContract } from 'constants/schema'
import { toBase64 } from 'utils/commonUtils'
import RootStore from '../rootStore'
class UserContractStore {
  rootStore: RootStore
  contractList: IContract[] = []
  currentContractContent

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public async getContractList(): Promise<void> {
    try {
      const response: ISuccessResponse<IContractListResponse> = await litsOfContractAPI()
      const contractList: IContract[] = response.data.contractList
      this.contractList = contractList
    } catch (error) {
      handleError(error, 'src/store/user/contractStore.ts', 'getContractList')
    }
  }

  public async showContractFile(id: string, fileName: string): Promise<void> {
    try {
      const acceptType = 'application/pdf'
      const contractFile: BlobPart = await showContractFileForUserAPI(id, fileName, acceptType)
      const result: string = (await toBase64(new Blob([contractFile], { type: acceptType }))) as string
      this.currentContractContent = result
    } catch (error) {
      handleError(error, 'src/store/admin/contractStore.ts', 'showContractFile')
    }
  }
}

export default UserContractStore
