import { ReactNode } from 'react'
import { Box } from '@chakra-ui/layout'
import Menu from '../Menu'

interface IDropdownProps {
  children?: ReactNode
  isOpen: boolean
  target: ReactNode
  onClose: () => void
  menuPlacement: 'bottom' | 'top'
}

const Dropdown = (props: IDropdownProps) => {
  const { children, isOpen, onClose, menuPlacement, target } = props
  return (
    <Box position="relative" width="full">
      {target}
      {isOpen ? <Menu menuPlacement={menuPlacement}>{children}</Menu> : null}
      {isOpen ? <Box position="fixed" zIndex="1" right="0" bottom="0" left="0" top="0" onClick={onClose} /> : null}
    </Box>
  )
}
export default Dropdown
