import React from 'react'

import ProjectTable from './Projects/Table'
import { getTimesheetHistoryAPI } from 'API/timesheet/index'
import { handleError } from 'API/error'
import { ETimesheetSubTitle } from 'constants/enum'
import { getValidArray } from 'utils/commonUtils'
import EmptyBoxContent from 'components/EmptyContentBox'
import { Messages } from 'constants/index'
import LoadingChakra from 'components/LoadingChakra'

class History extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userHistoryData: [],
      isHistoryLoading: false,
    }
  }

  componentDidMount() {
    window.sessionStorage.setItem('subPageTitle', ETimesheetSubTitle.HISTORY)
    window.dispatchEvent(new Event('storage'))
    this.generateProjectHistory()
  }

  generateProjectHistory = async () => {
    try {
      this.setState({ isHistoryLoading: true })
      const response = await getTimesheetHistoryAPI()
      this.setState({
        projectHistoryData: response?.data?.projectHistoryData,
      })
    } catch (error) {
      handleError(error, 'src/containers/TimeSheet/TimeSheetUser/History/index.js', 'generateProjectHistory')
    } finally {
      this.setState({ isHistoryLoading: false })
    }
  }

  render() {
    const { projectHistoryData } = this.state
    return <LoadingChakra isLoading={this.state.isHistoryLoading}>
        {getValidArray(projectHistoryData).length === 0 ? (
          <EmptyBoxContent text={Messages.noData} />
        ) : (
          <div className="timesheet-page">
            <div className="timesheet-content">
              <div className="container-fluid">
                <div className="box box-default">
                  <div className="box-header">Summary</div>
                  <div className="box-divider" />
                  <div className={`box-body`}>
                    <ProjectTable data={projectHistoryData} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
    </LoadingChakra>
  }
}

export default History
