import { useState } from 'react'
import { Container, useMediaQuery, VStack } from '@chakra-ui/react'
import { maxTabletSize } from 'constants/common'
import MenuItem from './MenuItem'
import { EItemLink, EItemText } from './MenuItem/constants'

const MenuChakra = () => {
  const [selectedLink, setSelectedLink] = useState(null)
  const [isMobile] = useMediaQuery(maxTabletSize)

  const handleLinkClick = (link: string) => {
    setSelectedLink(link)
  }

  if (isMobile) {
    return null
  }

  return (
    <Container background="white" width="full" maxWidth={{ base: 'unset', xl: '300px' }} padding={0} borderRadius={8}>
      <VStack backgroundColor="inherit" borderRadius="8px" padding="20px">
        <MenuItem
          href={EItemLink.USER_PROFILE_DETAIL}
          text={EItemText.USER_PROFILE_DETAIL}
          selectedLink={selectedLink}
          handleLinkClick={handleLinkClick}
        />
        <MenuItem
          href={EItemLink.PROJECT_RATINGS}
          text={EItemText.PROJECT_RATINGS}
          selectedLink={selectedLink}
          handleLinkClick={handleLinkClick}
        />
        <MenuItem
          href={EItemLink.SKILL_ANALYSIS}
          text={EItemText.SKILL_ANALYSIS}
          selectedLink={selectedLink}
          handleLinkClick={handleLinkClick}
        />
        <MenuItem
          href={EItemLink.LABOR_CONTRACT}
          text={EItemText.LABOR_CONTRACT}
          selectedLink={selectedLink}
          handleLinkClick={handleLinkClick}
        />
        {/* implement later */}
        {/* <MenuItem href="#Timetable" text="Timetable" selectedLink={selectedLink} handleLinkClick={handleLinkClick} /> */}
        <MenuItem
          href={EItemLink.CURRICULUM_VITAE}
          text={EItemText.CURRICULUM_VITAE}
          selectedLink={selectedLink}
          handleLinkClick={handleLinkClick}
        />
        <MenuItem
          href={EItemLink.USER_DATA}
          text={EItemText.USER_DATA}
          selectedLink={selectedLink}
          handleLinkClick={handleLinkClick}
        />
      </VStack>
    </Container>
  )
}

export default MenuChakra
