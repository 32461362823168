export const technology = {
  // Global Config Page - Technology &
  // [User Detail Page, Create User Page, Profile Page] - Technology &
  // [Project List Page & Project Detail & Project Create - Technology]
  value: '/technology',
  list: {
    value: '/technology/list',
  },
  technologyId: {
    value: (technologyId: string) => `/technology/${technologyId}`,
  },
}
