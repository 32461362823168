export const contract = {
  value: '/contract',
  // Profile Page - Labor Contract
  listOfUser: {
    value: '/contract/list-of-user',
  },
  // User Detail Page - Labor Contract && Contract Pages of Admin
  listOfAGivenUser: {
    value: `/contract/list-of-agiven-user`,
    userId: {
      value: (userId: string) => `/contract/list-of-agiven-user/${userId}`,
    },
  },
  delete: {
    value: `/contract/delete`,
    contractId: {
      value: (contractId: string) => `/contract/delete/${contractId}`,
    },
  },
  active: {
    value: `/contract/active`,
    contractId: {
      value: (contractId: string) => `/contract/active/${contractId}`,
    },
  },
  deactive: {
    value: `/contract/deactive`,
    contractId: {
      value: (contractId: string) => `/contract/deactive/${contractId}`,
    },
  },
  downloadFileFromAdmin: {
    value: '/contract/download-file-from-admin',
    contractId_fileName: {
      value: (contractId: string, fileName: string) => `/contract/download-file-from-admin/${contractId}/${fileName}`,
    },
  },
  showFileFromAdmin: {
    value: '/contract/show-file-from-admin',
    contractId_fileName: {
      value: (contractId: string, fileName: string) => `/contract/show-file-from-admin/${contractId}/${fileName}`,
    },
  },
  sendEmail: {
    value: '/contract/send-email',
    contractId_fileName: {
      value: (contractId: string, fileName: string) => `/contract/send-email/${contractId}/${fileName}`,
    },
  },
  showFileFromUser: {
    value: '/contract/show-file-from-user',
    contractId_fileName: {
      value: (contractId: string, fileName: string) => `/contract/show-file-from-user/${contractId}/${fileName}`,
    },
  },
  listOfAdmin: {
    value: '/contract/list-of-admin',
  },
  create: {
    value: '/contract/create',
  },
  detailOfAdmin: {
    value: (contractId: string) => `/contract/detail-of-admin/${contractId}`,
  },
  update: {
    value: (contractId: string) => `/contract/update/${contractId}`,
  },
  publish: {
    value: (contractId: string) => `/contract/publish/${contractId}`,
  },
}
