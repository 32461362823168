import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { THistory } from 'types/common'
import { IUser } from 'types/user'
import { EConditionGetList } from 'constants/enum'
import { frontendRoutes } from 'constants/routes'
import { useStores } from 'utils/hooks/useStores'
import CreateUserForm from '../components/CreateUserForm'

const UserCreate = () => {
  const history: THistory = useHistory()
  const { adminUserStore, adminProjectStore, adminTechnologyStore, adminPartnerStore } = useStores()
  const { creatableOptionList } = adminUserStore
  const { partnerNameList } = adminPartnerStore
  const { projectNameList } = adminProjectStore
  const { technologyList } = adminTechnologyStore
  useEffect(() => {
    adminUserStore.getOptionList()
    adminPartnerStore.getAllNamePartnerList(EConditionGetList.ACTIVE)
    adminTechnologyStore.getTechnologyList()
    adminProjectStore.getAllNameProjectList(EConditionGetList.ACTIVE)
  }, [])

  const submitHandler = async (data: IUser) => {
    await adminUserStore.createUser(data)
    history.push(frontendRoutes.userPage.list.value)
  }

  return (
    <CreateUserForm
      technologyList={technologyList}
      partnerList={partnerNameList}
      projectNamList={projectNameList}
      creatableOptionList={creatableOptionList}
      submitHandler={submitHandler}
    />
  )
}

export default observer(UserCreate)
