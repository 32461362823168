import React, { useEffect, useState } from 'react'
import { Box } from '@chakra-ui/react'
import { Document, Page, pdfjs } from 'react-pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface IShowPDFProps {
  cvFilePreview?: any
  cvFilePreviewName?: string
  cvFileName?: string
  getProfileDataHandler?: any
  content
}

const ShowPDF = (props: IShowPDFProps) => {
  const [numPages, setNumPages] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)
  const [disabledPre, setDisabledPre] = useState(false)
  const [disabledNext, setDisabledNext] = useState(false)
  const { content } = props

  const checkDisabledNavBtn = () => {
    let isDisabledPre
    let isDisabledNext
    if (numPages === 1) {
      isDisabledPre = true
      isDisabledNext = true
    }
    if (pageNumber === numPages) {
      isDisabledNext = true
    }
    if (pageNumber === 1) {
      isDisabledPre = true
    }

    if (pageNumber < numPages && pageNumber > 1) {
      isDisabledPre = false
      isDisabledNext = false
    }
    setDisabledPre(isDisabledPre)
    setDisabledNext(isDisabledNext)
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }
  const clickNextPageHandler = () => {
    setPageNumber(pageNumber + 1)
  }

  const clickPrePageHandler = () => {
    setPageNumber(pageNumber - 1)
  }

  // const toBase64 = (file) =>
  //   new Promise((resolve, reject) => {
  //     const reader = new FileReader()
  //     reader.readAsDataURL(file)
  //     reader.onload = () => resolve(reader.result)
  //     reader.onerror = (error) => reject(error)
  //   })

  useEffect(
    function handeDisableNavigation() {
      checkDisabledNavBtn()
    },
    [pageNumber]
  )

  return (
    <Box alignContent={'center'}>
      {content && (
        <div className={`box-body`}>
          <Document
            file={content}
            onLoadSuccess={onDocumentLoadSuccess}
            options={{
              cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
              cMapPacked: true,
            }}>
            <Page size="A4" orientation={'portrait'} pageNumber={pageNumber} />
          </Document>
          {numPages && (
            <div className="pagination-cv-page">
              <div className="pagination-sub">
                <span className={`nav-button ${disabledPre ? 'disabled' : ''}`} onClick={clickPrePageHandler}>
                  <span className="double-arrow">«</span>
                </span>
                <p>
                  Page {pageNumber} of {numPages}
                </p>
                <span className={`nav-button ${disabledNext ? 'disabled' : ''}`} onClick={clickNextPageHandler}>
                  <span className="double-arrow">»</span>
                </span>
              </div>
            </div>
          )}
        </div>
      )}
    </Box>
  )
}

export default ShowPDF
