import { handleError } from 'API/error'
import { getTechnologyListAPI } from 'API/technology'
import { makeAutoObservable } from 'mobx'
import RootStore from 'store/rootStore'
import { ITechnology } from 'types/technology'

class UserTechnologyStore {
  rootStore: RootStore
  technologyList: ITechnology[] = []

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public async getTechnologyList(): Promise<void> {
    try {
      const { technologies } = await getTechnologyListAPI()
      this.technologyList = technologies
    } catch (error) {
      handleError(error, 'src/store/user/technologyStore.ts', 'getTechnologyList')
    }
  }
}
export default UserTechnologyStore
