import { IContract, IPartner } from 'constants/schema'

export const CONTRACT_BASE_URL: string = '/contract'

export interface IContractListResponse {
  contractList: IContract[]
}

export interface IContractPayload extends IContract {
  isCreateInternalDoc?: boolean
}

export interface IContractDetail extends IContract {
  isCreateInternalDoc?: boolean
  partnerData: IPartner[]
}

export interface IContractDetailResponse {
  contractDetail: IContractDetail
}

export interface IContractListWithPaginationRequest {
  currentPage: number
  perPage: number
}

export interface IContractListWithPaginationResponse {
  contractList: IContract[]
  count: number
}
