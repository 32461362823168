import React from 'react'
import { Field } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import Select from 'components/Select'
import SelectField from 'components/SelectField'
import DatePicker from 'components/DatePickerOld'
import RadioButton from 'components/RadioButton'
import { ReportPeriods } from 'constants/defaultOptionList'
import { generateProjectOptions } from 'utils/user'
import { createOptionsOfSelect } from 'utils/dropdownOptionUtils'
import Button from 'components/Button'
const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
    fontSize: 16,
  },
})
class FilterFormOfPayment extends React.Component {
  render() {
    const { classes, projects, dirty, isSubmitting, handleSubmit, values, isBillReportLoading } = this.props
    const projectsOptions = generateProjectOptions(projects)
    const periodOptions = createOptionsOfSelect(ReportPeriods)

    return (
      <form onSubmit={handleSubmit}>
        <div className="container-fluid">
          <div className="box box-default">
            <div className="box-header">Filter</div>
            <div className="box-divider" />
            <div className={`box-body`}>
              <div className="container-fluid">
                <div className="fields">
                  <SelectField
                    name="project"
                    label="Project*"
                    options={projectsOptions}
                    isReactSelect
                    data={projects}
                  />
                  <div className="field">
                    <Field
                      name="period"
                      label="Period*"
                      className={classes.textField}
                      margin="normal"
                      component={Select}
                      options={periodOptions}
                    />
                  </div>
                  <div className="field">
                    <Field
                      name="date"
                      label="Date*"
                      className={classes.textField}
                      margin="normal"
                      disabled={values?.current ?? false}
                      component={DatePicker}
                    />
                  </div>
                </div>
                <div className="fields">
                  <div className="field radio-button">
                    <Field
                      name="viewHourlyMode"
                      label="Hourly Mode"
                      className={classes.textField}
                      margin="normal"
                      component={RadioButton}
                    />
                  </div>
                  <div className="field radio-button">
                    <Field
                      name="anonymous"
                      label="Anonymous"
                      className={classes.textField}
                      margin="normal"
                      component={RadioButton}
                    />
                  </div>
                  <div className="field radio-button">
                    <Field
                      name="showPrice"
                      label="Show Price"
                      className={classes.textField}
                      margin="normal"
                      component={RadioButton}
                    />
                  </div>
                  <div className="field radio-button">
                    <Field
                      name="current"
                      label="Current time"
                      className={classes.textField}
                      margin="normal"
                      component={RadioButton}
                    />
                  </div>
                </div>
                <div className="submit-option">
                  <Button isLoading={isBillReportLoading} type="submit" disabled={!dirty && isSubmitting}>
                    Filter
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }
}

export default withStyles(styles)(FilterFormOfPayment)
