import { useEffect, useState } from 'react'
import { VStack, HStack, Stack, Box } from '@chakra-ui/react'
import EmptyContentBox from 'components/EmptyContentBox'
import LoadingChakra from 'components/LoadingChakra'
import { observer } from 'mobx-react'
import { FormProvider, useForm } from 'react-hook-form'
import { EConditionGetList, EDashboardSubTitle } from 'constants/enum'
import { Messages } from 'constants/index'
import { useStores } from 'utils/hooks/useStores'
import HeatmapFilterForm from '../../components/HeatmapFilterForm'
import { IHeatmapFilterFormData, typeOfFilterOfHeadMap } from '../../constant'
import { RenderBodyHeatChart } from '../../container'

const Heatmap = () => {
  window.sessionStorage.setItem('subPageTitle', EDashboardSubTitle.HEATMAP)
  window.dispatchEvent(new Event('storage'))
  const { userProjectStore, userReportStore, userProfileStore, adminUserStore } = useStores()
  const { isSupervisor } = userProfileStore
  const { userNameListOfSupervisor } = adminUserStore
  const [isValidAnalysis, setIsValidAnalysis] = useState(false)
  const { projectNameListForSupervisor, projectNameList } = userProjectStore
  const { haveData, chartData } = userReportStore
  const methods = useForm<IHeatmapFilterFormData>({
    defaultValues: {
      typeOfFilter: typeOfFilterOfHeadMap[0],
    },
  })
  const { getValues } = methods
  const { selectedProject, selectedUser } = getValues()

  function viewHeatmapHandler(data: IHeatmapFilterFormData) {
    const { selectedProject, selectedUser, typeOfFilter } = data
    let requestData
    if (isSupervisor) {
      requestData = {
        project: selectedProject?.value,
        user: selectedUser?.value,
        typeOfFilter: typeOfFilter.value,
      }
    } else {
      requestData = {
        project: selectedProject.value,
      }
    }
    userReportStore.getHeatmapOfUser(isSupervisor, requestData)
  }

  useEffect(() => {
    if (isSupervisor) {
      userProjectStore.getNameProjectListForSupervisor(EConditionGetList.ACTIVE)
      adminUserStore.getAllNameUserListOfSupervisor(EConditionGetList.ACTIVE)
    } else {
      userProjectStore.getAllNameProjectList(EConditionGetList.ACTIVE)
    }
  }, [isSupervisor])

  useEffect(() => {
    if ((haveData && selectedProject) || (haveData && selectedUser)) {
      setIsValidAnalysis(true)
    } else {
      setIsValidAnalysis(false)
    }
  }, [haveData])
  let projectNameListData = []
  if (isSupervisor) {
    projectNameListData = projectNameListForSupervisor
  } else {
    projectNameListData = projectNameList
  }

  return (
    <VStack width="full">
      <FormProvider {...methods}>
        <HeatmapFilterForm
          projectNameList={projectNameListData}
          viewAnalyticHandler={viewHeatmapHandler}
          isSupervisor={isSupervisor}
          userNameList={userNameListOfSupervisor}
        />

        <LoadingChakra isLoading={userReportStore.isHeatmapLoading}>
          <Box width="inherit" background="white" margin={{ base: 2, lg: '16px' }}>
            {isValidAnalysis && <Stack width={'full'}>{RenderBodyHeatChart({ chartData })}</Stack>}
            {!isValidAnalysis && (
              <HStack width="inherit" padding={'16px'} minHeight={'80vh'}>
                <EmptyContentBox text={Messages.emptyBoxFilterMessage} position="bottom" />
              </HStack>
            )}
          </Box>
        </LoadingChakra>
      </FormProvider>
    </VStack>
  )
}

export default observer(Heatmap)
