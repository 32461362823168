import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import moment from 'moment'
import {
  notFoundDailyReportMessage,
  notFoundDailyReportTitle,
  invalidStartTimeMessage,
  invalidDurationMessage,
  notFoundDoneTasksTitle,
  notFoundDoneTasksMessage,
  haveDailyReportButNotFoundDoneTasksTitle,
  haveDailyReportButNotFoundDoneTasksMessage,
  invalidDurationTitle,
  invalidFormatDoneTaskMessage,
  invalidFormatDoneTaskTitle,
  invalidStartTimeTitle,
} from 'constants/dailyReportErrors'
import { EStatus } from 'constants/enum'
import { getValidArray, isValidArray } from 'utils/commonUtils'
import { IDailyReportData, IErrorDailyReport, IDoneTaskItem } from './constants'

dayjs.extend(duration)

export function convertDurationToHours(duration: string) {
  const hourPattern = /(\d+)\s*(h|hr|hrs)/i
  const minutePattern = /(\d+)\s*(m|min|mins|ms)?$/i

  const hoursMatch = duration.match(hourPattern)
  let hours = 0
  let minutes = 0

  if (hoursMatch) {
    hours = parseInt(hoursMatch[1], 10)
    if (hours >= 24) {
      return undefined
    }
    const remaining = duration.slice(hoursMatch.index + hoursMatch[0].length).trim()
    if (remaining) {
      const minutesMatch = remaining.match(minutePattern)
      if (minutesMatch && (!minutesMatch[2] || minutesMatch[2].match(/m|min|mins|ms/i))) {
        minutes = parseInt(minutesMatch[1], 10)
        if (minutes >= 60) {
          return undefined
        }
      } else {
        return undefined
      }
    }
  } else {
    const minutesOnlyMatch = duration.match(minutePattern)
    if (minutesOnlyMatch && (!minutesOnlyMatch[2] || minutesOnlyMatch[2].match(/m|min|mins|ms/i))) {
      minutes = parseInt(minutesOnlyMatch[1], 10)
      if (minutes >= 60) {
        return undefined
      }
    } else {
      return undefined
    }
  }

  return hours + minutes / 60
}

export function splitDailyReports(text: string): string[] {
  const reportRegex = /daily report \d{2}\/\d{2}/gi

  const headerMatch = text.match(reportRegex)
  if (!headerMatch) {
    return []
  }

  const reports = text.split(reportRegex)
  return reports
    .map((report, index) => {
      if (report.trim()) {
        return headerMatch[index - 1] + report
      }
      return null
    })
    .filter((report) => report !== null)
}

export function convertToSpecificDate(dateString, timeString) {
  const datePattern = /^(\d{1,2})\/(\d{1,2})$/
  const dateMatch = dateString.match(datePattern)
  if (!dateMatch) {
    return undefined
  }

  const [, day, month] = dateMatch.map(Number)

  const timePattern = /^(\d{1,2})(h|hr|hrs)(\d{1,2})?(m|min|mins|ms)?$/
  const timeMatch = timeString.match(timePattern)
  if (!timeMatch) {
    return undefined
  }

  const [, hours, , minutes] = timeMatch.map((value, index) =>
    index === 0 ? Number(value) : value ? Number(value) : 0
  )

  const year = new Date().getFullYear()

  const date = moment({ year, month: month - 1, date: day, hours, minutes })

  if (!date.isValid()) {
    return undefined
  }

  return date.toDate()
}

export function extractDoneTasks(reports: string[], projectId: string | number): IDoneTaskItem[] {
  const doneSectionRegex = /done:\s*([\s\S]*?)(?=\n[A-Z]+\s*:|$)/i
  const tasks = []

  reports.forEach((report) => {
    const dateMatch = report.match(/daily report (\d{2}\/\d{2})/i)
    const date = dateMatch ? dateMatch[1] : ''
    const match = report.match(doneSectionRegex)

    if (match) {
      const items = match[1]
        .trim()
        .split('\n')
        .filter((item) => item.trim() !== '')

      items.forEach((item) => {
        const parts = item.split('|').map((part) => part.trim())
        if (parts.length === 4) {
          const taskCode = parts[0]
          const taskTitleAndDescription = parts[1]
          const splittedTitleAndDescription = taskTitleAndDescription.split(':').map((part) => part.trim())
          const title = `${taskCode} | ${splittedTitleAndDescription[0]}`
          const timeTotal = convertDurationToHours(parts[3])
          const startTime = convertToSpecificDate(date, parts[2])
          if (!startTime) {
            const errorTask = {
              status: EStatus.INVALID,
              errorTitle: invalidStartTimeTitle(title),
              errorDescription: invalidStartTimeMessage,
            }
            tasks.push(errorTask)
            return
          }
          if (!timeTotal) {
            const errorTask = {
              status: EStatus.INVALID,
              errorTitle: invalidDurationTitle(title),
              errorDescription: invalidDurationMessage,
            }
            tasks.push(errorTask)
            return
          }
          const endTime = moment(startTime).add(timeTotal, 'hours').toDate()

          tasks.push({
            status: EStatus.VALID,
            taskName: title,
            description: splittedTitleAndDescription[1],
            project: projectId,
            timeTotal,
            startTime: startTime,
            endTime,
          })
        } else {
          tasks.push({
            status: EStatus.INVALID,
            errorTitle: invalidFormatDoneTaskTitle,
            errorDescription: invalidFormatDoneTaskMessage,
          })
        }
      })
    } else {
      tasks.push({
        status: EStatus.INVALID,
        errorTitle: haveDailyReportButNotFoundDoneTasksTitle,
        errorDescription: haveDailyReportButNotFoundDoneTasksMessage,
      })
    }
  })

  return tasks
}

export function getTimesheetDataFromDailyReport(rawData: IDailyReportData) {
  let finalData = []
  if (rawData) {
    const { project, dailyReport } = rawData
    const dailyReports = splitDailyReports(dailyReport)
    const doneTasksData = extractDoneTasks(dailyReports, project)
    getValidArray(doneTasksData).forEach((doneTask) => {
      if (doneTask.status === EStatus.VALID) {
        finalData.push({
          taskName: doneTask.taskName,
          description: doneTask.description,
          project: doneTask.project,
          timeTotal: doneTask.timeTotal,
          startTime: doneTask.startTime,
          endTime: doneTask.endTime,
        })
      }
    })
  }
  return finalData
}

export function getErrorTimesheetDataFromDailyReport(dailyReports: string): IErrorDailyReport[] {
  const error = []

  if (dailyReports) {
    const splittedDailyReports = splitDailyReports(dailyReports)
    if (isValidArray(splittedDailyReports)) {
      const doneTasksData = extractDoneTasks(splittedDailyReports, 'checkValidTask')
      if (isValidArray(doneTasksData)) {
        doneTasksData.forEach((doneTask) => {
          if (doneTask.status === EStatus.INVALID) {
            error.push({
              errorTitle: doneTask.errorTitle,
              errorDescription: doneTask.errorDescription,
            })
          }
        })
      } else {
        error.push({
          errorTitle: notFoundDoneTasksTitle,
          errorDescription: notFoundDoneTasksMessage,
        })
      }
    } else {
      error.push({
        errorTitle: notFoundDailyReportTitle,
        errorDescription: notFoundDailyReportMessage,
      })
    }
  }
  return error
}
