import { useState } from 'react'
import { Flex, Box, VStack, Link } from '@chakra-ui/react'
import Button from 'components/Button'
import LoginBackground from 'components/LoginBackground'
import { TitleLogin } from 'components/TitleLogin'
import { useParams } from 'react-router-dom'
import { frontendRoutes } from 'constants/routes'
import ResetPasswordForm from './components/ResetPasswordForm'

const ResetPassword = () => {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const { resetPasswordToken } = useParams<{ resetPasswordToken: string }>()

  return (
    <Flex h="100vh" direction={{ base: 'column', md: 'row' }}>
      <LoginBackground />
      <Flex flex={1} align="center" justify="center" bg="white" px="6">
        <VStack spacing="6" w="full" maxW="400px">
          <TitleLogin
            title="Reset your password"
            subtitle={
              isSubmitted
                ? 'Your password has been updated successfully. Please sign in to your account again'
                : 'Please provide your new password'
            }
          />
          {isSubmitted ? (
            <Box w="full" mt={6}>
              <Link href={frontendRoutes.loginPage.value} style={{ textDecoration: 'none' }}>
                <Button type="submit">Sign in now</Button>
              </Link>
            </Box>
          ) : (
            <Box w="full">
              <ResetPasswordForm setIsSubmitted={setIsSubmitted} resetPasswordToken={resetPasswordToken} />
            </Box>
          )}
        </VStack>
      </Flex>
    </Flex>
  )
}

export default ResetPassword
