import { FormControl, FormLabel, Switch } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'

interface ISwitchControl {
  name?: string
  leftLabel?: string
  rightLabel?: string
  handleOnChange?: (event?: React.ChangeEvent<HTMLInputElement>) => void
  isChecked?: boolean
  isActiveDefault?: boolean
}

const SwitchControl = (props: ISwitchControl) => {
  const { name, leftLabel, rightLabel, isActiveDefault, handleOnChange } = props
  const { register, watch } = useFormContext()
  const formValueWatcher = watch(name, isActiveDefault)

  return (
    <FormControl display="flex" alignItems="center">
      <FormLabel htmlFor={name} mb="0" marginLeft="10px">
        {leftLabel}
      </FormLabel>
      <Switch size="md" id={name} {...register(name)} onChange={handleOnChange} isChecked={formValueWatcher ?? false} />
      <FormLabel htmlFor={name} mb="0" marginLeft="10px">
        {rightLabel}
      </FormLabel>
    </FormControl>
  )
}

export default SwitchControl
