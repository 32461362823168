import { Link, Text } from '@chakra-ui/react'
import { MenuItemProps } from './interface'
import styles from './index.module.scss'

const MenuItem = ({ href, text, selectedLink, handleLinkClick }: MenuItemProps) => {
  const linkStyle = selectedLink === text ? { backgroundColor: '#F7F7F7', color: '#2D3748' } : {}

  return (
    <Link
      href={href}
      padding={'6px 12px'}
      paddingBottom={0}
      width={'90%'}
      onClick={() => handleLinkClick(text)}
      style={linkStyle}
      borderRadius={'6px'}
      _hover={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}>
      <Text
        alignSelf={'flex-start'}
        fontSize={{ base: '18px' }}
        className={styles.headerBox}
        style={linkStyle}
        paddingBottom={'6px'}
        lineHeight={'28px'}>
        {text}
      </Text>
    </Link>
  )
}

export default MenuItem
