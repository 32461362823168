import { useEffect } from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import { createRevenueChartForTimeAndMaterial } from './container'
import { IRevenueChartProps } from './interfaces'

am4core.useTheme(am4themes_animated)

const RevenueChart = (props: IRevenueChartProps) => {
  const { chartData } = props
  useEffect(() => {
    const chart = createRevenueChartForTimeAndMaterial(chartData)
    return () => {
      chart.dispose()
    }
  }, [chartData])
  return <div id="revenue-chartdiv-time-and-material" style={{ width: '100%', height: '500px' }} />
}

export default RevenueChart
