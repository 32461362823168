import { useEffect } from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import { Box } from '@chakra-ui/react'
import { createChart } from './container'
import { IHeatMapChartProps } from './interfaces'

am4core.useTheme(am4themes_animated)

const HeatMapChart = (props: IHeatMapChartProps) => {
  const { chartData } = props
  useEffect(() => {
    const chart = createChart(chartData)
    return () => {
      chart.dispose()
    }
  }, [chartData])
  return <Box id="heatmap-chartdiv" width={'full'} height={'500px'}></Box>
}

export default HeatMapChart
