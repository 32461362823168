export const timesheet = {
  value: '/timesheet',
  historyUser: {
    value: '/timesheet/history-user',
    project: {
      value: '/timesheet/history-user/project',
    },
  },
  historyAdmin: {
    value: '/timesheet/history-admin',
    user: {
      value: '/timesheet/history-admin/user',
    },
    project: {
      value: '/timesheet/history-admin/project',
    },
  },
  list: {
    value: '/timesheet/list',
  },
  timesheetUser: {
    value: '/timesheet/timesheet-user',
    list: {
      value: '/timesheet/timesheet-user/list',
    },
    timesheetId: {
      value: (timesheetId: string) => `/timesheet/timesheet-user/${timesheetId}`,
    },
    create: {
      value: '/timesheet/timesheet-user/create',
    },
    createSmartTimesheet: {
      value: '/timesheet/timesheet-user/create-smart-timesheet',
    },
  },
  detail: {
    value: '/timesheet/detail',
    timesheetId: {
      value: (timesheetId: string) => `/timesheet/detail/${timesheetId}`,
    },
  },
  delete: {
    value: '/timesheet/delete',
    timesheetId: {
      value: (timesheetId: string) => `/timesheet/delete/${timesheetId}`,
    },
  },
}
