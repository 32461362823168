import { Text, VStack, SimpleGrid, Container } from '@chakra-ui/react'
import LabelForm from 'components/LabelForm'
import WebLinkForm from 'components/WebLinkForm'
import { backgroundGrey100 } from 'theme/globalStyles'
import { IUser } from 'types/user'
import { EPaymentCategoryFromClientOfProject } from 'constants/enum'
import AvatarDevGroup from './components/avatarDevGroup'
import { getValidDevelopers } from './utils'
import styles from './styles.module.scss'

const ProjectDetailForm = (props) => {
  const { methods } = props
  const { getValues } = methods
  const untilNow = getValues('untilNow')
  const listDeveloper = getValues('developerDetailOnProject')
  const paymentCategoryFromClient = getValues('paymentCategoryFromClient')
  const validDevelopers: IUser[] = getValidDevelopers(listDeveloper)

  function renderPaymentCategoryFromClientData(paymentType: string) {
    switch (paymentType) {
      case EPaymentCategoryFromClientOfProject.TIME_AND_MATERIAL:
        return (
          <SimpleGrid columns={{ sm: 2, md: 2, lg: 2 }} gap={{ sm: 4, md: 6, lg: 8 }} width="full">
            <LabelForm name="clientRate" label="Client Rate" readonly={true} />
          </SimpleGrid>
        )
      case EPaymentCategoryFromClientOfProject.MONTHLY_FIXED_SCOPE:
        return (
          <SimpleGrid columns={{ sm: 2, md: 2, lg: 2 }} gap={{ sm: 4, md: 6, lg: 8 }} width="full">
            <LabelForm name="billableMonthlyAmount" label="Billable Monthly Amount" readonly={true} />
          </SimpleGrid>
        )
      default:
        return ''
    }
  }

  return (
    <Container background="white" width="full" maxWidth="unset" padding={0} borderRadius={8}>
      <VStack
        background={{ sm: backgroundGrey100, base: 'white', lg: 'white' }}
        padding={{ md: 0, lg: 6 }}
        borderRadius="8px"
        gap="12px"
        overflow="hidden">
        <VStack
          background="white"
          paddingLeft={{ lg: '0px', md: '10px', sm: '10px' }}
          paddingBottom="24px"
          borderRadius={{ md: '8px', lg: '0' }}
          className={`${styles.groupInformation} ${styles.border}`}
          gap={{ sm: 0, md: '12px', lg: '12px' }}>
          <Text alignSelf={'flex-start'} className={styles.headerBox} fontSize={{ base: '18px' }}>
            General Information
          </Text>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ sm: 0, md: 6, lg: 8 }} width="full">
            <LabelForm name="name" label="Project Name" readonly={true} />
            <LabelForm name="companyName" label="Company Name" readonly={true} />
          </SimpleGrid>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ sm: 0, md: 6, lg: 8 }} width="full">
            <WebLinkForm name="companyWebsite" label="Company Website" isHighligh={true} />
            <LabelForm name="companyPhone" label="Company Phone Number" isHighligh readonly={true} />
          </SimpleGrid>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ sm: 0, md: 6, lg: 8 }} width="full">
            <LabelForm name="country" label="Country" readonly={true} />
            <LabelForm name="address" label="Address" readonly={true} />
          </SimpleGrid>
        </VStack>
        <VStack
          background="white"
          paddingLeft={{ lg: '0px', md: '10px', sm: '10px' }}
          paddingBottom="24px"
          borderRadius={{ md: '8px', lg: '0' }}
          className={`${styles.groupInformation} ${styles.border}`}
          gap={{ sm: 0, md: '12px', lg: '12px' }}>
          <Text alignSelf={'flex-start'} className={`${styles.headerBox}`} fontSize={{ base: '18px' }}>
            Customer Information
          </Text>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ sm: 4, md: 6, lg: 8 }} width="full">
            <LabelForm name="customer" label="Customer Name" readonly={true} />
            <LabelForm name="customerEmail" label="Customer Email" isHighligh readonly={true} />
          </SimpleGrid>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ sm: 4, md: 6, lg: 8 }} width="full">
            <LabelForm name="customerPhone" label="Customer Phone Number" isHighligh readonly={true} />
          </SimpleGrid>
        </VStack>
        <VStack
          background="white"
          paddingLeft={{ lg: '0px', md: '10px', sm: '10px' }}
          paddingBottom="24px"
          borderRadius={{ md: '8px', lg: '0' }}
          className={`${styles.groupInformation} ${styles.border}`}
          gap={{ sm: 0, md: '12px', lg: '12px' }}>
          <Text alignSelf={'flex-start'} className={`${styles.headerBox}`} fontSize={{ base: '18px' }}>
            Payment From Client
          </Text>
          <SimpleGrid columns={{ sm: 2, md: 2, lg: 2 }} gap={{ sm: 4, md: 6, lg: 8 }} width="full">
            <LabelForm name="paymentCategoryFromClient" label="Category Of Payment From Client" readonly={true} />
            <LabelForm name="currency" label="Currency" readonly={true} />
          </SimpleGrid>
          {renderPaymentCategoryFromClientData(paymentCategoryFromClient)}
        </VStack>
        <VStack
          background="white"
          paddingLeft={{ lg: '0px', md: '10px', sm: '10px' }}
          paddingBottom="24px"
          borderRadius={{ md: '8px', lg: '0' }}
          className={`${styles.groupInformation} ${styles.border}`}
          gap={{ sm: 0, md: '12px', lg: '12px' }}>
          <Text alignSelf={'flex-start'} className={`${styles.headerBox}`} fontSize={{ base: '18px' }}>
            Payment For Staff
          </Text>
          <SimpleGrid columns={{ sm: 2, md: 2, lg: 2 }} gap={{ sm: 4, md: 6, lg: 8 }} width="full">
            <LabelForm name="paymentCategory" label="Category of Payment" readonly={true} />
            <LabelForm name="currency" label="Currency" readonly={true} />
          </SimpleGrid>
        </VStack>
        <VStack
          background="white"
          paddingLeft={{ lg: '0px', md: '10px', sm: '10px' }}
          paddingBottom="24px"
          borderRadius={{ md: '8px', lg: '0' }}
          className={styles.groupInformation}
          gap={{ sm: 0, md: '12px', lg: '12px' }}>
          <Text alignSelf={'flex-start'} className={`${styles.headerBox}`} fontSize={{ base: '18px' }}>
            Working Information
          </Text>
          <SimpleGrid columns={{ sm: 2, md: 2, lg: 2 }} gap={{ sm: 4, md: 6, lg: 8 }} width="full">
            <LabelForm name="startedDate" label="Start Date" readonly={true} />
            {!untilNow && <LabelForm name="endedDate" label="End Date" readonly={true} />}
            {untilNow && <LabelForm name="untilNowProject" label="Until Now" readonly={true} />}
          </SimpleGrid>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ sm: 4, md: 6, lg: 8 }} width="full">
            <LabelForm name="commitedHours" label="Commited Hours (hrs/week)" readonly={true} />
            <LabelForm name="technology" label="Technology" readonly={true} isTagListFormat />
          </SimpleGrid>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ sm: 4, md: 6, lg: 8 }} width="full">
            <LabelForm name="neededDeveloperNumber" label="Needed Developer Number" readonly={true} />
            <AvatarDevGroup name="listDeveloper" data={validDevelopers} label="Working Developers" />
          </SimpleGrid>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ sm: 4, md: 6, lg: 8 }} width="full">
            <LabelForm name="partnerId" label="Belongs To Partner" readonly={true} />
            <LabelForm name="type" label="Project Type" isTagFormat readonly={true} isTagListFormat />
          </SimpleGrid>
        </VStack>
      </VStack>
    </Container>
  )
}
export default ProjectDetailForm
