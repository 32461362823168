import { useState, useEffect } from 'react'
import { HStack } from '@chakra-ui/react'
import Calendar from 'components/Calendar'
import { ECalendarViewType } from 'components/Calendar/constants'
import { observer } from 'mobx-react'
import { useStores } from 'utils/hooks/useStores'
import { ICalendarSectionProps } from './interface'
import { ICalendarEvent } from './types'
import { generateEvents } from './utils'

const CalendarSection = ({ calendarRef }: ICalendarSectionProps) => {
  const { userTodoOfHRStore } = useStores()
  const { todoOfHRGroupsByMonth, selectedDateFromCalendar } = userTodoOfHRStore
  const [events, setEvents] = useState<ICalendarEvent[]>([])

  useEffect(() => {
    const currentEvents: ICalendarEvent[] = generateEvents(todoOfHRGroupsByMonth, selectedDateFromCalendar)
    setEvents(currentEvents)
  }, [todoOfHRGroupsByMonth])

  return (
    <HStack width={'full'} marginTop={6}>
      <Calendar
        calendarRef={calendarRef}
        events={events}
        editable={false}
        defaultView={ECalendarViewType.DAY_GRID_MONTH}
      />
    </HStack>
  )
}

export default observer(CalendarSection)
