import { Checkbox, Box } from '@chakra-ui/react'
import { useFormContext, Controller } from 'react-hook-form'

export interface ICheckbox {
  name?: string
  label?: string
  isDisabled?: boolean
}

const CheckboxFieldWithLabel = (props: ICheckbox) => {
  const { name, label, isDisabled } = props
  const { control } = useFormContext()
  return (
    <Box cursor="pointer" padding={{ sm: '10px' }}>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <Checkbox
              name={name}
              isDisabled={isDisabled}
              defaultChecked={value}
              size="md"
              colorScheme="blue"
              isChecked={value}
              onChange={onChange}
              sx={{
                '.chakra-checkbox__control': {
                  '&:not([data-checked])': { bg: 'white' },
                  rounded: 'base',
                  borderWidth: '1px',
                },
                '.chakra-checkbox__label': { fontSize: 'sm', color: 'gray.700' },
              }}>
              {label}
            </Checkbox>
          )
        }}
      />
    </Box>
  )
}

export default CheckboxFieldWithLabel
