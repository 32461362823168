import { CloseIcon } from '@chakra-ui/icons'
import { Tag, TagLabel } from '@chakra-ui/react'
import { useFormContext, useWatch } from 'react-hook-form'
import { IOption } from 'constants/common'
import { convertTextToColor } from 'utils/commonUtils'
interface IFilterTagProps {
  fieldName: string
  label: string
  color?: string
  textColor?: string
  selectDataHandler?: (option: IOption[]) => void
}

const FilterTag = (props: IFilterTagProps) => {
  const { label, fieldName, color = 'blue', textColor = 'gray.700', selectDataHandler } = props
  const { setValue, control } = useFormContext()
  const dataList: IOption[] = useWatch({ control, name: fieldName })

  function handleRemoveTag(): void {
    const newList = dataList.filter((data) => data?.label !== label).filter((item) => item.value !== 'all')
    setValue(fieldName, newList)
    if (selectDataHandler) {
      selectDataHandler(newList)
    }
  }
  let backgroundColor: string = convertTextToColor(color, 0.2)

  return (
    <Tag
      width="fit-content"
      border="1px solid"
      borderColor={color}
      backgroundColor={backgroundColor}
      color={textColor}
      paddingX={3}
      paddingY={1}
      marginRight={4}
      marginBottom={2}
      borderRadius="6px">
      <TagLabel fontWeight={400} fontSize="md" color={textColor}>
        {label}
      </TagLabel>
      <CloseIcon marginLeft={2} width={2} height={2} cursor="pointer" onClick={handleRemoveTag} />
    </Tag>
  )
}

export default FilterTag
